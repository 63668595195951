import * as actions from './constants';
import Immutable from 'immutable';

const initialState = Immutable.Map();

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_INITIAL_OPEN_QUESTION:
            return state.set(action.question, {
                values: Immutable.List(),
                maxValues: action.maxValues,
                updateValues:false
            });
        case actions.UPDATE_CHECK:
            var questionState = state.get(action.question);
            var oldList = questionState.values;
            var found = -1;
            oldList.forEach(function (item, idx) {
                if (item === action.value) {
                    found = idx;
                }
            });
            if (found === -1) {
                if (questionState.maxValues > (questionState.values.size)) {
                    var newList = questionState.values.push(action.value);
                    return state.set(action.question, {
                        maxValues: questionState.maxValues,
                        values: newList,
                        updateValues:true
                    });
                } else {
                    return state;
                }
            } else {
                var newListD = questionState.values.delete(found);
                return state.set(action.question, {
                    maxValues: questionState.maxValues,
                    values: newListD,
                    updateValues:true
                });
            }
            return state;
        case actions.UPDATE_OTHER_WHICH:
            var questionStateOther = state.get(action.question);
            var ListOther = questionStateOther.values;
            var foundOtherW = -1;
            ListOther.forEach(function (item, idx) {
                var patt = new RegExp("otherWhich##");
                if (patt.test(item)) {
                    foundOtherW = idx;
                }
            });
            if (foundOtherW === -1) {
                if (questionStateOther.maxValues > (questionStateOther.values.size)) {
                    var newListOther = questionStateOther.values.push(action.value);
                    return state.set(action.question, {
                        maxValues: questionStateOther.maxValues,
                        values: newListOther,
                        updateValues:true
                    });
                } else {
                    return state;
                }
            } else {
                var newListD = questionStateOther.values.update(foundOtherW,
                    (item)=>{
                        return action.value;
                    });
                return state.set(action.question, {
                    maxValues: questionStateOther.maxValues,
                    values: newListD,
                    updateValues:true
                });
            }
            return state;
        case actions.DELETE_OTHER_WHICH:
            var deleteQuestionStateOther = state.get(action.question);
            var List = deleteQuestionStateOther.values;
            var foundOtherWhich = -1;
            List.forEach(function (item, idx) {
                var patt = new RegExp("otherWhich##");
                if (patt.test(item)) {
                    foundOtherWhich = idx;
                }
            });
            if (foundOtherWhich != -1) {
                var newListD = deleteQuestionStateOther.values.delete(foundOtherWhich);
                return state.set(action.question, {
                    maxValues: deleteQuestionStateOther.maxValues,
                    values: newListD,
                    updateValues:true
                });
            }
            return state;
        default:
            return state;
    }
}
