import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import TextField from "material-ui/TextField";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateTextComponent } from "../../ducks";
import WaitComponent from "../waitComponent";

class TextComponent extends Component {
    static propTypes = {
        survey: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        page: PropTypes.string.isRequired,
        fieldProp: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        loadingConfiguration: PropTypes.bool.isRequired,
    };

    state = {
        text: "",
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { path, loadingConfiguration } = this.props;
        const { value } = nextProps;
        if (!_.isEqual(path, _.get(nextProps, "path", "")) && !loadingConfiguration) {
            this.setState({ text: value });
        }
    }

    onChangeValue = (e, value) => {
        const { updateTextComponent, survey, page, path, fieldProp } = this.props;
        this.setState({ text: value });
        updateTextComponent(path, _.set({}, fieldProp, value), page, survey);
    };

    render() {
        const { title, loadingConfiguration } = this.props;
        const { text } = this.state;
        if (loadingConfiguration) {
            return <WaitComponent />;
        }
        return (
            <TextField
                hintText=""
                floatingLabelText={title}
                style={{ minWidth: 140 }}
                type="text"
                value={text}
                onChange={this.onChangeValue}
            />
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateTextComponent }, dispatch);
}

function mapStateToProps({ analyticsReportsConfiguration }, ownProps) {
    const config = analyticsReportsConfiguration.get("pageConfiguration");
    const loadingConfiguration = analyticsReportsConfiguration.get("loadingConfiguration");
    const saving = analyticsReportsConfiguration.get("saving");
    const valueDefault = _.get(ownProps, "defaultValue", "");
    const value = _.get(config, `${_.get(ownProps, "path")}.${_.get(ownProps, "fieldProp")}`, valueDefault);
    const page = analyticsReportsConfiguration.get("page");
    return {
        value,
        page,
        loadingConfiguration,
        saving,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TextComponent);
