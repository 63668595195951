import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FetchQuestionsGroupId } from '../../../../actions/questions_form';
import MixinQuestion from '../../question/mixinQuestion';
import QuestionListCloseGroup from './QuestionListCloseGroup';
import {blue700} from 'material-ui/styles/colors';
import _ from 'lodash';
import Title from '../Title';
import { LBLSURVEYS } from '../../../../constants/labels';

const ColsQuestions = {
          paddingBottom: 10
      }, styleWidthContent = {
          minWidth: 240,
          margin: '0 auto'
      }, styleTableContent = {
          width: '100%',
          minWidth: 240,
          paddingTop: 0,
          borderCollapse:'collapse'
      };

class GroupList extends Component {

    constructor(props){
        super(props);
    }

    UNSAFE_componentWillMount() {
        const { question } = this.props;
        this.props.FetchQuestionsGroupId(_.isUndefined(_.get(question, '_id._id')) ? _.get(question, '_id') : _.get(question, '_id._id'));
    }

    renderQuestions(questions, valueAnswer){
        const {
            propsToSet,
            index
        } = this.props;
        let lapso = _.split(index, '-', 2);
        let minNum = parseInt(lapso[0]) - 1;
        return _.orderBy(questions, 'fieldName', 'asc').map((question, index) => {
            const propsToSetQuestion = _.chain(propsToSet)
                                        .omit(['question', 'idQuestion'])
                                        .set('question', question)
                                        .set('idQuestion', question._id)
                                        .set('valueAnswer', valueAnswer)
                                        .value();
            const MixinCloseQuestions = MixinQuestion(QuestionListCloseGroup);
            minNum++;
            return <MixinCloseQuestions key={question._id}
                {...propsToSetQuestion}
                index={minNum}
            />;
        });
    }

    renderOptionAnswers(valueAnswer, itemList){
        if(valueAnswer instanceof Array){
            let array;
            if(_.isEqual(itemList, 'lowerCase')){
                array = LBLSURVEYS.arrays.lowercase;
            } else if(_.isEqual(itemList, 'upperCase')){
                array = LBLSURVEYS.arrays.uppercase;
            } else {
                array = [];
            }
            return valueAnswer.map( ( value, index ) => {
                let enumeration = _.size(array) !== 0
                                        ? _.size(array) <= index ? (index + 1) : array[index] + "."
                                        : _.isEqual(itemList, 'numeric')
                                            ? (index + 1) + "."
                                            : (index + 1);
                return (
                    <li key={value._id} style={{display: 'inline', paddingRight: 10, color: blue700}}>{`${enumeration} ${value.value}`}</li>
                )
            });
        }
    }

    renderOptionAnswersTable(valueAnswer, countAnswers, itemList){
        if(valueAnswer instanceof Array){
            let array;
            if(_.isEqual(itemList, 'lowerCase')){
                array = LBLSURVEYS.arrays.lowercase;
            } else if(_.isEqual(itemList, 'upperCase')){
                array = LBLSURVEYS.arrays.uppercase;
            } else {
                array = [];
            }
            const widthAnswers = (100 / countAnswers) + "%";
            return valueAnswer.map( ( value, index ) => {
                let enumeration = _.size(array) !== 0
                                        ? _.size(array) <= index ? (index + 1) : array[index] + "."
                                        : _.isEqual(itemList, 'numeric')
                                            ? (index + 1) + "."
                                            : (index + 1);
                return (
                    <div key={index} style={{margin: '0 auto', display: 'inline-block', width: widthAnswers}}>
                        <h3 style={{fontWeight: 200, paddingLeft: 7}}>{enumeration}</h3>
                    </div>
                )
            });
        }
    }

    render() {
        const {
            groupquestions,
            question,
            index
        } = this.props;
        let questionList = _.map(groupquestions, question => {
            let num = getNumberQuestionGroup(question.fieldName);
            return _.set(question, 'key', _.isEqual(num, "") ? 0 : parseInt(num));
        });
        if(questionList instanceof Array && _.size(questionList)){
            const valueAnswer = _.get(question, 'valueSource.list.values');
            const widthAnswers = _.size(valueAnswer) * 44;
            return (
                <div style={styleWidthContent}>
                    <Title question={_.has(question, 'fieldName') ? question : _.get(question, '_id')}/>
                    <div style={{border: 0, borderStyle: 'solid', borderColor: '#000', width: '95%', paddingBottom: 15, paddingTop: 15}}>
                        <ul style={{listStyle: 'none', margin: '0 auto'}}>
                            <li style={{display: 'inline', paddingRight: 10}}>Estas son las opciones de respuesta:</li>
                            {this.renderOptionAnswers(valueAnswer, _.get(question, 'itemList'))}
                        </ul>
                    </div>
                    <table style={styleTableContent}>
                        <thead>
                            <tr style={{textAlign: 'center'}}>
                                <td></td>
                                <td style={{paddingBottom: 0, textAlign: 'left', width: widthAnswers}}>
                                    {this.renderOptionAnswersTable(valueAnswer, _.size(valueAnswer), _.get(question, 'itemList'))}
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderQuestions(_.orderBy(questionList, 'key', 'asc'), valueAnswer)}
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return (
                <div style={{textAlign: 'center'}}><h3 style={{fontWeight: 400, color: "#bbb"}}>Cargando...</h3></div>
            );
        }
    }
}

GroupList.propTypes = {
    router: PropTypes.object,
    question: PropTypes.object.isRequired,
    propsToSet: PropTypes.object.isRequired
};

function mapStateToProps({questions}, ownProps) {
    const id = _.isUndefined(_.get(ownProps.question, '_id._id')) ? _.get(ownProps.question, '_id') : _.get(ownProps.question, '_id._id');
    let groupquestions = [];
    questions.surveyquestionsgroup.map( (value) => {
        if(_.isEqual(id, value.id)){
            groupquestions = value.data.data;
        }
    });
    return {
        groupquestions
    };
}

function getNumberQuestionGroup(text) {
    let arrayText = text.split("");
    let order = "";
    for (let i = _.size(arrayText) -1; i >= 0; i--){
        if(_.isEqual("#", arrayText[i]+"")){
            break;
        } else {
            order = arrayText[i] + order;
        }
    }
    return order;
}

export default connect(mapStateToProps, {FetchQuestionsGroupId})(GroupList);
