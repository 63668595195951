import PropTypes from "prop-types";
import React, { Component } from "react";
import IdleTimer from "react-idle-timer";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { blue700 } from "material-ui/styles/colors";
import { logOut } from "../../dashboards/employee/actions";
import { browserHistory } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { LBLPROJECTS } from "../../../constants/labels";
import _ from "lodash";

const customContentStyle = {
    width: "100%",
    maxWidth: "none",
    textAlign: "center",
};

class IdleMonitor extends Component {
    constructor(props) {
        super(props);
        this.handlerCloseSession = this.handlerCloseSession.bind(this);
        this._onIdle = this._onIdle.bind(this);
        this._onAction = this._onAction.bind(this);
        this.state = {
            open: false,
        };
    }

    handlerCloseSession() {
        this.setState({ open: false });
        browserHistory.push(`/login`);
    }

    _onAction() {
        console.log("Active");
    }

    _onIdle() {
        const { isAdmin, logOut } = this.props;
        const isAdminValue = _.isUndefined(isAdmin) ? false : isAdmin;
        if (!isAdminValue) {
            this.setState({ open: true });
            logOut();
        }
    }

    render() {
        const { time } = this.props;
        const timeValue = _.isUndefined(time) ? 20 : time;
        return (
            <div>
                <IdleTimer
                    ref="idleTimer"
                    activeAction={this._onAction}
                    idleAction={this._onIdle}
                    timeout={timeValue * 60 * 1000}
                    format="MM-DD-YYYY HH:MM:ss.SSS"
                >
                    <Dialog
                        title="Tiempo inactivo"
                        titleStyle={{ textAlign: "center", color: blue700 }}
                        actions={[
                            <FlatButton
                                label="Aceptar"
                                secondary={true}
                                keyboardFocused={true}
                                onClick={this.handlerCloseSession}
                            />,
                        ]}
                        modal={true}
                        contentStyle={customContentStyle}
                        open={this.state.open}
                    >
                        <h3 style={{ textAlign: "center", fontWeight: 200 }}>
                            {" "}
                            {LBLPROJECTS.msg.inactiveMessageIDLE(timeValue)}
                        </h3>
                    </Dialog>
                </IdleTimer>
            </div>
        );
    }
}

IdleMonitor.PropTypes = {
    time: PropTypes.number.isRequired,
    isAdmin: PropTypes.bool.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            logOut,
        },
        dispatch
    );
}

export default connect(null, mapDispatchToProps)(IdleMonitor);
