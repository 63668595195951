import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import EmployeeActionSelected from '../TablePagination/actionSelected/employeeActionSelected';

class SelectedRecords extends Component {

    constructor(props) {
        super(props);
    }

    shouldComponentUpdate (prevProps, prevState) {
        return _.size(_.get(prevProps, 'selected')) !== _.size(this.props.selected);
    }

    render() {
        const {
            selected,
            name,
            selection,
            fieldSelection
        } = this.props;
        const allowedAction = (_.isUndefined(selection) || _.isNull(selection) || _.isUndefined(fieldSelection) || _.isNull(fieldSelection))
                              ? false
                              : selection;
        if(allowedAction){
            switch (name) {
                case "employees":
                    return( <EmployeeActionSelected selected={selected} /> );
                default:
                    return( <div></div> );
            }
        } else {
            return ( <div></div> );
        }
    }
}

function mapStateToProps({ genericTablePagination }, ownProps) {
    const store = genericTablePagination.get(_.get(ownProps, 'coreName', 'default'));
    return {
        selected: store.get('selected'),
        fieldSelection: store.get('fieldSelection')
    };
}

SelectedRecords.propTypes = {
    name: PropTypes.string.isRequired,
    selection: PropTypes.bool,
    coreName: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(SelectedRecords);
