import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
    loadMetadata,
    resetState,
    sendMessage,
    selectItem,
    BASE_INDICATORS,
    BASE_QUESTIONS,
    ALL,
    loadProjectAndCompanyName
} from "./ProjectDucks";
import { Col, Row } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import _ from "lodash";
import ButtonBack from "../../components/fields/buttonBack/buttonBack";
import AuthComponent from "../AuthComponent";
import SarinCharter from "./SarinCharter";

class ProjectView extends Component {
    UNSAFE_componentWillMount() {
        this.props.resetState();
    }

    componentDidMount() {
        const { params: { projectId }, loadMetadata, loadProjectAndCompanyName } = this.props;
        loadMetadata(projectId);
        loadProjectAndCompanyName(projectId);
    }

    render() {
        const { metadata, project, params } = this.props;
        const projectName = _.get(project, "project", "");
        const businessName = _.get(project, "company", "");
        const records = _.get(metadata, "records", []);
        const filterProject = {
            item: "projectId",
            operator: "=",
            value: params.projectId,
            columnFamily: "metadata"
        };

        let urlBack = "";

        if (_.isEqual(_.get(params, "profile"), "adm") || _.isEqual(_.get(params, "profile"), "ref")) {
            urlBack = `/admin/projects/advance/${_.get(params, "profile")}/${_.get(params, "company")}/${_.get(
                params,
                "projectId"
            )}`;
        } else if (_.isEqual(_.get(params, "profile"), "comp")) {
            urlBack = `/dashboard/company/project/advance/${_.get(params, "profile")}/${_.get(
                params,
                "company"
            )}/${_.get(params, "projectId")}`;
        }

        return (
            <AuthComponent
                component={
                    <Row style={{ marginBottom: 20, marginTop: 20 }}>
                        <Col xs={12} mdOffset={1} md={10}>
                            <Paper style={{ width: "100%" }}>
                                <ButtonBack url={urlBack} />
                                <SarinCharter
                                    baseFilter={filterProject}
                                    title={`${businessName} - ${projectName}`}
                                    records={metadata}
                                    baseFilterItems={[BASE_QUESTIONS, BASE_INDICATORS]}
                                    allowedFilters={[ALL]}
                                    allowSelectType
                                    projectsId={[params.projectId]}
                                />
                            </Paper>
                        </Col>
                    </Row>
                }
                permission={"cgr_results_project_view"}
                type={"url"}
            />
        );
    }
}

const mapStateToProps = ({ projectReports }) => ({
    metadata: projectReports.get("metadata"),
    project: projectReports.get("project"),
    items: projectReports.get("toRender"),
    selectedItem: projectReports.get("selectedItem"),
    status: projectReports.get("status")
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            loadMetadata,
            resetState,
            sendMessage,
            selectItem,
            loadProjectAndCompanyName
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ProjectView);
