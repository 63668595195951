import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MenuItem from "material-ui/MenuItem";
import IndicatorComponent from "./originComponents/indicatorComponent";
import QuestionComponent from "./originComponents/questionComponent";
import SelectFieldFormat from "../../fields/SelectFieldFormat/SelectFieldFormat";
import { INDICATOR, QUESTION } from "../../analyticsReport/types";
import { updateComponent } from "../ducks";
import WaitComponent from "./waitComponent";

const styles = {
    box: {
        border: "0px solid #000",
        boxSizing: "border-box",
    },
};

class OriginDataComponent extends Component {
    static propTypes = {
        survey: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        valueTypeChart: PropTypes.string,
        loadingConfiguration: PropTypes.bool.isRequired,
    };

    onChangeValue = (e, index, value) => {
        const { updateComponent, survey, page, path } = this.props;
        updateComponent(`${path}.chartProps`, { chartType: value, items: [] }, page, survey);
    };

    renderOrigin = () => {
        const { survey, path, valueTypeChart } = this.props;
        switch (valueTypeChart) {
            case QUESTION:
                return <QuestionComponent survey={survey} path={`${path}.chartProps`} />;
            case INDICATOR:
                return <IndicatorComponent survey={survey} path={`${path}.chartProps`} />;
            default:
                return <span />;
        }
    };

    render() {
        const { valueTypeChart, loadingConfiguration } = this.props;
        if (loadingConfiguration) {
            return <WaitComponent />;
        }
        return (
            <div style={{ ...styles.box }}>
                <SelectFieldFormat
                    config={{
                        floatingLabelText: "Origen de datos",
                        value: valueTypeChart,
                        autoWidth: true,
                        onChange: this.onChangeValue,
                    }}
                >
                    <MenuItem value={QUESTION} primaryText={"Preguntas"} />
                    <MenuItem value={INDICATOR} primaryText={"Indicadores"} />
                </SelectFieldFormat>
                {this.renderOrigin()}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateComponent }, dispatch);
}

function mapStateToProps({ analyticsReportsConfiguration }, ownProps) {
    const config = analyticsReportsConfiguration.get("pageConfiguration");
    const loadingConfiguration = analyticsReportsConfiguration.get("loadingConfiguration");
    return {
        page: analyticsReportsConfiguration.get("page"),
        valueTypeChart: _.get(config, `${_.get(ownProps, "path")}.chartProps.chartType`),
        loadingConfiguration,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OriginDataComponent);
