import _ from "lodash";
import { is } from "immutable";
import { schemeCategory10 } from "d3";
import AnalyticsItem from "./AnalyticsItem";
import { getColorPaletter } from "../../lib/dataVisualization";
import {
    baseItems,
    ALL,
    GROUPERS,
    METADATA,
    QUESTIONS,
    SARIN_RESPONSE_FORBIDDEN,
    SARIN_RESPONSE_ERROR,
    SARIN_RESPONSE_UNKNOWN,
    SARIN_RESPONSE_EMPTY,
    SARIN_RESPONSE_UNAUTHORIZED,
    FETCHING_VALUES,
    NO_VALUE
} from "./ProjectDucks";

export function mapItemNames(data, filter) {
    if (filter !== null && filter !== undefined) {
        return _.chain(data)
            .filter(item => {
                if (filter === 1) {
                    return _.isEqual(item.type, "QUESTION");
                } else if (filter === 2) {
                    return _.isEqual(item.type, "GROUPER");
                }
                return false;
            })
            .sortBy(["priority", "title"])
            .value();
    }
    return [];
}

/**
 * Function to build an index to search the keys in of the metadata available.
 * @param data, the collection of the data.
 * @param path, the path to get que key value of the element.
 * @param fnKey a function to apply to the key element.
 */
export function buildItemIndex(data = [], path = "id", fnKey = _.identity) {
    return _.reduce(
        data,
        (acc, current) => {
            const currentOldKey = _.get(current, path);
            const currentKey = fnKey(currentOldKey);
            return _.set(acc, currentKey, current);
        },
        {}
    );
}

/**
 * Filter the elements from a collection of metadata items to only the ones needed.
 * @param data, collection of metadata items
 * @param currentItem, items to filter
 */
export function buildCollectionForFilters(data = [], currentItem) {
    return _.filter(data, item => {
        switch (currentItem) {
            case "tags":
                return _.isEqual(item.type, "TAG_LABEL");
            case "questions":
                return _.isEqual(item.type, "QUESTION");
            case "groupers":
                return _.isEqual(item.type, "GROUPER");
            default:
                return false;
        }
    });
}

export function filterItemsAndBuildMap(data = [], currentSelected = {}) {
    return _.chain(data)
        .filter(item => {
            const itemId = _.get(item, "id");
            return !_.isEqual(itemId, currentSelected);
        })
        .reduce((acc, current) => {
            const currentOldKey = _.get(current, "id");
            return _.set(acc, currentOldKey, current);
        }, {})
        .value();
}

export function mapColumnFamilyForFilter(columnFamily) {
    switch (columnFamily) {
        case "QUESTION":
            return "questions";
        case "GROUPER":
            return "groupers";
        case "TAG_LABEL":
        case "metadata":
            return "metadata";
        default:
            return undefined;
    }
}

export function mapItemType(type, isFreq) {
    if (isFreq) {
        return "freq_grouper";
    }
    switch (type) {
        case "QUESTION":
            return "question";
        case "GROUPER":
            return "sum_grouper";

        default:
            return undefined;
    }
}

export function buildFields(item = {}) {
    switch (item.type) {
        case "question":
            const itemQuestionId = _.split(item.id, "_")[0];
            return [
                {
                    name: itemQuestionId,
                    type: "STRING",
                    fieldFamily: "question",
                    alias: itemQuestionId
                }
            ];
        case "sum":
            return [
                {
                    name: item.id,
                    type: "STRING",
                    fieldFamily: "sum_grouper",
                    alias: item.id
                }
            ];
        case "freq":
            const subItems = _.get(item, "subItems", []);
            return _.chain(subItems)
                .uniqBy("id")
                .map(subItem => ({
                    name: `${item.id}_${subItem.id}`,
                    type: "STRING",
                    fieldFamily: "freq_grouper",
                    alias: subItem.id
                }))
                .value();
    }
}

export function mapSumParsers(item = {}) {
    if (item.type === "sum") {
        return _.map(item.subItems, subItem => ({
            label: subItem.item,
            upperLimit: subItem.initialValue,
            lowerLimit: subItem.endValue
        }));
    }
    return [];
}

const transposeFilter = {
    "=": "eq",
    "<>": "neq",
    ">": "gt",
    "<": "lt",
    ">=": "gte",
    "<=": "lte"
};

export function mapFilters(filters = [], defaultFilters = []) {
    if (_.size(filters) === 0) {
        return _.map(defaultFilters, filter => ({
            itemId: filter.item,
            operator: transposeFilter[filter.operator],
            value: filter.value,
            columnFamily: mapColumnFamilyForFilter(filter.columnFamily)
        })).map(filter => [filter]);
    }
    return _.chain(filters)
        .flatMap(item => {
            const filterEntity = _.get(item, "itemObj", {});
            const typeOfElementInFilter = item.columnFamily;
            if (_.isEqual(typeOfElementInFilter, "GROUPER") && _.split(filterEntity.id, "_").length !== 3) {
                const minValue = Math.min(filterEntity.upperLimit, filterEntity.lowerLimit);
                const maxValue = Math.max(filterEntity.upperLimit, filterEntity.lowerLimit);
                if (item.operator === "<>") {
                    const filtersToReturn = [
                        _.assign({}, item, {
                            operator: "<",
                            value: minValue,
                            condition: "OR"
                        }),
                        _.assign({}, item, {
                            operator: ">",
                            value: maxValue,
                            condition: "OR"
                        })
                    ];
                    return _.flatMap(filtersToReturn, innerFilter =>
                        defaultFilters.map(baseFilter => [baseFilter, innerFilter])
                    );
                }
                // Filter is equal
                return [
                    [
                        _.assign({}, item, {
                            operator: ">=",
                            value: minValue,
                            condition: "OR"
                        }),
                        _.assign({}, item, {
                            operator: "<=",
                            value: maxValue,
                            condition: "OR"
                        }),
                        ...defaultFilters
                    ]
                ];
            }
            return [[item, ...defaultFilters]];
        })
        .map(items =>
            items.map(item => _.omit(item, ["filterEntity", "itemObj"])).map(filter => ({
                itemId: filter.item,
                operator: transposeFilter[filter.operator],
                value: filter.value,
                columnFamily: mapColumnFamilyForFilter(filter.columnFamily)
            }))
        )
        .map(item => item)
        .value();
}

export function mapCategories(item = {}) {
    if (item.type === "freq") {
        return _.chain(item.subItems)
            .uniqBy("id")
            .map("id")
            .value();
    }
    return [];
}

function findItemsInMetadata(metadata = [], itemId) {
    return _.chain(metadata)
        .filter(item => _.isEqual(item.id, itemId))
        .value();
}

function mapSubItemToAnalyticsItem(coi = [], sarinObj = {}, subItem, color, fieldFamily) {
    const sarinValue = _.get(sarinObj, subItem, 0);
    const totalValue = _.chain(sarinObj)
        .values()
        .sum()
        .value();
    const coiObj = _.find(coi, itemCoi => {
        const initialValue = itemCoi.initialValue;
        const endValue = itemCoi.endValue;
        const minValue = Math.min(initialValue, endValue);
        const maxValue = Math.max(initialValue, endValue);
        const sarinValuePercentage = sarinValue / totalValue * 100;
        return sarinValuePercentage >= minValue && sarinValuePercentage <= maxValue;
    });

    return new AnalyticsItem({
        label: subItem,
        value: sarinValue,
        color: color || _.sample(schemeCategory10),
        details: [],
        type: fieldFamily,
        coi: _.get(coiObj, "label", "")
    });
}

function mapSubItemsToAnalyticsItems(item, sarinObj, fieldFamily, colorFn) {
    const subItems = item.subItems;
    return _.map(subItems, subItem =>
        mapSubItemToAnalyticsItem(subItem.coi, sarinObj, subItem.item, colorFn(subItem.color), fieldFamily)
    );
}

export function mapSarinItemToAnalyticsItem(sarinObj, index) {
    return new AnalyticsItem({
        label: sarinObj.name,
        value: sarinObj.value,
        color: _.get(sarinObj, "metadata.color", _.sample(schemeCategory10)),
        type: sarinObj.type,
        coi: sarinObj.metadata.coi,
        index: index + 1
    });
}

export function mapSarinResponseToMetadataObject(metadataRecords = [], sarinResponse = {}) {
    const sarinMetadata = _.get(sarinResponse, "metadata", []);
    const sarinObj = _.get(sarinResponse, "message", {});
    return _.chain(sarinMetadata)
        .flatMap(field => {
            const fieldFamily = field.fieldFamily;
            switch (fieldFamily) {
                case "question":
                    const itemQuestionId = `${field.name}_PROCESSED`;
                    return _.flatMap(findItemsInMetadata(metadataRecords, itemQuestionId), item =>
                        mapSubItemsToAnalyticsItems(
                            item,
                            sarinObj,
                            fieldFamily,
                            color => (_.isEqual(color, "random") ? getColorPaletter() : color)
                        )
                    );
                case "freq_grouper":
                    const fieldFreqId = _.split(field.name, "_")[0];
                    const itemFreq = _.find(metadataRecords, ["id", fieldFreqId]);
                    const coiItems = _.find(itemFreq.subItems, subItem => subItem.id === field.alias);
                    return mapSubItemToAnalyticsItem(
                        _.get(coiItems, "coi", []),
                        sarinObj,
                        field.alias,
                        getColorPaletter(),
                        fieldFamily
                    );
                case "sum_grouper":
                    return _.flatMap(findItemsInMetadata(metadataRecords, field.name), item =>
                        mapSubItemsToAnalyticsItems(item, sarinObj, fieldFamily, _.identity)
                    );
                default:
                    return [];
            }
        })
        .sortBy((item: AnalyticsItem) => item.getLabel())
        .map((item: AnalyticsItem, index: number) => item.setIndex(index + 1))
        .value();
}

export function addStrokeToItem(selected, data, type) {
    const same = is(data, selected);
    switch (type) {
        case "color":
            return same ? "gray" : "#fff";
        case "width":
            return same ? 3 : 1;
        default:
            return "";
    }
}

export function getCurrentTypeFilter(allowedFilters = []) {
    const noAll = allowedFilters.filter(item => item !== ALL);
    if (!_.includes(noAll, ALL) && _.size(allowedFilters) === 1) {
        switch (noAll[0]) {
            case QUESTIONS:
                return "questions";
            case GROUPERS:
                return "groupers";
            case METADATA:
                return "tags";
            default:
                return undefined;
        }
    } else {
        return undefined;
    }
}

export function validationBaseFilterItem(props, propName) {
    const value = props[propName];
    if (props.allowSelectType === false) {
        if (_.isNil(value)) {
            return new Error("Select type marked as false and no base filter passed to the component");
        } else if (!_.includes(baseItems, value)) {
            return new Error(`Base filter should be one of ${baseItems}`);
        }
    }
}

export function shouldPresentMessage(type) {
    const arrayMessages = [
        NO_VALUE,
        FETCHING_VALUES,
        SARIN_RESPONSE_ERROR,
        SARIN_RESPONSE_UNKNOWN,
        SARIN_RESPONSE_EMPTY,
        SARIN_RESPONSE_FORBIDDEN
    ];
    return _.indexOf(arrayMessages, type) > -1;
}

export function getSarinMessage(type) {
    const errMessage =
        "Ocurrió un error inesperado al consultar los resultados. Por favor, inténtalo de nuevo más tarde o comunícate con un administrador.";
    const objMessages = {
        [SARIN_RESPONSE_FORBIDDEN]:
            "Los filtros seleccionados dan un resultado menor o igual a 5 personas, por lo cual no pueden presentarse resultados.",
        [SARIN_RESPONSE_EMPTY]:
            "Los filtros seleccionados no presentan resultados, cambia los filtros e inténtalo de nuevo.",
        [SARIN_RESPONSE_UNKNOWN]: errMessage,
        [SARIN_RESPONSE_ERROR]: errMessage,
        [FETCHING_VALUES]: "Calculando valores...",
        [NO_VALUE]: "Seleccione un elemento a graficar para comenzar...",
        [SARIN_RESPONSE_UNAUTHORIZED]: "No tienes los permisos suficiente para consultar esta fuente de datos."
    };
    return objMessages[type];
}
