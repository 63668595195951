import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, addArrayValue } from 'redux-form';
import { Row, Col } from 'react-flexbox-grid';
import PureInput from '../../components/PureInput';
import IconButton from 'material-ui/IconButton';
import NoCollapseIcon from 'material-ui/svg-icons/navigation/arrow-drop-up';
import CollapseIcon from 'material-ui/svg-icons/navigation/arrow-drop-down';
import _ from 'lodash';
// import NoCollapseIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-right';
// import CollapseIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-down';

class QuestionMetricalItemsNews extends Component {

    constructor(props){
        super(props);
        this._changeVisibleItems = this._changeVisibleItems.bind(this);
        this.state = {
            visible: true
        };
    }

    _changeVisibleItems(){
        this.setState({
            visible: !this.state.visible
        });
    }

    render() {
        const {
            fields: {
                _id,
                title,
                type,
                lists
            }
        } = this.props;
        return (
            <Row>
                <Col xs={10} xsOffset={1} md={10} mdOffset={1}>
                    <Row style={{marginBottom: 10}}>
                        <Col xs={11}>
                            <h3 style={{width: '100%', minWidth: 280, textAlign: 'justify'}}>{title.value}</h3>
                        </Col>
                        <Col xs={1}>
                            <IconButton tooltip={this.state.visible ? "Ocultar" : "Mostrar"}
                                        style={{border:0,borderStyle:'dashed',borderColor:'#000',borderRadius:'100%'}}
                                        tooltipPosition="top-center"
                                        onClick={this._changeVisibleItems}
                                        children={this.state.visible
                                        ? <CollapseIcon/>
                                        : <NoCollapseIcon/>}
                            />
                        </Col>
                    </Row>
                    <Row style={{display: this.state.visible ? 'flex' : 'none'}}>
                        <Col xs>
                            {_.map(lists, (list, index) =>
                                <Row key={index}>
                                    <Col xs style={{paddingTop: 0, paddingBottom: 0, paddingLeft: 10, paddingRight: 10, minWidth: 190, width: '100%'}}>
                                        <h3 style={{fontWeight: 200}}>{list.value.value}</h3>
                                    </Col>
                                    <Col xs style={{paddingTop: 0, paddingBottom: 0, paddingLeft: 10, paddingRight: 10, minWidth: 110}}>
                                        <PureInput
                                            hintText="Valor"
                                            type="number"
                                            errorText={list.metrical.touched && list.metrical.error ? list.metrical.error : '' }
                                            field={list.metrical}
                                            style={{width: 120, marginTop: -8, marginBottom: 8, marginLeft: 10}}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

QuestionMetricalItemsNews.propTypes = {
    fields: PropTypes.object.isRequired
};

export default reduxForm({
    form: 'QuestionMetricalItemsNewsForm',
    fields: [
        '_id',
        'title',
        'type',
        'lists[]._id',
        'lists[].key',
        'lists[].value',
        'lists[].name',
        'lists[].metrical'
    ]
})( QuestionMetricalItemsNews );
