import React, { Component } from "react";
import PropTypes from "prop-types";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Editor, EditorState, getDefaultKeyBinding } from "draft-js";
import { blue700 } from "material-ui/styles/colors";
import _ from "lodash";
import {
    openDialogMessage,
    closeDialogMessage,
    sendFirstMessage,
} from "./ducks";

const { bool, func, object } = PropTypes;

class SendMessageDialog extends Component {
    static propTypes = {
        open: bool.isRequired,
        hasFirstMessage: bool.isRequired,
        socket: object,
        handleConnection: func.isRequired,
        closeConversation: func.isRequired,
        cleanConnection: func.isRequired,
    };

    static defaultProp = {
        socket: undefined,
    };

    state = {
        editorState: EditorState.createEmpty(),
    };

    handleOpen = () => {
        const { openDialogMessage } = this.props;
        openDialogMessage();
    };

    handleClose = () => {
        const { closeDialogMessage } = this.props;
        closeDialogMessage();
    };

    handlerChangeText = (editorState) => {
        this.setState({ editorState });
    };

    sendMessage = () => {
        const {
            socket,
            handleConnection,
            closeDialogMessage,
            sendFirstMessage,
            cleanConnection,
            closeConversation,
            isReinit,
        } = this.props;
        const { editorState } = this.state;
        const text = editorState.getCurrentContent().getLastBlock().getText();
        sendFirstMessage();
        if (_.isUndefined(socket) && !isReinit && !_.isEmpty(_.trim(text))) {
            handleConnection(text);
        }
        if (isReinit && !_.isEmpty(_.trim(text))) {
            cleanConnection();
            closeConversation();
            handleConnection(text);
        }
        this.setState({ editorState: EditorState.createEmpty() });
        closeDialogMessage();
    };

    render() {
        const { open, hasFirstMessage } = this.props;
        const { editorState } = this.state;
        const contentState = editorState.getCurrentContent();
        return (
            <Dialog
                actions={[
                    <FlatButton
                        label="Cerrar"
                        primary
                        onClick={this.handleClose}
                    />,
                    <FlatButton
                        label="Enviar"
                        primary
                        disabled={!contentState.hasText()}
                        onClick={this.sendMessage}
                    />,
                ]}
                modal={false}
                open={open && !hasFirstMessage}
                onRequestClose={this.handleClose}
                contentStyle={{ maxWidth: "450px", width: "100%" }}
                title={"¿En que podemos ayudarte?"}
                autoScrollBodyContent
                titleStyle={{ textAlign: "center", color: blue700 }}
            >
                <div style={{ padding: "15px 0 10px 0", height: 100 }}>
                    <Editor
                        editorState={editorState}
                        onChange={this.handlerChangeText}
                        placeholder={"Escribir mensaje..."}
                        keyBindingFn={(event) => {
                            if (
                                _.isEqual(event.keyCode, 13) &&
                                this.state.editorState
                                    .getCurrentContent()
                                    .hasText()
                            ) {
                                this.sendMessage();
                                return "send-message";
                            }
                            return getDefaultKeyBinding(event);
                        }}
                    />
                </div>
            </Dialog>
        );
    }
}

function mapStateToProps({ chatEmployee }) {
    return {
        open: chatEmployee.get("open"),
        hasFirstMessage: chatEmployee.get("hasFirstMessage"),
        isReinit: chatEmployee.get("isReinit"),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            openDialogMessage,
            closeDialogMessage,
            sendFirstMessage,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SendMessageDialog);
