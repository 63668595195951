import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Row, Col, Grid} from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import _ from 'lodash';
import {FetchSurveyOrderParam, FetchProjectSurveyOrderUserParam} from '../../actions/info/surveys_info_form';
import {setInitialLocationQuestionState} from './question/location/actions';
import {setInitialSection} from './question/actions';
import ControlsSection from '../fields/ControlsSection/ControlsSection';
import Question from './question';
import {idEmployeeTest} from '../../constants/labels';
import ModalExitSurvey from "../ModalExitSurvey/componentModal";
import {showModalExitSurvey} from "../ModalExitSurvey/actions";
import AuthComponent from '../AuthComponent';
import ButtonBack from '../fields/buttonBack/buttonBack';

const stylePaper = {
    marginTop: 80,
    marginBottom: 95,
    paddingBottom: 40
}; const ColsQuestions = {
    paddingBottom: 21,
    paddingRight: 14,
    paddingLeft: 14,
    minWidth: 320,
    border: 0,
    borderStyle: 'solid',
    borderColor: "#000"
};

let cs = 0;
const urlBack = `/admin/surveys/grid`;

class Survey extends Component {

    static childContextTypes = {
        router: PropTypes.object
    };

    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth
        };
        this.renderQuestions = this.renderQuestions.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    handleResize(e) {
        this.setState({windowWidth: window.innerWidth});
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    UNSAFE_componentWillMount() {
        const {
            FetchSurveyOrderParam,
            FetchProjectSurveyOrderUserParam,
            setInitialLocationQuestionState,
            params,
            login
        } = this.props;

        const userID = login.get('loggedIn') ? _.get(login.get('profile'), 'person._id') : idEmployeeTest;
        setInitialLocationQuestionState();
        if (_.isUndefined(params.project)) {
            FetchSurveyOrderParam(params.survey, params.order);
        } else {
            FetchProjectSurveyOrderUserParam(params.project, params.survey, params.order, userID);
        }

    }

    shouldComponentUpdate(nextProps, nextState) {
        const {
            setInitialSection,
            setInitialLocationQuestionState,
            surveys
        } = nextProps;

        if (_.isEqual(this.state.windowWidth, _.toInteger(window.innerWidth))) {
            setInitialSection(surveys.data.questions, validateQuestionsGroups(surveys.data.questions));
            setInitialLocationQuestionState();
        }

        return true;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {
            surveys,
            params,
            login
        } = nextProps;
        const {
            FetchSurveyOrderParam,
            FetchProjectSurveyOrderUserParam
        } = this.props;
        if (cs != parseInt(params.order)) {
            cs = parseInt(params.order);
            const userID = login.get('loggedIn') ? _.get(login.get('profile'), 'person._id') : idEmployeeTest;
            FetchSurveyOrderParam(params.survey, params.order);
            window.scrollTo(0, 0);
        }
    }

    renderDescription(textDescription) {
        let description = "";
        if (!_.isUndefined(textDescription) && !_.isEqual(textDescription, "")) {
            description = _.split(textDescription, /\r\n|\r|\n/g);
        }
        if (description instanceof Array) {
            return (
                <h3 style={{textAlign: 'justify', fontWeight: 200}}>
                    {description.map((value, index) => {
                        if (!_.isEqual(value, '')) {
                            return (
                                <div key={index}>{value}<br/><br/></div>
                            );
                        }
                    })}
                </h3>
            );
        }
            return (
                <h3 style={{textAlign: 'justify', fontWeight: 200}}>{description}</h3>
            );

    }

    renderQuestions(questions) {
        const params = _.get(this, 'props.params');
        let index = 0;
        const resultQuestion = [];
        for (let position = 0; position < _.size(questions); position++) {
            const qt = _.has(questions[position], 'fieldName') ? questions[position] : _.get(questions[position], '_id');
            let indextext = "0";
            let indextext2 = "0";
            if (qt.type === 'GroupClose' || qt.type === 'Group' || qt.type === 'GroupList') {
                indextext = `${index + 1  }-${  index + parseInt(_.get(qt, 'sizeList'))}`;
                index += parseInt(_.get(qt, 'sizeList'));
            } else {
                index += 1;
                indextext = index;
            }
            if (_.isEqual(_.get(qt, 'size'), 'long') || (qt.type === 'GroupClose' || qt.type === 'Group' || qt.type === 'GroupList')) {
                resultQuestion.push(
                    <Row key={position}>
                        <Col xs={12} md={12} style={ColsQuestions}>
                            <Question
                                question={questions[position]}
                                idProject={params.project}
                                idSurvey={params.survey}
                                idQuestion={_.get(questions[position], '_id')}
                                sectionOrder={params.order}
                                index={indextext}
                            />
                        </Col>
                    </Row>
                );
            } else if (position + 1 < _.size(questions)) {
                    const qt2 = _.has(questions[position + 1], 'fieldName') ? questions[position + 1] : _.get(questions[position + 1], '_id');
                    if (_.isEqual(_.get(qt2, 'size'), 'long')) {
                        resultQuestion.push(
                            <Row key={position}>
                                <Col xs md style={ColsQuestions}>
                                    <Question
                                        question={questions[position]}
                                        idProject={params.project}
                                        idSurvey={params.survey}
                                        idQuestion={_.get(questions[position], '_id')}
                                        sectionOrder={params.order}
                                        index={indextext}
                                    />
                                </Col>
                                <Col key={position} xs md style={ColsQuestions}>
                                    <div style={{minWidth: 320, height: 'auto', display: 'inline-block'}} />
                                </Col>
                            </Row>
                        );
                    } else {
                        if (qt2.type === 'GroupClose' || qt2.type === 'Group' || qt2.type === 'GroupList') {
                            indextext2 = `${index + 1  }-${  index + parseInt(qt2.sizeList)}`;
                            index += parseInt(qt2.sizeList);
                        } else {
                            index += 1;
                            indextext2 = index;
                        }
                        resultQuestion.push(
                            <Row key={position}>
                                <Col xs md style={ColsQuestions}>
                                    <Question
                                        question={questions[position]}
                                        idProject={params.project}
                                        idSurvey={params.survey}
                                        idQuestion={_.get(questions[position], '_id')}
                                        sectionOrder={params.order}
                                        index={indextext}
                                    />
                                </Col>
                                <Col key={position} xs md style={ColsQuestions}>
                                    <Question
                                        question={questions[position + 1]}
                                        idProject={params.project}
                                        idSurvey={params.survey}
                                        idQuestion={_.get(questions[position + 1], '_id')}
                                        sectionOrder={params.order}
                                        index={indextext2}
                                    />
                                </Col>
                            </Row>
                        );
                        position += 1;
                    }
                } else {
                    resultQuestion.push(
                        <Row key={position}>
                            <Col xs md style={ColsQuestions}>
                                <Question
                                    question={questions[position]}
                                    idProject={params.project}
                                    idSurvey={params.survey}
                                    idQuestion={_.get(questions[position], '_id')}
                                    sectionOrder={params.order}
                                    index={indextext}
                                />
                            </Col>
                            <Col key={position} xs md style={ColsQuestions}>
                                <div style={{minWidth: 320, height: 'auto', display: 'inline-block'}} />
                            </Col>
                        </Row>
                    );
                }
        }
        return resultQuestion;
    }

    render() {
        const {
            inactiveTime,
            params,
            login
        } = this.props;
        console.log("Section survey>>>>>", parseInt(this.props.params.order));

        if (!_.isNull(_.get(this, 'props.surveys.data')) && !_.isUndefined(_.get(this, 'props.surveys.data'))) {
            return (
                <Grid>
                    <ButtonBack url={urlBack}/>
                    <AuthComponent
                        component={
                            <Row>
                                <Col xs={12} mdOffset={2} md={8}>
                                    <Paper style={stylePaper}>
                                        <Row>
                                            <Col xs>
                                                <ControlsSection
                                                    idSurveys={this.props.params.survey}
                                                    currentSection={parseInt(this.props.params.order)}
                                                    URL={`/surveys/appl/${_.get(params, 'survey')}/`}
                                                    idProjects={_.get(params, 'project')}
                                                    isAdmin
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xsOffset={1} xs={10}>
                                                <h1 style={{
                                                    textAlign: 'center',
                                                    fontWeight: 400,
                                                    lineHeight: 1.5
                                                }}>{_.get(this, 'props.surveys.data.title')}</h1>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xsOffset={1} xs={10}>
                                                {this.renderDescription(_.get(this, 'props.surveys.data.description'))}
                                            </Col>
                                        </Row>
                                        <div style={{
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            paddingLeft: this.state.windowWidth < 501 ? 20 : this.state.windowWidth < 651 ? 35 : this.state.windowWidth < 801 ? 50 : this.state.windowWidth < 951 ? 60 : 70,
                                            paddingRight: this.state.windowWidth < 501 ? 20 : this.state.windowWidth < 651 ? 35 : this.state.windowWidth < 801 ? 50 : this.state.windowWidth < 951 ? 60 : 70
                                        }}>
                                            {this.renderQuestions(_.get(this, 'props.surveys.data.questions'))}
                                        </div>
                                    </Paper>
                                </Col>
                            </Row>
                        }
                        permission="cgr_survey_preview"
                        type="url"
                    />
                    <ModalExitSurvey/>
                </Grid>
            );
        }
            return (
                <div />
            );

    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        FetchSurveyOrderParam,
        FetchProjectSurveyOrderUserParam,
        setInitialSection,
        setInitialLocationQuestionState,
        showModalExitSurvey
    }, dispatch);
}

function mapStateToProps({surveys, login}) {
    return {
        inactiveTime: _.isUndefined(_.get(surveys, 'survey.data.project.inactive')) ? 20 : _.get(surveys, 'survey.data.project.inactive'),
        surveys: _.isUndefined(surveys.survey) ? null : surveys.survey,
        login
    };
}

function validateQuestionsGroups(arrayQuestion) {
    let questionsGroups = 0;
    _.map(arrayQuestion, (item) => {
        switch (item.type) {
            case "GroupList":
                if (_.isEqual(typeof item.sizeList, 'string')) {
                    questionsGroups += _.size(_.get(item, 'valueSource.groupList.name'));
                } else if (_.isEqual(typeof item.sizeList, 'number')) {
                    questionsGroups += item.sizeList;
                }
                break;
            case "Group":
                if (_.isEqual(typeof item.sizeList, 'string')) {
                    questionsGroups += _.size(_.get(item, 'valueSource.group.name'));
                } else if (_.isEqual(typeof item.sizeList, 'number')) {
                    questionsGroups += item.sizeList;
                }
                break;
            case "GroupClose":
                if (_.isEqual(typeof item.sizeList, 'string')) {
                    questionsGroups += _.size(_.get(item, 'valueSource.groupClose.name'));
                } else if (_.isEqual(typeof item.sizeList, 'number')) {
                    questionsGroups += item.sizeList;
                }
                break;
            default:
                questionsGroups++;
                break;
        }
    });
    return questionsGroups;
}

export default connect(mapStateToProps, mapDispatchToProps)(Survey);
