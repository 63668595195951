import _ from "lodash";

const MIN = 15;
const MAX = 80;
const MIN_VALUES = 4;
const MAX_VALUES = 7;
const WORDS = [
    "lorem",
    "ipsum",
    "dolor",
    "sit",
    "amet",
    "consectetur",
    "adipiscing",
    "elit",
    "curabitur",
    "vel",
    "hendrerit",
    "libero",
    "eleifend",
    "blandit",
    "nunc",
    "ornare",
    "odio",
    "ut",
    "orci",
    "gravida",
    "imperdiet",
    "nullam",
    "purus",
    "lacinia",
    "a",
    "pretium",
    "quis",
    "congue",
    "praesent",
    "sagittis",
    "laoreet",
    "auctor",
    "mauris",
    "non",
    "velit",
    "eros",
    "dictum",
    "proin",
    "accumsan",
    "sapien",
    "nec",
    "massa",
    "volutpat",
    "venenatis",
    "sed",
    "eu",
    "molestie",
    "lacus",
    "quisque",
    "porttitor",
    "ligula",
    "dui",
    "mollis",
    "tempus",
    "at",
    "magna",
    "vestibulum",
    "turpis",
    "ac",
    "diam",
    "tincidunt",
    "id",
    "condimentum",
    "enim",
    "sodales",
    "in",
    "hac",
    "habitasse",
    "platea",
    "dictumst",
    "aenean",
    "neque",
    "fusce",
    "augue",
    "leo",
    "eget",
    "semper",
    "mattis",
    "tortor",
    "scelerisque",
    "nulla",
    "interdum",
    "tellus",
    "malesuada",
    "rhoncus",
    "porta",
    "sem",
    "aliquet",
    "et",
    "nam",
    "suspendisse",
    "potenti",
    "vivamus",
    "luctus",
    "fringilla",
    "erat",
    "donec",
    "justo",
    "vehicula",
    "ultricies",
    "varius",
    "ante",
    "primis",
    "faucibus",
    "ultrices",
    "posuere",
    "cubilia",
    "curae",
    "etiam",
    "cursus",
    "aliquam",
    "quam",
    "dapibus",
    "nisl",
    "feugiat",
    "egestas",
    "class",
    "aptent",
    "taciti",
    "sociosqu",
    "ad",
    "litora",
    "torquent",
    "per",
    "conubia",
    "nostra",
    "inceptos",
    "himenaeos",
    "phasellus",
    "nibh",
    "pulvinar",
    "vitae",
    "urna",
    "iaculis",
    "lobortis",
    "nisi",
    "viverra",
    "arcu",
    "morbi",
    "pellentesque",
    "metus",
    "commodo",
    "ut",
    "facilisis",
    "felis",
    "tristique",
    "ullamcorper",
    "placerat",
    "aenean",
    "convallis",
    "sollicitudin",
    "integer",
    "rutrum",
    "duis",
    "est",
    "etiam",
    "bibendum",
    "donec",
    "pharetra",
    "vulputate",
    "maecenas",
    "mi",
    "fermentum",
    "consequat",
    "suscipit",
    "aliquam",
    "habitant",
    "senectus",
    "netus",
    "fames",
    "quisque",
    "euismod",
    "curabitur",
    "lectus",
    "elementum",
    "tempor",
    "risus",
    "cras",
];

function getRandomNumber() {
    return Math.floor(Math.random() * (MAX - MIN)) + MIN;
}

function getRandomNumberMax(max) {
    return Math.floor(Math.random() * max);
}

export function getNumberValues() {
    return Math.floor(Math.random() * (MAX_VALUES - MIN_VALUES)) + MIN_VALUES;
}

export function getPencetage(value, total) {
    const v = value * 100 / total;
    return v.toFixed(1);
}

function getValues(count) {
    const array = [];
    let sumTotal = 0;
    for (let i = 0; i < count; i++) {
        const numberRandom = getRandomNumber();
        sumTotal += numberRandom;
        array.push(numberRandom);
    }
    return _.map(array, value => _.toNumber(getPencetage(value, sumTotal)));
}

function getValueStacked(keys, index) {
    const valuesStacked = _.reduce(keys, (sumKey, key) => _.set(sumKey, key, getRandomNumber()), {});
    const total = _.reduce(_.keys(valuesStacked), (sum, v) => sum + _.get(valuesStacked, v, 0), 0);
    const updateValuesStacked = _.reduce(
        keys,
        (sumData, key) => {
            const value = _.toNumber(getPencetage(_.get(valuesStacked, key, 0), total));
            return _.set(sumData, key, value);
        },
        {},
    );
    const data = {
        index: `#${index}`,
        textIndex: `valor ${index}`,
        total: 100,
    };
    return _.assign(data, updateValuesStacked);
}

export function generateData(COLORS, count) {
    const values = getValues(count);
    const total = _.reduce(values, (sum, v) => sum + v, 0);
    return _.map(values, (value, index) => ({
        value,
        color: _.get(COLORS, index, "#FFF"),
        index: `${getPencetage(value, total)}%`,
        textIndex: `${getPencetage(value, total)}%`,
    }));
}

export function generateDataStacked(COLORS, count) {
    const values = getValues(count);
    const keysStacked = _.map(values, (value, index) => ({
        key: `key_${index}`,
        color: _.get(COLORS, index, "#FFF"),
        text: `valor ${index + 1}`,
    }));
    const keys = _.map(keysStacked, key => _.get(key, "key"));
    const dataStacked = [getValueStacked(keys, 1)];
    return {
        keysStacked,
        dataStacked,
    };
}

export function generateDataMultiStacked(COLORS, count, numValues) {
    const values = getValues(count);
    const valuesGenerate = getValues(numValues);
    const keysStacked = _.map(values, (value, index) => ({
        key: `key_${index}`,
        color: _.get(COLORS, index, "#FFF"),
        text: `valor ${index + 1}`,
    }));
    const keys = _.map(keysStacked, key => _.get(key, "key"));
    const dataStacked = _.map(valuesGenerate, (v, i) => getValueStacked(keys, i + 1));
    return {
        keysStacked,
        dataStacked,
    };
}

export function getTextRandom(count) {
    const array = [];
    const maxValues = _.size(WORDS);
    for (let i = 0; i < count; i++) {
        array.push(getRandomNumberMax(maxValues));
    }
    return _.reduce(array, (sum, value) => `${sum} ${WORDS[value]}`, "");
}
