import PropTypes from "prop-types";
import React, { Component } from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { blue700 } from "material-ui/styles/colors";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { showModalExitSurvey } from "./actions";
import { LBLSURVEYS } from "../../constants/labels";

class ExitSurveyModal extends Component {
    constructor(props) {
        super(props);
        this._handleExitAction = this._handleExitAction.bind(this);
    }
    _handleExitAction() {
        const { showModalExitSurvey } = this.props;
        showModalExitSurvey(false);
        browserHistory.push(`/dashboard/employee`);
    }

    render() {
        const { showModal } = this.props;
        const actions = [
            <FlatButton
                label="Aceptar"
                primary={true}
                keyboardFocused={true}
                onClick={this._handleExitAction}
            />,
        ];

        return (
            <Dialog
                title={LBLSURVEYS.surveyValidate.titleModal}
                actions={actions}
                titleStyle={{ textAlign: "center", color: blue700 }}
                contentStyle={{
                    maxWidth: "none",
                    width: "100%",
                    textAlign: "justify",
                }}
                modal={true}
                open={showModal}
                onRequestClose={this._handleExitAction}
            >
                <h3 style={{ fontWeight: 200, textAlign: "center" }}>
                    {LBLSURVEYS.surveyValidate.messageModal}
                </h3>
            </Dialog>
        );
    }
}

function mapStateToProps({ modalExitSurvey }) {
    return {
        showModal: _.get(modalExitSurvey, "modalExitOpen"),
    };
}

export default connect(mapStateToProps, { showModalExitSurvey })(
    ExitSurveyModal
);
