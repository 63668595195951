import React, {Component} from 'react';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import {Row, Col} from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';
import {TableFooter} from 'material-ui/Table';
import TextField from 'material-ui/TextField';
import Toggle from 'material-ui/Toggle';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import { FetchSection } from '../../actions/grid/sections_grid_form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {blue700} from 'material-ui/styles/colors';
import _ from 'lodash';

const style = {
        margin: 20,
        float: 'right'
    },
    stylePaper = {
        marginTop: 20,
        marginBottom: 20
    };

const stylePopover = {
    padding: 20,
};

class SectionsGrid extends Component {
    UNSAFE_componentWillMount(){
        this.props.FetchSection();
    }
    renderSections(){
        if(_.get(this, 'props.sections.data')){
            return this.props.sections.data.map((section) => {
                return (
                    <TableRow key={section._id}>
                        <TableRowColumn>{section.title}</TableRowColumn>
                        <TableRowColumn>{section.order}</TableRowColumn>
                    </TableRow>
                );
            });
        }
    }
    render() {
        return (
          <Row>
              <Col xsOffset={2} xs={8}>
                  <Paper style={stylePaper}>
                      <Row>
                          <Col xs>
                              <div style={{width: '100%', background: blue700, paddingTop: 5, paddingBottom:5, color: "#FFF"}}>
                                  <h1 style={{textAlign: 'center', fontWeight: 400}}>Secciones registradas</h1>
                              </div>
                              <Table selectable={false}>
                                  <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                                      <TableRow>
                                          <TableHeaderColumn>Título</TableHeaderColumn>
                                          <TableHeaderColumn>Orden</TableHeaderColumn>
                                      </TableRow>
                                  </TableHeader>
                                  <TableBody displayRowCheckbox={false}>
                                      {this.renderSections()}
                                  </TableBody>
                              </Table>
                          </Col>
                      </Row>
                  </Paper>
              </Col>
          </Row>
        );
    }
}

function mapStateToProps(state){
    return  { sections: state.sections.all };
}

export default connect (mapStateToProps, { FetchSection })(SectionsGrid);
