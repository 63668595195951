import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Table, TableBody, TableHeader, TableRow, TableRowColumn, TableHeaderColumn} from 'material-ui/Table';
import AnalyticsItem from './AnalyticsItem';
import numeral from 'numeral';
import {is} from 'immutable';
import Coi from './coiComponent';

const renderRow = (index, color, value, label, count, key, selected, coi) => {
    return (
        <TableRow key={key} selected={selected}>
            <TableRowColumn style={{width:40}}>{index}</TableRowColumn>
            <TableRowColumn style={{width:40}}>
                <div style={{width:30, height: 30, backgroundColor: color}}></div>
            </TableRowColumn>
            <TableRowColumn>{value}</TableRowColumn>
            <TableRowColumn>{count}</TableRowColumn>
            <TableRowColumn style={{whiteSpace: 'normal'}}>{label}</TableRowColumn>
            <TableRowColumn style={{whiteSpace: 'normal'}}><Coi coi={coi}/></TableRowColumn>
        </TableRow>
    );
};

class TableComponent extends Component {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        data: PropTypes.arrayOf(AnalyticsItem).isRequired,
        selectedItem: PropTypes.instanceOf(AnalyticsItem),
        onClickRow: PropTypes.func
    };


    mapData = (total, current, item: AnalyticsItem, idx) => {
        const selected = is(item, current);
        const currentValue = item.getValue() / total;
        const multipliedValue = currentValue * 100;
        const value = (multipliedValue % 1 != 0 ? numeral(multipliedValue).format('0.00') : multipliedValue) + '%';
        const coi = item.coi;
        return renderRow(item.getIndex(), item.getColor(), value, item.getLabel(), item.getValue(), idx, selected, coi);
    };

    getTotal = (acc: Number, current: AnalyticsItem) => {
        return acc + current.getValue();
    };

    render() {
        const {data, selectedItem, onClickRow} = this.props;
        const total = data.reduce(this.getTotal, 0);
        return (
            <Table onRowSelection={function(items){
                const item = items[0];
                const element = data[item];
                onClickRow(element);
            }}>
                <TableHeader
                    displaySelectAll={false}
                    adjustForCheckbox={false}
                    enableSelectAll={false}
                >
                    <TableRow>
                        <TableHeaderColumn style={{width:40}}>#</TableHeaderColumn>
                        <TableHeaderColumn style={{width:40}}>Color</TableHeaderColumn>
                        <TableHeaderColumn>Valor</TableHeaderColumn>
                        <TableHeaderColumn>Cantidad</TableHeaderColumn>
                        <TableHeaderColumn>Etiqueta</TableHeaderColumn>
                        <TableHeaderColumn>Condición de interpretación</TableHeaderColumn>
                    </TableRow>

                </TableHeader>
                <TableBody
                    displayRowCheckbox={false}
                >
                    {data.map(this.mapData.bind(undefined, total, selectedItem))}
                </TableBody>
            </Table>
        );
    }
}

export default TableComponent;
