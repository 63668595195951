import axios from "axios";
import _ from "lodash";
import { List } from "immutable";
import { API_URL, API_URL_V2 } from "../constants/index";
import { store } from "../index";
import { toggleSnackbar } from "../actions/commons";

const { localStorage } = window;

let usedTokens = List();

const config = {
    baseURL: API_URL,
    timeout: 50500,
    headers: {
        authorization: _.get(localStorage, "authToken"),
    },
};

const config_V2 = {
    baseURL: API_URL_V2,
    timeout: 50500,
    headers: {
        authorization: _.get(localStorage, "authToken"),
    },
};

const configAuth = {
    baseURL: API_URL,
};

function equalTokens(newOne) {
    return _.isEqual(localStorage.getItem("authToken"), newOne);
}

function isFromAuthentication(path) {
    return _.endsWith(path, "users/authentication");
}

export const axiosAPI = axios.create(config);
export const axiosAuth = axios.create(configAuth);
export const axiosAPI_V2 = axios.create(config_V2);

function onFulfilled(response) {
    const tokenHeader = _.get(response, "headers.x-token");
    const tokenResponse = _.get(response, "data.token");
    const currentState = _.get(response, "status");
    const url = _.get(response, "config.url");
    const localStorageToken = localStorage.getItem("authToken");

    if (
        currentState === 200 &&
        !_.isNil(tokenResponse) &&
        _.endsWith(url, "users/authentication")
    ) {
        usedTokens = usedTokens.push(tokenResponse);
    }

    if (
        !_.isNil(tokenHeader) &&
        !_.isEqual(localStorageToken, tokenHeader) &&
        !usedTokens.contains(tokenHeader)
    ) {
        localStorage.setItem("authToken", tokenHeader);
    }

    return response;
}

function onRejected(err) {
    const errorObject = { ...err };
    const requestToken = _.get(errorObject, "config.headers.authorization");
    const errorStatus = _.get(errorObject, "response.status");
    const url = _.get(errorObject, "config.url");
    const sameToken = !equalTokens(requestToken);
    const fromAuthentication = isFromAuthentication(url);
    if (errorStatus === 401) {
        if (fromAuthentication) {
            return Promise.reject(err);
        }
        if (sameToken) {
            return axios.request(
                _.set(
                    _.get(errorObject, "config"),
                    "headers.authorization",
                    localStorage.getItem("authToken")
                )
            );
        }
        store.dispatch(
            toggleSnackbar(
                true,
                "Parece que tu sesión ha caducado. Ingresa de nuevo para continuar."
            )
        );
        return Promise.reject(err);
    }
    return Promise.reject(err);
}

axiosAPI.interceptors.response.use(onFulfilled, onRejected);
axiosAPI_V2.interceptors.response.use(onFulfilled, onRejected);
axiosAuth.interceptors.response.use(onFulfilled, onRejected);
