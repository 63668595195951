import React, {PureComponent} from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import Types from "../dragComponentTypes";
import {INDICATOR, QUESTION} from "../../analyticsReport/types";
import SelectComponent from "../propComponents/styleContent/selectComponent";
import CheckComponent from "../propComponents/styleContent/checkComponent";
import OriginDataComponent from "../propComponents/originDataComponent";
import ContentEdit from "../propComponents/styleContentComponent";
import RichTextComponent from "../propComponents/styleContent/RichTextComponent";
import {arraySizeObjectChart, sizeTypes, arrayObjectChart, chartTypes} from "../../reportComponents/types";

const styles = {
    box: {
        boxSizing: "border-box",
        padding: 10,
    },
    title: {
        fontWeight: 400,
        textAlign: "center",
        backgroundColor: "#ADADAD",
        color: "#FFF",
        margin: 0,
        padding: "12px 5px",
        width: "100%",
        boxSizing: "border-box",
    },
    boxError: {
        color: "#CCC",
        padding: 20,
        boxSizing: "border-box",
        textAlign: "center",
    },
};

class ElementConfiguration extends PureComponent {
    static propTypes = {
        survey: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        propElement: PropTypes.any.isRequired,
    };

    renderProps = () => {
        const {propElement, survey, path} = this.props;
        switch (_.get(propElement, "props.type")) {
            case Types.CHART:
                switch (_.get(propElement, "props.chartProps.chartType")) {
                    case INDICATOR:
                    case QUESTION:
                        return (
                            <div>
                                <h3 style={{...styles.title}}>Origen de datos</h3>
                                <div style={{...styles.box}}>
                                    <OriginDataComponent survey={survey} path={path}/>
                                </div>
                            </div>
                        );
                    default:
                        return <span/>;
                }
            case Types.TEXT:
                return (
                    <div style={{...styles.box}}>
                        <RichTextComponent survey={survey} path={path} height={400}/>
                    </div>
                );
            default:
                return <span/>;
        }
    };

    renderPropsDetail = () => {
        const {propElement, survey, path} = this.props;
        const chartType = _.get(propElement, "props.chartProps.chartType");
        const title = `Visualizar el botón de "Ver detalle"`;
        const fieldProp = "hasDetail";
        const pathValue = `${path}.chartProps`;
        switch (chartType) {
            case Types.DEMOGRAPHIC_CHART:
            case INDICATOR:
                return (
                    <CheckComponent
                        survey={survey}
                        path={pathValue}
                        fieldProp={fieldProp}
                        title={title}
                        defaultValue={false}
                    />
                );
            default:
                if (_.isEqual(_.get(propElement, "props.type"), Types.RANKING)) {
                    return (
                        <CheckComponent
                            survey={survey}
                            path={pathValue}
                            fieldProp={fieldProp}
                            title={title}
                            defaultValue={false}
                        />
                    );
                }
                return <span/>;
        }
    };

    renderDefaultProps = () => {
        const {propElement, survey, path} = this.props;
        switch (_.get(propElement, "props.type")) {
            case Types.CHART:
                return (
                    <div>
                        <SelectComponent
                            survey={survey}
                            path={`${path}.chartProps`}
                            fieldProp={"size"}
                            title={"Tamaño de la gráfica"}
                            defaultValue={sizeTypes.SMALL}
                            array={arraySizeObjectChart}
                        />
                        <SelectComponent
                            survey={survey}
                            path={`${path}.chartProps`}
                            fieldProp={"chartPresentationType"}
                            title={"Tipo de gráfica"}
                            defaultValue={chartTypes.PIE}
                            array={arrayObjectChart}
                        />
                        {this.renderPropsDetail()}
                    </div>
                );
            case Types.RANKING:
                return this.renderPropsDetail();
            default:
                return <span/>;
        }
    };

    render() {
        const {survey, path} = this.props;
        return (
            <div>
                <div style={{...styles.box}}>{this.renderDefaultProps()}</div>
                {this.renderProps()}
                <h3 style={{...styles.title}}>Bordes del contenido</h3>
                <div style={{...styles.box}}>
                    <ContentEdit survey={survey} path={path}/>
                </div>
            </div>
        );
    }
}

export default ElementConfiguration;
