import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Row, Col } from 'react-flexbox-grid';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import { blue700, pinkA200, green700, green200, grey300 } from 'material-ui/styles/colors';
import RaisedButton from 'material-ui/RaisedButton';
import IconButton from 'material-ui/IconButton';
import NoCollapseIcon from 'material-ui/svg-icons/navigation/arrow-drop-up';
import CollapseIcon from 'material-ui/svg-icons/navigation/arrow-drop-down';
import GenericTablePagination from '../../../GenericTablePagination/genericTablePagination';
import { cleanFilterCompanies } from '../../../FilterCompanies/actions';
import FilterCompanies from '../../../FilterCompanies/FilterCompanies';

class SelectCompanies extends Component {

    constructor(props) {
        super(props);
        this._changeVisibleItems = this._changeVisibleItems.bind(this);
        this.state = {
            visible: true
        };
    }

    UNSAFE_componentWillMount() {
        const { cleanFilterCompanies } = this.props;
        cleanFilterCompanies();
    }

    _changeVisibleItems(){
        this.setState({ visible: !this.state.visible });
    }

    render() {
        const { config, arlId, economicActivity, employeesNum, country, department, city } = this.props;
        const body = [
            {
                field: "nit",
                title: "NIT",
                filter: true,
            },
            {
                field: "businessName",
                title: "Razón social",
                colspan: 3,
                filter: true,
            }
        ];

        let extraData = { arlId: arlId };
        if(!_.isEqual(economicActivity, 'all') && !_.isNull(economicActivity) && !_.isUndefined(economicActivity)){
            extraData = _.set(extraData, 'economicActivity', economicActivity);
        }
        if(!_.isEqual(employeesNum, 'all') && !_.isNull(employeesNum) && !_.isUndefined(employeesNum)){
            extraData = _.set(extraData, 'numEmployees', employeesNum);
        }
        if(!_.isEqual(country, 'all') && !_.isNull(country) && !_.isUndefined(country)){
            extraData = _.set(extraData, 'country', country);
            if(!_.isEqual(department, 'all') && !_.isNull(department) && !_.isUndefined(department)){
                extraData = _.set(extraData, 'department', department);
                if(!_.isEqual(city, 'all') && !_.isNull(city) && !_.isUndefined(city)){
                    extraData = _.set(extraData, 'city', city);
                }
            }
        }
        
        return (
            <div>
                <Row style={{display: this.state.visible ? 'flex' : 'none'}}>
                    <Col xs>
                        <FilterCompanies
                            URL={`/arl/companies/list`}
                            version={1}
                            extra={extraData}
                            body={body}
                            isGeneric={true}
                            coreName={"companies"}
                        />
                    </Col>
                </Row>
                <Row style={{display: this.state.visible ? 'flex' : 'none'}}>
                    <Col xs>
                        <GenericTablePagination
                            name={`companies`}
                            URL={`/arl/companies/list`}
                            version={1}
                            body={body}
                            coreName={"companies"}
                            extra={extraData}
                            selection={true}
                            fieldSelection={"_id"}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        cleanFilterCompanies
    }, dispatch);
}

function mapStateToProps({ filterCompany, arlDashboard }) {
    return {
        economicActivity: filterCompany.get("economicActivity"),
        employeesNum: filterCompany.get("employeesNum"),
        country: filterCompany.get("country"),
        department: filterCompany.get("department"),
        city: filterCompany.get("city"),
        arlId: arlDashboard.get('arlId')
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectCompanies);
