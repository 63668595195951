import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import RaisedButton from "material-ui/RaisedButton";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router";
import _ from "lodash";
import { blue600 } from "material-ui/styles/colors";
import CircularProgress from "material-ui/CircularProgress";
import FloatingActionButtonTooltip from "../fields/FloatingActionButtonTooltip/FloatingActionButtonTooltip";
import { toggleDeleteModal } from "../../actions/commons";
import {
    FetchCompanyParam,
    clearCompanyParam,
    cleanProject,
} from "../../actions/info/companies_info_form";
import { LBLCOMPANIES, LBLPROJECTS } from "../../constants/labels";
import ButtonBack from "../../components/fields/buttonBack/buttonBack";
import { openModalSendEmail } from "../ModalSendEmail/action";
import SendMessageModal from "../ModalSendEmail/modalSendEmail";
import AuthComponent from "../AuthComponent";
import TablePagination from "../TablePagination/tablePagination";

const styles = {
    stylePaper: {
        marginTop: 20,
        marginBottom: 20,
    },
    styleTable: {
        margin: "auto",
        width: "90%",
    },
};

class CompaniesInfo extends Component {
    UNSAFE_componentWillMount() {
        const { FetchCompanyParam, clearCompanyParam, params } = this.props;
        clearCompanyParam();
        FetchCompanyParam(_.get(params, "_id"));
    }

    handleModalSendEmail = (user) => {
        const { openModalSendEmail } = this.props;
        const userData = _.omit(user, ["_id"]);
        const userUpdate = _.set(
            userData,
            "_id",
            _.get(userData, "person._id")
        );
        openModalSendEmail([userUpdate], "company");
    };

    renderCompany() {
        const { params, companies } = this.props;
        if (!_.isNull(_.get(companies, "data", null))) {
            const company = _.get(companies, "data");
            return (
                <div>
                    <div
                        style={{
                            width: "100%",
                            background: blue600,
                            paddingTop: 5,
                            paddingBottom: 5,
                            marginBottom: 25,
                            color: "#FFF",
                        }}
                    >
                        <h1
                            style={{
                                textAlign: "center",
                                fontWeight: 400,
                                lineHeight: 1.3,
                            }}
                        >
                            {company.businessName}
                        </h1>
                    </div>
                    <ButtonBack url="/admin/companies/grid" />
                    <table style={{ ...styles.styleTable }}>
                        <tbody>
                            <tr>
                                <td>
                                    <table
                                        style={{ margin: "auto", width: "90%" }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td
                                                    style={{
                                                        minWidth: 100,
                                                        maxWidth: 200,
                                                        width: 200,
                                                    }}
                                                >
                                                    <strong>
                                                        {LBLCOMPANIES.nit}
                                                    </strong>
                                                </td>
                                                <td>{_.get(company, "nit")}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        {
                                                            LBLCOMPANIES.abbreviation
                                                        }
                                                    </strong>
                                                </td>
                                                <td>
                                                    {_.get(company, "acronym")}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        {LBLCOMPANIES.phone}
                                                    </strong>
                                                </td>
                                                <td>
                                                    {_.get(company, "phone")}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        {LBLCOMPANIES.email}
                                                    </strong>
                                                </td>
                                                <td>
                                                    {_.get(company, "email")}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        {LBLCOMPANIES.address}
                                                    </strong>
                                                </td>
                                                <td>
                                                    {_.get(company, "address")}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        {
                                                            LBLCOMPANIES.ActivityEconomic
                                                        }
                                                    </strong>
                                                </td>
                                                <td>
                                                    {_.get(
                                                        company,
                                                        "activityEconomic",
                                                        company.economicActivity
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        {
                                                            LBLCOMPANIES.numberEmployees
                                                        }
                                                    </strong>
                                                </td>
                                                <td>
                                                    {_.get(
                                                        company,
                                                        "employeesNum",
                                                        company.numEmployees
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        {
                                                            LBLCOMPANIES.nameContact
                                                        }
                                                    </strong>
                                                </td>
                                                <td>
                                                    {_.get(
                                                        company,
                                                        "nameContact"
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        {
                                                            LBLCOMPANIES.phoneContact
                                                        }
                                                    </strong>
                                                </td>
                                                <td>
                                                    {_.get(
                                                        company,
                                                        "phoneContact"
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <strong>
                                                        {
                                                            LBLCOMPANIES.emailContact
                                                        }
                                                    </strong>
                                                </td>
                                                <td>
                                                    {_.get(
                                                        company,
                                                        "emailContact"
                                                    )}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <AuthComponent
                                            component={
                                                <Link
                                                    to={`/admin/employees/grid/adm/${_.get(
                                                        params,
                                                        "_id"
                                                    )}`}
                                                >
                                                    <RaisedButton
                                                        label={
                                                            LBLCOMPANIES.buttons
                                                                .listEmployess
                                                        }
                                                        secondary
                                                        style={{
                                                            margin: 10,
                                                            display:
                                                                "inline-block",
                                                        }}
                                                    />
                                                </Link>
                                            }
                                            permission={"cgr_employee_list"}
                                            type={"component"}
                                        />
                                        <AuthComponent
                                            component={
                                                <RaisedButton
                                                    label="Enviar usuario a empresa"
                                                    secondary
                                                    onClick={this.handleModalSendEmail.bind(
                                                        this,
                                                        _.get(
                                                            company,
                                                            "userCompany"
                                                        )
                                                    )}
                                                    style={{
                                                        margin: 10,
                                                        display: "inline-block",
                                                    }}
                                                />
                                            }
                                            permission={"cgr_users_send_email"}
                                            type={"component"}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div>
                        <div>
                            <h2
                                style={{ textAlign: "center", fontWeight: 400 }}
                            >
                                {LBLPROJECTS.titleGrid}
                            </h2>
                            <AuthComponent
                                component={
                                    <Link
                                        to={`/admin/projects/create/${_.get(
                                            params,
                                            "_id"
                                        )}`}
                                    >
                                        <FloatingActionButtonTooltip
                                            textTooltip={
                                                LBLPROJECTS.tooltips.btnCreate
                                            }
                                        />
                                    </Link>
                                }
                                permission={"cgr_project_create"}
                                type={"component"}
                            />
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div>
                <Row>
                    <Col xs style={{ textAlign: "center" }}>
                        <ButtonBack url="/admin/companies/grid" />
                        <CircularProgress
                            size={55}
                            thickness={4}
                            style={{ margin: "30px auto" }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }

    render() {
        const { params, companies } = this.props;
        const body = [
            {
                field: "name",
                title: "Nombre",
                filter: true,
                colspan: 2,
            },
            {
                field: "time.openDate",
                title: "Fecha inicio",
            },
            {
                field: "time.closeDate",
                title: "Fecha final",
            },
            {
                actions: true,
                title: "Operaciones",
                colspan: 2,
            },
        ];
        if (!_.isNull(_.get(companies, "data", null))) {
            return (
                <Row>
                    <AuthComponent
                        component={
                            <Col xs={12} mdOffset={2} md={8}>
                                <Paper style={{ ...styles.stylePaper }}>
                                    <Row>
                                        <Col xs>{this.renderCompany()}</Col>
                                    </Row>
                                    <Row>
                                        <Col xs>
                                            <TablePagination
                                                name={`projects`}
                                                URL={`/projects/config/company/${_.get(
                                                    params,
                                                    "_id"
                                                )}`}
                                                version={2}
                                                body={body}
                                                extra={{
                                                    company: _.get(
                                                        params,
                                                        "_id"
                                                    ),
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Paper>
                            </Col>
                        }
                        permission={"cgr_company_config"}
                        type={"url"}
                    />
                    <SendMessageModal />
                </Row>
            );
        }
        return (
            <div>
                <Row>
                    <Col xs style={{ textAlign: "center" }}>
                        <ButtonBack url="/admin/companies/grid" />
                        <CircularProgress
                            size={55}
                            thickness={4}
                            style={{ margin: "65px auto" }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

function mapStateToProps({ companies }) {
    return {
        companies: _.get(companies, "company"),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleDeleteModal,
            FetchCompanyParam,
            clearCompanyParam,
            cleanProject,
            openModalSendEmail,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesInfo);
