import PropTypes from "prop-types";
import React from "react";
import { Component } from "react";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import NSSTheme from "../constants/nssTheme";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";

class App extends Component {
    static childContextTypes = {
        muiTheme: PropTypes.object,
    };

    render() {
        const muiTheme = getMuiTheme(NSSTheme);

        return (
            <MuiThemeProvider muiTheme={muiTheme}>
                {this.props.children}
            </MuiThemeProvider>
        );
    }
}

export default App;
