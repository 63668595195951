import _ from 'lodash';
import {LBL_METADATA} from '../../constants/labels';

export const joinErrors = collection => (item, index) => {
    if (_.has(collection, index)) {
        return _.assign({}, item, collection[index]);
    } else {
        return item;
    }
};

export const requireFields = (...names) => data =>
    names.reduce((errors, name) => {
        if (!data[name]) {
            if (!_.isEqual(_.toInteger(data[name]), 0)) {
                errors[name] = 'Requerido';
            }
        }
        if (_.lt(_.toInteger(data[name]), 0)) {
            errors[name] = 'Debe ser igual o mayor a cero';
        }
        if (_.gt(_.toInteger(data[name]), 100)) {
            errors[name] = 'Debe ser igual o menor a cien';
        }
        return errors
    }, {});


export const evaluateRanges = data => _.chain(data)
    .filter(e => {
        return !(_.isUndefined(e.initialValue) || _.isUndefined(e.endValue));
    })
    .flatMap((e, i, array) => {
        return array.map((item, idx) => {
            const notTheSameObj = !_.isEqual(e, item);
            const sameType = _.isEqual(e.type, item.type);
            const initialConstraint = _.inRange(e.initialValue, item.initialValue, item.endValue);
            const endConstraint = _.inRange(e.endValue, item.initialValue, item.endValue);
            const equalsConstraint = _.isEqual(e.initialValue, item.initialValue) || _.isEqual(e.initialValue, item.endValue) || _.isEqual(e.endValue, item.initialValue) || _.isEqual(e.endValue, item.endValue);
            const validation = notTheSameObj && sameType && (initialConstraint || endConstraint || equalsConstraint);
            return [validation, e, i, item, idx];
        });
    })
    .filter(pair => pair[0])
    .flatMap(elements => {
        return [elements[2], elements[4]];
    })
    .uniq()
    .map(index => {
        return {
            initialValue: LBL_METADATA.valueOverlap,
            endValue: LBL_METADATA.valueOverlap,
            index: index
        }
    })
    .reduce((acc, current) => {
        return _.set(acc, current.index, current);
    }, {})
    .value();


export const evaluateValueMaxAndMin = data => _.chain(data)
    .flatMap((item, idx) => {
        if (_.gt(item.initialValue, item.endValue)) {
            return [idx];
        } else {
            return [];
        }
    })
    .map(idx => ({index: idx, initialValue: LBL_METADATA.maxMinVal, endValue: LBL_METADATA.maxMinVal}))
    .reduce((acc, current) => {
        return _.set(acc, current.index, current);
    }, {})
    .value();
