import PropTypes from "prop-types";
import React, { Component } from "react";
import _ from "lodash";
import IconButton from "material-ui/IconButton";
import { blue700, blue300 } from "material-ui/styles/colors";
import VisibilityAdd from "material-ui/svg-icons/action/visibility";

let styleButton = {
        padding: 0,
        borderRadius: "100%",
        background: blue700,
        WebkitTransition: "all 0.8s",
    },
    styleButtonHover = {
        padding: 0,
        borderRadius: "100%",
        background: blue300,
        WebkitTransition: "all 0.8s",
    },
    styleContent = { background: "#FFF" };

class ButtonDetailComponent extends Component {
    state = { hover: false };

    static propTypes = {
        textTooltip: PropTypes.string.isRequired,
        visible: PropTypes.bool,
        positionTooltip: PropTypes.string,
    };

    handleOnMouseEnter = () => {
        this.setState({ hover: true });
    };

    handleOnMouseLeave = () => {
        this.setState({ hover: false });
    };

    render() {
        const { textTooltip, visible, positionTooltip } = this.props;
        const { hover } = this.state;
        const ptt = _.isUndefined(positionTooltip) || _.isNull(positionTooltip) ? "top-left" : positionTooltip;
        if (visible) {
            return (
                <div onMouseEnter={this.handleOnMouseEnter} onMouseLeave={this.handleOnMouseLeave}>
                    <div style={hover ? styleButtonHover : styleButton}>
                        <IconButton
                            tooltip={textTooltip}
                            tooltipPosition={ptt}
                            children={<VisibilityAdd color="#FFF" />}
                        />
                    </div>
                </div>
            );
        }
        return <span />;
    }
}

export default ButtonDetailComponent;
