import React, {Component} from 'react';
import {Row, Col} from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';
import Divider from 'material-ui/Divider';
import { FetchFaq } from '../../actions/grid/faq_grid_form';
import { connect } from 'react-redux';
import { STYLE_GLOBAL } from '../../constants/index';
import _ from 'lodash';

class FaqGrid extends Component {

    UNSAFE_componentWillMount(){
        this.props.FetchFaq();
    }

    renderFaq(){
        if(_.has(this, 'props.faq')){
            if(this.props.faq.length){
                return this.props.faq.map((fq) => {
                    return (
                        <div key={fq._id} id={fq._id}>
                            <Row>
                                <Col xs>
                                    <div style={{width: '87%', marginLeft: 'auto', marginRight: 'auto', marginTop: 45}}>
                                        <h2 style={{fontWeight: 400}}>{fq.name}</h2>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs>
                                    <div style={{width: '87%', marginLeft: 'auto', marginBottom: 45, marginRight: 'auto', position: 'absoluto'}}>
                                        <h3 style={{fontWeight: 200, textAlign: 'justify'}}>
                                            {fq.description}
                                        </h3>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs>
                                    <Divider />
                                </Col>
                            </Row>
                        </div>
                    );
                });
            }
        }
    }

    render() {
        return (
          <Row>
              <Col xsOffset={3} xs={6}>
                  <Paper style={STYLE_GLOBAL.stylePaper}>
                      <Row>
                          <Col xs>
                              <h1 style={{fontWeight: 400, textAlign: 'center', marginTop: 40}}>Preguntas Frecuentes</h1>
                          </Col>
                      </Row>
                      {this.renderFaq()}
                  </Paper>
              </Col>
          </Row>
        );
    }

}

function mapStateToProps({faq}){
    return  { faq: faq.get('all').toArray() };
}

export default connect (mapStateToProps, { FetchFaq })(FaqGrid);
