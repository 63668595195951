import { axiosAPI, axiosAPI_V2 } from '../../../middleware/api';
import {
    LOAD_ARL_INFO,
    LOGOUT,
    LOAD_COMPANIES_ARL_INFO,
    CLEAN_INFO,
    GET_PROJECTS_COMPANIES_ARL,
    GET_STATUS_PROJECTS_COMPANIES_ARL,
    ADD_ID_PROJECTS_ARL,
    REMOVE_ID_PROJECTS_ARL,
    CLEAN_ID_PROJECTS_ARL,
    IN_SEARCH_PROJECTS_ARL,
    IN_SEARCH_STATUS_PROJECTS_ARL,
    RESULTS_PROJECTS_ARL
} from './constants';

export function getArlInfoFromPersonId(personId) {

    const request = axiosAPI_V2.get(`/arl/person/${personId}`);
    return {
        type: LOAD_ARL_INFO,
        payload: request
    }
}

export function logOut() {
    const localStorage = window.localStorage;
    localStorage.removeItem('authToken');
    axiosAPI.defaults.headers.authorization = undefined;
    axiosAPI_V2.defaults.headers.authorization = undefined;
    return {
        type: LOGOUT
    }
}

export function getInfoCompaniesArl(arlId){

    const request = axiosAPI_V2.get(`/arl/companies/${arlId}`);
    return {
        type: LOAD_COMPANIES_ARL_INFO,
        payload: request,
        meta: {
            arlId: arlId
        }
    }
}

export function cleanBusinessInfo(){

    return {
        type: CLEAN_INFO
    }
}

export function getProjectsForCompanies(searchProject){

    const request = axiosAPI_V2.post(`/arl/companies/projects`, {
                                                                    'project': searchProject
                                                                });
    return {
        type: GET_PROJECTS_COMPANIES_ARL,
        payload: request
    }
}

export function addIdProject(id){

    return {
        type: ADD_ID_PROJECTS_ARL,
        meta: {
            id: id
        }
    }
}

export function removeIdProject(id){

    return {
        type: REMOVE_ID_PROJECTS_ARL,
        meta: {
            id: id
        }
    }
}

export function cleanIdProjects(){

    return {
        type: CLEAN_ID_PROJECTS_ARL
    }
}

export function inSearchProject(){

    return {
        type: IN_SEARCH_PROJECTS_ARL
    }
}

export function inSeacrchStatusProject(){

    return {
        type: IN_SEARCH_STATUS_PROJECTS_ARL
    }
}

export function getStatusProjectForCompanies(arrayProjects){

    const request = axiosAPI_V2.post(`/arl/companies/projects/status`, {
                                                                    'project': arrayProjects
                                                                });
    return {
        type: GET_STATUS_PROJECTS_COMPANIES_ARL,
        payload: request
    }
}

export function setProjectsResultsAndNameSurvey(arrayProjects, nameSurvey){

    return {
        type: RESULTS_PROJECTS_ARL,
        meta: {
            projects: arrayProjects,
            nameSurvey: nameSurvey
        }
    }
}
