import React from "react";
import { useField, useFormikContext } from "formik";
import { useQuery } from "react-query";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useCleanUpOnUnmount } from "./utils";

const AsyncAutoCompleteField = ({
    query,
    queryProps = {},
    condition,
    id,
    label,
    helperText = "",
    getOptionLabel = () => {},
    disabled = false,
    getOptionSelected,
    ...props
}) => {
    const [field, meta, fieldProps] = useField(props);
    const { initialValues } = useFormikContext();
    useCleanUpOnUnmount({
        fieldProps,
        pristineValue: initialValues[field.name],
    });
    const { status, data = [] } = useQuery([id, queryProps], query);
    const isLoading = status === "loading";
    const hasError = meta.touched && meta.error;
    const errorMessage = meta.error;
    const helperTextContent = hasError ? errorMessage : helperText;
    const autoCompleteProps = {
        onChange: (e, newValue) => {
            fieldProps.setValue(newValue);
        },
        value: field.value,
        disabled,
    };
    return (
        <Autocomplete
            id={id}
            options={data}
            getOptionLabel={getOptionLabel}
            loading={isLoading}
            getOptionSelected={getOptionSelected}
            {...autoCompleteProps}
            renderInput={(params) => (
                <TextField
                    {...props}
                    {...params}
                    label={label}
                    variant="outlined"
                    error={hasError}
                    helperText={helperTextContent}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};

export default AsyncAutoCompleteField;
