import { axiosAPI_V2 } from '../../../middleware/api';
import * as actions from './constants';

export function getSurveyMetadata(surveyId){
    const request = axiosAPI_V2.get(`/results/metadata/${surveyId}`);
    return {
        type: actions.GET_SURVEY_METADATA,
        payload: request
    }
}
