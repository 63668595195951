import {TOGGLE_DIALOG, SELECT_USER, CHANGE_PASSWORD} from '../../actions/users/userDialogActions';
import _ from 'lodash';

export default (state = {status: false, user: {}, newUser: {}}, action) => {
    switch (action.type) {
        case TOGGLE_DIALOG:
            return _.assign({}, state, {
                status: action.status
            });
        case SELECT_USER:
            return _.assign({}, state, {
                user: action.user
            });
        case CHANGE_PASSWORD:
            return _.assign({}, state, {
                newUser: action.payload.data
            });
        default:
            return state;
    }
}
