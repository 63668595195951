// @flow
import React from "react";

const styles = {
    span: {
        background: "none",
        transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
        lineHeight: "16px",
        color: "rgba(0, 0, 0, 0.87)",
        position: "relative; z-index: 1",
        fontSize: 16,
        fontWeight: "inherit",
        fontFamily: "Roboto, sans-serif",
        padding: 0,
        outline: "none",
        textDecoration: "none",
        margin: 0,
        border: 10,
        boxSizing: "border-box",
        display: "block",
        cursor: "pointer",
    },
    label: { marginLeft: 0, padding: 16, position: "relative" },
};

type Props = {
    label: string,
};

const Box = (props: Props) => (
    <div>
        <div>
            <span style={styles.span}>
                <div>
                    <div style={styles.label}>{props.label}</div>
                </div>
            </span>
        </div>
    </div>
);

export default Box;
