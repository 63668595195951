import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getRecords, cleanTable, setInSearchRecords } from './actions';
import { blue500, blueGrey200 } from 'material-ui/styles/colors';
import CircularProgress from 'material-ui/CircularProgress';
import _ from 'lodash';

class ActionPagination extends Component {

    constructor(props) {
        super(props);
        this._renderButtons = this._renderButtons.bind(this);
        this._handleToUpdateRecords = this._handleToUpdateRecords.bind(this);
    }

    _getStyleButton(currentPage, i){
        return {
            display: 'inline-block',
            padding: '4px 10px',
            border: '0px solid #000',
            borderRadius: 7,
            marginLeft: 1,
            marginRight: 1,
            color: '#FFF',
            cursor: currentPage === i ? 'normal' : 'pointer',
            textAlign: 'center',
            background: currentPage === i ? blueGrey200 : blue500
        };
    }

    _getContentPage(currentPage, i){
        let styleButton = this._getStyleButton(currentPage, i);
        return <div key={`currentPage${i}`} style={styleButton} onClick={() => this._handleToUpdateRecords(i) }>{i}</div>;
    }

    _getContentEllipsis(i){
        return <div key={`ellipsis${i}`} style={{display: 'inline-block', margin: '0px 4px', fontSize: 20}}>...</div>;
    }

    _handleToUpdateRecords(page){
        const {
            URL,
            limit,
            skip,
            version,
            orderName,
            orderType,
            textFilter,
            arrayFields,
            getRecords,
            setInSearchRecords,
            extra,
            coreName
        } = this.props;
        let currentPage = Math.ceil(skip / limit) + 1;
        let newSkip = (limit * page) - limit;
        if(!(page === currentPage)){
            cleanTable(coreName);
            setInSearchRecords(coreName);
            if(!_.isUndefined(extra) && !_.isNull(extra)){
                getRecords(URL, version, newSkip, limit, orderName, orderType, arrayFields, textFilter, coreName, extra);
            } else {
                getRecords(URL, version, newSkip, limit, orderName, orderType, arrayFields, textFilter, coreName);
            }
        }
    }

    _renderButtons(){
        const {
            limit,
            skip,
            total
        } = this.props;
        let currentPage = Math.ceil(skip / limit) + 1;
        let possiblePages = Math.ceil(total / limit);
        let pages = [];
        if(possiblePages === 1){
            return ( <div></div> );
        } else if(possiblePages <= 7){
            for (let i = 1; i <= possiblePages; i++){
                pages.push( this._getContentPage(currentPage, i) );
            }
        } else {
            if(currentPage === 1 || currentPage === 2 || currentPage === 3){
                for (let i = 1; i <= 5; i++) {
                    pages.push( this._getContentPage(currentPage, i) );
                }
                pages.push( this._getContentEllipsis(0) );
                pages.push( this._getContentPage(1, possiblePages) );
            } else if(currentPage === possiblePages || currentPage === possiblePages - 1 || currentPage === possiblePages - 2){
                pages.push( this._getContentPage(possiblePages, 1) );
                pages.push( this._getContentEllipsis(0) );
                for (let i = possiblePages - 4; i <= possiblePages; i++) {
                    pages.push( this._getContentPage(currentPage, i) );
                }
            } else if(currentPage == 4) {
                for (let i = 1; i <= 6; i++) {
                    pages.push( this._getContentPage(currentPage, i) );
                }
                pages.push( this._getContentEllipsis(0) );
                pages.push( this._getContentPage(1, possiblePages) );
            } else if(currentPage == possiblePages - 3){
                pages.push( this._getContentPage(possiblePages, 1) );
                pages.push( this._getContentEllipsis(0) );
                for (let i = possiblePages - 5; i <= possiblePages; i++) {
                    pages.push( this._getContentPage(currentPage, i) );
                }
            } else {
                pages.push( this._getContentPage(possiblePages, 1) );
                pages.push( this._getContentEllipsis(0) );
                pages.push( this._getContentPage(currentPage, currentPage - 2) );
                pages.push( this._getContentPage(currentPage, currentPage - 1) );
                pages.push( this._getContentPage(currentPage, currentPage) );
                pages.push( this._getContentPage(currentPage, currentPage + 1) );
                pages.push( this._getContentPage(currentPage, currentPage + 2) );
                pages.push( this._getContentEllipsis(0) );
                pages.push( this._getContentPage(1, possiblePages) );
            }
        }
        /*Math.ceil(2.3) = 3;
        Math.floor(2.6) = 2;*/
        return pages;
    }

    render() {
        const {
            limit,
            skip,
            inSearch
        } = this.props;
        const styleContent = {
            paddingTop: 5,
            margin: '15px auto 5px auto',
            textAlign: 'center',
            height: 40
        };
        /*const styleContent = {
            paddingTop: limit >= total ? 0 : 5,
            margin: limit >= total ? '0 auto 0 auto' : '15px auto 5px auto',
            textAlign: 'center',
            height: limit >= total ? 0 : 40
        };*/
        return (
            <div style={styleContent}>
                {inSearch
                   ? <CircularProgress size={35} thickness={2} />
                   : this._renderButtons()
                }
            </div>
        );
    }

}

ActionPagination.propTypes = {
    URL: PropTypes.string.isRequired,
    version: PropTypes.number.isRequired,
    extra: PropTypes.object,
    coreName: PropTypes.string.isRequired
};

function mapStateToProps({ genericTablePagination }, ownProps) {
    const store = genericTablePagination.get(_.get(ownProps, 'coreName', 'default'));
    return {
        limit: store.get('limit'),
        skip: store.get('skip'),
        total: store.get('total'),
        orderName: store.get('orderName'),
        orderType: store.get('orderType'),
        textFilter: store.get('textFilter'),
        arrayFields: store.get('arrayFields'),
        inSearch: store.get('inSearch')
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getRecords,
        cleanTable,
        setInSearchRecords
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionPagination);
