import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Paper from 'material-ui/Paper';
import CircularProgress from 'material-ui/CircularProgress';
import {
    requestProjectsByReferees,
    GET_PROJECTS_BY_REFEREE_REJECTED,
    GET_PROJECTS_BY_REFEREE_FULFILLED,
    GET_PROJECTS_BY_REFEREE_REQUESTED
} from './ducks';
import _ from 'lodash';
import Chat from './index';
import{Row, Col} from 'react-flexbox-grid';

function wrapper(component) {
    return (
        <div style={{marginTop: 30, marginBottom: 30}}>
            <Row>
                <Col xs={12} sm={6} smOffset={0} md={4} mdOffset={4} lg={4} lgOffset={4}>
                    <Paper style={{minHeight: '100px'}}>
                        {component}
                    </Paper>
                </Col>
            </Row>
        </div>
    );
}

class PreRoom extends Component {

    componentDidMount() {
        const {requestProjectsByReferees, person} = this.props;
        requestProjectsByReferees(person);
    }

    render() {
        const {status, projects, chatWebSocketManager} = this.props;
        const noStatus = (_.isEqual(status, GET_PROJECTS_BY_REFEREE_REQUESTED) || _.isNil(status));
        const fulfilled = _.isEqual(status, GET_PROJECTS_BY_REFEREE_FULFILLED);
        const rejected = _.isEqual(status, GET_PROJECTS_BY_REFEREE_REJECTED);
        if (noStatus) {
            return wrapper(
                <div style={{textAlign: 'center', padding: '30px 10px'}}>
                    <CircularProgress/><br/>
                    <span>Espera un momento por favor...</span>
                </div>
            );
        } else if (rejected) {
            return wrapper(
                <div style={{textAlign: 'center', padding: '30px 10px'}}>
                    <span>Ha ocurrido un error al intentar cargar la información, intentalo nuevamente o comunícate con un administrador.</span>
                </div>
            );
        } else if (fulfilled) {
            if (_.size(projects) > 0) {
                return (
                    <Chat projects={projects} chatWebSocketManager={chatWebSocketManager}/>
                );
            } else {
                return wrapper(
                    <div style={{textAlign: 'center', padding: '30px 10px'}}>
                        <span>No tiene proyectos asociados para acceder al chat.</span>
                    </div>
                );
            }
        } else {
            return wrapper(
                <div style={{textAlign: 'center', padding: '30px 10px'}}>
                    <span>Error inesperado, comunícate con un administrador.</span>
                </div>
            );
        }
    }
}

function mapStateToProps({login, chatEvaluator}) {
    return {
        person: _.get(login.get('profile'), '_id'),
        status: chatEvaluator.get('statusProjects'),
        projects: chatEvaluator.get('assignedProjects') || []
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        requestProjectsByReferees
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PreRoom);
