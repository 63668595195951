import PropTypes from "prop-types";
import React, { Component } from "react";
import ColumnComponent from "./columnComponent";
import TitleComponent from "./titleComponent";
import * as _ from "lodash";

class RowComponent extends Component {
    static propTypes = {
        columns: PropTypes.oneOf([1, 2, 3, 4, 5]).isRequired,
        index: PropTypes.number.isRequired,
        sections: PropTypes.array.isRequired,
        title: PropTypes.object,
        menu: PropTypes.array.isRequired,
        projectId: PropTypes.string.isRequired,
        colors: PropTypes.array.isRequired,
        maxRanking: PropTypes.number.isRequired,
    };

    render() {
        const { columns, index, sections, title, menu, projectId, colors, maxRanking } = this.props;

        const rowStyle = {
                width: "100%",
                padding: "5px 0",
                boxSizing: "border-box",
            },
            tableStyle = {
                width: "100%",
                borderCollapse: "collapse",
                tableLayout: "fixed",
                padding: 0,
            },
            tableCellStyle = {
                padding: 5,
                margin: 0,
                verticalAlign: "top",
            };
        return (
            <div className="print_pdf_row" style={rowStyle}>
                <table style={tableStyle}>
                    <tbody>
                        <tr>
                            <td colSpan={columns} style={tableCellStyle}>
                                <TitleComponent
                                    text={_.get(title, "text", "")}
                                    style={_.get(title, "style", { margin: 0, padding: 0 })}
                                    code={_.get(title, "code", "")}
                                />
                            </td>
                        </tr>
                        <tr>
                            {_.map(sections, (section, indexSection) => (
                                <ColumnComponent
                                    key={`row-${index}-column-${indexSection}`}
                                    columns={_.get(section, "columns", 1)}
                                    numColumns={columns}
                                    rows={_.get(section, "rows", 1)}
                                    index={indexSection}
                                    title={_.get(section, "title", {})}
                                    orientation={_.get(section, "orientation", "VERTICAL")}
                                    style={_.get(section, "style", {})}
                                    children={_.get(section, "children", [])}
                                    contentAlign={_.get(section, "contentAlign", "left")}
                                    menu={menu}
                                    projectId={projectId}
                                    colors={colors}
                                    maxRanking={maxRanking}
                                />
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default RowComponent;
