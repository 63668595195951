import { POST_QUESTION, GET_QUESTION, GET_QUESTIONS_GROUP_SURVEYS, DELETE_QUESTION } from '../constants/index';
import _ from 'lodash';
import { axiosAPI } from '../middleware/api';

export function createQuestion(props, editMode) {

    function getProps(props){
        let objeto = {
            "fieldName": _.get(props, 'fieldName'),
            "type": _.get(props, 'type'),
            "title": _.get(props, 'title'),
            "size": _.get(props, 'size') ? _.get(props, 'size') : 'middle',
            "itemList": _.get(props, 'itemList') ? _.get(props, 'itemList') : 'none',
            'help': _.get(props, 'help') ? _.get(props, 'help') : ''
        };
        if(_.get(props, '_id')){
            objeto = _.set(objeto, '_id', _.get(props, '_id'));
        }
        if(_.get(props, 'valueSource') && (_.get(props, 'type') === 'ListOpen' || _.get(props, 'type') === 'ListClose') || _.get(props, 'type') === 'ListSelected'){
            objeto = _.set(objeto, 'valueSource', {
                "list": {
                    "name": _.get(props, 'valueSource')
                }
            });
        }
        if(_.get(props, 'type') === 'ListOpen'){
            objeto = _.chain(objeto)
                      .set('quantitySelectable', _.get(props, 'quantitySelectable'))
                      .set('otherWhich', _.get(props, 'otherWhich'))
                      .value();
        } else {
            objeto = _.chain(objeto)
                      .set('quantitySelectable', 0)
                      .set('otherWhich', false)
                      .value();
        }
        if (_.get(props, 'type') === 'Group'){
            objeto = _.set(objeto, 'valueSource', {
                "group": {
                    "name": _.get(props, 'list')
                }
            });
        }
        if (_.get(props, 'type') === 'GroupClose'){
            objeto = _.set(objeto, 'valueSource', {
                "groupClose": {
                    "name": _.get(props, 'listClose').map( ( value, index ) => {
                                return _.set(value, 'id', index + 1)
                            })
                }
            });
        }
        if (_.get(props, 'type') === 'GroupList'){
            objeto = _.set(objeto, 'valueSource', {
                "list": {
                    "name": !_.isUndefined(_.get(props, 'valueSource.nameList'))
                            ? _.get(props, 'valueSource.nameList')
                            : _.get(props, 'valueSource')
                },
                "groupList": {
                    "name": _.get(props, 'listClose').map( ( value, index ) => {
                                return _.set(value, 'id', index + 1)
                            })
                }
            });
        }
        return {'question': objeto};
    }

    let request = null;
    if(editMode){
        request = axiosAPI.put(`/questions`, getProps(props));
    } else {
        request = axiosAPI.post(`/questions`, getProps(props));
    }

    return {
        type: POST_QUESTION,
        payload: request
    }
}

export function FetchQuestions() {

    const request = axiosAPI.get(`/questions`);

    return {
        type: GET_QUESTION,
        payload: request
    }
}

export function FetchQuestionsGroup() {

    const request = axiosAPI.get(`/questions/group`);

    return {
        type: GET_QUESTION,
        payload: request
    }
}

export function FetchQuestionsGroupId(id) {

    const request = axiosAPI.get(`/questions/group/${id}`);

    return {
        type: GET_QUESTIONS_GROUP_SURVEYS,
        payload: request,
        meta: {
           idQuestion: id
        }
    }
}

export function deleteQuestions(questionId) {

    const request = axiosAPI.delete(`/questions/${questionId}`, {data: {"type": 'logic'}});

    return {
        type: DELETE_QUESTION,
        payload: request,
        meta: {
            questionId
        }
    }
}
