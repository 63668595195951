import { ADVANCE_USER, ADVANCE_USER_CLEAN, ADVANCE_USER_DIALOG } from './actions';
import _ from 'lodash';

const initialStates = {
    openDialog: false,
    advance: null
}

export default (state = initialStates, action) => {
    switch (action.type) {
        case ADVANCE_USER:
            var advance = _.isUndefined(_.get(action, 'payload.data.data'))
                            ? undefined
                            : _.set(_.get(action, 'payload.data.data'), 'person', action.meta.person);
            return _.assign({}, state, {
                advance: advance
            });
        case ADVANCE_USER_CLEAN:
            return _.assign({}, state, {
                advance: null
            });
        case ADVANCE_USER_DIALOG:
            return _.assign({}, state, {
                openDialog: action.meta.openDialog
            });
        default:
            return state;
    }
}
