import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {Row, Col} from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';
import FloatingActionButtonTooltip from '../../components/fields/FloatingActionButtonTooltip/FloatingActionButtonTooltip';
import _ from 'lodash';
import {blue700} from 'material-ui/styles/colors';
import {LBLLOCATIONS} from '../../constants/labels';
import { getNameDepartment } from '../fields/Locations/actions';
import ButtonBack from '../fields/buttonBack/buttonBack';
import LocationFormModal from '../ModalFormLocation/componentModalFormLocation';
import { openModalForm } from '../ModalFormLocation/actions';
import AuthComponent from '../AuthComponent';
import TablePagination from '../TablePagination/tablePagination';

const stylePaper = {
        marginTop: 20,
        marginBottom: 20
    };

class AdminCities extends Component {

    constructor(props) {
        super(props);
    }

    _handleOpenModalForm(title, id, code, name){
        const {
            openModalForm,
            params
        } = this.props;
        openModalForm(id, code, name, title, "city", params.departmentId, false);
    }

    UNSAFE_componentWillMount() {
        const {
            getNameDepartment,
            params
        } = this.props;
        getNameDepartment(_.get(params, 'departmentId'));
    }

    render() {
        const body = [
            {
                field: "code",
                title: "Código"
            },
            {
                field: "name",
                title: "Nombre",
                colspan: 3,
                filter: true
            },
            {
                actions: true,
                title: "Operaciones",
                colspan: 1
            }
        ];
        const {
            params,
            nameDepartment
        } = this.props;
        return (
            <Row>
                <AuthComponent
                    component={
                        <Col xs={12} mdOffset={2} md={8}>
                            <Paper style={stylePaper}>
                                <ButtonBack url={`/admin/locations/departments/${_.get(params, 'countryId')}`}/>
                                <Row>
                                    <Col xs>
                                        <div style={{width: '100%', background: blue700, paddingTop: 5, paddingBottom:5, color: "#FFF"}}>
                                            <h1 style={{textAlign: 'center', fontWeight: 400}}>{`${LBLLOCATIONS.cities} de ${_.capitalize(nameDepartment)}`}</h1>
                                            <AuthComponent
                                                component={
                                                    <div style={{position: "fixed", right: 15, bottom: 15}}>
                                                        <FloatingActionButtonTooltip
                                                            onClick={this._handleOpenModalForm.bind(this,`${LBLLOCATIONS.tooltips.btnCreate} municipio`, null, "", "" )}
                                                            textTooltip={LBLLOCATIONS.tooltips.btnCreateCity}
                                                        />
                                                    </div>
                                                }
                                                permission={"cgr_location_city_create"}
                                                type={"component"}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <TablePagination
                                            name={`cities`}
                                            URL={`/location/cities`}
                                            version={1}
                                            body={body}
                                            extra={{department: _.get(params, 'departmentId')}}
                                        />
                                    </Col>
                                </Row>
                            </Paper>
                            <LocationFormModal/>
                        </Col>
                    }
                    permission={"cgr_location_department_config"}
                    type={"url"}
                />
            </Row>
        );
    }
}


function mapStateToProps({master}, ownProps) {
    return {
        nameDepartment: _.get(master, "nameDepartment", "")
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openModalForm,
        getNameDepartment
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminCities);
