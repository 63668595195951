import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Divider from 'material-ui/Divider';
import FormProfileCategory from './FormProfileCategory';
import _ from 'lodash';

class ContainerResources extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {
            resources,
        } = this.props;
        if (!_.isUndefined(resources)) {
            return (
                <div style={{paddingLeft: 0, paddingRight: 0, paddingTop: 15}}>
                    {_.map(_.get(resources, 'keys', []), (key, index) =>
                        <div key={`formProfileResource${index}`} style={{marginBottom: 45}}>
                            <h3 style={{textAlign: 'Left'}}>{key}</h3>
                            <FormProfileCategory
                                resources={_.get(resources, `resources.${key}`, [])}
                            />
                            <Divider />
                        </div>
                    )}
                </div>
            );
        } else {
            return ( <div></div> );
        }
    }
}

ContainerResources.propTypes = {
    resources: PropTypes.object.isRequired
};

export default ContainerResources;
