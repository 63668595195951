import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import { bindActionCreators } from "redux";
import {
    getValuesTagsEmployee,
    getValuesTagsCompany,
    setValueTagEmployee,
    setTagEmployee,
    cleanTagEmployee,
} from "./actions";
import { setExtraStatusFilter } from "../TablePagination/actions";
import CircularProgress from "material-ui/CircularProgress";
import SelectFieldFormat from "../fields/SelectFieldFormat/SelectFieldFormat";
import { LBLPROJECTS } from "../../constants/labels";
import MenuItem from "material-ui/MenuItem";
import RaisedButton from "material-ui/RaisedButton";
import { getRecords } from "../TablePagination/actions";
import _ from "lodash";

class TagsEmployee extends Component {
    constructor(props) {
        super(props);
        this._onChangeTag = this._onChangeTag.bind(this);
        this._onChangeValueTag = this._onChangeValueTag.bind(this);
        this._handlerFilterTags = this._handlerFilterTags.bind(this);
        this._onClearTagEmployee = this._onClearTagEmployee.bind(this);
        this._execGetRecords = this._execGetRecords.bind(this);
        this.renderFilterStatus = this.renderFilterStatus.bind(this);
        this._onChangeStatus = this._onChangeStatus.bind(this);
    }

    UNSAFE_componentWillMount() {
        const { getValuesTagsCompany, companyId } = this.props;
        getValuesTagsCompany(companyId);
    }

    _onChangeTag(e, index, value) {
        const {
            tags,
            searchTags,
            setTagEmployee,
            getValuesTagsEmployee,
            companyId,
        } = this.props;
        let tagCompanySearch = "";
        _.map(searchTags, function (searchTag, index) {
            if (_.isEqual(searchTag, value)) {
                tagCompanySearch = tags[index];
            }
        });
        getValuesTagsEmployee(companyId, tagCompanySearch);
        setTagEmployee(value);
    }

    _onChangeValueTag(e, index, value) {
        const { setValueTagEmployee } = this.props;
        setValueTagEmployee(value);
    }

    _onClearTagEmployee() {
        const { extra, cleanTagEmployee, status, filterStatus } = this.props;
        cleanTagEmployee();
        let extraData = _.omit(extra, ["textTag", "textValueTag"]);
        if (
            !_.isNull(status) &&
            !_.isEqual(status, "all") &&
            !_.isUndefined(filterStatus) &&
            !_.isNull(filterStatus) &&
            filterStatus
        ) {
            extraData = _.chain(extraData).set("status", status).value();
        }
        this._execGetRecords(extraData);
    }

    _execGetRecords(extraData) {
        const {
            URL,
            version,
            skip,
            limit,
            orderName,
            orderType,
            textFilter,
            body,
            textValueTag,
            textTag,
            getRecords,
            fixedStatus,
        } = this.props;
        let arrayFields = [];
        _.map(body, function (field) {
            if (!_.has(field, "actions") && _.has(field, "filter")) {
                if (_.get(field, "filter")) {
                    arrayFields.push(_.get(field, "field"));
                }
            }
        });
        if (!_.isNull(fixedStatus) && !_.isUndefined(fixedStatus)) {
            extraData = _.set(extraData, "status", fixedStatus);
        }
        getRecords(
            URL,
            version,
            skip,
            limit,
            orderName,
            orderType,
            arrayFields,
            textFilter,
            extraData
        );
    }

    _handlerFilterTags() {
        const {
            extra,
            textTag,
            textValueTag,
            status,
            filterStatus,
        } = this.props;
        let extraData = _.chain(extra)
            .set("textTag", textTag)
            .set("textValueTag", textValueTag)
            .value();
        if (
            !_.isNull(status) &&
            !_.isEqual(status, "all") &&
            !_.isUndefined(filterStatus) &&
            !_.isNull(filterStatus) &&
            filterStatus
        ) {
            extraData = _.chain(extraData).set("status", status).value();
        }
        this._execGetRecords(extraData);
    }

    _onChangeStatus(e, item, value) {
        const {
            extra,
            filterStatus,
            setExtraStatusFilter,
            textTag,
            textValueTag,
        } = this.props;
        if (
            !_.isUndefined(filterStatus) &&
            !_.isNull(filterStatus) &&
            filterStatus
        ) {
            let extraData = extra;
            if (!_.isNull(textTag) && !_.isNull(textValueTag)) {
                extraData = _.chain(extra)
                    .set("textTag", textTag)
                    .set("textValueTag", textValueTag)
                    .value();
            }
            this._execGetRecords(_.set(extraData, "status", value));
            setExtraStatusFilter(value);
        }
    }

    renderFilterStatus() {
        const { filterStatus, status } = this.props;
        if (
            !_.isUndefined(filterStatus) &&
            !_.isNull(filterStatus) &&
            filterStatus
        ) {
            return (
                <SelectFieldFormat
                    config={{
                        floatingLabelText: "Estado",
                        value: status,
                        autoWidth: true,
                        onChange: this._onChangeStatus,
                    }}
                >
                    <MenuItem value={"all"} primaryText={"Todos"} />
                    <MenuItem
                        value={"pending"}
                        primaryText={LBLPROJECTS.advance.pending}
                    />
                    <MenuItem
                        value={"started"}
                        primaryText={LBLPROJECTS.advance.started}
                    />
                    <MenuItem
                        value={"finished"}
                        primaryText={LBLPROJECTS.advance.finished}
                    />
                    <MenuItem
                        value={"deserted"}
                        primaryText={LBLPROJECTS.advance.deserted}
                    />
                </SelectFieldFormat>
            );
        } else {
            return <div></div>;
        }
    }

    render() {
        const {
            tags,
            searchTags,
            valuesTags,
            textValueTag,
            textTag,
            disabledTagEmployee,
        } = this.props;
        return (
            <div style={{ paddingLeft: 20 }}>
                <Row>
                    <Col style={{ paddingLeft: 15, paddingTop: 15 }}>
                        <SelectFieldFormat
                            config={{
                                floatingLabelText: "Etiqueta de la empresa",
                                value: textTag,
                                autoWidth: true,
                                onChange: this._onChangeTag,
                            }}
                        >
                            {_.map(tags, (tag, index) => {
                                return (
                                    <MenuItem
                                        key={`tag${index}`}
                                        value={searchTags[index]}
                                        primaryText={tag}
                                    />
                                );
                            })}
                        </SelectFieldFormat>
                    </Col>
                    <Col style={{ paddingLeft: 15, paddingTop: 15 }}>
                        {disabledTagEmployee ? (
                            <CircularProgress
                                size={25}
                                thickness={2}
                                style={{ marginLeft: 50, marginTop: 35 }}
                            />
                        ) : (
                            <SelectFieldFormat
                                config={{
                                    floatingLabelText: "Valor de la etiqueta",
                                    value: textValueTag,
                                    autoWidth: true,
                                    disabled:
                                        _.isNull(textTag) ||
                                        _.isEqual(textTag, "") ||
                                        disabledTagEmployee,
                                    onChange: this._onChangeValueTag,
                                }}
                            >
                                {_.map(valuesTags, (value, index) => {
                                    return (
                                        <MenuItem
                                            key={`valueTag${index}`}
                                            value={value}
                                            primaryText={value}
                                        />
                                    );
                                })}
                            </SelectFieldFormat>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <RaisedButton
                            label="Filtrar etiquetas"
                            secondary={true}
                            style={{ margin: 12 }}
                            onClick={this._handlerFilterTags}
                            disabled={
                                _.isNull(textValueTag, "") || _.isNull(textTag)
                            }
                        />
                        <RaisedButton
                            label="Limpiar búsqueda"
                            secondary={true}
                            style={{ margin: 12 }}
                            onClick={this._onClearTagEmployee}
                            disabled={
                                _.isNull(textValueTag, "") || _.isNull(textTag)
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs style={{ paddingLeft: 15 }}>
                        {this.renderFilterStatus()}
                    </Col>
                </Row>
            </div>
        );
    }
}

function mapStateToProps({ tagsEmployee, tablePagination }) {
    return {
        tags: tagsEmployee.get("tags"),
        searchTags: tagsEmployee.get("searchTags"),
        valuesTags: tagsEmployee.get("valuesTags"),
        textValueTag: tagsEmployee.get("textValueTag"),
        disabledTagEmployee: tagsEmployee.get("disabled"),
        textTag: tagsEmployee.get("textTag"),
        skip: tablePagination.get("skip"),
        limit: tablePagination.get("limit"),
        orderName: tablePagination.get("orderName"),
        orderType: tablePagination.get("orderType"),
        textFilter: tablePagination.get("textFilter"),
        status: _.isNull(tablePagination.get("status"))
            ? "all"
            : tablePagination.get("status"),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getValuesTagsEmployee,
            getValuesTagsCompany,
            getRecords,
            setValueTagEmployee,
            setTagEmployee,
            cleanTagEmployee,
            setExtraStatusFilter,
        },
        dispatch
    );
}

TagsEmployee.propTypes = {
    companyId: PropTypes.string.isRequired,
    URL: PropTypes.string.isRequired,
    version: PropTypes.number.isRequired,
    extra: PropTypes.object.isRequired,
    body: PropTypes.array.isRequired,
    filterStatus: PropTypes.bool,
    fixedStatus: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(TagsEmployee);
