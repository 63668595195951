import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Checkbox from "material-ui/Checkbox";
import { updateComponent } from "../../ducks";
import WaitComponent from "../waitComponent";

class CheckComponent extends Component {
    static propTypes = {
        survey: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        page: PropTypes.string.isRequired,
        fieldProp: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        value: PropTypes.bool.isRequired,
        defaultValue: PropTypes.bool,
        loadingConfiguration: PropTypes.bool.isRequired,
    };

    onChangeValue = (e, value) => {
        const { updateComponent, survey, page, path, fieldProp } = this.props;
        updateComponent(path, _.set({}, fieldProp, value), page, survey);
    };

    render() {
        const { value, title, loadingConfiguration } = this.props;
        if (loadingConfiguration) {
            return <WaitComponent />;
        }
        return (
            <Checkbox
                label={title}
                checked={value}
                onCheck={this.onChangeValue}
                style={{ margin: "10px 0 10px 2px" }}
            />
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateComponent }, dispatch);
}

function mapStateToProps({ analyticsReportsConfiguration }, ownProps) {
    const config = analyticsReportsConfiguration.get("pageConfiguration");
    const loadingConfiguration = analyticsReportsConfiguration.get("loadingConfiguration");
    return {
        value: _.get(
            config,
            `${_.get(ownProps, "path")}.${_.get(ownProps, "fieldProp")}`,
            _.get(ownProps, "defaultValue", false),
        ),
        page: analyticsReportsConfiguration.get("page"),
        loadingConfiguration,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckComponent);
