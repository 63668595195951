import PropTypes from "prop-types";
import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import { blue600 } from "material-ui/styles/colors";
import MenuItem from "material-ui/MenuItem";
import { browserHistory } from "react-router";
import _ from "lodash";
import CircularProgress from "material-ui/CircularProgress";
import is from "is_js";
import PureInputAutoComplete from "../components/PureInputAutoComplete";
import { FetchCompanyParam } from "../actions/info/companies_info_form";
import { axiosAPI } from "../middleware/api";
import { EXPRESSION_IDENTIFICACION } from "../constants/regularExpressions";
import {
    createEmployee,
    getDocumentsTypes,
    FetchEmployeeParam,
} from "../actions/employees_form";
import { toggleSnackbar } from "../actions/commons";
import { LBLEMPLOYESS } from "../constants/labels";
import ButtonBack from "../components/fields/buttonBack/buttonBack";
import SelectFieldFormat from "../components/fields/SelectFieldFormat/SelectFieldFormat";
import AuthComponent from "../components/AuthComponent";

const styles = {
    buttonSubmit: {
        margin: "20px 20px 50px 20px",
        float: "right",
    },
    stylePaper: {
        marginTop: 20,
        marginBottom: 20,
    },
};

class EmployeesNews extends Component {
    static propTypes = {
        asyncValidating: PropTypes.string.isRequired,
        asyncValidatingEmail: PropTypes.string.isRequired,
        router: PropTypes.shape(Object),
    };

    UNSAFE_componentWillMount() {
        const {
            FetchEmployeeParam,
            FetchCompanyParam,
            getDocumentsTypes,
            params,
            resetForm,
        } = this.props;
        resetForm();
        getDocumentsTypes();
        if (
            _.has(this, "props.params._id") &&
            _.has(this, "props.params.company")
        ) {
            FetchEmployeeParam(params._id);
        }
        FetchCompanyParam(
            _.isUndefined(params.company) ? params._id : params.company
        );
    }

    handleSaveEmployee = (formData) => {
        const {
            createEmployee,
            toggleSnackbar,
            resetForm,
            params,
        } = this.props;
        const editMode = _.has(params, "_id") && _.has(params, "company");
        createEmployee(formData, editMode)
            .then(
                (data) => {
                    if (
                        _.get(data, "error") ||
                        _.get(data, "payload.status") !== 200
                    ) {
                        toggleSnackbar(true, LBLEMPLOYESS.msg.errorSave);
                    } else {
                        resetForm();
                        toggleSnackbar(true, LBLEMPLOYESS.msg.successSave);
                        const idCompany = _.has(this, "props.params.company")
                            ? _.get(this, "props.params.company")
                            : _.get(this, "props.params._id");
                        browserHistory.push(
                            `/admin/employees/grid/${_.get(
                                params,
                                "profile"
                            )}/${idCompany}`
                        );
                    }
                },
                (reason) => {
                    toggleSnackbar(true, LBLEMPLOYESS.msg.errorSave);
                }
            )
            .catch((data) => {
                toggleSnackbar(true, LBLEMPLOYESS.msg.errorSave);
            });
    };

    render() {
        const {
            asyncValidating,
            asyncValidatingEmail,
            fields: {
                documentType,
                id,
                _id,
                company,
                name,
                lastName,
                email,
                tags,
            },
            handleSubmit,
            params,
            documentsTypes,
            nameCompany,
        } = this.props;
        const editMode =
            _.has(this, "props.params._id") &&
            _.has(this, "props.params.company");
        const idBack = _.isUndefined(_.get(params, "company"))
            ? _.get(params, "_id")
            : _.get(params, "company");
        return (
            <Row style={{ marginBottom: 90 }}>
                <AuthComponent
                    component={
                        <Col xs={12} mdOffset={2} md={8}>
                            {!_.isNull(nameCompany) ? (
                                <Paper style={{ ...styles.stylePaper }}>
                                    <Row>
                                        <Col xs>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    background: blue600,
                                                    paddingTop: 5,
                                                    paddingBottom: 5,
                                                    marginBottom: 25,
                                                    color: "#FFF",
                                                }}
                                            >
                                                <h1
                                                    style={{
                                                        textAlign: "center",
                                                        fontWeight: 400,
                                                        lineHeight: 1.3,
                                                    }}
                                                >
                                                    {nameCompany}
                                                </h1>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xsOffset={2} xs={8}>
                                            <ButtonBack
                                                url={`/admin/employees/grid/${_.get(
                                                    params,
                                                    "profile"
                                                )}/${idBack}`}
                                            />
                                            <h1
                                                style={{
                                                    textAlign: "center",
                                                    fontWeight: 400,
                                                }}
                                            >
                                                {editMode
                                                    ? LBLEMPLOYESS.titleEdit
                                                    : LBLEMPLOYESS.titleCreate}
                                            </h1>
                                            <form
                                                onSubmit={handleSubmit(
                                                    this.handleSaveEmployee
                                                )}
                                            >
                                                <Row>
                                                    <Col xs>
                                                        <SelectFieldFormat
                                                            config={{
                                                                floatingLabelText:
                                                                    LBLEMPLOYESS.typeId,
                                                                value:
                                                                    documentType.value,
                                                                disabled: editMode,
                                                                errorText:
                                                                    documentType.touched &&
                                                                    documentType.error
                                                                        ? documentType.error
                                                                        : "",
                                                                onChange: (
                                                                    e,
                                                                    index,
                                                                    value
                                                                ) =>
                                                                    documentType.onChange(
                                                                        value
                                                                    ),
                                                            }}
                                                        >
                                                            {_.map(
                                                                documentsTypes,
                                                                (item) => (
                                                                    <MenuItem
                                                                        key={
                                                                            item._id
                                                                        }
                                                                        value={
                                                                            item._id
                                                                        }
                                                                        primaryText={
                                                                            item.value
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </SelectFieldFormat>
                                                    </Col>
                                                    <Col xs>
                                                        <TextField
                                                            hintText=""
                                                            floatingLabelText={
                                                                LBLEMPLOYESS.id
                                                            }
                                                            type="text"
                                                            disabled={editMode}
                                                            errorText={
                                                                id.touched &&
                                                                id.error
                                                                    ? id.error
                                                                    : ""
                                                            }
                                                            {...id}
                                                        />
                                                        {asyncValidating ===
                                                            "id" && (
                                                            <CircularProgress
                                                                size={25}
                                                                thickness={2}
                                                                style={{
                                                                    position:
                                                                        "absolute",
                                                                    float:
                                                                        "right",
                                                                    marginTop: 35,
                                                                }}
                                                            />
                                                        )}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs>
                                                        <TextField
                                                            hintText=""
                                                            floatingLabelText={
                                                                LBLEMPLOYESS.names
                                                            }
                                                            type="text"
                                                            errorText={
                                                                name.touched &&
                                                                name.error
                                                                    ? name.error
                                                                    : ""
                                                            }
                                                            {...name}
                                                        />
                                                    </Col>
                                                    <Col xs>
                                                        <TextField
                                                            hintText=""
                                                            floatingLabelText={
                                                                LBLEMPLOYESS.lastNames
                                                            }
                                                            type="text"
                                                            errorText={
                                                                lastName.touched &&
                                                                lastName.error
                                                                    ? lastName.error
                                                                    : ""
                                                            }
                                                            {...lastName}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs>
                                                        <TextField
                                                            hintText={
                                                                LBLEMPLOYESS
                                                                    .hintText
                                                                    .email
                                                            }
                                                            floatingLabelText={
                                                                LBLEMPLOYESS.email
                                                            }
                                                            type="email"
                                                            errorText={
                                                                email.touched &&
                                                                email.error
                                                                    ? email.error
                                                                    : ""
                                                            }
                                                            {...email}
                                                        />
                                                        {asyncValidatingEmail ===
                                                            "email" && (
                                                            <i /* spinning cog */
                                                            />
                                                        )}
                                                    </Col>
                                                </Row>
                                                {tags.map((tag, index) => (
                                                    <Row key={index}>
                                                        <Col xs>
                                                            <PureInputAutoComplete
                                                                TextField={
                                                                    tag.title
                                                                        .value
                                                                }
                                                                floatingLabelText={
                                                                    tag.title
                                                                        .value
                                                                }
                                                                type="text"
                                                                errorText={
                                                                    tag.value
                                                                        .touched &&
                                                                    tag.value
                                                                        .error
                                                                        ? tag
                                                                              .value
                                                                              .error
                                                                        : ""
                                                                }
                                                                field={
                                                                    tag.value
                                                                }
                                                                multiLine
                                                                fullWidth
                                                                rows={1}
                                                                dataSource={
                                                                    tag.dataSource
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                ))}
                                                <AuthComponent
                                                    component={
                                                        <RaisedButton
                                                            type="submit"
                                                            label={
                                                                editMode
                                                                    ? LBLEMPLOYESS
                                                                          .buttons
                                                                          .update
                                                                    : LBLEMPLOYESS
                                                                          .buttons
                                                                          .save
                                                            }
                                                            secondary
                                                            style={{
                                                                ...styles.buttonSubmit,
                                                            }}
                                                        />
                                                    }
                                                    permission={
                                                        editMode
                                                            ? "cgr_employee_update"
                                                            : "cgr_employee_create"
                                                    }
                                                    type="component"
                                                />
                                            </form>
                                        </Col>
                                    </Row>
                                </Paper>
                            ) : (
                                <Paper style={{ ...styles.stylePaper }}>
                                    <Row>
                                        <Col xs style={{ textAlign: "center" }}>
                                            <CircularProgress
                                                size={55}
                                                thickness={4}
                                                style={{ margin: "30px auto" }}
                                            />
                                        </Col>
                                    </Row>
                                </Paper>
                            )}
                        </Col>
                    }
                    permission={
                        editMode ? "cgr_employee_update" : "cgr_employee_create"
                    }
                    type="url"
                />
            </Row>
        );
    }
}

const requireFields = (...names) => (data) =>
    names.reduce((errors, index) => {
        if (!data[index]) {
            errors[index] = LBLEMPLOYESS.validations.isRequired;
        }
        return errors;
    }, {});

const validateTags = requireFields("value");

const validate = (values) => {
    const errors = {};
    if (!values.documentType) {
        errors.documentType = LBLEMPLOYESS.validations.typeId;
    }
    if (!values.id) {
        errors.id = LBLEMPLOYESS.validations.id;
    } else if (!values.id.match(EXPRESSION_IDENTIFICACION)) {
        errors.id = LBLEMPLOYESS.validations.validateId;
    }
    if (!values.name) {
        errors.name = LBLEMPLOYESS.validations.names;
    }
    if (!values.lastName) {
        errors.lastName = LBLEMPLOYESS.validations.lastNames;
    }
    if (!values.email) {
        errors.email = LBLEMPLOYESS.validations.email;
    } else if (!is.email(values.email)) {
        errors.email = LBLEMPLOYESS.validations.validateEmail;
    }

    errors.tags = values.tags.map(validateTags);

    return errors;
};

const asyncValidate = (values /* , dispatch */) =>
    new Promise((resolve, reject) => {
        if (values.id) {
            axiosAPI
                .get(`/employees/id`, {
                    params: {
                        company: values.company,
                        id: values.id,
                    },
                })
                .then((data) => {
                    const resp = _.get(data.data, "data");
                    if (!_.isNull(resp)) {
                        if (_.isUndefined(values._id)) {
                            reject({ id: LBLEMPLOYESS.validations.existId });
                        } else {
                            resolve();
                        }
                    } else {
                        resolve();
                    }
                })
                .catch((data) => {
                    console.log("Error", data);
                });
        }
    });

function validateEmailUnique(correo) {
    axiosAPI
        .get(`/employees/id/email`, {
            params: {
                email: correo,
            },
        })
        .then((dataEmail) => {
            const respuesta = _.get(dataEmail.data, "data");
            if (!_.isNull(respuesta)) {
                reject({ email: LBLEMPLOYESS.validations.existEmail });
            } else {
                resolve();
            }
        })
        .catch((data) => {
            console.log("Error", data);
        });
}

function getValueTagsValue(titleTag, valueform) {
    let valueTagForm = "";
    _.map(valueform, (value) => {
        if (_.isEqual(_.get(value, "title"), titleTag)) {
            valueTagForm = _.get(value, "value");
        }
    });
    return valueTagForm;
}

function mapStateToProps({ employees, companies, master }, ownProps) {
    if (_.has(ownProps, "params._id") && _.has(ownProps, "params.company")) {
        const data = _.get(employees.employee, "data");
        const tags = _.isUndefined(_.get(companies, "company.data.tags"))
            ? []
            : _.get(companies, "company.data.tags");
        let formEmployee;
        if (data) {
            formEmployee = {
                email: _.get(data, "person.email"),
                documentType: _.get(data, "person.documentType"),
                id: _.get(data, "person.id"),
                _id: _.get(ownProps, "params._id"),
                company: _.get(ownProps, "params.company"),
                name: _.get(data, "person.name"),
                lastName: _.get(data, "person.lastName"),
                tags: tags.map((tag) => ({
                    title: _.get(tag, "value"),
                    value: getValueTagsValue(
                        _.get(tag, "value"),
                        _.isUndefined(_.get(data, "tags"))
                            ? []
                            : _.get(data, "tags")
                    ),
                    dataSource: _.get(tag, "dataSource"),
                })),
            };
        }
        return {
            nameCompany: _.get(companies, "company.data.businessName", null),
            findCompany: _.get(companies, "company.data"),
            documentsTypes: master.documentsTypes,
            initialValues: formEmployee,
        };
    }
    const tags = _.isUndefined(_.get(companies, "company.data.tags"))
        ? []
        : _.get(companies, "company.data.tags");
    return {
        nameCompany: _.get(companies, "company.data.businessName", null),
        findCompany: _.get(companies, "company.data"),
        documentsTypes: master.documentsTypes,
        initialValues: {
            documentType: "",
            id: "",
            name: "",
            lastName: "",
            email: "",
            company: _.get(ownProps, "params._id"),
            tags: tags.map((tag) => ({
                title: _.get(tag, "value"),
                value: "",
                dataSource: _.get(tag, "dataSource"),
            })),
        },
    };
}

export default reduxForm(
    {
        form: "EmployeesNewForm",
        fields: [
            "documentType",
            "id",
            "_id",
            "company",
            "name",
            "lastName",
            "email",
            "tags[].title",
            "tags[].value",
            "tags[].dataSource[]",
        ],
        asyncValidate,
        asyncBlurFields: ["id", "email"],
        validate,
    },
    mapStateToProps,
    {
        createEmployee,
        FetchEmployeeParam,
        toggleSnackbar,
        FetchCompanyParam,
        getDocumentsTypes,
    }
)(EmployeesNews);
