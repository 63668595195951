import * as Rx from 'rxjs';
import isNil from 'lodash/isNil';
import {PEGASUS_URL} from '../../../constants/index';
import {Map, List} from 'immutable';

// constants
export const MESSAGES_STATUS = {
    READ: 'READ',
    UNREAD: 'UNREAD'
};

export const STATUS_NO_CONNECTED = 'STATUS_NO_CONNECTED';
export const STATUS_CHANGE = 'nss/pegasus/status_change';
export const STATUS_ENDED = 'nss/pegasus/conversation-ended'

export const ADD_MESSAGE = 'nss/pegasus/add-message';
export const ADD_CONVERSATION = 'nss/pegasus/add-conversation';
export const CLEAN_STATE = 'nss/pegasus/clean-state';

export const CHANGE_CONVERSATION_ID = 'nss/pegasus/change-conversation-id';
export const UPDATE_PING = 'nss/pegasus/update-heartbeat-ping';
export const UPDATE_PONG = 'nss/pegasus/update-heartbeat-pong';

const predicateUnreadMessage = message => message.uiStatus === MESSAGES_STATUS.UNREAD;

function isMessage(wsMessage){
    return wsMessage.type === 'message';
}

export function mapConnectionLabel(label, text) {
    switch (label) {
        case 'queue':
            return 'En espera...';
        case 'STATUS_NO_CONNECTED':
            return 'Conectando...';
        case 'dequeue':
            if(!_.isUndefined(text) && !_.isNull(text) && !_.isEqual(text, "")){
                return text;
            }
            return 'Conectado';
        case STATUS_ENDED:
            return 'Terminada';
        default:
            return '';
    }
}

export function mapConnectionColor(label) {
    switch (label) {
        case 'queue':
            return '#FE9A2E';
        case 'STATUS_NO_CONNECTED':
            return '#A4A4A4';
        case 'dequeue':
            return '#01DF01';
        case STATUS_ENDED:
            return '#FE2E2E';
        default:
            return '#CCC';
    }
}

export function isFinalizedChat(label) {
    switch (label) {
        case STATUS_ENDED:
            return true;
        default:
            return false;
    }
}

export function connectionHandler(projectsId, person, as, initialMessages = []) {
    const subject = Rx
        .Observable
        .webSocket({
            url: `${PEGASUS_URL}?id=${person}`,
            openObserver: {
                next: () => {
                    // Now it is safe to send a message
                    console.info('socket open');
                    // build the registration message as an employee
                    const registrationMessage = {
                        type: 'register',
                        as,
                        projectsId
                    };
                    // send the registration message
                    [registrationMessage]
                        .concat(initialMessages)
                        .map(JSON.stringify)
                        .forEach(message => {
                            subject.next(message);
                        });
                }
            }
        })
        .filter(message => !isNil(message));


    return subject;
}



export function changeStateConnection(status) {
    return {type: STATUS_CHANGE, status}
}

export function setConversationId(id, evaluator) {
    return {type: CHANGE_CONVERSATION_ID, id}
}

export function addMessage(message, self, open) {
    const messageShouldBeRead = self === message.author || open === true;
    const messageWithStatus = _.assign({}, message, {
        uiStatus: messageShouldBeRead ? MESSAGES_STATUS.READ : MESSAGES_STATUS.UNREAD
    });
    return {
        type: ADD_MESSAGE,
        message: messageWithStatus
    };
}

export function cleanState() {
    return {
        type: CLEAN_STATE
    }
}

export function updateHeartbeat(type, id){
    return {
        type,
        id
    }
}

export function getCounterOfUnreadMessages(conversations = Map()) {
    return conversations
        .toList()
        .flatMap(listMessages => {
            return listMessages.filter(predicateUnreadMessage)
        })
        .count();
}

export function getCounterOnUnreadMessagesSingleConversation(messages = Map(), conversation) {
    return (messages.get(conversation) || List())
        .count(predicateUnreadMessage);
}

export function getCounterUnreadEmployee(messages = List()) {
    return messages.count(predicateUnreadMessage);
}

export function createSoundQueue(person) {
    const subject = new Rx.BehaviorSubject()
        .filter(item => !isNil(item))
        .filter(isMessage)
        .filter(message => person !== message.author)
        .debounceTime(1000)
        .mapTo(new Audio('https://s3.amazonaws.com/nss.resources/notificationCGR.m4a'));

    subject.subscribe(audio => audio.play());

    return subject;
}
