import { GET_TEMPLATE, POST_TEMPLATE, DELETE_TEMPLATE, TEMPLATE_GRID } from '../constants/index';
import { axiosAPI_V2 } from '../middleware/api';

export function createTemplate(props, editMode) {

    let request = null;
    if(editMode){
        request = axiosAPI_V2.put(`/templateEmail`, { "template": props});
    } else {
        request = axiosAPI_V2.post(`/templateEmail`, { "template": props});
    }

    return {
        type: POST_TEMPLATE,
        payload: request
    }

}

export function getTemplates() {

    const request = axiosAPI_V2.get(`/templateEmail`);

    return {
        type: TEMPLATE_GRID,
        payload: request
    }
}

export function getTemplate(id) {

    const request = axiosAPI_V2.get(`/templateEmail/${id}`);

    return {
        type: GET_TEMPLATE,
        payload: request
    }
}

export function deleteTemplate(templateId) {

    const request = axiosAPI_V2.delete(`/templateEmail/${templateId}`, {data: {type: 'logic'}});

    return {
        type: DELETE_TEMPLATE,
        payload: request,
        meta: {
            templateId
        }
    }
}
