import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow} from 'material-ui/Table';
import SectionItem from './sectionItems';
import _ from 'lodash';

let count = 0;
let countText = "";

class SectionQuestionsList extends Component {
    constructor(props) {
        super(props);
        this._mapQuestions = this._mapQuestions.bind(this);
    }

    _mapQuestions(question, index) {
        if(index === 0){
            count = 0;
        }
        const {upQuestions, downQuestions, removeQuestions, questions} = this.props;
        count++;
        let num = 0;
        if(_.has(question, 'sizeList')){
            num = _.get(question, 'sizeList') instanceof Array ? _.get(question, 'sizeList[0]') : _.get(question, 'sizeList');
        }
        if(_.has(question, 'sizeList') && num > 0 && num > 1){
            countText = count + " al " + (count + (num - 1));
            count += (num - 1);
        } else {
            countText = count;
        }
        return (
            <SectionItem
                key={index}
                index={index}
                titleIndex={countText}
                question={question}
                questionsSize={questions.count()}
                upQuestions={upQuestions}
                downQuestions={downQuestions}
                removeQuestions={removeQuestions}
            />
        );
    }

    render() {
        const {questions} = this.props;
        return (
            <Table selectable={false}>
                <TableHeader displaySelectAll={false}>
                    <TableRow>
                        <TableHeaderColumn>
                        </TableHeaderColumn>
                    </TableRow>
                </TableHeader>
                <TableBody displayRowCheckbox={false}>
                    {questions.map(this._mapQuestions)}
                </TableBody>
            </Table>
        );
    }
}

export default SectionQuestionsList;
