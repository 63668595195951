import React, {Component} from 'react';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn,TableFooter} from 'material-ui/Table';
import {blue700, blue600} from 'material-ui/styles/colors';
import {Row, Col} from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import IconButton from 'material-ui/IconButton';
import EditIcon from 'material-ui/svg-icons/editor/mode-edit';
import {LBLEMPLOYESS} from '../../constants/labels';
import FloatingActionButtonTooltip from '../fields/FloatingActionButtonTooltip/FloatingActionButtonTooltip';
import DeleteEntityModal from '../deleteEntityModal/component';
import { FetchCompanyParam } from '../../actions/info/companies_info_form';
import ButtonBack from '../fields/buttonBack/buttonBack';
import DownloadFile from '../../components/fields/DownloadFile/DownloadFile';
import UploadFile from '../../components/fields/UploadFile/UploadFile';
import MenuItem from 'material-ui/MenuItem';
import SelectFieldFormat from '../../components/fields/SelectFieldFormat/SelectFieldFormat';
import _ from 'lodash';
import RaisedButton from 'material-ui/RaisedButton';
import AuthComponent from '../../components/AuthComponent';
import SpinnerLoading from '../../components/SpinnerLoading/componentSpinner';
import TablePagination from '../TablePagination/tablePagination';
import TagsEmployee from '../TagsEmployee/TagsEmployee';

const stylePaper = {
        marginTop: 20,
        marginBottom: 20
    }, configDeleteModal = {
        display: "table-cell"
    };

class EmployeesGrid extends Component {

    constructor(props) {
        super(props);
    }

    UNSAFE_componentWillMount() {
        const {
            params,
            FetchCompanyParam
        } = this.props;
        FetchCompanyParam(params._id);
    }

    render() {
        const { params, textValueTag, textTag } = this.props;

        let urlBack = `/admin/companies/info/${_.get(params, '_id')}`;
        if(_.isEqual(_.get(params, 'profile'), "ref")){
            urlBack = `/admin/companies/info/projects/${_.get(params, '_id')}`;
        }

        const body = [
            {
                field: "id",
                title: "Identificación",
                filter: true,
            },
            {
                field: "nameText",
                title: "Nombre",
                filter: true,
            },
            {
                field: "lastNameText",
                title: "Apellido(s)",
                filter: true,
            },
            {
                field: "email",
                title: "Correo electrónico"
            },
            {
                actions: true,
                title: "Operaciones"
            }
        ];
        let extra = { company: _.get(params, '_id'), profile: _.get(params, 'profile') };
        if(!_.isNull(textValueTag) && !_.isNull(textTag)){
            extra = _.chain(extra).set('textTag', textTag).set('textValueTag', textValueTag).value();
        }
        return (
            <AuthComponent
                component={
                    <Row>
                        <Col xs={12} mdOffset={2} md={8}>
                            <Paper style={stylePaper}>
                                <ButtonBack url={urlBack}/>
                                <Row>
                                    <Col xs>
                                        <div style={{width: '100%', background: blue600, paddingTop: 5, paddingBottom:5, color: "#FFF"}}>
                                            <h1 style={{textAlign: 'center', fontWeight: 400}}>
                                                {_.get(this, "props.company.businessName")}
                                            </h1>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <div style={{width: '100%', background: blue700, padding: '5px 0', color: "#FFF"}}>
                                            <h2 style={{textAlign: 'center', fontWeight: 400}}>
                                                {LBLEMPLOYESS.titleGrid}
                                            </h2>
                                        </div>
                                    </Col>
                                </Row>
                                <AuthComponent
                                    component={
                                        <Row style={{paddingTop: 15, paddingBottom: 15}}>
                                            <Col xs style={{textAlign: 'center'}}>
                                                <DownloadFile storeName="employees" id={_.get(this, 'props.params._id')}/>
                                            </Col>
                                            <Col xs style={{textAlign: 'center'}}>
                                                <UploadFile storeName="employees" id={_.get(this, 'props.params._id')} />
                                            </Col>
                                        </Row>
                                    }
                                    permission={"cgr_employee_load_massive"}
                                    type={"component"}
                                />
                                <AuthComponent
                                    component={
                                        <Link to={`/admin/employees/create/${_.get(params, 'profile')}/${this.props.params._id}`}>
                                            <FloatingActionButtonTooltip textTooltip={LBLEMPLOYESS.tooltips.btnCreate}/>
                                        </Link>
                                    }
                                    permission={"cgr_employee_create"}
                                    type={"component"}
                                />
                                <Row>
                                    <Col xs>
                                        <TagsEmployee
                                            companyId={_.get(params, '_id')}
                                            URL={`/employees/${_.get(params, '_id')}`}
                                            version={1}
                                            extra={{company: _.get(params, '_id'), profile: _.get(params, 'profile')}}
                                            body={body}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <TablePagination
                                            name={`employees`}
                                            URL={`/employees/${_.get(params, '_id')}`}
                                            version={1}
                                            body={body}
                                            selection={true}
                                            fieldSelection={"_id"}
                                            extra={extra}
                                        />
                                    </Col>
                                </Row>
                            </Paper>
                        </Col>
                        <SpinnerLoading/>
                    </Row>
                }
                permission={"cgr_employee_list"}
                type={"url"}
            />
        );
    }
}

function mapStateToProps({ employees, companies, tagsEmployee }) {
    return {
        company: companies.company.data,
        textValueTag: tagsEmployee.get("textValueTag"),
        textTag: tagsEmployee.get("textTag")
    };
}

export default connect(mapStateToProps, { FetchCompanyParam })(EmployeesGrid);
