import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TextField from 'material-ui/TextField';
import _ from 'lodash';
import {connect} from 'react-redux';
import Title from './Title';

const styleWidthContent = {
    minWidth: 240
};

class QuestionOpen extends Component {

    constructor(props) {
        super(props);
        this._onChange = this._onChange.bind(this);
    }

    _onChange(e) {
        this.props._handlerUpdateValue(e, e.target.value);
    }

    render() {
        const {
            question,
            type,
            index,
            valueQuestion
        } = this.props;
        return (
            <div style={styleWidthContent}>
                <div>
                    <Title question={_.has(question, 'fieldName') ? question : _.get(question, '_id')} index={index} />
                    <TextField
                        hintText=""
                        floatingLabelText="Respuesta"
                        multiLine={type === "text"}
                        fullWidth={true}
                        rows={type === "text" ? 2 : 1}
                        type={type === "textShort" ? "text" : type}
                        style={{marginTop: -10}}
                        onChange={this._onChange}
                        value={valueQuestion}
                    />
                </div>
            </div>
        );
    }
}

QuestionOpen.propTypes = {
    question: PropTypes.object.isRequired,
    type: PropTypes.oneOf(['text', 'number', 'textShort']).isRequired
};

function mapStateToProps({ questionsSurvey }, ownerProps) {
    return {
        valueQuestion: _.get(questionsSurvey.get('answered').toObject(), _.get(ownerProps, 'idQuestion'))
    };
}

export default connect(mapStateToProps)(QuestionOpen);
