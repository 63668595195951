import React, {Component} from 'react';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';





import {Row, Col} from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';
import {TableFooter} from 'material-ui/Table';
import TextField from 'material-ui/TextField';
import Toggle from 'material-ui/Toggle';
import RaisedButton from 'material-ui/RaisedButton';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FetchRefereeParam } from '../../actions/info/referees_info_form';

const style = {
        margin: 20,
        float: 'right'
    },
    stylePaper = {
        marginTop: 20,
        marginBottom: 20
    },
    styleTable = {
        margin: 'auto',
        width: '90%'
    },
    styleButton = {
        margin: 12,
    };

const stylePopover = {
    padding: 20,
};

class RefereesInfo extends Component {
    UNSAFE_componentWillMount(){
        this.props.FetchRefereeParam(this.props.params._id, this.props.params.id);
    }
    renderReferee(){
        if(this.props.referees.data){
            return (
                <table style={styleTable}>
                    <tbody>
                        <tr>
                            <td colSpan='3'>
                                <h1 style={{textAlign: 'center', fontWeight: 400}}> IAS </h1>
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Cedula</strong></td>
                            <td colSpan='2'>{this.props.employees.data.id}</td>
                        </tr>
                        <tr>
                            <td><strong>Teléfono</strong></td>
                            <td colSpan='2'>{this.props.referees.data.name}</td>
                        </tr>
                        <tr>
                            <td><strong>Correo electrónico</strong></td>
                            <td colSpan='2'>{this.props.referees.data.lasName}</td>
                        </tr>
                        <tr>
                            <td>
                                <RaisedButton label="Actualizar Evaluador" secondary={true} style={styleButton} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            );
        }
    }
    render() {
        return (
          <Row>
              <Col xsOffset={2} xs={8}>
                  <Paper style={stylePaper}>
                      <Row>
                          <Col xs>
                              {this.renderReferee()}
                          </Col>
                      </Row>
                  </Paper>
              </Col>
          </Row>
        );
    }
}

function mapStateToProps(state){
    return  { referees: state.referees.all };
}

export default connect (mapStateToProps, { FetchRefereeParam })(RefereesInfo);
