import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {select, scaleBand, scaleLinear, max, axisBottom, axisLeft} from 'd3';
import Faux from 'react-faux-dom';
import AnalyticsItem from './AnalyticsItem';

const margin = {top: 20, right: 20, bottom: 30, left: 60};

class BarChart extends Component {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        data: PropTypes.arrayOf(AnalyticsItem).isRequired,
        height: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
        yLabel: PropTypes.string,
        xLabel: PropTypes.string,
        selectedItem: PropTypes.instanceOf(AnalyticsItem)
    };

    render() {
        const {width, height, yLabel, xLabel, selectedItem} = this.props;
        const castData: Array<AnalyticsItem> = this.props.data;
        const faux = Faux.createElement('div');
        const svg = select(faux)
            .append("svg")
            .attr('height', height)
            .attr('width', width);

        const widthSvg = +width - margin.left - margin.right;
        const heightSvg = +height - margin.top - margin.bottom;


        const x = scaleBand()
            .rangeRound([0, widthSvg])
            .padding(0.1)
            .domain(castData.map(item => {
                return item.index;
            }));

        const y = scaleLinear()
            .rangeRound([heightSvg, 0])
            .domain([0, max(castData, function (d: AnalyticsItem) {
                return d.value;
            })]);

        const g = svg.append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        g.append("g")
            .attr("class", "axis axis--x")
            .attr("transform", "translate(0," + heightSvg + ")")
            .call(axisBottom(x));

        const yAxis = g.append("g")
            .attr("class", "axis axis--y")
            .call(axisLeft(y).ticks(5))
            .append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 6)
            .attr("dy", "0.71em")
            .attr("text-anchor", "end")
            .text("Frequency");

        // text label for the x axis
        g.append("text")
            .attr("transform", `translate( ${(widthSvg / 2)}, ${(heightSvg + margin.top + 5)})`)
            .style("text-anchor", "middle")
            .text(xLabel);

        const bars = g.selectAll(".bar")
            .data(castData)
            .enter().append("rect")
            .attr("class", "bar")
            .attr("x", function (d: AnalyticsItem) {
                return x(d.index);
            })
            .attr("y", function (d: AnalyticsItem) {
                return y(d.value);
            })
            .attr("width", x.bandwidth())
            .attr("height", function (d: AnalyticsItem) {
                return heightSvg - y(d.value);
            })
            .attr('fill', function (d: AnalyticsItem) {
                return d.color;
            });

        // text label for the y axis
        g.append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 0 - margin.left)
            .attr("x", 0 - (heightSvg / 2))
            .attr("dy", "1em")
            .style("text-anchor", "middle")
            .text(yLabel);


        return faux.toReact();
    }
}

export default BarChart;
