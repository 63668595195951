import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TextField from "material-ui/TextField";
import PureInputColorPicker from "./PureInputColorPicker";
import { updateComponent } from "../../ducks";
import WaitComponent from "../waitComponent";

class ShadowComponent extends Component {
    static propTypes = {
        survey: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        page: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        loadingConfiguration: PropTypes.bool.isRequired,
    };

    onChangeValue = (e, numValue) => {
        const { updateComponent, survey, page, path, value } = this.props;
        const arrayValue = _.split(value, " ", 5);
        arrayValue[2] = `${numValue}px`;
        updateComponent(path, { boxShadow: _.trim(_.reduce(arrayValue, (sum, v) => `${sum} ${v}`, "")) }, page, survey);
    };

    onChangeValueColor = color => {
        const { updateComponent, survey, page, path, value } = this.props;
        const arrayValue = _.split(value, " ", 5);
        arrayValue[4] = color;
        updateComponent(path, { boxShadow: _.trim(_.reduce(arrayValue, (sum, v) => `${sum} ${v}`, "")) }, page, survey);
    };

    render() {
        const { value, loadingConfiguration } = this.props;
        const arrayValue = _.split(value, " ", 5);
        const valueSize = _.toNumber(_.replace(_.get(arrayValue, 2), "px", ""));
        const valueColor = _.get(arrayValue, 4);
        if (loadingConfiguration) {
            return <WaitComponent />;
        }
        return (
            <div>
                <TextField
                    hintText=""
                    floatingLabelText={"Tamaño de la sombra"}
                    style={{ minWidth: 140 }}
                    type="number"
                    value={valueSize}
                    onChange={this.onChangeValue}
                />
                <div>
                    <table style={{ borderCollapse: "collapse", padding: 0, width: 362 }}>
                        <tbody>
                            <tr>
                                <td style={{ padding: "16px 3px 3px 3px", verticalAlign: "top" }}>
                                    Color de la sombra:
                                </td>
                                <td style={{ padding: 3, width: 226 }}>
                                    <PureInputColorPicker
                                        style={{ marginTop: -20, marginBottom: 5 }}
                                        onChangeFunc={this.onChangeValueColor}
                                        field={{ value: valueColor }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateComponent }, dispatch);
}

function mapStateToProps({ analyticsReportsConfiguration }, ownProps) {
    const config = analyticsReportsConfiguration.get("pageConfiguration");
    const loadingConfiguration = analyticsReportsConfiguration.get("loadingConfiguration");
    return {
        value: _.get(config, `${_.get(ownProps, "path")}.boxShadow`, _.get(ownProps, "defaultValue", "0 0 0 0 #EEE")),
        page: analyticsReportsConfiguration.get("page"),
        loadingConfiguration,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShadowComponent);
