import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-flexbox-grid';
import { bindActionCreators } from 'redux';
import { getValuesCountries, changeValueCountries, getValuesDepartments } from '../actions';
import CircularProgress from 'material-ui/CircularProgress';
import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import { getRecords } from '../../TablePagination/actions';
import SelectFieldFormat from '../../fields/SelectFieldFormat/SelectFieldFormat';
import _ from 'lodash';

class FilterCountries extends Component {

    constructor(props){
        super(props);
        this._onChangeCountries = this._onChangeCountries.bind(this);
        this._execGetRecords = this._execGetRecords.bind(this);
    }

    UNSAFE_componentWillMount() {
        const { getValuesCountries } = this.props;
        getValuesCountries();
    }

    _onChangeCountries(e, index, value){
        const { changeValueCountries, getValuesDepartments, extra } = this.props;
        let extraData = extra;
        changeValueCountries(value);
        if(!_.isEqual(value, 'all')){
            getValuesDepartments(value);
        }
        extraData = _.set(extraData, 'department', 'all');
        extraData = _.set(extraData, 'city', 'all');
        this._execGetRecords(_.set(extraData, 'country', value));
    }

    _execGetRecords(extraData){
        const {
            URL,
            version,
            skip,
            limit,
            orderName,
            orderType,
            textFilter,
            body,
            textValueTag,
            textTag,
            getRecords
        } = this.props;
        let arrayFields = [];
        _.map(body, function(field){
             if(!_.has(field, 'actions') && _.has(field, 'filter')){
                 if(_.get(field, 'filter')){
                     arrayFields.push(_.get(field, 'field'));
                 }
             }
        });
        getRecords(URL, version, skip, limit, orderName, orderType, arrayFields, textFilter, extraData);
    }

    render() {
        const { country, countries } = this.props;
        return (
            <SelectFieldFormat
                config={{
                    floatingLabelText: "País",
                    value: country,
                    autoWidth: true,
                    onChange: this._onChangeCountries
                }}
            >
                <MenuItem key={`country0`} value={'all'} primaryText={'Todos'}/>
                {
                    _.map(_.orderBy(countries, ['name']), (item, index) => {
                        return (
                            <MenuItem key={`${_.get(item, '_id')}_ne_${index}`} value={_.get(item, '_id')} primaryText={_.capitalize(_.get(item, 'name'))}/>
                        );
                    })
                }
            </SelectFieldFormat>
        );
    }
}

function mapStateToProps({ filterCompany, tablePagination }) {
    return {
        countries: filterCompany.get("countries"),
        country: filterCompany.get("country"),
        skip: tablePagination.get('skip'),
        limit: tablePagination.get('limit'),
        orderName: tablePagination.get('orderName'),
        orderType: tablePagination.get('orderType'),
        textFilter: tablePagination.get('textFilter'),
        status: _.isNull(tablePagination.get('status')) ? 'all' : tablePagination.get('status')
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getValuesCountries,
        changeValueCountries,
        getValuesDepartments,
        getRecords
    }, dispatch);
}

FilterCountries.propTypes = {
    URL: PropTypes.string.isRequired,
    version: PropTypes.number.isRequired,
    extra: PropTypes.object.isRequired,
    body: PropTypes.array.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterCountries);
