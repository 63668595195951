import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import { blue700 } from "material-ui/styles/colors";
import { connect } from "react-redux";
import { Link } from "react-router";
import { LBLEVALUATORS } from "../../constants/labels";
import FloatingActionButtonTooltip from "../fields/FloatingActionButtonTooltip/FloatingActionButtonTooltip";
import AuthComponent from "../../components/AuthComponent";
import SendMessageModal from "../ModalSendEmail/modalSendEmail";
import SpinnerLoading from "../../components/SpinnerLoading/componentSpinner";
import TablePagination from "../TablePagination/tablePagination";

const stylePaper = {
    marginTop: 20,
    marginBottom: 20,
};

class RefereesGrid extends Component {
    render() {
        const body = [
            {
                field: "id",
                title: "Identificación",
                filter: true,
            },
            {
                field: "nameText",
                title: "Nombre",
            },
            {
                field: "lastNameText",
                title: "Apellidos",
            },
            {
                actions: true,
                title: "Operaciones",
            },
        ];
        return (
            <Row>
                <AuthComponent
                    component={
                        <Col mdOffset={2} md={8} xs={12}>
                            <Paper style={stylePaper}>
                                <Row>
                                    <Col xs>
                                        <Row>
                                            <Col xs>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        background: blue700,
                                                        paddingTop: 5,
                                                        paddingBottom: 5,
                                                        color: "#FFF",
                                                    }}
                                                >
                                                    <h1 style={{ textAlign: "center", fontWeight: 400 }}>
                                                        {LBLEVALUATORS.titleGrid}
                                                    </h1>
                                                    <AuthComponent
                                                        component={
                                                            <Link to={`/admin/referees/create`}>
                                                                <FloatingActionButtonTooltip
                                                                    textTooltip={LBLEVALUATORS.tooltips.btnCreate}
                                                                />
                                                            </Link>
                                                        }
                                                        permission={"cgr_referees_create"}
                                                        type={"component"}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs>
                                                <TablePagination
                                                    name={`referees`}
                                                    URL={`/referees`}
                                                    version={1}
                                                    body={body}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <SpinnerLoading />
                                    <SendMessageModal />
                                </Row>
                            </Paper>
                        </Col>
                    }
                    permission={"cgr_referees_list"}
                    type={"url"}
                />
            </Row>
        );
    }
}

function mapStateToProps({ referees }) {
    return {
        referees: referees.all,
    };
}

export default connect(mapStateToProps)(RefereesGrid);
