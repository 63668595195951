import PropTypes from "prop-types";
import React, { Component } from "react";
import _ from "lodash";
import { select, scaleBand, scaleLinear, max, axisBottom, axisLeft, scaleOrdinal, format } from "d3";
import { stack } from "d3-shape";
import Faux from "react-faux-dom";
import Message from "./message";
import MiniLegentGroup from "../legends/miniLegentGroup";

function getPercentage(d, total) {
    let percentage = 0;
    if (d[1] - d[0] > 0) {
        percentage = d[1] - d[0];
    }
    const full = percentage * 100 / total;
    const val = full.toFixed(1);
    return val;
}

function getValueSize(size) {
    let s = "middle";
    if ((!_.isUndefined(size) && !_.isNull(size)) || !_.isEqual(size, "")) {
        if (_.indexOf(["small", "middle", "large"], size) !== -1) {
            s = size;
        }
    }
    switch (s) {
        case "small":
            return 230;
        case "middle":
            return 320;
        case "large":
            return 410;
        default:
            return 180;
    }
}

function updateData(data) {
    const keys = _.chain(data)
        .get(0)
        .keys()
        .omit(["total"])
        .value();
    return [
        _.reduce(
            keys,
            (sum, key) => {
                if (_.get(data, `0.${key}`, 0) <= 0) {
                    return sum;
                }
                return _.set(sum, key, _.get(data, `0.${key}`));
            },
            { total: _.get(data, "0.total", 0) }
        )
    ];
}

function updateKeys(data, keysstacked) {
    const keys = _.chain(data)
        .get(0)
        .keys()
        .omit(["total"])
        .map(key => ({ key }))
        .value();
    return _.intersectionBy(keysstacked, keys, "key");
}

function hasData(data) {
    const keys = _.chain(data)
        .get(0)
        .keys()
        .filter(key => _.indexOf(["total", "index", "textIndex"], key) === -1)
        .value();
    const values = _.get(data, "0", {});
    return _.reduce(keys, (sum, key) => sum + _.get(values, key, 0), 0) === 0;
}

class BarStackedGroupFilterComponent extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        keys: PropTypes.array.isRequired,
        hasMiniLegend: PropTypes.bool,
        size: PropTypes.oneOf(["small", "middle", "large"])
    };

    render() {
        const { data, keys, size, hasMiniLegend } = this.props;
        const hml = _.isUndefined(hasMiniLegend) || _.isNull(hasMiniLegend) ? true : hasMiniLegend;
        const faux = Faux.createElement("div");
        const dimensions = getValueSize(size);
        const width = dimensions;
        const height = dimensions;
        const total = _.get(data, "0.total", 100);

        if (hasData(data)) {
            return <Message />;
        }
        const dataUpdate = updateData(data);
        const keysUpdate = updateKeys(dataUpdate, keys);

        const svg = select(faux)
            .append("svg")
            .attr("height", width * 0.17)
            .attr("width", width)
            .style("page-break-inside", "avoid");

        const widthSvg = width - 30;
        const heightSvg = width * 0.19;

        const g = svg.append("g").attr("transform", "translate(15,-5)");

        const stackKey = _.chain(keysUpdate)
            .map(k => _.get(k, "key", null))
            .filter(k => !_.isNull(k))
            .value();

        const layers = stack().keys(stackKey)(dataUpdate);
        const maxValue = _.chain(dataUpdate)
            .map(d => _.get(d, "total", 100))
            .filter(d => !_.isNull(d))
            .reduce((sum, n) => (sum > n ? sum : n), 0)
            .value();

        const x = scaleLinear()
            .rangeRound([0, widthSvg])
            .domain([0, max(layers[layers.length - 1], d => maxValue)])
            .nice();

        const y = scaleBand()
            .rangeRound([heightSvg, 0])
            .padding(0.2)
            .domain(_.map(dataUpdate, d => d.index));

        const color = scaleOrdinal(
            _.chain(keysUpdate)
                .map(k => _.get(k, "color", "#FFF"))
                .filter(k => !_.isNull(k))
                .value()
        );

        // Sombra
        /* const radialGradient = svg
            .append("defs")
            .append("linearGradient")
            .attr("id", "radial-gradient")
            .attr("x1", "100%")
            .attr("y1", "100%")
            .attr("x2", "100%")
            .attr("y2", "0%");

        radialGradient.append("stop")
            .attr("offset", "0%")
            .attr("stop-color", "#DDD");

        radialGradient.append("stop")
            .attr("offset", "100%")
            .attr("stop-color", "#FFF");

        const barsShadow = g
            .selectAll(".bar-shadow")
            .data(layers)
            .enter()
            .append("g")
            .attr("class", "bar-shadow")
            .style("fill", "url(#radial-gradient)")
            .selectAll("rect")
            .data(d => d)
            .enter()
            .append("rect")
            .attr("y", d => y(d.data.index) - 12 )
            .attr("x", d =>  x(d[0]) )
            .attr("height", y.bandwidth())
            .attr("width", d => x(d[1]) - x(d[0]) ); */

        const bars = g
            .selectAll(".bar")
            .data(layers)
            .enter()
            .append("g")
            .attr("class", "bar")
            .style("fill", (d, i) => color(i))
            .selectAll("rect")
            .data(d => d)
            .enter()
            .append("rect")
            .attr("y", d => y(d.data.index))
            .attr("x", d => x(d[0]))
            .attr("height", y.bandwidth())
            .attr("width", d => x(d[1]) - x(d[0]));

        /* const text = g
            .selectAll(".text")
            .data(layers)
            .enter()
            .append("g")
            .attr("class", "tect-text")
            .selectAll("text")
            .data(d => d)
            .enter()
            .append("text")
            .attr("class", "text")
            .attr("font-family", "Roboto")
            .attr("y", d => y(d.data.index) + y.bandwidth() / 2 + 4)
            .attr("x", d => {
                const val = getPercentage(d, total);
                let sizeText = 17;
                if (val < 10) {
                    sizeText = 14;
                }
                return (x(d[1]) - x(d[0])) / 2 + x(d[0]) - sizeText;
            })
            .text(d => {
                const val = getPercentage(d, total);
                return `${val}%`;
            }); */
        /*<div style={{ display: "inline-block", width: 66 }} className="print_pdf_component">

</div>*/
        return (
            <div style={{ textAlign: "center", width: width + 10, display: "inline-block" }}>
                {faux.toReact()}
                {hml && <MiniLegentGroup data={data} size={size} keys={keys} />}
            </div>
        );
    }
}

export default BarStackedGroupFilterComponent;
