import React, { PureComponent } from "react";
import _ from "lodash";
import { string, shape, bool, func } from "prop-types";
import Drawer from "material-ui/Drawer";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AppBar from "material-ui/AppBar";
import IconButton from "material-ui/IconButton";
import ArrowForward from "material-ui/svg-icons/navigation/arrow-forward";
import Types from "../dragComponentTypes";
import { QUESTION, INDICATOR } from "../../analyticsReport/types";
import { closeConfiguration as cc } from "../ducks";
import TitleEditor from "../propComponents/styleTitleComponent";
import RowConfiguration from "./rowConfiguration";
import SectionConfiguration from "./sectionConfiguration";
import ElementConfiguration from "./elementConfiguration";

const styles = {
    boxNull: {
        color: "#CCC",
        padding: 30,
        boxSizing: "border-box",
        textAlign: "center",
    },
    title: {
        fontWeight: 400,
        textAlign: "center",
        color: "#8A8787",
        margin: 0,
        padding: "25px 5px 10px 5px",
        width: "100%",
        boxSizing: "border-box",
        fontSize: 19,
    },
    tableContent: {
        borderCollapse: "collapse",
        padding: 0,
        width: "100%",
        boxSizing: "border-box",
        height: "100%",
    },
    tableTdContent: {
        padding: 0,
    },
    innerContent: {
        overflowX: "hidden",
        overflowY: "auto",
        height: "100%",
        width: "100%",
    },
};

function getNameProp(type) {
    switch (type) {
        case Types.ROW:
            return "- Fila";
        case Types.SECTION:
            return "- Sección";
        case Types.ELEMENT:
            return "- Elemento";
        default:
            return "";
    }
}

export function getTextTitle(elementProps) {
    switch (_.get(elementProps, "props.type")) {
        case Types.ROW:
            return "Fila";
        case Types.SECTION:
            return "Sección";
        case Types.TEXT:
            return "Texto";
        case Types.CHART:
            switch (_.get(elementProps, "props.chartProps.chartType")) {
                case Types.DEMOGRAPHIC_CHART:
                    return "Sociodemográfico";
                case Types.FILTER_GROUPS:
                    return "Grupo de filtros";
                case INDICATOR:
                    return "Indicador";
                case QUESTION:
                    return "Pregunta";
                default:
                    return "Ninguno";
            }
        case Types.RANKING:
            return "Clasificación";
        case Types.MENU:
            return "Menú";
        default:
            switch (_.get(elementProps, "type")) {
                case Types.ROW:
                    return "Fila";
                case Types.SECTION:
                    return "Sección";
                default:
                    return "Ninguno";
            }
    }
}

class ComponentConfiguration extends PureComponent {
    static propTypes = {
        currentElement: shape({
            type: string,
            path: string,
        }),
        configurationOpen: bool,
        closeConfiguration: func.isRequired,
        survey: string.isRequired,
    };

    static defaultProps = {
        currentElement: {},
        configurationOpen: false,
    };

    renderProps = () => {
        const {
            currentElement: { path, propElement },
            survey,
        } = this.props;
        switch (_.get(propElement, "type")) {
            case Types.ROW:
                return <RowConfiguration survey={survey} path={path} />;
            case Types.SECTION:
                return <SectionConfiguration survey={survey} path={path} />;
            case Types.ELEMENT:
                return (
                    <ElementConfiguration
                        survey={survey}
                        path={path}
                        propElement={propElement}
                    />
                );
            default:
                return <div style={{ ...styles.boxNull }}>(vacío)</div>;
        }
    };

    render() {
        const {
            currentElement: { path, propElement },
            configurationOpen,
            closeConfiguration,
            survey,
        } = this.props;
        const title = getTextTitle(propElement);
        return (
            <Drawer
                width={400}
                openSecondary
                open={configurationOpen}
                containerStyle={{ paddingTop: 64 }}
            >
                <table style={{ ...styles.tableContent }}>
                    <tbody>
                        <tr>
                            <td
                                style={_.assign(
                                    { ...styles.tableTdContent },
                                    { height: 62 }
                                )}
                            >
                                <AppBar
                                    titleStyle={{ fontSize: 16 }}
                                    title={`Propiedades ${getNameProp(
                                        _.get(propElement, "type")
                                    )}`}
                                    iconElementLeft={
                                        <IconButton>
                                            <ArrowForward />
                                        </IconButton>
                                    }
                                    onLeftIconButtonClick={closeConfiguration}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td style={{ ...styles.tableTdContent }}>
                                <div style={{ ...styles.innerContent }}>
                                    <h3 style={{ ...styles.title }}>{title}</h3>
                                    {this.renderProps()}
                                    <TitleEditor
                                        survey={survey}
                                        path={`${path}.title`}
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Drawer>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            closeConfiguration: cc,
        },
        dispatch
    );
}

function mapStateToProps({ analyticsReportsConfiguration }) {
    return {
        currentElement: analyticsReportsConfiguration.get(
            "currentElementConfiguration"
        ),
        configurationOpen: analyticsReportsConfiguration.get(
            "configurationOpen"
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ComponentConfiguration);
