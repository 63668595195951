import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import {blue700, orange700, green700, red700, purple700, brown700, grey300, grey600} from 'material-ui/styles/colors';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { getStatusProjectForCompanies, inSeacrchStatusProject } from '../actions';
import RaisedButton from 'material-ui/RaisedButton';
import DoughnutChartGraphicsStatus from './doughnutChartStatus/doughnutChartGraphicsStatus';
import moment from 'moment';
import _ from 'lodash';

class StatusEmployees extends Component {

    constructor(props) {
        super(props);
        this.getStatusProjectsForCompanies = this.getStatusProjectsForCompanies.bind(this);
        this.renderTableDetails = this.renderTableDetails.bind(this);
    }

    getStatusProjectsForCompanies(){
        const { projects, inSeacrchStatusProject, getStatusProjectForCompanies } = this.props;
        inSeacrchStatusProject();
        getStatusProjectForCompanies(projects);
    }

    renderGraphicStatus(){
        return (
            <DoughnutChartGraphicsStatus />
        );
    }

    renderTableDetails(){
        const { details } = this.props;
        return _.map(details, (detail, index) => {
            return (
                <Col xs={6} style={{minWidth: 320, borderLeft: `1px solid ${grey300}`, borderTop: `1px solid ${grey300}`, padding: 10}}>
                    <div style={{width: '100%', minWidth: 295, padding: 15, display: 'inline-block'}}>
                        <table key={`detail${index}`} style={{width: '100%'}}>
                            <tbody>
                                <tr>
                                    <td>
                                        <h2 style={{padding: 0, margin: 0, fontWeight: 300}}>{_.get(detail, 'name')}</h2>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h3 style={{padding: 0, margin: 0, fontWeight: 300}}>{_.get(detail, 'businessName')}</h3>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h3 style={{padding: 0, margin: '5px 0 10px 0', fontWeight: 300, color: grey600}}>
                                            {getDates(_.get(detail, 'closeDate'), _.get(detail, 'openDate'))}
                                        </h3>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4 style={{borderBottom: '2px solid #EDE969', borderLeft: '1px solid #EDE969', width: 140, padding: '0 0 0 5px', margin: '0 0 5px 0', fontWeight: 400}}>Pendientes: {_.get(detail, 'pending')}</h4>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4 style={{borderBottom: '2px solid #EB9850', borderLeft: '1px solid #EB9850', width: 140, padding: '0 0 0 5px', margin: '0 0 5px 0', fontWeight: 400}}>En proceso: {_.get(detail, 'started')}</h4>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4 style={{borderBottom: '2px solid #58C257', borderLeft: '1px solid #58C257', width: 140, padding: '0 0 0 5px', margin: '0 0 5px 0', fontWeight: 400}}>Finalizados: {_.get(detail, 'finished')}</h4>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4 style={{borderBottom: '2px solid #E34242', borderLeft: '1px solid #E34242', width: 140, padding: '0 0 0 5px', margin: '0 0 5px 0', fontWeight: 400}}>Desertores: {_.get(detail, 'deserted')}</h4>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4 style={{padding: 0, margin: '0px 0 5px 0', fontWeight: 400}}>{_.get(detail, 'count')} Colaboradores</h4>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Col>
            );
        });
    }

    render() {
        const { countProjectsSelected, countCompaniesSelected, statusProjects, inSearchStatusProjectLoad } = this.props;
        //<h3 style={{fontWeight: 300}}>Número de empresas:  {countCompaniesSelected}</h3>
        return (
            <div>
                <Row style={{paddingTop: 30, paddingTop: 10, borderTop: `0px solid ${grey300}`}}>
                    <div style={{margin: '0 0 0 35px'}}>
                        <h3 style={{fontWeight: 300}}>Número de proyectos: {countProjectsSelected}</h3>
                    </div>
                </Row>
                <Row style={{paddingTop: 30}}>
                    <Col xs>
                        {this.renderGraphicStatus()}
                    </Col>
                </Row>
                <Row style={{paddingTop: 30}}>
                    {this.renderTableDetails()}
                </Row>
            </div>
        );
    }
}

function mapStateToProps({arlDashboard, graphicAdvanceProjects, genericTablePagination}) {
    const storeProjects = genericTablePagination.get("projectsARL");
    const storeCompanies = genericTablePagination.get("companies");
    return {
        projects: storeProjects.get("selected"),
        countCompaniesSelected: _.size(storeCompanies.get("selected")),
        countProjectsSelected: _.size(storeProjects.get("selected")),
        statusProjects: arlDashboard.get('statusProjects'),
        inSearchStatusProjectLoad: arlDashboard.get('inSearchStatusProjectLoad'),
        details: graphicAdvanceProjects.get('details')
    };
}

function getDates(closeDate, openDate){
    return `Del ${formatDate(openDate)} al ${formatDate(closeDate)}`;
}

function formatDate(date){
    return moment(date).format("DD/MM/YYYY");
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getStatusProjectForCompanies,
        inSeacrchStatusProject
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusEmployees);
