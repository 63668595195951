import PropTypes from "prop-types";
import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import _ from "lodash";
import IconButton from "material-ui/IconButton";
import RefreshIcon from "material-ui/svg-icons/navigation/refresh";
import CircularProgress from "material-ui/CircularProgress";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Doughnut } from "react-chartjs-2";
import { getAdvanceProject, inSearchProjectGraphic } from "./actions";

const options = {
    // Boolean - Whether we should show a stroke on each segment
    segmentShowStroke: true,

    // String - The colour of each segment stroke
    segmentStrokeColor: "#fff",

    // Number - The width of each segment stroke
    segmentStrokeWidth: 0,

    // Number - The percentage of the chart that we cut out of the middle
    percentageInnerCutout: 50, // This is 0 for Pie charts

    // Number - Amount of animation steps
    animationSteps: 100,

    // String - Animation easing effect
    animationEasing: "easeOutBounce",

    // Boolean - Whether we animate the rotation of the Doughnut
    animateRotate: true,

    // Boolean - Whether we animate scaling the Doughnut from the centre
    animateScale: false,

    //String - A legend template
    legendTemplate:
        '<ul class="<%=name.toLowerCase()%>-legend"><% for (var i=0; i<segments.length; i++){%><li><span style="background-color:<%=segments[i].fillColor%>"></span><%if(segments[i].label){%><%=segments[i].label%><%}%></li><%}%></ul>',

    legend: {
        display: false,
    },
};

function parseData(data = []) {
    return data.reduce(
        (acc, current) => {
            return {
                labels: acc.labels.concat(current.label),
                data: acc.data.concat(current.value),
                backgroundColor: acc.backgroundColor.concat(current.color),
                hoverBackgroundColor: acc.hoverBackgroundColor.concat(
                    current.highlight
                ),
            };
        },
        {
            labels: [],
            data: [],
            backgroundColor: [],
            hoverBackgroundColor: [],
        }
    );
}

class DoughnutChartGraphics extends Component {
    constructor(props) {
        super(props);
        this._toUpdateGraphic = this._toUpdateGraphic.bind(this);
    }

    UNSAFE_componentWillMount() {
        this._toUpdateGraphic();
    }

    _toUpdateGraphic() {
        const {
            projectId,
            getAdvanceProject,
            inSearchProjectGraphic,
        } = this.props;
        inSearchProjectGraphic();
        getAdvanceProject(projectId);
    }

    renderListValues(data) {
        if (data instanceof Array) {
            if (_.size(data)) {
                return data.map((value, index) => {
                    return (
                        <tr key={index}>
                            <td style={{ width: 20 }}>
                                <div
                                    style={{
                                        background: value.color,
                                        width: 20,
                                        height: 20,
                                        borderRadius: "100%",
                                    }}
                                ></div>
                            </td>
                            <td
                                style={{
                                    paddingLeft: 7,
                                    minWidth: 120,
                                    maxWidth: 200,
                                }}
                            >
                                <h3
                                    style={{ fontWeight: 200, margin: "4px 0" }}
                                >{`${value.label} (${value.value})`}</h3>
                            </td>
                        </tr>
                    );
                });
            }
        }
    }

    render() {
        const { advance, inSearch, height, width } = this.props;
        if (!_.isNull(advance)) {
            const hei = _.isUndefined(height) ? "250" : height + "";
            const wid = _.isUndefined(width) ? "250" : width + "";
            const dat = _.isUndefined(advance.data) ? [] : advance.data;
            const count = _.isUndefined(advance.count) ? "0" : advance.count;
            const parsedData = parseData(dat);
            return (
                <Row style={{ marginTop: 40, marginBottom: 5 }}>
                    <Col xs>
                        <div
                            style={{
                                width: parseInt(wid),
                                marginLeft: "auto",
                                paddingRight: 15,
                            }}
                        >
                            {inSearch ? (
                                <CircularProgress
                                    size={35}
                                    thickness={2}
                                    style={{
                                        marginTop: 108,
                                        marginLeft: 108,
                                        position: "absolute",
                                    }}
                                />
                            ) : (
                                <IconButton
                                    tooltip={"Actualizar"}
                                    style={{
                                        marginTop: 100,
                                        marginLeft: 100,
                                        position: "absolute",
                                    }}
                                    tooltipPosition="top-center"
                                    onClick={this._toUpdateGraphic}
                                    children={<RefreshIcon />}
                                />
                            )}
                            <Doughnut
                                data={{
                                    labels: parsedData.labels,
                                    datasets: [
                                        {
                                            data: parsedData.data,
                                            backgroundColor:
                                                parsedData.backgroundColor,
                                            hoverBackgroundColor:
                                                parsedData.hoverBackgroundColor,
                                        },
                                    ],
                                }}
                                options={options}
                                width={parseInt(wid)}
                                height={parseInt(hei)}
                            />
                        </div>
                    </Col>
                    <Col xs>
                        <div
                            style={{
                                width: parseInt(wid),
                                marginRight: "auto",
                                paddingLeft: 15,
                            }}
                        >
                            <table style={{ borderCollapse: "collapse" }}>
                                <tbody>
                                    {this.renderListValues(dat)}
                                    <tr>
                                        <td
                                            colSpan={2}
                                            style={{ paddingTop: 10 }}
                                        >
                                            <h3 style={{ fontWeight: 200 }}>
                                                {count}
                                            </h3>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
            );
        }
        return null;
    }
}

function mapStateToProps({ graphicAdvance }) {
    return {
        advance: graphicAdvance.get("advance"),
        inSearch: graphicAdvance.get("inSearch"),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getAdvanceProject,
            inSearchProjectGraphic,
        },
        dispatch
    );
}

DoughnutChartGraphics.propTypes = {
    projectId: PropTypes.string.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DoughnutChartGraphics);
