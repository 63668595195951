import Immutable from 'immutable';
import * as actions from './constants';
import _ from 'lodash';

const  initialState = Immutable.Map({
    data: []
});

export default function(state = initialState, action){
    switch(action.type){
        case actions.GET_SURVEY_METADATA:
            return state.set('data', _.get(action, 'payload.data'), []);
        default:
            return state;
    }
}
