import PropTypes from "prop-types";
import React, { Component } from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { grey400, grey600, grey700, blue700 } from "material-ui/styles/colors";
import IconButton from "material-ui/IconButton";
import InfoIcon from "material-ui/svg-icons/action/info";

const customContentStyle = {
    maxWidth: "650px",
};

class PersonDialogIcon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { person, project } = this.props;
        return (
            <div style={{ display: "inline-block" }}>
                <IconButton
                    tooltipPosition="bottom-left"
                    tooltip="Información"
                    onClick={this.handleOpen}
                >
                    <InfoIcon color={grey600} />
                </IconButton>
                <Dialog
                    actions={[
                        <FlatButton
                            label="Cerrar"
                            primary={true}
                            onClick={this.handleClose}
                        />,
                    ]}
                    modal={false}
                    open={this.state.open}
                    onRequestClose={this.handleClose}
                    contentStyle={customContentStyle}
                    autoScrollBodyContent={true}
                    title={"Información"}
                    titleStyle={{ textAlign: "center", color: blue700 }}
                >
                    <br />
                    Nombre:{" "}
                    {_.get(person, "name") + " " + _.get(person, "lastName")}
                    <br />
                    <br />
                    Proyecto: {_.get(project, "name")}
                    <br />
                    <br />
                    Empresa: {_.get(project, "company.businessName")}
                    <br />
                    <br />
                </Dialog>
            </div>
        );
    }
}

PersonDialogIcon.propTypes = {
    person: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
};

export default PersonDialogIcon;
