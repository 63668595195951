import React, {Component} from 'react';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import {Row, Col} from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import { connect } from 'react-redux';
import { FetchConsentParam } from '../../actions/info/consents_info_form';

const style = {
        margin: 20,
        float: 'right'
    },
    stylePaper = {
        marginTop: 20,
        marginBottom: 20
    },
    styleTable = {
        margin: 'auto',
        width: '90%'
    },
    styleButton = {
        margin: 12,
    };

const stylePopover = {
    padding: 20,
};

class ConsentsInfo extends Component {
    UNSAFE_componentWillMount(){
        this.props.FetchConsentParam(this.props.params._id);
    }
    renderConsent(){
        if(this.props.consents.data){
            return (
                <table style={styleTable}>
                    <tbody>
                        <tr>
                            <td colSpan='3'>
                                <h1 style={{textAlign: 'center', fontWeight: 400}}> {this.props.consents.data.name} </h1>
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Consentimiento</strong></td>
                            <td colSpan='2'>{this.props.consents.data.agreement}</td>
                        </tr>
                        <tr>
                            <td>
                                <RaisedButton label="Actualizar" secondary={true} style={styleButton} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            );
        }
    }
    render() {
        return (
          <Row>
              <Col xsOffset={2} xs={8}>
                  <Paper style={stylePaper}>
                      <Row>
                          <Col xs>
                              {this.renderConsent()}
                          </Col>
                      </Row>
                  </Paper>
              </Col>
          </Row>
        );
    }
}

function mapStateToProps(state){
    return  { companies: state.companies.all };
}

export default connect (mapStateToProps, { FetchConsentParam })(ConsentsInfo);
