import React, { Component } from "react";
import { reduxForm, addArrayValue } from "redux-form";
import { bindActionCreators } from "redux";
import { Row, Col, Grid } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import AppBar from "material-ui/AppBar";
import DoneIcon from "material-ui/svg-icons/action/done";
import ErrorIcon from "material-ui/svg-icons/alert/error";
import _ from "lodash";
import { Link } from "react-router";
import SnackbarMessage from "../../components/commons/snackBarMessage";
import { toggleSnackbar } from "../../actions/commons";
import { rememberPassword, login, setToken } from "./actions";

import LoadingPage from "../../components/commons/loadingPage";
import FaqMessage from "../../components/fields/FaqMessage/FaqMessage";
import About from "../../components/fields/about/About";

const loginBoxStyle = {
    marginTop: 80,
};

const style = {
    marginTop: 50,
    marginBottom: 20,
};

const passwordStyle = {
    marginLeft: 50,
    paddingBottom: 20,
};

const titleStyle = {
    textAlign: "center",
    fontWeight: 400,
    fontSize: 24,
    didFlip: "true",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: 0,
    paddingTop: 0,
    letterSpacing: 0,
    color: "#FFF",
    lineHeight: "50px",
    boxFlex: 1,
    flex: 1,
};

class forgotPasswordBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            showMessage: false,
            loadingSend: false,
            message: "",
            result: false,
        };

        this._handleRememberPass = this._handleRememberPass.bind(this);
        this.handleShowLoading = this.handleShowLoading.bind(this);
        this.handleShowMessage = this.handleShowMessage.bind(this);
        this.showSucces = this.showSucces.bind(this);
        this.setMessageResult = this.setMessageResult.bind(this);
    }

    _handleRememberPass(formdata) {
        this.handleShowMessage(false);
        this.handleShowLoading(true);
        const { rememberPassword, toggleSnackbar, resetForm } = this.props;
        rememberPassword(formdata.user)
            .then((data) => {
                resetForm();
                this.handleShowLoading(false);
                if (
                    _.get(data, "error") ||
                    _.get(data, "payload.status") !== 200
                ) {
                    this.handleShowMessage(false);
                    toggleSnackbar(
                        true,
                        "Servicio no disponible, intentelo de nuevo más tarde."
                    );
                } else {
                    this.handleShowMessage(true);
                    this.setMessageResult(
                        "Revisa la contraseña en el correo electrónico registrado."
                    );
                    this.getIconMessage(true);
                    /* if (
                        _.isEqual(_.get(data, "payload.data.type"), "success")
                    ) {
                        message = `La contraseña ha sido enviada al correo ${_.get(
                            data,
                            "payload.data.message"
                        )}`;
                        this.setMessageResult(message);
                        this.getIconMessage(true);
                    } else {
                        switch (_.get(data, "payload.data.message")) {
                            case "User_Not_Found":
                                message = `No existe el usuario ${formdata.user}`;
                                break;
                            case "Mail_No_Send":
                                message = "No fue posible enviar el correo";
                                break;
                            case "Not_Change_Password":
                                message =
                                    "No fue posible crear la nueva contraseña";
                                break;
                        }
                        this.setMessageResult(message);
                        this.getIconMessage(false);
                    } */
                }
            })
            .catch((err) => {
                this.handleShowLoading(false);
                this.handleShowMessage(false);
                console.log(err);
                toggleSnackbar(
                    true,
                    "Servicio no disponible, intentelo de nuevo más tarde."
                );
            });
    }

    handleShowLoading(show) {
        this.setState({
            loadingSend: show,
        });
    }

    handleShowMessage(show) {
        this.setState({
            showMessage: show,
        });
    }

    setMessageResult(message) {
        this.setState({
            message,
        });
    }

    showSucces(result) {
        this.setState({
            result,
        });
    }

    getIconMessage(success) {
        return success ? <DoneIcon /> : <ErrorIcon />;
    }

    render() {
        const {
            fields: { user },
            handleSubmit,
            resetForm,
        } = this.props;

        return (
            <div>
                <Grid fluid>
                    <Row style={loginBoxStyle}>
                        <Col xs={12} md={4} mdOffset={4}>
                            <Paper
                                style={{ paddingBottom: 25, paddingLeft: 0 }}
                            >
                                <Row>
                                    <Col xs>
                                        <AppBar
                                            title="SURA Encuestas"
                                            showMenuIconButton={false}
                                        />
                                    </Col>
                                </Row>
                                <form
                                    onSubmit={handleSubmit(
                                        this._handleRememberPass
                                    )}
                                >
                                    <Row style={{ marginTop: 40 }}>
                                        <Col xs style={{ textAlign: "center" }}>
                                            <TextField
                                                maxLength="15"
                                                hintText="ej: 1034244535"
                                                floatingLabelText="Número de identificación"
                                                style={{
                                                    margin: "0 auto",
                                                    textAlign: "left",
                                                }}
                                                errorText={
                                                    user.touched && user.error
                                                        ? user.error
                                                        : ""
                                                }
                                                {...user}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col
                                            xs={12}
                                            style={{
                                                textAlign: "center",
                                                margin: 20,
                                                width: "200px",
                                            }}
                                        >
                                            <div
                                                style={
                                                    this.state.showMessage
                                                        ? { display: "block" }
                                                        : { display: "none" }
                                                }
                                            >
                                                <div
                                                    style={{
                                                        textAlign: "center",
                                                        fontWeight: 500,
                                                        fontSize: "larger",
                                                    }}
                                                >
                                                    {this.state.message}
                                                </div>
                                                <span
                                                    style={{
                                                        float: "left",
                                                        top: "-31px",
                                                        position: "relative",
                                                        left: "20px",
                                                    }}
                                                >
                                                    {this.getIconMessage(
                                                        this.state.result
                                                    )}
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col
                                            xs
                                            style={{
                                                textAlign: "center",
                                                marginBottom: 20,
                                            }}
                                        >
                                            <RaisedButton
                                                label="Recordar contraseña"
                                                secondary
                                                disabled={
                                                    this.state.loadingSend
                                                }
                                                type="submit"
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs style={{ textAlign: "center" }}>
                                            <Link
                                                to="/login"
                                                style={{ color: "blue" }}
                                            >
                                                <FlatButton
                                                    label="Ir a iniciar sesión"
                                                    secondary
                                                />
                                            </Link>
                                        </Col>
                                    </Row>
                                </form>
                            </Paper>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs>
                            <LoadingPage visible={this.state.loadingSend} />
                            <SnackbarMessage />
                            <FaqMessage />
                            <About />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}
const validate = (values) => {
    const errors = {};
    if (!values.user) {
        errors.user = "Ingrese su número de identificación";
    }
    return errors;
};

function mapStateToProps({ login }, ownerProps) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleSnackbar,
            rememberPassword,
        },
        dispatch
    );
}
export default reduxForm(
    {
        form: "forgotPasswordForm",
        fields: ["user"],
        validate,
    },
    mapStateToProps,
    mapDispatchToProps
)(forgotPasswordBox);
