import PropTypes from 'prop-types';
import React, { Component } from 'react';
import includes from 'lodash/includes';
import size from 'lodash/size';
import {allowedListFilters, ALL, GROUPERS, METADATA, QUESTIONS} from './ProjectDucks';
import MenuItem from 'material-ui/MenuItem';
import SelectFieldFormat from '../fields/SelectFieldFormat/SelectFieldFormat';

class BaseFilterDialog extends Component {
    static propTypes = {
        allowedFilters: PropTypes.arrayOf(PropTypes.oneOf(allowedListFilters)).isRequired,
        config: PropTypes.object.isRequired
    };

    render() {
        const {config, allowedFilters} = this.props;
        const noAll = allowedFilters.filter(item => item !== ALL);
        if (includes(allowedFilters, ALL)) {
            return (
                <SelectFieldFormat config={config}>
                    <MenuItem value="tags" primaryText="Etiquetas"/>
                    <MenuItem value="questions" primaryText="Respuestas Básicas"/>
                    <MenuItem value="groupers" primaryText="Indicadores"/>
                </SelectFieldFormat>
            );
        } else if (size(noAll) === 1) {
            return <span/>
        } else {
            return (
                <SelectFieldFormat config={config}>
                    {includes(allowedFilters, METADATA) && <MenuItem value="tags" primaryText="Etiquetas"/>}
                    {includes(allowedFilters, QUESTIONS) &&
                    <MenuItem value="questions" primaryText="Respuestas Básicas"/>}
                    {includes(allowedFilters, GROUPERS) &&
                    <MenuItem value="groupers" primaryText="Indicadores"/>}
                </SelectFieldFormat>
            );
        }
    }
}


export default BaseFilterDialog;
