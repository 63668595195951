import React, { Fragment } from "react";
import AppMenu from "./menu_bar";
import LeftMenu from "./left_navbar";
import { Grid } from "react-flexbox-grid";

const AdminDashboard = (props) => {
    return (
        <Fragment>
            <AppMenu />
            <LeftMenu />
            <Grid fluid>{props.children}</Grid>
        </Fragment>
    );
};

export default AdminDashboard;
