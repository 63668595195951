import * as actions from './constants';
import Immutable from 'immutable';
import _ from 'lodash';

const initialState = Immutable.Map();

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.PRE_SAVE_ANSWER:
            var actPayloadWithoutType = action.payload;
            var keyMap = `${actPayloadWithoutType.question}:${actPayloadWithoutType.section}`;
            return state.set(keyMap, actPayloadWithoutType.answer);
        case actions.SAVE_SOLUTION_SECTION:
            var data = action.payload;
            return state;
        case actions.DECLINE_SURVEY:
            var data2 = action.payload;
            return state;
        default:
            return state;
    }
}
