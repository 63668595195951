import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-flexbox-grid';
import { bindActionCreators } from 'redux';
import { getValuesActivityEconomic, changeValueActivityEconomic } from '../actions';
import CircularProgress from 'material-ui/CircularProgress';
import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import { getRecords } from '../../GenericTablePagination/actions';
import SelectFieldFormat from '../../fields/SelectFieldFormat/SelectFieldFormat';
import _ from 'lodash';

class FilterEconomicActivityGeneric extends Component {

    constructor(props){
        super(props);
        this._onChangeEconomicActivity = this._onChangeEconomicActivity.bind(this);
        this._execGetRecords = this._execGetRecords.bind(this);
    }

    UNSAFE_componentWillMount() {
        const { getValuesActivityEconomic } = this.props;
        getValuesActivityEconomic();
    }

    _onChangeEconomicActivity(e, index, value){
        const { changeValueActivityEconomic, extra } = this.props;
        let extraData = extra;
        changeValueActivityEconomic(value);
        this._execGetRecords(_.set(extraData, 'economicActivity', value));
    }

    _execGetRecords(extraData){
        const {
            URL,
            version,
            skip,
            limit,
            orderName,
            orderType,
            textFilter,
            body,
            textValueTag,
            textTag,
            coreName,
            getRecords
        } = this.props;
        let arrayFields = [];
        _.map(body, function(field){
             if(!_.has(field, 'actions') && _.has(field, 'filter')){
                 if(_.get(field, 'filter')){
                     arrayFields.push(_.get(field, 'field'));
                 }
             }
        });
        getRecords(URL, version, skip, limit, orderName, orderType, arrayFields, textFilter, coreName, extraData);
    }

    render() {
        const { economicActivity, economicsActivities } = this.props;
        return (
            <SelectFieldFormat
                config={{
                    floatingLabelText: "Actividad económica",
                    value: economicActivity,
                    autoWidth: true,
                    onChange: this._onChangeEconomicActivity
                }}
            >
                <MenuItem key={`economicActivity0`} value={'all'} primaryText={'Todos'}/>
                {
                    _.map(_.orderBy(economicsActivities, ['value']), (item, index) => {
                        return (
                            <MenuItem key={`${_.get(item, '_id')}_ae_${index}`} value={_.get(item, '_id')} primaryText={_.get(item, 'value')}/>
                        );
                    })
                }
            </SelectFieldFormat>
        );
    }
}

function mapStateToProps({ filterCompany, genericTablePagination }, ownProps) {
    const tablePagination = genericTablePagination.get(_.get(ownProps, 'coreName', 'default'));
    return {
        economicsActivities: filterCompany.get("economicsActivities"),
        economicActivity: filterCompany.get("economicActivity"),
        skip: tablePagination.get('skip'),
        limit: tablePagination.get('limit'),
        orderName: tablePagination.get('orderName'),
        orderType: tablePagination.get('orderType'),
        textFilter: tablePagination.get('textFilter'),
        status: _.isNull(tablePagination.get('status')) ? 'all' : tablePagination.get('status')
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getValuesActivityEconomic,
        changeValueActivityEconomic,
        getRecords
    }, dispatch);
}

FilterEconomicActivityGeneric.propTypes = {
    URL: PropTypes.string.isRequired,
    version: PropTypes.number.isRequired,
    extra: PropTypes.object.isRequired,
    body: PropTypes.array.isRequired,
    coreName: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterEconomicActivityGeneric);
