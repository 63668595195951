import React, { Component } from "react";
import PropTypes from "prop-types";
import { arrayObjectAlign } from "../../reportComponents/types";
import ColorComponent from "./styleContent/colorComponent";
import NumberComponent from "./styleContent/numberComponent";
import TextComponent from "./styleContent/textComponent";
import SelectComponent from "./styleContent/selectComponent";

const styles = {
    box: {
        boxSizing: "border-box",
        padding: 10,
    },
    title: {
        fontWeight: 400,
        textAlign: "center",
        backgroundColor: "#ADADAD",
        color: "#FFF",
        margin: 0,
        padding: "12px 5px",
        width: "100%",
        boxSizing: "border-box",
    },
};

class StyleTitleComponent extends Component {
    static propTypes = {
        survey: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
    };

    render() {
        const { survey, path } = this.props;
        return (
            <div>
                <h3 style={{ ...styles.title }}>Propiedades del título</h3>
                <div style={{ ...styles.box }}>
                    <TextComponent
                        survey={survey}
                        path={path}
                        fieldProp={"text"}
                        title={"Texto del título"}
                        defaultValue={""}
                    />
                    <br />
                    <NumberComponent
                        survey={survey}
                        path={`${path}.style`}
                        fieldProp={"fontSize"}
                        title={"Tamaño de letra"}
                        defaultValue={12}
                    />
                    <ColorComponent
                        survey={survey}
                        path={`${path}.style`}
                        fieldProp={"color"}
                        title={"Color de letra:"}
                        defaultValue={"#111"}
                    />
                    <ColorComponent
                        survey={survey}
                        path={`${path}.style`}
                        fieldProp={"backgroundColor"}
                        title={"Color de fondo:"}
                        defaultValue={"#FFF"}
                    />
                    <SelectComponent
                        survey={survey}
                        path={`${path}.style`}
                        fieldProp={"textAlign"}
                        title={"Alineación"}
                        defaultValue={"left"}
                        array={arrayObjectAlign}
                    />
                    <br />
                    <NumberComponent
                        survey={survey}
                        path={`${path}.style`}
                        fieldProp={"padding"}
                        title={"Espaciado entre el borde"}
                        defaultValue={0}
                    />
                    <br />
                    <NumberComponent
                        survey={survey}
                        path={`${path}.style`}
                        fieldProp={"minHeight"}
                        title={"Mínimo de alto"}
                        defaultValue={10}
                    />
                    <br />
                    <NumberComponent
                        survey={survey}
                        path={`${path}.style`}
                        fieldProp={"borderRadius"}
                        title={"Radio del borde"}
                        defaultValue={0}
                    />
                </div>
            </div>
        );
    }
}

export default StyleTitleComponent;
