import React, {Component} from 'react';
import {Row, Col} from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import {LBLSURVEYS} from '../../constants/labels';
import FloatingActionButtonTooltip from '../fields/FloatingActionButtonTooltip/FloatingActionButtonTooltip';
import {blue700} from 'material-ui/styles/colors';
import _ from 'lodash';
import AuthComponent from '../../components/AuthComponent';
import TablePagination from '../TablePagination/tablePagination';

const stylePaper = {
        marginTop: 20,
        marginBottom: 20
    };

class SurveysGrid extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const body = [
            {
                field: "name",
                title: "Nombre",
                filter: true
            },
            {
                actions: true,
                title: "Operaciones"
            }
        ];
        return (
            <Row>
                <AuthComponent
                    component={
                        <Col mdOffset={2} md={8} xs={12}>
                            <Paper style={stylePaper}>
                                <Row>
                                    <Col xs>
                                        <div style={{width: '100%', background: blue700, paddingTop: 5, paddingBottom:5, color: "#FFF"}}>
                                            <h1 style={{textAlign: 'center', fontWeight: 400}}>{LBLSURVEYS.titleGrid}</h1>
                                            <AuthComponent
                                                component={
                                                    <Link to={`/admin/surveys/create`}>
                                                          <FloatingActionButtonTooltip
                                                              textTooltip={LBLSURVEYS.tooltips.btnCreate}
                                                          />
                                                    </Link>
                                                }
                                                permission={"cgr_surveys_create"}
                                                type={"component"}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <TablePagination
                                            name={`surveys`}
                                            URL={`/surveys`}
                                            version={1}
                                            body={body}
                                        />
                                    </Col>
                                </Row>
                            </Paper>
                        </Col>
                    }
                    permission={"cgr_surveys_list"}
                    type={"url"}
                />
            </Row>
        );
    }
}

function mapStateToProps({surveys}) {
    return {surveys: surveys.all};
}

export default connect(mapStateToProps)(SurveysGrid);
