import * as actions from './constants';
import Immutable from 'immutable';

const initialState = Immutable.Map({
    user: {},
    oneUser: null
});

export default (state = initialState, action) => {
    switch (action.type) {
    case actions.ADD_USER:
        return state.set("user", action.payload.data);
    case actions.GET_USER:
        return state.set("oneUser", action.payload.data);
    case actions.CLEAR_USER:
        return state.withMutations(state => {
            state.set('user', {});
            state.set('oneUser', null);
        });
    default:
        return state;
    }
}
