import { Map, List } from "immutable";
import * as Rx from "rxjs";
import get from "lodash/get";
import {
    STATUS_NO_CONNECTED,
    STATUS_CHANGE,
    CHANGE_CONVERSATION_ID,
    ADD_MESSAGE,
    CLEAN_STATE,
    STATUS_ENDED,
    MESSAGES_STATUS,
    changeStateConnection,
} from "../commons/functions";
import { axiosAPI_V2 } from "../../../middleware/api";

const CLOSE_CONVERSATION = "nss/pegasus/chat-close-conversation-employee";
const MARK_MESSAGES_READ = "nss/pegasus/chat-mark-all-messages-as-read";
const OPEN_DIALOG_MESSAGE_CHAT = "nss/pegasus/open-dialog-message-chat";
const CLOSE_DIALOG_MESSAGE_CHAT = "nss/pegasus/close-dialog-message-chat";
const SEND_FIRST_MESSAGE_CHAT = "nss/pegasus/send-first-message-chat";
const RESET_FIRST_MESSAGE_CHAT = "nss/pegasus/reset_first_message_chat";
const DEQUEUE_MESSAGE = "nss/pegasus/dequeue-employee-message";
const DEQUEUE_MESSAGE_REJECTED = "nss/pegasus/dequeue-employee-message-REJECTED";
const DEQUEUE_MESSAGE_FULFILLED = "nss/pegasus/dequeue-employee-message-FULFILLED";

const initialState = Map({
    pingId: 0,
    socketStatus: STATUS_NO_CONNECTED,
    conversation: undefined,
    evaluator: undefined,
    messages: List(),
    open: false,
    hasFirstMessage: false,
    firstMessage: null,
    isReinit: false,
});

export default function(state = initialState, action = {}) {
    switch (action.type) {
        case STATUS_CHANGE:
            return state.set("socketStatus", action.status);
        case CHANGE_CONVERSATION_ID:
            return state.set("conversation", action.id);
        case ADD_MESSAGE:
            return state.set("messages", state.get("messages").push(action.message));
        case CLEAN_STATE:
            return initialState;
        case CLOSE_CONVERSATION:
            return state.withMutations(map => {
                map.set("conversation", undefined);
                map.set("evaluator", undefined);
                map.set("socketStatus", STATUS_ENDED);
                map.set("messages", List());
                map.set("hasFirstMessage", false);
                map.set("isReinit", true);
            });
        case DEQUEUE_MESSAGE_FULFILLED:
            return state.set("evaluator", get(action, "payload.data.person", undefined));
        case MARK_MESSAGES_READ:
            const currentMessages = state.get("messages");
            const markedMessages = currentMessages.map(item =>
                Object.assign({}, item, { uiStatus: MESSAGES_STATUS.READ }),
            );
            return state.set("messages", markedMessages);
        case OPEN_DIALOG_MESSAGE_CHAT:
            return state.set("open", true);
        case CLOSE_DIALOG_MESSAGE_CHAT:
            return state.set("open", false);
        case SEND_FIRST_MESSAGE_CHAT:
            return state.set("hasFirstMessage", true);
        case RESET_FIRST_MESSAGE_CHAT:
            return state.withMutations(map => {
                map.set("hasFirstMessage", false);
                map.set("isReinit", true);
            });
        default:
            return state;
    }
}

export function closeConversation() {
    return {
        type: CLOSE_CONVERSATION,
    };
}

export function markMessagesAsRead() {
    return {
        type: MARK_MESSAGES_READ,
    };
}

export function openDialogMessage() {
    return {
        type: OPEN_DIALOG_MESSAGE_CHAT,
    };
}

export function closeDialogMessage() {
    return {
        type: CLOSE_DIALOG_MESSAGE_CHAT,
    };
}

export function sendFirstMessage() {
    return {
        type: SEND_FIRST_MESSAGE_CHAT,
    };
}

export function resetFirstMessageChat() {
    return {
        type: RESET_FIRST_MESSAGE_CHAT,
    };
}

export function dequeueMessage(message) {
    return {
        type: DEQUEUE_MESSAGE,
        payload: message,
    };
}

export const httpRequestFulfilled = action => data => ({ type: action, payload: { data } });
export const httpRequestRejected = (action, err) => ({ type: action, payload: { err } });

export const dequeueEpic$ = action$ =>
    action$.ofType(DEQUEUE_MESSAGE).mergeMap(action => {
        const promise = axiosAPI_V2.get(`/users/evaluator/${action.payload.evaluator}`);
        return Rx.Observable
            .fromPromise(promise)
            .map(response => response.data)
            .map(httpRequestFulfilled(DEQUEUE_MESSAGE_FULFILLED))
            .catch(error =>
                Rx.Observable.of(httpRequestRejected(DEQUEUE_MESSAGE_REJECTED, get(error, "response.data"))),
            )
            .startWith(changeStateConnection(action.payload.type));
    });
