import "es5-shim/es5-shim";
import "es5-shim/es5-sham";
import "@babel/polyfill";

import React from "react";
import ReactDOM from "react-dom";
import { QueryCache, ReactQueryCacheProvider } from "react-query";
import { browserHistory } from "react-router";
import Root from "./components/root/Root";
import configureStore from "./store/configureStore";
import WebSocketManager from "./components/chat/commons/WebSocketManager";

import Routes from "./routes";

import "./css/style.css";

const chatWebSocketManager = new WebSocketManager();

export const store = configureStore();

const queryCache = new QueryCache();

const theme = {
    palette: {
        primary: {
            light: "#757ce8",
            main: "#3f50b5",
            dark: "#002884",
            contrastText: "#fff",
        },
        secondary: {
            light: "#ff7961",
            main: "#f44336",
            dark: "#ba000d",
            contrastText: "#000",
        },
    },
};

const App = () => (
    <ReactQueryCacheProvider queryCache={queryCache}>
        <Root
            store={store}
            browserHistory={browserHistory}
            routes={Routes(chatWebSocketManager)}
        />
    </ReactQueryCacheProvider>
);

ReactDOM.render(<App />, document.getElementById("root"));
