const MAIN = "main_page";
const SOCIO_DEMOGRAPHIC = "question_page";
const INDICATORS = "indicator_page";
const RANKING = "ranking_page";

export const pageTypes = {
    MAIN,
    SOCIO_DEMOGRAPHIC,
    INDICATORS,
    RANKING,
};
