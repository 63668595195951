import { GET_FAQ_INFO } from '../actions/info/faq_info_form';
import { GET_FAQ_GRID } from '../actions/grid/faq_grid_form';
import { DELETE_FAQ } from '../constants/index';
import { SHOW_FAQ, SET_IN_SEARCH_FAQS } from '../constants/index';
import Immutable from 'immutable';
import _ from 'lodash';

const INITIAL_STATE = Immutable.Map({
    all: Immutable.List(),
    alluser: Immutable.List(),
    faq: null,
    show: false,
    inSearch: false
});

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_FAQ_INFO:
            return state.withMutations(function(state) {
                        state.set('faq', _.get(action, 'payload.data.data', null));
                        state.set('inSearch', false);
                    });
        case GET_FAQ_GRID:
            return state.withMutations(function(state) {
                        state.set('all', Immutable.List(_.get(action, 'payload.data.data', [])));
                        state.set('inSearch', false);
                    });
        case SHOW_FAQ:
            if(action.meta.show){
                return state.withMutations(function(state) {
                            state.set('alluser', Immutable.List(_.get(action, 'payload.data.data', [])));
                            state.set('show', action.meta.show);
                            state.set('inSearch', false);
                        });
            } else {
                return state.withMutations(function(state) {
                            state.set('show', action.meta.show);
                            state.set('inSearch', false);
                        });
            }
        case DELETE_FAQ:
            var listFaqs = state.get('all').filter( item => {
                                  return !_.isEqual(item._id, action.meta.faqId);
                              });
            return state.withMutations(function(state) {
                        state.set('all', listFaqs);
                        state.set('inSearch', false);
                    });
        case SET_IN_SEARCH_FAQS:
            return state.set('inSearch', true);
        default:
            return state;
    }
}
