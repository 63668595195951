import PropTypes from "prop-types";
import React, { Component } from "react";
import _ from "lodash";
import ChartComponent from "./chartComponent";
import BarStackedGroupFilterComponent from "./chartsComponents/barStackedGroupFilterComponent";
import BarStacked3DGroupFilterComponent from "./charts3DComponents/barStacked3DGroupFilterComponent";
import RaisedButton from "material-ui/RaisedButton";
import LegendStackedComponent from "./legends/legendStackedComponent";
import { arrayChart, arraySizeChart } from "./types";

class QuestionComponent extends Component {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        colors: PropTypes.array,
        data: PropTypes.array.isRequired,
        textValues: PropTypes.string,
        textKeys: PropTypes.string,
        type: PropTypes.oneOf(arrayChart).isRequired,
        hasLegend: PropTypes.bool,
        size: PropTypes.oneOf(arraySizeChart)
    };

    renderChart(dataFormat, keysStacked, dataStacked, textValues, textKeys, type, hasLegend, size, hasMiniLegend) {
        switch (type) {
            case "STACKED_BAR":
                return (
                    <div className="print_pdf_component">
                        <BarStackedGroupFilterComponent data={dataStacked} keys={keysStacked} size={size} hasMiniLegend={hasMiniLegend} />
                        <LegendStackedComponent data={dataStacked} size={size} keys={keysStacked} />
                    </div>
                );
            case "STACKED_BAR3D":
                return (
                    <div className="print_pdf_component">
                        <BarStacked3DGroupFilterComponent data={dataStacked} keys={keysStacked} size={size} hasMiniLegend={hasMiniLegend} />
                        <LegendStackedComponent data={dataStacked} size={size} keys={keysStacked} />
                    </div>
                );
            default:
                return (
                    <ChartComponent
                        data={dataFormat}
                        keysStacked={keysStacked}
                        dataStacked={dataStacked}
                        textValues={textValues}
                        textKeys={textKeys}
                        type={type}
                        hasLegend={hasLegend}
                        size={size}
                    />
                );
        }
    }

    render() {
        const { data, textValues, textKeys, type, hasLegend, size, colors } = this.props;
        const dataFormat = getData(data, colors);
        const dataFormatStacked = getDataStacked(data, colors);
        let dataStacked = _.get(dataFormatStacked, "dataStacked", []),
            keysStacked = _.get(dataFormatStacked, "keysStacked", []);
        if (_.size(dataStacked) > 0 && _.size(keysStacked) > 0) {
            return this.renderChart(
                dataFormat,
                keysStacked,
                dataStacked,
                textValues,
                textKeys,
                type,
                hasLegend,
                size,
                _.size(dataStacked) > 1
            );
        }
        return <span style={{ color: "#CCC" }} />;
    }
}

function getData(data, colors) {
    let total = 0;
    _.forEach(_.get(data, "0.values", []), value => {
        total += _.get(value, "count", 0);
    });
    if (total <= 0) {
        total = 100;
    }
    return _.map(_.get(data, "0.values", []), (value, index) => {
        const full = _.get(value, "count", 0) * 100 / total;
        const i = full.toFixed(1);
        return {
            value: _.get(value, "count", 0),
            textValue: _.get(value, "count", 0),
            color: _.get(colors, index, "#FFF"),
            index: `${i}%`,
            textIndex: `${_.get(value, "id", "0")} (${i}%)`
        };
    });
}

function getDataStacked(data, colors) {
    const keysStacked = [];
    let arrayCodes = [],
        dataStacked = {},
        total = 0;
    _.forEach(_.get(data, "0.values", []), value => {
        total += _.get(value, "count", 0);
    });
    if (total <= 0) {
        total = 100;
    }
    _.forEach(_.get(data, "0.values", []), (value, index) => {
        const code = getCodeValue(arrayCodes);
        arrayCodes.push(code);
        const full = _.get(value, "count", 0) * 100 / total;
        const i = full.toFixed(1);
        keysStacked.push({
            key: code,
            text: `${_.get(value, "id", "")} (${i}%)`,
            color: _.get(colors, index, "#FFF")
        });
        dataStacked = _.set(dataStacked, code, _.get(value, "count", 0));
    });
    return {
        keysStacked,
        dataStacked: [_.assign(dataStacked, { index: "", textIndex: "", total })]
    };
}

function getCodeValue(arrayCodes) {
    let text = "";
    let isValid = false;
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_";
    while (!isValid) {
        for (let i = 0; i < 20; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        isValid = _.indexOf(arrayCodes, text) === -1;
    }
    return text;
}

export default QuestionComponent;
