import Immutable from 'immutable';
import * as actions from './constants';
import _ from 'lodash';

const initialState = Immutable.Map({
    projects: [],
    dashboard: Immutable.Map()
});

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.LOAD_PROJECTS:
            return state.set('projects', _.get(action, 'payload.data', []));
        case actions.INITIAL_DASHBOARD:
            return state.set('dashboard', Immutable.Map());
        case actions.TOGGLE_PROJECT:
            var currentProjectId = action.project;
            var dashboardProjects = state.get('dashboard');
            var currentProjectState = dashboardProjects.get(currentProjectId);
            var stateToSet = _.isUndefined(currentProjectState) && _.has(action, 'current') ? action.current : !currentProjectState;
            var newStateDashboard =  dashboardProjects.set(currentProjectId, stateToSet);
            return state.set('dashboard', newStateDashboard);
        case actions.CLEAN_DASHBOARD_EMPLOYEE_PROJECT:
            return state.set('projects', _.get(action, 'payload.data', []));
        default:
            return state;
    }
}
