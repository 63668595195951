// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import TextComponent from "../../reportComponents/textComponent";

const styles = {
    contentText: {
        width: "95%",
        margin: "0 auto",
    },
};

class TextConfigComponent extends Component {
    static propTypes = {
        text: PropTypes.string.isRequired,
        elementPath: PropTypes.string.isRequired,
    };

    render() {
        const { text } = this.props;
        return (
            <div style={{ ...styles.contentText }}>
                <TextComponent text={text} />
            </div>
        );
    }
}

function mapStateToProps({ analyticsReportsConfiguration }, ownProps) {
    const config = analyticsReportsConfiguration.get("pageConfiguration");
    const loadingConfiguration = analyticsReportsConfiguration.get("loadingConfiguration");
    const text = _.get(config, `${_.get(ownProps, "elementPath")}.textProps.text`, "<p></p>");
    return {
        loadingConfiguration,
        text,
    };
}

export default connect(mapStateToProps)(TextConfigComponent);
