import {TOGGLE_DELETE_MODAL, TOGGLE_SNACKBAR} from '../actions/commons';
import _ from 'lodash';

export default (state = {delete: false, company: {}}, action) => {
    switch (action.type) {
        case TOGGLE_DELETE_MODAL:
            return _.assign({}, state, {
                delete: action.state,
                company: action.payload
            });
        case TOGGLE_SNACKBAR:
            return _.assign({}, state, {
                status: action.status,
                message: action.message
            });
        default:
            return state;
    }
}
