export function observableFeedPropType(props, propName, componentName) {
    if (!(props[propName] instanceof Object)) {
        return new Error(`Invalid Prop supplied to ${componentName} as feed not using an Subject`);
    }
    return null;
}

export const FILTER_GROUPS = "FILTER_GROUPS";
export const SOCIO_DEMOGRAPHIC = "SOCIO_DEMOGRAPHIC";
export const INDICATOR = "INDICATOR";
export const QUESTION = "QUESTION";
export const RANKING = "RANKING";
export const RANKING_STATS = "RANKING_STATS";
export const MENU = "MENU";
export const CHART = "CHART";
export const TEXT = "TEXT";

export const CHARTS = [
    "PIE",
    "PIE3D",
    "PIE_DONUT",
    "PIE_DONUT3D",
    "BAR_HORIZONTAL",
    "BAR_HORIZONTAL3D",
    "BAR_VERTICAL",
    "BAR_VERTICAL3D",
    "STACKED_BAR",
    "STACKED_BAR3D",
];
