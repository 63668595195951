import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import Paper from 'material-ui/Paper';
import {ListItem} from 'material-ui/List';
import { bindActionCreators } from 'redux';
import { loadSections } from '../../actions/surveys/sections';
import { FetchSurveyParam } from '../../actions/info/surveys_info_form';
import LoadingPage from '../commons/loadingPage';
import _ from 'lodash';
import {blue700} from 'material-ui/styles/colors';
import { browserHistory } from 'react-router';
import { toggleSnackbar } from '../../actions/commons';
import ButtonBack from '../../components/fields/buttonBack/buttonBack';
import AuthComponent from '../../components/AuthComponent';

const stylePaper = {
        marginTop: 20,
        marginBottom: 20
    };

class SurveySectionMetricalInfo extends Component {

    constructor(props) {
        super(props);
    }

    UNSAFE_componentWillMount() {
        this.props.loadSections(this.props.params.survey);
        this.props.FetchSurveyParam(this.props.params.survey);
    }

    renderDescription(descrip){
        let description = "";
        if(!_.isUndefined(descrip) && !_.isEqual(descrip, "")){
            description = _.split(descrip, /\r\n|\r|\n/g);
        }
        if(description instanceof Array){
            return (
                <h3 style={{textAlign: 'justify', fontWeight: 200}}>
                    {description.map((value, index) => {
                        if(!_.isEqual(value, '')){
                            return (
                                <div key={`descr${index}`}>{value}<br/><br/></div>
                            );
                        }
                    })}
                </h3>
            );
        } else {
            return (
                <h3 style={{textAlign: 'justify', fontWeight: 200}}>{description}</h3>
            );
        }
    }

    render() {
        const {
            description,
            name,
            sections
        } = this.props;
        if(!_.isUndefined(description) && !_.isUndefined(name)){
            return (
              <Row>
                  <AuthComponent
                      permission={"cgr_surveys_groupers_items_metrics"}
                      type={"url"}
                  />
                  <Col xs={12} mdOffset={2} md={8}>
                      <Paper style={stylePaper}>
                          <Row>
                              <Col xs>
                                  <ButtonBack url={`/admin/surveys/groupers/${this.props.params.survey}`}/>
                              </Col>
                          </Row>
                          <Row>
                              <Col xs>
                                  <div style={{width: '100%', background: blue700, paddingTop: 5, paddingBottom:5, color: "#FFF"}}>
                                      <h1 style={{textAlign: 'center', fontWeight: 400, lineHeight: 1.3}}>{name}</h1>
                                  </div>
                              </Col>
                          </Row>
                          <Row>
                              <Col xsOffset={1} xs={10}>
                                  <Row>
                                      <Col xs>
                                          {this.renderDescription(description)}
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col xs>
                                          <h1 style={{textAlign: 'center', fontWeight: 400}}>{`Secciones`}</h1>
                                      </Col>
                                  </Row>
                                  <Row style={{paddingBottom: 30}}>
                                      <Col xs>
                                          {_.size(sections) === 0 && <h3 style={{marginTop: 30, textAlign: 'center'}}>No hay secciones</h3>}
                                          {sections.map((section, index) =>
                                              <Row key={`section${index}`}>
                                                  <Col xs>
                                                      <ListItem
                                                          primaryText={`${index+1}. ${section.title}`}
                                                          secondaryText={`${section.description}`}
                                                          secondaryTextLines={2}
                                                          onClick={() => {
                                                                browserHistory.push(`/admin/surveys/metrical/assign/${this.props.params.survey}/${section._id}`);
                                                          }}
                                                      />
                                                  </Col>
                                              </Row>
                                            )}
                                      </Col>
                                  </Row>
                              </Col>
                          </Row>
                      </Paper>
                  </Col>
              </Row>
            );
        } else {
            return (
                <div>
                    <LoadingPage visible={true}/>
                    <ButtonBack url={`/admin/surveys/metrical/${this.props.params.survey}`}/>
                </div>
            );
        }
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        toggleSnackbar,
        loadSections,
        FetchSurveyParam
    }, dispatch);
}

function mapStateToProps({surveySections, surveys}) {
    return {
        sections: surveySections.get('all').toArray(),
        name: _.get(surveys,'all.data.name'),
        description: _.get(surveys,'all.data.description')
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveySectionMetricalInfo);
