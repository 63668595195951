import React, { Component } from "react";
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from "material-ui/Table";
import RaisedButton from "material-ui/RaisedButton";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import SettingsIcon from "material-ui/svg-icons/action/settings";
import EditIcon from "material-ui/svg-icons/editor/mode-edit";
import _ from "lodash";
import IconButton from "material-ui/IconButton";
import { FetchSurveyParam } from "../../actions/info/surveys_info_form";
import { getSurveyGroupers, deleteSurveyGrouper } from "./actions";
import { connect } from "react-redux";
import { Link } from "react-router";
import { LBLGROUPERS } from "../../constants/labels";
import DeleteEntityModal from "../deleteEntityModal/component";
import ButtonBack from "../fields/buttonBack/buttonBack";
import { blue700 } from "material-ui/styles/colors";
import Divider from "material-ui/Divider";
import AuthComponent from "../AuthComponent";
import { openModalForm } from "../../components/ModalFormGroupers/actions";
import ModalFormGroupers from "../../components/ModalFormGroupers/ModalFormGroupers";
import { toggleSnackbar } from "../../actions/commons";

const style = {
        margin: 20,
        float: "right"
    },
    stylePaper = {
        marginTop: 20,
        marginBottom: 20
    };

class GroupersGrid extends Component {
    constructor(props) {
        super(props);
        this._handleDeleteGrouper = this._handleDeleteGrouper.bind(this);
    }

    _handleDeleteGrouper(grouper) {
        const { deleteSurveyGrouper, toggleSnackbar } = this.props;
        deleteSurveyGrouper(_.get(grouper, "_id"))
            .then(
                data => {
                    if (_.get(data, "error") || _.get(data, "payload.status") !== 200) {
                        toggleSnackbar(true, LBLGROUPERS.msg.errorDelete);
                    } else {
                        toggleSnackbar(true, LBLGROUPERS.msg.successDelete);
                    }
                },
                reason => {
                    toggleSnackbar(true, LBLGROUPERS.msg.errorDelete);
                }
            )
            .catch(data => {
                toggleSnackbar(true, LBLGROUPERS.msg.errorDelete);
            });
    }

    UNSAFE_componentWillMount() {
        const { getSurveyGroupers, FetchSurveyParam, params } = this.props;
        getSurveyGroupers(params.survey);
        FetchSurveyParam(params.survey);
    }

    renderGroupers(groupersSurvey) {
        const { params, locked, openModalForm } = this.props;
        if (_.size(groupersSurvey) > 0) {
            return groupersSurvey.map(grouper => {
                return (
                    <TableRow key={grouper._id}>
                        <TableRowColumn colSpan={2} style={{ whiteSpace: "normal" }}>
                            {grouper.name}
                        </TableRowColumn>
                        <TableRowColumn>{grouper.level}</TableRowColumn>
                        <TableRowColumn>{grouper.visible ? "Si" : "No"}</TableRowColumn>
                        <TableRowColumn colSpan={2}>
                            {_.isEqual(grouper.typeEvaluation, "summatory") ? "Sumatoria" : "Frencuencia"}
                        </TableRowColumn>
                        <TableRowColumn colSpan={2}>
                            <div style={{ position: "absolute", marginTop: -24, zIndex: 99 }}>
                                <AuthComponent
                                    component={
                                        <Link
                                            to={`/admin/surveys/groupers/${_.get(params, "survey")}/conditionals/${_.get(grouper, "_id")}`}
                                        >
                                            <IconButton
                                                tooltip={LBLGROUPERS.tooltips.btnConfig}
                                                tooltipPosition="top-center"
                                                children={<SettingsIcon />}
                                            />
                                        </Link>
                                    }
                                    permission={"cgr_surveys_groupers_config"}
                                    type={"component"}
                                />
                                <AuthComponent
                                    component={
                                        <DeleteEntityModal
                                            tooltip={LBLGROUPERS.tooltips.btnDelete}
                                            title={LBLGROUPERS.titleDelete}
                                            valid={locked}
                                            message={LBLGROUPERS.msg.deleteModal(grouper.name)}
                                            fn={this._handleDeleteGrouper}
                                            args={[grouper]}
                                        />
                                    }
                                    permission={"cgr_surveys_groupers_delete"}
                                    type={"component"}
                                />
                                <AuthComponent
                                    component={
                                        <IconButton
                                            tooltip={LBLGROUPERS.tooltips.btnEdit}
                                            tooltipPosition="top-center"
                                            children={<EditIcon />}
                                            disabled={locked}
                                            onClick={() => {
                                                if (!locked) {
                                                    openModalForm(
                                                        _.get(grouper, "_id"),
                                                        _.get(grouper, "name"),
                                                        _.get(grouper, "visible") ? "true" : "false",
                                                        _.get(grouper, "priority")
                                                    );
                                                }
                                            }}
                                        />
                                    }
                                    permission={"cgr_surveys_groupers_edit"}
                                    type={"component"}
                                />
                            </div>
                        </TableRowColumn>
                    </TableRow>
                );
            });
        }
    }

    renderActions(locked, idSurvey) {
        if (!locked) {
            return (
                <div>
                    <AuthComponent
                        component={
                            <Link to={`/admin/surveys/metrical/${idSurvey}`}>
                                <RaisedButton label={LBLGROUPERS.buttons.valueItems} secondary={true} style={{ margin: 20 }} />
                            </Link>
                        }
                        permission={"cgr_surveys_groupers_items_metrics"}
                        type={"component"}
                    />
                    <AuthComponent
                        component={
                            <Link to={`/admin/surveys/groupers/questions/${idSurvey}`}>
                                <RaisedButton label={LBLGROUPERS.buttons.grouperQuestions} secondary={true} style={{ margin: 20 }} />
                            </Link>
                        }
                        permission={"cgr_surveys_groupers_questions"}
                        type={"component"}
                    />
                    <AuthComponent
                        component={
                            <Link to={`/admin/surveys/groupers/create/${idSurvey}`}>
                                <RaisedButton label={LBLGROUPERS.buttons.groupGroupers} secondary={true} style={{ margin: 20 }} />
                            </Link>
                        }
                        permission={"cgr_surveys_groupers_groupers"}
                        type={"component"}
                    />
                </div>
            );
        }
    }

    render() {
        const { surveyName, params, locked, groupersSurvey } = this.props;
        return (
            <AuthComponent
                component={
                    <Row>
                        <Col xs={12} mdOffset={2} md={8}>
                            <Paper style={stylePaper}>
                                <ButtonBack url="/admin/surveys/grid" />
                                <Row>
                                    <Col xs>
                                        <div style={{ width: "100%", background: blue700, paddingTop: 5, paddingBottom: 5, color: "#FFF" }}>
                                            <h1 style={{ textAlign: "center", fontWeight: 400 }}>{LBLGROUPERS.titleGridConfigGrouper}</h1>
                                            <h2 style={{ textAlign: "center", fontWeight: 400 }}>{surveyName}</h2>
                                        </div>
                                        {this.renderActions(locked, params.survey)}
                                        <Divider />
                                        <div>
                                            <Table selectable={false}>
                                                <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                                                    <TableRow>
                                                        <TableHeaderColumn colSpan={2} style={{ whiteSpace: "normal" }}>
                                                            {LBLGROUPERS.name}
                                                        </TableHeaderColumn>
                                                        <TableHeaderColumn>{LBLGROUPERS.level}</TableHeaderColumn>
                                                        <TableHeaderColumn>{LBLGROUPERS.viewEmployee}</TableHeaderColumn>
                                                        <TableHeaderColumn colSpan={2}>{LBLGROUPERS.typeOperation}</TableHeaderColumn>
                                                        <TableHeaderColumn colSpan={2}>{LBLGROUPERS.actions}</TableHeaderColumn>
                                                    </TableRow>
                                                </TableHeader>
                                                <TableBody displayRowCheckbox={false}>{this.renderGroupers(groupersSurvey)}</TableBody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </Paper>
                        </Col>
                        <ModalFormGroupers />
                    </Row>
                }
                permission={"cgr_surveys_config_groupers"}
                type={"url"}
            />
        );
    }
}

function mapStateToProps({ surveys, groupersSurvey }) {
    return {
        locked: _.get(surveys, "all.data.locked", true),
        surveyName: _.get(surveys, "all.data.name"),
        groupersSurvey: _.get(groupersSurvey, "groupers")
    };
}

export default connect(mapStateToProps, { FetchSurveyParam, getSurveyGroupers, deleteSurveyGrouper, openModalForm, toggleSnackbar })(
    GroupersGrid
);
