import PropTypes from "prop-types";
import React, { Component } from "react";
import { getRamdonString } from "../../../lib/util";
import _ from "lodash";

class LegendComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data } = this.props;
        return (
            <div style={{ textAlign: "left", marginBottom: 10, padding: 5 }} className="print_pdf_children_legend">
                {_.chain(data)
                    .map((value, index) => (
                        <span
                            key={`legend-values-${index}-${getRamdonString()}`}
                            style={{
                                display: "inline-block",
                                fontSize: 12,
                                marginRight: 7,
                                textAlign: "left" /* , whiteSpace: 'nowrap' */,
                            }}
                        >
                            <div style={getStyleCircle(_.get(value, "color", "#FFF"))}>
                                <div style={{ opacity: 0, position: "absolute" }}>.</div>
                            </div>
                            {`${_.get(value, "textIndex", "")} ${getTextValue(_.get(value, "textValue", ""))}`}
                        </span>
                    ))
                    .value()}
            </div>
        );
    }
}

function getStyleCircle(color) {
    return {
        display: "inline-block",
        width: 13,
        height: 13,
        margin: "0px 2px -2px 0px",
        borderRadius: "100%",
        backgroundColor: color,
        color,
    };
}

function getTextValue(textValue) {
    // if(_.isEqual(textValue, "")){
    return "";
    /* } else {
        return `(${textValue})`;
    } */
}

LegendComponent.propTypes = {
    data: PropTypes.array.isRequired,
};

export default LegendComponent;
