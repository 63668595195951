import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Row, Col} from 'react-flexbox-grid';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Paper from 'material-ui/Paper';
import {getAdvanceProjectSurvey} from '../../../actions/projects_form';
import {unMountProject} from '../../../actions/info/projects_info_form';
import DoughnutChartGraphics from '../../../components/DoughnutChart/doughnutChartGraphics';
import _ from 'lodash';
import RaisedButton from 'material-ui/RaisedButton';
import { Link } from 'react-router';
import ButtonBack from '../../fields/buttonBack/buttonBack';
import {browserHistory} from 'react-router';
import {LBLPROJECTS} from '../../../constants/labels';
import SpinnerLoading from '../../../components/SpinnerLoading/componentSpinner';
import LoadingPage from '../../../components/commons/loadingPage';
import AuthComponent from '../../AuthComponent';
import AdvanceUser from '../../fields/AdvanceUser/AdvanceUser';
import {blue700} from 'material-ui/styles/colors';
import TablePagination from '../../TablePagination/tablePagination';
import TagsEmployee from '../../TagsEmployee/TagsEmployee';


const stylePaper = {
    marginBottom: 20,
    marginTop: 40
};

class advanceProjectSurvey extends Component {

    constructor(props) {
        super(props);
        this.AdvanceProjectSurvey = this.AdvanceProjectSurvey.bind(this);
    }

    componentWillUnmount(){
        this.props.unMountProject();
    }

    UNSAFE_componentWillMount() {
        const {
            profile
        } = this.props;
        this.AdvanceProjectSurvey();
        const personId = _.get(profile, 'person._id');
        if (!personId) {
            console.error('No hay un usuario logeado');
            browserHistory.push(`/login`);
        }
    }

    AdvanceProjectSurvey(){
        const {
            getAdvanceProjectSurvey,
            params
        } = this.props;
        getAdvanceProjectSurvey(_.get(params, 'project'));
    }

    renderDescription(descrip) {
        let description = "";
        if (!_.isUndefined(descrip) && !_.isEqual(descrip, "")) {
            description = _.split(descrip, /\r\n|\r|\n/g);
        }
        if (description instanceof Array) {
            return (
                <h3 style={{textAlign: 'justify', fontWeight: 200}}>
                    {description.map((value, index) => {
                        if (!_.isEqual(value, '')) {
                            return (
                                <div key={index}>{value}<br/><br/></div>
                            );
                        }
                    })}
                </h3>
            );
        } else {
            return (
                <h3 style={{textAlign: 'justify', fontWeight: 200}}>{description}</h3>
            );
        }
    }

    render() {
        const {
            projectName,
            projectDescription,
            businessName,
            advance,
            params,
            textTag,
            textValueTag,
            projectSurveyId,
            status
        } = this.props;
        const body = [
            {
                field: "id",
                title: "Identificación",
                filter: true,
                colspan: 2
            },
            {
                field: "name",
                title: "Nombre",
                filter: true,
                colspan: 2
            },
            {
                field: "lastName",
                title: "Apellido(s)",
                filter: true,
                colspan: 2
            },
            {
                field: "email",
                title: "Correo electrónico",
                colspan: 2
            },
            {
                field: "status",
                title: "Estado",
                order: false,
                colspan: 2
            },
            {
                actions: true,
                title: ""
            }
        ];
        let extra = { project: _.get(params, 'project') };
        if(!_.isNull(textValueTag) && !_.isNull(textTag)){
            extra = _.chain(extra).set('textTag', textTag).set('textValueTag', textValueTag).value();
        }
        if(!_.isEqual(status, 'all') && !_.isNull(status)){
            extra = _.chain(extra).set('status', status).value();
        }
        const urlBack = `/dashboard/company/projects`;
        const urlProjectStats = `/dashboard/company/project/sarin/comp/${_.get(params, 'company')}/${_.get(params, 'project')}`;
        const urlProjectRenderReport = `/dashboard/company/project/report/${_.get(params, "profile")}/company/${_.get(params,"company")}/project/${_.get(params, "project")}/survey/${projectSurveyId}`;

        if (!_.isNull(projectName) && !_.isNull(advance)) {
            return (
                <Row>
                    <AuthComponent
                        component={
                            <Col xs={12} mdOffset={1} md={10}>
                                <Paper style={stylePaper}>
                                    <Row>
                                        <Col xs>
                                            <div style={{width: '100%', background: blue700, paddingTop: 5, paddingBottom: 5, marginBottom: 10, color: "#FFF"}}>
                                                <h1 style={{textAlign: 'center', paddingLeft: 10, paddingRight: 10, fontWeight: 400, lineHeight: 1.2}}>{`${businessName}`}</h1>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs>
                                            <ButtonBack url={urlBack} />
                                            <h1 style={{textAlign: 'center', fontWeight: 400, paddingLeft: 10, paddingRight: 10, marginTop: 30, lineHeight: 1.2}}>{projectName}</h1>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xsOffset={1} xs={10}>
                                            {this.renderDescription(projectDescription)}
                                        </Col>
                                    </Row>
                                    <DoughnutChartGraphics projectId={_.get(params, 'project')}/>
                                    <AuthComponent
                                        component = {
                                            <Link to={urlProjectStats}>
                                                <RaisedButton
                                                    label={LBLPROJECTS.buttons.viewResultStats}
                                                    style={{margin: 15}}
                                                    secondary={true}
                                                />
                                            </Link>
                                        }
                                        permission={"cgr_results_project_view"}
                                        type={"component"}
                                    />
                                    <AuthComponent
                                        component={
                                            <Link to={urlProjectRenderReport} style={{ marginRight: 20 }}>
                                                <RaisedButton
                                                    label={LBLPROJECTS.buttons.viewReportProject}
                                                    secondary={true}
                                                    disabled={_.isUndefined(projectSurveyId)}
                                                    style={{display: _.isUndefined(projectSurveyId) ? 'none' : 'inline-block'}}
                                                />
                                            </Link>
                                        }
                                        permission={"cgr_results_project_report"}
                                        type={"component"}
                                    />
                                    <Row>
                                        <Col xs>
                                            <TagsEmployee
                                                companyId={_.get(params, 'company')}
                                                URL={`/projects/employees/status/${_.get(params, 'company')}/${_.get(params, 'project')}`}
                                                version={2}
                                                extra={{company: _.get(params, 'company'), profile: _.get(params, 'profile')}}
                                                filterStatus={true}
                                                body={body}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs>
                                            <TablePagination
                                                name={`configemployeesstatuscompany`}
                                                URL={`/projects/employees/status/${_.get(params, 'company')}/${_.get(params, 'project')}`}
                                                version={2}
                                                body={body}
                                                extra={extra}
                                            />
                                        </Col>
                                    </Row>
                                </Paper>
                                <SpinnerLoading/>
                            </Col>
                        }
                        permission={"cgr_projects_status"}
                        type={"url"}
                    />
                    <AdvanceUser/>
                </Row>
            );
          } else {
              return (<LoadingPage visible={true} text="Cargando..."/>);
          }
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getAdvanceProjectSurvey,
        unMountProject
    }, dispatch);
}

function mapStateToProps({ login, projects, tagsEmployee, tablePagination }) {

    return {
        login,
        businessName: _.get(projects, 'project.data.company.businessName', ""),
        projectName: _.get(projects, 'project.data.name', ""),
        projectDescription: _.get(projects, 'project.data.description', ""),
        profile: login.get('profile'),
        textValueTag: tagsEmployee.get("textValueTag"),
        projectSurveyId: _.get(projects, "project.data.survey._id"),
        textTag: tagsEmployee.get("textTag"),
        selected: tablePagination.get("selected"),
        status: tablePagination.get('status')
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(advanceProjectSurvey);
