import {blue700} from 'material-ui/styles/colors';
import Spacing from 'material-ui/styles/spacing';
import zIndex from 'material-ui/styles/zIndex';

export default {
    spacing: Spacing,
    zIndex: zIndex,
    fontFamily: 'Roboto, sans-serif',
    palette: {
        primary1Color: blue700,
        primary2Color: blue700,
        primary3Color: blue700,
        accent1Color: blue700,
        accent2Color: blue700,
        accent3Color: blue700,

    }
};
