import PropTypes from "prop-types";
import React, { Component } from "react";
import _ from "lodash";
import { blue700 } from "material-ui/styles/colors";
import Scroll, { animateScroll } from "react-scroll";
import RowComponent from "../../reportComponents/rowComponent";

class Report extends Component {
    constructor(props) {
        super(props);
        this.handleScroll = this.handleScroll.bind(this);
        this.state = {
            isGoTop: false,
        };
    }

    static propTypes = {
        surveyId: PropTypes.string.isRequired,
        projectId: PropTypes.string.isRequired,
        rows: PropTypes.array.isRequired,
        menu: PropTypes.array.isRequired,
        colors: PropTypes.array.isRequired,
        maxRanking: PropTypes.number.isRequired,
    };

    _handleGoTop = () => {
        const { isGoTop } = this.state;
        if (isGoTop) {
            animateScroll.scrollToTop();
        }
    };

    handleScroll = (e) => {
        const positionScroll =
            _.toNumber(window.pageYOffset) > _.toNumber(window.scrollY)
                ? _.toNumber(window.pageYOffset)
                : _.toNumber(window.scrollY);
        if (positionScroll > 800) {
            this.setState({ isGoTop: true });
        } else {
            this.setState({ isGoTop: false });
        }
    };

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    render() {
        const {
            surveyId,
            rows,
            menu,
            projectId,
            colors,
            maxRanking,
        } = this.props;
        const { isGoTop } = this.state;
        if (_.size(rows) > 0) {
            return (
                <div
                    style={{
                        padding: "0px 0px",
                        boxSizing: "border-box",
                        fontFamily: "'Roboto', sans-serif",
                    }}
                >
                    {_.map(rows, (row, index) => (
                        <RowComponent
                            key={`row-${index}`}
                            columns={_.get(row, "columns", 1)}
                            index={index}
                            projectId={projectId}
                            sections={_.get(row, "sections", [])}
                            title={_.get(row, "title", {})}
                            menu={menu}
                            colors={colors}
                            maxRanking={maxRanking}
                        />
                    ))}
                    <div
                        style={{
                            zIddex: 999,
                            position: "fixed",
                            bottom: 0,
                            left: 0,
                            display: isGoTop ? "inline" : "none",
                            width: "100%",
                        }}
                    >
                        <div
                            style={{
                                width: 80,
                                textAlign: "center",
                                margin: "0 auto 15px auto",
                                padding: 7,
                                borderRadius: 8,
                                color: "#FFF",
                                backgroundColor: blue700,
                                fontSize: 14,
                                cursor: "pointer",
                            }}
                            onClick={this._handleGoTop}
                        >
                            Ir arriba
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div
                style={{
                    padding: "0px 0px",
                    boxSizing: "border-box",
                    fontFamily: "'Roboto', sans-serif",
                }}
            />
        );
    }
}

export default Report;
