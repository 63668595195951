import PropTypes from "prop-types";
import React, { Component } from "react";
import FlatButton from "material-ui/FlatButton";
import DownloadIcon from "material-ui/svg-icons/file/file-download";
import IconButton from "material-ui/IconButton";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TextField from "material-ui/TextField";
import is from "is_js";
import _ from "lodash";
import Dialog from "material-ui/Dialog";
import { blue700 } from "material-ui/styles/colors";
import { toggleSnackbar } from "../../../actions/commons";
import { downloadReportProject } from "../renderReport/ducks";

class DownloadReportProject extends Component {
    static propTypes = {
        projectId: PropTypes.string.isRequired,
        surveyId: PropTypes.string.isRequired,
        companyId: PropTypes.string.isRequired,
    };

    state = {
        email: "",
        emailError: "",
        nameFile: "",
        nameFileError: "",
        open: false,
    };

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    _downloadFile = (formData) => {
        const {
            downloadReportProject,
            toggleSnackbar,
            projectId,
            surveyId,
            companyId,
            filters,
        } = this.props;
        const { email, nameFile } = this.state;
        downloadReportProject(
            projectId,
            filters,
            surveyId,
            companyId,
            nameFile,
            email
        )
            .then(
                (data) => {
                    if (
                        _.get(data, "error") ||
                        _.get(data, "payload.status") !== 200
                    ) {
                        toggleSnackbar(
                            true,
                            "Ha ocurrido un error en el servidor"
                        );
                    } else {
                        toggleSnackbar(true, _.get(data, "payload.data.text"));
                    }
                    this.setState({
                        email: "",
                        emailError: "",
                        nameFile: "",
                        nameFileError: "",
                    });
                },
                (reason) => {
                    toggleSnackbar(true, "Ha ocurrido un error en el servidor");
                    this.setState({
                        email: "",
                        emailError: "",
                        nameFile: "",
                        nameFileError: "",
                    });
                }
            )
            .catch((data) => {
                toggleSnackbar(true, "Ha ocurrido un error en el servidor");
                this.setState({
                    email: "",
                    emailError: "",
                    nameFile: "",
                    nameFileError: "",
                });
            });
        this.setState({ open: false });
    };

    _onChangeEmail = (e, text) => {
        if (
            !_.isEqual(text, "") &&
            !_.isNull(text, "") &&
            !_.isUndefined(text, "")
        ) {
            if (!is.email(text)) {
                this.setState({
                    email: text,
                    emailError: "El correo electrónico no es válido",
                });
            } else {
                this.setState({ email: text, emailError: "" });
            }
        } else {
            this.setState({
                email: text,
                emailError: "El correo electrónico no debe estar vació",
            });
        }
    };

    _onChangeNameFile = (e, text) => {
        if (
            _.isEqual(text, "") ||
            _.isNull(text, "") ||
            _.isUndefined(text, "")
        ) {
            this.setState({
                nameFile: text,
                nameFileError: "El nombre del archivo no debe estar vació",
            });
        } else {
            this.setState({ nameFile: text, nameFileError: "" });
        }
    };

    render() {
        const { handleSubmit, filters } = this.props;
        const { open, emailError, email, nameFile, nameFileError } = this.state;
        const actions = [
            <FlatButton
                label="Cerrar"
                primary={true}
                onClick={this.handleClose}
            />,
            <FlatButton
                label="Descargar"
                primary={true}
                disabled={
                    !_.isEqual(emailError, "") ||
                    _.isEqual(email, "") ||
                    !_.isEqual(nameFileError, "") ||
                    _.isEqual(nameFile, "")
                }
                onClick={this._downloadFile}
            />,
        ];
        return (
            <div style={{ width: 48, display: "inline-block" }}>
                <IconButton
                    tooltip="Descargar informe"
                    tooltipPosition="bottom-center"
                    onClick={this.handleOpen}
                    children={<DownloadIcon color={"#FFF"} />}
                />
                <Dialog
                    title={"Información para descargar PDF"}
                    actions={actions}
                    modal={false}
                    open={open}
                    onRequestClose={this.handleClose}
                    contentStyle={{
                        maxWidth: "420px",
                        width: "100%",
                        textAlign: "center",
                    }}
                    autoScrollBodyContent={true}
                    titleStyle={{ textAlign: "center", color: blue700 }}
                >
                    <div style={{ padding: "5px 5px 20px 5px" }}>
                        <TextField
                            style={{ marginTop: 10 }}
                            floatingLabelText="Correo electrónico"
                            errorText={emailError}
                            onChange={this._onChangeEmail}
                            value={email}
                        />
                        <TextField
                            style={{ marginTop: 10 }}
                            hintText="Ej: Informe"
                            floatingLabelText="Nombre del archivo"
                            errorText={nameFileError}
                            onChange={this._onChangeNameFile}
                            value={nameFile}
                        />
                    </div>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps({ filterReport }) {
    const currentFilters = filterReport.get("currentFilters");
    let filters = [{ name: "Todos", combine: [] }];
    if (_.size(currentFilters) > 0) {
        filters = currentFilters;
    }
    return { filters };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleSnackbar,
            downloadReportProject,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DownloadReportProject);
