import PropTypes from "prop-types";
import React, { Component } from "react";
import { select, scaleBand, scaleLinear, max, axisBottom, axisLeft, scaleOrdinal, format } from "d3";
import { stack } from "d3-shape";
import Faux from "react-faux-dom";
import _ from "lodash";
import LegendStackedKeysComponent from "../legends/legendStackedKeysComponent";
import LegendStackedComponent from "../legends/legendStackedComponent";

class BarStacked3DChartComponent extends Component {
    constructor(props) {
        super(props);
        this.renderLegend = this.renderLegend.bind(this);
        this.renderSubLegend = this.renderSubLegend.bind(this);
    }

    renderLegend() {
        const { hasLegend, data, size, keys } = this.props;
        if (!_.isUndefined(hasLegend) && !_.isNull(hasLegend)) {
            if (hasLegend) {
                return <LegendStackedComponent data={data} size={size} keys={keys} />;
            }
            return <span />;
        }
        return <span />;
    }

    renderSubLegend() {
        const { hasSubLegend, size, keys } = this.props;
        if (!_.isUndefined(hasSubLegend) && !_.isNull(hasSubLegend)) {
            if (hasSubLegend) {
                return <LegendStackedKeysComponent data={keys} size={size} />;
            }
            return <span />;
        }
        return <span />;
    }

    render() {
        const { textVertical, textHorizontal, data, keys, size, height, width, hasAxis, hasSubLegend } = this.props;
        const faux = Faux.createElement("div"),
            margin = { top: 20, right: 20, bottom: 30, left: 50 },
            fontSizePercentage = 10,
            isAxis = !_.isNull(hasAxis) && !_.isUndefined(hasAxis) ? hasAxis : true;
        const darkerColor = -45;
        let sizeSubLegend = 0;
        if (!_.isUndefined(hasSubLegend) && !_.isNull(hasSubLegend)) {
            if (hasSubLegend) {
                sizeSubLegend = width * 0.3;
            }
        }

        const svg = select(faux)
            .append("svg")
            .attr("height", height)
            .attr("width", width)
            .style("page-break-inside", "avoid");

        const widthSvg = +width - margin.left - margin.right;
        const heightSvg = +height - margin.top - margin.bottom;

        const g = svg.append("g").attr("transform", `translate(${margin.left},${margin.top})`);

        const stackKey = _.chain(keys)
            .map(k => _.get(k, "key", null))
            .filter(k => !_.isNull(k))
            .value();

        const layers = stack().keys(stackKey)(data);

        const x = scaleLinear()
            .rangeRound([0, widthSvg])
            .domain([0, max(layers[layers.length - 1], d => 100)])
            .nice();

        const y = scaleBand()
            .rangeRound([heightSvg, 0])
            .padding(0.2)
            .domain(_.map(data, d => d.index));

        const color = scaleOrdinal(
            _.chain(keys)
                .map(k => _.get(k, "color", "#FFF"))
                .filter(k => !_.isNull(k))
                .value()
        );

        const color3D = scaleOrdinal(
            _.chain(keys)
                .map(k => LightenDarkenColor(_.get(k, "color", "#FFF"), darkerColor))
                .filter(k => !_.isNull(k))
                .value()
        );

        const bars3D = g
            .selectAll(".bar3D")
            .data(layers)
            .enter()
            .append("g")
            .attr("class", "bar3D")
            .style("fill", (d, i) => color3D(i))
            .selectAll("rect")
            .data(d => d)
            .enter()
            .append("rect")
            .attr("y", d => y(d.data.index) - 12)
            .attr("x", d => x(d[0]))
            .attr("height", y.bandwidth())
            .attr("width", d => x(d[1]) - x(d[0]));

        const bars = g
            .selectAll(".bar")
            .data(layers)
            .enter()
            .append("g")
            .attr("class", "bar")
            .style("fill", (d, i) => color(i))
            .selectAll("rect")
            .data(d => d)
            .enter()
            .append("rect")
            .attr("y", d => y(d.data.index))
            .attr("x", d => x(d[0]))
            .attr("height", y.bandwidth())
            .attr("width", d => x(d[1]) - x(d[0]));

        /* const text = g
            .selectAll(".text")
            .data(layers)
            .enter()
            .append("g")
            .attr("class", "tect-text")
            .selectAll("text")
            .data(d => d)
            .enter()
            .append("text")
            .attr("class", "text")
            .style("stroke-width", 2)
            .attr("font-size", fontSizePercentage)
            .attr("y", d => y(d.data.index) + 13)
            .attr("x", d => x(d[0]) + 3)
            .text(d => {
                let percentage = "";
                if (d[1] - d[0] > 0) {
                    percentage = d[1] - d[0];
                }
                return `${percentage}%`;
            }); */

        if (isAxis) {
            g
                .append("g")
                .attr("class", "axis axis--x-stack")
                .attr("transform", `translate(0,${heightSvg})`)
                .call(axisBottom(x).ticks(1));

            g
                .append("g")
                .attr("class", "axis axis--y-stack")
                .attr("transform", "translate(0,0)")
                .call(axisLeft(y));
        }

        return (
            <div style={{ textAlign: "center", width: width + 10 + sizeSubLegend, display: "inline-block" }}>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <tbody>
                        <tr>
                            <td style={{ padding: 0 }}>{faux.toReact()}</td>
                            <td style={{ padding: 5, width: sizeSubLegend, verticalAlign: "top", textAlign: "left" }}>
                                {this.renderSubLegend()}
                            </td>
                        </tr>
                        <tr>
                            <td style={{ padding: 5 }}>{this.renderLegend()}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

function LightenDarkenColor(col, amt) {
    let usePound = false;
    if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
    }
    let num = parseInt(col, 16),
        r = (num >> 16) + amt;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    let b = ((num >> 8) & 0x00ff) + amt;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
    let g = (num & 0x0000ff) + amt;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

function validText(text) {
    return !(_.isUndefined(text) || _.isNull(text) || _.isEqual(text, ""));
}

BarStacked3DChartComponent.propTypes = {
    data: PropTypes.array.isRequired,
    keys: PropTypes.array.isRequired,
    textVertical: PropTypes.string,
    textHorizontal: PropTypes.string,
    hasLegend: PropTypes.bool,
    hasSubLegend: PropTypes.bool,
    size: PropTypes.oneOf(["small", "middle", "large"]),
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    hasAxis: PropTypes.bool
};

export default BarStacked3DChartComponent;
