import React, { PureComponent } from "react";
import { string, func, any } from "prop-types";
import { IconConfig } from "./icons";

const styles = {
    contentIcon: {
        width: 16,
        height: 16,
        cursor: "pointer",
    },
};

class ConfigElementComponent extends PureComponent {
    static propTypes = {
        path: string.isRequired,
        handleClick: func.isRequired,
        propElement: any.isRequired,
    };

    state = {
        hover: false,
    };

    handleOnMouseEnter = () => {
        this.setState({ hover: true });
    };

    handleOnMouseLeave = () => {
        this.setState({ hover: false });
    };

    handleConfigElement = () => {
        const { path, handleClick, propElement } = this.props;
        handleClick(path, propElement);
    };

    render() {
        const { hover } = this.state;
        return (
            <div
                style={{ ...styles.contentIcon }}
                onClick={this.handleConfigElement}
                onMouseEnter={this.handleOnMouseEnter}
                onMouseLeave={this.handleOnMouseLeave}
            >
                <IconConfig color={hover ? "#0B54CE" : "#FFF"} />
            </div>
        );
    }
}

export default ConfigElementComponent;
