import { POST_LIST_MASTER, GET_LISTMASTER, GET_LISTMASTER_PARAMS,DELETE_LISTMASTER } from '../constants/index';
import _ from 'lodash';
import { axiosAPI } from '../middleware/api';

export function createListMaster(props) {

    function getProps(data){
        return _.map(data, (item, index) => {
            return {
                key: index,
                value: _.get(item, 'value')
            };
        });
    }

    const request = axiosAPI.post(`/listmaster`, {
                                                    listmaster: _.set(props, 'options', getProps(props.options))
                                                });

    return {
        type: POST_LIST_MASTER,
        payload: request
    }
}

export function loadListMaster() {

    const request = axiosAPI.get(`/listmaster`);

    return {
        type: GET_LISTMASTER,
        payload: request
    }
}

export function loadListMasterName(){
    const request = axiosAPI.get(`/listmaster/names`);

    return {
        type: GET_LISTMASTER,
        payload: request
    }
}


export function loadListMasterParams(id) {

    const request = axiosAPI.get(`/listmaster/${id}`);

    return {
        type: GET_LISTMASTER_PARAMS,
        payload: request
    }
}

export function deleteMasterList(listId) {

    const request = axiosAPI.delete(`/listmaster/${listId}`, {data: {"type": 'logic'}});

    return {
        type: DELETE_LISTMASTER,
        payload: request,
        meta: {
            listId
        }
    }
}
