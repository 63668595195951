import PropTypes from "prop-types";
import React, { Component } from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { blue700 } from "material-ui/styles/colors";
import IconButton from "material-ui/IconButton";
import ContentCopyIcon from "material-ui/svg-icons/content/content-copy";
import _ from "lodash";

class CloneEntityModal extends Component {
    constructor(props) {
        super(props);
        const { fn, args } = props;
        this.state = {
            open: false,
        };
        this._handleDeleteAction = this._handleDeleteAction.bind(
            this,
            fn,
            args
        );
        this._handleCloseAction = this._handleCloseAction.bind(this);
        this._handleOpenAction = this._handleOpenAction.bind(this);
    }

    _handleDeleteAction(fn, args) {
        this.setState({ open: false });
        fn.apply(this, args);
    }

    _handleCloseAction() {
        this.setState({ open: false });
    }

    _handleOpenAction() {
        this.setState({ open: true });
    }

    render() {
        const { title, tooltip, message, config, style } = this.props;
        const tooltipStyle = {
            zIndex: 100,
        };
        const styleIcon = _.isUndefined(style) ? {} : style;
        const actions = [
            <FlatButton
                label="Cancelar"
                secondary={true}
                onClick={this._handleCloseAction}
            />,
            <FlatButton
                label="Clonar"
                primary={true}
                keyboardFocused={true}
                onClick={this._handleDeleteAction}
            />,
        ];
        return (
            <span>
                <IconButton
                    tooltip={tooltip}
                    style={styleIcon}
                    tooltipPosition="top-center"
                    children={<ContentCopyIcon />}
                    onClick={this._handleOpenAction}
                    tooltipStyles={tooltipStyle}
                />
                <Dialog
                    {...config}
                    title={title}
                    actions={actions}
                    titleStyle={{ textAlign: "center", color: blue700 }}
                    contentStyle={{
                        maxWidth: 600,
                        minWidth: 300,
                        textAlign: "justify",
                    }}
                    modal={false}
                    open={this.state.open}
                    onRequestClose={this._handleCloseAction}
                >
                    <h3 style={{ fontWeight: 200, textAlign: "justify" }}>
                        {message}
                    </h3>
                </Dialog>
            </span>
        );
    }
}

CloneEntityModal.propTypes = {
    title: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    config: PropTypes.object,
    fn: PropTypes.func.isRequired,
    args: PropTypes.array.isRequired,
    style: PropTypes.object,
};

export default CloneEntityModal;
