import React, { Component } from "react";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import randomColor from "randomcolor";
import RenderReport from "./reportPrint/renderReport";
import AuthComponent from "../AuthComponent";
import { setColorDefault, getFilterTraceReport, getValuesReportRenderPrint, setFilterDefault } from "./renderReport/ducks";
import { FetchCompanyParam } from "../../actions/info/companies_info_form";
import { updateFilters, cleanSelectedFilters, cleanFilters } from "../analyticsReport/ducks";
import { axiosAPI, axiosAPI_V2 } from "../../middleware/api";
import { parseSolrFiltersToSarinFilters } from "../analyticsReport/item/functions";
import { pageTypes } from "./types";

class RenderReportProjectPrint extends Component {
    componentDidMount() {
        const {
            getFilterTraceReport,
            setColorDefault,
            getValuesReportRenderPrint,
            params,
            updateFilters,
            cleanFilters,
            FetchCompanyParam
        } = this.props;
        const token = _.replace(_.get(params, "token", ""), /___/g, ".");
        axiosAPI.defaults.headers.authorization = token;
        axiosAPI_V2.defaults.headers.authorization = token;
        const project = _.get(params, "project");
        const trace = _.get(params, "trace");
        // http://localhost:3000/print/report/token/eyJhbGciOiJIUzI1NiJ9___eyJpc3MiOiJodHRwczovL2F1dGgwLnNvbHVjaW9uZXNjZ3IuY29tLyIsInJlZnJlc2giOiJiZGEyYzc1NC01ODRhLTQzODQtYjY5MS05MGM4OWIxMjFjMTMiLCJleHAiOjE1MjU3MzEzOTksImlhdCI6MTUyNTcyNDE5OSwidXNlciI6IjU3NmM2NTNlMGJkYjY2ODgwM2ExZjhmNyIsImp0aSI6ImM0ZDA3ZjYyLTRhOWMtNGJkOC1hNzU2LTc0Y2MyY2VhYmYxNiJ9___Q32vvTQqOMEuinkdfI4144fUBLW7uIL0CBUnmJt_uk0/company/5991b0f27681be001053b1bd/project/5991b15b7681be001053b22d/survey/580a2d633b5f24a015b0aa72/trace/5af0b828c9e5898034afc6c6
        setColorDefault(randomColor({ luminosity: "light", count: 70 }));
        cleanFilters();
        FetchCompanyParam(_.get(params, "company"));
        getFilterTraceReport(project, trace)
            .then(data => {
                setFilterDefault();
                if (_.get(data, "error") || _.get(data, "payload.status") !== 200) {
                    updateFilters(parseSolrFiltersToSarinFilters([{ name: "Todos", combine: [] }], project));
                } else {
                    updateFilters(parseSolrFiltersToSarinFilters(_.get(data, "payload.data", []), project));
                }
            })
            .catch(data => {
                setFilterDefault();
                updateFilters(parseSolrFiltersToSarinFilters([{ name: "Todos", combine: [] }], project));
            });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {
            setFilterDefault,
            updateFilters,
            cleanFilters,
            cleanSelectedFilters,
            filterDefault,
            loadFilterDefault,
            setFilter,
            params
        } = this.props;
        const project = _.get(params, "project");
        const nextFilterDefault = _.get(nextProps, "filterDefault", []);
        if (loadFilterDefault && !setFilter) {
            cleanFilters();
            cleanSelectedFilters();
            setFilterDefault();
            updateFilters(parseSolrFiltersToSarinFilters(nextFilterDefault, project));
        }
    }

    render() {
        const { params, filters, company } = this.props;
        if (_.isEqual(filters, [])) {
            return <div />;
        }
        let companyInfo = <span />;
        if (!_.isNull(company)) {
            const styleTitleCompany = {
                width: 750,
                margin: "0px auto",
                paddingTop: 20,
                textAlign: "center",
                color: "#7B7B7B",
                fontSize: 15
            };
            companyInfo = <div style={{ ...styleTitleCompany }}>{company}</div>;
        }
        return (
            <AuthComponent
                component={
                    <div>
                        {companyInfo}
                        <RenderReport page={pageTypes.MAIN} project={_.get(params, "project")} survey={_.get(params, "survey")} />
                        <RenderReport
                            page={pageTypes.SOCIO_DEMOGRAPHIC}
                            project={_.get(params, "project")}
                            survey={_.get(params, "survey")}
                        />
                        <RenderReport page={pageTypes.INDICATORS} project={_.get(params, "project")} survey={_.get(params, "survey")} />
                        <RenderReport page={pageTypes.RANKING} project={_.get(params, "project")} survey={_.get(params, "survey")} />
                    </div>
                }
                permission={"default"}
                type={"component"}
                defaultPermission
            />
        );
    }
}

function mapStateToProps({ renderReport, analyticsReport, companies }) {
    const nameCompany = _.get(companies, "company.data.businessName", null);
    const nitCompany = _.get(companies, "company.data.nit", null);
    let company = null;
    if (!_.isNull(nameCompany) && !_.isNull(nitCompany)) {
        company = `${nitCompany} - ${nameCompany}`;
    }
    return {
        filterDefault: renderReport.get("filterDefault"),
        loadFilterDefault: renderReport.get("loadFilterDefault"),
        setFilter: renderReport.get("setFilter"),
        filters: analyticsReport.get("filters").toJS(),
        company
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setColorDefault,
            getFilterTraceReport,
            getValuesReportRenderPrint,
            setFilterDefault,
            updateFilters,
            cleanFilters,
            cleanSelectedFilters,
            FetchCompanyParam
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderReportProjectPrint);
