import { GET_CONSENTS_GRID } from '../actions/grid/consents_grid_form';
import { POST_CONSENT_INFO } from '../actions/info/consents_info_form';
import { LOAD_ALL_CONSENT, LOAD_APPROVAL_CONSENTS,DELETE_CONSENT } from '../constants/index';
import _ from 'lodash';

const INITIAL_STATE = { all: [], consents: null, consent: null};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_CONSENTS_GRID:
            return  _.assign({}, state, {
                all: action.payload.data
            });
        case POST_CONSENT_INFO:
            return  _.assign({}, state, {
                all: action.payload.data
            });
        case LOAD_ALL_CONSENT:
            return  _.assign({}, state, {
                all: action.payload.data
            });
        case LOAD_APPROVAL_CONSENTS:
            return  _.assign({}, state, {
                consent: action.payload.data
            });
        case DELETE_CONSENT:
            var newAll = _.filter(state.all.data, item => {
                return !_.isEqual(item._id, action.meta.consentId);
            });
            return _.assign({}, state, {
                all: {
                    data:newAll
                }
            });
        default:
            return state;
    }
}
