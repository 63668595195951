import React, { Component } from "react";
import CircularProgress from "material-ui/CircularProgress";

class WaitComponent extends Component {
    render() {
        return (
            <div style={{ textAlign: "center", height: 34, padding: "15px 5px" }}>
                <CircularProgress size={30} thickness={3} />
            </div>
        );
    }
}

export default WaitComponent;
