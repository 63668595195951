import PropTypes from "prop-types";
import React, { Component } from "react";
import TableCell from "@material-ui/core/TableCell";
import _ from "lodash";
import { Link } from "react-router";
import IconButton from "material-ui/IconButton";
import { connect } from "react-redux";
import EditIcon from "material-ui/svg-icons/editor/mode-edit";
import AuthComponent from "../../AuthComponent";
import { LBLQUESTIONS } from "../../../constants/labels";
import DeleteEntityModal from "../../deleteEntityModal/component";
import { deleteQuestions } from "../../../actions/questions_form";
import { toggleSnackbar } from "../../../actions/commons";
import { removeRecord } from "../actions";

class QuestionActionColumn extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        colSpan: PropTypes.number,
    };

    _handleDeleteQuestion = (data) => {
        const { deleteQuestions, toggleSnackbar, removeRecord } = this.props;
        const idQuestion = _.get(data, "_id");
        deleteQuestions(idQuestion)
            .then(
                (data) => {
                    if (
                        _.get(data, "error") ||
                        _.get(data, "payload.status") !== 200
                    ) {
                        toggleSnackbar(true, LBLQUESTIONS.msg.errorDelete);
                    } else {
                        removeRecord(idQuestion, "_id");
                        toggleSnackbar(true, LBLQUESTIONS.msg.successDelete);
                    }
                },
                (reason) => {
                    toggleSnackbar(true, LBLQUESTIONS.msg.errorDelete);
                }
            )
            .catch((data) => {
                toggleSnackbar(true, LBLQUESTIONS.msg.errorDelete);
            });
    };

    render() {
        const { data, colSpan } = this.props;
        const cs = _.isUndefined(colSpan) ? 1 : colSpan;

        let url = `/admin/questions/edit/${_.get(data, "_id")}`;
        if (
            _.isEqual(_.get(data, "type"), "GroupClose") ||
            _.isEqual(_.get(data, "type"), "GroupList") ||
            _.isEqual(_.get(data, "type"), "Group")
        ) {
            url = `/admin/questions/group/edit/${_.get(data, "_id")}`;
        }
        return (
            <TableCell colSpan={cs}>
                <div
                    style={{ position: "absolute", marginTop: -24, zIndex: 99 }}
                >
                    <AuthComponent
                        component={
                            <Link to={`${url}`}>
                                <IconButton
                                    tooltip={LBLQUESTIONS.tooltips.btnEdit}
                                    tooltipPosition="top-center"
                                >
                                    <EditIcon />
                                </IconButton>
                            </Link>
                        }
                        permission={"cgr_questions_update"}
                        type={"component"}
                    />
                    <AuthComponent
                        component={
                            <DeleteEntityModal
                                tooltip={LBLQUESTIONS.tooltips.btnDelete}
                                title={LBLQUESTIONS.titleDelete}
                                message={LBLQUESTIONS.msg.deleteModal(
                                    _.get(data, "title")
                                )}
                                fn={this._handleDeleteQuestion}
                                args={[data]}
                                style={{ display: "table-cell" }}
                            />
                        }
                        permission={"cgr_questions_delete"}
                        type={"component"}
                    />
                </div>
            </TableCell>
        );
    }
}

export default connect(null, { deleteQuestions, toggleSnackbar, removeRecord })(
    QuestionActionColumn
);
