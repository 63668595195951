import PropTypes from "prop-types";
import React, { Component } from "react";
import Downshift from "downshift";
import TextField from "material-ui/TextField";
import Paper from "material-ui/Paper";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { List, ListItem } from "material-ui/List";
import { Set } from "immutable";
import CircularProgress from "material-ui/CircularProgress";
import { searchText as st, getTypeOfRecord } from "./ducks";

const { func, string, shape, bool, arrayOf, number } = PropTypes;

const styles = {
    paper: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 5,
    },
};

/* const itemShape = shape({
    id: string.isRequired,
    lowerLimit: number,
    parentId: string,
    parentTitle: string,
    parentType: string,
    title: string.isRequired,
    type: string.isRequired,
    upperLimit: number,
}); */

class SearchBoxAnalytics extends Component {
    static propTypes = {
        searchText: func.isRequired,
        project: string.isRequired,
        data: shape().isRequired,
        loading: bool.isRequired,
        onChangeItem: func.isRequired,
        selected: arrayOf(string),
        paperDepth: number,
    };

    static defaultProps = {
        selected: [],
        paperDepth: 0,
    };

    handleOnStateChange = ({ inputValue }) => {
        const { searchText, project } = this.props;
        if (!_.isNil(inputValue)) {
            searchText(inputValue, project);
        }
    };

    renderItems = (open, selectedElements) => {
        const { onChangeItem, loading, data } = this.props;
        if (open) {
            if (loading) {
                return [
                    <ListItem disabled key="loading">
                        <div style={{ width: 35, margin: "0 auto" }}>
                            <CircularProgress size={35} thickness={3} />
                        </div>
                    </ListItem>,
                ];
            }
            if (data.isEmpty()) {
                return [
                    <ListItem
                        key="EMPTY"
                        disabled
                        primaryText="Sin resultados en la búsqueda..."
                    />,
                ];
            }
            return data.map((value, index) => {
                const { id, title, parentType, parentTitle } = value;
                const itemProps = {
                    key: id,
                    primaryText: title,
                    secondaryText: `${getTypeOfRecord[parentType]}: ${parentTitle}`,
                    secondaryTextLines: 2,
                    innerDivStyle:
                        index > 0 ? { borderTop: "1px solid #CDCDCD" } : {},
                    onClick: () => {
                        onChangeItem(value);
                    },
                    disabled: selectedElements.includes(id),
                };
                return <ListItem {...itemProps} />;
            });
        }
        return null;
    };

    render() {
        const self = this;
        const { paperDepth, selected } = this.props;
        const selectedElements = Set(selected);
        function renderBuilder({ getInputProps, isOpen, inputValue }) {
            const inputProps = {
                hintText: "¿Por cuales elementos deseas filtrar?",
                floatingLabelText: "Búsqueda de filtros",
                fullWidth: true,
                style: {
                    marginTop: -13,
                },
            };
            return (
                <div>
                    <Paper
                        zDepth={paperDepth}
                        rounded={false}
                        style={styles.paper}
                    >
                        <TextField {...getInputProps(inputProps)} />
                    </Paper>
                    {isOpen && inputValue && inputValue !== "" ? (
                        <Paper
                            zDepth={1}
                            rounded={false}
                            style={{ maxHeight: 500, overflow: "auto" }}
                        >
                            <List>
                                {self.renderItems(isOpen, selectedElements)}
                            </List>
                        </Paper>
                    ) : null}
                </div>
            );
        }
        return (
            <Downshift
                onStateChange={this.handleOnStateChange}
                onChange={(e, val) => console.log("change", e, val)}
            >
                {renderBuilder}
            </Downshift>
        );
    }
}

function mapStateToProps({ searchAnalytics }) {
    return {
        loading: searchAnalytics.get("loading"),
        data: searchAnalytics.get("data"),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            searchText: st,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBoxAnalytics);
