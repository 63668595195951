import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn,TableFooter} from 'material-ui/Table';
import { connect } from 'react-redux';
import {Row, Col} from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import IconButton from 'material-ui/IconButton';
import FloatingActionButtonTooltip from '../../components/fields/FloatingActionButtonTooltip/FloatingActionButtonTooltip';
import EditIcon from 'material-ui/svg-icons/image/edit';
import EditLocationIcon from 'material-ui/svg-icons/action/settings';
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';
import _ from 'lodash';
import { Link } from 'react-router';
import {blue700} from 'material-ui/styles/colors';
import {LBLLOCATIONS} from '../../constants/labels';
import LocationFormModal from '../ModalFormLocation/componentModalFormLocation';
import {openModalForm} from '../ModalFormLocation/actions';
import AuthComponent from '../AuthComponent';
import TablePagination from '../TablePagination/tablePagination';

const stylePaper = {
        marginTop: 20,
        marginBottom: 20
    };

class AdminLocation extends Component {

    constructor(props) {
        super(props);
        this._handleOpenModalForm = this._handleOpenModalForm.bind(this);
    }

    _handleOpenModalForm(title, id, code, name) {
        const {
            openModalForm
        } = this.props;
        openModalForm(id, code, name, title, "country", null, false);
    }

    render() {
        const body = [
            {
                field: "code",
                title: "Código"
            },
            {
                field: "name",
                title: "Nombre",
                colspan: 3,
                filter: true
            },
            {
                actions: true,
                title: "Operaciones",
                colspan: 1
            }
        ];
        return (
            <Row>
                <AuthComponent
                    component={
                        <Col xs={12} mdOffset={2} md={8}>
                            <Paper style={stylePaper}>
                                <Row>
                                    <Col xs>
                                        <div style={{width: '100%', background: blue700, paddingTop: 5, paddingBottom:5, color: "#FFF"}}>
                                            <h1 style={{textAlign: 'center', fontWeight: 400}}>{LBLLOCATIONS.countries}</h1>
                                            <AuthComponent
                                                component={
                                                    <div style={{position: "fixed", right: 15, bottom: 15}}>
                                                        <FloatingActionButtonTooltip
                                                            onClick={this._handleOpenModalForm.bind(this,`${LBLLOCATIONS.tooltips.btnCreate} país`, null, "", "" )}
                                                            textTooltip={LBLLOCATIONS.tooltips.btnCreateCountry}
                                                        />
                                                    </div>
                                                }
                                                permission={"cgr_location_country_create"}
                                                type={"component"}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <TablePagination
                                            name={`countries`}
                                            URL={`/location/countries`}
                                            version={1}
                                            body={body}
                                        />
                                    </Col>
                                </Row>
                            </Paper>
                            <LocationFormModal/>
                        </Col>
                    }
                    permission={"cgr_location_country_list"}
                    type={"url"}
                />
            </Row>
        );
    }
}


function mapStateToProps({master}, ownProps) {
    return {
        countries: master.countries
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openModalForm
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLocation);
