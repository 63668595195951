import {TOGGLE_SHOW_MODAL} from './actions';
import _ from 'lodash';

export default (state = {modalChangePasswordOpen: false}, action) => {
    switch (action.type) {
        case TOGGLE_SHOW_MODAL:
            return _.assign({}, state, {
                modalChangePasswordOpen: action.open
            });
        default:
            return state;
    }
}
