import { axiosAPI_V2 } from '../../../../../middleware/api';
import { GET_DATA_PROJECT_ARRAY_ADVANCE } from './constants';

export function getAdvanceArrayProject(arrayProjectsId) {

    const request = axiosAPI_V2.post(`/arl/companies/projects/status`, {
                                                                            arrayProjectsId: arrayProjectsId
                                                                        });

    return {
        payload: request,
        type: GET_DATA_PROJECT_ARRAY_ADVANCE
    }
}
