import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { IconDown } from "./icons";
import { changePositionPropsComponent } from "../ducks";

const styles = {
    contentIcon: {
        width: 16,
        height: 16,
        cursor: "pointer",
    },
};

class DownElementComponent extends Component {
    static propTypes = {
        path: PropTypes.string.isRequired,
        index: PropTypes.number.isRequired,
        survey: PropTypes.string.isRequired,
        page: PropTypes.string.isRequired,
        position: PropTypes.number.isRequired,
    };

    state = {
        hover: false,
        startPath: "rows.",
    };

    handleOnMouseEnter = () => {
        this.setState({ hover: true });
    };

    handleOnMouseLeave = () => {
        this.setState({ hover: false });
    };

    _handleDownElement = () => {
        const {
            path,
            index,
            position,
            changePositionPropsComponent,
            page,
            survey,
        } = this.props;
        const { startPath } = this.state;
        if (_.startsWith(path, startPath)) {
            changePositionPropsComponent(position, page, survey, "DOWN");
        }
    };

    render() {
        const { hover } = this.state;
        return (
            <div
                style={{ ...styles.contentIcon }}
                onClick={this._handleDownElement}
                onMouseEnter={this.handleOnMouseEnter}
                onMouseLeave={this.handleOnMouseLeave}
            >
                <IconDown color={hover ? "#0B54CE" : "#FFF"} />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ changePositionPropsComponent }, dispatch);
}

function mapStateToProps({ analyticsReportsConfiguration }) {
    return {
        page: analyticsReportsConfiguration.get("page"),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DownElementComponent);
