// @flow

import { Map } from "immutable";
import type { ActionsObservable, Epic } from "redux-observable";
import { Observable } from "rxjs";
import _ from "lodash";
import { axiosAPI_V2 as axios } from "../../middleware/api";
import createReducer from "../../lib/createReducer";
import type {
    StateType,
    ClearStateAction,
    DropComponentAction,
    DropComponentInProgressAction,
    DropComponentFulfillAction,
    UpdateComponentAction,
    deleteElementComponentAction,
    changePositionElementComponentAction,
} from "./types";

export const SELECT_ELEMENT_CONFIGURATION: string = "nss/analytics-report-configuration/SELECT_ELEMENT_CONFIGURATION";
export const CLOSE_ELEMENT_CONFIGURATION: string = "nss/analytics-report-configuration/CLOSE_ELEMENT_CONFIGURATION";
export const CHANGE_PAGE: string = "nss/analytics-report-configuration/CHANGE_PAGE";
export const CLEAR_STATE: string = "nss/analytics-report-configuration/CLEAR_STATE";
export const DROP_COMPONENT: string = "nss/analytics-report-configuration/DROP_COMPONENT";
export const DROP_COMPONENT_FULFILLED: string = "nss/analytics-report-configuration/DROP_COMPONENT_FULFILLED";
export const DROP_COMPONENT_REJECTED: string = "nss/analytics-report-configuration/DROP_COMPONENT_REJECTED";
export const DROP_COMPONENT_IN_PROGRESS: string = "nss/analytics-report-configuration/DROP_COMPONENT_IN_PROGRESS";

export const UPDATE_COMPONENT: string = "nss/analytics-report-configuration/UPDATE_COMPONENT";
export const UPDATE_COMPONENT_FULFILLED: string = "nss/analytics-report-configuration/UPDATE_COMPONENT_FULFILLED";
export const UPDATE_COMPONENT_REJECTED: string = "nss/analytics-report-configuration/UPDATE_COMPONENT_REJECTED";
export const UPDATE_COMPONENT_IN_PROGRESS: string = "nss/analytics-report-configuration/UPDATE_COMPONENT_IN_PROGRESS";

export const UPDATE_TEXT_COMPONENT: string = "nss/analytics-report-configuration/UPDATE_TEXT_COMPONENT";
export const UPDATE_TEXT_COMPONENT_FULFILLED: string =
    "nss/analytics-report-configuration/UPDATE_TEXT_COMPONENT_FULFILLED";
export const UPDATE_TEXT_COMPONENT_REJECTED: string =
    "nss/analytics-report-configuration/UPDATE_TEXT_COMPONENT_REJECTED";
export const UPDATE_TEXT_COMPONENT_IN_PROGRESS: string =
    "nss/analytics-report-configuration/UPDATE_TEXT_COMPONENT_IN_PROGRESS";

export const DELETE_COMPONENT: string = "nss/analytics-report-configuration/DELETE_COMPONENT";
export const DELETE_COMPONENT_FULFILLED: string = "nss/analytics-report-configuration/DELETE_COMPONENT_FULFILLED";
export const DELETE_COMPONENT_REJECTED: string = "nss/analytics-report-configuration/DELETE_COMPONENT_REJECTED";
export const DELETE_COMPONENT_IN_PROGRESS: string = "nss/analytics-report-configuration/DELETE_COMPONENT_IN_PROGRESS";

export const CHANGE_POSITION_COMPONENT: string = "nss/analytics-report-configuration/CHANGE_POSITION_COMPONENT";
export const CHANGE_POSITION_COMPONENT_FULFILLED: string =
    "nss/analytics-report-configuration/CHANGE_POSITION_COMPONENT_FULFILLED";
export const CHANGE_POSITION_COMPONENT_REJECTED: string =
    "nss/analytics-report-configuration/CHANGE_POSITION_COMPONENT_REJECTED";
export const CHANGE_POSITION_COMPONENT_IN_PROGRESS: string =
    "nss/analytics-report-configuration/CHANGE_POSITION_COMPONENT_IN_PROGRESS";

export const GET_PAGE_CONFIG: string = "nss/analytics-report-configuration/GET_PAGE_CONFIG";
export const GET_PAGE_CONFIG_FULFILLED: string = "nss/analytics-report-configuration/GET_PAGE_CONFIG_FULFILLED";
export const GET_PAGE_CONFIG_REJECTED: string = "nss/analytics-report-configuration/GET_PAGE_CONFIG_REJECTED";
export const GET_PAGE_CONFIG_IN_PROGRESS: string = "nss/analytics-report-configuration/GET_PAGE_CONFIG_IN_PROGRESS";

// Default functions
export const SET_COLOR_RENDER: string = "nss/analytics-report-configuration/SET_COLOR_RENDER";
export const GET_QUESTIONS_PROPS: string = "nss/analytics-report-configuration/GET_QUESTIONS_PROPS";
export const GET_INDICATORS_PROPS: string = "nss/analytics-report-configuration/GET_INDICATORS_PROPS";

const initialState: StateType = Map({
    page: "main_page",
    pageConfiguration: {},
    loadingConfiguration: false,
    colors: [],
    questions: [],
    indicators: [],
    saving: false,
});

export default createReducer(initialState, {
    [CLEAR_STATE]: (): StateType => initialState,
    [DROP_COMPONENT_IN_PROGRESS]: (state, action: DropComponentInProgressAction) =>
        state.withMutations(map => {
            map.set(action.payload.path, { ...state.get(action.payload.path), loading: true });
            map.set("saving", true);
        }),
    [DROP_COMPONENT_FULFILLED]: (state: StateType, action: DropComponentFulfillAction) => {
        const { payload: { data } } = action;
        const lastConf = state.get("pageConfiguration");
        const lastConfPath = _.get(lastConf, data.path);
        const newConfPath = [...lastConfPath, data.item];
        const newConf = _.set(lastConf, data.path, newConfPath);
        return state.withMutations(map => {
            map.set("pageConfiguration", newConf);
            map.set("saving", false);
        });
    },
    [GET_PAGE_CONFIG_FULFILLED]: (state: StateType, action) =>
        state.withMutations(map => {
            map.set("pageConfiguration", action.payload.data);
            map.set("loadingConfiguration", false);
        }),
    [GET_PAGE_CONFIG_IN_PROGRESS]: state => state.set("loadingConfiguration", true),
    [CHANGE_PAGE]: (state: StateType, action) => state.set("page", action.payload.page),
    [SET_COLOR_RENDER]: (state: StateType, action) => state.set("colors", _.get(action, "meta.colors", [])),
    [GET_QUESTIONS_PROPS]: (state: StateType, action) => state.set("questions", _.get(action, "payload.data", [])),
    [GET_INDICATORS_PROPS]: (state: StateType, action) => state.set("indicators", _.get(action, "payload.data", [])),
    [UPDATE_COMPONENT_IN_PROGRESS]: (state: StateType, action) => {
        const { payload: { path, update } } = action;
        const lastConf = state.get("pageConfiguration");
        const lastValuesPath = _.get(lastConf, path, {});
        const newConf = _.set(lastConf, path, { ...lastValuesPath, ...update });
        return state.withMutations(map => {
            map.set("pageConfiguration", newConf);
            map.set("saving", true);
        });
    },
    [UPDATE_COMPONENT_FULFILLED]: (state: StateType, action) => {
        const { payload: { path, item } } = action;
        const lastConf = state.get("pageConfiguration");
        const lastValuesPath = _.get(lastConf, path, {});
        const newConf = _.set(lastConf, path, { ...lastValuesPath, ...item });
        return state.withMutations(map => {
            map.set("pageConfiguration", newConf);
            map.set("saving", false);
        });
    },
    [UPDATE_TEXT_COMPONENT_IN_PROGRESS]: (state: StateType, action) => {
        const { payload: { path, update } } = action;
        const lastConf = state.get("pageConfiguration");
        const lastValuesPath = _.get(lastConf, path, {});
        const newConf = _.set(lastConf, path, { ...lastValuesPath, ...update });
        return state.withMutations(map => {
            map.set("pageConfiguration", newConf);
            map.set("saving", true);
        });
    },
    [UPDATE_TEXT_COMPONENT_FULFILLED]: (state: StateType, action) => {
        const { payload: { path, item } } = action;
        const lastConf = state.get("pageConfiguration");
        const lastValuesPath = _.get(lastConf, path, {});
        const newConf = _.set(lastConf, path, { ...lastValuesPath, ...item });
        return state.withMutations(map => {
            map.set("pageConfiguration", newConf);
            map.set("saving", false);
        });
    },
    [DELETE_COMPONENT_IN_PROGRESS]: (state: StateType, action) => {
        const { payload: { path, position } } = action;
        const lastConf = state.get("pageConfiguration");
        const lastValuesPath = _.chain(lastConf)
            .get(path, [])
            .filter((value, index) => !_.isEqual(index, position))
            .value();
        const newConf = _.set(lastConf, path, lastValuesPath);
        return state.withMutations(map => {
            map.set("pageConfiguration", newConf);
            map.set("saving", true);
        });
    },
    [DELETE_COMPONENT_FULFILLED]: (state: StateType) => state.set("saving", false),
    [CHANGE_POSITION_COMPONENT_IN_PROGRESS]: (state: StateType, act) => {
        const { payload: { position, action } } = act;
        const lastConf = state.get("pageConfiguration");
        let arrayRow = _.get(lastConf, "rows", []);
        const changePosition = _.isEqual(action, "UP") ? -1 : 1;
        const valuePosition = _.get(arrayRow, position);
        const valueNewPosition = _.get(arrayRow, position + changePosition);
        arrayRow = _.set(arrayRow, position, valueNewPosition);
        arrayRow = _.set(arrayRow, position + changePosition, valuePosition);
        const newConf = _.set(lastConf, "rows", arrayRow);
        return state.withMutations(map => {
            map.set("pageConfiguration", newConf);
            map.set("saving", true);
        });
    },
    [CHANGE_POSITION_COMPONENT_FULFILLED]: (state: StateType) => state.set("saving", false),
    [SELECT_ELEMENT_CONFIGURATION]: (state, action) =>
        state.withMutations(map => {
            map.set("currentElementConfiguration", action.payload);
            map.set("configurationOpen", true);
        }),

    [CLOSE_ELEMENT_CONFIGURATION]: state => state.set("configurationOpen", false),
});

export function cleanState(): ClearStateAction {
    return {
        type: CLEAR_STATE,
    };
}

export function dropElement(path: string, page: string, survey: string, props: any): DropComponentAction {
    return {
        type: DROP_COMPONENT,
        payload: {
            path,
            page,
            survey,
            props,
        },
    };
}

export function changePage(page: string) {
    return {
        type: CHANGE_PAGE,
        payload: {
            page,
        },
    };
}

export function setColorsRender(colors: any) {
    return {
        type: SET_COLOR_RENDER,
        meta: {
            colors,
        },
    };
}

export function questionsProps(survey: string) {
    const payload = axios.get(`/analytics/surveys/${survey}/config/props/questions`);
    return {
        type: GET_QUESTIONS_PROPS,
        payload,
    };
}

export function indicatorsProps(survey: string) {
    const payload = axios.get(`/analytics/surveys/${survey}/config/props/indicators`);
    return {
        type: GET_INDICATORS_PROPS,
        payload,
    };
}

export function getPageConfiguration(page: string, survey: string) {
    return {
        type: GET_PAGE_CONFIG,
        payload: {
            survey,
            page,
        },
    };
}

export function selectCurrentElementConfiguration(path: string, propElement: any) {
    return {
        type: SELECT_ELEMENT_CONFIGURATION,
        payload: {
            path,
            propElement,
        },
    };
}

export function closeConfiguration() {
    return {
        type: CLOSE_ELEMENT_CONFIGURATION,
    };
}

export function updateComponent(path: string, update: any, page: string, survey: string): UpdateComponentAction {
    return {
        type: UPDATE_COMPONENT,
        payload: {
            path,
            update,
            survey,
            page,
        },
    };
}

export function updateTextComponent(path: string, update: any, page: string, survey: string): UpdateComponentAction {
    return {
        type: UPDATE_TEXT_COMPONENT,
        payload: {
            path,
            update,
            survey,
            page,
        },
    };
}

export function updatePropsComponent(
    path: string,
    position: number,
    page: string,
    survey: string,
    action: string,
): deleteElementComponentAction {
    return {
        type: DELETE_COMPONENT,
        payload: {
            action,
            path,
            position,
            survey,
            page,
        },
    };
}

export function changePositionPropsComponent(
    position: number,
    page: string,
    survey: string,
    action: string,
): changePositionElementComponentAction {
    return {
        type: CHANGE_POSITION_COMPONENT,
        payload: {
            action,
            position,
            survey,
            page,
        },
    };
}

export const dropElementEpic$ = (action$: ActionsObservable): Epic =>
    action$.ofType(DROP_COMPONENT).mergeMap((action: DropComponentAction) => {
        const { payload: { path, page, survey, props } } = action;
        const promise = axios.post(`/analytics/surveys/${survey}/config`, { path, page, props });
        return Observable.fromPromise(promise)
            .map(response => response.data)
            .map(data => ({
                type: DROP_COMPONENT_FULFILLED,
                payload: {
                    data,
                },
            }))
            .catch(error => Observable.of({ type: DROP_COMPONENT_REJECTED, payload: { error } }))
            .startWith({ type: DROP_COMPONENT_IN_PROGRESS, payload: { path } });
    });

export const getPageConfigurationEpic$ = (action$: ActionsObservable): Epic =>
    action$.ofType(GET_PAGE_CONFIG).mergeMap((action: DropComponentAction) => {
        const { payload: { page, survey } } = action;
        const promise = axios.get(`/analytics/surveys/${survey}/config/${page}`);
        return Observable.fromPromise(promise)
            .map(response => response.data)
            .map(data => ({
                type: GET_PAGE_CONFIG_FULFILLED,
                payload: {
                    data,
                },
            }))
            .catch(error => Observable.of({ type: GET_PAGE_CONFIG_REJECTED, payload: { error } }))
            .startWith({ type: GET_PAGE_CONFIG_IN_PROGRESS, payload: { parent } });
    });

export const updateComponentEpic$ = (action$: ActionsObservable): Epic =>
    action$.ofType(UPDATE_COMPONENT).mergeMap((action: UpdateComponentAction) => {
        const { payload: { survey, ...otherProps } } = action;
        const promise = axios.put(`/analytics/surveys/${survey}/config`, { ...otherProps });
        return Observable.fromPromise(promise)
            .map(response => response.data)
            .map(data => ({
                type: UPDATE_COMPONENT_FULFILLED,
                payload: {
                    ...data,
                },
            }))
            .catch(error => Observable.of({ type: UPDATE_COMPONENT_REJECTED, payload: { error } }))
            .startWith({ type: UPDATE_COMPONENT_IN_PROGRESS, payload: { ...otherProps } });
    });

export const updateTextComponentEpic$ = (action$: ActionsObservable): Epic =>
    action$
        .ofType(UPDATE_TEXT_COMPONENT)
        .debounceTime(400)
        .mergeMap((action: UpdateComponentAction) => {
            const { payload: { survey, ...otherProps } } = action;
            const promise = axios.put(`/analytics/surveys/${survey}/config`, { ...otherProps });
            return Observable.fromPromise(promise)
                .map(response => response.data)
                .map(data => ({
                    type: UPDATE_TEXT_COMPONENT_FULFILLED,
                    payload: {
                        ...data,
                    },
                }))
                .catch(error => Observable.of({ type: UPDATE_TEXT_COMPONENT_REJECTED, payload: { error } }))
                .startWith({ type: UPDATE_TEXT_COMPONENT_IN_PROGRESS, payload: { ...otherProps } });
        });

export const updatePropsComponentEpic$ = (action$: ActionsObservable): Epic =>
    action$.ofType(DELETE_COMPONENT).mergeMap((action: deleteElementComponentAction) => {
        const { payload: { survey, ...otherProps } } = action;
        const promise = axios.put(`/analytics/surveys/${survey}/element/config`, { ...otherProps });
        return Observable.fromPromise(promise)
            .map(response => response.data)
            .map(data => ({
                type: DELETE_COMPONENT_FULFILLED,
                payload: {
                    ...data,
                },
            }))
            .catch(error => Observable.of({ type: DELETE_COMPONENT_REJECTED, payload: { error } }))
            .startWith({ type: DELETE_COMPONENT_IN_PROGRESS, payload: { ...otherProps } });
    });

export const changePositionPropsComponentEpic$ = (action$: ActionsObservable): Epic =>
    action$.ofType(CHANGE_POSITION_COMPONENT).mergeMap((action: changePositionElementComponentAction) => {
        const { payload: { survey, ...otherProps } } = action;
        const promise = axios.put(`/analytics/surveys/${survey}/element/config`, { ...otherProps });
        return Observable.fromPromise(promise)
            .map(response => response.data)
            .map(data => ({
                type: CHANGE_POSITION_COMPONENT_FULFILLED,
                payload: {
                    ...data,
                },
            }))
            .catch(error => Observable.of({ type: CHANGE_POSITION_COMPONENT_REJECTED, payload: { error } }))
            .startWith({ type: CHANGE_POSITION_COMPONENT_IN_PROGRESS, payload: { ...otherProps } });
    });
