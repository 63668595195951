import PropTypes from "prop-types";
import React, { Component } from "react";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import IconButton from "material-ui/IconButton";
import MoreVerticalIcon from "material-ui/svg-icons/navigation/more-vert";
import { browserHistory } from "react-router";

class ProjectCardActions extends Component {
    constructor(props) {
        super(props);
        this._handleProject = this._handleProject.bind(this);
    }

    _handleProject() {
        const { companyId, projectId } = this.props;
        browserHistory.push(
            `/admin/projects/info/ref/${projectId}/${companyId}`
        );
    }

    render() {
        return (
            <IconMenu
                iconButtonElement={
                    <IconButton>
                        <MoreVerticalIcon />
                    </IconButton>
                }
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
                targetOrigin={{ horizontal: "right", vertical: "top" }}
                style={{ marginRight: 0, position: "relative", float: "right" }}
            >
                <MenuItem
                    primaryText="Configuración del proyecto"
                    onClick={this._handleProject}
                />
            </IconMenu>
        );
    }
}

ProjectCardActions.PropTypes = {
    companyId: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
};

export default ProjectCardActions;
