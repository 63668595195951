import PropTypes from "prop-types";
import React, { Component } from "react";
import { reduxForm, addArrayValue } from "redux-form";
import { Row, Col } from "react-flexbox-grid";
import { bindActionCreators } from "redux";
import { updateFilterProject } from "../../../actions/projects_form";
import Paper from "material-ui/Paper";
import PureInput from "../../PureInput";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import FlatButton from "material-ui/FlatButton";
import IconButton from "material-ui/IconButton";
import { browserHistory } from "react-router";
import AuthComponent from "../../AuthComponent";
import UpIcon from "material-ui/svg-icons/hardware/keyboard-arrow-up";
import DownIcon from "material-ui/svg-icons/hardware/keyboard-arrow-down";
import DeleteIcon from "material-ui/svg-icons/action/delete";
import _ from "lodash";
import Divider from "material-ui/Divider";
import { toggleSnackbar } from "../../../actions/commons";
import CircularProgress from "material-ui/CircularProgress";
import {
    changeStepIndex,
    saveStateNameCombinations,
    saveCurrentFilter,
} from "./ducks";
import {
    updateFilters,
    cleanSelectedFilters,
    cleanFilters,
} from "../../analyticsReport/ducks";
import { parseSolrFiltersToSarinFilters } from "../../analyticsReport/item/functions";

const style = {
        margin: 20,
        float: "right",
    },
    stylePaper = {
        marginTop: 20,
        marginBottom: 20,
    };

class renameFilterReportComponent extends Component {
    static propTypes = {
        updateFilters: PropTypes.func.isRequired,
    };

    handlePrev = () => {
        const {
            changeStepIndex,
            saveStateNameCombinations,
            stepIndex,
            values,
        } = this.props;
        saveStateNameCombinations(_.get(values, "filters", []));
        changeStepIndex(stepIndex - 1);
    };

    _saveFilter = (formData) => {
        const {
            isSaveFilter,
            updateFilterProject,
            toggleSnackbar,
            projectId,
            companyId,
            profileId,
        } = this.props;
        const {
            updateFilters,
            cleanSelectedFilters,
            cleanFilters,
            saveCurrentFilter,
        } = this.props;
        const isSave =
            !_.isUndefined(isSaveFilter) && !_.isNull(isSaveFilter)
                ? isSaveFilter
                : false;

        const filters = _.chain(formData)
            .get("filters", [])
            .map((filter) => {
                if (_.isEqual(_.get(filter, "name", ""), "")) {
                    return _.set(filter, "name", _.get(filter, "title"));
                }
                return filter;
            })
            .map((filter) => _.omit(filter, ["title"]))
            .value();

        if (isSave) {
            updateFilterProject(filters, projectId)
                .then(
                    (data) => {
                        if (
                            _.get(data, "error") ||
                            _.get(data, "payload.status") !== 200
                        ) {
                            toggleSnackbar(
                                true,
                                "Hubo un error al actualizar el filto"
                            );
                        } else {
                            toggleSnackbar(
                                true,
                                "Se guardó correctamente el filto para el proyecto"
                            );
                            browserHistory.push(
                                `/admin/projects/info/${profileId}/${projectId}/${companyId}`
                            );
                        }
                    },
                    (reason) => {
                        toggleSnackbar(
                            true,
                            "Hubo un error al actualizar el filto"
                        );
                    }
                )
                .catch((data) => {
                    toggleSnackbar(
                        true,
                        "Hubo un error al actualizar el filto"
                    );
                });
        } else {
            cleanFilters();
            cleanSelectedFilters();
            if (_.size(filters) > 0) {
                saveCurrentFilter(filters);
                updateFilters(
                    parseSolrFiltersToSarinFilters(filters, projectId)
                );
            } else {
                saveCurrentFilter([{ name: "Todos", combine: [] }]);
                updateFilters(
                    parseSolrFiltersToSarinFilters(
                        [{ name: "Todos", combine: [] }],
                        projectId
                    )
                );
            }
        }
    };

    renderCombines(combine, index) {
        return (
            <ul>
                {_.chain(combine)
                    .map((c) => ({
                        parentTitle: _.get(c, "value.parentTitle", ""),
                        title: _.get(c, "value.title", ""),
                        parentTitleOrder: _.deburr(
                            _.get(c, "value.parentTitle", "")
                        ),
                        titleOrder: _.deburr(_.get(c, "value.title", "")),
                    }))
                    .orderBy(["parentTitleOrder", "titleOrder"], ["asc", "asc"])
                    .map((c, i) => (
                        <li key={`rename-${index}-combine-${i}`}>{`${_.get(
                            c,
                            "parentTitle",
                            ""
                        )}: ${_.get(c, "title", "")}`}</li>
                    ))
                    .value()}
            </ul>
        );
    }

    render() {
        const {
            fields: { filters },
            handleSubmit,
            stepIndex,
            isSaveFilter,
        } = this.props;
        const isSave =
            !_.isUndefined(isSaveFilter) && !_.isNull(isSaveFilter)
                ? isSaveFilter
                : false;

        return (
            <Row>
                <Col xs>
                    <form onSubmit={handleSubmit(this._saveFilter)}>
                        <Row>
                            <Col xs style={{ padding: "0px 30px 20px 30px" }}>
                                <FlatButton
                                    label="Volver al filtro de selección"
                                    disabled={false}
                                    onClick={this.handlePrev}
                                    style={{ margin: 10, float: "left" }}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 50 }}>
                            <Col xs>
                                {!filters.length && (
                                    <h3
                                        style={{
                                            marginBottom: 30,
                                            marginTop: 30,
                                            textAlign: "center",
                                        }}
                                    >{`No hay filtros seleccionados`}</h3>
                                )}
                                {_.map(filters, (option, index) => (
                                    <Row key={`rename-${index}`}>
                                        <Col xs={12}>
                                            <Row>
                                                <Col xs={6}>
                                                    <h3
                                                        style={{
                                                            fontWeight: 600,
                                                            padding:
                                                                "0px 0px 8px 0px",
                                                            margin:
                                                                "15px 0 0 0",
                                                            borderBottom:
                                                                "1px dashed #CC",
                                                        }}
                                                    >
                                                        {option.title.value}
                                                    </h3>
                                                    {this.renderCombines(
                                                        option.combine,
                                                        index
                                                    )}
                                                </Col>
                                                <Col xs={6}>
                                                    <PureInput
                                                        floatingLabelText={
                                                            "Nombre"
                                                        }
                                                        hintText={""}
                                                        type="text"
                                                        field={option.name}
                                                        multiLine={true}
                                                        fullWidth={true}
                                                        rows={1}
                                                    />
                                                </Col>
                                            </Row>
                                            <div style={{ marginTop: 15 }} />
                                        </Col>
                                    </Row>
                                ))}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs style={{ padding: "0px 30px 20px 30px" }}>
                                {isSave ? (
                                    <AuthComponent
                                        component={
                                            <RaisedButton
                                                type="submit"
                                                label={"Guardar filtro"}
                                                style={{
                                                    margin: 10,
                                                    float: "right",
                                                }}
                                                secondary={true}
                                            />
                                        }
                                        permission={
                                            "cgr_project_config_save_filter_default"
                                        }
                                        type={"component"}
                                    />
                                ) : (
                                    <RaisedButton
                                        type="submit"
                                        label={"Filtrar"}
                                        style={{ margin: 10, float: "right" }}
                                        secondary={true}
                                    />
                                )}
                            </Col>
                        </Row>
                    </form>
                </Col>
            </Row>
        );
    }
}

function mapStateToProps({ filterReport }, ownProps) {
    const combinations = filterReport.get("combinations");
    return {
        stepIndex: filterReport.get("stepIndex"),
        initialValues: {
            filters: combinations,
        },
    };
}

renameFilterReportComponent.propTypes = {
    projectId: PropTypes.string.isRequired,
    companyId: PropTypes.string.isRequired,
    profileId: PropTypes.string.isRequired,
    surveyId: PropTypes.string.isRequired,
    saveCurrentFilter: PropTypes.func.isRequired,
    isSaveFilter: PropTypes.bool,
};

export default reduxForm(
    {
        form: "RenameFilterReportForm",
        fields: ["filters[].title", "filters[].name", "filters[].combine[]"],
    },
    mapStateToProps,
    {
        changeStepIndex,
        saveStateNameCombinations,
        toggleSnackbar,
        updateFilters,
        cleanFilters,
        cleanSelectedFilters,
        updateFilterProject,
        saveCurrentFilter,
    }
)(renameFilterReportComponent);
