import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Row, Col} from 'react-flexbox-grid';
import FlatButton from 'material-ui/FlatButton';
import UploadIcon from 'material-ui/svg-icons/file/file-upload';
import FolderIcon from 'material-ui/svg-icons/file/folder';
import { toggleSnackbar } from '../../../actions/commons';
import { uploadFile } from './actions';
import { reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import CircularProgress from 'material-ui/CircularProgress';
import TextField from 'material-ui/TextField';
import is from 'is_js';

const inputFile = {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    opacity: 0
};

class UploadFile extends Component {

    constructor(props) {
        super(props);
        this.onChangeSelectFile = this.onChangeSelectFile.bind(this);
        this.renderNameFile = this.renderNameFile.bind(this);
        this._uploadFile = this._uploadFile.bind(this);
        this._onChangeEmail = this._onChangeEmail.bind(this);
        this.state = {
            file: "",
            fileReader: null,
            waiting: false,
            email: "",
            emailError: ""
        };
    }

    onChangeSelectFile(event) {
        this.setState({
            file: event.target.value,
            fileReader: event.target.files[0]
        });
    }

    renderNameFile(){
        const {
            file
        } = this.state;
        let nameFile = _.split(file, '\\');
        if(nameFile instanceof Array && _.size(nameFile) > 0 && !_.isEqual(file, '')){
            return (
                <h3 style={{fontWeight: 400, margin: '7px 0 0 0', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', padding: '2px 10px'}}>
                    {nameFile[_.size(nameFile) - 1]}
                </h3>
            );
        } else {
            return (
                <h3 style={{fontWeight: 400, margin: '7px 0 0 0', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', padding: '2px 10px'}}>
                    {"Ningún archivo seleccionado"}
                </h3>
            );
        }
    }

    _uploadFile(formData) {
        let fileFormData = new FormData();
        fileFormData.append('file', this.state.fileReader);
        const {
            storeName,
            id,
            uploadFile,
            toggleSnackbar,
            updateFunction
        } = this.props;
        this.setState({waiting: true});
        uploadFile(fileFormData, storeName, id, this.state.email)
            .then((data) => {
                console.log('payload', data.payload);
                if (_.get(data, 'error') || (_.get(data, 'payload.status') !== 200)) {
                    toggleSnackbar(true, "Ha ocurrido un error en el servidor");
                } else {
                    toggleSnackbar(true, _.get(data, 'payload.data.text'));
                }
                this.setState({waiting: false});
            }, (reason) => {
                this.setState({waiting: false});
                toggleSnackbar(true, "Ha ocurrido un error en el servidor");
            })
            .catch((data) => {
                this.setState({waiting: false});
                toggleSnackbar(true, "Ha ocurrido un error en el servidor");
            });
    }

    _onChangeEmail(text){
        if(!_.isEqual(text, "") && !_.isNull(text, "") && !_.isUndefined(text, "")){
            if (!is.email(text)) {
                this.setState({
                    email: text,
                    emailError: "El correo electrónico no es válido"
                });
            } else {
                this.setState({
                    email: text,
                    emailError: ""
                });
            }
        } else {
            this.setState({
                email: text,
                emailError: "El correo electrónico no debe estar vació"
            });
        }
    }

    render() {
        const {
            fields: {
                file
            },
            isXLSX,
            handleSubmit
        } = this.props;
        const tp = _.isUndefined(isXLSX) || _.isNull(isXLSX)
                  ? "application/ms-excel,application/vnd.ms-excel"
                  : !isXLSX
                        ? "application/ms-excel,application/vnd.ms-excel"
                        : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        return (
            <div style={{
                width: 290,
                height: 215,
                margin: '10px auto 0 auto',
                padding: '20px 5px',
                background: '#F8F8FC',
                borderRadius: 13}}
            >
                {this.state.waiting
                ? <CircularProgress size={115} thickness={6} style={{marginTop: 50}} />
                : <form name="formUploadCompanies" encType="multipart/form-data" onSubmit={handleSubmit(this._uploadFile)}>
                    <Row>
                        <Col xs>
                            <FlatButton
                                label="Seleccionar archivo"
                                labelPosition="before"
                                secondary={true}
                                style={{width: 230}}
                                icon={<FolderIcon />}
                            >
                                <input
                                    type="file"
                                    style={inputFile}
                                    onChange={this.onChangeSelectFile}
                                    name="file"
                                    accept={tp}
                                />
                            </FlatButton>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs>
                            {this.renderNameFile()}
                            <TextField
                                style={{marginTop: 10}}
                                hintText="Correo electrónico"
                                floatingLabelText="Correo electrónico"
                                errorText={this.state.emailError}
                                onChange={(e, text) => this._onChangeEmail(text) }
                                value={this.state.email}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs>
                            <FlatButton
                                label="Subir archivo"
                                labelPosition="before"
                                type="submit"
                                secondary={true}
                                disabled={_.isEqual(this.state.file, '') || !_.isEqual(this.state.emailError, "") || _.isEqual(this.state.email, "")}
                                style={{width: 190}}
                                icon={<UploadIcon />}
                            />
                        </Col>
                    </Row>
                </form>
                }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        toggleSnackbar,
        uploadFile
    }, dispatch);
}

UploadFile.propTypes = {
    storeName: PropTypes.string.isRequired,
    id: PropTypes.string,
    updateFunction: PropTypes.func,
    isXLSX: PropTypes.bool
};

export default reduxForm({
    form: 'UploadFileForm',
    fields: [
        'file'
    ]
}, null, mapDispatchToProps)(UploadFile);
