import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { bindActionCreators } from "redux";
import MenuItem from "material-ui/MenuItem";
import { blue600 } from "material-ui/styles/colors";
import IconButton from "material-ui/IconButton";
import DeleteIcon from "material-ui/svg-icons/action/delete";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import { connect } from "react-redux";
import SelectFieldFormat from "../../../fields/SelectFieldFormat/SelectFieldFormat";
import { updateComponent } from "../../ducks";
import WaitComponent from "../waitComponent";

const styles = {
    title: {
        fontWeight: 400,
        padding: "12px 10px",
        margin: "10px auto",
        textAlign: "center",
        minWidth: 280,
        maxWidth: 350,
        borderRadius: 8,
        color: "#FFF",
        backgroundColor: blue600,
        boxSizing: "border-box",
    },
    titlenoSelected: {
        fontWeight: 400,
        padding: "12px 10px",
        margin: "10px auto",
        textAlign: "center",
        minWidth: 280,
        maxWidth: 350,
        borderRadius: 8,
        backgroundColor: "#DEDEDE",
        boxSizing: "border-box",
    },
    radioButton: {
        display: "inline-block",
        width: 120,
    },
    contentType: {
        padding: 8,
        boxSizing: "border-box",
    },
    boxList: {
        border: "1px solid #CCC",
        width: "100%",
        boxSizing: "border-box",
    },
    boxItem: {
        width: "100%",
        boxSizing: "border-box",
    },
};

class IndicatorComponent extends Component {
    static propTypes = {
        survey: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        indicators: PropTypes.arrayOf(PropTypes.object).isRequired,
        selected: PropTypes.arrayOf(PropTypes.object).isRequired,
        loadingConfiguration: PropTypes.bool.isRequired,
        selectType: PropTypes.string,
    };

    state = {
        type: "summatory",
    };

    _onHandleAddValue = (e, index, value) => {
        const { updateComponent, survey, path, page, selected } = this.props;
        let selectItem = selected;
        if (_.isEqual("summatory", _.get(value, "typeEvaluation"))) {
            selectItem.push(value);
        } else {
            selectItem = [value];
        }
        updateComponent(
            path,
            { items: _.map(selectItem, (item) => _.get(item, "_id")) },
            page,
            survey
        );
    };

    _onHandleDeleteValue = (value) => {
        const { updateComponent, survey, path, page, selected } = this.props;
        const selectItem = _.filter(
            selected,
            (item) => !_.isEqual(_.get(item, "_id"), value)
        );
        updateComponent(
            path,
            { items: _.map(selectItem, (item) => _.get(item, "_id")) },
            page,
            survey
        );
    };

    onChangeTypeIndicator = (e, value) => {
        const { updateComponent, survey, path, page } = this.props;
        this.setState({ type: value });
        updateComponent(path, { items: [] }, page, survey);
    };

    renderContentItem = (primaryText, secondaryText) => (
        <div>
            {primaryText}
            <span style={{ marginLeft: 4, color: "#B3B3B3" }}>
                {secondaryText}
            </span>
        </div>
    );

    renderData = () => {
        const { type } = this.state;
        const { selected, selectType } = this.props;
        const valueType = _.isNull(selectType) ? type : selectType;
        if (_.isEqual(valueType, "summatory") && _.size(selected) > 0) {
            return (
                <div style={{ ...styles.boxList }}>
                    <table
                        style={{
                            borderCollapse: "collapse",
                            padding: 0,
                            width: "100%",
                        }}
                    >
                        <tbody>
                            {_.map(selected, (item, index) => (
                                <tr
                                    key={`indicator-config-item-selector-${index}`}
                                    style={{
                                        borderTop:
                                            index > 0 ? "1px solid #CCC" : "0",
                                    }}
                                >
                                    <td style={{ padding: 5 }}>
                                        <div>
                                            {_.get(item, "name")}
                                            <span
                                                style={{
                                                    marginLeft: 4,
                                                    color: "#B3B3B3",
                                                }}
                                            >
                                                {`Nivel ${_.get(
                                                    item,
                                                    "level",
                                                    1
                                                )}`}
                                            </span>
                                        </div>
                                    </td>
                                    <td
                                        style={{
                                            padding: 0,
                                            width: 50,
                                            verticalAlign: "top",
                                        }}
                                    >
                                        <IconButton
                                            tooltip={"Borrar"}
                                            tooltipPosition={"top-center"}
                                            onClick={this._onHandleDeleteValue.bind(
                                                this,
                                                _.get(item, "_id")
                                            )}
                                        >
                                            <DeleteIcon color={"#000"} />
                                        </IconButton>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            );
        }
        const valueFrecuency = selected[0];
        if (
            _.isEqual(valueType, "frecuency") &&
            !_.isNull(valueFrecuency) &&
            !_.isUndefined(valueFrecuency)
        ) {
            return (
                <div style={{ ...styles.boxItem }}>
                    <h3
                        style={{
                            fontWeight: 400,
                            padding: 7,
                            margin: 0,
                            fontSize: 13,
                        }}
                    >
                        {_.get(valueFrecuency, "name")}
                    </h3>
                </div>
            );
        }
        return (
            <h3 style={{ ...styles.titlenoSelected }}>
                No se tiene ningún valor seleccionado
            </h3>
        );
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { selectType } = nextProps;
        const { type } = this.state;
        if (
            !_.isEqual(type, selectType) &&
            !_.isNull(selectType) &&
            !_.isUndefined(selectType)
        ) {
            this.setState({ type: selectType });
        }
    }

    render() {
        const {
            indicators,
            selected,
            loadingConfiguration,
            selectType,
        } = this.props;
        const { type } = this.state;
        const valueType = _.isNull(selectType) ? type : selectType;
        if (loadingConfiguration) {
            return <WaitComponent />;
        }
        if (_.size(indicators) === 0) {
            return (
                <h3 style={{ ...styles.title }}>
                    No tiene agrupadores configurados
                </h3>
            );
        }
        return (
            <div>
                <div style={{ ...styles.contentType }}>
                    <RadioButtonGroup
                        name="typeIndicator"
                        defaultSelected={valueType}
                        onChange={this.onChangeTypeIndicator}
                    >
                        <RadioButton
                            value="summatory"
                            label="Sumatoria"
                            style={{ ...styles.radioButton }}
                        />
                        <RadioButton
                            value="frecuency"
                            label="Frecuencia"
                            style={{ ...styles.radioButton }}
                        />
                    </RadioButtonGroup>
                </div>
                <SelectFieldFormat
                    config={{
                        floatingLabelText: "Seleccione el indicador",
                        value: null,
                        autoWidth: true,
                        onChange: this._onHandleAddValue,
                    }}
                >
                    {_.chain(indicators)
                        .filter((indicator) =>
                            _.isEqual(_.get(indicator, "typeEvaluation"), type)
                        )
                        .filter(
                            (indicator) =>
                                _.findIndex(selected, {
                                    _id: _.get(indicator, "_id"),
                                }) === -1
                        )
                        .map((indicator, index) => (
                            <MenuItem
                                key={`indicator-config-${index}`}
                                value={indicator}
                                primaryText={this.renderContentItem(
                                    _.get(indicator, "name"),
                                    _.isEqual(
                                        _.get(indicator, "typeEvaluation"),
                                        "summatory"
                                    )
                                        ? `Nivel ${_.get(
                                              indicator,
                                              "level",
                                              1
                                          )}`
                                        : ""
                                )}
                            />
                        ))
                        .value()}
                </SelectFieldFormat>
                {this.renderData()}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateComponent }, dispatch);
}

function mapStateToProps({ analyticsReportsConfiguration }, ownProps) {
    const config = analyticsReportsConfiguration.get("pageConfiguration");
    const loadingConfiguration = analyticsReportsConfiguration.get(
        "loadingConfiguration"
    );
    const selectedIndicator = _.get(
        config,
        `${_.get(ownProps, "path")}.items`,
        []
    );
    const indicators = analyticsReportsConfiguration.get("indicators");
    const selected = _.map(
        selectedIndicator,
        (ind) => indicators[_.findIndex(indicators, { _id: ind })]
    );
    let selectType = "summatory";
    if (_.size(selected) === 0) {
        selectType = null;
    }
    if (_.size(selected) > 0) {
        selectType = _.get(selected, "0.typeEvaluation", "summatory");
    }
    return {
        page: analyticsReportsConfiguration.get("page"),
        indicators,
        selected,
        loadingConfiguration,
        selectType,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorComponent);
