import React, { Component } from "react";
import PropTypes from "prop-types";
import { Editor, EditorState, RichUtils, convertFromHTML, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import _ from "lodash";
import ControlEditor from "./editor/controlEditor";

class EditorRichText extends Component {
    state = {
        editorState: EditorState.createEmpty(),
        colorMap: {},
        colorBackgroundMap: {},
        fotmatTextMap: {
            STRIKETHROUGH: { textDecoration: "line-through" },
            UNDERLINE: { textDecoration: "underline" },
            BOLD: { fontWeight: 600 },
            ITALIC: { fontStyle: "italic" },
            TITLEH1: { fontSize: "2em" },
            TITLEH2: { fontSize: "1.5em" },
            TITLEH3: { fontSize: "1.18em" },
            TITLEH4: { fontSize: "1.00em" },
            TITLEH5: { fontSize: ".83em" },
            TITLEH6: { fontSize: ".67em" },
        },
    };

    static propTypes = {
        heightText: PropTypes.number.isRequired,
        initValue: PropTypes.string,
        onChangeUpdate: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { initValue } = this.props;
        if (hasValue(initValue) && !_.isEqual(initValue, "")) {
            const blocksFromHTML = convertFromHTML(initValue);
            const stateBlockHTML = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );
            this.setState({ editorState: EditorState.createWithContent(stateBlockHTML) });
        }
    }

    _handleKeyCommand = command => {
        const { editorState } = this.state;
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onChange(newState);
            return true;
        }
        return false;
    };

    _onChangeColor = (key, color) => {
        const { colorMap } = this.state;
        this.setState({ colorMap: _.set(colorMap, key, { color }) });
    };

    _onChangeBackgroundColor = (key, color) => {
        const { colorMap } = this.state;
        this.setState({ colorBackgroundMap: _.set(colorMap, key, { backgroundColor: color }) });
    };

    _onChange = editorState => {
        const { onChangeUpdate } = this.props;
        const { fotmatTextMap, colorMap, colorBackgroundMap } = this.state;
        const customMap = _.assign(_.assign(colorBackgroundMap, colorMap), fotmatTextMap);
        const keys = _.keys(customMap);
        const map = _.reduce(keys, (sumMap, key) => _.set(sumMap, key, { style: _.get(customMap, key, {}) }), {});
        const options = { inlineStyles: map };
        const html = stateToHTML(editorState.getCurrentContent(), options);
        this.setState({ editorState });
        onChangeUpdate(html);
    };

    _toggleList = blockType => {
        const { editorState } = this.state;
        this._onChange(RichUtils.toggleBlockType(editorState, blockType));
    };

    render() {
        const { heightText } = this.props;
        const height = !hasValue(heightText) ? 120 : heightText;
        const { editorState, colorMap, colorBackgroundMap, fotmatTextMap } = this.state;
        const customMap = _.assign(_.assign(colorBackgroundMap, colorMap), fotmatTextMap);
        return (
            <div style={{ border: "0px solid #CCC", width: "100%" }}>
                <ControlEditor
                    editorState={editorState}
                    onChange={this._onChange}
                    onChangeColor={this._onChangeColor}
                    onChangeBackgroundColor={this._onChangeBackgroundColor}
                    onToggleList={this._toggleList}
                    colorStyleMap={customMap}
                />
                <div style={{ borderTop: "1px solid #CCC", height, overflowY: "auto", padding: 7 }}>
                    <Editor
                        customStyleMap={customMap}
                        editorState={editorState}
                        onChange={this._onChange}
                        handleKeyCommand={this.handleKeyCommand}
                        placeholder={"Ingrese texto..."}
                    />
                </div>
            </div>
        );
    }
}

function hasValue(value) {
    return !_.isNull(value) && !_.isUndefined(value);
}

export default EditorRichText;
