import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, addArrayValue } from 'redux-form';
import { Row, Col } from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';
import PureInput from '../components/PureInput';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import { createListMaster } from '../actions/listMaster_form';
import { FetchListMasterAndListValuesParam } from '../actions/grid/listmaster_grid_form';
import FlatButton from 'material-ui/FlatButton';
import IconButton from 'material-ui/IconButton';
import UpIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-up';
import DownIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import _ from 'lodash';
import { axiosAPI } from '../middleware/api';
import { browserHistory } from 'react-router';
import {LBLLISTS} from '../constants/labels';
import {toggleSnackbar} from '../actions/commons';
import ButtonBack from '../components/fields/buttonBack/buttonBack';
import AuthComponent from '../components/AuthComponent';
import CircularProgress from 'material-ui/CircularProgress';

const style = {
        margin: 20,
        float: 'right'
    },
    stylePaper = {
        marginTop: 20,
        marginBottom: 20
    };

class ListMasterNews extends Component {

    constructor(props) {
        super(props);

        this._saveListMaster = this._saveListMaster.bind(this);
    }

    UNSAFE_componentWillMount() {
        if (_.has(this, 'props.params._id')) {
            this.props.FetchListMasterAndListValuesParam(this.props.params._id);
        }
    }

    _saveListMaster(formData) {
        const self = this;
        self
            .props
            .createListMaster(_.omit(formData, '_id'))
            .then((data) => {
                if(_.get(data, 'error') || (_.get(data, 'payload.status') !== 200)){
                    self.props.toggleSnackbar(true, LBLLISTS.msg.errorSave);
                } else {
                    self.props.resetForm();
                    self.props.toggleSnackbar(true, LBLLISTS.msg.successSave);
                    browserHistory.push(`/admin/lists/grid`);
                }
            }, (reason) => {
                self.props.toggleSnackbar(true, LBLLISTS.msg.errorSave);
            })
            .catch((data) => {
                self.props.toggleSnackbar(true, LBLLISTS.msg.errorSave);
            });
    }

    render() {
        const {
            asyncValidating,
            fields: {
                description,
                alias,
                options
            },
            handleSubmit,
            resetForm,
            params
        } = this.props;
        const editMode = _.has(params, '_id');
        return (
          <Row>
              <AuthComponent
                  component={
                      <Col xs={12} mdOffset={2} md={8}>
                          <Paper style={stylePaper}>
                              <Row>
                                  <Col xsOffset={1} xs={10}>
                                      <h1 style={{textAlign: 'center', fontWeight: 400}}>{editMode ? LBLLISTS.titleDuplicateList : LBLLISTS.titleCreate} </h1>
                                      <ButtonBack url={`/admin/lists/grid`}/>
                                      <form onSubmit={handleSubmit(this._saveListMaster)}>
                                          <Row>
                                              <Col xs>
                                                  <TextField
                                                      hintText=""
                                                      floatingLabelText={LBLLISTS.name}
                                                      type="text"
                                                      multiLine={true}
                                                      fullWidth={true}
                                                      rows={1}
                                                      errorText={alias.touched && alias.error ? alias.error : '' }
                                                      {...alias}
                                                  />
                                                  {asyncValidating === 'alias' && <CircularProgress size={25} thickness={2} style={{ position: 'absolute', float: 'right', marginTop: 35 }}/>}
                                              </Col>
                                          </Row>
                                          <Row>
                                              <Col xs>
                                                  <TextField
                                                      hintText=""
                                                      floatingLabelText={LBLLISTS.description}
                                                      multiLine={true}
                                                      fullWidth={true}
                                                      rows={2}
                                                      errorText={description.touched && description.error ? description.error : '' }
                                                      {...description}
                                                  />
                                              </Col>
                                          </Row>
                                          <Row style={{marginTop: 50}}>
                                              <Col xsOffset={1} xs={10}>
                                                  {!options.length && <h3 style={{marginTop: 30, textAlign: 'center'}}>{LBLLISTS.noValues}</h3>}
                                                  {(options.length >= 0 && options.length < 2) && <p style={{marginTop: 30, textAlign: 'center'}}>{LBLLISTS.help}</p>}
                                                  {options.map((option, index) =>
                                                  <Row key={index}>
                                                      <Col xs={7}>
                                                          <PureInput
                                                              floatingLabelText={LBLLISTS.nameList}
                                                              type="text"
                                                              errorText={option.value.touched && option.value.error ? option.value.error : '' }
                                                              field={option.value}
                                                              multiLine={true}
                                                              fullWidth={true}
                                                              rows={1}
                                                          />
                                                      </Col>
                                                      <Col xs={5}>
                                                          <div>
                                                              <IconButton
                                                                  children={<UpIcon/>}
                                                                  disabled={index === 0}
                                                                  tooltip={LBLLISTS.tooltips.btnUp}
                                                                  tooltipPosition="top-center"
                                                                  onClick={() => { options.swapFields(index, index - 1); }}
                                                              />
                                                              <IconButton
                                                                  children={<DownIcon/>}
                                                                  disabled={index === options.length - 1}
                                                                  tooltip={LBLLISTS.tooltips.btnDown}
                                                                  tooltipPosition="top-center"
                                                                  onClick={() => { options.swapFields(index, index + 1); }}
                                                              />
                                                              <IconButton
                                                                  children={<DeleteIcon/>}
                                                                  tooltip={LBLLISTS.tooltips.btnDelete}
                                                                  tooltipPosition="top-center"
                                                                  onClick={() => { options.removeField(index); }}
                                                              />
                                                          </div>
                                                      </Col>
                                                  </Row>)}
                                              </Col>
                                          </Row>
                                          <Row>
                                              <Col xsOffset={1} xs={10}>
                                                  <div>
                                                      <FlatButton
                                                          label={`${LBLLISTS.buttons.addOption} ${options.length + 1}`}
                                                          secondary={true}
                                                          style={style}
                                                          onClick={() => { options.addField(); }}
                                                      />
                                                  </div>
                                              </Col>
                                          </Row>
                                          <AuthComponent
                                              component={<RaisedButton
                                                            type="submit"
                                                            label={editMode ? LBLLISTS.buttons.saveDuplicate : LBLLISTS.buttons.save}
                                                            disabled={(options.length >= 0 && options.length < 2)}
                                                            secondary={true}
                                                            style={style}
                                                        />}
                                              permission={editMode ? "cgr_answer_options_create" : "cgr_answer_options_duplicate"}
                                              type={"url"}
                                          />
                                      </form>
                                  </Col>
                              </Row>
                          </Paper>
                      </Col>
                  }
                  permission={editMode ? "cgr_answer_options_create" : "cgr_answer_options_duplicate"}
                  type={"url"}
               />
          </Row>
        );
    }
}

const requireFields = (...names) =>
    data =>
        names.reduce((errors, index) => {
            if (!data[index]) {
                errors[index] = LBLLISTS.validations.isRequired;
            }
            return errors;
        }, {});

const validateOptions = requireFields('value');

const validate = (values) => {
    const errors = {};
    if (!values.alias) {
        errors.alias = LBLLISTS.validations.name;
    }
    if (!values.description) {
        errors.description = LBLLISTS.validations.description;
    }
    errors.options = values.options.map(validateOptions);

    return errors;
};

const asyncValidate = (values) => {
    return new Promise((resolve, reject) => {
        axiosAPI.get(`/listmaster/id`, {
            params: { alias: values.alias }
        })
        .then((data) => {
            let resp = _.get(data.data, 'data');
            if(!_.isNull(resp)){
                if(_.isUndefined(values.alias) || _.isEqual(values.alias, '')){
                    reject({alias: LBLLISTS.validations.name});
                } else {
                    reject({alias: LBLLISTS.validations.existName});
                }
            } else {
                if(_.isUndefined(values.alias) || _.isEqual(values.alias, '')){
                    reject({alias: LBLLISTS.validations.name});
                } else {
                    resolve();
                }
            }
        })
        .catch((data) => {
            console.log('Error', data);
        });
    });
};

function mapStateToProps({listmaster}, ownProps) {
    if (_.has(ownProps, 'params._id')) {
        if(_.get(listmaster, 'all.data') && _.get(listmaster, 'all.options')){
            return  {
                initialValues: {
                    description: _.get(listmaster, 'all.data.description', ''),
                    alias: '',
                    options: _.get(listmaster, 'all.options'),
                }
            };
        }
    } else {
        return  {
            initialValues: {
                description: '',
                alias: '',
                options: [],
            }
        };
    }
}

ListMasterNews.propTypes = {
    fields: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    router: PropTypes.object
};

export default reduxForm({
    form: 'ListMasterNewForm',
    fields: [
        'description',
        'alias',
        'options[].value'
    ],
    asyncValidate,
    asyncBlurFields: ['alias'],
    validate
}, mapStateToProps, {createListMaster, toggleSnackbar, FetchListMasterAndListValuesParam})(ListMasterNews);
