import PropTypes from 'prop-types';
import React, { Component } from 'react';
import RichTextEditor from 'react-rte';
import { red500 } from 'material-ui/styles/colors';
import _ from 'lodash';

class RichText extends Component {

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.state = {
            value: RichTextEditor.createEmptyValue(),
            text: '',
            valInit: ''
        };
    }

    UNSAFE_componentWillMount(){
        this.setState({valInit: ''});
    }

    UNSAFE_componentWillUpdate (nextProps, nextstate){
        const {
            valueInitial
        } = nextProps;
        let init = _.isUndefined(valueInitial) ? nextProps.field.initialValue : valueInitial;
        if(!_.isEqual(init, this.state.valInit) && !_.isEqual(init, "")){
            this.setState({valInit: init});
            this.setState({'value': RichTextEditor.createValueFromString(init, 'html')});
        }
    }

    onChange(value){
        const {
            field
        } = this.props;
        this.setState({value});
        this.setState({text: value.toString('html')});
        field.onChange(value.toString('html'));
    }

    render() {
        const {
            errorText,
            field
        } = this.props;
        let errTxt = _.isUndefined(errorText) || _.isNull(errorText) ? "" : errorText;
        let styleError = {
            position: 'relative',
            top: 5,
            fontSize: 12,
            lineHeight: 2,
            color: red500,
            transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
        };
        return (
            <div>
                <RichTextEditor
                    value={this.state.value}
                    onChange={this.onChange}
                />
                <div style={styleError}>{errTxt}</div>
            </div>
        );
    }
}

RichText.propTypes = {
    field: PropTypes.object.isRequired,
    errorText: PropTypes.string.isRequired,
    valueInitial: PropTypes.string
};

export default RichText;
