import Immutable from "immutable";
import { axiosAPI_V2 } from "../../middleware/api";
import * as Rx from "rxjs";
import { SARIN_URL } from "../../constants/index";
import * as _ from "lodash";
import { mapSarinItemToAnalyticsItem } from "./ProjectFunctions";

// actions
const LOAD_PROJECT_METADATA = "nss/sarin/LOAD_PROJECT_METADATA";
const RESET_PROJECT = "nss/sarin/RESET_PROJECT";
const RESPONSE_FROM_SARIN = "nss/sarin/RESPONSE_FROM_SARIN";
const SEND_MESSAGE = "nss/sarin/SEND_MESSAGE";
const SELECT_ITEM = "nss/sarin/SELECT_ITEM";
const LOAD_PROJECT_NAME = "nss/sarin/LOAD_PROJECT_NAME";
const LOAD_PROJECT_NAME_FULFILLED = "nss/sarin/LOAD_PROJECT_NAME_FULFILLED";

export const NO_VALUE = "NO_VALUE";
export const FETCHING_VALUES = "FETCHING_VALUES";
export const FETCHED_VALUES = "FETCHED_VALUES";

export const ALL = "ALL";
export const QUESTIONS = "QUESTIONS";
export const GROUPERS = "GROUPERS";
export const METADATA = "METADATA";
export const allowedListFilters = [ALL, QUESTIONS, GROUPERS, METADATA];

export const BASE_QUESTIONS = 1;
export const BASE_INDICATORS = 2;
export const baseItems = [BASE_QUESTIONS, BASE_INDICATORS];

const SARIN_WS_RESPONSE_SUCCESS = "SUCCESS";
const SARIN_WS_RESPONSE_ERROR = "ERROR";
const SARIN_WS_RESPONSE_EMPTY = "EMPTY";
const SARIN_WS_RESPONSE_FORBIDDEN = "FORBIDDEN";
const SARIN_WS_RESPONSE_UNAUTHORIZED = "UNAUTHORIZED";

export const SARIN_RESPONSE_SUCCESS = "nss/sarin/SUCCESS";
export const SARIN_RESPONSE_ERROR = "nss/sarin/ERROR";
export const SARIN_RESPONSE_EMPTY = "nss/sarin/EMPTY";
export const SARIN_RESPONSE_FORBIDDEN = "nss/sarin/FORBIDDEN";
export const SARIN_RESPONSE_UNAUTHORIZED = "nss/sarin/UNAUTHORIZED";
export const SARIN_RESPONSE_UNKNOWN = "nss/sarin/UNKNOWN";

const initialState = Immutable.Map({
    metadata: [],
    project: {},
    sarinResponse: {},
    toRender: [],
    metadataGrouped: [],
    metadataGroupedChildren: [],
    selectedItem: undefined,
    status: NO_VALUE
});

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case LOAD_PROJECT_METADATA: {
            const dataFromServer = action.payload.data;
            return state.withMutations(map => {
                const groupedMetadata = _.map(dataFromServer, item => ({
                    id: item.parentId,
                    type: item.parentType,
                    priority: item.priority,
                    title: item.parentTitle,
                    isFreqGrouper: item.parentType === "GROUPER" && _.split(item.id, "_").length === 3
                }));
                const groupedChildren = _.groupBy(dataFromServer, "parentId");
                map.set("metadataGrouped", _.uniqBy(groupedMetadata, "id"));
                map.set("metadataGroupedChildren", groupedChildren);
                map.set("metadata", dataFromServer);
            });
        }
        case RESET_PROJECT:
            return initialState;
        case SARIN_RESPONSE_SUCCESS:
            return state.withMutations(map => {
                map.set("sarinResponse", action.payload);
                map.set("toRender", action.payload);
                map.set("status", FETCHED_VALUES);
            });
        case SARIN_RESPONSE_UNKNOWN:
        case SARIN_RESPONSE_ERROR:
        case SARIN_RESPONSE_EMPTY:
        case SARIN_RESPONSE_FORBIDDEN:
            return state.set("status", action.type);
        case SELECT_ITEM:
            const first = action.payload;
            const selectedItem = state.get("selectedItem");
            const value = Immutable.is(first, selectedItem) ? undefined : first;
            return state.set("selectedItem", value);
        case SEND_MESSAGE:
            return state.set("status", FETCHING_VALUES);
        case LOAD_PROJECT_NAME_FULFILLED:
            return state.set("project", action.payload.data);
        default:
            return state;
    }
};

export const loadMetadata = projectId => {
    const request = axiosAPI_V2.get("/results/metadata", {
        params: {
            projectId
        }
    });
    return {
        type: LOAD_PROJECT_METADATA,
        payload: request
    };
};

export const resetState = () => ({
    type: RESET_PROJECT
});

export const sendMessage = data => ({
    type: SEND_MESSAGE,
    payload: data
});

export const selectItem = item => ({
    type: SELECT_ITEM,
    payload: item
});

export const loadProjectAndCompanyName = projectId => ({
    type: LOAD_PROJECT_NAME,
    payload: projectId
});

const loadProjectAndCompanyNameFulfilled = data => ({
    type: LOAD_PROJECT_NAME_FULFILLED,
    payload: data
});

export const sendSarinEpic = (action$, store) =>
    action$
        .ofType(SEND_MESSAGE)
        .map(action => JSON.stringify(action.payload))
        .mergeMap(message =>
            Rx.Observable
                .create(observer => {
                    const socket = new WebSocket(SARIN_URL);
                    socket.onopen = function onopen() {
                        socket.send(message);
                    };

                    socket.onmessage = function onmessage(event) {
                        const eventMessage = event.data;
                        observer.next(eventMessage);
                    };

                    socket.onclose = function onclose() {
                        observer.complete();
                    };

                    socket.onerror = function onerror(e) {
                        observer.error(e);
                    };
                })
                .map(response => JSON.parse(response))
                .catch(error =>
                    Rx.Observable.of({
                        type: SARIN_RESPONSE_ERROR,
                        message: error
                    })
                )
        )
        .mergeMap(
            response => {
                if (_.get(response, "data.0.type") === "invalid") {
                    return Rx.Observable.of({ type: SARIN_RESPONSE_FORBIDDEN });
                } else if (_.get(response, "data.length") === 0) {
                    return Rx.Observable.of({ type: SARIN_RESPONSE_EMPTY });
                } else if (_.get(response, "type") === "ERROR") {
                    return Rx.Observable.of({ type: SARIN_RESPONSE_ERROR });
                }
                console.log(response.data);
                return Rx.Observable.of({
                    type: SARIN_RESPONSE_SUCCESS,
                    payload: response.data.map(mapSarinItemToAnalyticsItem)
                });
            }
            /* switch (response.type) {
                case SARIN_WS_RESPONSE_SUCCESS: {
                    return Rx.Observable.of({
                        type: SARIN_RESPONSE_SUCCESS,
                        payload: message
                    });
                }
                case SARIN_WS_RESPONSE_ERROR:
                    return Rx.Observable.of({ type: SARIN_RESPONSE_ERROR });
                case SARIN_WS_RESPONSE_EMPTY:
                    return Rx.Observable.of({ type: SARIN_RESPONSE_EMPTY });
                case SARIN_WS_RESPONSE_FORBIDDEN:
                    return Rx.Observable.of({ type: SARIN_RESPONSE_FORBIDDEN });
                default:
                    return Rx.Observable.of({ type: SARIN_RESPONSE_UNKNOWN });
            } */
        );

export const loadProjectNameEpic = action$ =>
    action$
        .ofType(LOAD_PROJECT_NAME)
        .mergeMap(action => {
            const request = axiosAPI_V2.get(`/projects/name/${action.payload}`);
            return Rx.Observable.fromPromise(request);
        })
        .map(response => response.data)
        .map(loadProjectAndCompanyNameFulfilled);
