import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FetchQuestionsGroupId } from '../../../../actions/questions_form';
import MixinQuestion from '../../question/mixinQuestion';
import QuestionListGroup from './QuestionListGroup';
import Title from '../Title';
import _ from 'lodash';

const styleWidthContent = {
          minWidth: 240,
          margin: '0 auto'
      }, styleTableContent = {
          width: '100%',
          minWidth: 240,
          marginRight: 'auto',
          marginLeft: 'auto',
          paddingTop: 0,
          borderCollapse:'collapse'
      };

function getNumberQuestionGroup(text) {
    let arrayText = text.split("");
    let order = "";
    for (let i = _.size(arrayText) -1; i >= 0; i--){
        if(_.isEqual("#", arrayText[i]+"")){
            break;
        } else {
            order = arrayText[i] + order;
        }
    }
    return order;
}

class Group extends Component {

    constructor(props){
        super(props);
    }

    UNSAFE_componentWillMount() {
        const { question } = this.props;
        this.props.FetchQuestionsGroupId(_.isUndefined(_.get(question, '_id._id')) ? _.get(question, '_id') : _.get(question, '_id._id'));
    }

    renderQuestions(questions){
        const {
            propsToSet,
            index
        } = this.props;
        let lapso = _.split(index, '-', 2);
        let minNum = parseInt(lapso[0]) - 1;
            return _.map(questions, (question, index) => {
            const propsToSetQuestion = _.chain(propsToSet)
                                        .omit(['question', 'idQuestion'])
                                        .set('question', question)
                                        .set('idQuestion', question._id)
                                        .set('valueAnswer', _.get(question, 'valueSource.list.values'))
                                        .value();
            const MixinCloseQuestions = MixinQuestion(QuestionListGroup);
            minNum++;
            return <MixinCloseQuestions key={question._id}
                {...propsToSetQuestion}
                index={minNum}
            />;
        });
    }

    render() {
        const {
            groupquestions,
            question,
            index
        } = this.props;
        let questionList = _.map(groupquestions, question => {
            let num = getNumberQuestionGroup(question.title);
            return _.set(question, 'key', _.isEqual(num, "") ? 0 : parseInt(num));
        });
        if(questionList instanceof Array && _.size(questionList)){
            let countValues = 0;
            _.map(questionList, ( value ) => {
                if(_.size(_.get(value, 'valueSource.list.values')) > countValues){
                    countValues = _.size(_.get(value, 'valueSource.list.values'));
                }
            });
            return (
                <div style={styleWidthContent}>
                    <Title question={_.has(question, 'fieldName') ? question : _.get(question, '_id')}/>
                    <table style={styleTableContent}>
                        <tbody>
                            {this.renderQuestions(_.orderBy(questionList, 'key', 'asc'))}
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return (
                <div style={{textAlign: 'center'}}><h3 style={{fontWeight: 400, color: "#bbb"}}>Cargando...</h3></div>
            );
        }
    }
}

Group.propTypes = {
    router: PropTypes.object,
    question: PropTypes.object.isRequired,
    propsToSet: PropTypes.object.isRequired
};

function mapStateToProps({questions}, ownProps) {
    const id = _.isUndefined(_.get(ownProps.question, '_id._id')) ? _.get(ownProps.question, '_id') : _.get(ownProps.question, '_id._id');
    let groupquestions = [];
    questions.surveyquestionsgroup.map( (value) => {
        if(_.isEqual(id, value.id)){
            groupquestions = value.data.data;
        }
    });
    return {
        groupquestions
    };
}

export default connect(mapStateToProps, {FetchQuestionsGroupId})(Group);
