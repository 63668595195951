const ROW = "ROW";
const SECTION = "SECTION";
const ELEMENT = "ELEMENT";
const TITLE = "TITLE";
const CHART = "CHART";
const DEMOGRAPHIC_CHART = "SOCIO_DEMOGRAPHIC";
const FILTER_GROUPS = "FILTER_GROUPS";
const RANKING = "RANKING";
const MENU = "MENU";
const TEXT = "TEXT";

const ItemTypes = {
    ROW,
    SECTION,
    ELEMENT,
    TITLE,
    CHART,
    DEMOGRAPHIC_CHART,
    FILTER_GROUPS,
    RANKING,
    MENU,
    TEXT,
};

export default ItemTypes;
