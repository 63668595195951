import React, { Component } from "react";
import PropTypes from "prop-types";
import { SketchPicker } from "react-color";
import IconButton from "material-ui/IconButton";
import { Row, Col } from "react-flexbox-grid";
import _ from "lodash";

const colorDefault = "#999";

class ColorPicker extends Component {
    static propTypes = {
        value: PropTypes.string.isRequired,
        onChangeColor: PropTypes.func.isRequired,
        Icon: PropTypes.func.isRequired,
        positionFixed: PropTypes.bool,
    };

    state = {
        displayColorPicker: false,
    };

    handleChangeComplete = (color) => {
        const { onChangeColor, key } = this.props;
        onChangeColor(key, _.get(color, "hex"));
    };

    handleClickColorPicker = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker });
    };

    handleCloseColorPicker = () => {
        this.setState({ displayColorPicker: false });
    };

    render() {
        const { value, positionFixed, onChangeColor, Icon } = this.props;
        const positionPicker = _.isUndefined(positionFixed)
            ? false
            : positionFixed;
        const cover = {
            position: "fixed",
            top: 20,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: -2,
        };
        let popover = {
            position: "absolute",
            zIndex: 2,
            paddingLeft: 30,
        };
        if (positionPicker) {
            popover = {
                position: "fixed",
                bottom: 16,
                zIndex: 2,
                paddingLeft: 30,
            };
        }

        return (
            <div style={{ display: "inline-block" }}>
                <IconButton
                    tooltip={"Color de letra"}
                    onClick={this.handleClickColorPicker}
                    tooltipPosition={"top-center"}
                    children={
                        <Icon
                            color={_.isUndefined(value) ? colorDefault : value}
                        />
                    }
                />
                {this.state.displayColorPicker && (
                    <div style={popover}>
                        <div
                            style={cover}
                            onClick={this.handleCloseColorPicker}
                        />
                        <SketchPicker
                            color={_.isUndefined(value) ? colorDefault : value}
                            onChangeComplete={(color) => onChangeColor(color)}
                            presetColors={[
                                "#F44E3B",
                                "#FE5700",
                                "#FE9200",
                                "#FCDC00",
                                "#DBDF00",
                                "#A4DD00",
                                "#68CCCA",
                                "#73D8FF",
                                "#AEA1FF",
                                "#FDA1FF",
                                "#999999",
                                "#4D4D4D",
                            ]}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default ColorPicker;
