import PropTypes from 'prop-types';
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import {
    getRecordsAnswersConditionsTags,
    inSearchValues,
    openValues,
    closeValues,
    addSelectedValue as asv,
    saveStateCombinations,
    changeStepIndex,
} from "./ducks";
import SearchBoxAnalytics from "../../analyticsReport/searchBox";

const { func, arrayOf, string } = PropTypes;

class FilterReportComponent extends Component {
    static defaultProps = {
        selectedIds: [],
    };

    static propTypes = {
        projectId: string.isRequired,
        addSelectedValue: func.isRequired,
        selectedIds: arrayOf(string).isRequired,
    };

    handleNextOrFinalized = () => {
        const { selectedValues, saveStateCombinations, stepIndex, changeStepIndex } = this.props;
        const listKeysGroup = _.keys(selectedValues);

        let listComb = [],
            array = [];
        _.forEach(listKeysGroup, key => {
            const groupValues = _.groupBy(_.get(selectedValues, key, []), "parentId");
            const listKeysSubGroup = _.keys(groupValues);
            _.forEach(listKeysSubGroup, keySub => {
                array.push(_.get(groupValues, keySub, []));
            });
        });

        listComb = _.map(combinations(array), value => ({
            title: getTitleCombine(value),
            name: "",
            combine: value,
        }));
        saveStateCombinations(listComb);
        changeStepIndex(stepIndex + 1);
    };

    render() {
        const { projectId, addSelectedValue, selectedIds } = this.props;
        return (
            <div style={{ minWidth: 310, maxWidth: 670, margin: "0 auto" }}>
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                    <tbody>
                        <tr>
                            <td>
                                <h3
                                    style={{ fontWeight: 400, fontSize: 14, padding: 0, margin: 0 }}
                                >{`Pasos para realizar la búsqueda de información`}</h3>
                                <ul style={{ listStyleType: "decimal", marginBottom: 30, padding: "0 0 0 20px" }}>
                                    <li>Ingrese las palabras por las cuales desea realizar la búsqueda.</li>
                                    <li>
                                        A medida que ingresa la información, se listarán las opciones que coincidan con
                                        la descripción dada.
                                    </li>
                                    <li>
                                        De la lista desplegable, puede seleccionar los valores necesarios dando clic
                                        sobre él.
                                    </li>
                                    <li>Los valores seleccionados se listarán debajo del buscador.</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ padding: 0 }}>
                                <SearchBoxAnalytics project={projectId} onChangeItem={addSelectedValue} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

function mapStateToProps({ filterReport }) {
    return {
        open: filterReport.get("open"),
        inSearch: filterReport.get("inSearch"),
        values: filterReport.get("values"),
        selectedValues: filterReport.get("selectedValues"),
        stepIndex: filterReport.get("stepIndex"),
    };
}

function combinations(arrays) {
    let combList = [];
    _.forEach(arrays, array => {
        combList = combineTwoArray(combList, array);
    });
    return combList;
}

function combineTwoArray(array1, array2) {
    const array = [];
    if (_.size(array1) === 0) {
        return _.map(array2, value => [value]);
    }
    _.forEach(array1, list => {
        _.forEach(array2, value => {
            array.push(_.concat(list, [value]));
        });
    });

    return array;
}

function getTitleCombine(array) {
    let title = "";
    _.forEach(array, (value, index) => {
        title += index > 0 ? ` - ${_.get(value, "title")}` : _.get(value, "title");
    });
    return title;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getRecordsAnswersConditionsTags,
            inSearchValues,
            openValues,
            closeValues,
            addSelectedValue: asv,
            saveStateCombinations,
            changeStepIndex,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterReportComponent);
