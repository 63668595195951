import _ from "lodash";
import * as Rx from "rxjs";
import uuid from "uuid";
import { axiosAPI_V2 as axios } from "../../../middleware/api";
import { FILTER_GROUPS, SOCIO_DEMOGRAPHIC, INDICATOR, QUESTION, RANKING, RANKING_STATS } from "../types";

const COLUMN_FAMILY_GROUPERS = "groupers";
const COLUMN_FAMILY_QUESTIONS = "questions";
const COLUMN_FAMILY_METADATA = "metadata";

function buildFilter(itemId, operator, value, columnFamily) {
    return Object.assign(
        {},
        {
            itemId,
            operator,
            value,
            columnFamily,
        },
    );
}

function buildProjectFilter(projectId) {
    return buildFilter("projectId", "eq", projectId, COLUMN_FAMILY_METADATA);
}

function getTagValue(value = "") {
    return `tags_${_.get(_.split(value, "||"), "1", "")}`;
}

function transformFilter(filter) {
    const { type, parentId, upperLimit, lowerLimit, title } = filter;

    switch (type) {
        case "CONDITION":
            return [
                buildFilter(parentId, "gte", lowerLimit, COLUMN_FAMILY_GROUPERS),
                buildFilter(parentId, "lte", upperLimit, COLUMN_FAMILY_GROUPERS),
            ];
        case "ANSWER":
            return [buildFilter(`${parentId}_RAW`, "eq", title, COLUMN_FAMILY_QUESTIONS)];
        case "TAG_VALUE":
            return [buildFilter(getTagValue(parentId), "eq", title, COLUMN_FAMILY_METADATA)];
        default:
            return [];
    }
}

function groupFiltersMapper(filters = [], projectFilter) {
    return _.flatten(filters.map(transformFilter).concat([projectFilter]));
}

export function parseSolrFiltersToSarinFilters(groupFilters = [], projectId) {
    const projectFilter = buildProjectFilter(projectId);
    return groupFilters.map(filters =>
        Object.assign({}, filters, {
            transform: groupFiltersMapper(filters.combine, projectFilter),
            code: uuid.v4(),
        }),
    );
}

export function shouldQueryAllProject(type, filters) {
    switch (type) {
        case FILTER_GROUPS:
        case SOCIO_DEMOGRAPHIC: {
            const filterCompare = _.flatMap(filters, innerFilter =>
                _.map(innerFilter.combine, innerFilterItem => innerFilterItem.type === "TAG_VALUE"),
            );
            return _.every(filterCompare, _.identity);
        }
        default:
            return false;
    }
}

function mapTagsValues({ parentTitle, title }) {
    return {
        title: parentTitle,
        value: title,
    };
}

export function buildObservableForTags(filters, projectId) {
    return Rx.Observable.from(filters).mergeMap(innerFilter => {
        const { combine } = innerFilter;
        if (_.size(combine) > 0) {
            const tags = combine.map(mapTagsValues);
            const promise = axios.post(`solutions/status/${projectId}`, {
                solution: {
                    tags,
                },
            });
            return Rx.Observable
                .fromPromise(promise)
                .map(response => response.data)
                .map(values => Object.assign({}, innerFilter, { values }));
        }
        const promise = axios.get(`projects/${projectId}/status`);
        return Rx.Observable
            .fromPromise(promise)
            .map(response => response.data)
            .map(values => Object.assign({}, innerFilter, { values }));
    });
}

export function processDataQuestion(data = []) {
    return data.map(item =>
        Object.assign(
            {},
            {
                id: _.get(item, "name"),
                count: _.get(item, "value"),
            },
        ),
    );
}

export function processDataSumGrouper(data = []) {
    return data.map(item =>
        Object.assign(
            {},
            {
                id: _.get(item, "name"),
                count: _.get(item, "value"),
                color: _.get(item, "metadata.color"),
                coi: _.get(item, "metadata.coi"),
                equivalence: _.get(item, "metadata.equivalence"),
                priority: _.get(item, "metadata.priority", 0),
                isDominant: _.get(item, "metadata.isDominant", false),
            },
        ),
    );
}

export function processDataFreqGrouper(data = []) {
    return data.map(item =>
        Object.assign(
            {},
            {
                id: _.get(item, "name"),
                count: _.get(item, "value"),
            },
        ),
    );
}

function getConditions(conditions = []) {
    return _.map(conditions, condition => _.get(condition, "metadata.conditions", []));
}

function processCondition(conditions) {
    return _.map(conditions, condition => ({
        eq: condition.equivalence,
        name: condition.reportDescription,
        color: condition.color,
        priority: condition.priority,
    }));
}

function getUniqueByEq(conditions) {
    return _.uniqBy(conditions, "eq");
}

function sortColumnsByPriority(conditions) {
    return _.sortBy(conditions, "priority");
}

const processDataRankingStats = _.flow([
    getConditions,
    _.flatMap,
    processCondition,
    getUniqueByEq,
    sortColumnsByPriority,
]);

export function processDataRanking(data = [], type) {
    switch (type) {
        case RANKING: {
            const values = data.map((item, idx) =>
                Object.assign(
                    {},
                    {
                        id: _.get(item, "metadata.id"),
                        name: _.get(item, "name"),
                        count: _.get(item, "value"),
                        conditionName: _.get(item, "metadata.condition.reportDescription"),
                        conditionRate: _.get(item, "metadata.condition.rate"),
                        conditionColor: _.get(item, "metadata.condition.color"),
                        index: idx,
                    },
                ),
            );
            return {
                values,
            };
        }
        case RANKING_STATS: {
            const columns = processDataRankingStats(data);
            const values = data.map(condition => {
                const conditionsValues = condition.metadata.conditions
                    .map(innerCondition => ({ key: innerCondition.equivalence, value: innerCondition.rate }))
                    .reduce((acc, current) => Object.assign({}, acc, { [current.key]: current.value }), {});
                const innerValues = columns.map(column => conditionsValues[column.eq] || 0.0);
                return [condition.name, ...innerValues];
            });
            return {
                columns,
                values,
            };
        }
        default:
            return data;
    }
}

export function processaDataMessage(objectType = {}, extractTypeFn = _.identity, message = {}, componentType) {
    const type = extractTypeFn(objectType);
    switch (type) {
        case "question": {
            return Object.assign(
                {},
                {
                    id: _.split(_.get(message, "itemId"), "_")[0],
                    values: processDataQuestion(message.data),
                },
            );
        }
        case "sum_grouper": {
            return Object.assign(
                {},
                {
                    id: _.get(message, "itemId"),
                    label: _.get(message, "label"),
                    values: processDataSumGrouper(message.data),
                },
            );
        }
        case "freq_grouper": {
            return Object.assign(
                {},
                {
                    id: _.get(message, "itemId"),
                    label: _.get(message, "label"),
                    values: processDataFreqGrouper(message.data),
                },
            );
        }
        case "ranking": {
            return {
                id: _.get(message, "itemId"),
                ...processDataRanking(message.data, componentType),
            };
        }
        case "counter": {
            return Object.assign(
                {},
                {
                    id: _.get(message, "itemId"),
                    values: [
                        {
                            count: _.get(message, "data.0.value", 0),
                            _id: _.get(message, "data.0.metadata.status"),
                        },
                    ],
                },
            );
        }
        case FILTER_GROUPS: {
            const sizeValues = _.chain(message)
                .get("values", [])
                .filter(val => _.get(val, "valid", true))
                .size()
                .value();
            if (sizeValues === 0) {
                return {
                    id: _.get(message, "name"),
                    type,
                };
            }
            return Object.assign(
                {},
                {
                    id: _.get(message, "name"),
                    code: _.get(message, "code"),
                    values: _.get(message, "values"),
                },
            );
        }
        case SOCIO_DEMOGRAPHIC: {
            return Object.assign(
                {},
                {
                    id: _.get(message, "name"),
                    code: _.get(message, "code"),
                    values: _.get(message, "values"),
                },
            );
        }
        case "invalid": {
            return {
                id: _.get(message, "name"),
                type,
            };
        }
        default:
            return message;
    }
}

export function sarinMessageBuilder(elementsId, type, filters, componentId, projectId, reportType) {
    switch (type) {
        case INDICATOR:
            return Rx.Observable.from(elementsId).mergeMap(grouperId => {
                const promise = axios.get(`surveygroupers/details/${grouperId}`);
                return Rx.Observable
                    .fromPromise(promise)
                    .map(response => response.data)
                    .filter(data => data.exists)
                    .map(data => {
                        const initialMessage = {
                            field: {
                                name: grouperId,
                                family: data.type,
                            },
                            itemType: data.type,
                            filters: _.map(filters, "transform"),
                            queryId: componentId,
                            reportType,
                            projectsId: [projectId],
                        };
                        return Object.assign({}, initialMessage, _.omit(data, ["exists", "type"]));
                    });
            });
        case QUESTION:
            return Rx.Observable.from(elementsId).map(questionId =>
                Object.assign(
                    {},
                    {
                        field: {
                            name: `${questionId}_RAW`,
                            family: "question",
                        },
                        itemType: "question",
                        filters: _.map(filters, "transform"),
                        queryId: componentId,
                        reportType,
                        projectsId: [projectId],
                    },
                ),
            );
        case SOCIO_DEMOGRAPHIC:
            return Rx.Observable.of({
                field: {
                    name: "ALL",
                    family: "counter",
                },
                itemType: "counter",
                filters: _.map(filters, "transform"),
                queryId: componentId,
                reportType,
                projectsId: [projectId],
            });
        case FILTER_GROUPS:
            return Rx.Observable.from(filters).map(innerFilter => ({
                field: {
                    name: innerFilter.name || "ALL",
                    family: "counter",
                },
                itemType: "counter",
                filters: [innerFilter.transform],
                queryId: componentId,
                reportType,
                projectsId: [projectId],
            }));
        case RANKING_STATS:
        case RANKING:
            return Rx.Observable.of({
                groupers: elementsId,
                itemType: "ranking",
                filters: _.map(filters, "transform"),
                queryId: componentId,
                reportType,
                projectsId: [projectId],
            });
        default:
            return Rx.Observable.empty();
    }
}

export const takeCount = {
    [INDICATOR]: -1,
    [QUESTION]: 1,
    [RANKING]: 1,
    [RANKING_STATS]: 1,
    [SOCIO_DEMOGRAPHIC]: 1,
    [FILTER_GROUPS]: -1,
};
