import * as actions from './constants';
import { axiosAPI, axiosAPI_V2 } from '../../../middleware/api';
import _ from 'lodash';

export const GET_DOCUMENTS_TYPES = "GET_DOCUMENTS_TYPES";

export function addUser(data, sendEmail, modeUpdate) {

    data = _.set(data, "sendPasswordEmail", sendEmail);
    if(!_.isEqual(_.get(data, 'role'), 'arl')){
        data = _.omit(data, ['arl']);
    } else {
        if(!_.toString(_.get(data, 'idNumber')).match(/^(ARL)/)){
            data = _.set(data, "idNumber", "ARL" + _.toString(_.get(data, 'idNumber')));
        }
    }

    let request;
    if(modeUpdate){
        request = axiosAPI.put(`/users`, { user: data });
    } else {
        request = axiosAPI.post(`/users`, { user: data });
    }

    return {
        type: actions.ADD_USER,
        payload: request
    }

}

export function getUser(id) {

    const request = axiosAPI.get(`/users/${id}`);

    return {
        type: actions.GET_USER,
        payload: request
    }

}

export function clearUser() {

    return {
        type: actions.CLEAR_USER
    }

}

export function getDocumentsTypes() {

    const request = axiosAPI_V2.get(`/master/documentsTypes`);

    return {
        type: GET_DOCUMENTS_TYPES,
        payload: request
    }
}
