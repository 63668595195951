import PropTypes from "prop-types";
import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import _ from "lodash";
import { blue700, grey300 } from "material-ui/styles/colors";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { List, ListItem } from "material-ui/List";
import ActionGrade from "material-ui/svg-icons/action/grade";
import RaisedButton from "material-ui/RaisedButton";
import ContentInbox from "material-ui/svg-icons/content/inbox";
import ContentSend from "material-ui/svg-icons/content/send";
import Divider from "material-ui/Divider";
import Subheader from "material-ui/Subheader";
import Toggle from "material-ui/Toggle";
import { setProjectsResultsAndNameSurvey } from "../actions";
import { loadMetadata, resetState } from "../../../sarin/ProjectDucks";
import IconButton from "material-ui/IconButton";
import Dialog from "material-ui/Dialog";
import VisibilityIcon from "material-ui/svg-icons/action/visibility";
import FlatButton from "material-ui/FlatButton";

class SurveysProjects extends Component {
    constructor(props) {
        super(props);
        this.renderSurveys = this.renderSurveys.bind(this);
        this.handleSurveyProjects = this.handleSurveyProjects.bind(this);
        this.handleViewProjects = this.handleViewProjects.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.state = {
            open: false,
            projects: [],
        };
    }

    handleViewProjects(projects) {
        this.setState({ open: true, projects: projects });
    }

    handleClose() {
        this.setState({ open: false });
    }

    handleSurveyProjects(projects, nameSurvey) {
        const {
            loadMetadata,
            resetState,
            setProjectsResultsAndNameSurvey,
            handleNext,
        } = this.props;
        let idProjects = projects;
        let countProjects = _.size(idProjects);
        let nameExtra = _.isEqual(countProjects, 1) ? "proyecto" : "proyectos";
        let nameSurveyMetadata = `${nameSurvey} (${countProjects} ${nameExtra})`;
        idProjects = _.chain(idProjects)
            .map((project) => _.get(project, "_id", null))
            .filter((id) => !_.isNull(id))
            .value();
        setProjectsResultsAndNameSurvey(idProjects, nameSurveyMetadata);
        resetState();
        loadMetadata(idProjects);
        handleNext();
    }

    renderDialog() {
        const customContentStyle = {
            maxWidth: 600,
            minWidth: 300,
            textAlign: "justify",
        };
        return (
            <Dialog
                title="Lista de proyectos de la prueba"
                titleStyle={{ textAlign: "center", color: blue700 }}
                actions={[
                    <FlatButton
                        label="Aceptar"
                        secondary={true}
                        keyboardFocused={true}
                        onClick={this.handleClose}
                    />,
                ]}
                modal={true}
                contentStyle={customContentStyle}
                autoScrollBodyContent={true}
                onRequestClose={this.handleClose}
                open={this.state.open}
            >
                <h3
                    style={{ fontWeight: 200, marginTop: 20, marginBottom: 10 }}
                >
                    <ul>
                        {_.map(
                            _.orderBy(this.state.projects, "name", "asc"),
                            (project, index) => (
                                <li
                                    style={{ paddingBottom: 10 }}
                                    key={`project_survey_${index}_${_.get(
                                        project,
                                        "_id"
                                    )}`}
                                >
                                    {_.get(project, "name")}
                                </li>
                            )
                        )}
                    </ul>
                </h3>
            </Dialog>
        );
    }

    renderSurveys() {
        const { surveys } = this.props;
        return _.map(surveys, (survey, index) => {
            return (
                <tr
                    key={`survey_${index}`}
                    style={{ borderTop: `1px solid ${grey300}` }}
                >
                    <td style={{ width: 160 }}>
                        <RaisedButton
                            label={`Ver resultados`}
                            secondary={true}
                            style={{ margin: 5 }}
                            onClick={() =>
                                this.handleSurveyProjects(
                                    _.get(survey, "projects", []),
                                    _.get(survey, "detail.name", "Prueba")
                                )
                            }
                        />
                    </td>
                    <td style={{ width: 50 }}>
                        <IconButton
                            onClick={() =>
                                this.handleViewProjects(
                                    _.get(survey, "projects", [])
                                )
                            }
                            tooltip={"Ver proyectos"}
                            tooltipPosition="top-center"
                            children={<VisibilityIcon />}
                        />
                    </td>
                    <td style={{}}>
                        {_.get(survey, "detail.name", "Prueba")} (proyectos:{" "}
                        {_.get(survey, "countProjects", 0)} )
                    </td>
                </tr>
            );
        });
    }

    render() {
        const { surveys, fnNextStep } = this.props;
        if (_.size(surveys) > 0) {
            return (
                <div style={{ marginTop: 40, marginBottom: 20 }}>
                    <Row>
                        <Col xs>
                            <h3 style={{ fontWeight: 400, textAlign: "left" }}>
                                Pruebas
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs>
                            <table
                                style={{
                                    borderCollapse: "collapse",
                                    width: "100%",
                                }}
                            >
                                <tbody>{this.renderSurveys()}</tbody>
                            </table>
                        </Col>
                    </Row>
                    {this.renderDialog()}
                </div>
            );
        } else {
            return <div></div>;
        }
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setProjectsResultsAndNameSurvey,
            loadMetadata,
            resetState,
        },
        dispatch
    );
}

function mapStateToProps({ graphicAdvanceProjects }) {
    let surveys = _.chain(graphicAdvanceProjects.get("surveysProjects"))
        .map((s) => _.set(s, "countProjects", _.size(_.get(s, "projects", []))))
        .orderBy(["countProjects"], ["desc"])
        .value();
    return {
        surveys: surveys,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveysProjects);
