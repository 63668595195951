import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Title from './Title';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { LBLCOMPANIES } from '../../../constants/labels';
import { getCountries } from '../../../components/fields/Locations/actions';
import SelectFieldFormat from '../../fields/SelectFieldFormat/SelectFieldFormat';

const styleWidthContent = {
    minWidth: 240
};

class QuestionSelectCountries extends Component {

    constructor(props) {
        super(props);
        this._onChange = this._onChange.bind(this);
    }

    UNSAFE_componentWillMount() {
        const {
            getCountries,
            }=this.props;
        getCountries();
    }

    _onChange(e, index, value) {
        this.props._handlerUpdateValue(e, value);
    }

    render() {
        const {
            question,
            countries,
            index,
            valueQuestion
        } = this.props;
        return (

            <div style={styleWidthContent}>
                <div style={{marginBottom: 10}}>
                    <Title question={_.has(question, 'fieldName') ? question : _.get(question, '_id')} index={index}/>
                </div>
                <SelectFieldFormat
                    config={{
                        floatingLabelText: LBLCOMPANIES.country,
                        autoWidth: true,
                        fullWidth: true,
                        value: valueQuestion,
                        onChange: this._onChange
                    }}
                >
                    {
                        _.orderBy(countries, ['name']).map((item) => {
                            return (
                                <MenuItem key={_.get(item, '_id')} value={_.get(item, '_id')} primaryText={_.capitalize(_.get(item, 'name'))}/>
                            );
                        })
                    }
                </SelectFieldFormat>
            </div>
        );
    }
}

QuestionSelectCountries.propTypes = {
    question: PropTypes.object.isRequired
};

function mapStateToProps({ master, questionsSurvey }, ownProps) {
    return {
        countries: master.countries,
        valueQuestion: _.get(questionsSurvey.get('answered').toObject(), _.get(ownProps, 'idQuestion'))
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCountries
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionSelectCountries);
