import PropTypes from "prop-types";
import React, { Component } from "react";
import { browserHistory } from "react-router";
import RaisedButton from "material-ui/RaisedButton";
import _ from "lodash";
import Rx from "rx";
import * as ps from "../../../lib/projectScheduler";
import moment from "moment";
import {
    toggleProject,
    initialProjects,
    fetchSolutionProcessed,
} from "./actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AuthComponent from "../../AuthComponent";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { blue700 } from "material-ui/styles/colors";

const styleButton = {
    marginBottom: 10,
    marginRight: 10,
    marginTop: 0,
    float: "right",
};

class ProjectCardActions extends Component {
    constructor(props) {
        super(props);
        this.handleButton = this.handleButton.bind(this);
        this._handleCloseAction = this._handleCloseAction.bind(this);
        this._handleOpenAction = this._handleOpenAction.bind(this);
        this.state = {
            open: false,
            buttonNameValid: "Ver resultados",
            buttonsDisabledValid: [
                "Finalizado",
                "No iniciado",
                "No terminado",
                "Rechazado",
            ],
        };
    }

    _handleCloseAction() {
        this.setState({ open: false });
    }

    _handleOpenAction() {
        this.setState({ open: true });
    }

    handleButton(URL) {
        const {
            buttonName,
            projectId,
            personId,
            fetchSolutionProcessed,
        } = this.props;
        if (_.isEqual(this.state.buttonNameValid, buttonName)) {
            fetchSolutionProcessed(projectId, personId)
                .then(
                    (data) => {
                        if (
                            _.isNull(_.get(data, "error", null)) &&
                            _.isEqual(_.get(data, "payload.status"), 200)
                        ) {
                            if (
                                _.get(data, "payload.data.isProcessed", false)
                            ) {
                                browserHistory.push(URL);
                            } else {
                                this._handleOpenAction();
                            }
                        }
                    },
                    (reason) => {
                        this._handleOpenAction();
                    }
                )
                .catch((data) => {
                    this._handleOpenAction();
                });
        } else {
            browserHistory.push(URL);
        }
    }

    componentDidMount() {
        const {
            time,
            toggleProject,
            projectId,
            buttonDisabled,
            projectFinalized,
        } = this.props;
        let validPT = ps.validateProjectToday(time);
        if (
            _.isEqual(buttonDisabled, false) &&
            validPT &&
            _.isEqual(projectFinalized, false)
        ) {
            toggleProject(projectId, !validPT);
            const $time = Rx.Observable.from(time.dayWeek)
                .filter(
                    (lapse) =>
                        moment().weekday() === lapse.day ||
                        (moment().weekday() === 7 && lapse.day === 0)
                )
                .pluck("lapses")
                .flatMap((lapses) => lapses)
                .flatMap((lapse) => {
                    return [
                        moment()
                            .hour(lapse.open.hour)
                            .minute(lapse.open.minute)
                            .seconds(0),
                        moment()
                            .hour(lapse.close.hour)
                            .minute(lapse.close.minute)
                            .seconds(0),
                    ];
                })
                .filter((date) => date.isSameOrAfter(moment()))
                .subscribe((item) => {
                    console.log(item);
                    var disposable = Rx.Scheduler.default.scheduleFuture(
                        "world",
                        item.toDate(),
                        function (scheduler, x) {
                            console.log(
                                scheduler,
                                "change state button " + x,
                                new Date()
                            );
                            toggleProject(projectId);
                        }
                    );
                });
        } else {
            if (!_.isEqual(projectFinalized, true)) {
                if (_.isEqual(buttonDisabled, true)) {
                    toggleProject(projectId, false);
                } else {
                    toggleProject(projectId, !validPT);
                }
            } else {
                toggleProject(projectId, false);
            }
        }
    }

    UNSAFE_componentWillMount() {
        const { initialProjects } = this.props;
        initialProjects();
    }

    render() {
        const { buttonName, URLAction, buttonStatus, projectId } = this.props;
        var buttonDisabled = false;
        if (_.indexOf(this.state.buttonsDisabledValid, buttonName) >= 0) {
            buttonDisabled = true;
        } else if (_.isEqual(this.state.buttonNameValid, buttonName)) {
            buttonDisabled = false;
        } else {
            buttonDisabled = buttonStatus;
        }
        if (_.isEqual(this.state.buttonNameValid, buttonName)) {
            return (
                <div>
                    <Dialog
                        title={"Aviso"}
                        actions={[
                            <FlatButton
                                label="Aceptar"
                                secondary={true}
                                onClick={this._handleCloseAction}
                            />,
                        ]}
                        titleStyle={{ textAlign: "center", color: blue700 }}
                        contentStyle={{
                            maxWidth: 600,
                            minWidth: 300,
                            textAlign: "justify",
                        }}
                        modal={false}
                        open={this.state.open}
                        onRequestClose={this._handleCloseAction}
                    >
                        <h3 style={{ fontWeight: 200, textAlign: "justify" }}>
                            Los resultados se están procesando. Inténtelo más
                            tarde.
                        </h3>
                    </Dialog>
                    <AuthComponent
                        component={
                            <RaisedButton
                                label={buttonName}
                                secondary={true}
                                style={styleButton}
                                onClick={this.handleButton.bind(
                                    this,
                                    URLAction
                                )}
                                disabled={buttonDisabled}
                            />
                        }
                        componentDefault={
                            <RaisedButton
                                label={"Finalizado"}
                                secondary={true}
                                style={styleButton}
                                disabled={true}
                            />
                        }
                        type={"component"}
                        permission={"cgr_employee_results_view"}
                    />
                </div>
            );
        } else {
            return (
                <RaisedButton
                    label={buttonName}
                    secondary={true}
                    style={styleButton}
                    onClick={this.handleButton.bind(this, URLAction)}
                    disabled={buttonDisabled}
                />
            );
        }
    }
}

function mapStateToProps({ dashboard, login }, ownProps) {
    const dashboardState = dashboard.get("dashboard");
    let profile = login.get("profile");
    if (_.isEqual(ownProps.buttonName, "Rechazado")) {
        return {
            buttonStatus: true,
            personId: _.get(profile, "person._id"),
        };
    } else {
        return {
            buttonStatus: dashboardState.get(ownProps.projectId),
            personId: _.get(profile, "person._id"),
        };
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleProject,
            initialProjects,
            fetchSolutionProcessed,
        },
        dispatch
    );
}

ProjectCardActions.PropTypes = {
    buttonName: PropTypes.string,
    URLAction: PropTypes.string,
    buttonDisabled: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCardActions);
