import {axiosAPI} from '../../middleware/api';
import _ from 'lodash';

export const GET_TREE_QUESTIONS_ITEMS = "GET_TREE_QUESTIONS_ITEMS";
export const SAVE_GROUPER_QUESTIONS_SUMMATORY = "SAVE_GROUPER_QUESTIONS_SUMMATORY";
export const SAVE_GROUPER_QUESTIONS_FRECUENCY = "SAVE_GROUPER_QUESTIONS_FRECUENCY";
export const CLEAN_STATE_TREE_MAP = "CLEAN_STATE_TREE_MAP";

/**
 *
 * @param idSurvey
 * @returns {{type: string, payload: *}}
 */
export function getTreeQuestionItems(idSurvey) {
    const request = axiosAPI.get(`/surveys/tree/questions/${idSurvey}`);

    return {
        type: GET_TREE_QUESTIONS_ITEMS,
        payload: request
    };
}

/**
 *
 * @param storeName
 * @returns {{type: string, storeName: *}}
 */
export function cleanStateTreeMap(storeName) {
    return {
        type: CLEAN_STATE_TREE_MAP,
        storeName
    }
}

/**
 *
 * @param props
 * @returns {{type: string, payload: (*|axios.Promise)}}
 */
export function saveGrouperQuestions(props) {
    const request = axiosAPI.post(`/surveys/grouper/questions`, {"survey": {
                                                                    name: _.get(props, 'name'),
                                                                    survey: _.get(props, 'survey'),
                                                                    typeEvaluation: _.get(props, 'typeEvaluation'),
                                                                    visible: _.isEqual(_.get(props, 'visible'), "true") ? true : false,
                                                                    level: 1,
                                                                    priority: _.get(props, 'priority'),
                                                                    questions: _.map(_.get(props, 'questions', []), question => _.get(question, 'id'))
                                                                }});

    return {
        type: SAVE_GROUPER_QUESTIONS_SUMMATORY,
        payload: request
    };
}

/**
 *
 * @param props
 * @returns {{type: string, payload: (axios.Promise|*)}}
 */
export function saveGrouperQuestionsFrecuency(name, survey, visible, questions, priority) {

    const request = axiosAPI.post(`/surveys/grouper/questions`, {"survey": {
                                                                    name: name,
                                                                    survey: survey,
                                                                    typeEvaluation: 'frecuency',
                                                                    visible: _.isEqual(visible, "true") ? true : false,
                                                                    level: 1,
                                                                    priority: priority,
                                                                    questions: _.map(questions, (question) => _.get(question, '_id'))
                                                                }});

    return {
        type: SAVE_GROUPER_QUESTIONS_FRECUENCY,
        payload: request
    };
}
