import * as actions from '../components/fields/Locations/constants';
import { GET_DOCUMENTS_TYPES } from '../constants/index';
import { GET_STUDY_LEVELS } from '../actions/info/referees_info_form';
import { GET_NUMBER_EMPLOYEES, GET_ECONOMIC_ACTIVITIES, GET_ARLS } from '../actions/info/companies_info_form';
import { GET_TEMPLATE_EMAIL, SEND_EMAIL } from '../components/ModalSendEmail/action';
import _ from 'lodash';

const INITIAL_STATE = {
    countries: [],
    nameCountry: "",
    departments:[],
    nameDepartment: "",
    cities:[],
    nameCity: "",
    arls:[],
    employeesNumber:[],
    economicActivities:[],
    studylevels:[],
    documentsTypes:[],
    templatesEmail:[]
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.GET_COUNTRIES:
            return  _.assign({}, state, {
                countries: action.payload.data
            });
        case actions.GET_NAME_COUNTRY:
            return  _.assign({}, state, {
                nameCountry: _.capitalize(_.get(action, 'payload.data.name', ''))
            });
        case actions.GET_DEPARTMENTS:
            return  _.assign({}, state, {
                departments: action.payload.data
            });
        case actions.GET_NAME_DEPARTMENT:
            return  _.assign({}, state, {
                nameDepartment: _.capitalize(_.get(action, 'payload.data.name', ''))
            });
        case actions.GET_CITIES:
            return  _.assign({}, state, {
                cities: action.payload.data
            });
        case actions.GET_NAME_CITY:
            return  _.assign({}, state, {
                nameCity: _.capitalize(_.get(action, 'payload.data.name', ''))
            });
        case actions.ClEAN_CITIES:
            return  _.assign({}, state, {
                cities: []
            });
        case GET_NUMBER_EMPLOYEES:
            return  _.assign({}, state, {
                employeesNumber: action.payload.data
            });
        case GET_ECONOMIC_ACTIVITIES:
            return  _.assign({}, state, {
                economicActivities: action.payload.data
            });
        case GET_ARLS:
            return  _.assign({}, state, {
                arls: action.payload.data
            });
        case GET_DOCUMENTS_TYPES:
            return  _.assign({}, state, {
                documentsTypes: action.payload.data
            });
        case GET_STUDY_LEVELS:
            return  _.assign({}, state, {
                studylevels: action.payload.data
            });
        case GET_TEMPLATE_EMAIL:
            return  _.assign({}, state, {
                templatesEmail: action.payload.data
            });
        case SEND_EMAIL:
            return  state;
        case actions.DELETE_COUNTRY:
            var newCountries = _.filter(state.countries, item => {
                return !_.isEqual(item._id, action.meta.idCountry);
            });
            return _.assign({}, state, {
                countries: newCountries
            });
        case actions.DELETE_DEPARTMENT:
            var newDepartments = _.filter(state.departments, item => {
                return !_.isEqual(item._id, action.meta.idDepartment);
            });
            return _.assign({}, state, {
                departments: newDepartments
            });
        case actions.DELETE_CITY:
            var newCities= _.filter(state.cities, item => {
                return !_.isEqual(item._id, action.meta.idCity);
            });
            return _.assign({}, state, {
                cities: newCities
            });
        default:
            return state;
    }
}
