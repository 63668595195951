import React, { Component } from "react";
import PropTypes from "prop-types";
import DeleteElementComponent from "./deleteElementComponent";
import UpElementComponent from "./upElementComponent";
import DownElementComponent from "./downElementComponent";
import ConfigElementComponent from "./configElementComponent";

const styles = {
    titleElement: {
        padding: "1px 6px",
        fontSize: 12,
        borderRadius: "5px 5px 0 0",
        color: "#FFF",
        userSelect: "none",
        float: "right",
        marginTop: -27,
        marginRight: -1,
    },
};

class TabElementComponent extends Component {
    static propTypes = {
        backgroundColorTitle: PropTypes.string.isRequired,
        indexElement: PropTypes.number,
        totalElements: PropTypes.number,
        path: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        hasUpDown: PropTypes.bool,
        pathArray: PropTypes.string.isRequired,
        position: PropTypes.number.isRequired,
        survey: PropTypes.string.isRequired,
        propElement: PropTypes.any.isRequired,
    };

    render() {
        const {
            backgroundColorTitle,
            totalElements,
            path,
            indexElement,
            hasUpDown,
            name,
            pathArray,
            position,
            survey,
            propElement,
        } = this.props;
        return (
            <div style={{ width: "100%" }}>
                <div
                    style={{
                        ...styles.titleElement,
                        backgroundColor: backgroundColorTitle,
                    }}
                >
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ padding: "0 5px 0 0" }}>{name}</td>
                                <td style={{ padding: 0 }}>
                                    <DeleteElementComponent
                                        path={path}
                                        pathArray={pathArray}
                                        position={position}
                                        nameElement={name}
                                        survey={survey}
                                    />
                                </td>
                                {totalElements !== indexElement + 1 &&
                                    hasUpDown && (
                                        <td style={{ padding: 0 }}>
                                            <DownElementComponent
                                                pathArray={pathArray}
                                                survey={survey}
                                                path={path}
                                                position={position}
                                                index={indexElement}
                                            />
                                        </td>
                                    )}
                                {indexElement !== 0 &&
                                    hasUpDown && (
                                        <td style={{ padding: 0 }}>
                                            <UpElementComponent
                                                pathArray={pathArray}
                                                survey={survey}
                                                position={position}
                                                path={path}
                                                index={indexElement}
                                            />
                                        </td>
                                    )}
                                <td style={{ padding: 0 }}>
                                    <ConfigElementComponent path={path} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default TabElementComponent;
