import React, { Component } from "react";
import PropTypes from "prop-types";
import IconButton from "material-ui/IconButton";
import FormatUlList from "material-ui/svg-icons/editor/format-list-bulleted";
import FormatOlList from "material-ui/svg-icons/editor/format-list-numbered";
import _ from "lodash";

class EditorList extends Component {
    state = {
        BLOCK_TYPES: [
            { name: "Lista", style: "unordered-list-item", icon: FormatUlList },
            {
                name: "Lista enumerada",
                style: "ordered-list-item",
                icon: FormatOlList,
            },
        ],
    };

    static propTypes = {
        editorState: PropTypes.object.isRequired,
        onToggle: PropTypes.func.isRequired,
        colorDefault: PropTypes.string.isRequired,
        colorFocus: PropTypes.string.isRequired,
    };

    _onToggle = (style, e) => {
        const { onToggle } = this.props;
        e.preventDefault();
        onToggle(style);
    };

    render() {
        const {
            editorState,
            colorDefault,
            colorFocus,
            tooltipPositionButton,
        } = this.props;
        const { BLOCK_TYPES } = this.state;
        const currentStyle = editorState.getCurrentInlineStyle();
        const selection = editorState.getSelection();
        const blockType = editorState
            .getCurrentContent()
            .getBlockForKey(selection.getStartKey())
            .getType();
        return (
            <div style={{ display: "inline-block" }}>
                {_.map(BLOCK_TYPES, (type, index) => {
                    let colorIcon = colorDefault;
                    if (_.isEqual(_.get(type, "style"), blockType)) {
                        colorIcon = colorFocus;
                    }
                    const Icon = _.get(type, "icon");
                    return (
                        <IconButton
                            key={`list-rich-text-editor-${index}`}
                            tooltip={_.get(type, "name")}
                            onClick={this._onToggle.bind(this, type.style)}
                            tooltipPosition={tooltipPositionButton}
                            children={<Icon color={colorIcon} />}
                        />
                    );
                })}
            </div>
        );
    }
}

export default EditorList;
