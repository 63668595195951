import PropTypes from "prop-types";
import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Doughnut } from "react-chartjs-2";
import _ from "lodash";

const options = {
    //Boolean - Whether we should show a stroke on each segment
    segmentShowStroke: true,

    //String - The colour of each segment stroke
    segmentStrokeColor: "#fff",

    //Number - The width of each segment stroke
    segmentStrokeWidth: 0,

    //Number - The percentage of the chart that we cut out of the middle
    percentageInnerCutout: 50, // This is 0 for Pie charts

    //Number - Amount of animation steps
    animationSteps: 100,

    //String - Animation easing effect
    animationEasing: "easeOutBounce",

    //Boolean - Whether we animate the rotation of the Doughnut
    animateRotate: true,

    //Boolean - Whether we animate scaling the Doughnut from the centre
    animateScale: false,

    //String - A legend template
    legendTemplate:
        '<ul class="<%=name.toLowerCase()%>-legend"><% for (var i=0; i<segments.length; i++){%><li><span style="background-color:<%=segments[i].fillColor%>"></span><%if(segments[i].label){%><%=segments[i].label%><%}%></li><%}%></ul>',
    legend: {
        display: false,
    },
};

function parseData(dataset = []) {
    const result = dataset.reduce(
        (acc, current) => {
            return {
                labels: acc.labels.concat(current.label),
                data: acc.data.concat(current.value),
                backgroundColor: acc.backgroundColor.concat(current.color),
                hoverBackgroundColor: acc.hoverBackgroundColor.concat(
                    current.highlight
                ),
            };
        },
        {
            labels: [],
            data: [],
            backgroundColor: [],
            hoverBackgroundColor: [],
        }
    );
    const { labels, data, backgroundColor, hoverBackgroundColor } = result;
    return {
        labels,
        datasets: [
            {
                data,
                backgroundColor,
                hoverBackgroundColor,
            },
        ],
    };
}

class PieChart extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data, width, height } = this.props;
        if (!_.isNull(data) && !_.isUndefined(data)) {
            const parsedData = parseData(data);
            return (
                <Row style={{ marginTop: 10, marginBottom: 10 }}>
                    <Col xs>
                        <div
                            style={{
                                width: parseInt(width),
                                marginLeft: "auto",
                                paddingRight: 15,
                            }}
                        >
                            <Doughnut
                                data={parsedData}
                                options={options}
                                width={parseInt(width)}
                                height={parseInt(height)}
                            />
                        </div>
                        <div style={{ width: "100%", margin: "0 auto" }}>
                            <h4 style={{ fontWeight: 400 }}>
                                Total: {_.get(data, "count", 0)}
                            </h4>
                        </div>
                    </Col>
                </Row>
            );
        }

        return null;
    }
}

PieChart.propTypes = {
    data: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
};

export default PieChart;
