// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import Charts from "./components/charts";

class SociodemographicComponent extends Component {
    static propTypes = {
        chartProps: PropTypes.object.isRequired,
        rowPath: PropTypes.string.isRequired,
        sectionPath: PropTypes.string.isRequired,
        elementPath: PropTypes.string.isRequired,
    };

    render() {
        const { chartProps, rowPath, sectionPath, elementPath } = this.props;
        return (
            <Charts
                size={_.get(chartProps, "size")}
                type={_.get(chartProps, "chartPresentationType")}
                typeComponent={_.get(chartProps, "chartType")}
                rowPath={rowPath}
                sectionPath={sectionPath}
                elementPath={elementPath}
            />
        );
    }
}

export default SociodemographicComponent;
