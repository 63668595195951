import PropTypes from "prop-types";
import React, { Component } from "react";
import StarIcon from "material-ui/svg-icons/toggle/star";
import { blue700 } from "material-ui/styles/colors";
import _ from "lodash";
import ButtonDetailComponent from "./buttonDetailComponent";
import { pageTypes } from "../reportAdmon/types";

const styles = {
    tdHeadIconTitle: {
        textAlign: "center",
        padding: "6px 2px 4px 2px",
        verticalAlign: "top",
        backgroundColor: "#D4D0D0",
        color: "#303131",
        border: "1px solid #757575",
    },
    tdHeadTitle: {
        padding: "3px 6px",
        verticalAlign: "top",
        backgroundColor: "#D4D0D0",
        color: "#303131",
        border: "1px solid #757575",
        textAlign: "center",
    },
    styleValueTitle: {
        fontWeight: 400,
        fontSize: 13,
        color: "#000",
        textAlign: "center",
        margin: "7px 0px 0px 0px",
        padding: "1px 3px 0px 3px",
    },
};

function getStyleValues(styleTD) {
    return _.assign(
        {
            fontWeight: 400,
            fontSize: 12,
            color: "#000",
            textAlign: "left",
            margin: 0,
            padding: 0,
        },
        styleTD,
    );
}

function styleIndicator(color) {
    return {
        display: "inline-block",
        height: 12,
        width: 12,
        position: "absolute",
        marginTop: 3,
        borderRadius: "100%",
        backgroundColor: color,
        color,
    };
}

function getRate(value) {
    if (value <= 0) {
        return "0%";
    }
    const por = value * 100;
    return `${por.toFixed(2)}%`;
}

function getStyleTdValue(index, colorB) {
    let backgroundColor = "#FAFAFA";
    if (index % 2 !== 0) {
        backgroundColor = "#FFF";
    }
    if (!_.isUndefined(colorB) && !_.isNull(colorB)) {
        backgroundColor = colorB;
    }
    return {
        padding: "2px 3px",
        verticalAlign: "top",
        backgroundColor,
        color: "#2E2E2E",
        border: "1px solid #757575",
    };
}

class RankingComponent extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        hasDetail: PropTypes.bool,
        projectId: PropTypes.string,
        maxRanking: PropTypes.number.isRequired,
    };

    render() {
        const { data, hasDetail, projectId, maxRanking } = this.props;
        const visibleDetail = _.isUndefined(hasDetail) || _.isNull(hasDetail) ? false : hasDetail;
        const ranking = _.chain(data)
            .get("0.values", [])
            .orderBy(["index"], ["asc"])
            .take(maxRanking)
            .value();
        const hasRanking = _.size(ranking) > 0;
        const styleTable = {
            width: "100%",
            borderCollapse: "collapse",
            padding: 0,
            textAlign: "left",
            border: "1px solid #CCC",
            boxSizing: "border-box",
        };

        return (
            <div>
                <table style={styleTable}>
                    <tbody>
                        <tr className="print_pdf_component">
                            <td style={_.assign(styles.tdHeadIconTitle, { width: 28 })} className="print_pdf_component">
                                <div>
                                    <StarIcon color={blue700} />
                                </div>
                            </td>
                            <td style={styles.tdHeadTitle} className="print_pdf_component">
                                <div style={styles.styleValueTitle}>Indicador</div>
                            </td>
                            <td style={styles.tdHeadTitle} className="print_pdf_component">
                                <div style={styles.styleValueTitle}>Condición</div>
                            </td>
                            <td style={styles.tdHeadTitle} className="print_pdf_component">
                                <div style={styles.styleValueTitle}>Valor</div>
                            </td>
                        </tr>
                        {hasRanking ? (
                            _.map(ranking, (rank, index) => (
                                <tr key={`ranking-indicator-${index}`} className="print_pdf_component">
                                    <td
                                        style={_.assign(getStyleTdValue(index), { textAlign: "center", width: 28 })}
                                        className="print_pdf_component"
                                    >
                                        <div>{index + 1}</div>
                                    </td>
                                    <td style={getStyleTdValue(index)} className="print_pdf_component">
                                        <div style={getStyleValues({})}>{`${_.get(rank, "name", "")}`}</div>
                                    </td>
                                    <td style={getStyleTdValue(index)} className="print_pdf_component">
                                        <div style={styleIndicator(_.get(rank, "conditionColor", "none"))}>
                                            <div style={{ opacity: 0, position: "absolute" }}>.</div>
                                        </div>
                                        <div style={getStyleValues({ display: "inline-block", paddingLeft: 15 })}>
                                            {`${_.get(rank, "conditionName", "")}`}
                                        </div>
                                    </td>
                                    <td style={getStyleTdValue(index)} className="print_pdf_component">
                                        <div style={getStyleValues({})}>{`${getRate(
                                            _.get(rank, "conditionRate", 0),
                                        )}`}</div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={4} style={getStyleTdValue(1)} className="print_pdf_component">
                                    <div style={getStyleValues({ textAlign: "center" })}>No hay indicadores</div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div style={{ margin: "3px 0px 7px 7px", float: "right" }}>
                    {!_.isNull(projectId) &&
                        !_.isUndefined(projectId) && (
                            <ButtonDetailComponent
                                projectId={projectId}
                                textTooltip={"Ver detalle ranking"}
                                visible={visibleDetail}
                                goPage={pageTypes.RANKING}
                            />
                        )}
                </div>
            </div>
        );
    }
}

export default RankingComponent;
