import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import CircularProgress from "material-ui/CircularProgress";
import { blue700, green700, green200 } from "material-ui/styles/colors";
import Divider from "material-ui/Divider";
import { advanceUserClean, closeDialogUserAdvance } from "./actions";
import _ from "lodash";
import moment from "moment";

class AdvanceUser extends Component {
    constructor(props) {
        super(props);
        this._handleCloseAction = this._handleCloseAction.bind(this);
        this.renderInformation = this.renderInformation.bind(this);
        this.renderCircleProgress = this.renderCircleProgress.bind(this);
    }

    _handleCloseAction() {
        const { advanceUserClean, closeDialogUserAdvance } = this.props;
        advanceUserClean();
        closeDialogUserAdvance();
    }

    renderCircleProgress() {
        const { advance } = this.props;
        var Circle = require("rc-progress").Circle;
        const styleCircularProgress = {
            width: 87,
            height: 87,
            borderRadius: "100%",
            display: "inline-block",
        };
        const percentage = _.get(advance, "percentage");
        return (
            <div
                style={{
                    margin: "30px 10px 0px 0px",
                    width: 215,
                    display: "inline-block",
                    float: "right",
                }}
            >
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <div
                                    style={{
                                        display: "inline-block",
                                        fontSize: 60,
                                        marginTop: 10,
                                    }}
                                >
                                    {`${_.get(advance, "percentage")}%`}
                                </div>
                            </td>
                            <td>
                                <div style={styleCircularProgress}>
                                    <Circle
                                        percent={percentage}
                                        strokeWidth={10}
                                        strokeColor={green700}
                                        trailWidth={0}
                                        trailColor={green200}
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    renderInformation() {
        const { advance } = this.props;
        if (_.isNull(advance)) {
            return (
                <div style={{ margin: "20px auto", width: 50 }}>
                    <CircularProgress
                        style={{ margin: "30px auto" }}
                        size={45}
                        thickness={3}
                    />
                </div>
            );
        } else if (_.isUndefined(advance)) {
            return (
                <div>
                    <h3 style={{ fontWeight: 200, textAlign: "center" }}>
                        Al parecer hubo un error al buscar la información para
                        este usuario
                    </h3>
                </div>
            );
        } else {
            const initDate = moment(_.get(advance, "started")).format(
                "DD/MMM/YYYY HH:mm"
            );
            const finishDate = moment(_.get(advance, "finished")).format(
                "DD/MMM/YYYY HH:mm"
            );
            return (
                <div>
                    <h4 style={{ fontWeight: 200, textAlign: "left" }}>
                        <strong>Identificación: </strong>
                        {`${_.get(advance, "person.id")}`}
                    </h4>
                    <h4 style={{ fontWeight: 200, textAlign: "left" }}>
                        <strong>Nombre: </strong>
                        {`${_.get(advance, "person.name")} ${_.get(
                            advance,
                            "person.lastName"
                        )}`}
                    </h4>
                    <Divider />
                    <div>
                        <div
                            style={{
                                minWidth: 150,
                                width: 190,
                                display: "inline-block",
                                paddingRight: 20,
                            }}
                        >
                            <h4 style={{ fontWeight: 200, textAlign: "left" }}>
                                <strong>Inicio: </strong>
                                {`${initDate}`}
                            </h4>
                            <h4 style={{ fontWeight: 200, textAlign: "left" }}>
                                <strong>Fin: </strong>
                                {`${finishDate}`}
                            </h4>
                            <h4 style={{ fontWeight: 200, textAlign: "left" }}>
                                <strong>Sección: </strong>
                                {`${_.get(advance, "section")}`}
                            </h4>
                        </div>
                        {this.renderCircleProgress()}
                    </div>
                </div>
            );
        }
    }

    render() {
        const { openDialog } = this.props;
        return (
            <Dialog
                title={"Estado actual del usuario"}
                actions={[
                    <FlatButton
                        label="Aceptar"
                        secondary={true}
                        onClick={this._handleCloseAction}
                    />,
                ]}
                titleStyle={{ textAlign: "center", color: blue700 }}
                autoScrollBodyContent={true}
                contentStyle={{
                    maxWidth: 600,
                    minWidth: 300,
                    textAlign: "justify",
                }}
                modal={false}
                open={openDialog}
                onRequestClose={this._handleCloseAction}
            >
                {this.renderInformation()}
            </Dialog>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            advanceUserClean,
            closeDialogUserAdvance,
        },
        dispatch
    );
}

function mapStateToProps({ UserDialogAdvance }, ownProps) {
    return {
        openDialog: _.get(UserDialogAdvance, "openDialog"),
        advance: _.get(UserDialogAdvance, "advance"),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceUser);
