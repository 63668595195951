import { Map, List, Set } from "immutable";
import * as Rx from "rxjs";
import * as _ from "lodash";
import { SARIN_URL } from "../../constants/index";
import {
    buildObservableForTags,
    processaDataMessage,
    sarinMessageBuilder,
    shouldQueryAllProject,
    takeCount,
} from "./item/functions";
import createReducer from "../../lib/createReducer";

const UPDATE_FILTERS = "nss/analytics-report/UPDATE_FILTERS";
const CLEAN_FILTERS = "nss/analytics-report/CLEAN_FILTERS";
const SELECTED_FILTERS = "nss/analytics-report/SELECTED_FILTERS";
const CLEAN_SELECTED_FILTERS = "nss/analytics-report/CLEAN_SELECTED_FILTERS";
const SEND_MESSAGE = "nss/analytics-report/SEND_MESSAGE";
const CLEAR_STATE = "nss/analytics-report/CLEAR_STATE";
const SARIN_RESPONSE = "nss/analytics-report/SARIN_RESPONSE";
const SARIN_RESPONSE_ERROR = "nss/analytics-report/SARIN_RESPONSE_ERROR";
const SARIN_RESPONSE_IN_PROGRESS = "nss/analytics-report/SARIN_RESPONSE_IN_PROGRESS";
const SARIN_CLEAN = "nss/analytics-report/SARIN_CLEAN";

const initialState = Map({
    filters: List(),
    selectedFilters: List(),
    loading: Set(),
    invalid: Set(),
});

export default createReducer(initialState, {
    [UPDATE_FILTERS]: (state, action) => state.set("filters", List(_.get(action, "payload.filters", []))),
    [CLEAN_FILTERS]: state => state.set("filters", List()),
    [CLEAR_STATE]: () => initialState,
    [SARIN_RESPONSE]: (state, action) => {
        const { payload: { uuid, data } } = action;
        const isInvalid = _.some(data, item => _.isEqual(item.type, "invalid"));
        const lastValues = state.get(uuid) || [];
        return state.withMutations(map => {
            map.set("loading", state.get("loading").delete(uuid));
            if (isInvalid) {
                // map.set("invalid", state.get("invalid").add(uuid));
                map.set(uuid, lastValues.concat(data.filter(item => item.type !== "invalid")));
            } else {
                map.set(uuid, lastValues.concat(data));
            }
        });
    },
    [SELECTED_FILTERS]: (state, action) => {
        let codesFilters = state.get("selectedFilters").toJS();
        if (_.get(action, "meta.isSelected", false)) {
            codesFilters.push(_.get(action, "meta.code"));
        } else {
            codesFilters = _.filter(codesFilters, cod => !_.isEqual(cod, _.get(action, "meta.code")));
        }
        return state.set("selectedFilters", List(codesFilters));
    },
    [SARIN_CLEAN]: (state, action) => {
        const { payload: { uuid } } = action;
        return state.set(uuid, []).set("invalid", state.get("invalid").delete(uuid));
    },
    [SARIN_RESPONSE_IN_PROGRESS]: (state, action) => {
        const { payload: { uuid } } = action;
        return state.set("loading", state.get("loading").add(uuid)).set("invalid", state.get("invalid").delete(uuid));
    },
    [CLEAN_SELECTED_FILTERS]: state => state.set("selectedFilters", List()),
});

export function updateFilters(filters) {
    return {
        type: UPDATE_FILTERS,
        payload: {
            filters,
        },
    };
}

export function cleanFilters() {
    return {
        type: CLEAN_FILTERS,
    };
}

export function clearState() {
    return {
        type: CLEAR_STATE,
    };
}

export function sendMessage(elementsId, projectId, uuid, filters, type, reportType) {
    return {
        type: SEND_MESSAGE,
        payload: {
            elementsId,
            uuid,
            projectId,
            filters,
            type,
            reportType,
        },
    };
}

function takeElements(type, elementsId, filters) {
    const elemetsPreSize = takeCount[type];
    if (elemetsPreSize === -1) {
        const elementsSize = _.get(elementsId, "length", 0);
        if (elementsSize === 0) {
            return _.get(filters, "length", 0);
        }
        return elementsSize;
    }
    return elemetsPreSize;
}

export const sendMessageEpic$ = action$ =>
    action$.ofType(SEND_MESSAGE).mergeMap(action => {
        const { payload: { elementsId, uuid, projectId, filters, type, reportType } } = action;
        const token = window.localStorage.getItem("authToken");
        const isAllTags = shouldQueryAllProject(type, filters);
        const takeCountElements = takeElements(type, elementsId, filters);
        if (isAllTags) {
            return buildObservableForTags(filters, projectId)
                .map(message => processaDataMessage({ type }, innerMessage => innerMessage.type, message, (a, b) => b))
                .toArray()
                .map(data => ({ type: SARIN_RESPONSE, payload: { data, uuid } }))
                .startWith({ type: SARIN_CLEAN, payload: { uuid } });
        }
        return Rx.Observable
            .create(source => {
                const connection = Rx.Observable.webSocket({
                    url: `${SARIN_URL}?token=${token}`,
                    openObserver: {
                        next: () => {
                            sarinMessageBuilder(
                                elementsId,
                                type,
                                filters,
                                uuid,
                                projectId,
                                reportType,
                            ).subscribe(message => connection.next(JSON.stringify(message)));
                        },
                    },
                });
                const subscription = connection.subscribe(
                    next => {
                        source.next(next);
                    },
                    error => {
                        source.error(error);
                    },
                );

                return () => {
                    subscription.unsubscribe();
                };
            })
            .catch(e => Rx.Observable.of({ type: SARIN_RESPONSE_ERROR, payload: e }))
            .take(takeCountElements)
            .filter(val => !_.isNil(val)) // better safe than sorry
            .filter(message => _.isEqual(message.queryId, uuid))
            .map(message => processaDataMessage(message, innerMessage => innerMessage.type, message, type))
            .toArray()
            .map(data => ({ type: SARIN_RESPONSE, payload: { data, uuid } }))
            .startWith(
                { type: SARIN_RESPONSE_IN_PROGRESS, payload: { uuid } },
                { type: SARIN_CLEAN, payload: { uuid } },
            );
    });

export function selectedFilters(code, isSelected) {
    return {
        type: SELECTED_FILTERS,
        meta: {
            code,
            isSelected,
        },
    };
}

export function cleanSelectedFilters() {
    return {
        type: CLEAN_SELECTED_FILTERS,
    };
}
