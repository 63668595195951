// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { blue400 } from "material-ui/styles/colors";
import TitleComponent from "../../reportComponents/titleComponent";

const styles = {
    box: {
        width: "100%",
        border: `1px dotted ${blue400}`,
        minHeight: 30,
        marginTop: 30,
        boxSizing: "border-box",
        backgroundColor: "#FFF",
    },
    boxDefault: {
        width: "100%",
        minHeight: 30,
    },
    contentTitle: {},
};

class TitleContent extends Component {
    static propTypes = {
        title: PropTypes.object,
        saving: PropTypes.bool.isRequired,
        path: PropTypes.string.isRequired,
    };

    render() {
        const { title, saving } = this.props;
        if (_.isEmpty(title)) {
            return <div style={{ ...styles.boxDefault }} />;
        }
        if (_.isNull(_.get(title, "text", null)) || _.isEqual(_.get(title, "text", ""), "")) {
            return <div style={{ ...styles.boxDefault }} />;
        }
        return <TitleComponent {...title} />;
    }
}

function mapStateToProps({ analyticsReportsConfiguration }, ownProps) {
    const pageConfiguration = analyticsReportsConfiguration.get("pageConfiguration");
    const title = _.get(pageConfiguration, `${_.get(ownProps, "path")}.title`);
    return {
        saving: analyticsReportsConfiguration.get("saving"),
        title,
    };
}

export default connect(mapStateToProps)(TitleContent);
