import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {reduxForm, addArrayValue} from 'redux-form';
import {Row, Col} from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import {blue700, red700} from 'material-ui/styles/colors';
import { createProjects } from '../actions/projects_form';
import { loadSurveysProject } from '../actions/surveys_form';
import { loadConsentsProjectName } from '../actions/consents_form';
import { bindActionCreators } from 'redux';
import MenuItem from 'material-ui/MenuItem';
import moment from 'moment';
import PureInput from '../components/PureInput';
import IconButton from 'material-ui/IconButton';
import UpIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-up';
import DownIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import { addValueStore } from '../components/functional/actions';
import { removeListValuesStore } from '../components/functional/actions';
import { removeValueStore } from '../components/functional/actions';
import { changePositionValueStore } from '../components/functional/actions';
import DatePickerFormat from '../components/fields/DatePickerFormat/DatePickerFormat';
import SelectFieldFormat from '../components/fields/SelectFieldFormat/SelectFieldFormat';
import _ from 'lodash';
import { browserHistory } from 'react-router';
import {toggleSnackbar} from '../actions/commons';
import {LBLPROJECTS} from '../constants/labels';
import ButtonBack from '../components/fields/buttonBack/buttonBack';
import { EXPRESSION_NUMBERS } from '../constants/regularExpressions';
import AuthComponent from '../components/AuthComponent';

const style = {
        margin: 20,
        float: 'right'
    },
    stylePaper = {
        marginTop: 20,
        marginBottom: 20
    },
    styleTable = {
        margin: 'auto',
        width: '90%'
    };

function errorFieldValidation(field){
    return field.touched && field.error ? field.error : '';
}

class ProjectsNews extends Component {

    constructor(props) {
        super(props);
        this.addConsents = this.addConsents.bind(this);
        this.removeConsents = this.removeConsents.bind(this);
        this.removeAllConsents = this.removeAllConsents.bind(this);
        this.upConsents = this.upConsents.bind(this);
        this.downConsents = this.downConsents.bind(this);
        this._saveProject = this._saveProject.bind(this);
        this._onChangeValueState = this._onChangeValueState.bind(this);
        this.state = {
            name: '',
            description: ''
        };
    }

    _saveProject(formData) {
        const {
            createProjects,
            toggleSnackbar,
            resetForm,
            companyId,
            params
        } = this.props;
        let data = _.set(formData, 'company', companyId);
        createProjects(data)
            .then((data) => {
                if(_.get(data, 'error') || (_.get(data, 'payload.status') !== 200)){
                    toggleSnackbar(true, LBLPROJECTS.msg.errorSave);
                } else {
                    resetForm();
                    toggleSnackbar(true, LBLPROJECTS.msg.successSave);
                    browserHistory.push(`/admin/companies/info/${_.get(params, '_id')}`);
                }
            }, (reason) => {
                toggleSnackbar(true, LBLPROJECTS.msg.errorSave);
            })
            .catch((data) => {
                toggleSnackbar(true, LBLPROJECTS.msg.errorSave);
            });
    }
    addConsents(index){
        //this.props.addValueStore(index, 'consents');
        const {
            fields: {
                consents
            }
        } = this.props;
        let exist = true;
        consents.map(function (consent) {
            if(_.isEqual(consent._id.value, index._id)){
                exist = false;
            }
        });
        if(exist){
            consents.addField({
                '_id': index._id,
                'name': index.name,
                'agreement': index.agreement,
                'required': index.required,
                'placeholders': index.placeholders.map( function (placeHolder){
                    return {
                        value: placeHolder.value,
                        title: placeHolder.title,
                        description: placeHolder.description
                    }
                })
            });
        }
    }
    removeConsents(index){
        //this.props.removeValueStore(index, 'consents');
    }
    removeAllConsents(){
        this.props.removeListValuesStore('consents');
    }
    upConsents(index){
        this.props.changePositionValueStore(index, 'consents', 'up');
    }
    downConsents(index){
        this.props.changePositionValueStore(index, 'consents', 'down');
    }
    _onChangeValueState(field, value){
        this.setState({field: value});
    }
    UNSAFE_componentWillMount(){
        const { resetForm, loadSurveysProject, loadConsentsProjectName } = this.props;
        resetForm();
        this.removeAllConsents('consents');
        loadSurveysProject();
        loadConsentsProjectName();
    }
    renderOptionList(list){
        if(_.size(list)){
            if(list instanceof Array){
                return list.map((option) => {
                    return (
                        <MenuItem key={option._id} value={option._id} primaryText={option.name}/>
                    );
                });
            }
        }
    }

    renderOptionConsents(consents){
        if(_.size(consents)){
            if(consents instanceof Array){
                return consents.map((consent) => {
                    return (
                        <MenuItem key={consent._id} value={consent} primaryText={consent.name}/>
                    );
                });
            }
        }
    }

    render() {
        const {
            fields: {
                name,
                description,
                survey,
                inactive,
                openDate,
                closeDate,
                consents
            },
            handleSubmit,
            projects,
            cs
        } = this.props;
        if(_.get(this, 'props.projects.data')){
            return (
                <AuthComponent
                    component={
                        <Row>
                            <Col xsOffset={2} xs={8}>
                                <Paper style={stylePaper}>
                                    <ButtonBack url={`/admin/companies/info/${this.props.params._id}`}/>
                                    <Row>
                                        <Col xsOffset={2} xs={8}>
                                            <h1 style={{textAlign: 'center', fontWeight: 400}}>{LBLPROJECTS.titleCreate}</h1>
                                            <form onSubmit={handleSubmit(this._saveProject)}>
                                                <Row>
                                                    <Col xs>
                                                        <TextField
                                                            hintText=""
                                                            floatingLabelText={LBLPROJECTS.name}
                                                            type="text"
                                                            fullWidth={true}
                                                            value={name.value}
                                                            errorText={name.touched && name.error ? name.error : '' }
                                                            onChange={(e)=>{this._onChangeValueState('name',name)}}
                                                            {...name}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs>
                                                        <SelectFieldFormat
                                                            config={{
                                                                floatingLabelText: LBLPROJECTS.survey,
                                                                value: survey.value,
                                                                fullWidth: true,
                                                                errorText: survey.touched && survey.error ? survey.error : '',
                                                                onChange: (e, index, value) => survey.onChange(value)
                                                            }}
                                                        >
                                                            {this.renderOptionList(projects.data)}
                                                        </SelectFieldFormat>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs>
                                                        <TextField
                                                            hintText=""
                                                            floatingLabelText={LBLPROJECTS.description}
                                                            multiLine={true}
                                                            rows={3}
                                                            fullWidth={true}
                                                            errorText={description.touched && description.error ? description.error : '' }
                                                            onChange={(e)=>{this._onChangeValueState('description',description)}}
                                                            {...description}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs>
                                                        <DatePickerFormat
                                                            datePickerConfig={{
                                                                floatingLabelText:LBLPROJECTS.initialDate,
                                                                hintText:LBLPROJECTS.initialDateHint,
                                                                mode: 'landscape',
                                                                errorText: errorFieldValidation(openDate),
                                                                value: openDate.value,
                                                                onChange:(e, value) => openDate.onChange(value),
                                                                style: {marginTop: 24, marginBottom: 23}
                                                            }}
                                                            stylefloatingLabelText={{marginTop: 33,marginBottom: -33}}
                                                        />
                                                    </Col>
                                                    <Col xs>
                                                        <DatePickerFormat
                                                            datePickerConfig={{
                                                                floatingLabelText:LBLPROJECTS.finalDate,
                                                                hintText:LBLPROJECTS.finalDateHint,
                                                                mode: 'landscape',
                                                                errorText: errorFieldValidation(closeDate),
                                                                value: closeDate.value,
                                                                onChange:(e, value) => closeDate.onChange(value),
                                                                style: {marginTop: 24, marginBottom: 23}
                                                            }}
                                                            stylefloatingLabelText={{marginTop: 33,marginBottom: -33}}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs>
                                                        <TextField
                                                            floatingLabelText={LBLPROJECTS.inactiveTime}
                                                            errorText={inactive.touched && inactive.error ? inactive.error : '' }
                                                            type="number"
                                                            {...inactive}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs>
                                                        <SelectFieldFormat
                                                            config={{
                                                                floatingLabelText: LBLPROJECTS.searchConsents,
                                                                fullWidth: true,
                                                                autoWidth: true,
                                                                onChange: (e, index, value) => this.addConsents(value)
                                                            }}
                                                        >
                                                            {this.renderOptionConsents(cs)}
                                                        </SelectFieldFormat>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs>
                                                        {_.size(consents) > 0 &&
                                                            <h3 style={{fontWeight: 400, textAlign: 'center', color: red700}}>
                                                                Los campos que se muestran con cada consentimiento, son obligatorios.
                                                            </h3>}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs>
                                                        {consents.map((consent, index) => {
                                                            return (
                                                                <table style={styleTable} key={consent._id.value}>
                                                                    <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <h3 style={{fontWeight: 400, textAlign: 'left'}}>{`${index+1}. ${consent.name.value}. `}
                                                                                <span style={{color: red700}}>{LBLPROJECTS.required(consent.required.value)}</span>
                                                                            </h3>
                                                                        </td>
                                                                        <td style={{border: 1, width: 150}}>
                                                                            <IconButton
                                                                                children={<UpIcon/>}
                                                                                disabled={index === 0}
                                                                                tooltip={LBLPROJECTS.tooltips.btnUp}
                                                                                tooltipPosition="top-center"
                                                                                onClick={() => {
                                                                                    consents.swapFields(index, index - 1);
                                                                                    // this.upConsents(index)
                                                                                }}
                                                                            />
                                                                            <IconButton
                                                                                children={<DownIcon/>}
                                                                                tooltip={LBLPROJECTS.tooltips.btnDown}
                                                                                tooltipPosition="top-center"
                                                                                disabled={index === _.size(consents) - 1}
                                                                                onClick={() => {
                                                                                    consents.swapFields(index, index + 1);
                                                                                    // this.downConsents(index)
                                                                                }}
                                                                            />
                                                                            <IconButton
                                                                                children={<DeleteIcon/>}
                                                                                tooltip={LBLPROJECTS.tooltips.btnDelete}
                                                                                tooltipPosition="top-center"
                                                                                onClick={() => {
                                                                                    consents.removeField(index);
                                                                                    // this.removeConsents(index)
                                                                                }}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} style={{textAlign: 'justify'}}>

                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} style={{textAlign: 'justify'}}>
                                                                            <table style={styleTable} key={consent._id.value}>
                                                                                <tbody>
                                                                                {consent.placeholders.map( (placeholder, ind) => {
                                                                                    return (
                                                                                        <tr key={placeholder.id.value}>
                                                                                            <td>
                                                                                                <PureInput
                                                                                                    floatingLabelText={placeholder.title.value}
                                                                                                    type='text'
                                                                                                    hintText={`##${placeholder.description.value}`}
                                                                                                    errorText={placeholder.value.touched && placeholder.value.error ? placeholder.value.error : '' }
                                                                                                    field={placeholder.value}
                                                                                                />
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                })}
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            );
                                                        })}
                                                    </Col>
                                                </Row>
                                                <AuthComponent
                                                    component={
                                                        <RaisedButton
                                                            type="submit"
                                                            disabled={!consents.length}
                                                            label={LBLPROJECTS.buttons.save}
                                                            secondary={true}
                                                            style={style}
                                                        />
                                                    }
                                                    permission={"cgr_project_create"}
                                                    type={"component"}
                                                />
                                            </form>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Col>
                        </Row>
                    }
                    permission={"cgr_project_create"}
                    type={"url"}
                />
            );
        } else {
            return (<div></div>);
        }
    }
}

const requireFields = (...names) =>
    data =>
        names.reduce((errors, index) => {
                if (!data[index]) {
                    errors[index] = LBLPROJECTS.validations.isRequired;
                }
                return errors;
            },
            {});

const validateFieldsConsents = requireFields('value');

const validate = (values) => {
    const errors = {};
    if (!values.closeDate) {
        errors.closeDate = LBLPROJECTS.validations.finalDate;
    }
    if (!values.openDate) {
        errors.openDate = LBLPROJECTS.validations.initialDate;
    }
    if(values.closeDate && values.openDate){
        let fechaInicial = moment(values.openDate).format('YYYY-MM-DD');
        let fechaFinal = moment(values.closeDate).format('YYYY-MM-DD');
        if(!(moment(fechaInicial).isBefore(fechaFinal))){
            if(!_.isEqual(fechaInicial + '', fechaFinal + '')){
                errors.openDate = LBLPROJECTS.validations.validateDate;
            }
        }
    }
    if (!values.name) {
        errors.name = LBLPROJECTS.validations.name;
    }
    if (!values.description) {
        errors.description = LBLPROJECTS.validations.description;
    }
    if (!values.inactive) {
        errors.inactive = LBLPROJECTS.validations.validateInactiveTime;
    } else {
        if(!EXPRESSION_NUMBERS.test(values.inactive)){
            errors.inactive = LBLPROJECTS.validations.validateInactiveTimeOnlyNumber;
        }
    }
    if (!values.survey) {
        errors.survey = LBLPROJECTS.validations.survey;
    }
    errors.consents = values.consents.map((consent, index) => {
        return {
            placeholders: consent.placeholders.map(validateFieldsConsents)
        };
    });
    return errors;
};

function mapStateToProps({autoComplete, surveys, consents, form}, ownProps){
    return  {
        autocomplete: autoComplete.get('consents'),
        projects: surveys.all,
        companyId: ownProps.params._id,
        cs: _.isUndefined(consents.all.data) ? [] : _.get(consents, 'all.data')
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        createProjects,
        loadSurveysProject,
        loadConsentsProjectName,
        addValueStore,
        removeValueStore,
        removeListValuesStore,
        changePositionValueStore,
        toggleSnackbar
    }, dispatch);
}

ProjectsNews.propTypes = {
    fields: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired
};

export default reduxForm({
    form: 'ProjectsNewForm',
    fields: [
        'name',
        'description',
        'survey',
        'openDate',
        'closeDate',
        'inactive',
        'consents[]._id',
        'consents[].name',
        'consents[].agreement',
        'consents[].required',
        'consents[].placeholders[].id',
        'consents[].placeholders[].value',
        'consents[].placeholders[].title',
        'consents[].placeholders[].description'
    ],
    validate
}, mapStateToProps, mapDispatchToProps)(ProjectsNews);
