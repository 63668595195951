import PropTypes from "prop-types";
import React, { Component } from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { LBLEMPLOYESS } from "../../constants/labels";
import { showModalResult } from "./actions";
import { connect } from "react-redux";
import { blue700 } from "material-ui/styles/colors";
import { bindActionCreators } from "redux";

class ModalMessageResult extends Component {
    constructor(props) {
        super(props);
        this.handleModalClose = this.handleModalClose.bind(this);
    }

    handleModalClose() {
        this.props.showModalResult(false, "", "");
    }

    render() {
        const actions = [
            <FlatButton
                label={LBLEMPLOYESS.buttons.cancel}
                secondary={true}
                onClick={this.handleModalClose}
            />,
        ];

        const { title, message, open } = this.props;

        return (
            <Dialog
                title={title}
                modal={true}
                autoDetectWindowHeight={true}
                repositionOnUpdate={true}
                autoScrollBodyContent={true}
                titleStyle={{ textAlign: "center", color: blue700 }}
                contentStyle={{
                    maxWidth: 600,
                    minWidth: 300,
                    textAlign: "justify",
                }}
                open={open}
                actions={actions}
                onRequestClose={this.handleModalClose}
            >
                <h3 style={{ fontWeight: 200, textAlign: "justify" }}>
                    {message}
                </h3>
            </Dialog>
        );
    }
}

function mapStateToProps({ modalMessageResult }, ownProps) {
    return {
        open: modalMessageResult.get("open"),
        message: modalMessageResult.get("message"),
        title: modalMessageResult.get("title"),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            showModalResult,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalMessageResult);
