import {CHECK_VALUE_FIELD, CLEAN_STATE_TREE_MAP} from './actions';
import Immutable from 'immutable';

const initialState = Immutable.Map();
export default (state = initialState, action) => {
    switch (action.type) {
        case CHECK_VALUE_FIELD:
            return state.set(action.meta.storeName, action.payload.data);
        case CLEAN_STATE_TREE_MAP:
            return state.set(action.storeName, {data: null});
        default:
            return state;
    }
};
