import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { logOut } from "./actions";
import { showModalChangePassword } from "../../ModalChangePassword/actions";
import { showFaq, setInSearch } from "../../../actions/faq_form";
import { LBLAPP, NameEmployeeTest } from "../../../constants/labels";
import AppBar from "material-ui/AppBar";
import IconMenu from "material-ui/IconMenu";
import MoreVertIcon from "material-ui/svg-icons/navigation/more-vert";
import { blue600 } from "material-ui/styles/colors";
import AccountCircleIcon from "material-ui/svg-icons/action/account-circle";
import HelpIcon from "material-ui/svg-icons/action/help";
import MenuItem from "material-ui/MenuItem";
import IconButton from "material-ui/IconButton";
import _ from "lodash";
import { browserHistory } from "react-router";
import { Link } from "react-router";
import ModalChangeFirstPassword from "../../ModalChangePassword/componentChangeFirstPassword";
import ModalChangePassword from "../../ModalChangePassword/componentChangePassword";

class appBarDashboardEmployee extends Component {
    constructor(props) {
        super(props);
        this._handleLogOut = this._handleLogOut.bind(this);
        this._handleShowModalChangePassword = this._handleShowModalChangePassword.bind(
            this
        );
        this.onTapFaq = this.onTapFaq.bind(this);
    }

    onTapFaq() {
        const { showFaq, setInSearch } = this.props;
        setInSearch();
        showFaq(true);
    }

    UNSAFE_componentWillMount() {
        const { profile } = this.props;
        const personId = _.get(profile, "person._id");
        if (!personId) {
            console.error("No hay un usuario logeado");
            browserHistory.replace(`/login`);
        }
    }

    _handleShowModalChangePassword() {
        this.props.showModalChangePassword(true);
    }

    _handleLogOut() {
        console.log("logout");
        this.props.logOut();
        browserHistory.push("/login");
    }

    render() {
        const { profile, loggedIn } = this.props;

        const firstPassword = _.get(profile, "firstPassword");
        const userName = loggedIn
            ? _.get(profile, "person.name") +
              " " +
              _.get(profile, "person.lastName")
            : NameEmployeeTest;
        return (
            <AppBar
                title={LBLAPP.title}
                style={{ zIndex: 1400, position: "fixed", left: 0 }}
                showMenuIconButton={false}
                onTitleTouchTap={(e) =>
                    browserHistory.push(`/dashboard/employee/projects`)
                }
                iconElementRight={
                    <div>
                        <IconButton
                            touch={true}
                            tooltip={LBLAPP.menu.frecuenceQuestions}
                            children={<HelpIcon color={"#FFF"} />}
                            onClick={this.onTapFaq}
                        />
                        <IconMenu
                            iconButtonElement={
                                <IconButton iconStyle={{ fill: "#FFF" }}>
                                    <MoreVertIcon />
                                </IconButton>
                            }
                            targetOrigin={{
                                horizontal: "right",
                                vertical: "top",
                            }}
                            anchorOrigin={{
                                horizontal: "right",
                                vertical: "top",
                            }}
                        >
                            <MenuItem
                                primaryText={userName}
                                rightIcon={
                                    <AccountCircleIcon color={blue600} />
                                }
                                disabled={true}
                                innerDivStyle={{
                                    paddingBottom: 5,
                                    borderBottom: "1px solid #CCC",
                                }}
                                style={{ color: "#2E2E2E" }}
                            />
                            <MenuItem
                                primaryText={"Cambiar contraseña"}
                                onClick={this._handleShowModalChangePassword}
                            />
                            <MenuItem
                                onClick={this._handleLogOut}
                                primaryText="Cerrar sesión"
                            />
                        </IconMenu>
                        <ModalChangeFirstPassword
                            showChangePass={firstPassword}
                            user={profile}
                        />
                        <ModalChangePassword user={profile} />
                    </div>
                }
            />
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            logOut,
            showModalChangePassword,
            showFaq,
            setInSearch,
        },
        dispatch
    );
}

function mapStateToProps({ login }) {
    return {
        profile: login.get("profile"),
        loggedIn: login.get("loggedIn"),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(appBarDashboardEmployee);
