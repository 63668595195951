import PropTypes from "prop-types";
import React, { Component } from "react";
import TableCell from "@material-ui/core/TableCell";
import _ from "lodash";
import AuthComponent from "../../AuthComponent";
import { Link } from "react-router";
import { blue700, blue300 } from "material-ui/styles/colors";
import IconButton from "material-ui/IconButton";
import { toggleSnackbar } from "../../../actions/commons";
import { connect } from "react-redux";
import ContentCopyIcon from "material-ui/svg-icons/content/content-copy";
import FlatButton from "material-ui/FlatButton";
import VisibilityIcon from "material-ui/svg-icons/action/visibility";
import Dialog from "material-ui/Dialog";
import { LBLLISTS } from "../../../constants/labels";
import DeleteEntityModal from "../../deleteEntityModal/component";
import { deleteMasterList } from "../../../actions/listMaster_form";
import { removeRecord } from "../actions";

class LisMasterActionColumn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
        this._handleDeleteList = this._handleDeleteList.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.setState({ open: false });
    }

    _handleDeleteList(data) {
        const { deleteMasterList, toggleSnackbar, removeRecord } = this.props;
        const idListMaster = _.get(data, "_id");
        deleteMasterList(idListMaster)
            .then(
                (data) => {
                    if (
                        _.get(data, "error") ||
                        _.get(data, "payload.status") !== 200
                    ) {
                        toggleSnackbar(true, LBLLISTS.msg.errorDelete);
                    } else {
                        removeRecord(idListMaster, "_id");
                        toggleSnackbar(true, LBLLISTS.msg.successDelete);
                    }
                },
                (reason) => {
                    toggleSnackbar(true, LBLLISTS.msg.errorDelete);
                }
            )
            .catch((data) => {
                toggleSnackbar(true, LBLLISTS.msg.errorDelete);
            });
    }

    renderDialog() {
        const { data } = this.props;
        const customContentStyle = {
            maxWidth: 600,
            minWidth: 300,
            textAlign: "justify",
        };
        let listsData = _.map(_.get(data, "lists"), function (list) {
            return _.set(list, "key", _.toInteger(_.get(list, "key")));
        });
        return (
            <Dialog
                title="Lista de valores"
                titleStyle={{ textAlign: "center", color: blue700 }}
                actions={[
                    <FlatButton
                        label="Aceptar"
                        secondary={true}
                        keyboardFocused={true}
                        onClick={this.handleClose}
                    />,
                ]}
                modal={true}
                contentStyle={customContentStyle}
                autoScrollBodyContent={true}
                onRequestClose={this.handleClose}
                open={this.state.open}
            >
                <h2
                    style={{
                        fontWeight: 200,
                        textAlign: "center",
                        marginTop: 23,
                        marginBottom: 20,
                    }}
                >
                    {_.get(data, "alias")}
                </h2>
                <h3
                    style={{ fontWeight: 200, marginTop: 10, marginBottom: 10 }}
                >
                    <ul>
                        {_.map(
                            _.orderBy(listsData, "key", "asc"),
                            (list, index) => (
                                <li
                                    style={{ paddingBottom: 10 }}
                                    key={`list_item${index}_${_.get(
                                        list,
                                        "_id"
                                    )}`}
                                >
                                    {_.get(list, "value")}
                                </li>
                            )
                        )}
                    </ul>
                </h3>
            </Dialog>
        );
    }

    render() {
        const { data, colSpan } = this.props;
        const cs = _.isUndefined(colSpan) ? 1 : colSpan;
        return (
            <TableCell colSpan={cs}>
                <div
                    style={{ position: "absolute", marginTop: -24, zIndex: 99 }}
                >
                    <AuthComponent
                        component={
                            <IconButton
                                onClick={() => this.setState({ open: true })}
                                tooltip={LBLLISTS.tooltips.btnInfo}
                                tooltipPosition="top-center"
                                children={<VisibilityIcon />}
                            />
                        }
                        permission={"cgr_answer_options_visualization_list"}
                        type={"component"}
                    />
                    <AuthComponent
                        component={
                            <Link
                                to={`/admin/lists/edit/${_.get(data, "_id")}`}
                            >
                                <IconButton
                                    tooltip={LBLLISTS.tooltips.btnCopy}
                                    tooltipPosition="top-center"
                                    children={<ContentCopyIcon />}
                                />
                            </Link>
                        }
                        permission={"cgr_answer_options_duplicate"}
                        type={"component"}
                    />
                    <AuthComponent
                        component={
                            <DeleteEntityModal
                                tooltip={LBLLISTS.tooltips.btnDelete}
                                title={LBLLISTS.titleDelete}
                                message={LBLLISTS.msg.deleteModal(
                                    _.get(data, "alias")
                                )}
                                fn={this._handleDeleteList}
                                args={[data]}
                                style={{ display: "table-cell" }}
                            />
                        }
                        permission={"cgr_answer_options_delete"}
                        type={"component"}
                    />
                    {this.renderDialog()}
                </div>
            </TableCell>
        );
    }
}

LisMasterActionColumn.propTypes = {
    data: PropTypes.object.isRequired,
    colSpan: PropTypes.number,
};

export default connect(null, {
    deleteMasterList,
    toggleSnackbar,
    removeRecord,
})(LisMasterActionColumn);
