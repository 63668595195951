import React from "react";
import { Route, Redirect } from "react-router";
import App from "./components/app";

/**
 * FORM
 */
import CompaniesCreate from "./containers/form_companies";
import EmployeesCreate from "./containers/form_employees";
import ConsentsCreate from "./containers/form_consents";
import SurveysCreate from "./containers/form_surveys";
import MetricalSection from "./containers/metrical_section_form";
import GrouperQuestionSection from "./containers/grouper_questions_form";
import GroupersGroup from "./components/GroupersGroup/componentGrouperGroup";
import GrouperQuestionConditionals from "./containers/form_grouper_conditionals";
import FaqCreate from "./containers/form_faq";
import ListMasterCreate from "./containers/form_listMaster";
import QuestionCreate from "./components/questions/form";
import QuestionGroupCreate from "./containers/form_questions_group";
import RefereeCreate from "./containers/form_referees";
import SectionCreate from "./containers/form_sections";
import ProjectCreate from "./containers/form_projects";
import ProjectEdit from "./containers/form_edit_projects";
import ProjectColors from "./containers/form_colors_project";
import ProjectRanking from "./containers/form_ranking_projects";
// import ConfigProjectCreate from './containers/form_configProjects';
import ConfigProjectCreate from "./containers/form_configDaysProject";
import ProjectRefereesCreate from "./containers/form_projectsReferees";
import Login from "./containers/login/loginBox";
import ForgetPassword from "./containers/ForgotPassword/forgotPassword";
import AdminCountries from "./components/AdminLocations/componentAdminCountries";
import AdminDepartments from "./components/AdminLocations/componentAdminDepartments";
import AdminCities from "./components/AdminLocations/componentAdminCities";
import FormTemplateEmails from "./containers/form_template_emails";
import ContentFilterReport from "./components/reportAdmon/FilterReport";
import ProfileCreate from "./containers/form_profile";
import UsersCreate from "./containers/form_users";

/**
 * TABLE
 */
import CompaniesGrid from "./components/table/companies_table";
import EmployeesGrid from "./components/table/employees_table";
import EmployeesGridAssoc from "./components/tableProjectAssociated/table_employees_assoc";
import RefereesGrid from "./components/table/referees_table";
import ConsentsGrid from "./components/table/consents_table";
import ListMasterGrid from "./components/table/listmaster_table";
import ListGrid from "./components/table/list_table";
import SurveysGrid from "./components/table/surveys_table";
import FaqGrid from "./components/table/faq_table";
import FaqList from "./components/table/faq_list";
import SectionsGrid from "./components/table/sections_table";
import ProfilesGrid from "./components/table/profile_table";
import QuestionsGrid from "./components/table/questions_table";
import UsersGrid from "./containers/users/users_table";
import SendEmailMasive from "./components/SendEmailMasive/componentSendEmail";
import SendEmailMasiveFilter from "./components/SendEmailMasive/componentSendEmailFilter";
import TableTemplateEmails from "./components/table/templateemail_table";
import TableReportEmployeeReferee from "./components/reportEmployee/reportEmployeeTable";

/**
 * INFORMATION
 */
import CompaniesInfo from "./components/info/companies_info";
import companiesInfoProject from "./components/info/companies_info_project";
import EmployeesInfo from "./components/info/employees_info";
import RefereesInfo from "./components/info/referees_info";
import ConsentsInfo from "./components/info/consents_info";
import ListMasterInfo from "./components/info/listmaster_info";
import ListInfo from "./components/info/list_info";
import SurveysInfo from "./components/info/surveys_info";
import SurveysInfoSections from "./components/info/surveys_sections_info";
import SurveysInfoSectionsMetrical from "./components/info/surveys_sections_metrical";
import Survey from "./components/survey/surveyAppl";
import SurveyGlance from "./components/survey/surveyApplGlance";
import AcceptConsent from "./containers/accept_consents";
import SectionsInfo from "./components/info/sections_info";
import ProjectsInfo from "./components/info/projects_info";
import messagesCard from "./containers/chat/messagesPaper";
import AdminDashboard from "./components/dashboards/admin/componentAdmin";
import EmployeeDashboard from "./components/dashboards/employee/component";
import CompanyDashboard from "./components/dashboards/company/dashboard";
import ArlDashboard from "./components/dashboards/arl/dashboard";
import MenuDashboard from "./components/dashboards/appBar";
import WarningMessage from "./components/fields/Messages/Warning";
import UserSearch from "./containers/users/addUserComponent/Component";
import TableConfigGroupers from "./components/groupersConfiguration/tableConfigGroupers";
import AuditView from "./components/Audit/componentViewAudit";

/**
 * DASHBOARDS
 */
import advanceProjectSurvey from "./components/dashboards/referee/advanceProjectSurvey";
import advanceProjectSurveyCompany from "./components/dashboards/company/advanceProjectSurvey";
import ProjectsEmployee from "./components/dashboards/employee/ProjectsEmployee";
import ProjectsCompany from "./components/dashboards/company/projectsComponent";
import CompaniesArl from "./components/dashboards/arl/companiesComponent";
import ProjectsReferee from "./components/dashboards/referee/projectsComponent";
import surveyResults from "./components/surveyResults/componentResults";
import componentResultsReferee from "./components/surveyResultsReferee/componentResults";
import SarinDashboard from "./components/sarin/ProjectComponent";
import MetadataDashboard from "./components/metadataEditor/MetadataEditorComponent";

import surveyResultsMetadata from "./components/surveyResults/dashboard/dashboardResults";
import surveyEditorMetadata from "./components/surveyMetadataEditor/editorComponent";
import SurveyResultsExcelGenerator from "./components/SurveyResultsExcelGenerator/excelGeneratorComponent";
import ChatDashboard from "./components/chat/evaluator/preRoom";
import AnalyticsConfigurationDashboard from "./components/analyticsReportConfiguration";

import RenderProjectPrint from "./components/reportAdmon/renderReportPrint";

export default (chatWebSocketManager) => (
    <div>
        <Redirect from="/" to="/login" />
        <Redirect from="/dashboard/referee" to="/dashboard/referee/projects" />
        <Redirect
            from="/dashboard/employee"
            to="/dashboard/employee/projects"
        />
        <Redirect from="/dashboard/company" to="/dashboard/company/projects" />
        <Redirect from="/dashboard/arl" to="/dashboard/arl/companies" />
        <Route path="/" component={App}>
            <Route path="/print">
                <Route
                    path="report/token/:token/company/:company/project/:project/survey/:survey/trace/:trace"
                    components={RenderProjectPrint}
                />
            </Route>
            <Route path="/admin" component={AdminDashboard}>
                <Route path="companies">
                    <Route path="create" components={CompaniesCreate} />
                    <Route path="edit/:_id" components={CompaniesCreate} />
                    <Route path="grid" components={CompaniesGrid} />
                    <Route path="info/:_id" components={CompaniesInfo} />
                    <Route
                        path="info/projects/:_id"
                        components={companiesInfoProject}
                    />
                </Route>
                <Route path="employees">
                    <Route
                        path="create/:profile/:_id"
                        components={EmployeesCreate}
                    />
                    <Route
                        path="edit/:profile/:company/:_id"
                        components={EmployeesCreate}
                    />
                    <Route
                        path="grid/:profile/:_id"
                        components={EmployeesGrid}
                    />
                    <Route path="info/:_id/:id" components={EmployeesInfo} />
                </Route>
                <Route path="surveys">
                    <Route path="create" components={SurveysCreate} />
                    <Route path="grid" components={SurveysGrid} />
                    <Route path="edit/:_id" components={SurveysCreate} />
                    <Route path="info/:_id" components={SurveysInfo} />
                    <Route
                        path="info/:profile/:_id/:project/:company"
                        components={SurveysInfoSections}
                    />
                    <Route
                        path="metrical/:survey"
                        components={SurveysInfoSectionsMetrical}
                    />
                    <Route
                        path="metrical/assign/:survey/:section"
                        components={MetricalSection}
                    />
                    <Route
                        path="appl/:profile/:company/:survey/:order/:project"
                        components={Survey}
                    />
                    <Route
                        path="appl/:survey/:order"
                        components={SurveyGlance}
                    />
                    <Route
                        path="groupers/:survey"
                        components={TableConfigGroupers}
                    />
                    <Route
                        path=":survey/metadata"
                        components={surveyEditorMetadata}
                    />
                    <Route
                        path="groupers/:survey/conditionals/:grouper"
                        components={GrouperQuestionConditionals}
                    />
                    <Route
                        path="groupers/create/:survey"
                        components={GroupersGroup}
                    />
                    <Route
                        path="groupers/questions/:survey"
                        components={GrouperQuestionSection}
                    />
                    <Route
                        path="export/applications"
                        components={SurveyResultsExcelGenerator}
                    />
                    <Route
                        path=":survey/analytics/config"
                        component={AnalyticsConfigurationDashboard}
                    />
                </Route>
                <Route path="consents">
                    <Route path="create" components={ConsentsCreate} />
                    <Route path="edit/:_id" components={ConsentsCreate} />
                    <Route path="grid" components={ConsentsGrid} />
                    <Route path="info/:_id" components={ConsentsInfo} />
                </Route>
                <Route path="lists">
                    <Route path="create" components={ListMasterCreate} />
                    <Route path="grid" components={ListMasterGrid} />
                    <Route path="edit/:_id" components={ListMasterCreate} />
                    <Route path="info/:_id" components={ListMasterInfo} />
                </Route>
                <Route path="questions">
                    <Route path="create" components={QuestionCreate} />
                    <Route path="grid" components={QuestionsGrid} />
                    <Route path="edit/:_id" components={QuestionCreate} />
                    <Route path="group">
                        <Route path="create" components={QuestionGroupCreate} />
                        <Route
                            path="edit/:_id"
                            components={QuestionGroupCreate}
                        />
                    </Route>
                </Route>
                <Route path="referees">
                    <Route path="create" components={RefereeCreate} />
                    <Route path="edit/:_id" components={RefereeCreate} />
                    <Route path="info/:_id/:id" components={RefereesInfo} />
                    <Route path="grid" components={RefereesGrid} />
                </Route>
                <Route path="projects">
                    <Route path="create/:_id" components={ProjectCreate} />
                    <Route
                        path="edit/:profile/:_id/:company"
                        components={ProjectEdit}
                    />
                    <Route
                        path="colors/:profile/:project/:company"
                        components={ProjectColors}
                    />
                    <Route
                        path="ranking/:profile/:project/:survey/:company"
                        components={ProjectRanking}
                    />
                    <Route
                        path="filter/:action/:profile/:project/:company"
                        components={ContentFilterReport}
                    />
                    <Route
                        path="edit_referees/:profile/:_id/:company"
                        components={ProjectRefereesCreate}
                    />
                    <Route
                        path="info/:profile/:_id/:company"
                        components={ProjectsInfo}
                    />
                    <Route
                        path="email/employees/:profile/:_id/:company"
                        components={SendEmailMasive}
                    />
                    <Route
                        path="associate/employees/:profile/:_id/:company"
                        components={EmployeesGridAssoc}
                    />
                    <Route
                        path="configDay/:profile/:_id/:company/:day"
                        components={ConfigProjectCreate}
                    />
                    <Route
                        path="advance/:profile/:company/:project"
                        components={advanceProjectSurvey}
                    />
                    <Route
                        path="report/:profile/:company/:project"
                        components={TableReportEmployeeReferee}
                    />
                    <Route
                        path="report/:profile/company/:company/project/:project/survey/:survey"
                        components={ContentFilterReport}
                    />
                    <Route
                        path="email/:profile/:company/:idProject"
                        components={SendEmailMasiveFilter}
                    />
                    <Route
                        path="employees/company/:profile/:company/project/:project/surveys/:survey/employee/:employee/results"
                        component={surveyResults}
                    />
                    <Route
                        path="employee/:profile/:employee/company/:company/project/:project/results"
                        component={componentResultsReferee}
                    />
                    <Route
                        path="results/:profile/:company/:surveyId(/:projectId)"
                        component={surveyResultsMetadata}
                    />
                    <Route
                        path="sarin/:profile/:company/:projectId"
                        component={SarinDashboard}
                    />
                    <Route
                        path="metadata/:companyId/:projectId"
                        component={MetadataDashboard}
                    />
                </Route>
                <Route path="sections">
                    <Route path="create/:_id" components={SectionCreate} />
                    <Route
                        path="edit/:_id/:section"
                        components={SectionCreate}
                    />
                    <Route path="grid" components={SectionsGrid} />
                    <Route path="info/:_id" components={SectionsInfo} />
                </Route>
                <Route path="faq">
                    <Route path="create" components={FaqCreate} />
                    <Route path="grid" components={FaqGrid} />
                    <Route path="edit/:_id" components={FaqCreate} />
                </Route>
                <Route path="locations">
                    <Route path="countries" components={AdminCountries} />
                    <Route
                        path="departments/:countryId"
                        components={AdminDepartments}
                    />
                    <Route
                        path="cities/:departmentId/:countryId"
                        components={AdminCities}
                    />
                </Route>
                <Route path="templateemail">
                    <Route path="create" components={FormTemplateEmails} />
                    <Route path="edit/:_id" components={FormTemplateEmails} />
                    <Route path="grid" components={TableTemplateEmails} />
                </Route>
                <Route path="profiles">
                    <Route path="grid" components={ProfilesGrid} />
                    <Route path="create" components={ProfileCreate} />
                    <Route path="edit/:profile" components={ProfileCreate} />
                </Route>
                <Route path="messages">
                    <Route path="dashboard" components={messagesCard} />
                </Route>
                <Route path="users">
                    <Route path="grid" components={UsersGrid} />
                    <Route path="create" components={UsersCreate} />
                    <Route path="edit/:user" components={UsersCreate} />
                    <Route path="search" components={UserSearch} />
                </Route>
                <Route path="me">
                    <Route path="assignprojects" components={ProjectsReferee} />
                    <Route path="projects" components={ProjectsEmployee} />
                </Route>
                <Route path="audit">
                    <Route path="view" components={AuditView} />
                </Route>
                <Route path="list/grid" components={ListGrid} />
                <Route path="list/info/:_id/:id" components={ListInfo} />
                <Route
                    path="chat"
                    components={(props) => (
                        <ChatDashboard
                            chatWebSocketManager={chatWebSocketManager}
                            {...props}
                        />
                    )}
                />
            </Route>
            <Route path="dashboard" component={MenuDashboard}>
                <Route path="employee" component={EmployeeDashboard}>
                    <Route path="projects" components={ProjectsEmployee} />
                    <Route
                        path="survey/appl/:survey/:order/:project"
                        components={(props) => (
                            <Survey
                                chatWebSocketManager={chatWebSocketManager}
                                {...props}
                            />
                        )}
                    />
                    <Route
                        path="project/appl/:profile/:project/:userId"
                        components={AcceptConsent}
                    />
                    <Route
                        path="projects/:profile/:project/surveys/:survey/employee/:employee/results"
                        component={surveyResults}
                    />
                </Route>
                <Route path="company" component={CompanyDashboard}>
                    <Route path="projects" components={ProjectsCompany} />
                    <Route
                        path="project/advance/:profile/:company/:project"
                        components={advanceProjectSurveyCompany}
                    />
                    <Route
                        path="project/results/:profile/:company/:surveyId/:projectId"
                        component={surveyResultsMetadata}
                    />
                    <Route
                        path="project/sarin/:profile/:company/:projectId"
                        component={SarinDashboard}
                    />
                    <Route
                        path="project/report/:profile/company/:company/project/:project/survey/:survey"
                        components={ContentFilterReport}
                    />
                </Route>
                <Route path="arl" component={ArlDashboard}>
                    <Route path="companies" components={CompaniesArl} />
                </Route>
            </Route>
            <Route path="faq" components={FaqList} />
            <Route path="message">
                <Route
                    path="warning/:survey/:sectionValid/:project"
                    components={WarningMessage}
                />
            </Route>
            <Route path="login" components={Login} />
            <Route path="forgot" components={ForgetPassword} />
        </Route>
    </div>
);
