import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { getListSections } from "../fields/ControlsSection/actions";
import { SaveSolutionSection } from "../survey/question/actions";
import { clearSection } from "../../actions/info/surveys_info_form";
import { toggleSnackbar } from "../../actions/commons";
import { blue700 } from "material-ui/styles/colors";
import { browserHistory } from "react-router";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";
import { toggleProject } from "../../components/dashboards/employee/actions";
import { LBLSURVEYS, idEmployeeTest } from "../../constants/labels";

const customContentStyle = {
    maxWidth: 600,
    minWidth: 300,
    textAlign: "justify",
};

class ButtonControlsSections extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            userId: "",
        };
        this._handleSection = this._handleSection.bind(this);
        this._handleCloseAction = this._handleCloseAction.bind(this);
        this._handleOpenAction = this._handleOpenAction.bind(this);
    }

    UNSAFE_componentWillMount() {
        const { idSurveys, getListSections, login, isAdmin } = this.props;
        if (idSurveys) {
            getListSections(idSurveys);
        }
        if (!isAdmin) {
            if (login.get("loggedIn")) {
                var profile = login.get("profile");
                this.setState({
                    userId: _.get(profile, "person._id"),
                });
            } else {
                this.setState({
                    userId: idEmployeeTest,
                });
                console.error("No hay un usuario logeado");
                browserHistory.replace(`/login`);
            }
        }
    }

    _handleCloseAction() {
        this.setState({
            open: false,
        });
    }

    _handleSection() {
        this.setState({
            open: false,
        });
        const {
            currentSection,
            idProjects,
            idSurveys,
            section,
            idCompany,
            toggleProject,
            SaveSolutionSection,
            sectionId,
            clearSection,
            adminApply,
        } = this.props;
        var entries = Array.from(section.get("answered").entries());
        var answers = [];
        entries.forEach((item, idx) => {
            answers.push({
                idQuestion: item[0],
                answer: item[1],
            });
        });
        let self = this;
        SaveSolutionSection(
            this.state.userId,
            idProjects,
            idCompany,
            currentSection,
            sectionId,
            idSurveys,
            answers
        )
            .then(
                (data) => {
                    if (
                        _.get(data, "error") ||
                        _.get(data, "payload.status") !== 200
                    ) {
                        if (
                            _.isEqual(
                                _.get(
                                    data,
                                    "payload.response.data.error.error",
                                    null
                                ),
                                "UserExistsSolutionSection"
                            )
                        ) {
                            self.props.toggleSnackbar(
                                true,
                                LBLSURVEYS.msg.errorSaveSectionExistsSolution
                            );
                        } else {
                            self.props.toggleSnackbar(
                                true,
                                LBLSURVEYS.msg.errorSaveSection
                            );
                        }
                    } else {
                        var nextSectionURL = _.get(
                            data,
                            "payload.data.nextUrl"
                        );
                        if (
                            _.isEqual(
                                nextSectionURL,
                                "/dashboard/employee/projects"
                            )
                        ) {
                            if (adminApply) {
                                nextSectionURL = "/admin/me/projects";
                            }
                            toggleProject(idProjects, true);
                        }
                        clearSection();
                        browserHistory.replace(nextSectionURL);
                    }
                },
                (reason) => {
                    console.log("reason", reason);
                    self.props.toggleSnackbar(
                        true,
                        LBLSURVEYS.msg.errorSaveSection
                    );
                }
            )
            .catch((data) => {
                console.log("data", data);
                self.props.toggleSnackbar(
                    true,
                    LBLSURVEYS.msg.errorSaveSection
                );
            });
    }

    _handleOpenAction() {
        this.setState({
            open: true,
        });
    }

    render() {
        const {
            currentSection,
            arraySections,
            validSection,
            isAdmin,
        } = this.props;
        let nameButton = "...";
        let message = "...";
        if (_.size(arraySections) > 0) {
            if (_.size(arraySections) === currentSection) {
                nameButton = LBLSURVEYS.buttons.finilize;
                message = LBLSURVEYS.msg.confirmSectionFinalize;
            } else {
                nameButton = LBLSURVEYS.buttons.next;
                message = LBLSURVEYS.msg.confirmSectionNext;
            }
        }
        const actions = [
            <FlatButton
                label={LBLSURVEYS.buttons.cancel}
                secondary={true}
                onClick={this._handleCloseAction}
            />,
            <FlatButton
                label={LBLSURVEYS.buttons.acept}
                primary={true}
                keyboardFocused={true}
                onClick={this._handleSection}
            />,
        ];
        return (
            <span>
                <RaisedButton
                    label={nameButton}
                    secondary={true}
                    style={
                        isAdmin
                            ? { display: "none" }
                            : { display: "table", margin: "0 auto" }
                    }
                    disabled={!validSection}
                    onClick={this._handleOpenAction}
                />
                <Dialog
                    title={LBLSURVEYS.confirm}
                    actions={actions}
                    modal={false}
                    titleStyle={{ textAlign: "center", color: blue700 }}
                    contentStyle={customContentStyle}
                    autoScrollBodyContent={true}
                    open={this.state.open}
                    onRequestClose={this._handleCloseAction}
                >
                    <h3 style={{ fontWeight: 200, textAlign: "justify" }}>
                        {message}
                    </h3>
                    <h3 style={{ fontWeight: 200, textAlign: "justify" }}>
                        {LBLSURVEYS.msg.rememberConfirm}
                    </h3>
                </Dialog>
            </span>
        );
    }
}

ButtonControlsSections.propTypes = {
    idSurveys: PropTypes.string.isRequired,
    idProjects: PropTypes.string,
    isAdmin: PropTypes.bool.isRequired,
    adminApply: PropTypes.bool.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getListSections,
            SaveSolutionSection,
            toggleSnackbar,
            toggleProject,
            clearSection,
        },
        dispatch
    );
}

function mapStateToProps(
    { controlsections, questionsSurvey, login, surveys },
    ownerProps
) {
    return {
        sectionId: _.get(surveys, "survey.data._id"),
        idCompany: _.get(surveys, "survey.data.project.company._id"),
        currentSection: _.get(surveys, "survey.data.order"),
        arraySections: _.get(controlsections.get("values"), "data", []),
        section: questionsSurvey || [],
        validSection: questionsSurvey.get("valid"),
        login,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ButtonControlsSections);
