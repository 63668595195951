import * as actions from "./constants";
import Immutable from "immutable";
import _ from "lodash";

const INITIAL_STATE = Immutable.Map({
    idResult: "",
    freq: [],
    sum: [],
    notEval: [],
    comment: "",
});

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.LOAD_RESULTS:
            var results = _.get(action, "payload.data", {});
            var noEvalQuestions = _.orderBy(
                _.get(results, "questions", []),
                ["section", "sectionOrder"],
                ["asc", "asc"],
            );
            var comment = _.get(results, "comment", "");
            var solutionId = _.get(results, "solutionId", "");
            var sumQuestions = _.chain(results)
                .get("tree", {})
                .value();
            var freqQuestions = _.chain(results)
                .get("results")
                .filter(item => {
                    const type = _.get(item, "type");
                    return _.isEqual(type, "freq");
                })
                .map(item => {
                    const results = _.chain(item)
                        .get("result")
                        .reduce((list, value, key) => {
                            list.push({ key, value });
                            return list;
                        }, [])
                        .value();
                    return _.set(item, "result", results);
                })
                .value();
            return state.withMutations(map => {
                map.set("solutionId", solutionId);
                map.set("freq", freqQuestions);
                map.set("sum", sumQuestions);
                map.set("notEval", noEvalQuestions);
                map.set("raw", results);
                map.set("comment", comment);
            });
        case actions.SAVE_COMMENT_RESULT:
            return state;
        case action.CLEAN_RESULTS_SURVEY:
            return INITIAL_STATE;
        default:
            return state;
    }
};
