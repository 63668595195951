import Immutable from 'immutable';
import * as actions from './constants';
import _ from 'lodash';

const initialState = Immutable.Map({
    projects: Immutable.List(),
    referee: {}
});

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.LOAD_REFEREE_PROJECTS:
            var projectsResponse = _.get(action, 'payload.data', []);
            var projectsList = Immutable.List(projectsResponse);
            return state.set('projects', projectsList);
        case actions.LOAD_REFEREE_INFO:
            var refereeInfo = _.get(action, 'payload.data', {});
            return state.set('referee', refereeInfo);
        case actions.CLEAN_DASHBOARD_REFEREE_PROJECTS:
            return state.set('projects', Immutable.List());
        default:
            return state;
    }
}
