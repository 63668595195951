import PropTypes from "prop-types";
import React, { Component } from "react";
import _ from "lodash";

class TextComponent extends Component {
    static propTypes = {
        text: PropTypes.string.isRequired,
    };
    render() {
        const { text } = this.props;
        const html = !_.isUndefined(text) && !_.isNull(text) ? text : "<span></span>";
        const renderHtml = `<div style='border:1px solid #c7c7c7;width:0px;height:22px;position:absolute;margin: 13px 0px 0px -7px;'></div>${html}`;
        return (
            <div
                style={{ textAlign: "justify", alignContent: "stretch", padding: 5, boxSizing: "border-box" }}
                dangerouslySetInnerHTML={{ __html: renderHtml }}
            />
        );
    }
}

export default TextComponent;
