import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { red700 } from 'material-ui/styles/colors';
import IconButton from 'material-ui/IconButton';
import VisibilityIcon from 'material-ui/svg-icons/action/visibility';
import _ from 'lodash';

function getNumberQuestionGroup(text) {
    let arrayText = text.split("");
    let order = "";
    for (let i = _.size(arrayText) -1; i >= 0; i--){
        if(_.isEqual("#", arrayText[i]+"")){
            break;
        } else {
            order = arrayText[i] + order;
        }
    }
    return order;
}

class MissingQuestions extends Component {

    constructor(props) {
        super(props);
        this.changeVisibility = this.changeVisibility.bind(this);
        this.state = {
            visible: false
        };
    }

    changeVisibility(){
        this.setState({ visible: !this.state.visible});
    }

    render() {
        const {
            isAdmin,
            valid,
            answered,
            questions,
            questionsGroup
        } = this.props;
        if(!valid && !isAdmin){
            let countQuestions = 0;
            const arrayQuestions = !this.state.visible  ? [] : _.chain(questions)
                                                                .flatMap( question => {
                                                                    if(_.isEqual(_.get(question, 'type'), 'GroupList') || _.isEqual(_.get(question, 'type'), 'Group')){
                                                                        let qg = [];
                                                                        _.map(questionsGroup, qGroup => {
                                                                            if(_.isEqual(_.get(qGroup, 'id'), _.has(question, '_id._id') ? _.get(question, '_id._id') : _.get(question, '_id'))){
                                                                                let dataQuestionsGroup = _.get(qGroup, 'data.data');
                                                                                if(_.isEqual(_.get(question, 'type'), 'GroupList')){
                                                                                    dataQuestionsGroup = _.map(dataQuestionsGroup, question => {
                                                                                        let num = getNumberQuestionGroup(question.fieldName);
                                                                                        return _.set(question, 'key', _.isEqual(num, "") ? 0 : parseInt(num));
                                                                                    });
                                                                                } else {
                                                                                    dataQuestionsGroup = _.map(dataQuestionsGroup, question => {
                                                                                        let num = getNumberQuestionGroup(question.title);
                                                                                        return _.set(question, 'key', _.isEqual(num, "") ? 0 : parseInt(num));
                                                                                    });
                                                                                }
                                                                                _.map(_.orderBy(dataQuestionsGroup, 'key', 'asc'), q => {
                                                                                    countQuestions++;
                                                                                    qg.push({order: countQuestions, question: q});
                                                                                });
                                                                            }
                                                                        });
                                                                        return qg;
                                                                    } else {
                                                                        countQuestions++;
                                                                        return [{order: countQuestions, question: _.has(question, '_id._id') ? _.get(question, '_id') : question}];
                                                                    }
                                                                })
                                                                .filter( question => { return !_.has(answered, _.get(question, 'question._id')) })
                                                                .map( question => { return `${_.get(question, 'order', '')}. ${_.get(question, 'question.title', "")}` })
                                                                .value();
            return(
                <div style={{paddingLeft: 20, paddingRight: 20}}>
                    <table style={{width: '100%'}}>
                        <tbody>
                            <tr>
                                <td style={{textAlign: 'center'}}>
                                    <h3 style={{paddingTop: 10, fontWeight: 200, textAlign: 'center', color: red700}}>{`Por favor responda todas las preguntas para poder continuar`}</h3>
                                    <h2 style={{display: 'inline-block', paddingTop: 10, fontWeight: 200, textAlign: 'center'}}>{`Ver preguntas pendientes`}</h2>
                                    <IconButton
                                        tooltip={`Ver`}
                                        tooltipPosition="top-center"
                                        style={{marginLeft: 7}}
                                        onClick={this.changeVisibility}
                                        children={<VisibilityIcon />}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h3 style={{fontWeight: 200, marginBottom: 0, marginTop: 0}}>
                                        <ul>
                                            {_.map(arrayQuestions, ( question, index ) =>
                                                <li key={`missingQ${index}`}>{question}</li>
                                            )}
                                        </ul>
                                    </h3>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return (
                <div></div>
            );
        }
    }
}

MissingQuestions.propTypes = {
    isAdmin: PropTypes.bool.isRequired
};

function mapStateToProps({questionsSurvey, questions}) {
    return {
        valid: questionsSurvey.get('valid'),
        answered: questionsSurvey.get('answered').toObject(),
        questions: questionsSurvey.get('questions').toArray(),
        questionsSize: questionsSurvey.get('questionsSize'),
        questionsGroup: questions.surveyquestionsgroup
    };
}

export default connect(mapStateToProps)(MissingQuestions);
