import { useEffect } from "react";
import _ from "lodash";

export function useCleanUpOnUnmount({ fieldProps, pristineValue = "" }) {
    if (_.isNil(fieldProps) || !_.isFunction(fieldProps.setValue)) {
        throw new Error("Invalid or null fieldProps");
    }
    useEffect(() => {
        return () => {
            fieldProps.setValue(pristineValue);
            fieldProps.setTouched(false);
        };
    }, []);
}
