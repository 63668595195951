import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {reduxForm, addArrayValue} from 'redux-form';
import {Row, Col} from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import {createReferee} from '../actions/referees_form';
import {FetchRefereeParam,getDocumentsTypes,getStudyLevels} from '../actions/info/referees_info_form';
import {getCompaniesName} from '../actions/grid/companies_grid_form';
import MenuItem from 'material-ui/MenuItem';
import { axiosAPI } from '../middleware/api';
import {EXPRESSION_IDENTIFICACION, EXPRESSION_TELEPHONE} from '../constants/regularExpressions';
import _ from 'lodash';
import DatePickerFormat from '../components/fields/DatePickerFormat/DatePickerFormat';
import {toggleSnackbar} from '../actions/commons';
import {browserHistory} from 'react-router';
import {LBLEVALUATORS} from '../constants/labels';
import ButtonBack from '../components/fields/buttonBack/buttonBack';
import SelectFieldFormat from '../components/fields/SelectFieldFormat/SelectFieldFormat';
import AuthComponent from '../components/AuthComponent';
import Checkbox from 'material-ui/Checkbox';
import CircularProgress from 'material-ui/CircularProgress';
import is from 'is_js';

const style = {
        margin: 20,
        float: 'right'
    },
    stylePaper = {
        marginTop: 20,
        marginBottom: 20
    };

function errorFieldValidation(field) {
    return field.touched && field.error ? field.error : '';
}

class RefereesNews extends Component {

    constructor(props) {
        super(props);
        this.state={
          chekedSendEmail: true
        }
        this._saveReferees = this._saveReferees.bind(this);
        this._onCheckSendEmail = this._onCheckSendEmail.bind(this);
    }

    _saveReferees(formData) {
        const {
            createReferee,
            toggleSnackbar,
            resetForm,
            params
        } = this.props;
        const editMode = _.has(params, '_id');
        createReferee(formData, editMode, editMode ? false : this.state.chekedSendEmail)
            .then((data) => {
                if (_.get(data, 'error') || (_.get(data, 'payload.status') !== 200)) {
                    toggleSnackbar(true, "Hubo un error al guardar");
                } else {
                    resetForm();
                    toggleSnackbar(true, "Evaluador guardado correctamente.");
                    browserHistory.push(`/admin/referees/grid`);
                }
            }, (reason) => {
                toggleSnackbar(true, "Hubo un error al guardar");
            })
            .catch((data) => {
                toggleSnackbar(true, "Hubo un error al guardar");
            });
    }

    UNSAFE_componentWillMount() {
        const {
            FetchRefereeParam,
            getCompaniesName,
            getDocumentsTypes,
            getStudyLevels
        } = this.props;
        getCompaniesName();
        getDocumentsTypes();
        getStudyLevels();
        if (_.has(this, 'props.params._id')) {
            FetchRefereeParam(this.props.params._id);
        }
    }

    _onCheckSendEmail(e, cheked){
        this.state ={
          chekedSendEmail: cheked
        };
    }

    render() {
        const {
            asyncValidating,
            asyncValidatingEmail,
            fields: {
                documentType,
                id,
                _id,
                name,
                email,
                lastName,
                profession,
                company,
                educationLevel,
                professionalCard,
                healthLicense,
                expeditionDateHealthLicensed,
                workingProcess
            },
            handleSubmit,
            resetForm,
            params,
            studyLevels
        } = this.props;
        const editMode = _.has(params, '_id');
        return (
            <Row>
                <Col xsOffset={2} xs={8}>
                    <Paper style={stylePaper}>
                        <Row>
                            <Col xsOffset={2} xs={8}>
                                <ButtonBack url={`/admin/referees/grid`}/>
                                <h1 style={{textAlign: 'center', fontWeight: 400}}>{editMode ? LBLEVALUATORS.titleEdit : LBLEVALUATORS.titleCreate} </h1>
                                <form onSubmit={handleSubmit(this._saveReferees)}>
                                    <Row>
                                        <Col xs>
                                            <SelectFieldFormat
                                                config={{
                                                    floatingLabelText: LBLEVALUATORS.typeId,
                                                    value: documentType.value,
                                                    disabled: editMode,
                                                    errorText: documentType.touched && documentType.error ? documentType.error : '',
                                                    onChange: (e, index, value) => documentType.onChange(value)
                                                }}
                                            >
                                                {
                                                    this.props.documentsTypes.map((item) => {
                                                        return (
                                                            <MenuItem key={item._id} value={item._id} primaryText={item.value}/>
                                                        );
                                                    })
                                                }
                                            </SelectFieldFormat>
                                        </Col>
                                        <Col xs>
                                            <TextField
                                                hintText=""
                                                floatingLabelText={LBLEVALUATORS.id}
                                                type="text"
                                                disabled={editMode}
                                                errorText={id.touched && id.error ? id.error : '' }
                                                {...id}
                                            />
                                            {asyncValidating === 'id' && <CircularProgress size={25} thickness={2} style={{ position: 'absolute', float: 'right', marginTop: 35 }}/>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs>
                                            <TextField
                                                hintText=""
                                                floatingLabelText={LBLEVALUATORS.name}
                                                type="text"
                                                errorText={name.touched && name.error ? name.error : '' }
                                                {...name}
                                            />
                                        </Col>
                                        <Col xs>
                                            <TextField
                                                hintText=""
                                                floatingLabelText={LBLEVALUATORS.lastNames}
                                                type="text"
                                                errorText={lastName.touched && lastName.error ? lastName.error : '' }
                                                {...lastName}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs>
                                            <TextField
                                                hintText=""
                                                floatingLabelText={LBLEVALUATORS.profession}
                                                type="text"
                                                errorText={profession.touched && profession.error ? profession.error : '' }
                                                {...profession}
                                            />
                                        </Col>
                                        <Col xs>
                                            <TextField
                                                hintText=""
                                                floatingLabelText={LBLEVALUATORS.cardProfessional}
                                                type="text"
                                                errorText={professionalCard.touched && professionalCard.error ? professionalCard.error : '' }
                                                {...professionalCard}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs>
                                            <TextField
                                                hintText=""
                                                floatingLabelText={LBLEVALUATORS.licensedOccupationalHealth}
                                                type="text"
                                                errorText={healthLicense.touched && healthLicense.error ? healthLicense.error : '' }
                                                {...healthLicense}
                                            />
                                        </Col>
                                        <Col xs>
                                            <DatePickerFormat
                                                datePickerConfig={{
                                                  floatingLabelText:LBLEVALUATORS.licenseIssuanceDate,
                                                  hintText:LBLEVALUATORS.licenseIssuanceDate,
                                                  mode:"landscape",
                                                  errorText: errorFieldValidation(expeditionDateHealthLicensed),
                                                  value: expeditionDateHealthLicensed.value,
                                                  onChange:(e, value) => expeditionDateHealthLicensed.onChange(value),
                                                  style: {marginTop: 0, marginBottom: 23}
                                              }}
                                                stylefloatingLabelText={{marginTop: 16, marginBottom: -12}}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs>
                                            <SelectFieldFormat
                                                config={{
                                                    floatingLabelText: LBLEVALUATORS.levelStudy,
                                                    value: educationLevel.value,
                                                    errorText: educationLevel.touched && educationLevel.error ? educationLevel.error : '',
                                                    onChange: (e, index, value) => educationLevel.onChange(value)
                                                }}
                                            >
                                                {
                                                    _.map(studyLevels, (item, index) => {
                                                        return (
                                                            <MenuItem key={index} value={item.value} primaryText={item.value}/>
                                                        );
                                                    })
                                                }
                                            </SelectFieldFormat>
                                        </Col>
                                        <Col xs>
                                            <TextField
                                                hintText=""
                                                floatingLabelText={LBLEVALUATORS.email}
                                                type="email"
                                                errorText={email.touched && email.error ? email.error : '' }
                                                {...email}
                                            />
                                            {asyncValidatingEmail === 'email' && <i /* spinning cog *//>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs>
                                            <SelectFieldFormat
                                                config={{
                                                    floatingLabelText: LBLEVALUATORS.company,
                                                    value: company.value,
                                                    autoWidth: true,
                                                    fullWidth: true,
                                                    errorText: company.touched && company.error ? company.error : '',
                                                    onChange: (e, index, value) => company.onChange(value)
                                                }}
                                            >
                                                {
                                                    _.orderBy(this.props.companies, ['businessName']).map((item) => {
                                                        return (
                                                            <MenuItem key={item._id} value={item._id} primaryText={item.businessName}/>
                                                        );
                                                    })
                                                }
                                            </SelectFieldFormat>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs>
                                            <TextField
                                                hintText=""
                                                floatingLabelText={LBLEVALUATORS.process}
                                                type="text"
                                                fullWidth={true}
                                                errorText={workingProcess.touched && workingProcess.error ? workingProcess.error : '' }
                                                {...workingProcess}
                                            />
                                        </Col>
                                    </Row>
                                    {!editMode &&
                                    <Row>
                                      <Col>
                                      <Checkbox
                                             label="Enviar notificación de creación"
                                             defaultChecked={true}
                                             style={{padding:20}}
                                             onCheck={this._onCheckSendEmail}
                                           />
                                      </Col>
                                    </Row>
                                  }
                                    <AuthComponent
                                        component={<RaisedButton
                                                        type="submit"
                                                        label={editMode ? LBLEVALUATORS.buttons.update : LBLEVALUATORS.buttons.save}
                                                        secondary={true}
                                                        style={style}
                                                    />}
                                        permission={editMode ? "cgr_referees_update" : "cgr_referees_create"}
                                        type={"component"}
                                    />
                                </form>
                            </Col>
                        </Row>
                    </Paper>
                </Col>
            </Row>
        );
    }
}


function getStudylevels() {
    return LBLEVALUATORS.arrayLevelsStudys.map(value=> {
        return <MenuItem value={value} primaryText={value}/>;
    });
}
const validate = (values) => {
    const errors = {};
    if (!values.documentType) {
        errors.documentType = LBLEVALUATORS.validations.typeId;
    }
    if (!values.id) {
        errors.id = LBLEVALUATORS.validations.id;
    }
    //else {
    //    if(!EXPRESSION_IDENTIFICACION.test(values.id)){
    //        errors.id = "Ingrese un número de identificación válido";
    //    }
    //}
    if (!values.name) {
        errors.name = LBLEVALUATORS.validations.name;
    }
    if (!values.company) {
        errors.company = LBLEVALUATORS.validations.company;
    }
    if (!values.lastName) {
        errors.lastName = LBLEVALUATORS.validations.lastName;
    }
    if (!values.educationLevel) {
        errors.educationLevel = LBLEVALUATORS.validations.levelStudy;
    }
    if (!values.profession) {
        errors.profession = LBLEVALUATORS.validations.profession;
    }
    if (!values.professionalCard) {
        errors.professionalCard = LBLEVALUATORS.validations.cardProfessional;
    }
    //else {
    //    if(!EXPRESSION_IDENTIFICACION.test(values.professionalCard)){
    //        errors.professionalCard = LBLEVALUATORS.validations."Ingrese un número de tarjeta profesional válido";
    //    }
    //}
    if (!values.healthLicense) {
        errors.healthLicense = LBLEVALUATORS.validations.licensedOccupationalHealth;
    }
    //else {
    //    if(!EXPRESSION_IDENTIFICACION.test(values.healthLicense)){
    //        errors.healthLicense = "Ingrese un número de licencia salud ocupacional válido";
    //    }
    //}
    if (!values.email) {
        errors.email = LBLEVALUATORS.validations.email;
    } else {
        if (!is.email(values.email)) {
            errors.email = LBLEVALUATORS.validations.emailValid;
        }
    }
    if(!values.workingProcess){
        errors.workingProcess = LBLEVALUATORS.validations.process;
    }
    if (!values.expeditionDateHealthLicensed) {
        errors.expeditionDateHealthLicensed = LBLEVALUATORS.validations.licenseIssuanceDate;
    }
    return errors;
};

const asyncValidate = (values/*, dispatch */) => {
    return new Promise((resolve, reject) => {
        if (values.id) {
            axiosAPI.get(`/referees/id`, {
                    params: {
                        id: values.id
                    }
                })
                .then((data) => {
                    let resp = _.get(data.data, 'data');
                    if (!_.isNull(resp)) {
                        if (_.isNull(values._id)) {
                            reject({id: LBLEVALUATORS.validations.idExist});
                        } else {
                            resolve();
                        }
                    } else {
                        resolve();
                    }
                })
                .catch((data) => {
                    console.log('Error', data);
                });
        }

        /*if(values.email){
         axios.get('http://localhost:3030/api/v1/persons/id/email', {
         params: {
         email: values.email
         }
         })
         .then((dataEmail) => {
         let respuesta = _.get(dataEmail.data, 'data');
         if(!_.isNull(respuesta)){
         reject({email: 'Este correo electrónico se encuentra asociado a otra persona'});
         } else {
         resolve();
         }
         })
         .catch((data) => {
         console.log('Error', data);
         });//51451451
         }*/

    });
};

function mapStateToProps({referees, companies, master}, ownProps) {
    if (_.has(ownProps, 'params._id') && _.has(referees, 'referee.profession')) {
        var data = _.get(referees, 'referee');
        return {
            companies: companies.all,
            initialValues: {
                documentType: _.get(data, 'person.documentType'),
                id: _.get(data, 'person.id'),
                _id: _.get(ownProps.params, '_id'),
                name: _.get(data, 'person.name'),
                lastName: _.get(data, 'person.lastName'),
                profession: _.get(data, 'profession'),
                email: _.get(data, 'person.email'),
                company: _.get(data, 'company'),
                educationLevel: _.get(data, 'educationLevel'),
                professionalCard: _.get(data, 'professionalCard'),
                healthLicense: _.get(data, 'healthLicense'),
                expeditionDateHealthLicensed: new Date(_.get(data, 'expeditionDateHealthLicensed')),
                workingProcess: _.get(data, 'workingProcess')
            },
            studyLevels: master.studylevels,
            documentsTypes: master.documentsTypes
        };
    } else {
        return {
            companies: companies.all,
            initialValues: {
                documentType: '',
                id: '',
                _id: null,
                name: '',
                lastName: '',
                profession: '',
                educationLevel: '',
                professionalCard: '',
                company: '',
                healthLicense: '',
                expeditionDateHealthLicensed: '',
                workingProcess: ''
            },
            studyLevels: master.studylevels,
            documentsTypes: master.documentsTypes
        };
    }
}

RefereesNews.propTypes = {
    asyncValidating: PropTypes.string.isRequired,
    asyncValidatingEmail: PropTypes.string.isRequired,
    router: PropTypes.object
};

export default reduxForm({
    form: 'RefereesNewForm',
    fields: [
        'documentType',
        'id',
        '_id',
        'name',
        'email',
        'lastName',
        'profession',
        'educationLevel',
        'professionalCard',
        'company',
        'healthLicense',
        'expeditionDateHealthLicensed',
        'workingProcess'
    ],
    asyncValidate,
    asyncBlurFields: ['id', 'email'],
    validate
}, mapStateToProps, {createReferee, FetchRefereeParam, getCompaniesName, toggleSnackbar,getDocumentsTypes,getStudyLevels})(RefereesNews);
