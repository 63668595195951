import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import { connect } from "react-redux";
import { getAdvanceProjectSurvey } from "../../actions/projects_form";
import { toggleSnackbar } from "../../actions/commons";
import RaisedButton from "material-ui/RaisedButton";
import { blue700, blue300 } from "material-ui/styles/colors";
import LoadingPage from "../../components/commons/loadingPage";
import AuthComponent from "../../components/AuthComponent";
import TextField from "material-ui/TextField";
import TablePagination from "../TablePagination/tablePagination";
import ButtonBack from "../fields/buttonBack/buttonBack";
import TagsEmployee from "../TagsEmployee/TagsEmployee";
import _ from "lodash";
import is from "is_js";
import {
    requestReports,
    requestReportsReset,
    REPORT_EMPLOYEE_SUBMIT_CLEAN,
    REPORT_EMPLOYEE_SUBMIT_FULFILLED,
    REPORT_EMPLOYEE_SUBMIT_REJECTED,
    REPORT_EMPLOYEE_SUBMIT_IN_PROGRESS,
} from "./reportEmployeeDucks";
import EmployeesProcessed from "../employeesProcessed/employeesProcessed";

const stylePaper = {
    marginTop: 20,
    marginBottom: 20,
};

const customContentStyle = {
    maxWidth: 600,
    minWidth: 300,
    textAlign: "justify",
};

class ReportEmployeesReferee extends Component {
    constructor(props) {
        super(props);
        this._onChangeEmail = this._onChangeEmail.bind(this);
        this._handleReportRefereeComplete = this._handleReportRefereeComplete.bind(
            this
        );
        this.state = {
            waiting: false,
            email: "",
            emailError: "",
        };
    }

    UNSAFE_componentWillMount() {
        const { getAdvanceProjectSurvey, params } = this.props;
        getAdvanceProjectSurvey(_.get(params, "project"));
    }

    _onChangeEmail(text) {
        if (
            !_.isEqual(text, "") &&
            !_.isNull(text, "") &&
            !_.isUndefined(text, "")
        ) {
            if (!is.email(text)) {
                this.setState({
                    email: text,
                    emailError: "El correo electrónico no es válido",
                });
            } else {
                this.setState({
                    email: text,
                    emailError: "",
                });
            }
        } else {
            this.setState({
                email: text,
                emailError: "El correo electrónico no debe estar vació",
            });
        }
    }

    _handleReportRefereeComplete() {
        const { params, selected, requestReports } = this.props;
        const report = {
            projectId: _.get(params, "project"),
            email: this.state.email,
            employeesId: selected,
        };
        requestReports(_.get(params, "project"), selected, this.state.email);
    }

    UNSAFE_componentWillReceiveProps({ statusReportReferee }) {
        const { requestReportsReset, toggleSnackbar } = this.props;
        switch (statusReportReferee) {
            case REPORT_EMPLOYEE_SUBMIT_CLEAN:
                this.setState({ waiting: false });
                break;
            case REPORT_EMPLOYEE_SUBMIT_FULFILLED:
                this.setState({ waiting: false });
                toggleSnackbar(
                    true,
                    "Se han enviado los reportes correctamente."
                );
                requestReportsReset();
                break;
            case REPORT_EMPLOYEE_SUBMIT_REJECTED:
                this.setState({ waiting: false });
                toggleSnackbar(
                    true,
                    "Ha ocurrido un error en el envío de reportes."
                );
                requestReportsReset();
                break;
            case REPORT_EMPLOYEE_SUBMIT_IN_PROGRESS:
                this.setState({ waiting: true });
                break;
            default:
                return state;
        }
    }

    render() {
        const {
            projectSurveyId,
            textValueTag,
            textTag,
            selected,
            businessName,
            projectName,
            params,
            statusReportReferee,
        } = this.props;
        const urlBack = _.isEqual(_.get(params, "profile"), "adm")
            ? `/admin/companies/info/${_.get(params, "company")}`
            : `/admin/me/assignprojects`;

        const body = [
            {
                field: "id",
                title: "Identificación",
                filter: true,
            },
            {
                field: "name",
                title: "Nombre",
                filter: true,
            },
            {
                field: "lastName",
                title: "Apellido(s)",
                filter: true,
            },
            {
                field: "email",
                title: "Correo electrónico",
            },
        ];

        let extra = {
            project: _.get(params, "project"),
            projectSurveyId: projectSurveyId,
            status: "finished",
        };
        if (!_.isNull(textValueTag) && !_.isNull(textTag)) {
            extra = _.chain(extra)
                .set("textTag", textTag)
                .set("textValueTag", textValueTag)
                .value();
        }

        return (
            <Row>
                <AuthComponent
                    component={
                        <Col mdOffset={2} md={8} xs={12}>
                            <Paper style={stylePaper}>
                                <Row>
                                    <Col xs>
                                        <div
                                            style={{
                                                width: "100%",
                                                background: blue700,
                                                paddingTop: 5,
                                                lineHeight: 1.5,
                                                paddingBottom: 5,
                                                marginBottom: 20,
                                                color: "#FFF",
                                            }}
                                        >
                                            <h1
                                                style={{
                                                    textAlign: "center",
                                                    paddingLeft: 10,
                                                    paddingRight: 10,
                                                    lineHeight: 1.5,
                                                    fontWeight: 400,
                                                }}
                                            >
                                                {businessName}
                                            </h1>
                                        </div>
                                        <ButtonBack url={urlBack} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <h1
                                            style={{
                                                textAlign: "center",
                                                paddingLeft: 10,
                                                paddingRight: 10,
                                                lineHeight: 1.5,
                                                fontWeight: 400,
                                            }}
                                        >
                                            {projectName}
                                        </h1>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <EmployeesProcessed
                                            idProjects={[
                                                _.get(params, "project"),
                                            ]}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <TagsEmployee
                                            companyId={_.get(params, "company")}
                                            URL={`/projects/employees/status/${_.get(
                                                params,
                                                "company"
                                            )}/${_.get(params, "project")}`}
                                            version={2}
                                            extra={{
                                                company: _.get(
                                                    params,
                                                    "company"
                                                ),
                                                profile: _.get(
                                                    params,
                                                    "profile"
                                                ),
                                            }}
                                            filterStatus={false}
                                            fixedStatus={"finished"}
                                            body={body}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <TablePagination
                                            name={`configemployeesstatus`}
                                            URL={`/projects/employees/status/${_.get(
                                                params,
                                                "company"
                                            )}/${_.get(params, "project")}`}
                                            version={2}
                                            selection={true}
                                            fieldSelection={"_id"}
                                            body={body}
                                            extra={extra}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ padding: 40 }}>
                                        <TextField
                                            style={{
                                                margin: "10px  auto 0 auto",
                                            }}
                                            hintText="Correo electrónico a enviar reportes"
                                            floatingLabelText="Correo electrónico a enviar reportes"
                                            errorText={this.state.emailError}
                                            onChange={(e, text) =>
                                                this._onChangeEmail(text)
                                            }
                                            value={this.state.email}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <table
                                            style={{
                                                borderCollapse: "collapse",
                                                width: "100%",
                                            }}
                                        >
                                            <thead>
                                                <tr>
                                                    <td colSpan={2}>
                                                        <h4
                                                            style={{
                                                                textAlign:
                                                                    "center",
                                                                fontWeight: 400,
                                                                color: blue700,
                                                            }}
                                                        >
                                                            Personas a generar
                                                            reporte:{" "}
                                                            {_.size(selected)}
                                                        </h4>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td
                                                        style={{
                                                            padding: 15,
                                                            borderTop:
                                                                "1px solid #CCC",
                                                        }}
                                                    >
                                                        <h4
                                                            style={{
                                                                fontWeight: 400,
                                                                textAlign:
                                                                    "justify",
                                                            }}
                                                        >
                                                            Descargar el reporte
                                                            completo (lo visible
                                                            y lo no visible) de
                                                            uno o varios
                                                            colaboradores.
                                                        </h4>
                                                    </td>
                                                    <td
                                                        style={{
                                                            padding: 15,
                                                            width: 160,
                                                            borderTop:
                                                                "1px solid #CCC",
                                                        }}
                                                    >
                                                        <RaisedButton
                                                            label={
                                                                this.state
                                                                    .waiting
                                                                    ? "Enviando..."
                                                                    : "Enviar"
                                                            }
                                                            disabled={
                                                                !_.isEqual(
                                                                    this.state
                                                                        .emailError,
                                                                    ""
                                                                ) ||
                                                                _.isEqual(
                                                                    this.state
                                                                        .email,
                                                                    ""
                                                                ) ||
                                                                _.size(
                                                                    selected
                                                                ) === 0 ||
                                                                this.state
                                                                    .waiting ||
                                                                !_.isEqual(
                                                                    statusReportReferee,
                                                                    REPORT_EMPLOYEE_SUBMIT_CLEAN
                                                                )
                                                            }
                                                            secondary={true}
                                                            style={{
                                                                width: 150,
                                                                margin:
                                                                    "0 auto",
                                                            }}
                                                            onClick={
                                                                this
                                                                    ._handleReportRefereeComplete
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                                <LoadingPage visible={this.state.waiting} />
                            </Paper>
                        </Col>
                    }
                    permission={"cgr_results_view_referee"}
                    type={"url"}
                />
            </Row>
        );
    }
}

function mapStateToProps({
    projects,
    reportEmployee,
    tagsEmployee,
    tablePagination,
}) {
    return {
        businessName: _.get(projects, "project.data.company.businessName"),
        projectName: _.get(projects, "project.data.name"),
        projectSurveyId: _.get(projects, "project.data.survey._id"),
        textValueTag: tagsEmployee.get("textValueTag"),
        textTag: tagsEmployee.get("textTag"),
        selected: tablePagination.get("selected"),
        statusReportReferee: reportEmployee.get("statusReportReferee"),
    };
}

export default connect(mapStateToProps, {
    toggleSnackbar,
    getAdvanceProjectSurvey,
    requestReports,
    requestReportsReset,
})(ReportEmployeesReferee);
