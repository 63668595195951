import { GET_REFEREES_GRID } from '../actions/grid/referees_grid_form';
import { GET_REFEREE_INFO } from '../actions/info/referees_info_form';
import { DELETE_REFEREE } from '../constants/index';
import _ from 'lodash';

const INITIAL_STATE = { all: [], referee: null};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_REFEREES_GRID:
            return  _.assign({}, state, {
                all: action.payload.data
            });
        case GET_REFEREE_INFO:
            return  _.assign({}, state, {
                all: action.payload.data,
                referee: _.get(action, "payload.data.data",null)
            });
        case DELETE_REFEREE:
            var newAll = _.filter(state.all.data, item => {
                return !_.isEqual(item._id, action.meta.refereeId);
            });
            return _.assign({}, state, {
                all: {
                    data:newAll
                }
            });
        default:
            return state;
    }
}
