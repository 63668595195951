import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Row, Col} from 'react-flexbox-grid';
import {blue100} from 'material-ui/styles/colors';
import assign from 'lodash/assign';
import _ from 'lodash';

const styles = {
    messageMarginBasic: {
        marginTop: '12px',
    },
    message: {
        position: 'relative',
        paddingLeft: '7px',
        paddingRight: '7px',
    },
    messageContinuation: {
        marginTop: '5px'
    },
    messageIn: {
        backgroundColor: '#FFF',
        float: 'left',
    },
    messageOut: {
        backgroundColor: blue100,
        float: 'right'
    },
    messageBasic: {
        borderRadius: '7.5px',
        position: 'relative',
        boxShadow: '0 1px 0.5px rgba(0,0,0,0.13)'
    },
    messageChat: {
        maxWidth: '75%'
    },
    bubble: {
        paddingTop: 7,
        paddingRight: 10,
        paddingBottom: 7,
        paddingLeft: 10
    },
    messageText: {
        position: 'relative',
        fontSize: '13.6px',
        lineHeight: '19px',
        fontFamily: 'Roboto, sans-serif',
        wordWrap: 'break-word',
        color: '#262626'
    },
    selectableText: {
        userSelect: 'text',
        cursor: 'text',
        whiteSpace: 'pre-wrap'
    },
    messageSystemContainer: {
        textAlign: 'center',
        maxWidth: '100% !important',
    },
    messageSystem: {
        fontFamily: 'Roboto, sans-serif',
        display: 'inline-block',
        borderRadius: '7.5px',
        textShadow: '0 1px 0 rgba(255,255,255,0.4)',
        backgroundColor: 'rgba(255,245,196,0.95)',
        padding: '6px 12px 6px 12px',
        boxShadow: '0 1px 0.5px rgba(0,0,0,0.13)',
        color: 'rgba(0,0,0,0.95)',
        fontSize: '12.5px',
        lineHeight: '21px',
    },

};

class Message extends Component {

    static propTypes = {
        text: PropTypes.string.isRequired,
        dir: PropTypes.oneOf(['in', 'out']).isRequired,
        continuation: PropTypes.bool.isRequired,
        type: PropTypes.oneOf(['user', 'server']).isRequired,
        idElement: PropTypes.string.isRequired
    };

    render() {
        const {dir, text, continuation, type, idElement} = this.props;
        const {
            messageSystem,
            messageSystemContainer,
            message,
            messageChat,
            messageIn,
            messageOut,
            messageContinuation,
            messageBasic,
            bubble,
            messageText,
            selectableText,
            messageMarginBasic
        } = styles;
        const continuationStyle = continuation ? messageContinuation : messageMarginBasic;
        const messageStyleIn = dir === 'in' ? messageIn : {};
        const messageStyleOut = dir === 'out' ? messageOut : {};
        const messageStyleUser = type === 'user' ? _.assign({}, messageStyleIn, messageStyleOut) : {};
        const messageStyleServer = type === 'server' ? messageSystemContainer : {};
        const messageContainerStyle = type === 'user' ? _.assign({}, messageStyleUser, messageChat) : messageStyleServer;
        const messageContainerBox =  type === 'user' ? messageBasic : {};
        const innerMessageStyle = assign({}, messageContainerStyle, messageContainerBox);
        const bubbleStyle = type === 'user' ? bubble : {};
        const messageTextStyle = type === 'user' ? messageText : messageSystem;
        return (
            <div style={continuationStyle} id={idElement}>
                <Row>
                    <Col xs={12}>
                        <div style={message}>
                            <div style={innerMessageStyle}>
                                <div style={bubbleStyle}>
                                    <div style={messageTextStyle}>
                                        <span style={selectableText}>
                                            {text}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Message;
