import {GET_VALUES_LIST_SECTIONS, CLEAN_STATE_TREE_MAP} from './actions';
import Immutable from 'immutable';

const initialState = Immutable.Map({
    values: Immutable.List()
});

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_VALUES_LIST_SECTIONS:
            return state.set('values', action.payload.data);
        case CLEAN_STATE_TREE_MAP:
            return state.set(action.storeName, {data: null});
        default:
            return state;
    }
};
