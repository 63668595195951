const PIE = "PIE";
const PIE3D = "PIE3D";
const PIE_DONUT = "PIE_DONUT";
const PIE_DONUT3D = "PIE_DONUT3D";
const BAR_HORIZONTAL = "BAR_HORIZONTAL";
const BAR_HORIZONTAL3D = "BAR_HORIZONTAL3D";
const BAR_VERTICAL = "BAR_VERTICAL";
const BAR_VERTICAL3D = "BAR_VERTICAL3D";
const STACKED_BAR = "STACKED_BAR";
const STACKED_BAR3D = "STACKED_BAR3D";

const SMALL = "small";
const MIDDLE = "middle";
const LARGE = "large";

const ALIGN_CENTER = "center";
const ALIGN_LEFT = "left";
const ALIGN_RIGHT = "right";

const ORIENTATION_VERTICAL = "VERTICAL";
const ORIENTATION_HORIZONTAL = "HORIZONTAL";

export const chartTypes = {
    PIE,
    PIE3D,
    PIE_DONUT,
    PIE_DONUT3D,
    BAR_HORIZONTAL,
    BAR_HORIZONTAL3D,
    BAR_VERTICAL,
    BAR_VERTICAL3D,
    STACKED_BAR,
    STACKED_BAR3D,
};

export const arrayChart = [PIE, PIE3D, PIE_DONUT, PIE_DONUT3D, BAR_HORIZONTAL, BAR_VERTICAL, STACKED_BAR];

export const arraySizeChart = [SMALL, MIDDLE, LARGE];

export const arrayAlign = [ALIGN_LEFT, ALIGN_CENTER, ALIGN_RIGHT];

export const arrayOrientation = [ORIENTATION_VERTICAL, ORIENTATION_HORIZONTAL];

export const sizeTypes = {
    SMALL,
    MIDDLE,
    LARGE,
};

export const alignTypes = {
    ALIGN_LEFT,
    ALIGN_CENTER,
    ALIGN_RIGHT,
};

export const orientationTypes = {
    ORIENTATION_VERTICAL,
    ORIENTATION_HORIZONTAL,
};

export const arrayObjectChart = [
    { value: PIE, text: "Torta" },
    { value: PIE3D, text: "Torta 3D" },
    { value: PIE_DONUT, text: "Dona" },
    { value: PIE_DONUT3D, text: "Dona 3D" },
    { value: BAR_HORIZONTAL, text: "Barras horizontales" },
    { value: BAR_VERTICAL, text: "Barras verticales" },
    { value: STACKED_BAR, text: "Barras apiladas" },
];

export const arraySizeObjectChart = [
    { value: SMALL, text: "Pequeño" },
    { value: MIDDLE, text: "Mediano" },
    { value: LARGE, text: "Grande" },
];

export const arrayObjectAlign = [
    { value: ALIGN_LEFT, text: "Izquierda" },
    { value: ALIGN_CENTER, text: "Centro" },
    { value: ALIGN_RIGHT, text: "Derecha" },
];

export const arrayObjectOrientation = [
    { value: ORIENTATION_VERTICAL, text: "Vertical" },
    { value: ORIENTATION_HORIZONTAL, text: "Horizontal" },
];

export const colorState = { deserted: "#E34242", finished: "#58C257", started: "#EB9850", pending: "#EDE969" };
