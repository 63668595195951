import * as actions from "./question/constants";
import Immutable from "immutable";
import _ from "lodash";

const initialState = Immutable.Map({
    valid: false,
    answered: Immutable.Map(),
    questions: Immutable.List(),
    questionsSize: 0,
});

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_INITIAL_SECTION: {
            const questionsListFromService = _.get(action, "questions", []);
            const questionsList = Immutable.List(questionsListFromService);
            const defaultAnswers = _.filter(questionsListFromService, (item) =>
                _.has(item, "defaultValue._id")
            )
                .map((item) => {
                    const questionId = item._id;
                    const defaultAnswer = item.defaultValue._id;
                    return [questionId, defaultAnswer];
                })
                .reduce(
                    (acc, [question, answer]) => ({
                        ...acc,
                        [question]: answer,
                    }),
                    {}
                );
            const answers = Immutable.Map(defaultAnswers);
            const questionsSize = action.size;
            const isComplete = questionsSize === answers.size;
            return state.withMutations((map) => {
                map.set("questions", questionsList);
                map.set("answered", answers);
                map.set("valid", isComplete);
                map.set("questionsSize", action.size);
            });
        }
        case actions.UPDATE_ANSWERS:
            let answerState = {};
            let answerlist = state.get("answered");
            let listQuestions = state.get("questions");
            let answer = action.answer;
            let idQuestion = action.question;
            let typeQuestion = null;
            listQuestions.forEach(function (item, idx) {
                if (typeof item._id == "object") {
                    if (item._id._id === idQuestion) {
                        typeQuestion = item.type;
                    }
                } else {
                    if (item._id === action.question) {
                        typeQuestion = item.type;
                    }
                }
                if (_.isNull(typeQuestion)) {
                    switch (item.type) {
                        case "GroupClose":
                        case "GroupList":
                        case "Group":
                            typeQuestion = item.type;
                            break;
                    }
                }
            });
            const actionValidation = validateQuestion(typeQuestion, answer);
            switch (actionValidation) {
                case "insert":
                    const newlist = answerlist.set(idQuestion, answer);
                    answerState.answered = newlist;
                    break;
                case "delete":
                    answerlist = answerlist.delete(idQuestion);
                    answerState.answered = answerlist;
                    break;
                default:
                    break;
            }
            answerState.valid =
                state.get("questionsSize") === answerState.answered.size;
            return state.withMutations((map) => {
                map.set("answered", answerState.answered);
                map.set("valid", answerState.valid);
            });
        default:
            return state;
    }
};

function validateQuestion(typeQuestion, answer) {
    switch (typeQuestion) {
        case "AnswerOpen":
            if (_.isEmpty(_.trim(answer))) {
                return "delete";
            } else {
                return "insert";
            }
        case "LocationDepartment":
            if (_.isEmpty(answer)) {
                return "delete";
            } else {
                return "insert";
            }
        case "LocationCity":
            if (_.isEmpty(answer)) {
                return "delete";
            } else {
                return "insert";
            }
        case "ListOpen":
            if (_.isArray(answer)) {
                let size = _.size(answer);
                if (size != 0) {
                    for (let i = 0; i < size; i++) {
                        if (_.isEqual(answer[i], "otherWhich##")) {
                            return "delete";
                        }
                    }
                    return "insert";
                } else {
                    return "delete";
                }
            } else {
                return "NoArray";
            }
        case "AnswerOpenShort":
        case "AnswerClose":
        case "Date":
        case "ListClose":
        case "ListSelected":
        case "LocationCountry":
        case "GroupClose":
        case "GroupList":
        case "Group":
            return "insert";
        case "Number":
            if (_.isEmpty(answer)) {
                return "delete";
            } else {
                return "insert";
            }
        default:
            return "";
    }
}
