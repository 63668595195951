import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { blue700, grey500 } from "material-ui/styles/colors";
import _ from "lodash";
import { List, ListItem } from "material-ui/List";
import IconButton from "material-ui/IconButton";
import RemoveIcon from "material-ui/svg-icons/navigation/close";
import { removeSelectedValue } from "./ducks";

const styles = {
    contentGroup: {
        minWidth: 320,
        width: "100%",
        padding: "25px 0",
    },
    contentSubGroup: {
        margin: 20,
        display: "inline-block",
        width: 310,
    },
    contentValueList: {
        border: `1px solid ${grey500}`,
        width: "100%",
        maxHeight: 340,
        overflowY: "auto",
        overflowX: "hidden",
    },
};

class FilterInfoReportComponent extends Component {
    constructor(props) {
        super(props);
        this._handleDeleteRecord = this._handleDeleteRecord.bind(this);
        this.renderGroupSelected = this.renderGroupSelected.bind(this);
    }

    _handleDeleteRecord(record) {
        const { removeSelectedValue } = this.props;
        removeSelectedValue(_.get(record, "id"));
    }

    renderListSelect(listFilter) {
        const titleSubGroup = _.get(listFilter, "0.parentTitle", "");
        const countList = _.size(listFilter);
        /*tooltip="Eliminar"
        tooltipPosition="bottom-left"*/
        return (
            <div style={{ width: "100%" }}>
                <div style={{ width: "100%", border: `1px solid ${grey500}` }}>
                    <table
                        style={{ borderCollapse: "collapse", width: "100%" }}
                    >
                        <thead>
                            <tr>
                                <td colSpan={2} style={{ padding: 0 }}>
                                    <div
                                        style={{
                                            background: grey500,
                                            padding: 9,
                                        }}
                                    >
                                        <h3
                                            style={{
                                                textAlign: "left",
                                                fontWeight: 400,
                                                padding: 0,
                                                margin: 0,
                                                color: "#FFF",
                                            }}
                                        >
                                            {titleSubGroup}
                                        </h3>
                                    </div>
                                </td>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div style={styles.contentValueList}>
                    <table
                        style={{ borderCollapse: "collapse", width: "100%" }}
                    >
                        <tbody>
                            {_.chain(listFilter)
                                .map((f) =>
                                    _.set(
                                        f,
                                        "titleOrder",
                                        _.deburr(_.get(f, "title"))
                                    )
                                )
                                .orderBy(["titleOrder"], ["asc"])
                                .map((value, index) => {
                                    return (
                                        <tr
                                            key={`value-${index}`}
                                            style={
                                                index > 0
                                                    ? {
                                                          borderTop:
                                                              "1px solid #CDCDCD",
                                                      }
                                                    : {}
                                            }
                                        >
                                            <td style={{ padding: "0 12px" }}>
                                                <h4
                                                    style={{
                                                        textAlign: "left",
                                                        fontWeight: 400,
                                                    }}
                                                >{`${_.get(
                                                    value,
                                                    "title"
                                                )}`}</h4>
                                            </td>
                                            <td
                                                style={{
                                                    width: 45,
                                                    verticalAlign: "top",
                                                }}
                                            >
                                                <IconButton
                                                    onClick={this._handleDeleteRecord.bind(
                                                        this,
                                                        value
                                                    )}
                                                >
                                                    <RemoveIcon />
                                                </IconButton>
                                            </td>
                                        </tr>
                                    );
                                })
                                .value()}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    renderListSubGroup(listValues) {
        const mapValues = _.chain(listValues)
            .map((v) =>
                _.set(v, "parentTitleOrder", _.deburr(_.get(v, "parentTitle")))
            )
            .orderBy(["parentTitleOrder"], ["asc"])
            .groupBy("parentId")
            .value();
        const listKeys = _.chain(mapValues).keys().value();
        return _.map(listKeys, (key, index) => {
            return (
                <div key={`group-${index}`} style={styles.contentSubGroup}>
                    {this.renderListSelect(_.get(mapValues, key, []))}
                </div>
            );
        });
    }

    renderGroupSelected() {
        const { selectedValues } = this.props;
        const listKeys = _.chain(selectedValues)
            .keys()
            .map((key) => ({ key: key, title: getTitleGroupFilter(key) }))
            .orderBy(["title"], ["asc"])
            .map((k) => _.get(k, "key"))
            .value();
        return _.map(listKeys, (key, index) => {
            return (
                <tr key={`group-general-${index}`}>
                    <td>
                        <div>
                            <h3 style={{ textAlign: "left", marginTop: 50 }}>
                                {getTitleGroupFilter(key)}
                            </h3>
                            {this.renderListSubGroup(
                                _.get(selectedValues, key, [])
                            )}
                        </div>
                    </td>
                </tr>
            );
        });
    }

    render() {
        return (
            <div style={styles.contentGroup}>
                <table style={{ borderCollapse: "collapse", width: "100%" }}>
                    <tbody>{this.renderGroupSelected()}</tbody>
                </table>
            </div>
        );
    }
}

function getTitleGroupFilter(value) {
    switch (value) {
        case "ANSWER":
            return "Respuestas (a preguntas)";
        case "CONDITION":
            return "Agrupadores";
        case "TAG_VALUE":
            return "Etiquetas";
        default:
            "";
    }
}

function mapStateToProps({ filterReport }, ownProps) {
    return {
        selectedValues: filterReport.get("selectedValues"),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            removeSelectedValue,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FilterInfoReportComponent);
