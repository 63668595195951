import PropTypes from "prop-types";
import React, { Component } from "react";
import { Table, TableBody, TableRow, TableRowColumn } from "material-ui/Table";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { LBLQUESTIONS } from "../../constants/labels";
import { addSelected, removeSelected } from "./actions";
import { grey300 } from "material-ui/styles/colors";
import FaqActionColumn from "../TablePagination/actionsColumn/faqActionColumn";
import LisMasterActionColumn from "../TablePagination/actionsColumn/lisMasterActionColumn";
import SurveyActionColumn from "../TablePagination/actionsColumn/surveyActionColumn";
import QuestionActionColumn from "../TablePagination/actionsColumn/questionActionColumn";
import ConsentActionColumn from "../TablePagination/actionsColumn/consentActionColumn";
import RefereeActionColumn from "../TablePagination/actionsColumn/refereeActionColumn";
import CompanyActionColumn from "../TablePagination/actionsColumn/companyActionColumn";
import ProfileActionColumn from "../TablePagination/actionsColumn/profileActionColumn";
import TemplateEmailActionColumn from "../TablePagination/actionsColumn/templateEmailActionColumn";
import CountryActionColumn from "../TablePagination/actionsColumn/countryActionColumn";
import DepartmentActionColumn from "../TablePagination/actionsColumn/departmentActionColumn";
import CityActionColumn from "../TablePagination/actionsColumn/cityActionColumn";
import EmployeeActionColumn from "../TablePagination/actionsColumn/employeeActionColumn";
import ProjectActionColumn from "../TablePagination/actionsColumn/projectActionColumn";
import EmployeeProjectActionColumn from "../TablePagination/actionsColumn/employeeProjectActionColumn";
import EmployeeStatusProjectActionColumn from "../TablePagination/actionsColumn/employeeStatusProjectActionColumn";
import EmployeeStatusProjectForCompanyActionColumn from "../TablePagination/actionsColumn/employeeStatusProjectForCompanyActionColumn";
import UserActionColumn from "../TablePagination/actionsColumn/userActionColumn";

class TableBodyPagination extends Component {
    constructor(props) {
        super(props);
        this._renderRecords = this._renderRecords.bind(this);
        this._renderRowRecords = this._renderRowRecords.bind(this);
        this._onRowSelection = this._onRowSelection.bind(this);
        this._renderActions = this._renderActions.bind(this);
        this._handleAddSelected = this._handleAddSelected.bind(this);
    }

    _onRowSelection(event, data) {
        console.log(arguments, event, data);
    }

    _renderActions(record, index, colSpan) {
        const { name, extra } = this.props;
        switch (name) {
            case "faqs":
                return (
                    <FaqActionColumn
                        key={`body${index}`}
                        colSpan={colSpan}
                        data={record}
                    />
                );
            case "listmaster":
                return (
                    <LisMasterActionColumn
                        key={`body${index}`}
                        colSpan={colSpan}
                        data={record}
                    />
                );
            case "surveys":
                return (
                    <SurveyActionColumn
                        key={`body${index}`}
                        colSpan={colSpan}
                        data={record}
                    />
                );
            case "questions":
                return (
                    <QuestionActionColumn
                        key={`body${index}`}
                        colSpan={colSpan}
                        data={record}
                    />
                );
            case "consents":
                return (
                    <ConsentActionColumn
                        key={`body${index}`}
                        colSpan={colSpan}
                        data={record}
                    />
                );
            case "referees":
                return (
                    <RefereeActionColumn
                        key={`body${index}`}
                        colSpan={colSpan}
                        data={record}
                    />
                );
            case "companies":
                return (
                    <CompanyActionColumn
                        key={`body${index}`}
                        colSpan={colSpan}
                        data={record}
                    />
                );
            case "profiles":
                return (
                    <ProfileActionColumn
                        key={`body${index}`}
                        colSpan={colSpan}
                        data={record}
                    />
                );
            case "templateEmail":
                return (
                    <TemplateEmailActionColumn
                        key={`body${index}`}
                        colSpan={colSpan}
                        data={record}
                    />
                );
            case "countries":
                return (
                    <CountryActionColumn
                        key={`body${index}`}
                        colSpan={colSpan}
                        data={record}
                    />
                );
            case "departments":
                return (
                    <DepartmentActionColumn
                        key={`body${index}`}
                        colSpan={colSpan}
                        data={record}
                        extra={extra}
                    />
                );
            case "cities":
                return (
                    <CityActionColumn
                        key={`body${index}`}
                        colSpan={colSpan}
                        data={record}
                        extra={extra}
                    />
                );
            case "employees":
                return (
                    <EmployeeActionColumn
                        key={`body${index}`}
                        colSpan={colSpan}
                        data={record}
                        extra={extra}
                    />
                );
            case "projects":
                return (
                    <ProjectActionColumn
                        key={`body${index}`}
                        colSpan={colSpan}
                        data={record}
                        extra={extra}
                    />
                );
            case "configemployees":
                return (
                    <EmployeeProjectActionColumn
                        key={`body${index}`}
                        colSpan={colSpan}
                        data={record}
                        extra={extra}
                    />
                );
            case "configemployeesstatus":
                return (
                    <EmployeeStatusProjectActionColumn
                        key={`body${index}`}
                        colSpan={colSpan}
                        data={record}
                        extra={extra}
                    />
                );
            case "configemployeesstatuscompany":
                return (
                    <EmployeeStatusProjectForCompanyActionColumn
                        key={`body${index}`}
                        colSpan={colSpan}
                        data={record}
                        extra={extra}
                    />
                );
            case "users":
                return (
                    <UserActionColumn
                        key={`body${index}`}
                        colSpan={colSpan}
                        data={record}
                        extra={extra}
                    />
                );
            default:
                return <div></div>;
        }
    }

    _renderRowRecords(record) {
        const { body, name } = this.props;
        let styleColFields = {
            whiteSpace: "normal",
            wordWrap: "break-word",
            textAlign: "justify",
        };
        let styleColFieldStatus = {
            whiteSpace: "normal",
            wordWrap: "break-word",
            textAlign: "justify",
            borderLeft: `5px solid ${getColorBorder(record)}`,
        };
        return _.map(body, (field, index) => {
            if (_.has(field, "actions")) {
                return this._renderActions(
                    record,
                    index,
                    _.get(field, "colspan", 1)
                );
            } else if (
                index === 0 &&
                (_.isEqual(name, "configemployeesemail") ||
                    _.isEqual(name, "configemployeesstatus") ||
                    _.isEqual(name, "configemployeesstatuscompany"))
            ) {
                return (
                    <TableRowColumn
                        key={`bodyRow${index}`}
                        colSpan={_.get(field, "colspan", 1)}
                        style={styleColFieldStatus}
                    >
                        {_.get(record, _.get(field, "field"), "---")}
                    </TableRowColumn>
                );
            } else if (
                _.isEqual(_.get(field, "field"), "type") &&
                _.isEqual(name, "questions")
            ) {
                return (
                    <TableRowColumn
                        key={`bodyRow${index}`}
                        colSpan={_.get(field, "colspan", 1)}
                        style={styleColFields}
                    >
                        {getTypeQuestion(
                            _.get(record, _.get(field, "field"), "---")
                        )}
                    </TableRowColumn>
                );
            } else if (
                _.isEqual(_.get(field, "field"), "name") &&
                (_.isEqual(name, "countries") ||
                    _.isEqual(name, "departments") ||
                    _.isEqual(name, "cities"))
            ) {
                return (
                    <TableRowColumn
                        key={`bodyRow${index}`}
                        colSpan={_.get(field, "colspan", 1)}
                        style={styleColFields}
                    >
                        {_.capitalize(
                            _.get(record, _.get(field, "field"), "---")
                        )}
                    </TableRowColumn>
                );
            } else if (
                _.isEqual(name, "projects") &&
                (_.isEqual(_.get(field, "field"), "time.openDate") ||
                    _.isEqual(_.get(field, "field"), "time.closeDate"))
            ) {
                return (
                    <TableRowColumn
                        key={`bodyRow${index}`}
                        colSpan={_.get(field, "colspan", 1)}
                        style={styleColFields}
                    >
                        {moment(
                            _.get(record, _.get(field, "field"), "")
                        ).format("DD/MM/YYYY")}
                    </TableRowColumn>
                );
            } else if (
                _.isEqual(name, "audit") &&
                _.isEqual(_.get(field, "field"), "date")
            ) {
                return (
                    <TableRowColumn
                        key={`bodyRow${index}`}
                        colSpan={_.get(field, "colspan", 1)}
                        style={styleColFields}
                    >
                        {moment(
                            _.get(record, _.get(field, "field"), "")
                        ).format("LLL")}
                    </TableRowColumn>
                );
            } else {
                return (
                    <TableRowColumn
                        key={`bodyRow${index}`}
                        colSpan={_.get(field, "colspan", 1)}
                        style={styleColFields}
                    >
                        {_.get(record, _.get(field, "field"), "---")}
                    </TableRowColumn>
                );
            }
        });
    }

    _handleAddSelected(record, recordSeleted) {
        const {
            addSelected,
            removeSelected,
            selection,
            fieldSelection,
            coreName,
        } = this.props;
        const selectionRow =
            _.isUndefined(selection) ||
            _.isNull(selection) ||
            _.isUndefined(fieldSelection) ||
            _.isNull(fieldSelection)
                ? false
                : selection;
        if (selectionRow) {
            if (recordSeleted) {
                removeSelected(_.get(record, fieldSelection), coreName);
            } else {
                addSelected(_.get(record, fieldSelection), coreName);
            }
        }
    }

    _renderRecords() {
        const { records, skip, selection, fieldSelection } = this.props;
        return _.map(records, (record, index) => {
            const recordSeleted = _.has(record, "selected")
                ? _.get(record, "selected")
                : false;
            const styleRow = {
                backgroundColor: recordSeleted ? grey300 : "inherit",
            };
            return (
                <TableRow
                    key={`body${index}`}
                    style={styleRow}
                    onClick={() =>
                        this._handleAddSelected(record, recordSeleted)
                    }
                >
                    {this._renderRowRecords(record)}
                </TableRow>
            );
        });
    }

    render() {
        return (
            <Table selectable={false} onRowSelection={this._onRowSelection}>
                <TableBody displayRowCheckbox={false}>
                    {this._renderRecords()}
                </TableBody>
            </Table>
        );
    }
}

TableBodyPagination.propTypes = {
    body: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    extra: PropTypes.object,
    selection: PropTypes.bool,
    coreName: PropTypes.string.isRequired,
};

function mapStateToProps({ genericTablePagination }, ownProps) {
    const store = genericTablePagination.get(
        _.get(ownProps, "coreName", "default")
    );
    return {
        skip: store.get("skip"),
        records: store.get("records"),
        fieldSelection: store.get("fieldSelection"),
    };
}

function getTypeQuestion(typeQuestion) {
    switch (typeQuestion) {
        case "AnswerOpen":
            return LBLQUESTIONS.arrTypesQuestions[0];
        case "AnswerClose":
            return LBLQUESTIONS.arrTypesQuestions[1];
        case "ListOpen":
            return LBLQUESTIONS.arrTypesQuestions[2];
        case "ListClose":
            return LBLQUESTIONS.arrTypesQuestions[3];
        case "Date":
            return LBLQUESTIONS.arrTypesQuestions[4];
        case "Number":
            return LBLQUESTIONS.arrTypesQuestions[5];
        case "AnswerOpenShort":
            return LBLQUESTIONS.arrTypesQuestions[6];
        case "GroupClose":
            return LBLQUESTIONS.arrTypesQuestions[7];
        case "GroupList":
            return LBLQUESTIONS.arrTypesQuestions[8];
        case "Group":
            return LBLQUESTIONS.arrTypesQuestions[9];
        case "ListSelected":
            return LBLQUESTIONS.arrTypesQuestions[10];
        case "LocationCountry":
            return LBLQUESTIONS.arrTypesQuestions[11];
        case "LocationDepartment":
            return LBLQUESTIONS.arrTypesQuestions[12];
        case "LocationCity":
            return LBLQUESTIONS.arrTypesQuestions[13];
        default:
            return "Vacío";
    }
}

function getColorBorder(record) {
    switch (_.get(record, "status")) {
        case "Pendiente":
            return "#EDE969";
        case "Desertor":
            return "#E34242";
        case "Finalizado":
            return "#58C257";
        case "En proceso":
            return "#EB9850";
        default:
            return "#FFF";
    }
}

export default connect(mapStateToProps, { addSelected, removeSelected })(
    TableBodyPagination
);
