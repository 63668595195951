import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FlatButton from 'material-ui/FlatButton';
import DownloadIcon from 'material-ui/svg-icons/file/file-download';
import {reduxForm} from 'redux-form';
import {bindActionCreators} from 'redux';
import {toggleSnackbar} from '../../../actions/commons';
import {downloadFile} from './actions';
import CircularProgress from 'material-ui/CircularProgress';
import TextField from 'material-ui/TextField';
import is from 'is_js';
import _ from 'lodash';

class DownloadFile extends Component {

    constructor(props) {
        super(props);
        this._downloadFile = this._downloadFile.bind(this);
        this._onChangeEmail = this._onChangeEmail.bind(this);
        this.state = {
            waiting: false,
            email: "",
            emailError: ""
        };
    }

    _downloadFile(formData) {
        const {
            storeName,
            id,
            downloadFile,
            toggleSnackbar
        } = this.props;
        this.setState({waiting: true});
        downloadFile(storeName, id, this.state.email)
            .then((data) => {
                if (_.get(data, 'error') || (_.get(data, 'payload.status') !== 200)) {
                    toggleSnackbar(true, "Ha ocurrido un error en el servidor");
                } else {
                    toggleSnackbar(true, _.get(data, 'payload.data.text'));
                }
                this.setState({waiting: false});
            }, (reason) => {
                this.setState({waiting: false});
                toggleSnackbar(true, "Ha ocurrido un error en el servidor");
            })
            .catch((data) => {
                this.setState({waiting: false});
                toggleSnackbar(true, "Ha ocurrido un error en el servidor");
            });
    }

    _onChangeEmail(text) {
        if(!_.isEqual(text, "") && !_.isNull(text, "") && !_.isUndefined(text, "")){
            if (!is.email(text)) {
                this.setState({
                    email: text,
                    emailError: "El correo electrónico no es válido"
                });
            } else {
                this.setState({
                    email: text,
                    emailError: ""
                });
            }
        } else {
            this.setState({
                email: text,
                emailError: "El correo electrónico no debe estar vació"
            });
        }
    }

    render() {
        const {
            handleSubmit
        } = this.props;
        return (
            <div style={{
                width: 290,
                height: 150,
                margin: '10px auto 0 auto',
                padding: '53px 5px 53px 5px',
                background: '#F8F8FC',
                borderRadius: 13
            }}>
                {this.state.waiting
                    ? <CircularProgress size={115} thickness={6}  style={{marginTop: 20}} />
                    : <div>
                    <TextField
                        style={{marginTop: 10}}
                        hintText="Correo electrónico"
                        floatingLabelText="Correo electrónico"
                        errorText={this.state.emailError}
                        onChange={(e, text) => this._onChangeEmail(text) }
                        value={this.state.email}
                    />
                    <form name="formUploadCompanies" onSubmit={handleSubmit(this._downloadFile)}>
                        <FlatButton
                            type="submit"
                            label="Descargar archivo"
                            labelPosition="before"
                            secondary={true}
                            style={{width: 230}}
                            disabled={!_.isEqual(this.state.emailError, "") || _.isEqual(this.state.email, "")}
                            icon={<DownloadIcon />}
                        />
                    </form>
                </div>
                }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        toggleSnackbar,
        downloadFile
    }, dispatch);
}

DownloadFile.propTypes = {
    storeName: PropTypes.string.isRequired,
    id: PropTypes.string
};

export default reduxForm({
    form: 'DownloadFileForm',
    fields: []
}, null, mapDispatchToProps)(DownloadFile);
