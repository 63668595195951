import { SHOW_ABOUT_MESSAGE_INFO, HIDE_ABOUT_MESSAGE_INFO } from '../constants/index';
import Immutable from 'immutable';
import _ from 'lodash';

const INITIAL_STATE = Immutable.Map({
    show: false
});

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SHOW_ABOUT_MESSAGE_INFO:
            return state.set('show', true);
        case HIDE_ABOUT_MESSAGE_INFO:
            return state.set('show', false);
        default:
            return state;
    }
}
