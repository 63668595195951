import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { toggleSnackbar } from '../../../actions/commons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import IconButton from 'material-ui/IconButton';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import Badge from 'material-ui/Badge';
import { blue700 } from 'material-ui/styles/colors';
import _ from 'lodash';
import { deleteEmployeeMasiveId } from '../../../actions/grid/employees_grid_form';
import AuthComponent from '../../AuthComponent';
import DeleteEntityModal from '../../deleteEntityModal/component';
import { LBLEMPLOYESS } from '../../../constants/labels';
import { removeMassiveRecord } from '../actions';

class EmployeeActionSelected extends Component {

    constructor(props) {
        super(props);
        this._handleDeleteEmployees = this._handleDeleteEmployees.bind(this);
    }

    _handleDeleteEmployees(employees) {
        const {
            deleteEmployeeMasiveId,
            toggleSnackbar,
            removeMassiveRecord
        } = this.props;
        deleteEmployeeMasiveId(employees)
            .then((data) => {
                if (_.get(data, 'error') || (_.get(data, 'payload.status') !== 200)) {
                    toggleSnackbar(true, LBLEMPLOYESS.msg.errorDeleteEmployees);
                } else {
                    removeMassiveRecord(employees, '_id');
                    toggleSnackbar(true, LBLEMPLOYESS.msg.successDeleteEmployees);
                }
            }, (reason) => {
                toggleSnackbar(true, LBLEMPLOYESS.msg.errorDeleteEmployees);
            })
            .catch((data) => {
                toggleSnackbar(true, LBLEMPLOYESS.msg.errorDeleteEmployees);
            });
    }

    render() {
        const {
            selected
        } = this.props;
        const sizeSelected = _.size(selected);
        if(sizeSelected > 0){
            return (
                <AuthComponent
                    component={
                        <div style={{zIndex: 1100, borderRadius: '100%', position: 'fixed', bottom: 100, right: 30, backgroundColor: blue700, width: 51, height: 51, textAlign: 'center', padding: '4px 3px 3px 3px'}}>
                            <div style={{right: 75, zIndex: 1099, position: 'fixed', marginTop: 15, backgroundColor: '#FFF', border: '0px solid #000', height: 19, padding: '0px 6px', borderRadius: 10}}>
                                {sizeSelected}
                            </div>
                            <DeleteEntityModal
                                tooltip={LBLEMPLOYESS.buttons.deleteBulkEmployees}
                                title={LBLEMPLOYESS.titleDeleteMasive}
                                message={LBLEMPLOYESS.msg.deleteModalMasive(sizeSelected)}
                                fn={this._handleDeleteEmployees}
                                args={[selected]}
                                style={{ display: "table-cell" }}
                                valid={sizeSelected === 0}
                                colorIconButton={"#FFF"}
                                positionTextIconButton={"top-left"}
                            />
                        </div>
                    }
                    permission={"cgr_employee_delete_massive"}
                    type={"component"}
                />
            );
        } else {
            return ( <div></div> );
        }
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        deleteEmployeeMasiveId,
        toggleSnackbar,
        removeMassiveRecord
    }, dispatch);
}

EmployeeActionSelected.propTypes = {
    selected: PropTypes.array.isRequired
};

export default connect(null, mapDispatchToProps)(EmployeeActionSelected);
