import { GET_ALL_USERS_AUDITS, CLEAR_ALL_USERS_AUDITS, GET_USERS_AUDITS_IN_SEARCH } from './constants';
import { axiosAPI_V2 } from '../../middleware/api';

export function getAllUsers(idProject){

    const request = axiosAPI_V2.get(`/audit/users/${idProject}`);

    return {
        type: GET_ALL_USERS_AUDITS,
        payload: request
    }
}

export function clearUsersAudit(idProject){

    return {
        type: CLEAR_ALL_USERS_AUDITS
    }
}

export function inSearchUsersAudit(){

    return {
        type: GET_USERS_AUDITS_IN_SEARCH
    }
}
