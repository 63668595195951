import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import {Row, Col} from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';
import { bindActionCreators } from 'redux';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import {createConsent} from '../actions/consents_form';
import {FetchConsentParam} from '../actions/info/consents_info_form';
import _ from 'lodash';
import { browserHistory } from 'react-router';
import {toggleSnackbar} from '../actions/commons';
import {LBLCONSENTS} from '../constants/labels';
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';
import ButtonBack from '../components/fields/buttonBack/buttonBack';
import AuthComponent from '../components/AuthComponent';

const style = {
        margin: 20,
        float: 'right'
    },
    stylePaper = {
        marginTop: 20,
        marginBottom: 20
    },
    styleSpanHelp = {
        color: '#1976d2',
        fontWeight: 400
    };

class ConsentsNews extends Component {

    constructor(props) {
        super(props);
        this._saveConsents = this._saveConsents.bind(this);
    }

    UNSAFE_componentWillMount() {
        if (_.has(this, 'props.params._id')) {
            this.props.FetchConsentParam(this.props.params._id);
        }
    }

    _saveConsents(formData) {
        const {
            createConsent,
            toggleSnackbar,
            resetForm,
            params
        } = this.props;
        const editMode = _.has(params, '_id');
        createConsent(formData, editMode)
            .then((data) => {
                if(_.get(data, 'error') || (_.get(data, 'payload.status') !== 200)){
                    toggleSnackbar(true, LBLCONSENTS.msg.errorSave);
                } else {
                    resetForm();
                    toggleSnackbar(true,  LBLCONSENTS.msg.successSave);
                    browserHistory.push(`/admin/consents/grid`);
                }
            }, (reason) => {
                toggleSnackbar(true,  LBLCONSENTS.msg.errorSave);
            })
            .catch((data) => {
                toggleSnackbar(true,  LBLCONSENTS.msg.errorSave);
            });
    }

    render() {
        const {
            fields: {
                name,
                agreement,
                _id,
                required,
                placeholders
            },
            handleSubmit,
            resetForm
        } = this.props;
        const editMode = _.has(this, 'props.params._id');
        return (
            <Row>
                <AuthComponent
                    component={
                        <Col xs={12} mdOffset={2} md={8}>
                            <Paper style={stylePaper}>
                                <Row>
                                    <Col xsOffset={1} xs={10}>
                                        <h1 style={{textAlign: 'center', fontWeight: 400}}>{editMode ?  LBLCONSENTS.titleEdit : LBLCONSENTS.titleCreate}</h1>
                                        <ButtonBack url={`/admin/consents/grid`}/>
                                        <form onSubmit={handleSubmit(this._saveConsents)}>
                                            <Row>
                                                <Col xs={12}>
                                                    <TextField
                                                        hintText=""
                                                        floatingLabelText={LBLCONSENTS.name}
                                                        type="text"
                                                        fullWidth={true}
                                                        errorText={name.touched && name.error ? name.error : '' }
                                                        {...name}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xsOffset={1} xs={10}>
                                                    <h3 style={{fontWeight: 200, textAlign: 'justify', marginTop: 30, marginBottom: 30}}>
                                                        Para agregar un <span style={styleSpanHelp}>campo que debe ser
                                                        diligenciado</span> al momento de crear un proyecto, se debe de agregar un doble
                                                        numeral <span  style={styleSpanHelp}>##</span> antes del campo. Por ejemplo,
                                                        si se necesitará ingresar la ciudad actual donde se realiza la encuesta,
                                                        pondría así: <span style={styleSpanHelp}>##Ciudad</span>
                                                        <br />
                                                        <br />
                                                        Nota: si se desea especificar el valor del campo con una oración y no una palabra,
                                                        solo basta con poner una mayúscula cada vez que se deba poner espacio,
                                                        así: <span style={styleSpanHelp}>##CiudadActualDeLaEncuesta</span>. Recuerde que
                                                        no deben de haber espacios en blanco, caracteres especiales ni números cuando se
                                                        crea un campo. Solo pueden ser letras.
                                                    </h3>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12}>
                                                    <TextField
                                                        hintText=""
                                                        floatingLabelText={LBLCONSENTS.agreementsConsent}
                                                        fullWidth={true}
                                                        multiLine={true}
                                                        rows={7}
                                                        {...agreement}
                                                        errorText={agreement.touched && agreement.error ? agreement.error : '' }
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs>
                                                    <h3 style={{textAlign: 'left', fontWeight: 400}}>{LBLCONSENTS.titleRequired}</h3>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs>
                                                    <RadioButtonGroup
                                                        name="required"
                                                        defaultSelected={required.value}
                                                        valueSelected={required.value}
                                                        style={{width: 200}}
                                                        onChange={(e, value) => required.onChange(value) }
                                                    >
                                                        <RadioButton
                                                            value="1"
                                                            label="Si"
                                                            style={{ marginBottom: 16, display: 'inline-block', width: '50%' }}
                                                        />
                                                        <RadioButton
                                                            value="0"
                                                            label="No"
                                                            style={{  marginBottom: 16, display: 'inline-block', width: '50%' }}
                                                        />
                                                    </RadioButtonGroup>
                                                </Col>
                                                <Col xs> </Col>
                                            </Row>
                                            <AuthComponent
                                                component={<RaisedButton
                                                                type="submit"
                                                                label={editMode ? LBLCONSENTS.buttons.update : LBLCONSENTS.buttons.save}
                                                                secondary={true}
                                                                style={style}
                                                            />}
                                                permission={editMode ? "cgr_consent_update" : "cgr_consent_create"}
                                                type={"component"}
                                            />
                                        </form>
                                    </Col>
                                </Row>
                            </Paper>
                        </Col>
                    }
                    permission={editMode ? "cgr_consent_update" : "cgr_consent_create"}
                    type={"url"}
                />
            </Row>
        );
    }
}

const validate = (values) => {
    const errors = {};

    if (!values.name) {
        errors.name = LBLCONSENTS.validations.name;
    }
    if (!values.agreement) {
        errors.agreement = LBLCONSENTS.validations.agreementsConsent;
    }

    return errors;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        createConsent,
        FetchConsentParam,
        toggleSnackbar
    }, dispatch);
}

function mapStateToProps({consents}, ownProps) {
    if(_.has(ownProps, 'params._id')){
        return {
            consents: consents.all,
            _id: _.get(ownProps, 'params._id'),
            initialValues: {
                _id: !_.has(consents,'all.data._id') ? "" : _.get(consents, 'all.data._id'),
                name: !_.has(consents,'all.data.name') ? "" : _.get(consents, 'all.data.name'),
                agreement: !_.has(consents,'all.data.agreement') ? "" : _.get(consents, 'all.data.agreement'),
                required: !_.has(consents,'all.data.required') ? "0" : _.get(consents, 'all.data.required') ? "1" : "0"
            }
        };
    } else {
        return {
            consents: null,
            initialValues: {
                _id: "",
                name: "",
                agreement: "",
                required: "0"
            },
            _id: ""
        };
    }
}

ConsentsNews.propTypes = {
    fields: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    router: PropTypes.object
};

export default reduxForm({
    form: 'ConsentsNewForm',
    fields: [
        'name',
        'agreement',
        'required',
        '_id'
    ],
    validate
}, mapStateToProps, mapDispatchToProps)(ConsentsNews);
