import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';
import _ from 'lodash';
import {connect} from 'react-redux';
import TitleGroup from './TitleGroup';

const styleWidthContentTitle = {
          padding: '0px 20px 0px 0px',
          borderTop: 1,
          borderTopStyle: 'solid',
          borderTopColor: "#eee"
      };

class QuestionListCloseGroup extends Component {

    constructor(props) {
        super(props);
        this._onChange = this._onChange.bind(this);
        this.renderOptions = this.renderOptions.bind(this);
        this.state = {
            answerText: ""
        };
    }

    _onChange(e, value) {
        const { _handlerUpdateValue } = this.props;
        _handlerUpdateValue(e, value);
    }

    renderOptions(options) {
        const widthAnswers = (100 / _.size(options)) + "%";
        if(options instanceof Array){
            let OptionList = _.map(options, function (op){
                return _.set(op, 'key', parseInt(_.get(op, 'key')));
            });
            return _.map(_.orderBy(OptionList, 'key', 'asc'), (option, position) => {
                return (
                    <RadioButton
                        value={`${option._id}`}
                        key={option._id}
                        onClick={() => this.setState({answerText:_.get(option, 'value')}) }
                        style={{
                            marginBottom: 5,
                            width: widthAnswers,
                            display: 'inline-block'
                        }}
                    />
                );
            });
        }
    }

    render() {
        const {
            question,
            valueAnswer,
            index,
            valueQuestion
        } = this.props;
        const styleWidthContentTitle2 = {
                  borderTop: 1,
                  borderTopStyle: 'solid',
                  borderTopColor: "#eee",
                  paddingTop: 10,
                  paddingBottom: 0,
                  width: _.size(valueAnswer) * 44,
                  verticalAlign: 'center'
              };
        return (
            <tr>
                <td style={styleWidthContentTitle}>
                    <TitleGroup title={index+". " + _.get(question, 'title')} answerText={this.state.answerText}/>
                </td>
                <td style={styleWidthContentTitle2}>
                    <div>
                        <RadioButtonGroup
                            name={question._id}
                            onChange={this._onChange}
                            valueSelected={valueQuestion}
                        >
                            {this.renderOptions(valueAnswer)}
                        </RadioButtonGroup>
                    </div>
                </td>
            </tr>
        );
    }
}

QuestionListCloseGroup.propTypes = {
    question: PropTypes.object.isRequired,
    valueAnswer: PropTypes.array.isRequired
};

function mapStateToProps({ questionsSurvey }, ownerProps) {
    return {
        valueQuestion: _.get(questionsSurvey.get('answered').toObject(), _.get(ownerProps, 'idQuestion'))
    };
}

export default connect(mapStateToProps)(QuestionListCloseGroup);
