export const TOGGLE_SHOW_MODAL = "TOGGLE_DIALOG";
export const SELECT_USER = "SELECT_USER";
export const FIRST_CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
import {axiosAPI} from '../../middleware/api';


export function changeFirstPassword(id, password) {
    const request = axiosAPI.put("/users/password", {
        userId: id,
        password,
        firstPassword: false
    });
    return {
        type: FIRST_CHANGE_PASSWORD,
        payload: request
    }
}

export function changePasswordUser(id, currentPassword, newPassword) {
    const request = axiosAPI.post("/users/password", {
        userId: id,
        currentPassword,
        newPassword
    });
    return {
        type: CHANGE_PASSWORD,
        payload: request
    }
}


export function showModalChangePassword(show) {
    return {
        type: TOGGLE_SHOW_MODAL,
        open: show
    }
}
