import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import _ from "lodash";

function validatePermission(permissionsList, permission) {
    return _.findIndex(permissionsList, p => _.isEqual(permission, p)) !== -1;
}

function validatePermissions(permissionsList, permissions) {
    return _.findIndex(permissionsList, p => _.indexOf(permissions, p) >= 0) !== -1;
}

class AuthComponent extends Component {
    state = {
        authorized: null,
    };

    static defaultProps = {
        component: <span />,
        permission: undefined,
        permissions: undefined,
        type: "component",
        key: undefined,
        defaultPermission: false,
        componentDefault: undefined,
    };

    static propTypes = {
        component: PropTypes.element,
        permission: PropTypes.string,
        permissions: PropTypes.array,
        permissionsList: PropTypes.array,
        type: PropTypes.oneOf(["component", "url"]),
        key: PropTypes.string,
        defaultPermission: PropTypes.bool,
        componentDefault: PropTypes.element,
    };

    componentDidMount() {
        const { permissionsList, permissions, permission, defaultPermission } = this.props;
        const defPer = _.isUndefined(defaultPermission) || _.isNull(defaultPermission) ? false : defaultPermission;
        if (defPer) {
            this.setState({ authorized: true });
        } else if (_.isUndefined(permission) || _.isNull(permission)) {
            if (_.isUndefined(permissions) || _.isNull(permissions)) {
                this.setState({ authorized: false });
            } else if (_.isArray(permissions) && _.size(permissions) > 0) {
                const validate = validatePermissions(permissionsList, permissions);
                this.setState({ authorized: validate });
            } else {
                this.setState({ authorized: false });
            }
        } else {
            const validate = validatePermission(permissionsList, permission);
            this.setState({ authorized: validate });
        }
    }

    render() {
        const { component, type, key, componentDefault } = this.props;
        const { authorized } = this.state;
        const typeElement = _.isUndefined(type) ? "component" : type;
        const comp = _.isUndefined(component) ? <span /> : component;
        if (!_.isNull(authorized)) {
            if (authorized) {
                return comp;
            }
            if (typeElement === "url") {
                browserHistory.goBack();
            } else {
                if (!_.isUndefined(componentDefault) && !_.isNull(componentDefault)) {
                    return componentDefault;
                }
                if (_.isUndefined(key) || _.isNull(key)) {
                    return <span />;
                }
                return <span key={key} />;
            }
        }
        if (_.isUndefined(key) || _.isNull(key)) {
            return <span />;
        }
        return <span key={key} />;
    }
}

function mapStateToProps({ login }) {
    return {
        permissionsList: login.get("permissions"),
    };
}

export default connect(mapStateToProps)(AuthComponent);
