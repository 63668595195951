import PropTypes from "prop-types";
import React, { Component } from "react";
import FlatButton from "material-ui/FlatButton";
import { buildItemIndex } from "./ProjectFunctions";
import FilterCreator from "./FilterCreator";
import { allowedListFilters } from "./ProjectDucks";
import Chip from "material-ui/Chip";
import * as _ from "lodash";

const styles = {
    chip: {
        whiteSpace: "normal",
        padding: 0,
        margin: 2,
    },
    labelStyle: {
        wordBreak: "normal",
        whiteSpace: "normal",
    },
    wrapper: {
        display: "flex",
        flexWrap: "wrap",
    },
};

class FiltersContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            map: {},
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            map: buildItemIndex(nextProps.data, "id"),
        });
    }

    static propTypes = {
        addField: PropTypes.func.isRequired,
        data: PropTypes.array.isRequired,
        selectedValue: PropTypes.string.isRequired,
        value: PropTypes.array.isRequired,
        allowedFilters: PropTypes.arrayOf(PropTypes.oneOf(allowedListFilters))
            .isRequired,
    };

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    mapValue(delFn, map, { item, value, operator }, i) {
        const itemObject = _.get(map, item.value, {});
        //backgroundColor
        //labelColor
        return (
            <Chip
                style={styles.chip}
                labelStyle={styles.labelStyle}
                key={i}
                onRequestDelete={() => {
                    delFn(i);
                }}
            >
                {`${itemObject.name} ${operator.value} ${value.value}`}
            </Chip>
        );
    }

    render() {
        const {
            data,
            value,
            addField,
            selectedValue,
            allowedFilters,
        } = this.props;
        const actions = [
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={this.handleClose}
            />,
            <FlatButton
                label="Aplicar"
                primary={true}
                onClick={this.handleClose}
            />,
        ];

        return (
            <div>
                <FilterCreator
                    data={data}
                    currentSelected={selectedValue}
                    save={addField}
                    allowedFilters={allowedFilters}
                />
                <div style={styles.wrapper}>
                    {value.map(
                        this.mapValue.bind(
                            undefined,
                            value.removeField,
                            this.state.map
                        )
                    )}
                </div>
            </div>
        );
    }
}

export default FiltersContainer;
