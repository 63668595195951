// @flow
import React, { PureComponent } from "react";
import { DragSource } from "react-dnd";
import type { ConnectDragSource, ConnectDragPreview } from "react-dnd";
import ItemTypes from "../dragComponentTypes";
import Box from "./box";
import { getStyleIsDragging } from "./functions";

const rowSource = {
    beginDrag() {
        return {
            type: ItemTypes.ROW,
        };
    },
};

function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
        connectDragPreview: connect.dragPreview(),
    };
}

type Props = {
    isDragging: boolean,
    connectDragSource: ConnectDragSource,
    connectDragPreview: ConnectDragPreview,
};

class RowItem extends PureComponent<void, Props, void> {
    componentDidMount() {
        const { connectDragPreview } = this.props;
        const img = new Image();
        img.onload = () => {
            connectDragPreview(img, {
                anchorX: 0.5,
                anchorY: 0.5,
            });
        };
    }

    render() {
        const { connectDragSource, isDragging } = this.props;
        return connectDragSource(
            <div style={getStyleIsDragging(isDragging)}>
                <Box label="Fila" />
            </div>,
        );
    }
}

export default DragSource(ItemTypes.CHART, rowSource, collect)(RowItem);
