import { axiosAPI, axiosAPI_V2 } from '../../../middleware/api';
export const LOGOUT = "LOGOUT";

export function logOut() {
    const localStorage = window.localStorage;
    localStorage.removeItem('authToken');
    axiosAPI.defaults.headers.authorization = undefined;
    axiosAPI_V2.defaults.headers.authorization = undefined;
    return {
        type: LOGOUT
    }
}
