import PropTypes from "prop-types";
import React, { Component } from "react";
import _ from "lodash";
import { chartTypes, arrayChart, arraySizeChart } from "./types";

import Message from "./chartsComponents/message";

/** ******** 2D ********* */
import PieChartComponent from "./chartsComponents/pieChartComponent";
import PieDonutChartComponent from "./chartsComponents/pieDonutChartComponent";
import BarChartHorizontalComponent from "./chartsComponents/barChartHorizontalComponent";
import BarChartVerticalComponent from "./chartsComponents/barChartVerticalComponent";
import BarStackedChartComponent from "./chartsComponents/barStackedChartComponent";

/** ******** 3D ********* */
import Pie3DChartComponent from "./charts3DComponents/pie3DChartComponent";
import PieDonut3DChartComponent from "./charts3DComponents/pieDonut3DChartComponent";
import BarChart3DHorizontalComponent from "./charts3DComponents/barChart3DHorizontalComponent";
import BarChart3DVerticalComponent from "./charts3DComponents/barChart3DVerticalComponent";
import BarStacked3DChartComponent from "./charts3DComponents/barStacked3DChartComponent";

function getValueSize(size) {
    let s = "middle";
    if ((!_.isUndefined(size) && !_.isNull(size)) || !_.isEqual(size, "")) {
        if (_.indexOf(["small", "middle", "large"], size) !== -1) {
            s = size;
        }
    }
    switch (s) {
        case "small":
            return 195;
        case "middle":
            return 285;
        case "large":
            return 375;
        default:
            return 180;
    }
}

function invalidData(type, data, dataStacked) {
    switch (type) {
        case chartTypes.PIE:
        case chartTypes.PIE3D:
        case chartTypes.PIE_DONUT:
        case chartTypes.PIE_DONUT3D:
        case chartTypes.BAR_HORIZONTAL:
        case chartTypes.BAR_HORIZONTAL3D:
        case chartTypes.BAR_VERTICAL:
        case chartTypes.BAR_VERTICAL3D:
            return _.reduce(data, (sum, value) => sum + _.get(value, "value", 0), 0) === 0;
        case chartTypes.STACKED_BAR:
        case chartTypes.STACKED_BAR3D:
            return (
                _.chain(dataStacked)
                    .map(value => _.get(value, "total", 0))
                    .reduce((sum, value) => sum + value, 0)
                    .value() === 0
            );
        default:
            return true;
    }
}

function updateData(data) {
    return _.filter(data, value => !_.isEqual(_.get(value, "value", 0), 0));
}

function updateDataStacked(data) {
    return _.filter(data, value => !_.isEqual(_.get(value, "total", 0), 0));
}

class ChartComponent extends Component {
    static propTypes = {
        // Required: barHorizontal, barVertical, pie, pie3D, pieDonut, pieDonut3D
        data: PropTypes.array,

        // Required: barStacked
        dataStacked: PropTypes.any,

        // Required: barStacked
        keysStacked: PropTypes.any,

        // Optional: barHorizontal, barVertical, barStacked
        textValues: PropTypes.string,

        // Optional: barHorizontal, barVertical, barStacked
        textKeys: PropTypes.string,

        // Required: all
        type: PropTypes.oneOf(arrayChart).isRequired,

        // Optional: all
        hasLegend: PropTypes.bool,

        // Optional and Only: barStacked
        hasSubLegend: PropTypes.bool,

        // Default: middle
        size: PropTypes.oneOf(arraySizeChart)
    };

    render() {
        const { data, textValues, textKeys, type, hasLegend, hasSubLegend, size, dataStacked, keysStacked } = this.props;
        const textVertical = _.isEqual(type, "barHorizontal") ? textKeys : textValues;
        const textHorizontal = _.isEqual(type, "barHorizontal") ? textValues : textKeys;
        const dimensions = getValueSize(size);
        const width = dimensions;
        const height = dimensions;

        /* const data = [
            {value: 25, color: "#BFFCC6", index: "#1", textIndex: "value1"},
            {value: 32, color: "#6EB5FF", index: "#2", textIndex: "value2"},
            {value: 19, color: "#FFABAB", index: "#3", textIndex: "value3"},
            {value: 10, color: "#97A2FF", index: "#4", textIndex: "value4"},
            {value: 47, color: "#C5A3FF", index: "#5", textIndex: "value5"}
        ],
        dataStacked = [
            {"index":"#1",textIndex:"value1","total":100,"started":20,"pending":15,"finished":40, "deserted": 25},
            {"index":"#2",textIndex:"value2","total":100,"started":15,"pending":16,"finished":54, "deserted": 15},
            {"index":"#3",textIndex:"value3","total":100,"started":11,"pending":15,"finished":62, "deserted": 12},
            {"index":"#4",textIndex:"value4","total":100,"started":26,"pending":15,"finished":42, "deserted": 17}
        ],
        keysStacked = [
            {"key": "pending", "text": "Pendientes", "color": "#FFFFD1"},
            {"key": "started", "text": "En proceso", "color": "#FFF5BA"},
            {"key": "finished", "text": "Finalizados", "color": "#BFFCC6"},
            {"key": "deserted", "text": "Desertores", "color": "#FFABAB"}
        ]; */

        if (invalidData(type, data, dataStacked)) {
            return <Message />;
        }

        switch (type) {
            case chartTypes.PIE:
                return (
                    <PieChartComponent
                        className="print_pdf_component"
                        data={updateData(data)}
                        hasLegend={hasLegend}
                        size={size}
                        height={height}
                        width={width}
                    />
                );
            case chartTypes.PIE3D:
                return (
                    <Pie3DChartComponent
                        className="print_pdf_component"
                        data={updateData(data)}
                        hasLegend={hasLegend}
                        size={size}
                        height={height}
                        width={width}
                    />
                );
            case chartTypes.PIE_DONUT:
                return (
                    <PieDonutChartComponent
                        className="print_pdf_component"
                        data={updateData(data)}
                        hasLegend={hasLegend}
                        size={size}
                        height={height}
                        width={width}
                    />
                );
            case chartTypes.PIE_DONUT3D:
                return (
                    <PieDonut3DChartComponent
                        className="print_pdf_component"
                        data={updateData(data)}
                        hasLegend={hasLegend}
                        size={size}
                        height={height}
                        width={width}
                    />
                );
            case chartTypes.BAR_HORIZONTAL:
                return (
                    <BarChartHorizontalComponent
                        className="print_pdf_component"
                        data={updateData(data)}
                        textVertical={textVertical}
                        textHorizontal={textHorizontal}
                        hasLegend={hasLegend}
                        size={size}
                        height={height}
                        width={width}
                    />
                );
            case chartTypes.BAR_HORIZONTAL3D:
                return (
                    <BarChart3DHorizontalComponent
                        className="print_pdf_component"
                        data={updateData(data)}
                        textVertical={textVertical}
                        textHorizontal={textHorizontal}
                        hasLegend={hasLegend}
                        size={size}
                        height={height}
                        width={width}
                    />
                );
            case chartTypes.BAR_VERTICAL:
                return (
                    <BarChartVerticalComponent
                        className="print_pdf_component"
                        data={updateData(data)}
                        textVertical={textVertical}
                        textHorizontal={textHorizontal}
                        hasLegend={hasLegend}
                        size={size}
                        height={height}
                        width={width}
                    />
                );
            case chartTypes.BAR_VERTICAL3D:
                return (
                    <BarChart3DVerticalComponent
                        className="print_pdf_component"
                        data={updateData(data)}
                        textVertical={textVertical}
                        textHorizontal={textHorizontal}
                        hasLegend={hasLegend}
                        size={size}
                        height={height}
                        width={width}
                    />
                );
            case chartTypes.STACKED_BAR:
                return (
                    <BarStackedChartComponent
                        className="print_pdf_component"
                        data={updateDataStacked(dataStacked)}
                        keys={keysStacked}
                        textVertical={textVertical}
                        textHorizontal={textHorizontal}
                        hasLegend={hasLegend}
                        hasSubLegend={hasSubLegend}
                        size={size}
                        height={height}
                        width={width}
                        hasAxis={false}
                    />
                );
            case chartTypes.STACKED_BAR3D:
                return (
                    <BarStacked3DChartComponent
                        className="print_pdf_component"
                        data={updateDataStacked(dataStacked)}
                        keys={keysStacked}
                        textVertical={textVertical}
                        textHorizontal={textHorizontal}
                        hasLegend={hasLegend}
                        hasSubLegend={hasSubLegend}
                        size={size}
                        height={height}
                        width={width}
                        hasAxis={false}
                    />
                );
            default:
                return <span style={{ color: "#CCC", textAlign: "center" }}>(vacío)</span>;
        }
    }
}

export default ChartComponent;
