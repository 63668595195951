import {createStore, applyMiddleware} from 'redux';
import rootReducer from '../reducers';
import {browserHistory} from 'react-router';
import {syncHistory} from 'react-router-redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import {loadState} from '../lib/persistentState';
import persistentMiddleware from '../lib/persistentMiddleware';
import {createEpicMiddleware} from 'redux-observable';
import rootEpic from './epics';

const epicMiddleware = createEpicMiddleware(rootEpic);
const reduxRouterMiddleware = syncHistory(browserHistory);
const persistent = persistentMiddleware(['login', 'questionsSurvey']);

function configureStore() {
    const persistentState = loadState();
    const finalCreateStore = applyMiddleware(thunk, promise, reduxRouterMiddleware, persistent, epicMiddleware)(createStore);
    return finalCreateStore(rootReducer, persistentState);
}

export default configureStore;
