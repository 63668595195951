import { GET_LISTMASTER_GRID, GET_LISTMASTER_AND_LIST_VALUES, GET_LIST_VALUES_LISTMASTER } from '../actions/grid/listmaster_grid_form';
import { POST_LISTMASTER_INFO } from '../actions/info/listmaster_info_form';
import { GET_LISTMASTER, GET_LISTMASTER_PARAMS, DELETE_LISTMASTER} from '../constants/index';
import _ from 'lodash';

const INITIAL_STATE = { all: [], listmaster: null, listvalues: null};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_LISTMASTER_GRID:
            return  _.assign({}, state, {
                all: action.payload.data
            });
        case GET_LISTMASTER_AND_LIST_VALUES:
            return  _.assign({}, state, {
                all: action.payload.data
            });
        case GET_LIST_VALUES_LISTMASTER:
            return  _.assign({}, state, {
                listvalues: action.payload.data
            });
        case POST_LISTMASTER_INFO:
            return  _.assign({}, state, {
                all: action.payload.data
            });
        case GET_LISTMASTER:
            return  _.assign({}, state, {
                all: action.payload.data
            });
        case GET_LISTMASTER_PARAMS:
            return  _.assign({}, state, {
                all: action.payload.data
            });
        case DELETE_LISTMASTER:
            var newAll = _.filter(state.all.data, item => {
                return !_.isEqual(item._id, action.meta.listId);
            });
            return _.assign({}, state, {
                all: {
                    data:newAll
                }
            });
        default:
            return state;
    }
}
