import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { getValuesNameList, cleanStateTreeMap } from './actions';

let listName = "";

class FetchListValues extends Component {

    constructor(props) {
        super(props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (listName !== nextProps.nameList && nextProps.nameList != null) {
            listName = nextProps.nameList;
            nextProps.getValuesNameList(nextProps.nameList);
        }
    }

    UNSAFE_componentWillMount() {
        this.props.cleanStateTreeMap('values');
        listName = "";
    }

    render() {
        const {
            listData,
            visible,
            styleList
        } = this.props;
        let visib = _.isUndefined(visible) ? false : visible;
        let stlList = _.isUndefined(styleList) ? 'disc' :
                            styleList === 'none' ? 'disc' :
                            styleList === 'numeric' ? 'decimal' :
                            styleList === 'lowerCase' ? 'lower-latin' :
                            styleList === 'upperCase' ? 'upper-latin' : 'disc';
        let OptionList = _.map(listData, function (option){
            return _.set(option, 'key', parseInt(_.get(option, 'key')));
        });
        return (
            <ul style={{
                display: visib ? 'block' : 'none',
                listStyleType: stlList
            }}> {
                _.map(_.orderBy(OptionList, 'key', 'asc'), (val) => {
                    return ( <li key={val._id}><h3 style={{fontWeight: 200, marginTop: -10}}>{val.value}</h3></li> );
                })
            } </ul>
        );
    }
}

FetchListValues.propTypes = {
    nameList: PropTypes.string.isRequired,
    visible: PropTypes.bool,
    styleList: PropTypes.string
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getValuesNameList,
        cleanStateTreeMap
    }, dispatch);
}

function mapStateToProps({listValues}) {
    if(_.get(listValues.get('values'), 'size') !== 0){
        if(_.size(_.get(listValues.get('values'), 'data'))){
            return {
                listData: _.get(listValues.get('values'), 'data')
            };
        }else{
            return { listData: [] };
        }
    }
    return { listData: [] };
}

export default connect(mapStateToProps, mapDispatchToProps)(FetchListValues);
