import PropTypes from "prop-types";
import React, { Component } from "react";
import _ from "lodash";

class TitleComponent extends Component {
    static propTypes = {
        text: PropTypes.string.isRequired,
        style: PropTypes.object.isRequired,
        code: PropTypes.string,
    };

    renderCode = () => {
        const { code } = this.props;
        const stylePointTitle = { position: "absolute", opacity: 0 };
        if (!_.isNull(code) && !_.isUndefined(code)) {
            return <span id={code} style={stylePointTitle} />;
        }
        return <span style={stylePointTitle} />;
    };

    render() {
        const { text, style } = this.props;
        const styleTitle = {
            fontSize: "1.18em",
            fontWeight: "bold",
            marginTop: 20,
            marginBottom: 20,
        };
        let styleDefault = { fontWeight: 400 };
        if (!_.isEqual(text, "") && !_.isNull(text) && !_.isUndefined(text)) {
            styleDefault = _.assign(styleDefault, { lineHeight: 1.2, marginTop: 10, marginBottom: 10 });
        }
        const styleReport = _.assign(style, styleDefault);
        return (
            <div className="print_pdf_title" style={_.assign(styleTitle, styleReport)}>
                {this.renderCode()}
                {text}
            </div>
        );
        /* return (
            <h3 className="print_pdf_title" style={styleReport}>
                {this.renderCode()}
                {text}
            </h3>
        ); */
    }
}

export default TitleComponent;
