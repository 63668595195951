import React from "react";
import { useFormikContext } from "formik";
import _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useListNameValues } from "../api-operations";

const itemListStyle = {
    none: "disc",
    numeric: "decimal",
    lowerCase: "lower-latin",
    upperCase: "upper-latin",
};

const ValueSourcesPresentation = () => {
    const {
        values: { valueSource, itemList, defaultValue },
    } = useFormikContext();
    const listName = _.get(valueSource, "nameList");
    const defaultValueContent = _.get(defaultValue, "value", "");
    const { data, isLoading, isError } = useListNameValues(listName);

    if (isLoading) {
        return (
            <div>
                <CircularProgress />
                <span>Cargando valores de lista</span>
            </div>
        );
    } else if (isError) {
        return (
            <div>
                <span>Error al cargar los valores de la list</span>
            </div>
        );
    } else {
        const listStyle = itemListStyle[itemList] || "disc";
        const listItems = _.orderBy(data, "key").map((item) => {
            const isSameAsDefaultValue = item.value === defaultValueContent;
            const itemClassName = isSameAsDefaultValue
                ? "text-green-700 underline font-bold"
                : "";
            return (
                <li key={item._id} className={itemClassName}>
                    <span>{item.value}</span>
                </li>
            );
        });
        return (
            <div className="flex flex-col max-w-sm mx-auto p-3 rounded-lg shadow-sm bg-gray-200">
                <span className="text-lg leading-8 tracking-tight text-gray-900">
                    Previsualización de respuestas
                </span>
                <div>
                    <ul
                        style={{
                            listStyleType: listStyle,
                        }}
                        className="text-base font-mono pl-8 mt-2"
                    >
                        {listItems}
                    </ul>
                </div>
            </div>
        );
    }
};

export default ValueSourcesPresentation;
