import PropTypes from "prop-types";
import React, { Component } from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import IconButton from "material-ui/IconButton";
import Visibility from "material-ui/svg-icons/action/visibility";
import VisibilityOff from "material-ui/svg-icons/action/visibility-off";
import _ from "lodash";

export default class Coi extends Component {
    state = {
        open: false,
    };

    static propTypes = {
        coi: PropTypes.string.required,
    };

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { coi } = this.props;
        const available =
            !_.isEqual(coi, "-") && !_.isNil(coi) && !_.isEqual(coi, "");
        const actions = [
            <FlatButton
                label="Cerrar"
                primary={true}
                onClick={this.handleClose}
            />,
        ];

        return (
            <div style={{ position: "absolute", marginTop: -24, zIndex: 99 }}>
                <IconButton
                    label="Ver condición de interpretación"
                    tooltip={"Ver interpretación"}
                    tooltipPosition="top-center"
                    onClick={this.handleOpen}
                    disabled={!available}
                >
                    {available && <Visibility />}
                    {!available && <VisibilityOff />}
                </IconButton>
                <Dialog
                    title="Condición de interpretación"
                    actions={actions}
                    modal={false}
                    open={this.state.open}
                    onRequestClose={this.handleClose}
                >
                    {coi}
                </Dialog>
            </div>
        );
    }
}
