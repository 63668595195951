import PropTypes from "prop-types";
import React, { Component } from "react";
import { arc, pie, select } from "d3";
import Faux from "react-faux-dom";
import AnalyticsItem from "./AnalyticsItem";
import { is } from "immutable";
import { addStrokeToItem } from "./ProjectFunctions";

function explode(selected, x) {
    const offset = is(x.data, selected) ? 20 : 0;
    const angle = (x.startAngle + x.endAngle) / 2;
    const xOff = Math.sin(angle) * offset;
    const yOff = -Math.cos(angle) * offset;
    return `translate(${xOff},${yOff})`;
}

class PieChart extends Component {
    static propTypes = {
        data: PropTypes.arrayOf(AnalyticsItem).isRequired,
        height: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
        onClickPortion: PropTypes.func,
        selectedItem: PropTypes.instanceOf(AnalyticsItem)
    };

    render() {
        const { data, height, width, onClickPortion, selectedItem } = this.props;
        const faux = Faux.createElement("div");
        const radius = Math.min(width, height) / 2;

        const arcFn = arc()
            .outerRadius(radius - 40)
            .innerRadius(0);

        const labelArc = arc()
            .outerRadius(radius - 80)
            .innerRadius(radius - 80);

        const pieFn = pie()
            .sort(null)
            .value((d: AnalyticsItem) => d.getValue());

        const svg = select(faux)
            .append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", `translate(${width / 2}, ${height / 2})`);

        const g = svg
            .selectAll(".arc")
            .data(pieFn(data))
            .enter()
            .append("g")
            .attr("class", "arc");

        g
            .append("path")
            .attr("d", arcFn)
            .style("fill", d => {
                const item: AnalyticsItem = d.data;
                return item.color;
            })
            .style("stroke", d => addStrokeToItem(selectedItem, d.data, "color"))
            .style("stroke-width", d => addStrokeToItem(selectedItem, d.data, "width"))
            .attr("transform", function(data) {
                return explode.bind(this, selectedItem)(data);
            })
            .on("click", item => {
                if (onClickPortion) {
                    onClickPortion(item.data);
                }
            });

        g
            .append("text")
            .attr("transform", d => `translate(${labelArc.centroid(d)})`)
            .attr("dy", ".35em")
            .text(d => {
                const item: AnalyticsItem = d.data;
                return item.getIndex();
            });

        return faux.toReact();
    }
}

export default PieChart;
