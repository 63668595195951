import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import AddIcon from "material-ui/svg-icons/content/add";
import RemoveIcon from "material-ui/svg-icons/content/remove";
import IconButton from "material-ui/IconButton";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { blue700, grey400 } from "material-ui/styles/colors";
import { updateComponent } from "../ducks";
import WaitComponent from "./waitComponent";

function getTextRemainingColumns(remainingColumns) {
    if (remainingColumns === 0) {
        return "No hay columnas disponibles para asignar";
    } else if (remainingColumns === 1) {
        return "Una columna disponible";
    } else if (remainingColumns > 1) {
        return `${remainingColumns} columnas disponibles`;
    }
    return "";
}

class OccupyColumnsComponent extends Component {
    static propTypes = {
        survey: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        parentPath: PropTypes.string.isRequired,
        page: PropTypes.string.isRequired,
        remainingColumns: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
        loadingConfiguration: PropTypes.bool.isRequired,
    };

    onChangeMinValue = () => {
        const { updateComponent, survey, page, path, value } = this.props;
        if (value > 1) {
            updateComponent(path, { columns: value - 1 }, page, survey);
        }
    };

    onChangeMaxValue = () => {
        const {
            updateComponent,
            survey,
            page,
            path,
            value,
            remainingColumns,
        } = this.props;
        const numColumns =
            _.isUndefined(remainingColumns) || _.isNull(remainingColumns)
                ? 0
                : remainingColumns;
        if (numColumns > 0) {
            updateComponent(path, { columns: value + 1 }, page, survey);
        }
    };

    render() {
        const { remainingColumns, value, loadingConfiguration } = this.props;
        const numColumns =
            _.isUndefined(remainingColumns) || _.isNull(remainingColumns)
                ? 0
                : remainingColumns;
        const allowedMod = numColumns > 0;
        if (loadingConfiguration) {
            return <WaitComponent />;
        }
        return (
            <table style={{ borderCollapse: "collapse", width: 165 }}>
                <tbody>
                    <tr>
                        <td style={{ padding: 4, width: 48 }}>
                            <IconButton
                                tooltip={"Disminuir columnas"}
                                tooltipPosition="bottom-right"
                                disabled={value === 1}
                                style={{
                                    borderRadius: "100%",
                                    backgroundColor:
                                        value === 1 ? grey400 : blue700,
                                }}
                                onClick={this.onChangeMinValue}
                            >
                                <RemoveIcon color="#FFF" />
                            </IconButton>
                        </td>
                        <td
                            style={{
                                padding: 4,
                                width: 30,
                                fontSize: 18,
                                textAlign: "center",
                            }}
                        >
                            {value}
                        </td>
                        <td style={{ padding: 4, width: 48 }}>
                            <IconButton
                                tooltip={"Aumentar columnas"}
                                tooltipPosition="bottom-right"
                                disabled={!allowedMod}
                                style={{
                                    borderRadius: "100%",
                                    backgroundColor: !allowedMod
                                        ? grey400
                                        : blue700,
                                }}
                                onClick={this.onChangeMaxValue}
                            >
                                <AddIcon color="#FFF" />
                            </IconButton>
                        </td>
                    </tr>
                    <tr>
                        <td
                            colSpan={3}
                            style={{
                                padding: "5px 7px",
                                textAlign: "center",
                                color: "#909090",
                                height: 40,
                            }}
                        >
                            {getTextRemainingColumns(remainingColumns)}
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateComponent }, dispatch);
}

function mapStateToProps({ analyticsReportsConfiguration }, ownProps) {
    const config = analyticsReportsConfiguration.get("pageConfiguration");
    const loadingConfiguration = analyticsReportsConfiguration.get(
        "loadingConfiguration"
    );
    const rowColumns = _.get(
        config,
        `${_.get(ownProps, "parentPath")}.columns`,
        1
    );
    const sections = _.get(
        config,
        `${_.get(ownProps, "parentPath")}.sections`,
        []
    );
    const sumColumns = _.reduce(
        sections,
        (sum, section) => sum + _.get(section, "columns", 1),
        0
    );
    return {
        value: _.get(config, `${_.get(ownProps, "path")}.columns`, 1),
        page: analyticsReportsConfiguration.get("page"),
        remainingColumns: rowColumns - sumColumns,
        loadingConfiguration,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OccupyColumnsComponent);
