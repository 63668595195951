import Immutable from 'immutable';
import * as actions from './constants';
import _ from 'lodash';

const initialState = Immutable.Map({
    profile: Immutable.Map(),
    profiles: Immutable.List()
});

export default (state = initialState, action) => {
    switch (action.type) {
        case actions.SAVE_PROFILE:
            return state.set("profile", action.payload.data);
        case actions.LOAD_PROFILES:
            var listData = Immutable.List.of(...action.payload.data);
            return state.set("profiles", listData);
        case actions.DELETE_PROFILE:
            var profile = action.payload.data;
            var index = _.findIndex(initialState.get('profiles'), function (item) {
                return _.isEqual(item._id, profile._id);
            });
            var currentList = state.get('profiles');
            return state.set("profiles", currentList.delete(index));
        default:
            return state;
    }
}
