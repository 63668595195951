import { axiosAPI } from '../../middleware/api';

export const GET_SURVEY_SECTIONS_INFO = "GET_SURVEY_SECTIONS_INFO";
export const GET_SURVEY_INFO = "GET_SURVEY_INFO";
export const GET_SURVEY_SECTION = "GET_SURVEY_SECTION";
export const CLEAR_SURVEY_SECTION = "CLEAR_SURVEY_SECTION";

export function FetchSurveyParam(id) {
    const request = axiosAPI.get(`/surveys/${id}`);

    return {
        type: GET_SURVEY_SECTIONS_INFO,
        payload: request
    }
}

export function FetchOnlySurveyParam(id) {
    const request = axiosAPI.get(`/surveys/${id}`);

    return {
        type: GET_SURVEY_INFO,
        payload: request
    }
}

/**
 *  @param project
 *  @param survey
 *  @param order
 *  @param user
 */
export function FetchProjectSurveyOrderUserParam(project, survey, order, user) {
    const request = axiosAPI.get(`/sections/${project}/${survey}/${order}/${user}`);

    return {
        type: GET_SURVEY_SECTION,
        payload: request
    }
}

/**
 *  @param survey
 *  @param order
 */
export function FetchSurveyOrderParam(survey, order) {
    const request = axiosAPI.get(`/sections/${survey}/${order}`);

    return {
        type: GET_SURVEY_SECTION,
        payload: request
    }
}


export function clearSection() {
    return {
        type: CLEAR_SURVEY_SECTION
    }
}
