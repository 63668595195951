import PropTypes from "prop-types";
import React, { Component } from "react";
import { reduxForm, addArrayValue } from "redux-form";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import { ListItem } from "material-ui/List";
import RaisedButton from "material-ui/RaisedButton";
import { loadSections } from "../../actions/surveys/sections";
import { FetchSurveyParam } from "../../actions/info/surveys_info_form";
import { updateOrderSections } from "../../actions/sections_form";
import { deleteSection } from "../../actions/sections_form";
import IconButton from "material-ui/IconButton";
import UpIcon from "material-ui/svg-icons/hardware/keyboard-arrow-up";
import EditIcon from "material-ui/svg-icons/editor/mode-edit";
import DownIcon from "material-ui/svg-icons/hardware/keyboard-arrow-down";
import _ from "lodash";
import { Link } from "react-router";
import { toggleSnackbar } from "../../actions/commons";
import FloatingActionButtonTooltip from "../fields/FloatingActionButtonTooltip/FloatingActionButtonTooltip";
import TableListValuesSection from "../fields/TableListValuesSection/TableListValuesSection";
import DeleteEntityModal from "../deleteEntityModal/component";
import { LBLSURVEYS } from "../../constants/labels";
import ButtonBack from "../fields/buttonBack/buttonBack";
import AuthComponent from "../AuthComponent";

const style = {
        margin: 20,
        float: "right"
    },
    stylePaper = {
        marginTop: 20,
        marginBottom: 20
    };

let updateLoad = true;

class SurveysInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            idSectionSelected: ""
        };
        this._saveConfigSectionsSurveys = this._saveConfigSectionsSurveys.bind(this);
    }

    static propTypes = {
        fields: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired
    };

    UNSAFE_componentWillMount() {
        const { params } = this.props;
        updateLoad = true;
        this.props.loadSections(_.get(params, "_id"));
        this.props.FetchSurveyParam(_.get(params, "_id"));
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (updateLoad) {
            updateLoad = false;
            this.setState({
                idSectionSelected: ""
            });
        }
    }

    deleteSections(section, sections) {
        const self = this;
        self.props
            .deleteSection(section, sections)
            .then(
                data => {
                    if (_.get(data, "error") || _.get(data, "payload.status") !== 200) {
                        self.props.toggleSnackbar(true, "Hubo un error al eliminar la sección");
                    } else {
                        self.props.toggleSnackbar(true, "La sección se eliminó correctamente.");
                    }
                },
                reason => {
                    self.props.toggleSnackbar(true, "Hubo un error al eliminar");
                }
            )
            .catch(data => {
                self.props.toggleSnackbar(true, "Hubo un error al eliminar");
            });
    }

    _saveConfigSectionsSurveys(formData) {
        const self = this;
        self.props
            .updateOrderSections(formData)
            .then(
                data => {
                    if (_.get(data, "error") || _.get(data, "payload.status") !== 200) {
                        self.props.toggleSnackbar(true, "Hubo un error al guardar el orden");
                    } else {
                        self.props.toggleSnackbar(true, "Orden de secciones guardado correctamente.");
                    }
                },
                reason => {
                    self.props.toggleSnackbar(true, "Hubo un error al guardar");
                }
            )
            .catch(data => {
                self.props.toggleSnackbar(true, "Hubo un error al guardar");
            });
    }

    renderDescription(descrip) {
        let description = "";
        if (!_.isUndefined(descrip) && !_.isEqual(descrip, "")) {
            description = _.split(descrip, /\r\n|\r|\n/g);
        }
        if (description instanceof Array) {
            return (
                <h3 style={{ textAlign: "justify", fontWeight: 200 }}>
                    {description.map((value, index) => {
                        if (!_.isEqual(value, "")) {
                            return (
                                <div key={index}>
                                    {value}
                                    <br />
                                    <br />
                                </div>
                            );
                        }
                    })}
                </h3>
            );
        }
        return <h3 style={{ textAlign: "justify", fontWeight: 200 }}>{description}</h3>;
    }

    render() {
        const configDeleteModal = {
            display: "table-cell"
        };
        const { fields: { description, name, _id, sections }, locked, params, handleSubmit } = this.props;
        if (!_.isUndefined(description.value) && !_.isUndefined(name.value)) {
            return (
                <Row>
                    <AuthComponent permission={"cgr_surveys_config"} type={"url"} />
                    <Col xs={12} mdOffset={2} md={8}>
                        <Paper style={stylePaper}>
                            <Row>
                                <Col xsOffset={1} xs={10}>
                                    <Row>
                                        <Col xs>
                                            <ButtonBack url={`/admin/surveys/grid`} />
                                            <h1 style={{ textAlign: "center", fontWeight: 400, lineHeight: 1.3 }}>{name.initialValue}</h1>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs>{this.renderDescription(description.initialValue)}</Col>
                                    </Row>
                                    <Row>
                                        <Col xs>
                                            <AuthComponent
                                                component={
                                                    <Link
                                                        to={`/admin/surveys/edit/${_.get(params, "_id")}`}
                                                        style={locked ? { display: "none" } : {}}
                                                    >
                                                        <RaisedButton
                                                            label={LBLSURVEYS.buttons.edit}
                                                            secondary
                                                            disabled={locked}
                                                            style={style}
                                                        />
                                                    </Link>
                                                }
                                                permission={"cgr_surveys_update"}
                                                type={"component"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs>
                                            {locked ? (
                                                <AuthComponent
                                                    component={
                                                        <Link to={`/admin/surveys/${_.get(params, "_id")}/analytics/config`}>
                                                            <RaisedButton
                                                                label={LBLSURVEYS.buttons.configReport}
                                                                secondary
                                                                style={{ margin: "0 20px 30px 20px", float: "right" }}
                                                            />
                                                        </Link>
                                                    }
                                                    permission={"cgr_surveys_config_report"}
                                                    type={"component"}
                                                />
                                            ) : (
                                                <span />
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs>
                                            <h1 style={{ textAlign: "center", fontWeight: 400 }}>{`Secciones`}</h1>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs>
                                            <AuthComponent
                                                component={
                                                    <Link
                                                        to={`/admin/sections/create/${_.get(params, "_id")}`}
                                                        style={locked ? { display: "none" } : {}}
                                                    >
                                                        <FloatingActionButtonTooltip textTooltip="Agregar sección" />
                                                    </Link>
                                                }
                                                permission={"cgr_sections_create"}
                                                type={"component"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs>
                                            <form onSubmit={handleSubmit(this._saveConfigSectionsSurveys)}>
                                                <Row>
                                                    <Col xs>
                                                        {!sections.length && (
                                                            <h3 style={{ marginTop: 30, textAlign: "center" }}>No hay secciones</h3>
                                                        )}
                                                        {sections.map((section, index) => (
                                                            <Row key={`${section._id.value}`}>
                                                                <Col xs={8}>
                                                                    <ListItem
                                                                        primaryText={`${section.title.value}`}
                                                                        secondaryText={`${section.description.value}`}
                                                                        secondaryTextLines={2}
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                idSectionSelected: section._id.value
                                                                            });
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col xs={4}>
                                                                    <AuthComponent
                                                                        component={
                                                                            <IconButton
                                                                                children={<UpIcon />}
                                                                                disabled={index === 0 || locked}
                                                                                tooltip="Subir"
                                                                                tooltipPosition="top-center"
                                                                                onClick={() => {
                                                                                    sections.swapFields(index, index - 1);
                                                                                }}
                                                                            />
                                                                        }
                                                                        permission={"cgr_sections_change_order"}
                                                                        type={"component"}
                                                                    />
                                                                    <AuthComponent
                                                                        component={
                                                                            <IconButton
                                                                                children={<DownIcon />}
                                                                                disabled={index === sections.length - 1 || locked}
                                                                                tooltip="Bajar"
                                                                                tooltipPosition="top-center"
                                                                                onClick={() => {
                                                                                    sections.swapFields(index, index + 1);
                                                                                }}
                                                                            />
                                                                        }
                                                                        permission={"cgr_sections_change_order"}
                                                                        type={"component"}
                                                                    />
                                                                    <AuthComponent
                                                                        component={
                                                                            <Link
                                                                                to={`/admin/sections/edit/${_id.initialValue}/${section._id
                                                                                    .value}`}
                                                                                style={locked ? { display: "none" } : {}}
                                                                            >
                                                                                <IconButton
                                                                                    children={<EditIcon />}
                                                                                    tooltip={LBLSURVEYS.buttons.edit}
                                                                                    disabled={locked}
                                                                                    tooltipPosition="top-center"
                                                                                />
                                                                            </Link>
                                                                        }
                                                                        permission={"cgr_sections_update"}
                                                                        type={"component"}
                                                                    />
                                                                    <AuthComponent
                                                                        component={
                                                                            <DeleteEntityModal
                                                                                tooltip={LBLSURVEYS.tooltips.btnDelete}
                                                                                title={LBLSURVEYS.titleDeleteSection}
                                                                                message={LBLSURVEYS.msg.deleteModalSection(
                                                                                    section.title.value
                                                                                )}
                                                                                valid={locked}
                                                                                fn={() => {
                                                                                    this.deleteSections(section, sections);
                                                                                    sections.removeField(index);
                                                                                }}
                                                                                args={[section]}
                                                                                style={configDeleteModal}
                                                                            />
                                                                        }
                                                                        permission={"cgr_sections_delete"}
                                                                        type={"component"}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        ))}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs>
                                                        <AuthComponent
                                                            component={
                                                                <RaisedButton
                                                                    type="submit"
                                                                    label="Guardar Orden"
                                                                    disabled={!sections.length || sections.length <= 1 || locked}
                                                                    secondary
                                                                    style={style}
                                                                />
                                                            }
                                                            permission={"cgr_sections_change_order"}
                                                            type={"component"}
                                                        />
                                                    </Col>
                                                </Row>
                                            </form>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs>
                                            <TableListValuesSection
                                                idSection={this.state.idSectionSelected}
                                                visible={!_.isEqual(this.state.idSectionSelected, "")}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Paper>
                    </Col>
                </Row>
            );
        }
        return <div />;
    }
}

function mapStateToProps({ surveySections, surveys }) {
    return {
        locked: _.get(surveys, "all.data.locked", true),
        initialValues: {
            description: _.get(surveys, "all.data.description"),
            name: _.get(surveys, "all.data.name"),
            _id: _.get(surveys, "all.data._id"),
            sections: _.isUndefined(surveySections.get("all").toArray()) ? [] : surveySections.get("all").toArray()
        }
    };
}

export default reduxForm(
    {
        form: "SurveysInfoNewForm",
        fields: [
            "description",
            "name",
            "_id",
            "sections[]._id",
            "sections[].title",
            "sections[].description",
            "sections[].questions[].title",
            "sections[].questions[].type"
        ]
    },
    mapStateToProps,
    { toggleSnackbar, updateOrderSections, loadSections, FetchSurveyParam, deleteSection }
)(SurveysInfo);
