import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, addArrayValue } from 'redux-form';
import { Row, Col } from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';
import {ListItem} from 'material-ui/List';
import { loadSections } from '../../actions/surveys/sections';
import { FetchSurveyParam } from '../../actions/info/surveys_info_form';
import _ from 'lodash';
import { toggleSnackbar } from '../../actions/commons';
import TableListValuesSection from '../fields/TableListValuesSection/TableListValuesSection';
import ButtonBack from '../../components/fields/buttonBack/buttonBack';
import AuthComponent from '../../components/AuthComponent';

const style = {
        margin: 20,
        float: 'right'
    },
    stylePaper = {
        marginTop: 20,
        marginBottom: 20
    };

let updateLoad = true;
let urlBack = "";

class SurveysInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            idSectionSelected: ''
        };
    }

    static propTypes = {
         fields: PropTypes.object.isRequired,
         handleSubmit: PropTypes.func.isRequired
    };

    UNSAFE_componentWillMount() {
        const {
            profile,
            params
        } = this.props;

        urlBack = `/admin/projects/info/${_.get(params, 'profile')}`;

        updateLoad = true;
        this.props.loadSections(params._id);
        this.props.FetchSurveyParam(params._id);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (updateLoad) {
            updateLoad = false;
            this.setState({
                idSectionSelected: ''
            });
        }
    }

    renderDescription(descrip){
        let description = "";
        if(!_.isUndefined(descrip) && !_.isEqual(descrip, "")){
            description = _.split(descrip, /\r\n|\r|\n/g);
        }
        if(description instanceof Array){
            return (
                <h3 style={{textAlign: 'justify', fontWeight: 200}}>
                    {description.map((value, index) => {
                        if(!_.isEqual(value, '')){
                            return (
                                <div key={index}>{value}<br/><br/></div>
                            );
                        }
                    })}
                </h3>
            );
        } else {
            return (
                <h3 style={{textAlign: 'justify', fontWeight: 200}}>{description}</h3>
            );
        }
    }

    render() {
        const {
            fields: {
                description,
                name,
                _id,
                sections
                },
            handleSubmit,
            params
        } = this.props;
        if(!_.isUndefined(description.value) && !_.isUndefined(name.value)){
            return (
              <Row>
                  <AuthComponent
                      permission={"cgr_survey_visualization"}
                      type={"url"}
                  />
                  <Col xs={12} mdOffset={2} md={8}>
                      <Paper style={stylePaper}>
                          <Row>
                              <Col xsOffset={1} xs={10}>
                                  <Row>
                                      <Col xs>
                                          <h1 style={{textAlign: 'center', fontWeight: 400, lineHeight: 1.3}}>{name.initialValue}</h1>
                                          <ButtonBack url={`${urlBack}/${params.project}/${params.company}`}/>
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col xs>
                                          {this.renderDescription(description.initialValue)}
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col xs>
                                          <h1 style={{textAlign: 'center', fontWeight: 400}}>{`Secciones`}</h1>
                                      </Col>
                                  </Row>
                                  <Row style={{paddingBottom: 30}}>
                                      <Col xs>
                                          {!sections.length && <h3 style={{marginTop: 30, textAlign: 'center'}}>No hay secciones</h3>}
                                          {sections.map((section, index) =>
                                              <Row key={`${section._id.value}`}>
                                                  <Col xs>
                                                      <ListItem
                                                          primaryText={`${index+1}. ${section.title.value}`}
                                                          secondaryText={`${section.description.value}`}
                                                          secondaryTextLines={2}
                                                          onClick={() => {
                                                              this.setState({
                                                                  idSectionSelected: section._id.value
                                                              });
                                                          }}
                                                      />
                                                  </Col>
                                              </Row>
                                            )}
                                      </Col>
                                  </Row>
                                  <Row>
                                      <Col xs>
                                          <TableListValuesSection
                                              idSection={this.state.idSectionSelected}
                                              visible={!_.isEqual(this.state.idSectionSelected, '')}
                                          />
                                      </Col>
                                  </Row>
                              </Col>
                          </Row>
                      </Paper>
                  </Col>
              </Row>
            );
        } else {
            return (
                <div></div>
            );
        }
    }
}

function mapStateToProps({surveySections, surveys, login}) {
    return {
        profile: login.get('profile'),
        initialValues: {
            description: _.get(surveys, 'all.data.description'),
            name: _.get(surveys, 'all.data.name'),
            _id: _.get(surveys, 'all.data._id'),
            sections: _.isUndefined(surveySections.get('all').toArray()) ? [] : surveySections.get('all').toArray()
        }
    };
}

export default reduxForm({
    form: 'SurveysInfoNewForm',
    fields: [
        'description',
        'name',
        '_id',
        'sections[]._id',
        'sections[].title',
        'sections[].description',
        'sections[].questions[].title',
        'sections[].questions[].type'
    ]
}, mapStateToProps, {toggleSnackbar, loadSections, FetchSurveyParam})(SurveysInfo);
