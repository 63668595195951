import React from "react";
import { useFormikContext } from "formik";
import _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import { LBLQUESTIONS } from "Labels";
import { isBlank } from "Utils";
import { useListNameValues } from "../api-operations";
import SelectField from "./select-field";

const QuantitySelectableField = () => {
    const {
        values: { valueSource, editMode },
    } = useFormikContext();
    const listName = _.get(valueSource, "nameList");
    const { isLoading, isError, data } = useListNameValues(listName);
    const isDisabled = editMode || isBlank(listName);
    if (isLoading) {
        return (
            <div>
                <CircularProgress />
                <span>Cargando valores de lista</span>
            </div>
        );
    } else if (isError) {
        return (
            <div>
                <span>Error al cargar los valores de la list</span>
            </div>
        );
    } else {
        const values = _.range(0, data.length)
            .map((item) => item + 1)
            .map((value) => {
                return {
                    id: String(value),
                    label: String(value),
                };
            });
        return (
            <SelectField
                name="quantitySelectable"
                helperText=""
                variant="outlined"
                id="quantity-selectable"
                disabled={isDisabled}
                label={LBLQUESTIONS.optionsSelectable}
                items={values}
            />
        );
    }
};

export default QuantitySelectableField;
