import React, {Component} from 'react';
import {CardMedia} from 'material-ui/Card';
import { connect } from 'react-redux';
import Message from './message';
import _ from 'lodash';

class messagesDashboard extends Component {
    constructor(props) {
        super(props)
    }

    _renderMessages(message) {
        return (<Message key={_.uniqueId("message_")} message={message}/>);
    }

    render() {
        return (
            <CardMedia>
                {this.props.messages.map(this._renderMessages)}
            </CardMedia>
        );
    }
}

function mapStateToProps({chat}) {
    return {
        messages: chat.get('messages')
    }
}

export default connect(mapStateToProps)(messagesDashboard);
