import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import { Table, TableBody, TableRow, TableRowColumn, TableHeader, TableHeaderColumn } from 'material-ui/Table';
import {Row, Col} from 'react-flexbox-grid';
import {blue700} from 'material-ui/styles/colors';

class DataQuestionsRaw extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { result } = this.props;
        if(_.isNull(result)){
            return ( <div></div> )
        } else {
            let styleColumn = { whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'justify' };
            return (
                <div>
                    <Row style={{padding: '0 20px'}}>
                        <Col xs>
                            <h1 style={{fontWeight: 400, marginTop: 30}}>Información general</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs>
                            <Table selectable={false}>
                                <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                                    <TableRow>
                                        <TableHeaderColumn>Pregunta</TableHeaderColumn>
                                        <TableHeaderColumn>Respuesta</TableHeaderColumn>
                                    </TableRow>
                                </TableHeader>
                                <TableBody displayRowCheckbox={false}>
                                    {_.map(_.get(result, 'questionsRaw', []), (questionRaw, index) => {
                                        return (
                                            <TableRow key={`questionsRaw${index}`}>
                                                <TableRowColumn style={styleColumn}>
                                                    {_.get(questionRaw, 'questionTitle')}
                                                </TableRowColumn>
                                                <TableRowColumn style={styleColumn}>
                                                    {_.get(questionRaw, 'answerValue')}
                                                </TableRowColumn>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Col>
                    </Row>
                </div>
            );
        }
    }
}

export default DataQuestionsRaw;
