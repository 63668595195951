import { Record } from "immutable";
import { schemeCategory10 } from "d3";
import sample from "lodash/sample";

const AnalyticsRecord = Record({
    label: "",
    value: 0,
    color: undefined,
    details: undefined,
    type: undefined,
    index: 0,
    coi: ""
});

class AnalyticsItem extends AnalyticsRecord {
    getColor() {
        return this.get("color") || sample(schemeCategory10);
    }

    getLabel() {
        return this.get("label");
    }

    getValue() {
        return this.get("value");
    }

    setIndex(index) {
        return this.set("index", index);
    }

    getIndex() {
        return `#${this.get("index")}`;
    }

    get coi() {
        return this.get("coi");
    }

    get index() {
        return `#${this.get("index")}`;
    }

    get value() {
        return this.get("value");
    }

    get color() {
        return this.get("color") || sample(schemeCategory10);
    }

    inspect() {
        return this.toJS();
    }
}

export default AnalyticsItem;
