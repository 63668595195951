import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Toggle from 'material-ui/Toggle';
import { addPermission } from '../../actions/profile_form';
import _ from 'lodash';
import {red700, blue700, blue100, blue200 } from 'material-ui/styles/colors';

class ToggleComponent extends Component {

    constructor(props) {
        super(props);
    }

    _savePermission(value, id){
        const { addPermission } = this.props;
        addPermission(value, id);
    }

    render() {
        const {
            id,
            label,
            isToggle
        } = this.props;
        return (
            <Toggle
                label={label}
                style={{ marginBottom: 15 }}
                labelPosition={'left'}
                thumbStyle={{ backgroundColor: blue200 }}
                trackStyle={{ backgroundColor: blue100 }}
                thumbSwitchedStyle={{ backgroundColor: blue700 }}
                trackSwitchedStyle={{ backgroundColor: blue100 }}
                toggled={isToggle}
                onToggle={(e, value) => this._savePermission(value, id) }
            />
        );

    }
}

ToggleComponent.propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addPermission
    }, dispatch);
}

function mapStateToProps({profileForm}, ownProps) {
    let isToggle = _.indexOf(profileForm.get('permissions').toArray(), _.get(ownProps, 'id')) !== -1;
    /*let isToggle = false;
    _.forEach(profileForm.get('permissions').toArray(), p => {
        if(_.isEqual(p, _.get(ownProps, 'id'))){
            isToggle = true;
        }
    });*/
    return {
        isToggle: isToggle
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ToggleComponent);
