// @flow
import React from "react";
import CircularProgress from "material-ui/CircularProgress";

type PropTypes = {
    loading: boolean,
};

const LoadingConf = (props: PropTypes) =>
    props.loading ? (
        <div style={{ textAlign: "center" }}>
            <CircularProgress style={{ marginTop: 25, marginBottom: 25 }} />
        </div>
    ) : null;

export default LoadingConf;
