import { POST_COMPANY, DELETE_COMPANY} from '../constants/index';
import { axiosAPI } from '../middleware/api';
import _ from 'lodash';

export function createCompany(props, editMode) {

    let request = null;
    if(editMode){
        request = axiosAPI.put(`/companies`, {'company': _.chain(props)
                                                                .set('type', 'NIT')
                                                                .omit('_id')
                                                                .value()
        });
    } else {
        request = axiosAPI.post(`/companies`, {'company': _.chain(props)
                                                                .set('type', 'NIT')
                                                                .omit('_id')
                                                                .value()
        });
    }

    return {
        type: POST_COMPANY,
        payload: request
    }
}

export function deleteCompany(companyId) {

    const request = axiosAPI.delete(`/companies/${companyId}`, {data: {"type": 'logic'}});

    return {
        type: DELETE_COMPANY,
        payload: request,
        meta: {
            companyId
        }
    }
}
