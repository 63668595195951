import React, {Component} from 'react';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import {Row, Col} from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';
import { deleteCompany } from '../../actions/companies_form';
import { FetchCompany } from '../../actions/grid/companies_grid_form';
import { toggleSnackbar } from '../../actions/commons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import IconButton from 'material-ui/IconButton';
import SettingsIcon from 'material-ui/svg-icons/action/settings';
import EditIcon from 'material-ui/svg-icons/editor/mode-edit';
import { Link } from 'react-router';
import { blue700 } from 'material-ui/styles/colors';
import { LBLCOMPANIES } from '../../constants/labels';
import FloatingActionButtonTooltip from '../fields/FloatingActionButtonTooltip/FloatingActionButtonTooltip';
import DownloadFile from '../../components/fields/DownloadFile/DownloadFile';
import UploadFile from '../../components/fields/UploadFile/UploadFile';
import _ from 'lodash';
import LoadingPage from '../../components/commons/loadingPage';
import AuthComponent from '../../components/AuthComponent';
import TablePagination from '../TablePagination/tablePagination';
import FilterCompanies from '../FilterCompanies/FilterCompanies';
import { cleanFilterCompanies } from '../FilterCompanies/actions';

class CompaniesGrid extends Component {

    constructor(props) {
        super(props);
    }

    UNSAFE_componentWillMount() {
        const { cleanFilterCompanies } = this.props;
        cleanFilterCompanies();
    }

    render() {
        const { economicActivity, employeesNum, country, department, city } = this.props;
        const body = [
            {
                field: "nit",
                title: "NIT",
                filter: true,
            },
            {
                field: "businessName",
                title: "Razón social",
                colspan: 3,
                filter: true,
            },
            {
                actions: true,
                title: "Operaciones"
            }
        ];

        let extra = {};
        if(!_.isEqual(economicActivity, 'all') && !_.isNull(economicActivity) && !_.isUndefined(economicActivity)){
            extra = _.set(extra, 'economicActivity', economicActivity);
        }
        if(!_.isEqual(employeesNum, 'all') && !_.isNull(employeesNum) && !_.isUndefined(employeesNum)){
            extra = _.set(extra, 'numEmployees', employeesNum);
        }
        if(!_.isEqual(country, 'all') && !_.isNull(country) && !_.isUndefined(country)){
            extra = _.set(extra, 'country', country);
            if(!_.isEqual(department, 'all') && !_.isNull(department) && !_.isUndefined(department)){
                extra = _.set(extra, 'department', department);
                if(!_.isEqual(city, 'all') && !_.isNull(city) && !_.isUndefined(city)){
                    extra = _.set(extra, 'city', city);
                }
            }
        }

        return (
            <Row>
                <AuthComponent
                    component={
                        <Col xs={12} mdOffset={2} md={8}>
                            <Paper style={{ marginTop: 20, marginBottom: 20 }}>
                                <Row>
                                    <Col xs>
                                        <div style={{width: '100%', background: blue700, paddingTop: 5, paddingBottom:5, color: "#FFF"}}>
                                            <h1 style={{textAlign: 'center', fontWeight: 400}}>{LBLCOMPANIES.titleGrid}</h1>
                                        </div>
                                    </Col>
                                </Row>
                                <AuthComponent
                                    component={<Row style={{paddingTop: 15, paddingBottom: 15}}>
                                                    <Col xs style={{textAlign: 'center'}}>
                                                        <DownloadFile storeName="companies" />
                                                    </Col>
                                                    <Col xs style={{textAlign: 'center'}}>
                                                        <UploadFile storeName="companies" />
                                                    </Col>
                                                </Row>}
                                    permission={"cgr_company_load_massive"}
                                    type={"component"}
                                />
                                <Row>
                                    <Col xs>
                                        <AuthComponent
                                            component={<div>
                                                            <Link to={`/admin/companies/create`}>
                                                                <FloatingActionButtonTooltip textTooltip={LBLCOMPANIES.tooltips.btnCreate}/>
                                                            </Link>
                                                        </div>}
                                            permission={"cgr_company_create"}
                                            type={"component"}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <FilterCompanies
                                            URL={`/companies`}
                                            version={1}
                                            extra={extra}
                                            body={body}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <TablePagination
                                            name={`companies`}
                                            URL={`/companies`}
                                            version={1}
                                            extra={extra}
                                            body={body}
                                        />
                                    </Col>
                                </Row>
                            </Paper>
                        </Col>
                    }
                    permission={"cgr_company_list"}
                    type={"url"}
                />
            </Row>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        cleanFilterCompanies
    }, dispatch);
}

function mapStateToProps({companies, filterCompany}) {
    return {
        economicActivity: filterCompany.get("economicActivity"),
        employeesNum: filterCompany.get("employeesNum"),
        country: filterCompany.get("country"),
        department: filterCompany.get("department"),
        city: filterCompany.get("city"),
        companies: companies.all,
        numFound: companies.numFound
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesGrid);
