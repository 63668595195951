import { OPEN_MODAL_FORM_GROUPER, CLOSE_MODAL_FORM_GROUPER, SAVE_NAME_GROUPER_GROUPER } from './constants';
import { axiosAPI_V2 } from '../../middleware/api';

export function openModalForm(id, name, visible, priority) {
    return {
        type: OPEN_MODAL_FORM_GROUPER,
        meta: {
            id: id,
            name: name,
            visible: visible,
            priority: priority
        }
    }
}


export function closeModal() {
    return {
        type: CLOSE_MODAL_FORM_GROUPER
    }
}


export function saveNameGrouper(id, name, visible, priority) {

    const request = axiosAPI_V2.put(`/surveygroupers/grouper/${id}`, { grouper: {
                                                                                name: name,
                                                                                visible: visible,
                                                                                priority: priority
                                                                            }});

    return {
        type: SAVE_NAME_GROUPER_GROUPER,
        payload: request,
        meta: {
            id: id,
            name: name,
            visible: visible,
            priority: priority
        }
    };
}
