import React, {Component} from 'react';
import Snackbar from 'material-ui/Snackbar';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toggleSnackbar} from '../../actions/commons';

class SnackbarMessage extends Component {
    constructor(props) {
        super(props);

        this._onRequestClose = this._onRequestClose.bind(this);
    }

    _onRequestClose() {
        this.props.toggleSnackbar(false);
    }

    render() {
        const {status, message} = this.props.snackbar;
        return (
            <Snackbar
                open={status}
                message={message}
                autoHideDuration={6000}
                onRequestClose={this._onRequestClose}
            />
        );
    }
}

function mapStateToProps({snackbar}) {
    return {snackbar};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        toggleSnackbar
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarMessage);
