import PropTypes from "prop-types";
import React, { Component } from "react";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import _ from "lodash";
import { connect } from "react-redux";
import Title from "./Title";
import { LBLSURVEYS } from "../../../constants/labels";

const styleWidthContent = {
    minWidth: 240,
};

class QuestionListClose extends Component {
    constructor(props) {
        super(props);
        this._onChange = this._onChange.bind(this);
    }

    _onChange(e, value) {
        this.props._handlerUpdateValue(e, value);
    }

    renderOptions(options, itemList) {
        let Pares = OptionCouples(options);
        let array;
        if (_.isEqual(itemList, "lowerCase")) {
            array = LBLSURVEYS.arrays.lowercase;
        } else if (_.isEqual(itemList, "upperCase")) {
            array = LBLSURVEYS.arrays.uppercase;
        } else {
            array = [];
        }
        let OptionList = _.map(options, function (op) {
            return _.set(op, "key", parseInt(_.get(op, "key")));
        });
        return _.map(
            _.orderBy(OptionList, "key", "asc"),
            (option, position) => {
                let enumeration =
                    _.size(array) !== 0
                        ? _.size(array) <= position
                            ? ""
                            : array[position] + "."
                        : _.isEqual(itemList, "numeric")
                        ? position + 1 + "."
                        : "";
                return (
                    <RadioButton
                        value={`${option._id}`}
                        key={option._id}
                        label={`${enumeration} ${option.value}`}
                        style={{
                            marginBottom: 5,
                            width: Pares,
                            float: position % 2 === 0 && "left",
                        }}
                    />
                );
            }
        );
    }

    render() {
        const { question, index, valueQuestion } = this.props;
        const itemList = _.has(question, "itemList")
            ? _.get(question, "itemList")
            : "none";
        return (
            <div style={styleWidthContent}>
                <div style={{ marginBottom: 10 }}>
                    <Title
                        question={
                            _.has(question, "fieldName")
                                ? question
                                : _.get(question, "_id")
                        }
                        index={index}
                    />
                </div>
                <RadioButtonGroup
                    name={question._id}
                    onChange={this._onChange}
                    valueSelected={valueQuestion}
                >
                    {this.renderOptions(
                        question.valueSource.list.values,
                        itemList
                    )}
                </RadioButtonGroup>
            </div>
        );
    }
}

function OptionCouples(options) {
    let Pairs = "50%";
    options.map((option, position) => {
        let val = option.value;
        if (_.size(val.split(" ")) >= 3) {
            let sizeLetter = 0;
            val.split(" ").map((vl) => {
                if (_.size(vl) >= 4) {
                    sizeLetter++;
                }
            });
            if (sizeLetter >= 2) {
                Pairs = "100%";
            }
        }
    });
    return Pairs;
}

QuestionListClose.propTypes = {
    question: PropTypes.object.isRequired,
};

function mapStateToProps({ questionsSurvey }, ownerProps) {
    return {
        valueQuestion: _.get(
            questionsSurvey.get("answered").toObject(),
            _.get(ownerProps, "idQuestion")
        ),
    };
}

export default connect(mapStateToProps)(QuestionListClose);
