import React, {Component} from 'react';
import {Row, Col} from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';
import IconButton from 'material-ui/IconButton';
import EditIcon from 'material-ui/svg-icons/editor/mode-edit';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import {blue700} from 'material-ui/styles/colors';
import {LBLCONSENTS} from '../../constants/labels';
import FloatingActionButtonTooltip from '../fields/FloatingActionButtonTooltip/FloatingActionButtonTooltip';
import AuthComponent from '../AuthComponent';
import TablePagination from '../TablePagination/tablePagination';

const stylePaper = {
        marginTop: 20,
        marginBottom: 20
    };

class ConsentsGrid extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const body = [
            {
                field: "name",
                title: "Nombre",
                filter: true,
                colspan: 3
            },
            {
                actions: true,
                title: "Operaciones"
            }
        ];
        return (
            <Row>
                <AuthComponent
                    component={
                        <Col mdOffset={2} md={8} xs={12}>
                            <Paper style={stylePaper}>
                                <Row>
                                    <Col xs>
                                        <Row>
                                            <Col xs>
                                                <div style={{width: '100%', background: blue700, paddingTop: 5, paddingBottom:5, color: "#FFF"}}>
                                                    <h1 style={{textAlign: 'center', fontWeight: 400}}>{LBLCONSENTS.titleGrid}</h1>
                                                    <AuthComponent
                                                        component={<Link to={`/admin/consents/create`}>
                                                                        <FloatingActionButtonTooltip textTooltip={LBLCONSENTS.tooltips.btnCreate}/>
                                                                    </Link>}
                                                        permission={"cgr_consent_create"}
                                                        type={"component"}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs>
                                                <TablePagination
                                                    name={`consents`}
                                                    URL={`/consents`}
                                                    version={1}
                                                    body={body}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Paper>
                        </Col>
                    }
                    permission={"cgr_consents_list"}
                    type={"url"}
                />
            </Row>
        );
    }
}

function mapStateToProps(state) {
    return {consents: state.consents.all};
}

export default connect(mapStateToProps)(ConsentsGrid);
