import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import ChartComponent from "../../../reportComponents/chartComponent";
import BarStackedGroupFilterComponent from "../../../reportComponents/chartsComponents/barStackedGroupFilterComponent";
import BarStacked3DGroupFilterComponent from "../../../reportComponents/charts3DComponents/barStacked3DGroupFilterComponent";
import LegendComponent from "../../../reportComponents/legends/legendComponent";
import { getRamdonString } from "../../../../lib/util";
import {
    generateData,
    generateDataStacked,
    generateDataMultiStacked,
    getNumberValues,
    getPencetage,
    getTextRandom,
} from "./functions";
import { chartTypes } from "../../../reportComponents/types";
import { SOCIO_DEMOGRAPHIC, FILTER_GROUPS, INDICATOR, QUESTION } from "../../../analyticsReport/types";

function isDemographicOrGroupFilter(type) {
    return _.indexOf([SOCIO_DEMOGRAPHIC, FILTER_GROUPS], type) !== -1;
}

const styles = {
    titleGroup: {
        fontWeight: 400,
        textAlign: "right",
        margin: "2px 0px 5px 0px",
        padding: "4px 0 0 0",
        fontSize: 12,
        width: 130,
        display: "inline-block",
        verticalAlign: "top",
        cursor: "pointer",
    },
    contentCoi: {
        backgroundColor: "#FBFBFB",
        borderRadius: 8,
        minWidth: 170,
        display: "inline-block",
        verticalAlign: "top",
        textAlign: "left",
        padding: "7px 10px",
        margin: 5,
    },
    textCoi: {
        textAlign: "justify",
        fontSize: 12,
        lineHeight: 1.4,
    },
    titleNullValues: {
        width: "80%",
        padding: 7,
        margin: "11px auto",
        fontWeight: 400,
        borderRadius: 8,
        background: "#CA6464",
        color: "#FFF",
        textAlign: "center",
    },
};

function getSizeTitle() {
    return 130;
}

function getValueSize(size) {
    let s = "middle";
    if ((!_.isUndefined(size) && !_.isNull(size)) || !_.isEqual(size, "")) {
        if (_.indexOf(["small", "middle", "large"], size) !== -1) {
            s = size;
        }
    }
    switch (s) {
        case "small":
            return 220;
        case "middle":
            return 310;
        case "large":
            return 400;
        default:
            return 200;
    }
}

function isFullWidth(numColumns, contentColumns, sizeComponent) {
    if (contentColumns * 100 / numColumns / 100 * 980 - sizeComponent - 50 < 330) {
        return true;
    }
    return false;
}

function getSizeCoi(numColumns, contentColumns, size) {
    const chartSize = getValueSize(size);
    const componentSize = chartSize + getSizeTitle();
    if (_.indexOf([1, 2, 3, 4, 5], numColumns) !== -1) {
        const s = contentColumns * 100 / numColumns / 100 * 980 - componentSize - 50;
        if (isFullWidth(numColumns, contentColumns, componentSize)) {
            return "86%";
        }
        return s - 53;
    }
    return "86%";
}

class Charts extends Component {
    state = {
        data: [],
        keysStacked: [],
        dataStacked: [],
        standardColors: ["#E34242", "#58C257", "#EB9850", "#EDE969"],
        textRandom: "",
    };

    static propTypes = {
        size: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        typeComponent: PropTypes.string.isRequired,
        items: PropTypes.any.isRequired,
        numColumns: PropTypes.number.isRequired,
        contentColumns: PropTypes.number.isRequired,
        indicators: PropTypes.any,
        hasCoi: PropTypes.bool.isRequired,
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { items } = this.props;
        const { standardColors } = this.state;
        const newItems = _.get(nextProps, "items", []);
        if (!_.isEqual(_.size(items), _.size(newItems))) {
            const { typeComponent, randomColors } = this.props;
            const dafaultColor = isDemographicOrGroupFilter(typeComponent) ? standardColors : [];
            const countValues = isDemographicOrGroupFilter(typeComponent) ? 4 : getNumberValues();
            const COLORS = _.concat(dafaultColor, randomColors);
            const numItems = _.size(newItems) === 0 ? 1 : _.size(newItems);
            const multiStacked = generateDataMultiStacked(COLORS, countValues, numItems);
            this.setState({
                multiKeysStacked: _.get(multiStacked, "keysStacked", []),
                multiDataStacked: _.get(multiStacked, "dataStacked", []),
            });
        }
    }

    componentDidMount() {
        const { typeComponent, randomColors, items } = this.props;
        const { standardColors } = this.state;
        const dafaultColor = isDemographicOrGroupFilter(typeComponent) ? standardColors : [];
        const countValues = isDemographicOrGroupFilter(typeComponent) ? 4 : getNumberValues();
        const COLORS = _.concat(dafaultColor, randomColors);
        const stacked = generateDataStacked(COLORS, countValues);
        const numItems = _.size(items) === 0 ? 1 : _.size(items);
        const multiStacked = generateDataMultiStacked(COLORS, countValues, numItems);
        this.setState({
            data: generateData(COLORS, countValues),
            keysStacked: _.get(stacked, "keysStacked", []),
            dataStacked: _.get(stacked, "dataStacked", []),
            multiKeysStacked: _.get(multiStacked, "keysStacked", []),
            multiDataStacked: _.get(multiStacked, "dataStacked", []),
            textRandom: getTextRandom(50),
        });
    }

    renderInterpretationConditions = (dataCoi, sizeDataCoi) => (
        <div style={_.assign({ ...styles.contentCoi }, { width: sizeDataCoi })}>
            {_.map(dataCoi, (coi, index) => (
                <div key={`coi-indicator-${index}-${getRamdonString()}`}>
                    <h3 style={{ fontSize: 14, margin: "10px 0 5px 0", color: "#363638" }}>
                        {_.get(coi, "title", "")}
                    </h3>
                    {_.map(_.get(coi, "texts", []), (text, idx) => (
                        <p key={`coi-indicator-${index}-${idx}-${getRamdonString()}`} style={{ ...styles.textCoi }}>
                            {text}
                        </p>
                    ))}
                </div>
            ))}
        </div>
    );

    renderLegendChart = () => {
        const { size, type } = this.props;
        const { keysStacked, dataStacked } = this.state;
        switch (type) {
            case "STACKED_BAR":
            case "STACKED_BAR3D":
                return (
                    <LegendComponent
                        data={_.map(keysStacked, k => ({
                            color: _.get(k, "color", "#FFF"),
                            index: `${getPencetage(
                                _.get(dataStacked, `0.${_.get(k, "key")}`, 0),
                                _.get(dataStacked, `0.total`, 100),
                            )}%`,
                            textIndex: `${getPencetage(
                                _.get(dataStacked, `0.${_.get(k, "key")}`, 0),
                                _.get(dataStacked, `0.total`, 100),
                            )}%`,
                            value: 0,
                        }))}
                        size={size}
                    />
                );
            default:
                return <span />;
        }
    };

    renderLegendMultiChart = () => {
        const { size, type } = this.props;
        const { multiKeysStacked } = this.state;
        switch (type) {
            case "STACKED_BAR":
            case "STACKED_BAR3D":
                return (
                    <LegendComponent
                        data={_.map(multiKeysStacked, val => ({
                            color: _.get(val, "color", "#FFF"),
                            index: _.get(val, "text", ""),
                            textIndex: _.get(val, "text", ""),
                            value: 0,
                        }))}
                        size={size}
                    />
                );
            default:
                return <span />;
        }
    };

    renderChart = (data, keysStacked, dataStacked) => {
        const { size, type } = this.props;
        switch (type) {
            case chartTypes.STACKED_BAR:
                return _.size(keysStacked) > 0 && _.size(dataStacked) > 0 ? (
                    <BarStackedGroupFilterComponent data={dataStacked} keys={keysStacked} size={size} />
                ) : (
                    <span />
                );
            case chartTypes.STACKED_BAR3D:
                return _.size(keysStacked) > 0 && _.size(dataStacked) > 0 ? (
                    <BarStacked3DGroupFilterComponent data={dataStacked} keys={keysStacked} size={size} />
                ) : (
                    <span />
                );
            default:
                return <ChartComponent data={data} type={type} size={size} hasLegend />;
        }
    };

    render() {
        const { typeComponent, items, numColumns, contentColumns, size, hasCoi } = this.props;
        const { data, keysStacked, dataStacked, multiKeysStacked, multiDataStacked, textRandom } = this.state;
        const numItems = _.size(items);
        const sizeDataCoi = getSizeCoi(numColumns, contentColumns, size);
        switch (typeComponent) {
            case FILTER_GROUPS:
                return (
                    <div>
                        <div>
                            <h3 style={{ ...styles.titleGroup }}>Todos</h3>
                            {this.renderChart(data, keysStacked, dataStacked)}
                            {this.renderLegendChart()}
                        </div>
                    </div>
                );
            case INDICATOR:
                if (_.size(items) === 0) {
                    return <h3 style={{ ...styles.titleNullValues }}>No se tiene seleccionado ningún valor</h3>;
                }
                const nameTile = "Indicador";
                const valuesCoi = numItems === 1 ? dataStacked : multiDataStacked;
                const dataCoi = _.map(valuesCoi, (val, idx) => ({
                    title: `${nameTile} ${idx + 1}`,
                    texts: [textRandom],
                }));
                return (
                    <div>
                        <div style={_.isEqual(sizeDataCoi, "86%") ? {} : { display: "inline-block" }}>
                            {numItems === 1
                                ? this.renderChart(data, keysStacked, dataStacked)
                                : _.map(multiDataStacked, (mds, idx) => (
                                      <div key={`multi-stacked-values-${idx}-${getRamdonString()}`}>
                                          <h3 style={{ ...styles.titleGroup }}>{`${nameTile} ${idx + 1}`}</h3>
                                          {this.renderChart(data, multiKeysStacked, [mds])}
                                      </div>
                                  ))}
                            {numItems === 1 ? this.renderLegendChart() : this.renderLegendMultiChart()}
                        </div>
                        {hasCoi && this.renderInterpretationConditions(dataCoi, sizeDataCoi)}
                    </div>
                );
            case QUESTION:
                if (_.size(items) === 0) {
                    return <h3 style={{ ...styles.titleNullValues }}>No se tiene seleccionado ningún valor</h3>;
                }
                return this.renderChart(data, keysStacked, dataStacked);
            default:
                return this.renderChart(data, keysStacked, dataStacked);
        }
    }
}

function mapStateToProps({ analyticsReportsConfiguration }, ownProps) {
    const config = analyticsReportsConfiguration.get("pageConfiguration");
    const loadingConfiguration = analyticsReportsConfiguration.get("loadingConfiguration");
    const numColumns = _.get(config, `${_.get(ownProps, "rowPath")}.columns`, 1);
    const contentColumns = _.get(config, `${_.get(ownProps, "sectionPath")}.columns`, 1);
    const items = _.get(config, `${_.get(ownProps, "elementPath")}.chartProps.items`, []);
    const indicators = analyticsReportsConfiguration.get("indicators");
    const hasCoi =
        _.chain(items)
            .map(item => (_.findIndex(indicators, { _id: item, typeEvaluation: "frecuency" }) !== -1 ? item : null))
            .filter(item => !_.isNull(item))
            .size()
            .value() === 0;
    return {
        randomColors: analyticsReportsConfiguration.get("colors"),
        loadingConfiguration,
        numColumns,
        contentColumns,
        indicators,
        hasCoi,
        items,
    };
}

export default connect(mapStateToProps)(Charts);
