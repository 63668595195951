import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import Divider from "material-ui/Divider";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { blue700 } from "material-ui/styles/colors";
import CircularProgress from "material-ui/CircularProgress";
import { showFaq, setInSearch } from "../../../actions/faq_form";
import { connect } from "react-redux";
import _ from "lodash";

class FaqGrid extends Component {
    constructor(props) {
        super(props);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.renderFaqs = this.renderFaqs.bind(this);
    }

    handleOpen() {
        const { showFaq, setInSearch } = this.props;
        setInSearch();
        showFaq(true);
    }

    handleClose() {
        const { showFaq } = this.props;
        showFaq(false);
    }

    renderFaqs() {
        const { inSearch } = this.props;
        if (inSearch) {
            return (
                <div style={{ margin: "20px auto", width: 50 }}>
                    <CircularProgress
                        style={{ margin: "30px auto" }}
                        size={45}
                        thickness={3}
                    />
                </div>
            );
        } else {
            if (_.has(this, "props.faqs")) {
                if (this.props.faqs.length) {
                    return _.map(this.props.faqs, (fq, idx) => {
                        return (
                            <div
                                key={`faq-${_.get(fq, "_id")}-${idx}`}
                                id={_.get(fq, "_id")}
                            >
                                <Row>
                                    <Col xs>
                                        <Divider />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <div
                                            style={{
                                                width: "95%",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                                marginTop: 45,
                                            }}
                                        >
                                            <h3
                                                style={{
                                                    fontWeight: 450,
                                                    marginBottom: 5,
                                                }}
                                            >
                                                {_.get(fq, "name")}
                                            </h3>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <div
                                            style={{
                                                width: "95%",
                                                marginLeft: "auto",
                                                marginBottom: 45,
                                                marginRight: "auto",
                                            }}
                                        >
                                            <div
                                                style={{ textAlign: "justify" }}
                                            >
                                                {_.get(fq, "description")}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        );
                    });
                } else {
                    return (
                        <div>
                            <h3
                                style={{ fontWeight: 200, textAlign: "center" }}
                            >
                                No hay preguntas frecuentes.
                            </h3>
                        </div>
                    );
                }
            } else {
                return (
                    <div>
                        <h3 style={{ fontWeight: 200, textAlign: "center" }}>
                            Al parecer hubo un error al buscar las preguntas
                            frecuentes. Inténtelo de nuevo más tarde.
                        </h3>
                    </div>
                );
            }
        }
    }

    render() {
        const { show } = this.props;
        return (
            <Dialog
                title="Preguntas frecuentes"
                actions={[
                    <FlatButton
                        label="Cerrar"
                        primary={true}
                        onClick={this.handleClose}
                    />,
                ]}
                titleStyle={{ textAlign: "center", color: blue700 }}
                contentStyle={{ textAlign: "justify", minWidth: 300 }}
                modal={false}
                autoScrollBodyContent={true}
                open={show}
                onRequestClose={this.handleClose}
            >
                {this.renderFaqs()}
            </Dialog>
        );
    }
}

function mapStateToProps({ faq }) {
    return {
        faqs: faq.get("alluser").toArray(),
        inSearch: faq.get("inSearch"),
        show: faq.get("show"),
    };
}

export default connect(mapStateToProps, { showFaq, setInSearch })(FaqGrid);
