import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import MenuItem from "material-ui/MenuItem";
import { blue600 } from "material-ui/styles/colors";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SelectFieldFormat from "../../../fields/SelectFieldFormat/SelectFieldFormat";
import { updateComponent } from "../../ducks";
import WaitComponent from "../waitComponent";

const styles = {
    title: {
        fontWeight: 400,
        padding: "12px 10px",
        margin: "10px auto",
        textAlign: "center",
        minWidth: 300,
        maxWidth: 350,
        borderRadius: 8,
        color: "#FFF",
        backgroundColor: blue600,
        boxSizing: "border-box",
    },
};

class QuestionComponent extends Component {
    static propTypes = {
        survey: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        page: PropTypes.string.isRequired,
        questions: PropTypes.arrayOf(PropTypes.object).isRequired,
        loadingConfiguration: PropTypes.bool.isRequired,
    };

    onChangeValue = (e, index, value) => {
        const { updateComponent, survey, path, page } = this.props;
        updateComponent(path, { items: [value] }, page, survey);
    };

    render() {
        const { questions, value, loadingConfiguration } = this.props;
        if (loadingConfiguration) {
            return <WaitComponent />;
        }
        if (_.size(questions) === 0) {
            return <h3 style={{ ...styles.title }}>No tiene preguntas configuradas</h3>;
        }
        return (
            <div>
                <SelectFieldFormat
                    config={{
                        floatingLabelText: "Seleccione la pregunta",
                        value,
                        autoWidth: true,
                        onChange: this.onChangeValue,
                    }}
                >
                    {_.map(questions, (ques, index) => (
                        <MenuItem
                            key={`question-config-${index}`}
                            value={_.get(ques, "_id")}
                            primaryText={_.get(ques, "title")}
                        />
                    ))}
                </SelectFieldFormat>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateComponent }, dispatch);
}

function mapStateToProps({ analyticsReportsConfiguration }, ownProps) {
    const config = analyticsReportsConfiguration.get("pageConfiguration");
    const loadingConfiguration = analyticsReportsConfiguration.get("loadingConfiguration");
    const questions = analyticsReportsConfiguration.get("questions");
    const selectedQuestion = _.get(config, `${_.get(ownProps, "path")}.items`, []);
    return {
        value: _.get(selectedQuestion, 0),
        page: analyticsReportsConfiguration.get("page"),
        questions,
        loadingConfiguration,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionComponent);
