import PropTypes from 'prop-types';
import React, { Component } from "react";
import uuid from "uuid";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import CircularProgress from "material-ui/CircularProgress";
import { FILTER_GROUPS, SOCIO_DEMOGRAPHIC, INDICATOR, QUESTION, RANKING, RANKING_STATS } from "../types";
import { sendMessage as sm } from "../ducks";
import { parseSolrFiltersToSarinFilters } from "./functions";

const { arrayOf, oneOf, string, func, shape, bool } = PropTypes;

const styles = {
    titleInvalid: {
        padding: 10,
        fontWeight: 400,
        boxSizing: "border-box",
        color: "#4c4c4c",
        textAlign: "center"
    }
};

class AnalyticsItem extends Component {
    static propTypes = {
        filters: arrayOf(shape()).isRequired,
        projectId: string.isRequired,
        type: oneOf([FILTER_GROUPS, SOCIO_DEMOGRAPHIC, INDICATOR, QUESTION, RANKING, RANKING_STATS]).isRequired,
        elementsId: arrayOf(string),
        componentId: string.isRequired,
        data: arrayOf(shape()).isRequired,
        sendMessage: func.isRequired,
        ToRender: func.isRequired,
        componentProps: shape(),
        isLoading: bool.isRequired,
        isInvalid: bool.isRequired
    };

    static defaultProps = {
        elementsId: [],
        componentProps: {}
    };

    componentDidMount() {
        const { filters, componentId, elementsId, projectId, type, sendMessage } = this.props;
        sendMessage(elementsId, projectId, componentId, filters, type, "EE");
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { filters } = this.props;
        const { sendMessage, componentId, projectId, type, elementsId } = this.props;
        const nextFilters = _.get(nextProps, "filters", parseSolrFiltersToSarinFilters([{ name: "Todos", combine: [] }], projectId));
        if (!_.isEqual(filters, nextFilters)) {
            sendMessage(elementsId, projectId, componentId, nextFilters, type, "EE");
        }
    }

    render() {
        const { ToRender, componentProps, data, isLoading, isInvalid, type } = this.props;
        const newProps = { ...componentProps, data };
        if (isLoading) {
            return (
                <div style={{ textAlign: "center", padding: 15 }}>
                    <CircularProgress size={35} thickness={3} style={{ marginTop: 20, marginBottom: 20 }} />
                </div>
            );
        } else if (isInvalid) {
            return <h3 style={{ ...styles.titleInvalid }}>No se muestra información porque son menos de cinco personas</h3>;
        }
        if (_.size(data) === 0) {
            if (_.isEqual(type, FILTER_GROUPS)) {
                return (
                    <h3 style={{ ...styles.titleInvalid }}>
                        No se muestra información porque son menos de cinco personas o no hay resultados para el filtro seleccionado
                    </h3>
                );
            }
            return <h3 style={{ ...styles.titleInvalid }}>No hay resultados para el filtro seleccionado</h3>;
        }
        return <ToRender {...newProps} />;
    }
}/* (
  <div>
      <code>{JSON.stringify(data, null, 2)}</code>
      <ToRender {...newProps} />
  </div>
) */

function isSelected(selectedFilters, filter) {
    return _.size(selectedFilters) === 0 || _.indexOf(selectedFilters, _.get(filter, "code")) !== -1;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            sendMessage: sm
        },
        dispatch
    );
}

function mapStateToProps() {
    const componentId = uuid.v4();
    return function mapPropsFn({ analyticsReport }) {
        const selectedFilters = analyticsReport.get("selectedFilters").toJS();
        return {
            data: analyticsReport.get(componentId) || [],
            componentId,
            selectedFilters,
            filters: analyticsReport
                .get("filters")
                .filter(filter => isSelected(selectedFilters, filter))
                .toJS(),
            isLoading: analyticsReport.get("loading").includes(componentId),
            isInvalid: analyticsReport.get("invalid").includes(componentId)
        };
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsItem);
