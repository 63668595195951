import React from "react";
import { shape } from "prop-types";
import { Provider } from "react-redux";

import { Router } from "react-router";

const Root = (props) => (
    <Provider store={props.store}>
        <div>
            <Router history={props.browserHistory} routes={props.routes} />
        </div>
    </Provider>
);

Root.propTypes = {
    store: shape().isRequired,
    browserHistory: shape().isRequired,
    routes: shape().isRequired,
};

export default Root;
