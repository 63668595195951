import PropTypes from "prop-types";
import React, { Component } from "react";
import TableCell from "@material-ui/core/TableCell";
import _ from "lodash";
import AuthComponent from "../../AuthComponent";
import { Link } from "react-router";
import IconButton from "material-ui/IconButton";
import { connect } from "react-redux";
import EditLocationIcon from "material-ui/svg-icons/action/settings";
import EditIcon from "material-ui/svg-icons/editor/mode-edit";
import { LBLLOCATIONS } from "../../../constants/labels";
import DeleteEntityModal from "../../deleteEntityModal/component";
import { deleteCity } from "../../fields/Locations/actions";
import { openModalForm } from "../../ModalFormLocation/actions";
import { toggleSnackbar } from "../../../actions/commons";
import { removeRecord } from "../actions";

class CityActionColumn extends Component {
    constructor(props) {
        super(props);
        this._handleDeleteLocation = this._handleDeleteLocation.bind(this);
        this._handleOpenModalForm = this._handleOpenModalForm.bind(this);
    }

    _handleDeleteLocation(data) {
        const { deleteCity, toggleSnackbar, removeRecord } = this.props;
        const idCity = _.get(data, "_id");
        deleteCity(idCity)
            .then(
                (data) => {
                    if (
                        _.get(data, "error") ||
                        _.get(data, "payload.status") !== 200
                    ) {
                        toggleSnackbar(true, LBLLOCATIONS.msg.errorDelete);
                    } else {
                        removeRecord(idCity, "_id");
                        toggleSnackbar(true, LBLLOCATIONS.msg.successDelete);
                    }
                },
                (reason) => {
                    toggleSnackbar(true, LBLLOCATIONS.msg.errorDelete);
                }
            )
            .catch((data) => {
                toggleSnackbar(true, LBLLOCATIONS.msg.errorDelete);
            });
    }

    _handleOpenModalForm(title, id, code, name) {
        const { openModalForm, extra } = this.props;
        openModalForm(
            id,
            code,
            name,
            title,
            "city",
            _.get(extra, "department"),
            true
        );
    }

    render() {
        const { data, colSpan, extra } = this.props;
        const cs = _.isUndefined(colSpan) ? 1 : colSpan;
        return (
            <TableCell colSpan={cs}>
                <div
                    style={{ position: "absolute", marginTop: -24, zIndex: 99 }}
                >
                    <AuthComponent
                        component={
                            <IconButton
                                tooltip={LBLLOCATIONS.tooltips.btnEdit}
                                tooltipPosition="top-center"
                                children={<EditIcon />}
                                onClick={this._handleOpenModalForm.bind(
                                    this,
                                    `${LBLLOCATIONS.tooltips.btnEdit} municipio`,
                                    _.get(data, "_id"),
                                    _.get(data, "code"),
                                    _.get(data, "name")
                                )}
                            />
                        }
                        permission={"cgr_location_city_update"}
                        type={"component"}
                    />
                    <AuthComponent
                        component={
                            <DeleteEntityModal
                                tooltip={LBLLOCATIONS.tooltips.btnDelete}
                                title={LBLLOCATIONS.titleDelete}
                                message={LBLLOCATIONS.msg.deleteModal(
                                    _.capitalize(_.get(data, "name"))
                                )}
                                fn={this._handleDeleteLocation}
                                args={[data]}
                                style={{ display: "table-cell" }}
                            />
                        }
                        permission={"cgr_location_city_delete"}
                        type={"component"}
                    />
                </div>
            </TableCell>
        );
    }
}

CityActionColumn.propTypes = {
    data: PropTypes.object.isRequired,
    colSpan: PropTypes.number,
    extra: PropTypes.object,
};

export default connect(null, {
    deleteCity,
    openModalForm,
    toggleSnackbar,
    removeRecord,
})(CityActionColumn);
