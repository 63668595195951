import { axiosAPI, axiosAPI_V2} from '../../middleware/api';
import immutable from 'immutable';
import _ from 'lodash';
import * as Rx from 'rxjs';

export const GET_EMPLOYEES_ASSOCIATIES = 'nss/send-email-massive/GET_EMPLOYEES_ASSOCIATIES';
export const CLEAR_EMPLOYEES_ASSOCIATIES = 'nss/send-email-massive/CLEAR_EMPLOYEES_ASSOCIATIES';
export const TOGGLE_ROW_EMPLOYEE = 'nss/send-email-massive/TOGGLE_ROW_EMPLOYEE';
export const FILTER_EMPLOYEES = 'nss/send-email-massive/FILTER_EMPLOYEES';
export const SELECT_ALL_EMAIL = 'nss/send-email-massive/SELECT_ALL_EMAIL';
export const UNSELECT_ALL_EMAIL = 'nss/send-email-massive/UNSELECT_ALL_EMAIL';

export const RESET_PASSWORD_MASSIVE_REQUESTED = 'nss/send-email-masive/RESET_PASSWORD_MASSIVE_REQUESTED';
export const RESET_PASSWORD_MASSIVE_IN_PROGRESS = 'nss/send-email-masive/RESET_PASSWORD_MASSIVE_IN_PROGRESS';
export const RESET_PASSWORD_MASSIVE_REJECTED = 'nss/send-email-masive/RESET_PASSWORD_MASSIVE_REJECTED';
export const RESET_PASSWORD_MASSIVE_FULFILLED = 'nss/send-email-masive/RESET_PASSWORD_MASSIVE_FULFILLED';
export const RESET_PASSWORD_MASSIVE_CLEAN = 'nss/send-email-masive/RESET_PASSWORD_MASSIVE_CLEAN';


const initialState = {stateEmployees: [], tags:[], companyTags:[], allEmployees:[], statusBulkReset: RESET_PASSWORD_MASSIVE_CLEAN};
export default function (state = initialState, action = {}) {
    switch (action.type) {
        case GET_EMPLOYEES_ASSOCIATIES:
            var arrayEmployees = _.get(action, 'payload.data.data.employees', []);
            var initialStateEmployees = [];
            arrayEmployees.map(item => {
                initialStateEmployees.push(_.set(item, 'selected', true));
            });
            var generalTags = getTagsEmployees(arrayEmployees);
            return _.assign({}, state, {
                stateEmployees: initialStateEmployees,
                tags: _.get(generalTags, 'tags', []),
                companyTags: _.get(generalTags, 'companyTags', []),
                allEmployees: initialStateEmployees,
            });
        case FILTER_EMPLOYEES:
            var newEmployees = _.get(action, 'employees', []);
            var employees = [];
            newEmployees.map(item=> {
                employees.push(_.set(item, 'selected', true));
            });
            return _.assign({}, state, {
                stateEmployees: employees
            });
        case CLEAR_EMPLOYEES_ASSOCIATIES:
            return initialState;
        case TOGGLE_ROW_EMPLOYEE:
            var arrayEmployees = state.stateEmployees;
            var index = _.findIndex(arrayEmployees, (item)=>_.isEqual(item._id, action.employee));
            _.set(arrayEmployees[index], 'selected', !_.get(arrayEmployees[index], 'selected'));
            return _.assign({}, state, {
                stateEmployees: arrayEmployees
            });
        case SELECT_ALL_EMAIL:
            var employees = _.get(state,'stateEmployees');
            var newEmployeesState = _.map(employees,(employee)=>{
                return _.set(employee, 'selected', true);
            });
            return  _.assign({}, state, {
                stateEmployees: newEmployeesState
            });
        case UNSELECT_ALL_EMAIL:
            var employees = _.get(state,'stateEmployees');
            var newStEmployees = _.map(employees,(employee)=>{
                return _.set(employee, 'selected', false);
            });
            return  _.assign({}, state, {
                stateEmployees: newStEmployees
            });
        case RESET_PASSWORD_MASSIVE_REJECTED:
        case RESET_PASSWORD_MASSIVE_IN_PROGRESS:
        case RESET_PASSWORD_MASSIVE_FULFILLED:
        case RESET_PASSWORD_MASSIVE_CLEAN:
            return _.set(state, 'statusBulkReset', action.type);
        default:
            return state;
    }
}

function getTagsEmployees(employees) {
    var tags = [];
    var companyTags = [];
    if (!_.isUndefined(employees)) {
        employees.map((item)=> {
            var tagsEmployee = _.get(item, 'tags');
            if (_.size(tagsEmployee) > 0) {
                tagsEmployee.map( (tag) => {
                    companyTags.push(_.get(tag, 'title'));
                    tags.push(tag.value);
                });
            }
        });
    }
    return {
        tags: _.uniq(tags),
        companyTags: _.uniq(companyTags)
    };
}



export function initialEmployeesAssociaties(idProject) {

    const request = axiosAPI.get(`/projects/${idProject}`);

    return {
        type: GET_EMPLOYEES_ASSOCIATIES,
        payload: request
    }
}

export function filterEmployees(filteredEmployees){

    return {
        type: FILTER_EMPLOYEES,
        employees: filteredEmployees
    }
}

export function clearEmployeesAssoc() {
    return {
        type: CLEAR_EMPLOYEES_ASSOCIATIES
    }
}

export function toggleRowEmployee(idEmployee) {
    return {
        type: TOGGLE_ROW_EMPLOYEE,
        employee:idEmployee
    }
}

export function selectAll() {
    return {
        type: SELECT_ALL_EMAIL
    }
}

export function unselectAll() {
    return {
        type: UNSELECT_ALL_EMAIL
    }
}

export function sendUserResetBulk(employees, email){
    return {
        type: RESET_PASSWORD_MASSIVE_REQUESTED,
        payload: {
            employees,
            email
        }
    };
}

export function resetUserResetBulk(){
    return {
        type: RESET_PASSWORD_MASSIVE_CLEAN
    };
}


export const resetPasswordMassiveEpic$ = action$ => action$
    .ofType(RESET_PASSWORD_MASSIVE_REQUESTED)
    .mergeMap(action => {
        const {email, employees} = action.payload;
        const promise = axiosAPI_V2.put('/users/password/bulk', {
            employees,
            email
        });

        const request$ =  Rx.Observable.fromPromise(promise)
            .map(response => ({type: RESET_PASSWORD_MASSIVE_FULFILLED, payload: {data: response}}))
            .concat(Rx.Observable.of({type: RESET_PASSWORD_MASSIVE_CLEAN}).delay(3000))
            .catch(err => Rx.Observable.of({type: RESET_PASSWORD_MASSIVE_REJECTED, err}));

        return Rx.Observable
            .of({type: RESET_PASSWORD_MASSIVE_IN_PROGRESS})
            .concat(request$);
    });
