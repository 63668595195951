import React, { PureComponent } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import OccupyColumnsComponent from "../propComponents/occupyColumnsComponent";
import SelectComponent from "../propComponents/styleContent/selectComponent";
import BordersComponent from "../propComponents/bordersComponent";
import ContentEdit from "../propComponents/styleContentComponent";
import { arrayObjectAlign, alignTypes, arrayObjectOrientation, orientationTypes } from "../../reportComponents/types";

const styles = {
    box: {
        boxSizing: "border-box",
        padding: 10,
    },
    title: {
        fontWeight: 400,
        textAlign: "center",
        backgroundColor: "#ADADAD",
        color: "#FFF",
        margin: 0,
        padding: "12px 5px",
        width: "100%",
        boxSizing: "border-box",
    },
    boxError: {
        color: "#CCC",
        padding: 20,
        boxSizing: "border-box",
        textAlign: "center",
    },
};

class SectionConfiguration extends PureComponent {
    static propTypes = {
        survey: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
    };

    render() {
        const { survey, path } = this.props;
        const arrayPath = _.split(path, /\./);
        const parentPath = `${_.get(arrayPath, 0)}.${_.get(arrayPath, 1)}`;
        const patron = /^rows\.[0-9]{1,}$/;
        if (!patron.test(parentPath)) {
            return <div style={{ ...styles.boxError }}>No se puede calcular las columnas disponibles</div>;
        }
        return (
            <div>
                <div style={{ ...styles.box }}>
                    <OccupyColumnsComponent parentPath={parentPath} survey={survey} path={path} />
                    <SelectComponent
                        survey={survey}
                        path={path}
                        fieldProp={"orientation"}
                        title={"Orientación del contenido"}
                        defaultValue={orientationTypes.ORIENTATION_VERTICAL}
                        array={arrayObjectOrientation}
                    />
                    <SelectComponent
                        survey={survey}
                        path={path}
                        fieldProp={"contentAlign"}
                        title={"Alineación del contenido"}
                        defaultValue={alignTypes.ALIGN_LEFT}
                        array={arrayObjectAlign}
                    />
                </div>
                <h3 style={{ ...styles.title }}>Bordes</h3>
                <div style={{ ...styles.box }}>
                    <BordersComponent survey={survey} path={path} />
                </div>
                <h3 style={{ ...styles.title }}>Bordes del contenido</h3>
                <div style={{ ...styles.box }}>
                    <ContentEdit survey={survey} path={path} isSection />
                </div>
            </div>
        );
    }
}

export default SectionConfiguration;
