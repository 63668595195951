import {SHOW_EXIT_SURVEY} from './actions';
import _ from 'lodash';

export default (state = {modalExitOpen: false}, action) => {
    switch (action.type) {
        case SHOW_EXIT_SURVEY:
            return _.assign({}, state, {
                modalExitOpen: action.open
            });
        default:
            return state;
    }
}
