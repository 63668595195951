import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {validateButtonProject} from '../../dashboards/employee/actions';
import Paper from 'material-ui/Paper';
import _ from 'lodash';
import IconButton from 'material-ui/IconButton';
import VisibilityIcon from 'material-ui/svg-icons/action/visibility';
import {blue700} from 'material-ui/styles/colors';
import { Link } from 'react-router';
import CardActionsMenuProjectEmployee from '../employee/CardActionsMenuProject';
import CardActionsMenuProjectReferee from '../referee/CardActionsMenuProject';
import CardActionsMenuProjectCompany from '../company/CardActionsMenuProject';
import AuthComponent from '../../AuthComponent';

const stylePaper = {
    minWidth: 300,
    width: '100%',
    margin: '1px auto',
    textAlign: 'center',
    display: 'inline-block',
    borderRadius: 10
};

class projectCardComponent extends Component {

    constructor(props) {
        super(props);
        this.renderColorStatus = this.renderColorStatus.bind(this);
        this.renderTitleCompany = this.renderTitleCompany.bind(this);
        this.renderTitleCompanyDetail = this.renderTitleCompanyDetail.bind(this);
        this.renderTitleProject = this.renderTitleProject.bind(this);
        this.renderInformation = this.renderInformation.bind(this);
        this.renderMenu = this.renderMenu.bind(this);
    }

    renderColorStatus(){
        const {
            businessAcronym,
            projectColor,
            user
        } = this.props;
        const color = _.isUndefined(projectColor) ? blue700 : projectColor;
        const style = {
            border: '0px dashed #F0F',
            position: 'absolute',
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 20,
            paddingRight: 20,
            fontSize: 17,
            color: "#FFF",
            background: color,
            borderRadius: '10px 0 5px 0'
        };
        if(!_.isEqual(user, 'company')){
            return (
                <div style={style}>
                    {businessAcronym}
                </div>
            );
        } else {
            return ( <div></div> );
        }
    }

    renderTitleCompany(){
        const {
            businessName,
            user
        } = this.props;
        const styleTable = { borderCollapse: 'collapse', marginBottom: -5 };
        const styleName = { fontWeight: 300, marginLeft: 5, textAlign: 'left' };
        if(!_.isEqual(user, 'company')){
            return (
                <table style={styleTable}>
                    <tbody>
                        <tr>
                            <td>
                                <h3 style={styleName}>{`${businessName}`}</h3>
                            </td>
                            {this.renderTitleCompanyDetail()}
                        </tr>
                    </tbody>
                </table>
            );
        } else {
            return ( <div></div> );
        }
    }

    renderTitleCompanyDetail(){
        const {
            businessId,
            user
        } = this.props;
        if(_.isEqual(user, 'referee')){
            return (
                <td style={{width: 30}}>
                    <AuthComponent
                        component={<Link to={`/admin/companies/info/projects/${businessId}`}>
                                      <IconButton
                                          tooltip={"Visualizar empresa"}
                                          tooltipPosition="top-center"
                                          children={ <VisibilityIcon /> }
                                      />
                                  </Link>}
                        permission={"cgr_company_config"}
                        type={"component"}
                    />
                </td>
            );
        } else {
            return ( <td></td> );
        }
    }

    renderTitleProject(){
        const {
            projectName,
            projectDates
        } = this.props;
        const styleProjectName = { fontWeight: 400, margin: 5, textAlign: 'left', lineHeight: 1.2 };
        const styleDates = { fontWeight: 200, color: '#AAA', margin: 4, textAlign: 'left' };
        return (
            <div>
                <h1 style={styleProjectName}>{projectName}</h1>
                <h3 style={styleDates}>{projectDates}</h3>
            </div>
        );
    }

    renderInformation(){
        const {
            businessAcronym,
            projectColor
        } = this.props;
        const color = _.isUndefined(projectColor) ? blue700 : projectColor;
        const styleInfo ={ marginTop: 25, paddingTop: 20, paddingLeft: 20, paddingRight: 10, paddingBottom: 10 };
        const styleTable = { width: '100%', borderCollapse: 'collapse' };
        const styleImg = { background: color/*'#E5E5E5'*/, opacity: 0.4, width: 50, height: 50, borderRadius: '100%', margin: '0 auto' };
        return (
            <div style={styleInfo}>
                <table style={styleTable}>
                    <tbody>
                    <tr>
                        <td style={{ width: 53 }}>
                            <div style={styleImg}>
                                <div style={{fontSize: 25, width: '99%', textAlign: 'center', paddingTop: 15, color: '#FFF'}}>
                                    {_.size(businessAcronym) > 0 ? businessAcronym[0] : '?'}
                                </div>
                            </div>
                        </td>
                        <td>
                            {this.renderTitleCompany()}
                            {this.renderTitleProject()}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    renderMenu(){
        const {
            user,
            projectId,
            businessId,
            projectDescription,
            projectName,
            projectDays,
            projectInactive
        } = this.props;
        if(_.isEqual(user, 'employee')){
            return (
                <CardActionsMenuProjectEmployee
                    projectName={projectName}
                    projectDescription={projectDescription}
                    projectDays={projectDays}
                    projectInactive={projectInactive}
                />
            );
        } else if(_.isEqual(user, 'company')){
            return (
                <CardActionsMenuProjectCompany
                    projectName={projectName}
                    projectDescription={projectDescription}
                    projectDays={projectDays}
                    projectInactive={projectInactive}
                />
            );
        } else if(_.isEqual(user, 'referee')){
            return (
                <CardActionsMenuProjectReferee
                    companyId={businessId}
                    projectId={projectId}
                />
            );
        } else {
            return(
                <div></div>
            );
        }
    }

    render() {
        const {
            CardActionsComponent,
            buttonName,
            urlAction,
            buttonDisabled,
            projectTime,
            projectId,
            companyId,
            projectFinalized
        } = this.props;
        return (
            <Paper style={stylePaper}>
                {this.renderColorStatus()}
                {this.renderMenu()}
                {this.renderInformation()}
                <CardActionsComponent
                    buttonName={buttonName}
                    URLAction={urlAction}
                    buttonDisabled={buttonDisabled}
                    time={projectTime}
                    projectId={projectId}
                    projectFinalized={projectFinalized}
                    companyId={companyId}
                />
            </Paper>
        );
    }
}

projectCardComponent.PropTypes = {
    buttonName: PropTypes.string,
    projectDates: PropTypes.string,
    buttonDisabled: PropTypes.bool,
    finalized: PropTypes.bool,
    buttonVisible: PropTypes.bool,
    urlAction: PropTypes.string,
    projectId: PropTypes.string.isRequired,
    projectName: PropTypes.string.isRequired,
    projectDescription: PropTypes.string,
    projectDays: PropTypes.array.isRequired,
    businessId: PropTypes.string.isRequired,
    businessName: PropTypes.string,
    businessAcronym: PropTypes.string.isRequired,
    CardActionsComponent: PropTypes.element,
    projectColor: PropTypes.string,
    projectInactive: PropTypes.number,
    user: PropTypes.string.isRequired
};


function mapStateToProps({dashboard, login}) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        validateButtonProject
    }, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(projectCardComponent);
