// @flow
import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Tabs, Tab } from "material-ui/Tabs";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { changePage as cp, getPageConfiguration as gpc } from "../ducks";
import { pageTypes } from "../../reportAdmon/types";

type Props = {
    changePage: (page: string) => any,
    getPageConfiguration: (page: string, survey: string) => any,
    page: string,
    survey: string,
};

class PageSelector extends Component<void, Props, void> {
    componentDidMount() {
        const { survey, changePage, getPageConfiguration } = this.props;
        const initialPage = pageTypes.MAIN;
        changePage(initialPage);
        getPageConfiguration(initialPage, survey);
    }

    handleChangePage = page => {
        const { survey, changePage, getPageConfiguration } = this.props;
        changePage(page);
        getPageConfiguration(page, survey);
    };

    render() {
        const { page } = this.props;
        return (
            <Tabs value={page} onChange={this.handleChangePage}>
                <Tab label="Principal" value={pageTypes.MAIN} />
                <Tab label="Sociodemográfico" value={pageTypes.SOCIO_DEMOGRAPHIC} />
                <Tab label="Indicadores" value={pageTypes.INDICATORS} />
            </Tabs>
        );
    }
}

function mapStateToProps({ analyticsReportsConfiguration }) {
    return {
        page: analyticsReportsConfiguration.get("page"),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            changePage: cp,
            getPageConfiguration: gpc,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PageSelector);
