import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {reduxForm} from 'redux-form';
import {Row, Col} from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import {createApprovalConsents} from '../actions/consents_form';
import {FetchProjectOnlyParam} from '../actions/info/projects_info_form';
import {loadApprovalConsentsProject} from '../actions/consents_form';
import _ from 'lodash';
import { browserHistory } from 'react-router';
import {blue700} from 'material-ui/styles/colors';
import SnackbarMessage from '../components/commons/snackBarMessage';
import {toggleSnackbar} from '../actions/commons';
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';
import ButtonDeclineSurvey from '../components/DeclineSurvey/declineSurvey';
import FaqMessage from '../components/fields/FaqMessage/FaqMessage';
import About from '../components/fields/about/About';
import AuthComponent from '../components/AuthComponent';
import ButtonBack from '../components/fields/buttonBack/buttonBack';

const stylePaper = {
        marginTop: 80,
        marginBottom: 50,
        minWidth: 300
    },
    headline = {
        fontSize: 24,
        paddingTop: 20,
        marginBottom: 20,
        fontWeight: 400,
        textAlign: 'center'
    },
    styleBodyConsents = {
        paddingRight: 50,
        paddingLeft: 50
    };

let disableAccepConsents = false;
let disableNoMakeSurvey = false;
var isAdmin = false;
var urlBack = "";

class AcceptConsentsNews extends Component {

    constructor(props) {
        super(props);
        this._saveApprovalConsents = this._saveApprovalConsents.bind(this);
        this._backHome = this._backHome.bind(this);
        this.renderConsents = this.renderConsents.bind(this);
        this.renderDescription = this.renderDescription.bind(this);
    }

    UNSAFE_componentWillMount() {
        const {
            FetchProjectOnlyParam,
            loadApprovalConsentsProject,
            profile,
            params
        } = this.props;
        FetchProjectOnlyParam(params.project);
        loadApprovalConsentsProject(params.userId, params.project);
    }

    shouldComponentUpdate(nextProps,nextState){
        const {
            profile,
        } = nextProps;
        const personId = _.get(profile, 'person._id');
        if (!personId) {
            console.error('No hay un usuario logeado');
            browserHistory('/login');
            return false;
        } else {
            return true;
        }
    }

    _saveApprovalConsents(formData) {
        const self = this;
        const companyId = _.get(this.props.projects, "data.company");
        self
            .props
            .createApprovalConsents(formData, this.props.params.userId, companyId)
            .then((data) => {
                if (_.get(data, 'error') || (_.get(data, 'payload.status') !== 200)) {
                    if(_.isEqual(_.get(data, 'payload.response.data.error.error'), 'UserExistsApprovalConsents')){
                        self.props.toggleSnackbar(true, "El usuario ya tiene unos consentimientos diligenciados para esta prueba");
                    } else {
                        self.props.toggleSnackbar(true, "Ocurrió un error inesperado en el servidor");
                    }
                } else {
                    browserHistory.replace(`/dashboard/employee/survey/appl/${this.props.projects.data.survey}/1/${this.props.params.project}`);
                }
            }, (reason) => {
                console.log("Error ->", reason);
                this.props.toggleSnackbar(true, "Ocurrió un error inesperado en el servidor");
            })
            .catch((data) => {
                console.log("Error ->", data);
                this.props.toggleSnackbar(true, "Ocurrió un error inesperado en el servidor");
            });
    }

    _backHome() {
        browserHistory.push(urlBack);
    }

    _validateAcceptConsents(value, ind) {
        let aswersValid = 0;
        let countRequired = 0;
        this.props.fields.consents.map((consent, index) => {
            if (ind === index) {
                if (consent.required.value) {
                    countRequired++;
                    if (value === "true") {
                        aswersValid++
                    }
                } else {
                    aswersValid++
                }
                disableNoMakeSurvey = true;
            } else {
                if (consent.required.value) {
                    countRequired++;
                    if (consent.accept.value != null) {
                        disableNoMakeSurvey = true;
                        if (consent.accept.value === "true") {
                            aswersValid++
                        }
                    } else {
                        disableNoMakeSurvey = false;
                    }
                } else {
                    if (consent.accept.value != null) {
                        aswersValid++;
                        disableNoMakeSurvey = true;
                    } else {
                        disableNoMakeSurvey = false;
                    }
                }
            }

        });
        disableAccepConsents = false;
        if (aswersValid === _.size(this.props.fields.consents)) {
            disableAccepConsents = true;
        }
    }

    renderDescription(consent) {
        let cs = "";
        if (!_.isUndefined(consent) && !_.isEqual(consent, "")) {
            cs = _.split(consent, /\r\n|\r|\n/g);
        }
        if (cs instanceof Array) {
            return cs.map((value, index) => {
                if (!_.isEqual(value, '')) {
                    return (
                        <div key={index}>{value}<br/><br/></div>
                    );
                }
            });
        } else {
            return cs;
        }
    }

    render() {
        const {
            fields: {
                approval,
                project,
                consents
            },
            handleSubmit,
            nextsection,
            projects,
            profile
        } = this.props;
        const companyId = _.get(projects, "data.company");
        isAdmin = !_.isEqual(_.get(profile, "role"), "employee");
        if (isAdmin){
            urlBack = '/admin/me/projects';
        } else {
            urlBack = '/dashboard/employee';
        }
        if (_.get(this, 'props.projects.data')) {
            return (
                <AuthComponent
                    component={
                        <Row>
                            <Col xs={12} mdOffset={2} md={8}>
                                <ButtonBack url={urlBack}/>
                                <Paper style={stylePaper}>
                                    <form onSubmit={handleSubmit(this._saveApprovalConsents)}>
                                        {this.renderConsents()}
                                        <Row>
                                            <Col xs>
                                                <h1 style={headline}>
                                                    {
                                                        (!_.isUndefined(_.get(this, 'props.values.approval')) && !_.get(this, 'props.values.approval'))
                                                            ? <div>
                                                            <RaisedButton type="submit" disabled={!disableAccepConsents}
                                                                          label="Comenzar" secondary={true}
                                                                          style={{marginBottom: 12, marginRight:15}}/>
                                                            <ButtonDeclineSurvey idProject={this.props.params.project}
                                                                                 disabled={!disableNoMakeSurvey}
                                                                                 companyId={companyId}
                                                                                 consentsArray={consents}/>
                                                               </div>
                                                            : <RaisedButton label="Ir al inicio" secondary={true}
                                                                            onClick={this._backHome}
                                                                            style={{marginBottom: 12}}/>
                                                    }
                                                </h1>
                                            </Col>
                                        </Row>
                                    </form>
                                </Paper>
                            </Col>
                            <SnackbarMessage />
                            <FaqMessage />
                            <About />
                        </Row>
                    }
                    permission={"cgr_employee_fill_survey"}
                    type={"url"}
                />
            );
        } else {
            return (
                <div></div>
            );
        }
    }

    renderConsents() {
        const {
            fields: {
                consents
            }
        } = this.props;
        if (!_.isUndefined(_.get(this, 'props.values.approval')) && !_.get(this, 'props.values.approval')) {
            if(_.size(consents) === 0){
                return (
                    <h2 style={{marginTop: 120, textAlign: 'center'}}>
                        No hay consentimientos asignados para esta evaluación
                    </h2>
                );
            } else {
                return consents.map( (consent, index) =>
                    <div key={index}>
                        <Row>
                            <Col xs>
                                <div style={{width: '100%', background: blue700, paddingTop: 5, paddingBottom:5, color: "#FFF"}}>
                                    <h2 style={{textAlign: 'center', fontWeight: 400}}>
                                        {_.get(consent, '_id.value.name')}
                                    </h2>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs style={styleBodyConsents}>
                                <div style={{marginBottom: 30, marginTop:30, textAlign: 'justify'}}>
                                    {this.renderDescription(_.get(consent, 'consent.value'))}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs style={styleBodyConsents}>
                                <span style={{textAlign: 'justify', color: 'red', display: _.get(consent, 'required.value', false) ? 'block':'none' }}>
                                    Es obligatorio aceptarlo para comenzar con la prueba.
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs style={styleBodyConsents}>
                                <div style={{marginBottom: 20, marginTop: 20, textAlign: 'justify', width: 290}}>
                                    <RadioButtonGroup
                                        name={index+""}
                                        style={{display: 'block'}}
                                        onChange={(e,value)=>{
                                             consent.accept.onChange(value);
                                             consent.fecha.onChange(new Date());
                                             this._validateAcceptConsents(value,index);
                                        }}
                                    >
                                        <RadioButton
                                            value="true"
                                            label="Acepto"
                                            style={{width: "50%", display: 'inline-block'}}
                                        />
                                        <RadioButton
                                            value="false"
                                            label="No acepto"
                                            style={{width: "50%", display: 'inline-block'}}
                                        />
                                    </RadioButtonGroup>
                                </div>
                            </Col>
                        </Row>
                    </div>
                );
            }
        } else {
            return (
                <Row>
                    <Col xs={12}>
                        <div style={{paddingTop:20, textAlign: 'center'}}>
                            <h2>No le esta permitido ingresar a esta página</h2>
                        </div>
                    </Col>
                </Row>
            );
        }

    }
}

function mapStateToProps({projects, consents, login}, ownProps) {

    if (_.get(projects, 'all.data.consent') && _.size(_.get(projects, 'all.data.consent')) && _.has(login.toJS(), 'loggedIn')) {
        let consent = _.get(projects, 'all.data.consent').map((consent) => {
            consent = _.set(consent, 'fecha', '');
            return _.set(consent, 'accept', null);
        });
        return {
            projects: projects.all,
            profile: login.get('profile'),
            loggedIn: login.get('loggedIn'),
            initialValues: {
                consents: _.get(projects, 'all.data.consent'),
                project: _.get(ownProps, 'params.project'),
                approval: !_.get(consents, 'consent.data.allowed')
            }
        };
    }
}

AcceptConsentsNews.propTypes = {
    router: PropTypes.func
};

export default reduxForm({
    form: 'AcceptConsentsNewsForm',
    fields: [
        'approval',
        'project',
        'consents[]._id',
        'consents[].consent',
        'consents[].value',
        'consents[].accept',
        'consents[].required',
        'consents[].fecha'
    ]
}, mapStateToProps, {
    createApprovalConsents,
    FetchProjectOnlyParam,
    toggleSnackbar,
    loadApprovalConsentsProject
})(AcceptConsentsNews);
