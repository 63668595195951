import PropTypes from "prop-types";
import React, { Component } from "react";
import StarIcon from "material-ui/svg-icons/toggle/star";
import { blue700 } from "material-ui/styles/colors";
import _ from "lodash";

const styles = {
    titleHead: {
        fontWeight: 400,
        margin: 0,
        padding: "7px 5px 5px 5px",
        textAlign: "center",
    },
    IconTitle: {
        textAlign: "center",
        padding: "6px 2px 4px 2px",
        verticalAlign: "top",
        backgroundColor: "#D4D0D0",
        color: "#303131",
        border: "1px solid #757575",
        width: 32,
    },
};

function getStyleBody(index, colorB) {
    let backgroundColor = "#FAFAFA";
    if (index % 2 !== 0) {
        backgroundColor = "#FFF";
    }
    if (!_.isUndefined(colorB) && !_.isNull(colorB)) {
        backgroundColor = colorB;
    }
    return {
        verticalAlign: "top",
        backgroundColor,
        color: "#2E2E2E",
        border: "1px solid #757575",
    };
}

function getStyleHead(colorB) {
    let backgroundColor = "#D4D0D0";
    let textColor = "#303131";
    if (!_.isUndefined(colorB) && !_.isNull(colorB)) {
        backgroundColor = colorB;
        textColor = "#111111";
    }
    return {
        verticalAlign: "top",
        backgroundColor,
        color: textColor,
        border: "1px solid #757575",
    };
}

function getValueRate(value) {
    if (value <= 0) {
        return value;
    }
    const v = value * 100;
    return v.toFixed(2);
}

function getStyleTitleBody() {
    return {
        fontWeight: 400,
        margin: 0,
        padding: 5,
        fontSize: 12,
    };
}

class RankingStatsComponent extends Component {
    static propTypes = {
        data: PropTypes.any.isRequired,
    };

    render() {
        const { data } = this.props;
        const columns = _.get(data, "0.columns", []);
        const values = _.get(data, "0.values", []);
        const hasRanking = _.size(values) > 0;
        // column => eq, color, priority
        return (
            <div>
                <table
                    style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        padding: 0,
                        textAlign: "left",
                        border: "1px solid #CCC",
                    }}
                >
                    <tbody>
                        <tr className="print_pdf_component">
                            <td style={styles.IconTitle} className="print_pdf_component">
                                <div>
                                    <StarIcon color={blue700} />
                                </div>
                            </td>
                            <td style={getStyleHead(null)} className="print_pdf_component">
                                <div style={styles.titleHead}>Indicador</div>
                            </td>
                            {_.map(columns, (column, index) => (
                                <td
                                    key={`table-head-ranking-stats-${index}`}
                                    style={getStyleHead(_.get(column, "color", null))}
                                    className="print_pdf_component"
                                >
                                    <div style={styles.titleHead}>{_.get(column, "name", "")}</div>
                                </td>
                            ))}
                        </tr>
                        {hasRanking ? (
                            _.map(values, (valueArray, index) => (
                                <tr key={`table-body-row-ranking-stats-${index}`} className="print_pdf_component">
                                    <td
                                        style={_.assign(getStyleBody(index), { textAlign: "center", width: 32 })}
                                        className="print_pdf_component"
                                    >
                                        <div style={_.assign(getStyleTitleBody(), { textAlign: "center" })}>
                                            {index + 1}
                                        </div>
                                    </td>
                                    {_.map(valueArray, (value, idx) => (
                                        <td
                                            key={`table-body-row-column-ranking-stats-${idx}`}
                                            style={getStyleBody(index)}
                                            className="print_pdf_component"
                                        >
                                            <div
                                                style={_.assign(getStyleTitleBody(), {
                                                    textAlign: idx !== 0 ? "center" : "left",
                                                })}
                                            >
                                                {idx !== 0 ? `${getValueRate(value)}%` : value}
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td
                                    colSpan={_.size(columns) + 2}
                                    style={_.assign(getStyleBody(1), { textAlign: "center" })}
                                    className="print_pdf_component"
                                >
                                    <div style={_.assign(getStyleTitleBody(), { textAlign: "center", fontSize: 13 })}>
                                        No hay indicadores
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default RankingStatsComponent;
