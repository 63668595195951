import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {reduxForm} from 'redux-form';
import {Row, Col} from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import { saveConfigDaysProject } from '../actions/configProjects_form';
import IconButton from 'material-ui/IconButton';
import _ from 'lodash';
import { browserHistory } from 'react-router';
import {LBLPROJECTS} from '../constants/labels';
import { FetchProjectParam } from '../actions/info/projects_info_form';
import {toggleSnackbar} from '../actions/commons';
import ButtonBack from '../components/fields/buttonBack/buttonBack';
import AuthComponent from '../components/AuthComponent';
import PureInput from '../components/PureInput';
import FlatButton from 'material-ui/FlatButton';
import DeleteIcon from 'material-ui/svg-icons/action/delete';

const style = {
        margin: 20,
        float: 'right'
    },
    stylePaper = {
        marginTop: 20,
        marginBottom: 20,
        padding: 30

    },
    styleTable = {
        margin: 'auto',
        width: '90%'
    };

var urlBack;

class ConfigDaysProjectsNews extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isReferee: false
        };
        this._saveConfigProject = this._saveConfigProject.bind(this);
        this.renderTitle = this.renderTitle.bind(this);
    }

    UNSAFE_componentWillMount(){
        const {
            profile,
            FetchProjectParam,
            params
        } = this.props;
        FetchProjectParam(params._id);
        if (!_.isEmpty(profile)) {
            this.state = {
                isReferee: _.isEqual(_.get(profile, 'role'), 'referee')
            }
        }
        urlBack = `/admin/projects/info/${_.get(params, 'profile')}/`;
    }

    _saveConfigProject(formData) {
        const {
            saveConfigDaysProject,
            toggleSnackbar,
            resetForm,
            params
        } = this.props;
        saveConfigDaysProject(formData)
            .then((data) => {
                if (_.get(data, 'error')) {
                    toggleSnackbar(true, LBLPROJECTS.msg.errorSave);
                } else {
                    resetForm();
                    toggleSnackbar(true, LBLPROJECTS.msg.successSaveConfig);
                    browserHistory.push(`/admin/projects/info/${_.get(params, 'profile')}/${params._id}/${params.company}`);
                }
            }, (reason) => {
                toggleSnackbar(true, LBLPROJECTS.msg.errorSave);
            })
            .catch((data) => {
                toggleSnackbar(true, LBLPROJECTS.msg.errorSave);
            });
    }

    renderTitle(){
        return (
            <div>
                <Row>
                    <Col xsOffset={2} xs={8}>
                        <h1 style={{textAlign: 'center', fontWeight: 400, lineHeight: 1.5}}>{LBLPROJECTS.config.toDay}
                            {
                                this.props.params.day == 1 ? LBLPROJECTS.timeLapse.week[0].toLowerCase() :
                                    this.props.params.day == 2 ? LBLPROJECTS.timeLapse.week[1].toLowerCase() :
                                        this.props.params.day == 3 ? LBLPROJECTS.timeLapse.week[2].toLowerCase() :
                                            this.props.params.day == 4 ? LBLPROJECTS.timeLapse.week[3].toLowerCase() :
                                                this.props.params.day == 5 ? LBLPROJECTS.timeLapse.week[4].toLowerCase() :
                                                    this.props.params.day == 6 ? LBLPROJECTS.timeLapse.week[5].toLowerCase() : LBLPROJECTS.timeLapse.week[6].toLowerCase()
                            }</h1>
                    </Col>
                </Row>
                <br/>
            </div>
        );
    }

    render() {
        const {
            fields: {
                lapses
            },
            handleSubmit,
            params
        } = this.props;
        return (
            <Row>
                <AuthComponent
                    component={
                        <Col xs={12} smOffset={2} sm={8} mdOffset={3} md={6}>
                            <Paper style={stylePaper}>
                                <ButtonBack url={`${urlBack}${params._id}/${params.company}`}/>
                                <form onSubmit={handleSubmit(this._saveConfigProject)}>
                                    {this.renderTitle()}
                                    <Row>
                                        <Col xs style={{margin: '0 auto', paddingLeft: 20, paddingRight: 20}}>
                                            <h3 style={{float: 'left', textAlign: 'left', fontWeight: 500, display: 'inline-block'}}>{`Hora de inicio y hora de cierre`}</h3>
                                        </Col>
                                    </Row>
                                    {lapses.map((lapse, index) =>
                                        <div style={{paddingBottom: 25}}>
                                            <Row key={index}>
                                                <Col xs style={{margin: '0 auto', paddingLeft: 20, paddingRight: 20}}>
                                                    <div style={{width: 20, display: 'inline-block'}}>
                                                        <h3 style={{fontWeight: 600}}>{`${index + 1}. `}</h3>
                                                    </div>
                                                    <div style={{width: 150, display: 'inline-block'}}>
                                                        <PureInput
                                                            style={{width: 150, paddingRight: 5}}
                                                            type="time"
                                                            onChange={(e, value) => lapse.openTime.onChange(value)}
                                                            field={lapse.openTime}
                                                            errorText={lapse.openTime.touched && lapse.openTime.error ? lapse.openTime.error : '' }
                                                        />
                                                    </div>
                                                    <div style={{marginLeft: 20, width: 150, display: 'inline-block'}}>
                                                        <PureInput
                                                            style={{width: 150, paddingRight: 5}}
                                                            type="time"
                                                            onChange={(e, value) => lapse.closeTime.onChange(value)}
                                                            field={lapse.closeTime}
                                                            errorText={lapse.closeTime.touched && lapse.closeTime.error ? lapse.closeTime.error : '' }
                                                        />
                                                    </div>
                                                </Col>
                                                <Col xs={2}>
                                                    <div>
                                                        <IconButton
                                                            children={<DeleteIcon/>}
                                                            tooltip={"Eliminar"}
                                                            tooltipPosition="top-center"
                                                            onClick={() => { lapses.removeField(index); }}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    )}
                                    <Row>
                                        <Col xsOffset={6} xs={6}>
                                            <div>
                                                <FlatButton
                                                    label={`Agregar lapso`}
                                                    secondary={true}
                                                    disabled={_.size(lapses) >= 4}
                                                    style={{ margin: 20, float: 'right' }}
                                                    onClick={() => { lapses.addField(); }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xsOffset={6} xs={6}>
                                            <AuthComponent
                                                component={<RaisedButton
                                                                type="submit"
                                                                label={LBLPROJECTS.buttons.saveConfig}
                                                                secondary={true}
                                                                style={style}
                                                            />}
                                                permission={"cgr_project_update_lapses_time"}
                                                type={"component"}
                                            />
                                        </Col>
                                    </Row>
                                </form>
                            </Paper>
                        </Col>
                    }
                    permission={"cgr_project_update_lapses_time"}
                    type={"url"}
                />
            </Row>
        );
    }
}

const requireFields = (...names) =>
    data =>
        names.reduce((errors, index) => {
            if (!data[index]) {
                errors[index] = "Es requerido";
            }
            return errors;
        }, {});

const validateLapses = requireFields('openTime', 'closeTime');

const validate = (values) => {

    const errors = {};

    errors.lapses = values.lapses.map(validateLapses);
    let validLapses = true;
    _.map(errors.lapses, function(lapse){
        if(_.has(lapse, 'openTime') || _.has(lapse, 'closeTime')){
            validLapses = false;
        }
    });
    if(validLapses){
        let closeTimeTemp = null;
        errors.lapses = values.lapses.map(function(lapse, index){
            let openDate = new Date(`1/1/1990 ${_.get(lapse, 'openTime')}`);
            let closeDate = new Date(`1/1/1990 ${_.get(lapse, 'closeTime')}`);
            if (openDate >= closeDate) {
                closeTimeTemp = closeDate;
                return {
                    openTime: LBLPROJECTS.validations.validateHour
                };
            } else {
                if(index !== 0 && !_.isNull(closeTimeTemp)){
                    if(openDate < closeTimeTemp){
                        closeTimeTemp = closeDate;
                        return {
                            openTime: LBLPROJECTS.validations.validateHourPreviousTime
                        };
                    } else {
                        closeTimeTemp = closeDate;
                        return {};
                    }
                } else {
                    closeTimeTemp = closeDate;
                    return {};
                }
            }
        });
    }

    return errors;
};

function mapStateToProps({projects, login}, ownProps) {
    let lapses = [];
    if(!_.isEmpty(projects.all)) {
        projects.all.data.time.dayWeek.map((item)=> {
             if(item.day == ownProps.params.day){
                 lapses = item.lapses;
             }
        });
        lapses = _.map(lapses, function(lapse, index){
            return {
                openTime: getTimeFormat(_.get(lapse, 'open')),
                closeTime: getTimeFormat(_.get(lapse, 'close'))
            };
        });
    }
    return {
        initialValues: {
            project: ownProps.params._id,
            day: ownProps.params.day,
            lapses: lapses
        },
        profile: login.get('profile')
    };
}

function getTimeFormat(time){
    let t = "";
    if(_.get(time, 'hour') > 9){
        t = _.get(time, 'hour');
    } else {
        t = `0${_.get(time, 'hour')}`;
    }
    if(_.get(time, 'minute') > 9){
        t = t + ":" + _.get(time, 'minute');
    } else {
        t = t + ":" + `0${_.get(time, 'minute')}`;
    }
    return t;
}

export default reduxForm({
    form: 'ConfigDaysProjectsNewForm',
    fields: [
        'project',
        'day',
        'lapses[].openTime',
        'lapses[].closeTime'
    ],
    validate
}, mapStateToProps, {saveConfigDaysProject, toggleSnackbar, FetchProjectParam})(ConfigDaysProjectsNews);
