export const TOGGLE_DIALOG = "TOGGLE_DIALOG";
export const SELECT_USER = "SELECT_USER";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
import { axiosAPI } from '../../middleware/api';

export function toggleDialog(status) {
    return {
        type: TOGGLE_DIALOG,
        status
    }
}

export function selectUser(user) {
    return {
        type: SELECT_USER,
        user
    }
}

export function changePassword(id, password) {
    const request = axiosAPI.put("/users/password", {
        userId: id,
        password,
        firstPassword: true
    });

    return {
        type: CHANGE_PASSWORD,
        payload: request
    }
}
