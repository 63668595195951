import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { addUser } from "./actions";
import { Col, Row } from "react-flexbox-grid";
import TextField from "material-ui/TextField";
import Dialog from "material-ui/Dialog";
import { reduxForm } from "redux-form";
import FloatingActionButtonTooltip from "../../../components/fields/FloatingActionButtonTooltip/FloatingActionButtonTooltip";
import FlatButton from "material-ui/FlatButton";
import { blue700 } from "material-ui/styles/colors";
import SelectFieldFormat from "../../../components/fields/SelectFieldFormat/SelectFieldFormat";
import MenuItem from "material-ui/MenuItem";
import { toggleSnackbar } from "../../../actions/commons";
import AuthComponent from "../../../components/AuthComponent";
import is from "is_js";

class AddUser extends Component {
    constructor(props) {
        super(props);
        this._handleAddUser = this._handleAddUser.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.state = {
            open: false,
        };
    }

    handleOpen() {
        console.log("click", arguments);
        this.setState({ open: true });
    }

    handleClose() {
        this.setState({ open: false });
    }

    _handleAddUser(data) {
        const { addUser, toggleSnackbar } = this.props;
        const self = this;
        addUser(data)
            .then((response) => {
                toggleSnackbar(true, "Usuario creado con éxito");
                self.setState({ open: false });
            })
            .catch((err) => {
                console.log(err);
                toggleSnackbar(
                    true,
                    "Ocurrió un error inesperado, intentalo de nuevo más tarde."
                );
            });
    }

    render() {
        const {
            fields: { name, email, lastName, idNumber, role },
            submitting,
            handleSubmit,
        } = this.props;

        return (
            <div>
                <FloatingActionButtonTooltip
                    textTooltip="Crear usuario"
                    onClick={this.handleOpen}
                />
                <Dialog
                    title="Nuevo usuario"
                    modal={false}
                    open={this.state.open}
                    titleStyle={{ textAlign: "center", color: blue700 }}
                    contentStyle={{
                        maxWidth: 720,
                        minWidth: 300,
                        textAlign: "justify",
                    }}
                    autoScrollBodyContent={true}
                    onRequestClose={this.handleClose}
                    actions={[
                        <FlatButton
                            label="Cancelar"
                            secondary={true}
                            onClick={this.handleClose}
                        />,
                    ]}
                >
                    <form onSubmit={handleSubmit(this._handleAddUser)}>
                        <Row>
                            <Col xs={12} mdOffset={1} md={10}>
                                <Row>
                                    <Col xs>
                                        <TextField
                                            floatingLabelText="Número de identificación"
                                            errorText={
                                                idNumber.touched &&
                                                idNumber.error
                                                    ? idNumber.error
                                                    : ""
                                            }
                                            {...idNumber}
                                        />
                                    </Col>
                                    <Col xs></Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <TextField
                                            floatingLabelText="Nombres"
                                            errorText={
                                                name.touched && name.error
                                                    ? name.error
                                                    : ""
                                            }
                                            {...name}
                                        />
                                    </Col>
                                    <Col xs>
                                        <TextField
                                            floatingLabelText="Apellidos"
                                            errorText={
                                                lastName.touched &&
                                                lastName.error
                                                    ? lastName.error
                                                    : ""
                                            }
                                            {...lastName}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <TextField
                                            floatingLabelText="Correo electrónico"
                                            errorText={
                                                email.touched && email.error
                                                    ? email.error
                                                    : ""
                                            }
                                            {...email}
                                        />
                                    </Col>
                                    <Col xs>
                                        <SelectFieldFormat
                                            config={{
                                                floatingLabelText:
                                                    "Rol en el sistema",
                                                value: role.value,
                                                errorText:
                                                    role.touched && role.error
                                                        ? role.error
                                                        : "",
                                                onChange: (e, index, value) =>
                                                    role.onChange(value),
                                            }}
                                        >
                                            <MenuItem
                                                value="admin"
                                                primaryText="Administrador"
                                            />
                                            <MenuItem
                                                value="tabulator"
                                                primaryText="Tabulador"
                                            />
                                        </SelectFieldFormat>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs></Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs>
                                <AuthComponent
                                    component={
                                        <FlatButton
                                            label="Guardar"
                                            primary={true}
                                            type="submit"
                                            style={{
                                                float: "right",
                                                marginTop: 15,
                                            }}
                                            disabled={submitting}
                                        />
                                    }
                                    permission={"cgr_users_create"}
                                    type={"component"}
                                />
                            </Col>
                        </Row>
                    </form>
                </Dialog>
            </div>
        );
    }
}

const validate = (values) => {
    const errors = {};
    if (!values.email) {
        errors.email = "Ingrese el correo electrónico";
    } else if (!is.email(values.email)) {
        errors.email = "Dirección de correo electrónico inválida";
    }
    if (!values.name) {
        errors.name = "Ingrese el nombre";
    }
    if (!values.lastName) {
        errors.lastName = "Ingrese los apellidos";
    }
    if (!values.idNumber) {
        errors.idNumber = "Ingrese la identificación"; //Seleccione el tipo documento
    }
    if (!values.role) {
        errors.role = "Seleccione el rol que tendrá en el sistema";
    }
    return errors;
};

function mapStateToProps({ userAdd }) {
    console.log(userAdd.get("text"));
    return {
        user: userAdd.get("user"),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            addUser,
            toggleSnackbar,
        },
        dispatch
    );
}

const reduxFormUser = reduxForm(
    {
        form: "userAdd",
        fields: ["idNumber", "name", "lastName", "email", "role"],
        validate,
    },
    mapStateToProps,
    mapDispatchToProps
)(AddUser);

export default reduxFormUser;
