import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Row, Col} from 'react-flexbox-grid';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Divider from 'material-ui/Divider';

export default class BaseFilterComponent extends Component {
    static propTypes = {
        field: PropTypes.object.isRequired,
        styles: PropTypes.object
    };

    render() {
        const {field, styles} = this.props;
        return (
            <Col xs={12}>
                <Row>
                    <Col xs={6}>
                        <h3>Tipo de elemento a gráficar</h3>
                    </Col>
                    <Col xs={6}>
                        <SelectField
                            {...field}
                            onChange={(e, index, value) => {
                                                    field.onChange(value);
                                                }}
                            autoWidth={true}
                            style={styles}
                            floatingLabelText="Seleccione el tipo de elemento a gráficar"
                            floatingLabelFixed={true}
                        >
                            <MenuItem value={1} primaryText="Respuestas Básicas"/>
                            <MenuItem value={2} primaryText="Indicadores"/>
                        </SelectField>
                    </Col>
                </Row>
                <Divider/>
            </Col>

        );
    }
}
