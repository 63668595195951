import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { reduxForm } from "redux-form";
import { blue600 } from "material-ui/styles/colors";
import { bindActionCreators } from "redux";
import MenuItem from "material-ui/MenuItem";
import SelectFieldFormat from "../../fields/SelectFieldFormat/SelectFieldFormat";
import PureInputColorPicker from "./styleContent/PureInputColorPicker";
import PureInput from "../../PureInput";
import { updateComponent } from "../ducks";
import WaitComponent from "./waitComponent";

const styles = {
    box: {
        width: "100%",
        boxSizing: "border-box",
        backgroundColor: "#FFF",
    },
    table: {
        width: "100%",
        padding: 0,
        borderCollapse: "collapse",
    },
    title: {
        fontWeight: 400,
        padding: 5,
        margin: "15px 0 10px 0",
        textAlign: "center",
    },
    headCells: {
        border: `1px solid ${blue600}`,
        padding: "7px 5px",
        textAlign: "center",
    },
    bodyCells: {
        border: "1px solid #CCC",
        padding: "7px 5px",
        textAlign: "left",
        height: 50,
        verticalAlign: "top",
    },
};

const borderDefault = [
    { position: "top", style: "solid", size: 0, color: "#EEE" },
    { position: "right", style: "solid", size: 0, color: "#EEE" },
    { position: "bottom", style: "solid", size: 0, color: "#EEE" },
    { position: "left", style: "solid", size: 0, color: "#EEE" },
];

function getNameProp(position) {
    switch (position) {
        case "top":
            return "Arriba";
        case "right":
            return "Derecha";
        case "bottom":
            return "Abajo";
        case "left":
            return "Izquierda";
        default:
            return "";
    }
}

function getSizeBorder(type) {
    if (true) {
        return "0px";
    }
    switch (type) {
        case "double":
        case "dotted":
            return "4px";
        case "solid":
        case "dashed":
            return "2px";
        default:
            return "0px";
    }
}

class BordersComponent extends Component {
    static propTypes = {
        survey: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        page: PropTypes.string.isRequired,
        loadingConfiguration: PropTypes.bool.isRequired,
    };

    state = {
        borderTypes: [
            { style: "solid", name: "Sólido" },
            { style: "dashed", name: "Discontinua" },
            { style: "double", name: "Doble" },
            { style: "dotted", name: "Punteada" },
        ],
    };

    renderContentHeadCell = (content, styleParam) => <td style={{ ...styles.headCells, ...styleParam }}>{content}</td>;

    renderContentBodyCell = (content, style) => <td style={{ ...styles.bodyCells, ...style }}>{content}</td>;

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { fields: { borders }, updateComponent, survey, page, path } = this.props;
        const contentBorder = _.map(borders, border => {
            const keys = _.keys(border);
            return _.reduce(keys, (sum, key) => _.assign(sum, _.set({}, key, _.get(border, `${key}.value`))), {});
        });
        const contentNewBorder = _.map(_.get(nextProps, "fields.borders", []), border => {
            const keys = _.keys(border);
            return _.reduce(keys, (sum, key) => _.assign(sum, _.set({}, key, _.get(border, `${key}.value`))), {});
        });
        if (!_.isEqual(contentBorder, contentNewBorder)) {
            updateComponent(`${path}.style`, { borders: contentNewBorder }, page, survey);
        }
    }

    handleSave = formData => {};

    onChangeBorderStyle = (field, e, index, value) => {
        field.onChange(value);
        setTimeout(this.updateValuesBorderStyle, 100);
    };

    updateValuesBorderStyle = () => {
        const { fields: { borders }, updateComponent, survey, page, path } = this.props;
        const contentBorder = _.map(borders, border => {
            const keys = _.keys(border);
            return _.reduce(keys, (sum, key) => _.assign(sum, _.set({}, key, _.get(border, `${key}.value`))), {});
        });
        updateComponent(`${path}.style`, { borders: contentBorder }, page, survey);
    };

    render() {
        const { borderTypes } = this.state;
        const { fields: { borders }, handleSubmit, loadingConfiguration } = this.props;
        if (loadingConfiguration) {
            return <WaitComponent />;
        } // <h3 style={{ ...styles.title }}>Bordes</h3>
        return (
            <div style={{ ...styles.box }}>
                <form onSubmit={handleSubmit(this.handleSave)}>
                    <table style={{ ...styles.table }}>
                        <thead>
                            <tr style={{ backgroundColor: blue600, color: "#FFF" }}>
                                {this.renderContentHeadCell("Lado", { borderRight: "1px solid #FFF" })}
                                {this.renderContentHeadCell("Color", { borderRight: "1px solid #FFF" })}
                                {this.renderContentHeadCell("Tipo", { borderRight: "1px solid #FFF" })}
                                {this.renderContentHeadCell("Tamaño", {})}
                            </tr>
                        </thead>
                        <tbody>
                            {borders.map((border, index) => (
                                <tr key={`row-body-border-config-${index}`}>
                                    {this.renderContentBodyCell(getNameProp(_.get(border, "position.value")), {
                                        width: 70,
                                        paddingTop: 20,
                                    })}
                                    {this.renderContentBodyCell(
                                        <PureInputColorPicker
                                            style={{ marginTop: -20, marginBottom: 5 }}
                                            stylePopover={{ margin: "0 -170px 0 10px" }}
                                            field={border.color}
                                        />,
                                        { width: 40, padding: "7px 2px" },
                                    )}
                                    {this.renderContentBodyCell(
                                        <SelectFieldFormat
                                            config={{
                                                floatingLabelText: "",
                                                value: border.style.value,
                                                style: { fontSize: 14, minWidth: 135, width: "100%", marginTop: 0 },
                                                errorText:
                                                    border.style.touched && border.style.error
                                                        ? border.style.error
                                                        : "",
                                                onChange: this.onChangeBorderStyle.bind(this, border.style),
                                            }}
                                        >
                                            {_.map(borderTypes, (border, index) => (
                                                <MenuItem
                                                    key={`borders-type-config-${index}`}
                                                    value={_.get(border, "style")}
                                                    primaryText={_.get(border, "name")}
                                                    secondaryText={
                                                        <div
                                                            style={{
                                                                float: "right",
                                                                borderRight: `${getSizeBorder(
                                                                    _.get(border, "style"),
                                                                )} ${_.get(border, "style")} #BBB`,
                                                                width: 10,
                                                                height: 25,
                                                                color: "rgba(255,255,255,0)",
                                                            }}
                                                        >
                                                            .
                                                        </div>
                                                    }
                                                />
                                            ))}
                                        </SelectFieldFormat>,
                                        { minWidth: 135 },
                                    )}
                                    {this.renderContentBodyCell(
                                        <PureInput
                                            type="number"
                                            hintText="0 y 10"
                                            field={border.size}
                                            errorText={
                                                border.size.touched && border.size.error ? border.size.error : ""
                                            }
                                            style={{ fontSize: 14, minWidth: 35, width: "100%" }}
                                        />,
                                        { minWidth: 35 },
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </form>
            </div>
        );
    }
}

const requireFields = (...names) => data =>
    names.reduce((errors, index) => {
        if (!data[index] && data[index] !== 0) {
            errors[index] = "Es requerido";
        } else if (data[index] < 0) {
            errors[index] = "El tamaño no puede ser menor a 0";
        } else if (data[index] > 10) {
            errors[index] = "El tamaño no puede ser mayor a 10";
        }
        return errors;
    }, {});

const validateOptions = requireFields("size");

const validate = values => {
    const errors = {};
    errors.borders = values.borders.map(validateOptions);
    return errors;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateComponent }, dispatch);
}

function mapStateToProps({ analyticsReportsConfiguration }, ownProps) {
    const config = analyticsReportsConfiguration.get("pageConfiguration");
    const loadingConfiguration = analyticsReportsConfiguration.get("loadingConfiguration");
    const valueBorders = _.get(config, `${_.get(ownProps, "path")}.style.borders`, []);
    const keys = _.map(borderDefault, value => _.get(value, "position"));
    const borders = _.map(keys, key => {
        const index = _.findIndex(valueBorders, { position: key });
        if (index !== -1) {
            return valueBorders[index];
        }
        return borderDefault[_.findIndex(borderDefault, { position: key })];
    });
    return {
        page: analyticsReportsConfiguration.get("page"),
        loadingConfiguration,
        initialValues: {
            borders,
        },
    };
}

export default reduxForm(
    {
        form: "BordersConfigReportNewsForm",
        fields: ["borders[].position", "borders[].style", "borders[].size", "borders[].color"],
        validate,
    },
    mapStateToProps,
    mapDispatchToProps,
)(BordersComponent);
