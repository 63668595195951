import { POST_CONFIG_PROJECT, DELETE_CONFIG_DAY_PROJECT} from '../constants/index';
import { axiosAPI } from '../middleware/api';
import _ from 'lodash';

export function createConfigProject(props) {

    function getInfoConfig() {

        let lapso1 = null, lapso2 = null, lapso3 = null, lapso4 = null;

        if (_.get(props, 'openTime') && _.get(props, 'closeTime')) {
            let horaInicio = new Date(_.get(props, 'openTime'));
            let horaFin = new Date(_.get(props, 'closeTime'));
            lapso1 = {
                open: {
                    hour: horaInicio.getHours(),
                    minute: horaInicio.getMinutes()
                },
                close: {
                    hour: horaFin.getHours(),
                    minute: horaFin.getMinutes()
                }
            }
        }

        if (_.get(props, 'openTime2') && _.get(props, 'closeTime2')) {
            let horaInicio2 = new Date(_.get(props, 'openTime2'));
            let horaFin2 = new Date(_.get(props, 'closeTime2'));

            lapso2 = {
                open: {
                    hour: horaInicio2.getHours(),
                    minute: horaInicio2.getMinutes()
                },
                close: {
                    hour: horaFin2.getHours(),
                    minute: horaFin2.getMinutes()
                }
            }
        }
        if (_.get(props, 'openTime3') && _.get(props, 'closeTime3')) {
            let horaInicio3 = new Date(_.get(props, 'openTime3'));
            let horaFin3 = new Date(_.get(props, 'closeTime3'));
            lapso3 = {
                open: {
                    hour: horaInicio3.getHours(),
                    minute: horaInicio3.getMinutes()
                },
                close: {
                    hour: horaFin3.getHours(),
                    minute: horaFin3.getMinutes()
                }
            }
        }


        if (_.get(props, 'openTime4') && _.get(props, 'closeTime4')) {
            let horaInicio4 = new Date(_.get(props, 'openTime4'));
            let horaFin4 = new Date(_.get(props, 'closeTime4'));
            lapso4 = {
                open: {
                    hour: horaInicio4.getHours(),
                    minute: horaInicio4.getMinutes()
                },
                close: {
                    hour: horaFin4.getHours(),
                    minute: horaFin4.getMinutes()
                }
            }
        }

        let lapses = [lapso1];

        if (!_.isNull(lapso2)) {
            lapses.push(lapso2);
        }
        if (!_.isNull(lapso3)) {
            lapses.push(lapso3);
        }
        if (!_.isNull(lapso4)) {
            lapses.push(lapso4);
        }

        return {
            'project': {
                _id: _.get(props, 'project'),
                dayWeek: {
                    day: _.get(props, 'day'),
                    lapses
                }
            }
        };
    }

    const request = axiosAPI.put(`/projects/configuration`, getInfoConfig());

    return {
        type: POST_CONFIG_PROJECT,
        payload: request
    }
}

export function saveConfigDaysProject(props) {

    function getInfoConfig() {

        return {
            'project': {
                _id: _.get(props, 'project'),
                dayWeek: {
                    day: _.get(props, 'day'),
                    lapses: _.map(_.get(props, 'lapses'), function(lapse){
                                let arrayOpenTime = _.map(_.split(_.get(lapse, 'openTime'), ':', 2), _.parseInt);
                                let arrayCloseTime = _.map(_.split(_.get(lapse, 'closeTime'), ':', 2), _.parseInt);
                                return {
                                    open: {
                                        hour: _.get(arrayOpenTime, 0),
                                        minute: _.get(arrayOpenTime, 1)
                                    },
                                    close: {
                                        hour: _.get(arrayCloseTime, 0),
                                        minute: _.get(arrayCloseTime, 1)
                                    }
                                }
                            })
                }
            }
        };
    }

    const request = axiosAPI.put(`/projects/configuration`, getInfoConfig());

    return {
        type: POST_CONFIG_PROJECT,
        payload: request
    }
}

export function deleteConfigDayProject(project, day) {

    const request = axiosAPI.delete(`/projects/configuration/${project}/${day}`, {data: {"type": 'logic'}});

    return {
        type: DELETE_CONFIG_DAY_PROJECT,
        payload: request,
        meta: {
            day
        }
    }
}
