import PropTypes from "prop-types";
import React, { Component } from "react";
import { getRamdonString } from "../../../lib/util";
import _ from "lodash";

class LegendStackedComponent extends Component {
    constructor(props) {
        super(props);
        this.renderValues = this.renderValues.bind(this);
    }

    renderValues() {
        const { data, keys } = this.props;
        return _.map(data, (value, index) => (
                <div
                    style={{
                        paddingBottom: 4,
                        marginLeft: 4,
                        display: "inline-block",
                        margin: 0,
                        verticalAlign: "top",
                    }}
                    key={`legend-values-${index}-${getRamdonString()}`}
                    className="print_pdf_children_legend"
                >
                    <table style={{ borderCollapse: "collapse", width: "100%", paddingRight: 2 }}>
                        <tbody>
                            <tr>
                                <td
                                    style={{
                                        paddingRight: 2,
                                        width: 22,
                                        textAlign: "left",
                                        borderRight: "0px solid #DDD",
                                    }}
                                >
                                    <strong>
                                        <span style={{ fontSize: 12 }}>{_.get(value, "index", "")}</span>
                                    </strong>
                                </td>
                                <td style={{ padding: "3px 0", textAlign: "left" }}>
                                    <span style={{ fontSize: 12 }}>{`${_.get(value, "textIndex", "")}`}</span>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2} style={{ padding: 4 }}>
                                    {_.map(keys, key => (
                                        <span
                                            key={`legend-values-${index}-${getRamdonString()}`}
                                            style={{
                                                display: "inline-block",
                                                fontSize: 12,
                                                marginRight: 7,
                                                textAlign: "left" /* , whiteSpace: 'nowrap' */,
                                            }}
                                        >
                                            <div style={getStyleCircle(_.get(key, "color", "#FFF"))}>
                                                <div style={{ opacity: 0, position: "absolute" }}>.</div>
                                            </div>
                                            {`${_.get(key, "text", "")}`}
                                        </span>
                                    ))}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ));
    }

    render() {
        return <div style={{ textAlign: "left", margin: "-13px 0 10px 0" }}>{this.renderValues()}</div>;
    }
}

function getStyleCircle(color) {
    return {
        display: "inline-block",
        width: 13,
        height: 13,
        margin: "0px 2px -2px 0px",
        borderRadius: "100%",
        backgroundColor: color,
        color,
    };
}

function getTextValue(textValue) {
    // if(_.isEqual(textValue, "")){
    return "";
    /* } else {
        return `(${textValue})`;
    } */
}

LegendStackedComponent.propTypes = {
    data: PropTypes.array.isRequired,
    keys: PropTypes.array.isRequired,
};

export default LegendStackedComponent;
