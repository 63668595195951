// @flow
import React, { Component } from "react";
import Paper from "material-ui/Paper";
import { DropTarget } from "react-dnd";
import type { ConnectDropTarget, DropTargetSpec } from "react-dnd";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { dropElement as de } from "../ducks";
import Types from "../dragComponentTypes";
import EmptyContent from "../componentsUtil/emptyContent";
import TitleContent from "../componentsUtil/titleContent";
// import ElementDropContext from "./elementDropContext";
import ElementContent from "../componentsUtil/elementContent";
import { colorHover, textHover, colorBackgroundHover, colorTitleHover, colorBorderHover } from "../utils";
import TabElementComponent from "../componentsUtil/tabElementComponent";
import { getPropsDefault } from "./functions";

const styles = {
    box: {
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        padding: 0,
        border: "1px solid",
    },
    contentPaper: {
        width: "100%",
        height: "100%",
        padding: "10px 2px 2px 2px",
        borderRadius: 0,
    },
};

type Title = {
    text: string,
    style: any,
    code: string,
};

type Props = {
    _id: string,
    columns: number,
    title: Title,
    elements: any,
    orientation: string,
    dropElement: (string, string) => Action,
    contentAlign: string,
    styleSection: object,
    survey: string,
    path: string,
    pathArray: string,
    rowPath: string,
    position: number,
    connectDropTarget: ConnectDropTarget,
    isOver: boolean,
    canDrop: boolean,
    isOverCurrent: boolean,
    saving: boolean,
};

type defaultProps = {
    columns: number,
    title: Title,
    elements: any,
};

const itemTarget: DropTargetSpec = {
    canDrop(props, monitor) {
        const item = monitor.getItem();
        const validElements = [
            Types.CHART,
            Types.DEMOGRAPHIC_CHART,
            Types.FILTER_GROUPS,
            Types.RANKING,
            Types.MENU,
            Types.TEXT,
        ];
        if (_.indexOf(validElements, item.type) !== -1) {
            return true;
        }
        return false;
    },
    drop(props, monitor): ?Object {
        if (monitor.didDrop()) {
            return {};
        }
        const item = monitor.getItem();
        // const item = monitor.getItem();
        props.dropElement(`${props.path}.children`, props.page, props.survey, getPropsDefault(item.type));
        return { moved: true };
    },
};

function collect(connect, monitor) {
    return {
        // Call this function inside render()
        // to let React DnD handle the drag events:
        connectDropTarget: connect.dropTarget(),
        // You can ask the monitor about the current drag state:
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
        itemType: monitor.getItemType(),
    };
}

function getBorders(borders) {
    const propsBorder = _.groupBy(borders, "position");
    const keysBorder = _.keys(propsBorder);
    return _.chain(keysBorder)
        .flatMap(key => [
            {
                key: `border${_.capitalize(key)}Color`,
                value: _.get(propsBorder, `${key}.0.color`, "#EEE"),
            },
            {
                key: `border${_.capitalize(key)}Style`,
                value: _.get(propsBorder, `${key}.0.style`, "solid"),
            },
            {
                key: `border${_.capitalize(key)}Width`,
                value: _.get(propsBorder, `${key}.0.size`, 0),
            },
        ])
        .reduce((acc, current) => _.assign({}, acc, { [current.key]: current.value }), {})
        .value();
}

class SectionDropContext extends Component<defaultProps, Props, void> {
    static defaultProps = {
        columns: 1,
        title: {
            text: "",
            style: {},
            code: "",
        },
        elements: [],
    };

    renderElements = (child: any, index: number, collection: any) => {
        const { path, orientation, survey, rowPath } = this.props;
        const childrenPath = `${path}.children.${index}`;
        const pathArray = `${path}.children`;
        // rowPath
        return (
            <ElementContent
                key={`row-section-child-${path}-${index}`}
                index={index}
                elementProps={child}
                rowPath={rowPath}
                sectionPath={path}
                path={childrenPath}
                pathArray={pathArray}
                position={index}
                orientation={orientation}
                survey={survey}
            />
        );
    };

    render() {
        const {
            elements,
            _id,
            connectDropTarget,
            isOver,
            canDrop,
            isOverCurrent,
            title,
            path,
            contentAlign,
            styleSection,
            pathArray,
            position,
            survey,
        } = this.props;
        const colorSelector = colorHover(isOver, canDrop, isOverCurrent);
        const textMessage = textHover(
            isOver,
            canDrop,
            isOverCurrent,
            "Puede arrastrar elementos de título, gráfico, sociodemográfico, grupo de filtros, menú, clasificación y texto",
        );
        const elementsRender = elements.map(this.renderElements);
        const hasContent = _.size(elementsRender) > 0;
        const backgroundColor = hasContent ? colorBackgroundHover(isOver, canDrop, isOverCurrent) : "#FFF";
        const backgroundColorTitle = colorTitleHover(isOver, canDrop, isOverCurrent);
        const backgroundColorBorder = colorBorderHover(isOver, canDrop, isOverCurrent);
        const styleContentSection = _.get(styleSection, "content", {});
        const styleBorderSection = getBorders(_.get(styleSection, "borders", []));
        return connectDropTarget(
            <div style={{ ...styles.box, borderColor: backgroundColorBorder }}>
                <TabElementComponent
                    pathArray={pathArray}
                    backgroundColorTitle={backgroundColorTitle}
                    path={path}
                    position={position}
                    name={"Sección"}
                    survey={survey}
                    type={Types.SECTION}
                    propElement={{ type: Types.SECTION }}
                />
                <div style={{ ...styleBorderSection, height: "100%" }}>
                    <table style={{ borderCollapse: "collapse", width: "100%", height: "100%", backgroundColor }}>
                        <tbody>
                            <tr>
                                <td style={{ height: 45 }}>
                                    <TitleContent path={path} name={"Sección"} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Paper
                                        style={{
                                            ...styles.contentPaper,
                                            ...styleContentSection,
                                            backgroundColor,
                                            textAlign: contentAlign,
                                        }}
                                        zDepth={0}
                                    >
                                        <EmptyContent
                                            text={textMessage}
                                            hasElements={hasContent}
                                            color={colorSelector}
                                        />
                                        {elementsRender}
                                    </Paper>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>,
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            dropElement: de,
        },
        dispatch,
    );
}

function mapStateToProps({ analyticsReportsConfiguration }) {
    return {
        page: analyticsReportsConfiguration.get("page"),
        saving: analyticsReportsConfiguration.get("saving"),
    };
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    DropTarget(Object.values(Types), itemTarget, collect),
)(SectionDropContext);
