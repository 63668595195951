import { GET_LIST_GRID } from '../actions/grid/list_grid_form';
import { POST_LIST_INFO } from '../actions/info/list_info_form';
import _ from 'lodash';

const INITIAL_STATE = { all: [], list: null};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_LIST_GRID:
            return  _.assign({}, state, {
                all: action.payload.data
            });
        case POST_LIST_INFO:
            return  _.assign({}, state, {
                all: action.payload.data
            });
        default:
            return state;
    }
}
