import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Row, Col, Grid } from 'react-flexbox-grid';
import AppBarArl from './appBarComponent';

class dashboardArl extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Grid fluid>
                    <Row>
                        <AppBarArl/>
                    </Row>
                    <Row style={{paddingTop: 50}}>
                        <Col xs>
                            {this.props.children}
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default connect(null)(dashboardArl);
