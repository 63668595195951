import PropTypes from "prop-types";
import React, { Component } from "react";
import {
    red400,
    lime400,
    green400,
    blue400,
    white,
} from "material-ui/styles/colors";
import Chip from "material-ui/Chip";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import _ from "lodash";

const customContentStyle = { maxWidth: "400px" };

const notConnect = (status) => _.isUndefined(status);

const isConnect = (status, lastPing, lastPong) =>
    status === "watching" && lastPing === -1 && lastPong === -1;

const isConsideredBoundary = (lastPing, lastPong) =>
    lastPing === lastPong || lastPing === lastPong + 1;

class StatusChip extends Component {
    static defaultProps = {
        status: undefined,
    };

    static propTypes = {
        lastPing: PropTypes.number.isRequired,
        lastPong: PropTypes.number.isRequired,
        status: PropTypes.string,
        lastUpdate: PropTypes.object.isRequired,
    };

    state = {
        open: false,
    };

    get color() {
        const { status, lastPing, lastPong } = this.props;
        if (notConnect(status)) {
            return red400;
        } else if (isConnect(status, lastPing, lastPong)) {
            return blue400;
        }
        if (isConsideredBoundary(lastPing, lastPong)) {
            return green400;
        }
        return lime400;
    }

    get label() {
        const { status, lastPing, lastPong } = this.props;
        if (notConnect(status)) {
            return "No conectado";
        } else if (isConnect(status, lastPing, lastPong)) {
            return "Conectado";
        }
        if (isConsideredBoundary(lastPing, lastPong)) {
            return "En linea";
        }
        return "Latencia en los mensajes";
    }

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const actions = [
            <FlatButton label="Cerrar" primary onClick={this.handleClose} />,
        ];
        const background = this.color;
        return (
            <div>
                <Chip
                    backgroundColor={background}
                    labelColor={white}
                    onClick={this.handleOpen}
                >
                    {this.label}
                </Chip>
                <Dialog
                    actions={actions}
                    modal={false}
                    open={this.state.open}
                    onRequestClose={this.handleClose}
                    contentStyle={customContentStyle}
                >
                    Estado actual: {this.label}
                    <br />
                    <br />
                    Última actualización:{" "}
                    {this.props.lastUpdate.locale("es").format("LLLL")}
                </Dialog>
            </div>
        );
    }
}

export default StatusChip;
