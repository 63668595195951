// @flow
import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { blue400 } from "material-ui/styles/colors";
import Types from "../dragComponentTypes";
import TextComponent from "../configComponents/textComponent";
import ChartComponent from "../configComponents/chartComponent";
import MenuConfigComponent from "../configComponents/menuConfigComponent";
import RankingConfigComponent from "../configComponents/rankingConfigComponent";
import TabElementComponent from "../componentsUtil/tabElementComponent";
import TitleContent from "../componentsUtil/titleContent";
import { orientationTypes } from "../../reportComponents/types";
import TitleTypeComponent from "./titleTypeComponent";
import ButtonDetailComponent from "../configComponents/buttonDetailComponent";

const styles = {
    box: {
        border: "1px solid",
        minHeight: 130,
        boxSizing: "border-box",
        backgroundColor: "#FFF",
    },
};

function getStyleExtra(type, orientation) {
    const { TEXT } = Types;
    switch (type) {
        case TEXT:
            return { width: "100%" };
        default:
            if (_.isEqual(orientation, orientationTypes.ORIENTATION_HORIZONTAL)) {
                return { display: "inline-block", verticalAlign: "top" };
            }
            return { width: "100%" };
    }
}

function getTextTooltip(elementProps) {
    const { DEMOGRAPHIC_CHART, CHART, RANKING } = Types;
    const type = _.get(elementProps, "type");
    switch (type) {
        case RANKING:
            return `Ver detalle ranking`;
        case CHART:
            const typeChart = _.get(elementProps, "chartProps.chartType");
            switch (typeChart) {
                case DEMOGRAPHIC_CHART:
                    return `Ver detalle socio-demográfico`;
                default:
                    return `Ver detalle de indicadores`;
            }
        default:
            return "Ver detalle";
    }
}

class ElementContent extends Component {
    static defaultProps = {
        title: {
            text: "",
            style: {},
            code: "",
        },
        chartProps: {},
        textProps: { text: "<span></span>" },
        style: {},
    };

    static propTypes = {
        index: PropTypes.number.isRequired,
        elementProps: PropTypes.any.isRequired,
        path: PropTypes.string.isRequired,
        pathArray: PropTypes.string.isRequired,
        position: PropTypes.number.isRequired,
        survey: PropTypes.string.isRequired,
        orientation: PropTypes.string.isRequired,
        saving: PropTypes.bool.isRequired,
        rowPath: PropTypes.string.isRequired,
        sectionPath: PropTypes.string.isRequired,
    };

    renderElements = () => {
        const { elementProps, rowPath, sectionPath, path } = this.props;
        const { TEXT, CHART, RANKING, MENU } = Types;
        switch (_.get(elementProps, "type")) {
            case TEXT:
                return (
                    <TextComponent {...elementProps} rowPath={rowPath} sectionPath={sectionPath} elementPath={path} />
                );
            case CHART:
                return (
                    <ChartComponent {...elementProps} rowPath={rowPath} sectionPath={sectionPath} elementPath={path} />
                );
            case RANKING:
                return <RankingConfigComponent rowPath={rowPath} sectionPath={sectionPath} elementPath={path} />;
            case MENU:
                return <MenuConfigComponent rowPath={rowPath} sectionPath={sectionPath} elementPath={path} />;
            default:
                return <span>{_.get(elementProps, "type")}</span>;
        }
    };

    render() {
        const { elementProps, orientation, path, index, pathArray, position, survey } = this.props;
        const styleExtra = getStyleExtra(_.get(elementProps, "type"), orientation);
        const styleChild = _.get(elementProps, "style", {});
        const visibleButton = _.get(elementProps, "chartProps.hasDetail", false);
        return (
            <div
                style={{
                    ...styles.box,
                    ...styleExtra,
                    marginBottom: 30,
                    borderColor: blue400,
                }}
            >
                <TabElementComponent
                    pathArray={pathArray}
                    backgroundColorTitle={blue400}
                    path={path}
                    name={"Elemento"}
                    position={position}
                    survey={survey}
                    type={Types.ELEMENT}
                    propElement={{ type: Types.ELEMENT, props: elementProps }}
                />
                <TitleTypeComponent elementProps={elementProps} />
                <TitleContent path={path} name={"Elemento"} />
                <div style={{ ...styleChild }}>{this.renderElements()}</div>
                <div style={{ height: visibleButton ? 54 : 0 }}>
                    <div style={{ margin: "0px 4px 4px 0px", float: "right" }}>
                        <ButtonDetailComponent textTooltip={getTextTooltip(elementProps)} visible={visibleButton} />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps({ analyticsReportsConfiguration }) {
    return {
        page: analyticsReportsConfiguration.get("page"),
        saving: analyticsReportsConfiguration.get("saving"),
    };
}

export default connect(mapStateToProps)(ElementContent);
