import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Row, Col} from 'react-flexbox-grid';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FetchListLapsesTimeParam } from '../../../actions/info/projects_info_form';
import { LBLPROJECTS } from '../../../constants/labels';
import AuthComponent from '../../AuthComponent';
import {Link} from 'react-router';
import RaisedButton from 'material-ui/RaisedButton';
import DeleteEntityModal from '../../deleteEntityModal/component';
import { Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui/Table';
import { deleteConfigDayProject } from '../../../actions/configProjects_form';
import { toggleSnackbar } from '../../../actions/commons';

class LapsesTimeInfoProjectComponent extends Component {

    constructor(props){
        super(props);
        this._toUpdateLapsesTime = this._toUpdateLapsesTime.bind(this);
    }

    UNSAFE_componentWillMount() {
        this._toUpdateLapsesTime();
    }

    _toUpdateLapsesTime(){
        const { projectId, FetchListLapsesTimeParam } = this.props;
        FetchListLapsesTimeParam(projectId);
    }

    _handleDeleteConfigDay(day) {
        const { projectId, deleteConfigDayProject, toggleSnackbar } = this.props;
        deleteConfigDayProject(projectId, day)
        toggleSnackbar(true, LBLPROJECTS.msg.successDelete);
    }

    renderWeekDay(weeks, projectId) {
        if (_.size(weeks)) {
            let arrayDays = _.orderBy(weeks, 'day', 'asc');
            return arrayDays.map((week) => {
                let day = '';
                switch (week.day) {
                case 1:
                    day = LBLPROJECTS.timeLapse.week[0];
                    break;
                case 2:
                    day = LBLPROJECTS.timeLapse.week[1];
                    break;
                case 3:
                    day = LBLPROJECTS.timeLapse.week[2];
                    break;
                case 4:
                    day = LBLPROJECTS.timeLapse.week[3];
                    break;
                case 5:
                    day = LBLPROJECTS.timeLapse.week[4];
                    break;
                case 6:
                    day = LBLPROJECTS.timeLapse.week[5];
                    break;
                case 7:
                case 0:
                    day = LBLPROJECTS.timeLapse.week[6];
                    break;
                default:
                    day = 'null';
                    break;
                }

                let arraylapses = ['', '', '', ''];

                if (_.size(week.lapses) > 0) {

                    for (let i = 0; i < 4; i++) {
                        if (!_.isUndefined(week.lapses[i])) {
                            let TimeLapso = this.OrganizarTiempo(week.lapses[i].open.hour) + ':';
                            TimeLapso += this.OrganizarTiempo(week.lapses[i].open.minute) + ' a ';
                            TimeLapso += this.OrganizarTiempo(week.lapses[i].close.hour) + ':';
                            TimeLapso += this.OrganizarTiempo(week.lapses[i].close.minute);
                            arraylapses[i] = TimeLapso;
                        }
                    }
                }
                return (
                    <TableRow key={`dayWeek${week.day}`}>
                        <TableRowColumn style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>{day}</TableRowColumn>
                        <TableRowColumn style={{whiteSpace: 'normal'}}>{arraylapses[0]}</TableRowColumn>
                        <TableRowColumn style={{whiteSpace: 'normal'}}>{arraylapses[1]}</TableRowColumn>
                        <TableRowColumn style={{whiteSpace: 'normal'}}>{arraylapses[2]}</TableRowColumn>
                        <TableRowColumn style={{whiteSpace: 'normal'}}>{arraylapses[3]}</TableRowColumn>
                        <TableRowColumn style={{whiteSpace: 'normal'}}>
                            <div style={{position: 'absolute', marginTop: -24, zIndex: 99}}>
                                <AuthComponent
                                    component={
                                        <DeleteEntityModal
                                            tooltip={LBLPROJECTS.tooltips.btnDelete}
                                            title={LBLPROJECTS.titleDeleteConfigDay}
                                            message={LBLPROJECTS.msg.deleteConfigDayModal(day)}
                                            fn={this._handleDeleteConfigDay.bind(this, week.day)}
                                            args={[projectId, week.day]}
                                        />
                                    }
                                    permission={"cgr_project_delete_lapses_time"}
                                    type={"component"}
                                />
                            </div>
                        </TableRowColumn>
                    </TableRow>
                );
            });
        } else {
            return (<TableRow></TableRow>)
        }
    }

    OrganizarTiempo(numberTime) {
        if (numberTime > 9) {
            return numberTime;
        } else {
            return '0' + numberTime;
        }
    }

    render() {
        const { lapsesTimeInfo, urlBase, projectId } = this.props;
        if(!_.isNull(lapsesTimeInfo)){
            return (
                <div style={{ height: 'auto', padding: 0 }}>
                    <Row>
                        <Col xsOffset={1} xs={10}>
                            <h2 style={{
                                textAlign: 'center',
                                fontWeight: 400,
                                marginTop: 40,
                                marginBottom: 20
                            }}>{LBLPROJECTS.timeLapse.configInactiveTitle}</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col xsOffset={1} xs={10} mdOffset={2} md={10}>
                            {LBLPROJECTS.msg.inactiveMessage(_.isUndefined(_.get(lapsesTimeInfo, 'inactive')) ? 0 : parseInt(_.get(lapsesTimeInfo, 'inactive')))}
                        </Col>
                    </Row>
                    <AuthComponent
                        component={<div>
                            <Row>
                                <Col xsOffset={1} xs={10}>
                                    <h2 style={{
                                        textAlign: 'center',
                                        fontWeight: 400,
                                        marginTop: 40,
                                        marginBottom: 20
                                    }}>{LBLPROJECTS.timeLapse.configTitle}</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col xsOffset={2} xs={10}>
                                    <Link to={`${urlBase}/1`}>
                                        <RaisedButton label={LBLPROJECTS.timeLapse.week[0]}
                                                      secondary={true}
                                                      style={{margin: 5}}/>
                                    </Link>
                                    <Link to={`${urlBase}/2`}>
                                        <RaisedButton label={LBLPROJECTS.timeLapse.week[1]}
                                                      secondary={true}
                                                      style={{margin: 5}}/>
                                    </Link>
                                    <Link to={`${urlBase}/3`}>
                                        <RaisedButton label={LBLPROJECTS.timeLapse.week[2]}
                                                      secondary={true}
                                                      style={{margin: 5}}/>
                                    </Link>
                                    <Link to={`${urlBase}/4`}>
                                        <RaisedButton label={LBLPROJECTS.timeLapse.week[3]}
                                                      secondary={true}
                                                      style={{margin: 5}}/>
                                    </Link>
                                    <Link to={`${urlBase}/5`}>
                                        <RaisedButton label={LBLPROJECTS.timeLapse.week[4]}
                                                      secondary={true}
                                                      style={{margin: 5}}/>
                                    </Link>
                                    <Link to={`${urlBase}/6`}>
                                        <RaisedButton label={LBLPROJECTS.timeLapse.week[5]}
                                                      secondary={true}
                                                      style={{margin: 5}}/>
                                    </Link>
                                    <Link to={`${urlBase}/0`}>
                                        <RaisedButton label={LBLPROJECTS.timeLapse.week[6]}
                                                      secondary={true}
                                                      style={{margin: 5}}/>
                                    </Link>
                                </Col>
                            </Row>
                        </div>}
                        permission={"cgr_project_update_lapses_time"}
                        type={"component"}
                    />
                    <Row>
                        <Col xsOffset={1} xs={10}>
                            <h2 style={{
                                textAlign: 'center',
                                fontWeight: 400,
                                marginTop: 40,
                                marginBottom: 20
                            }}>{LBLPROJECTS.timeLapse.title}</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs>
                            <Table selectable={false}>
                                <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                                    <TableRow>
                                        <TableHeaderColumn>{LBLPROJECTS.timeLapse.day}</TableHeaderColumn>
                                        <TableHeaderColumn>{LBLPROJECTS.timeLapse.firstLapse}</TableHeaderColumn>
                                        <TableHeaderColumn>{LBLPROJECTS.timeLapse.secondLapse}</TableHeaderColumn>
                                        <TableHeaderColumn>{LBLPROJECTS.timeLapse.thirdLapse}</TableHeaderColumn>
                                        <TableHeaderColumn>{LBLPROJECTS.timeLapse.fourthLapse}</TableHeaderColumn>
                                        <TableHeaderColumn>{LBLPROJECTS.timeLapse.actions}</TableHeaderColumn>
                                    </TableRow>
                                </TableHeader>
                                <TableBody displayRowCheckbox={false}>
                                    {this.renderWeekDay(_.get(lapsesTimeInfo, 'time.dayWeek'), projectId)}
                                </TableBody>
                            </Table>
                        </Col>
                    </Row>
                </div>
            );
        } else {
            return (<div></div>);
        }
    }
}

function mapStateToProps({ projects }) {
  
    return {
        lapsesTimeInfo: _.get(projects, 'lapsesTimeInfo', null)
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        FetchListLapsesTimeParam,
        deleteConfigDayProject,
        toggleSnackbar
    }, dispatch);
}

LapsesTimeInfoProjectComponent.propTypes = {
    projectId: PropTypes.string.isRequired,
    urlBase: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(LapsesTimeInfoProjectComponent);
