import PropTypes from "prop-types";
import React, { Component } from "react";
import { getRamdonString } from "../../../lib/util";
import _ from "lodash";

class LegendStackedKeysComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data, keyLegend } = this.props;
        return (
            <div style={{ textAlign: "left", marginBottom: 10, padding: 5 }} className="print_pdf_children_legend">
                {_.chain(data)
                    .map((value, index) => (
                        <span
                            key={`legend-values-${index}-${getRamdonString()}`}
                            style={{
                                display: "inline-block",
                                fontSize: 12,
                                marginRight: 7,
                                textAlign: "left" /* , whiteSpace: 'nowrap' */,
                            }}
                        >
                            <div style={getStyleCircle(_.get(value, "color", "#FFF"))}>
                                <div style={{ opacity: 0, position: "absolute" }}>.</div>
                            </div>
                            {_.get(value, "text", "")}
                        </span>
                    ))
                    .value()}
            </div>
        );
    }
}

function getBorderColor(color) {
    if (_.isEqual(color, "")) {
        return "0px solid #CCC";
    }
    return "1px solid #CCC";
}

function getStyleCircle(color) {
    return {
        display: "inline-block",
        width: 16,
        height: 16,
        margin: "0px 2px -3px 0px",
        borderRadius: "100%",
        backgroundColor: color,
        color,
    };
}

LegendStackedKeysComponent.propTypes = {
    data: PropTypes.array.isRequired,
};

export default LegendStackedKeysComponent;
