import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    blue700,
    green700,
    green200,
    indigo900,
    tealA700,
    greenA700,
    lightGreenA700,
    limeA700,
} from "material-ui/styles/colors";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import IconButton from "material-ui/IconButton";
import { Circle } from "rc-progress";
import _ from "lodash";
import { getSurveyAdvance } from "./actions";
import { idEmployeeTest } from "../../../constants/labels";

const customContentStyle = {
    maxWidth: 600,
    minWidth: 300,
    textAlign: "justify",
};

class SurveyAdvanceEmployee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            color: greenA700,
            secondColor: green200,
            loggedIn: false,
            windowWidth: window.innerWidth,
            open: false,
        };
        this.handleResize = this.handleResize.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.renderDialogInfo = this.renderDialogInfo.bind(this);
        this.getPercentage = this.getPercentage.bind(this);
    }

    handleResize(e) {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    handleOpen() {
        this.setState({ open: true });
    }

    handleClose() {
        this.setState({ open: false });
    }

    UNSAFE_componentWillMount() {
        const { login, getSurveyAdvance, idProject, idSurveys } = this.props;
        const userID = login.get("loggedIn")
            ? _.get(login.get("profile"), "person._id")
            : idEmployeeTest;
        if (idProject && !_.isEqual(userID, "")) {
            getSurveyAdvance(idProject, idSurveys, userID);
        }
    }

    renderListDialog(advance, currentSection, answered) {
        if (!_.isUndefined(advance) && advance.titles instanceof Array) {
            return advance.titles.map((value, index) => {
                let answeredQuestion =
                    value.order === currentSection ? answered : 0;
                return (
                    <tr
                        key={index}
                        style={{
                            borderBottom: 1,
                            borderBottomStyle: "solid",
                            borderBottomColor: "#ccc",
                        }}
                    >
                        <td>
                            <h3
                                style={{
                                    fontWeight: 200,
                                    padding: 0,
                                    marginTop: 7,
                                    marginBottom: 7,
                                    marginLeft: 7,
                                    textAlign: "left",
                                }}
                            >{`${index + 1}. ${value.title} (${
                                value.question
                            } preguntas)`}</h3>
                        </td>
                        <td style={{ paddingLeft: 15, paddingRight: 5 }}>
                            <h3 style={{ fontWeight: 200 }}>
                                {value.order < currentSection ? (
                                    <p style={{ color: green700 }}>100%</p>
                                ) : (
                                    <p style={{ color: "#ccc" }}>
                                        {answeredQuestion === 0
                                            ? `0%`
                                            : `${(
                                                  (answeredQuestion * 100) /
                                                  value.question
                                              ).toFixed(0)}%`}
                                    </p>
                                )}
                            </h3>
                        </td>
                    </tr>
                );
            });
        }
    }

    renderDialogInfo(advance, currentSection, answered) {
        return (
            <Dialog
                title="Estado de la encuesta"
                titleStyle={{ textAlign: "center", color: blue700 }}
                actions={
                    <FlatButton
                        label="Aceptar"
                        secondary={true}
                        onClick={this.handleClose}
                    />
                }
                modal={false}
                contentStyle={customContentStyle}
                autoScrollBodyContent={true}
                open={this.state.open}
                onRequestClose={this.handleClose}
            >
                <table
                    style={{
                        width: "90%",
                        margin: "0 auto",
                        borderCollapse: "collapse",
                        padding: 0,
                    }}
                >
                    <tbody>
                        {this.renderListDialog(
                            advance,
                            currentSection,
                            answered
                        )}
                    </tbody>
                </table>
            </Dialog>
        );
    }

    getPercentage(advance, currentSection, answered) {
        var colorMap = [tealA700, greenA700, lightGreenA700, limeA700];
        let result = 0;
        if (currentSection > 1 && !_.isUndefined(advance)) {
            advance.titles.map((value) => {
                if (value.order < currentSection) {
                    result += value.question;
                }
            });
        }
        result = result + answered;
        if (result !== 0) {
            let percentage = ~~((result * 100) / advance.countQuestions);
            percentage = percentage > 100 ? 100 : percentage;
            return percentage;
        }
        return 0;
    }

    render() {
        const { currentSection, isAdmin, advance, answered } = this.props;
        if (!_.isNull(advance)) {
            const percentage = this.getPercentage(
                advance,
                currentSection,
                answered
            );
            const AdvanceStyle = {
                    width: 87,
                    height: 53,
                    color: indigo900,
                    textAlign: "center",
                    position: "fixed",
                    top: 185,
                    left: 20,
                    zIndex: 9999,
                    fontSize: "medium",
                    display: !isAdmin
                        ? this.state.open
                            ? "none"
                            : "block"
                        : "none",
                },
                styleCircularProgress = {
                    width: 87,
                    height: 87,
                    position: "fixed",
                    padding: 0,
                    borderRadius: "100%",
                    top: 170,
                    left: 20,
                    zIndex: 99998,
                    display: !isAdmin
                        ? this.state.open
                            ? "none"
                            : "block"
                        : "none",
                },
                styleButton = {
                    position: "fixed",
                    top: 188,
                    left: 38,
                    zIndex: 99999,
                    width: 50,
                    height: 50,
                    borderRadius: "100%",
                    display: !isAdmin
                        ? this.state.open
                            ? "none"
                            : "block"
                        : "none",
                };
            return (
                <div>
                    <div style={AdvanceStyle}>
                        <h3
                            style={{
                                zIndex: 9999,
                                fontWeight: 500,
                                marginLeft: 4,
                                paddingTop: 1,
                                color: "#000",
                            }}
                        >{`${percentage}%`}</h3>
                    </div>
                    <div style={styleCircularProgress}>
                        <Circle
                            percent={percentage}
                            strokeWidth={10}
                            strokeColor={this.state.color}
                            trailWidth={0}
                            trailColor={this.state.secondColor}
                        />
                    </div>
                    <IconButton
                        tooltip="Click para ver estado"
                        style={styleButton}
                        touch
                        tooltipPosition="bottom-right"
                        onClick={this.handleOpen}
                    />
                    {this.renderDialogInfo(advance, currentSection, answered)}
                </div>
            );
        }
        return null;
    }
}

SurveyAdvanceEmployee.propTypes = {
    currentSection: PropTypes.number.isRequired,
    idSurveys: PropTypes.string.isRequired,
    idProject: PropTypes.string,
    isAdmin: PropTypes.bool.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getSurveyAdvance,
        },
        dispatch
    );
}

function mapStateToProps({ surveyadvance, questionsSurvey, login }) {
    return {
        advance: surveyadvance.get("advance"),
        login,
        answered: questionsSurvey.get("answered").size,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SurveyAdvanceEmployee);
