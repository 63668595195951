import { axiosAPI, axiosAPI_V2 } from '../../middleware/api';
import _ from 'lodash';
import {
    GENERIC_GET_RECORDS,
    GENERIC_ADD_RECORD,
    GENERIC_CLEAN_TABLE,
    GENERIC_REMOVE_RECORD,
    GENERIC_REMOVE_MASSIVE_RECORD,
    GENERIC_CHANGE_FIELD_RECORD,
    GENERIC_LOCKED_RECORD,
    GENERIC_CHANGE_STATUS_RECORD,
    GENERIC_RESET_ORDER_RECORDS,
    GENERIC_SET_ARRAY_FIELDS_FILTER,
    GENERIC_SET_FIELD_SELECTION,
    GENERIC_CLEAN_SELECTED,
    GENERIC_ADD_SELECTED,
    GENERIC_REMOVE_SELECTED,
    GENERIC_ADD_MASSIVE_SELECTED,
    GENERIC_REMOVE_MASSIVE_SELECTED,
    GENERIC_SET_SELECTED,
    GENERIC_SET_STATUS_FILTER,
    GENERIC_SET_IN_SEARCH_RECORDS,
    GENERIC_CHANGE_LIMIT_RECORDS
} from './constants';

export function getRecords(url, version, skip, limit, orderName, orderType, arrayFields, textFilter, coreName, extraData) {

    let request = null;
    let params = {
        skip: skip,
        limit: limit,
        order: orderName,
        orderType: orderType,
        filter: arrayFields,
        textFilter: textFilter
    };

    if(!_.isUndefined(extraData) && !_.isNull(extraData)){
        params = _.set(params, 'extra', extraData);
    }

    if(version === 2){
        if(_.isEqual(url, '/arl/companies/projects')){
            request = axiosAPI_V2.post(url, { params: params });
        } else {
            request = axiosAPI_V2.get(url, { params: params });
        }
    } else {
        if(_.isEqual(url, '/arl/companies/projects')){
            request = axiosAPI.post(url, { params: params });
        } else {
            request = axiosAPI.get(url, { params: params });
        }
    }

    return {
        type: GENERIC_GET_RECORDS,
        payload: request,
        meta: {
            skip: skip,
            orderName: orderName,
            orderType: orderType,
            textFilter: textFilter,
            store: coreName
        }
    };

}

export function addRecordInList(record){

    return {
        type: GENERIC_ADD_RECORD,
        meta: {
            record: record
        }
    };
}

export function setExtraStatusFilter(status, coreName){

    return {
        type: GENERIC_SET_STATUS_FILTER,
        meta: {
            status: status,
            store: coreName
        }
    };
}

export function cleanTable(coreName) {

    return {
        type: GENERIC_CLEAN_TABLE,
        meta: {
            store: coreName
        }
    };

}

export function removeRecord(id, field, coreName){

    return {
        type: GENERIC_REMOVE_RECORD,
        meta: {
            id: id,
            store: coreName,
            field: field
        }
    };
}

export function removeMassiveRecord(arrayId, coreName){

    return {
        type: GENERIC_REMOVE_MASSIVE_RECORD,
        meta: {
            arrayId: arrayId,
            store: coreName
        }
    };
}

export function changeFieldRecord(id, field, value, coreName){

    return {
        type: GENERIC_CHANGE_FIELD_RECORD,
        meta: {
            id: id,
            store: coreName,
            field: field,
            value: value
        }
    };
}

export function LockRecord(id, coreName){

    return {
        type: GENERIC_LOCKED_RECORD,
        meta: {
            id: id,
            store: coreName
        }
    };
}

export function changeStatusRecord(ids, value){

    return {
        type: GENERIC_CHANGE_STATUS_RECORD,
        meta: {
            ids: ids,
            value: value
        }
    }
}

export function resetOrderRecord(coreName){

     return {
         type: GENERIC_RESET_ORDER_RECORDS,
         meta: {
             store: coreName
         }
     }
}

export function setArrayfieldsFilter(arrayFields, coreName){

    return {
        type: GENERIC_SET_ARRAY_FIELDS_FILTER,
        meta: {
            store: coreName,
            arrayFields: arrayFields
        }
    };
}

export function setFieldSelection(fieldSelection, coreName){

    return {
        type: GENERIC_SET_FIELD_SELECTION,
        meta: {
            fieldSelection: fieldSelection,
            store: coreName
        }
    };
}

export function cleanSelected(coreName){

    return {
        type: GENERIC_CLEAN_SELECTED,
        meta: {
            store: coreName
        }
    }
}

export function addSelected(id, coreName){

    return {
        type: GENERIC_ADD_SELECTED,
        meta: {
            id: id,
            store: coreName
        }
    }
}

export function removeSelected(id, coreName){

    return {
        type: GENERIC_REMOVE_SELECTED,
        meta: {
            id: id,
            store: coreName
        }
    }
}

export function addMassiveSelected(array, coreName){

    return {
        type: GENERIC_ADD_MASSIVE_SELECTED,
        meta: {
            array: array,
            store: coreName
        }
    }
}

export function removeMassiveSelected(array, coreName){

    return {
        type: GENERIC_REMOVE_MASSIVE_SELECTED,
        meta: {
            array: array,
            store: coreName
        }
    }
}

export function setSelected(arraySelected, coreName){

    return {
        type: GENERIC_SET_SELECTED,
        meta: {
            selected: arraySelected,
            store: coreName
        }
    }
}

export function setInSearchRecords(coreName){

    return {
        type: GENERIC_SET_IN_SEARCH_RECORDS,
        meta: {
            store: coreName
        }
    }
}

export function changeLimitRecords(limit, coreName){

    return {
        type: GENERIC_CHANGE_LIMIT_RECORDS,
        meta: {
            limit: limit,
            store: coreName
        }
    }
}
