import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {CardActions} from 'material-ui/Card';
import {Row, Col} from 'react-flexbox-grid';
import ContentSend from 'material-ui/svg-icons/content/send';
import size from 'lodash/size';
import {sendMessage} from '../../actions/chat';
import TextField from 'material-ui/TextField';
import FloatingActionButton from 'material-ui/FloatingActionButton';

import {SOCKET_HOST, SOCKET_PORT} from '../../constants/index';
//const io = socket.connect(`http://${SOCKET_HOST}:${SOCKET_PORT}`);


const style = {
    marginRight: 20,
    marginTop: 5
};

class messagesField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            message: '',
            buttonActive: true
        };
        this.io = io;

        this._handleChange = this._handleChange.bind(this);
        this._handleClick = this._handleClick.bind(this);
    }

    componentDidMount() {
        var self = this;
        self.io.on('connect', function () {
            self.io.emit('newMessage', {body: "Hi there boys"});
        });

        this.io.on('newMessage', message => {
            console.log(message);
            self.props.sendMessage(message);
        });
    }

    _handleChange(e) {
        const message = e.target.value;
        console.log(message, size(message));
        this.setState({
            message: message,
            buttonActive: size(message) === 0
        });
    }

    _handleClick(e) {
        e.preventDefault();
        socket.send('newMessage', {body: this.state.message});
        this.setState({
            message: ''
        });
    }

    render() {
        return (
            <CardActions>
                <Row>
                    <Col xs={11}>
                        <TextField
                            hintText="Escribe tu mensaje aquí..."
                            floatingLabelText="Mensaje"
                            multiLine={true}
                            rowsMax={5}
                            onChange={this._handleChange}
                            style={{width: '100%'}}
                            value={this.state.message}
                        />
                    </Col>
                    <Col xs={1}>
                        <FloatingActionButton tooltip="Enviar mensaje..." onClick={this._handleClick} style={style}
                                              disabled={this.state.buttonActive}>
                            <ContentSend/>
                        </FloatingActionButton>
                    </Col>
                </Row>

            </CardActions>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        sendMessage
    }, dispatch);
}

export default connect(null, mapDispatchToProps)(messagesField);
