import _ from "lodash";
import Rx from "rxjs/Rx";
import { URL_LOGIN } from "../../constants/index";
import * as actions from "./constants";
import { axiosAPI_V2 } from "../../middleware/api";

export const GET_TEMPLATE_EMAIL = "GET_TEMPLATE_EMAIL";

export const sendEmail$ = (people, subject, message, typeTemplate, typeEmail, extra = {}) =>
    Rx.Observable
        .from(people)
        /* .filter(user => {
                if (_.has(user, "selected")) {
                    return _.get(user, "selected");
                } else {
                    return true;
                }
            }) */
        .map(person =>
            // return _.get(person, "person._id", null);
            _.get(person, "_id", null),
        )
        .bufferCount(50)
        .mergeMap(persons => {
            const mail = _.assign(
                _.omit(extra, ["persons", "typeTemplate", "subject", "message", "URL_LOGIN", "type"]),
                {
                    persons,
                    typeTemplate,
                    subject,
                    message,
                    URL_LOGIN,
                    type: typeEmail,
                },
            );
            const request = axiosAPI_V2.post(`/email`, { email: mail });
            return Rx.Observable.fromPromise(request).map(response => response.data);
        })
        .reduce((acc, current) => [...acc, current], []);

export function getTemplatesEmail() {
    const request = axiosAPI_V2.get(`/templateEmail/list`);

    return {
        type: GET_TEMPLATE_EMAIL,
        payload: request,
    };
}

export function sendEmail(form, persons, typeTemplate, typeEmail) {
    const mail = {
        persons,
        typeTemplate,
        subject: form.subject,
        message: form.message,
        URL_LOGIN,
        type: typeEmail,
    };

    const request = axiosAPI_V2.post(`/email`, { email: mail });

    return {
        type: actions.SEND_EMAIL,
        payload: request,
    };
}

export function openModalSendEmail(people, type) {
    return {
        type: actions.OPEN_MODAL_SEND_EMAIL,
        people,
        meta: {
            type,
        },
    };
}

export function openModalSendEmailId(peopleId, type) {
    const request = axiosAPI_V2.post(`/email/employees`, { email: { persons: peopleId } });

    return {
        type: actions.OPEN_MODAL_SEND_EMAIL_ID,
        payload: request,
        meta: {
            type,
        },
    };
}

export function openModalSendEmailUser(people, type, companyId) {
    return {
        type: actions.OPEN_MODAL_SEND_EMAIL_USER,
        people,
        meta: {
            type,
            companyId,
        },
    };
}

export function closeModal() {
    return {
        type: actions.CLOSE_MODAL_SEND_EMAIL,
    };
}
