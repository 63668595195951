import PropTypes from "prop-types";
import React, { Component } from "react";
import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";
import { Row, Col } from "react-flexbox-grid";
import { bindActionCreators } from "redux";
import MenuItem from "material-ui/MenuItem";
import { blue700 } from "material-ui/styles/colors";
import _ from "lodash";
import { reduxForm } from "redux-form";
import { LBLEMPLOYESS } from "../../constants/labels";
import Dialog from "../../../node_modules/material-ui/Dialog";
import { getTemplatesEmail, sendEmail, closeModal, sendEmail$ } from "./action";
import { showModalResult } from "../../components/ModalMessageResult/actions";
import { toggleSnackbar } from "../../actions/commons";
import { showSpinnerLoading } from "../../components/SpinnerLoading/actions";
import SelectFieldFormat from "../fields/SelectFieldFormat/SelectFieldFormat";
import RichText from "../../components/fields/RichText/RichText";

function getCompanyId(data, value) {
    if (!_.isNull(value) && !_.isUndefined(value) && !_.isEqual(value, "")) {
        return _.set(data, "companyId", value);
    }
    return data;
}

class SendMessageModal extends Component {
    constructor(props) {
        super(props);
        this.onChange = (editorState) => this.setState({ editorState });
    }

    state = {
        loadingSend: false,
        template: "",
    };

    handleModalClose = () => {
        const { closeModal, resetForm } = this.props;
        closeModal();
        resetForm();
        this.setState({ template: "" });
    };

    handleShowLoading = (show) => {
        const { showSpinnerLoading } = this.props;
        showSpinnerLoading(show, "Enviando correo...");
    };

    UNSAFE_componentWillMount() {
        const { getTemplatesEmail } = this.props;
        getTemplatesEmail();
    }

    _sendEmail = (formData) => {
        const {
            persons,
            sendEmail,
            toggleSnackbar,
            templatesEmail,
            typeEmail,
            companyId,
        } = this.props;
        const extra = getCompanyId({}, companyId);
        this.handleShowLoading(true);
        let templateType = "generic";
        if (!_.isEqual(_.get(formData, "template"), "")) {
            const index = _.findIndex(templatesEmail, (item) =>
                _.isEqual(_.get(item, "_id"), _.get(formData, "template"))
            );
            templateType = _.get(templatesEmail[index], "type");
        }
        this.handleModalClose();

        sendEmail$(
            persons,
            _.get(formData, "subject"),
            _.get(formData, "message"),
            templateType,
            typeEmail,
            extra
        ).subscribe(
            (next) => {
                // Luego terminar para mostrar un mensaje de respuesta
                // showModalResult(true, "titulo", "mensaje");
                console.log(next);
                if (_.size(persons) === 1) {
                    toggleSnackbar(true, "Correo enviado con éxito.");
                } else {
                    toggleSnackbar(true, "Correos enviados con éxito.");
                }
                this.handleShowLoading(false);
            },
            (error) => console.log(error),
            () => {
                this.handleShowLoading(false);
            }
        );
    };

    render() {
        const actions = [
            <FlatButton
                label={LBLEMPLOYESS.buttons.cancel}
                secondary
                onClick={this.handleModalClose}
            />,
            <FlatButton
                label={LBLEMPLOYESS.buttons.clear}
                secondary
                onClick={this.props.resetForm}
            />,
            <FlatButton
                style={{ marginLeft: 10 }}
                label={LBLEMPLOYESS.buttons.send}
                primary
                form="SendEmailForm"
                type="submit"
            />,
        ];

        const {
            fields: { subject, template, message },
            handleSubmit,
            persons,
            openModal,
        } = this.props;
        let toAddresses = "";
        let sizeEmail = 0;
        _.forEach(persons, (user) => {
            if (_.has(user, "selected")) {
                if (_.get(user, "selected")) {
                    toAddresses += `${_.get(user, "person.email")}; `;
                    sizeEmail++;
                }
            } else {
                toAddresses += `${_.get(user, "person.email")}; `;
                sizeEmail++;
            }
        });
        return (
            <Dialog
                title={LBLEMPLOYESS.lblSendEmail}
                modal
                autoDetectWindowHeight
                repositionOnUpdate
                autoScrollBodyContent
                titleStyle={{ textAlign: "center", color: blue700 }}
                open={openModal}
                actions={actions}
                onRequestClose={this.handleModalClose}
            >
                <form
                    id="SendEmailForm"
                    onSubmit={handleSubmit(this._sendEmail)}
                >
                    <Row>
                        <Col xsOffset={1} xs={10}>
                            <TextField
                                ref="toSend"
                                disabled
                                hintText=""
                                style={{ width: "100%" }}
                                defaultValue={toAddresses}
                                floatingLabelText={`${LBLEMPLOYESS.emailTo} (${sizeEmail}):`}
                                type="text"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xsOffset={1} xs={10}>
                            <TextField
                                ref="subject"
                                hintText=""
                                style={{ width: "100%" }}
                                floatingLabelText={LBLEMPLOYESS.subject}
                                type="text"
                                errorText={
                                    subject.touched && subject.error
                                        ? subject.error
                                        : ""
                                }
                                {...subject}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xsOffset={1} xs={10}>
                            <SelectFieldFormat
                                config={{
                                    floatingLabelText: LBLEMPLOYESS.template,
                                    value: template.value,
                                    fullWidth: true,
                                    autoWidth: true,
                                    onChange: (e, index, value) => {
                                        const i = _.findIndex(
                                            this.props.templatesEmail,
                                            (item) => _.isEqual(item._id, value)
                                        );

                                        const tem = _.get(
                                            this.props.templatesEmail[i],
                                            "template"
                                        );
                                        this.setState({ template: tem });
                                        template.onChange(value);
                                        message.onChange(tem);
                                    },
                                }}
                            >
                                {_.map(
                                    _.get(this, "props.templatesEmail", []),
                                    (item) => (
                                        <MenuItem
                                            key={item._id}
                                            value={item._id}
                                            primaryText={`${item.name} - ${
                                                _.isEqual(item.type, "login")
                                                    ? "Ingresar al sistema"
                                                    : "Genérica"
                                            }`}
                                        />
                                    )
                                )}
                            </SelectFieldFormat>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col xsOffset={1} xs={10}>
                            <span>{LBLEMPLOYESS.message}</span>
                            <RichText
                                field={message}
                                valueInitial={this.state.template}
                                errorText={
                                    message.touched && message.error
                                        ? message.error
                                        : ""
                                }
                            />
                        </Col>
                    </Row>
                </form>
            </Dialog>
        );
    }
}

const validate = (values) => {
    const errors = {};
    if (!values.subject) {
        errors.subject = "Ingrese el asunto";
    }
    if (!values.message) {
        errors.message = "Ingrese el mensaje";
    } else if (_.isEqual(values.message.trim(), "<p><br></p>")) {
        errors.message = "Ingrese el mensaje";
    } else {
        let txtDescription = values.message;
        while (txtDescription.indexOf("&nbsp;") !== -1) {
            txtDescription = _.replace(txtDescription, "&nbsp;", "");
        }
        if (
            _.isEqual(txtDescription, "<p><br></p>") ||
            _.isEqual(txtDescription, "<p></p>")
        ) {
            errors.message = "Ingrese el mensaje";
        }
    }

    return errors;
};

function mapStateToProps({ master, sendEmail }, ownProps) {
    return {
        initialValues: {
            subject: "",
            template: "",
            message: "",
        },
        openModal: sendEmail.get("openModal"),
        persons: sendEmail.get("persons"),
        typeEmail: sendEmail.get("type"),
        companyId: sendEmail.get("companyId"),
        templatesEmail: master.templatesEmail,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getTemplatesEmail,
            sendEmail,
            toggleSnackbar,
            closeModal,
            showSpinnerLoading,
            showModalResult,
        },
        dispatch
    );
}

export default reduxForm(
    {
        form: "SendEmailForm",
        fields: ["subject", "template", "message"],
        validate,
    },
    mapStateToProps,
    mapDispatchToProps
)(SendMessageModal);
