import PropTypes from "prop-types";
import React, { Component } from "react";
import TableCell from "@material-ui/core/TableCell";
import _ from "lodash";
import AuthComponent from "../../AuthComponent";
import { Link } from "react-router";
import IconButton from "material-ui/IconButton";
import { connect } from "react-redux";
import EditIcon from "material-ui/svg-icons/editor/mode-edit";
import { LBLCONSENTS } from "../../../constants/labels";
import DeleteEntityModal from "../../deleteEntityModal/component";
import { deleteConsent } from "../../../actions/consents_form";
import { toggleSnackbar } from "../../../actions/commons";
import { removeRecord } from "../actions";

class ConsentActionColumn extends Component {
    constructor(props) {
        super(props);
        this._handleDeleteConsent = this._handleDeleteConsent.bind(this);
    }

    _handleDeleteConsent(data) {
        const { deleteConsent, toggleSnackbar, removeRecord } = this.props;
        const idConsent = _.get(data, "_id");
        deleteConsent(idConsent)
            .then(
                (data) => {
                    if (
                        _.get(data, "error") ||
                        _.get(data, "payload.status") !== 200
                    ) {
                        toggleSnackbar(true, LBLCONSENTS.msg.errorDelete);
                    } else {
                        removeRecord(idConsent, "_id");
                        toggleSnackbar(true, LBLCONSENTS.msg.successDelete);
                    }
                },
                (reason) => {
                    toggleSnackbar(true, LBLCONSENTS.msg.errorDelete);
                }
            )
            .catch((data) => {
                toggleSnackbar(true, LBLCONSENTS.msg.errorDelete);
            });
    }

    render() {
        const { data, colSpan } = this.props;
        const cs = _.isUndefined(colSpan) ? 1 : colSpan;
        return (
            <TableCell colSpan={cs}>
                <AuthComponent
                    component={
                        <span
                            style={{
                                position: "absolute",
                                marginTop: -24,
                                zIndex: 99,
                            }}
                        >
                            <Link
                                to={`/admin/consents/edit/${_.get(
                                    data,
                                    "_id"
                                )}`}
                                style={{ display: "table-cell" }}
                            >
                                <IconButton
                                    tooltip={LBLCONSENTS.tooltips.btnEdit}
                                    tooltipPosition="top-center"
                                    children={<EditIcon />}
                                    tooltipStyles={{ zIndex: 999 }}
                                />
                            </Link>
                        </span>
                    }
                    permission={"cgr_consent_update"}
                    type={"component"}
                />
                <AuthComponent
                    component={
                        <span
                            style={{
                                position: "absolute",
                                marginLeft: 42,
                                marginTop: -24,
                                zIndex: 99,
                            }}
                        >
                            <DeleteEntityModal
                                tooltip={LBLCONSENTS.tooltips.btnDelete}
                                title={LBLCONSENTS.titleDelete}
                                message={LBLCONSENTS.msg.deleteModal(
                                    _.get(data, "name")
                                )}
                                fn={this._handleDeleteConsent}
                                args={[data]}
                                style={{ display: "table-cell" }}
                            />
                        </span>
                    }
                    permission={"cgr_consent_delete"}
                    type={"component"}
                />
            </TableCell>
        );
    }
}

ConsentActionColumn.propTypes = {
    data: PropTypes.object.isRequired,
    colSpan: PropTypes.number,
};

export default connect(null, { deleteConsent, toggleSnackbar, removeRecord })(
    ConsentActionColumn
);
