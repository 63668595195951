import PropTypes from "prop-types";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { reduxForm } from "redux-form";
import { Row, Col } from "react-flexbox-grid";
import TextField from "material-ui/TextField";
import Checkbox from "material-ui/Checkbox";
import { submitFormDetails } from "./excelGeneratorDucks";
import is from "is_js";
import get from "lodash/get";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";

const validate = (values) => {
    const errors = {};
    if (!values.email) {
        errors.email = "El correo electrónico es obligatorio.";
    } else {
        if (!is.email(values.email)) {
            errors.email = "Dirección de correo electrónico inválida.";
        }
    }
    if (!values.filename) {
        errors.filename = "El nombre de archivo es obligatorio.";
    }
    if (get(values, "type.0") === false && get(values, "type.1") === false) {
        errors.type = "Se debe seleccionar por lo menos un tipo.";
    }
    return errors;
};

class FormDetails extends Component {
    render() {
        const {
            handleSubmit,
            fields: { email, type, filename },
            submitFormDetails,
        } = this.props;
        return (
            <Row>
                <form
                    onSubmit={handleSubmit((data) => {
                        submitFormDetails(data);
                        this.props.handleNext();
                    })}
                >
                    <Col xs={12}>
                        <Row>
                            <Col xs>
                                <TextField
                                    ref="email"
                                    hintText="Ej: user@dominio.com"
                                    floatingLabelText="Dirección de correo electrónico"
                                    type="text"
                                    {...email}
                                    errorText={
                                        email.touched && email.error
                                            ? email.error
                                            : ""
                                    }
                                />
                            </Col>
                            <Col xs>
                                <TextField
                                    ref="filename"
                                    hintText="ReporteProyectoT"
                                    floatingLabelText="Nombre del archivo"
                                    type="text"
                                    {...filename}
                                    errorText={
                                        filename.touched && filename.error
                                            ? filename.error
                                            : ""
                                    }
                                />
                            </Col>
                            <Col xs>
                                <p>Tipo de archivo</p>
                                <Checkbox
                                    label="Resultados de datos brutos"
                                    checked={type.value[0]}
                                    onCheck={(e, checked) => {
                                        type.onChange([checked, type.value[1]]);
                                    }}
                                />
                                <Checkbox
                                    label="Resultados de datos procesados"
                                    checked={type.value[1]}
                                    onCheck={(e, checked) => {
                                        type.onChange([type.value[0], checked]);
                                    }}
                                />
                                {type.touched && type.error
                                    ? filename.error
                                    : ""}
                            </Col>
                        </Row>
                        <div style={{ margin: "12px 0" }}>
                            <RaisedButton
                                label="Siguiente"
                                disableTouchRipple={true}
                                disableFocusRipple={true}
                                primary={true}
                                style={{ marginRight: 12 }}
                                type="submit"
                            />
                            <FlatButton
                                label="Atrás"
                                disableTouchRipple={true}
                                disableFocusRipple={true}
                                onClick={this.props.handlePrev}
                            />
                        </div>
                    </Col>
                </form>
            </Row>
        );
    }
}

function mapStateToProps() {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ submitFormDetails }, dispatch);
}

export default reduxForm(
    {
        form: "excel-generator-form-details",
        fields: ["email", "type", "filename"],
        initialValues: {
            type: [false, false],
        },
        destroyOnUnmount: false,
        validate,
    },
    mapStateToProps,
    mapDispatchToProps
)(FormDetails);
