import PropTypes from "prop-types";
import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Paper from "material-ui/Paper";
import { Link } from "react-router";
import { LBLPROJECTS, LBLEMPLOYESS } from "../../../constants/labels";
import { getAdvanceProjectSurvey } from "../../../actions/projects_form";
import { unMountProject } from "../../../actions/info/projects_info_form";
import DoughnutChartGraphics from "../../DoughnutChart/doughnutChartGraphics";
import SendMessageModal from "../../ModalSendEmail/modalSendEmail";
import _ from "lodash";
import ButtonBack from "../../fields/buttonBack/buttonBack";
import { browserHistory } from "react-router";
import SpinnerLoading from "../../../components/SpinnerLoading/componentSpinner";
import LoadingPage from "../../../components/commons/loadingPage";
import RaisedButton from "material-ui/RaisedButton";
import AuthComponent from "../../AuthComponent";
import DownloadFile from "../../fields/DownloadFile/DownloadFile";
import UploadFile from "../../fields/UploadFile/UploadFile";
import { blue700 } from "material-ui/styles/colors";
import AdvanceUser from "../../fields/AdvanceUser/AdvanceUser";
import TablePagination from "../../TablePagination/tablePagination";
import TagsEmployee from "../../TagsEmployee/TagsEmployee";

const stylePaper = {
    marginBottom: 20,
    marginTop: 40,
};

class advanceProjectSurvey extends Component {
    constructor(props) {
        super(props);
        this.AdvanceProjectSurvey = this.AdvanceProjectSurvey.bind(this);
    }

    componentWillUnmount() {
        this.props.unMountProject();
    }

    UNSAFE_componentWillMount() {
        const { profile } = this.props;
        this.AdvanceProjectSurvey();
        const personId = _.get(profile, "person._id");
        if (!personId) {
            console.error("No hay un usuario logeado");
            browserHistory.push("/login");
        }
    }

    AdvanceProjectSurvey() {
        const { getAdvanceProjectSurvey, params } = this.props;
        getAdvanceProjectSurvey(params.project);
    }

    renderDescription(descrip) {
        let description = "";
        if (!_.isUndefined(descrip) && !_.isEqual(descrip, "")) {
            description = _.split(descrip, /\r\n|\r|\n/g);
        }
        if (description instanceof Array) {
            return (
                <h3 style={{ textAlign: "justify", fontWeight: 200 }}>
                    {description.map((value, index) => {
                        if (!_.isEqual(value, "")) {
                            return (
                                <div key={index}>
                                    {value}
                                    <br />
                                    <br />
                                </div>
                            );
                        }
                    })}
                </h3>
            );
        }
        return <h3 style={{ textAlign: "justify", fontWeight: 200 }}>{description}</h3>;
    }

    render() {
        const {
            project,
            advance,
            params,
            textTag,
            textValueTag,
            businessName,
            projectName,
            projectDescription,
            projectSurveyId,
            status,
        } = this.props;
        const urlBack = _.isEqual(_.get(params, "profile"), "adm")
            ? `/admin/companies/info/${params.company}`
            : "/admin/me/assignprojects";
        const urlEmail = `/admin/projects/email/${_.get(params, "profile")}/${_.get(params, "company")}/${_.get(
            params,
            "project",
        )}`;
        const urlProjectStats = `/admin/projects/sarin/${_.get(params, "profile")}/${_.get(params, "company")}/${_.get(
            params,
            "project",
        )}`;
        const urlProjectRenderReport = `/admin/projects/report/${_.get(params, "profile")}/company/${_.get(
            params,
            "company",
        )}/project/${_.get(params, "project")}/survey/${projectSurveyId}`;

        const body = [
            {
                field: "id",
                title: "Identificación",
                filter: true,
            },
            {
                field: "name",
                title: "Nombre",
                filter: true,
            },
            {
                field: "lastName",
                title: "Apellido(s)",
                filter: true,
            },
            {
                field: "email",
                title: "Correo electrónico",
                colspan: 2,
            },
            {
                field: "status",
                title: "Estado",
                order: false,
            },
            {
                actions: true,
                title: "Operaciones",
                colspan: 2,
            },
        ];
        let extra = {
            project: _.get(params, "project"),
            projectSurveyId,
            urlResult: `/admin/projects/employees/company/${_.get(params, "profile")}/${_.get(
                params,
                "company",
            )}/project`,
        };
        if (!_.isNull(textValueTag) && !_.isNull(textTag)) {
            extra = _.chain(extra)
                .set("textTag", textTag)
                .set("textValueTag", textValueTag)
                .value();
        }
        if (!_.isEqual(status, "all") && !_.isNull(status)) {
            extra = _.chain(extra)
                .set("status", status)
                .value();
        }
        if (!_.isNull(projectName) && !_.isNull(advance)) {
            return (
                <Row>
                    <AuthComponent
                        component={
                            <Col xs={12} mdOffset={1} md={10}>
                                <Paper style={stylePaper}>
                                    <Row>
                                        <Col xs>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    background: blue700,
                                                    paddingTop: 5,
                                                    paddingBottom: 5,
                                                    marginBottom: 10,
                                                    color: "#FFF",
                                                }}
                                            >
                                                <h1
                                                    style={{
                                                        textAlign: "center",
                                                        paddingLeft: 10,
                                                        paddingRight: 10,
                                                        fontWeight: 400,
                                                        lineHeight: 1.2,
                                                    }}
                                                >
                                                    {businessName}
                                                </h1>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs>
                                            <ButtonBack url={urlBack} />
                                            <h1
                                                style={{
                                                    textAlign: "center",
                                                    fontWeight: 400,
                                                    paddingLeft: 10,
                                                    paddingRight: 10,
                                                    marginTop: 30,
                                                    lineHeight: 1.2,
                                                }}
                                            >
                                                {projectName}
                                            </h1>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xsOffset={1} xs={10}>
                                            {this.renderDescription(projectDescription)}
                                        </Col>
                                    </Row>
                                    <AuthComponent
                                        component={
                                            <Row style={{ paddingTop: 15, paddingBottom: 15 }}>
                                                <Col xs style={{ textAlign: "center" }}>
                                                    <DownloadFile storeName="solutions" id={_.get(params, "project")} />
                                                </Col>
                                                <Col xs style={{ textAlign: "center" }}>
                                                    <UploadFile
                                                        storeName="solutions"
                                                        isXLSX
                                                        id={_.get(params, "project")}
                                                    />
                                                </Col>
                                            </Row>
                                        }
                                        permission={"cgr_results_load_massive"}
                                        type={"component"}
                                    />
                                    <DoughnutChartGraphics projectId={_.get(params, "project")} />
                                    <Row style={{ marginTop: 40 }}>
                                        <Col xs>
                                            <div style={{ margin: 20 }}>
                                                <AuthComponent
                                                    component={
                                                        <Link to={urlEmail} style={{ marginRight: 20 }}>
                                                            <RaisedButton
                                                                label={LBLEMPLOYESS.buttons.masiveOperations}
                                                                secondary
                                                            />
                                                        </Link>
                                                    }
                                                    permission={"cgr_results_send_massive_emails"}
                                                    type={"component"}
                                                />
                                                <AuthComponent
                                                    component={
                                                        <Link to={urlProjectStats} style={{ marginRight: 20 }}>
                                                            <RaisedButton
                                                                label={LBLPROJECTS.buttons.viewResultStats}
                                                                secondary
                                                            />
                                                        </Link>
                                                    }
                                                    permission={"cgr_results_project_view"}
                                                    type={"component"}
                                                />
                                                <AuthComponent
                                                    component={
                                                        <Link to={urlProjectRenderReport} style={{ marginRight: 20 }}>
                                                            <RaisedButton
                                                                label={LBLPROJECTS.buttons.viewReportProject}
                                                                secondary={true}
                                                                disabled={_.isUndefined(projectSurveyId)}
                                                                style={{display: _.isUndefined(projectSurveyId) ? 'none' : 'inline-block'}}
                                                            />
                                                        </Link>
                                                    }
                                                    permission={"cgr_results_project_report"}
                                                    type={"component"}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs>
                                            <TagsEmployee
                                                companyId={_.get(params, "company")}
                                                URL={`/projects/employees/status/${_.get(params, "company")}/${_.get(
                                                    params,
                                                    "project",
                                                )}`}
                                                version={2}
                                                extra={{
                                                    company: _.get(params, "company"),
                                                    profile: _.get(params, "profile"),
                                                }}
                                                filterStatus
                                                body={body}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs>
                                            <TablePagination
                                                name={"configemployeesstatus"}
                                                URL={`/projects/employees/status/${_.get(params, "company")}/${_.get(
                                                    params,
                                                    "project",
                                                )}`}
                                                version={2}
                                                body={body}
                                                extra={extra}
                                            />
                                        </Col>
                                    </Row>
                                </Paper>
                                <SpinnerLoading />
                                <SendMessageModal />
                                <AdvanceUser />
                            </Col>
                        }
                        permission={"cgr_projects_status"}
                        type={"url"}
                    />
                </Row>
            );
        }
        return <LoadingPage visible text="Cargando..." />;
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getAdvanceProjectSurvey,
            unMountProject,
        },
        dispatch,
    );
}

function mapStateToProps({ login, projects, tagsEmployee, tablePagination }) {
    return {
        login,
        businessName: _.get(projects, "project.data.company.businessName"),
        projectName: _.get(projects, "project.data.name"),
        projectDescription: _.get(projects, "project.data.description"),
        projectSurveyId: _.get(projects, "project.data.survey._id"),
        profile: login.get("profile"),
        textValueTag: tagsEmployee.get("textValueTag"),
        textTag: tagsEmployee.get("textTag"),
        status: tablePagination.get("status"),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(advanceProjectSurvey);
