import PropTypes from "prop-types";
import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { connect } from "react-redux";
import { blue700 } from "material-ui/styles/colors";
import { browserHistory } from "react-router";
import AuthComponent from "../AuthComponent";
import ButtonBack from "../fields/buttonBack/buttonBack";
import RaisedButton from "material-ui/RaisedButton";
import CircularProgress from "material-ui/CircularProgress";
import Divider from "material-ui/Divider";
import IconButton from "material-ui/IconButton";
import NoCollapseIcon from "material-ui/svg-icons/navigation/arrow-drop-up";
import CollapseIcon from "material-ui/svg-icons/navigation/arrow-drop-down";
import { Step, Stepper, StepLabel } from "material-ui/Stepper";
import FilterReportComponent from "./filterComponent/filterReportComponent";
import FilterInfoReportComponent from "./filterComponent/filterInfoReportComponent";
import RenameFilterReportComponent from "./filterComponent/renameFilterReportComponent";
import {
    resetValuesFilter,
    getValuesFilterRecordsAnswersConditionsTags,
} from "./filterComponent/ducks";
import {
    saveStateCombinations,
    changeStepIndex,
} from "./filterComponent/ducks";
import RenderReportProject from "./renderReportProject";

class FilterReport extends Component {
    constructor(props) {
        super(props);
        this.getStepContent = this.getStepContent.bind(this);
        this.handleNextOrFinalized = this.handleNextOrFinalized.bind(this);
        this.state = { hideFilter: true };
    }

    UNSAFE_componentWillMount() {
        const {
            params,
            resetValuesFilter,
            getValuesFilterRecordsAnswersConditionsTags,
        } = this.props;
        const isSaveFilter =
            !_.isUndefined(_.get(params, "action")) &&
            !_.isNull(_.get(params, "action")) &&
            _.isEqual(_.get(params, "action", ""), "init");
        this.setState({ hideFilter: !isSaveFilter });
        resetValuesFilter();
        getValuesFilterRecordsAnswersConditionsTags(_.get(params, "project"));
    }

    handleNextOrFinalized() {
        const {
            selectedValues,
            saveStateCombinations,
            stepIndex,
            changeStepIndex,
        } = this.props;
        const listKeysGroup = _.keys(selectedValues);

        let listComb = [],
            array = [];
        _.forEach(listKeysGroup, (key) => {
            const groupValues = _.groupBy(
                _.get(selectedValues, key, []),
                "parentId"
            );
            const listKeysSubGroup = _.keys(groupValues);
            _.forEach(listKeysSubGroup, (keySub) => {
                array.push(_.get(groupValues, keySub, []));
            });
        });

        listComb = _.map(combinations(array), (value) => ({
            title: getTitleCombine(value),
            name: "",
            combine: value,
        }));
        saveStateCombinations(listComb);
        changeStepIndex(stepIndex + 1);
    }

    getStepContent() {
        const { params, stepIndex, countSelectedValues } = this.props;
        const isSaveFilter =
            !_.isUndefined(_.get(params, "action")) &&
            !_.isNull(_.get(params, "action")) &&
            _.isEqual(_.get(params, "action", ""), "init");
        switch (stepIndex) {
            case 0:
                return (
                    <div style={{ width: "100%" }}>
                        <Row style={{ padding: 20 }}>
                            <Col xsOffset={1} xs={10}>
                                <FilterReportComponent
                                    projectId={_.get(params, "project")}
                                    companyId={_.get(params, "company")}
                                />
                            </Col>
                        </Row>
                        {countSelectedValues > 0 && (
                            <div>
                                <Row>
                                    <Col xs style={{ paddingTop: 5 }}></Col>
                                </Row>
                                <Row>
                                    <Col xs style={{ paddingTop: 10 }}>
                                        <div style={{ width: "100%" }}>
                                            <h2
                                                style={{
                                                    textAlign: "center",
                                                    marginTop: 30,
                                                    fontWeight: 400,
                                                }}
                                            >
                                                {"Filtros seleccionados"}
                                            </h2>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col
                                        xs
                                        style={{
                                            padding: "0px 30px 20px 30px",
                                        }}
                                    >
                                        <FilterInfoReportComponent />
                                    </Col>
                                </Row>
                            </div>
                        )}
                        <Row style={{ padding: 20 }}>
                            <Col xsOffset={1} xs={10}>
                                <RaisedButton
                                    label={
                                        stepIndex === 1
                                            ? "Finalizar"
                                            : "Continuar"
                                    }
                                    primary={true}
                                    onClick={this.handleNextOrFinalized}
                                    style={{ margin: 10, float: "right" }}
                                />
                            </Col>
                        </Row>
                    </div>
                );
            case 1:
                return (
                    <Row style={{ padding: 20 }}>
                        <Col xsOffset={1} xs={10}>
                            <RenameFilterReportComponent
                                isSaveFilter={isSaveFilter}
                                projectId={_.get(params, "project")}
                                companyId={_.get(params, "company")}
                                profileId={_.get(params, "profile")}
                                surveyId={_.get(params, "survey")}
                            />
                        </Col>
                    </Row>
                );
            default:
                return <span></span>;
        }
    }

    _handledHideContentFilter = () => {
        const { hideFilter } = this.state;
        this.setState({ hideFilter: !hideFilter });
    };

    render() {
        const { params, stepIndex } = this.props;
        const isSaveFilter =
            !_.isUndefined(_.get(params, "action")) &&
            !_.isNull(_.get(params, "action")) &&
            _.isEqual(_.get(params, "action", ""), "init");
        const { hideFilter } = this.state;
        let urlBack = "";
        let marginTopMenu = 0;
        if (isSaveFilter) {
            urlBack = `/admin/projects/info/${_.get(params, "profile")}/${_.get(
                params,
                "project"
            )}/${_.get(params, "company")}`;
        } else {
            if (
                _.isEqual(_.get(params, "profile"), "adm") ||
                _.isEqual(_.get(params, "profile"), "ref")
            ) {
                urlBack = `/admin/projects/advance/${_.get(
                    params,
                    "profile"
                )}/${_.get(params, "company")}/${_.get(params, "project")}`;
            } else if (_.isEqual(_.get(params, "profile"), "comp")) {
                urlBack = `/dashboard/company/project/advance/${_.get(
                    params,
                    "profile"
                )}/${_.get(params, "company")}/${_.get(params, "project")}`;
                marginTopMenu = 40;
            }
        }

        return (
            <Row style={{ marginTop: marginTopMenu }}>
                <AuthComponent
                    component={
                        <Col xs>
                            <Row>
                                <Col xs>
                                    <ButtonBack url={urlBack} />
                                    <AuthComponent
                                        component={
                                            <Paper
                                                style={{
                                                    width: 980,
                                                    margin: "20px auto 0",
                                                }}
                                            >
                                                <Row>
                                                    <Col xs>
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                background: blue700,
                                                                paddingTop: 1,
                                                                paddingBottom: 1,
                                                                color: "#FFF",
                                                            }}
                                                        >
                                                            <table
                                                                style={{
                                                                    borderCollapse:
                                                                        "collapse",
                                                                    padding: 0,
                                                                    width:
                                                                        "100%",
                                                                }}
                                                            >
                                                                <tbody>
                                                                    <tr>
                                                                        <td
                                                                            style={{
                                                                                width: 65,
                                                                                textAlign:
                                                                                    "center",
                                                                                padding: 0,
                                                                            }}
                                                                        ></td>
                                                                        <td
                                                                            style={{
                                                                                padding:
                                                                                    "0px 5px",
                                                                            }}
                                                                        >
                                                                            <h1
                                                                                style={{
                                                                                    textAlign:
                                                                                        "center",
                                                                                    marginTop: 20,
                                                                                    marginBottom: 10,
                                                                                    fontWeight: 400,
                                                                                }}
                                                                            >
                                                                                {isSaveFilter
                                                                                    ? "Filtro de información"
                                                                                    : "Configurar filtro"}
                                                                            </h1>
                                                                            <h4
                                                                                style={{
                                                                                    textAlign:
                                                                                        "center",
                                                                                    marginTop: 7,
                                                                                    marginBottom: 20,
                                                                                    fontWeight: 400,
                                                                                }}
                                                                            >
                                                                                {!isSaveFilter
                                                                                    ? "(Expanda el panel para configurar el filtro)"
                                                                                    : ""}
                                                                            </h4>
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                width: 65,
                                                                                textAlign:
                                                                                    "center",
                                                                                padding: 0,
                                                                            }}
                                                                        >
                                                                            {!isSaveFilter && (
                                                                                <IconButton
                                                                                    tooltip={
                                                                                        hideFilter
                                                                                            ? "Mostrar"
                                                                                            : "Ocultar"
                                                                                    }
                                                                                    tooltipPosition="bottom-center"
                                                                                    onClick={
                                                                                        this
                                                                                            ._handledHideContentFilter
                                                                                    }
                                                                                    children={
                                                                                        hideFilter ? (
                                                                                            <CollapseIcon
                                                                                                color={
                                                                                                    "#FFF"
                                                                                                }
                                                                                            />
                                                                                        ) : (
                                                                                            <NoCollapseIcon
                                                                                                color={
                                                                                                    "#FFF"
                                                                                                }
                                                                                            />
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        padding: "0 0",
                                                        display: hideFilter
                                                            ? "none"
                                                            : "inline",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            width: 340,
                                                            margin: "0 auto",
                                                            padding: 0,
                                                        }}
                                                    >
                                                        <Stepper
                                                            activeStep={
                                                                stepIndex
                                                            }
                                                        >
                                                            <Step>
                                                                <StepLabel>{`Selección de filtros`}</StepLabel>
                                                            </Step>
                                                            <Step>
                                                                <StepLabel>{`Renombrar filtros`}</StepLabel>
                                                            </Step>
                                                        </Stepper>
                                                    </div>
                                                    {this.getStepContent()}
                                                </div>
                                            </Paper>
                                        }
                                        permission={
                                            "cgr_results_project_filter"
                                        }
                                        type={"component"}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                {!isSaveFilter && (
                                    <Col xs>
                                        <RenderReportProject
                                            projectId={_.get(params, "project")}
                                            surveyId={_.get(params, "survey")}
                                            companyId={_.get(params, "company")}
                                            visible={hideFilter}
                                        />
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    }
                    permissions={[
                        "cgr_results_project_report",
                        "cgr_results_project_filter",
                    ]}
                    type={"url"}
                />
            </Row>
        );
    }
}

function combinations(arrays) {
    let combList = [];
    _.forEach(arrays, (array) => {
        combList = combineTwoArray(combList, array);
    });
    return combList;
}

function combineTwoArray(array1, array2) {
    let array = [];
    if (_.size(array1) === 0) {
        return _.map(array2, (value) => [value]);
    } else {
        _.forEach(array1, (list) => {
            _.forEach(array2, (value) => {
                array.push(_.concat(list, [value]));
            });
        });
    }
    return array;
}

function getTitleCombine(array) {
    let title = "";
    _.forEach(array, (value, index) => {
        title +=
            index > 0 ? " - " + _.get(value, "title") : _.get(value, "title");
    });
    return title;
}

function mapStateToProps({ projects, filterReport }, ownProps) {
    return {
        stepIndex: filterReport.get("stepIndex"),
        countSelectedValues: _.size(filterReport.get("selectedValues")),
        selectedValues: filterReport.get("selectedValues"),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetValuesFilter,
            getValuesFilterRecordsAnswersConditionsTags,
            saveStateCombinations,
            changeStepIndex,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterReport);
