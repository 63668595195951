import React, { Component } from "react";
import _ from "lodash";
import { Row, Col } from "react-flexbox-grid";
import CircularProgress from "material-ui/CircularProgress";
import { bindActionCreators } from "redux";
import RaisedButton from "material-ui/RaisedButton";
import { connect } from "react-redux";
import { blue700 } from "material-ui/styles/colors";
import PropTypes from "prop-types";
import randomColor from "randomcolor";
import AuthComponent from "../AuthComponent";
import Report from "./renderReport/report";
import {
    inSearchValues,
    getValuesReportRender,
    setColorDefault,
    getFilterDefault,
    setFilterDefault,
    resetValuesFilterDefault,
} from "./renderReport/ducks";
import { saveCurrentFilter } from "./filterComponent/ducks";
import {
    updateFilters,
    cleanSelectedFilters,
    cleanFilters,
} from "../analyticsReport/ducks";
import { parseSolrFiltersToSarinFilters } from "../analyticsReport/item/functions";
import DownloadReportProject from "./reportPrint/modalDownloadReport";
import { pageTypes } from "./types";

function getCurrentPage(currentPage) {
    switch (currentPage) {
        case pageTypes.MAIN:
            return "informe General";
        case pageTypes.SOCIO_DEMOGRAPHIC:
            return "detalle - sociodemográfico";
        case pageTypes.INDICATORS:
            return "detalle - indicadores";
        case pageTypes.RANKING:
            return "detalle - ranking";
        default:
            return "informe";
    }
}

function isMainPage(currentPage) {
    return _.isEqual(currentPage, pageTypes.MAIN);
}

function validateData(report) {
    if (_.size(_.get(report, "rows", [])) === 0) {
        return false;
    }
    return true;
}

class RenderReportProject extends Component {
    static propTypes = {
        projectId: PropTypes.string.isRequired,
        surveyId: PropTypes.string.isRequired,
        companyId: PropTypes.string.isRequired,
        visible: PropTypes.bool.isRequired,
    };

    componentDidMount() {
        const {
            inSearchValues,
            getValuesReportRender,
            cleanSelectedFilters,
            setColorDefault,
            getFilterDefault,
            saveCurrentFilter,
            resetValuesFilterDefault,
            projectId,
            updateFilters,
            cleanFilters,
            setFilterDefault,
        } = this.props;
        resetValuesFilterDefault();
        cleanFilters();
        cleanSelectedFilters();
        setColorDefault(randomColor({ luminosity: "light", count: 70 }));
        getFilterDefault(projectId)
            .then((data) => {
                setFilterDefault();
                if (
                    _.get(data, "error") ||
                    _.get(data, "payload.status") !== 200
                ) {
                    updateFilters(
                        parseSolrFiltersToSarinFilters(
                            [{ name: "Todos", combine: [] }],
                            projectId
                        )
                    );
                } else {
                    saveCurrentFilter(
                        _.get(data, "payload.data", [
                            { name: "Todos", combine: [] },
                        ])
                    );
                    updateFilters(
                        parseSolrFiltersToSarinFilters(
                            _.get(data, "payload.data", []),
                            projectId
                        )
                    );
                }
            })
            .catch((data) => {
                setFilterDefault();
                updateFilters(
                    parseSolrFiltersToSarinFilters(
                        [{ name: "Todos", combine: [] }],
                        projectId
                    )
                );
            });
        inSearchValues();
        getValuesReportRender(projectId, pageTypes.MAIN);
    }

    _handledGoMain = () => {
        const { inSearchValues, getValuesReportRender, projectId } = this.props;
        inSearchValues();
        getValuesReportRender(projectId, pageTypes.MAIN);
    };

    renderContent = () => {
        const { report, colorsDefault, projectId, surveyId } = this.props;
        if (validateData(report)) {
            return (
                <Report
                    surveyId={surveyId}
                    projectId={projectId}
                    rows={_.get(report, "rows", [])}
                    menu={_.get(report, "menu", [])}
                    maxRanking={_.get(report, "maxRanking", 5)}
                    colors={_.concat(
                        _.get(report, "colors", []),
                        colorsDefault
                    )}
                />
            );
        }
        return (
            <blockquote
                style={{
                    fontWeight: 400,
                    textAlign: "center",
                    margin: "25px 0",
                }}
            >
                No se ha configurado ninguna plantilla
            </blockquote>
        );
    };

    render() {
        const {
            inSearch,
            report,
            colorsDefault,
            currentPage,
            projectId,
            visible,
            surveyId,
            companyId,
            filters,
        } = this.props;
        const hideContent =
            _.isUndefined(visible) || _.isNull(visible) ? false : visible;
        if (inSearch || _.isEqual(filters, [])) {
            return (
                <div
                    style={{
                        textAlign: "center",
                        padding: "1px 15px 15px 15px",
                    }}
                >
                    <CircularProgress
                        size={35}
                        thickness={3}
                        style={{ marginTop: 20, marginBottom: 20 }}
                    />
                </div>
            );
        }
        return (
            <AuthComponent
                component={
                    <div
                        style={{
                            width: 980,
                            margin: "0 auto 30px",
                            marginTop: 0,
                            background: "#FFF",
                        }}
                    >
                        <Row>
                            <Col xs>
                                <div
                                    style={{
                                        width: "100%",
                                        background: blue700,
                                        paddingTop: 1,
                                        paddingBottom: 1,
                                        color: "#FFF",
                                        borderTop: "1px solid #FFF",
                                    }}
                                >
                                    <table
                                        style={{
                                            borderCollapse: "collapse",
                                            padding: 0,
                                            width: "100%",
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                {!isMainPage(currentPage) ? (
                                                    <td
                                                        style={{
                                                            width: 65,
                                                            textAlign: "center",
                                                            padding: 0,
                                                        }}
                                                    />
                                                ) : (
                                                    <td
                                                        style={{
                                                            width: 65,
                                                            textAlign: "center",
                                                            padding: 0,
                                                        }}
                                                    />
                                                )}
                                                <td
                                                    style={{
                                                        padding: "0px 5px",
                                                    }}
                                                >
                                                    <h2
                                                        style={{
                                                            textAlign: "center",
                                                            fontWeight: 400,
                                                        }}
                                                    >{`Presentación: ${getCurrentPage(
                                                        currentPage
                                                    )}`}</h2>
                                                </td>
                                                <td
                                                    style={{
                                                        width: 65,
                                                        textAlign: "center",
                                                        padding: 0,
                                                    }}
                                                >
                                                    <AuthComponent
                                                        component={
                                                            <DownloadReportProject
                                                                projectId={
                                                                    projectId
                                                                }
                                                                surveyId={
                                                                    surveyId
                                                                }
                                                                companyId={
                                                                    companyId
                                                                }
                                                            />
                                                        }
                                                        permission={
                                                            "cgr_results_download_report_project"
                                                        }
                                                        type={"component"}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs>
                                {!isMainPage(currentPage) && (
                                    <RaisedButton
                                        onClick={this._handledGoMain}
                                        label={"Volver al informe general"}
                                        style={{ margin: 10, float: "left" }}
                                        secondary
                                    />
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs>{this.renderContent()}</Col>
                        </Row>
                    </div>
                }
                permission={"cgr_results_project_report"}
                type={"component"}
            />
        );
    }
}

function mapStateToProps({ renderReport, analyticsReport }, ownProps) {
    return {
        inSearch: renderReport.get("inSearch"),
        report: renderReport.get("report"),
        colorsDefault: renderReport.get("colorsDefault"),
        currentPage: renderReport.get("currentPage"),
        filterDefault: renderReport.get("filterDefault"),
        loadFilterDefault: renderReport.get("loadFilterDefault"),
        setFilter: renderReport.get("setFilter"),
        countFilter: analyticsReport.get("filters").size,
        filters: analyticsReport.get("filters").toJS(),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            inSearchValues,
            getValuesReportRender,
            setColorDefault,
            getFilterDefault,
            saveCurrentFilter,
            setFilterDefault,
            updateFilters,
            cleanFilters,
            cleanSelectedFilters,
            resetValuesFilterDefault,
        },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RenderReportProject);
