import React, { Component, cloneElement } from "react";
import LeftNav from "material-ui/Drawer";
import { getRamdonString } from "../../../lib/util";
import { List, ListItem } from "material-ui/List";
import BusinessIcon from "material-ui/svg-icons/communication/business";
import ChatIcon from "material-ui/svg-icons/communication/chat";
import TableIcon from "material-ui/svg-icons/action/view-list";
import IconList from "material-ui/svg-icons/action/list";
import QuestionsIcon from "material-ui/svg-icons/action/question-answer";
import PeopleIcon from "material-ui/svg-icons/social/people";
import PersonPinIcon from "material-ui/svg-icons/maps/person-pin";
import SurveyIcon from "material-ui/svg-icons/action/assessment";
import ConsentIcon from "material-ui/svg-icons/action/description";
import HelpFaqIcon from "material-ui/svg-icons/action/help-outline";
import AdminLocationIcon from "material-ui/svg-icons/social/public";
import AuditIcon from "material-ui/svg-icons/action/find-in-page";
import EmailAdminIcon from "material-ui/svg-icons/communication/email";
import AccessibilityIcon from "material-ui/svg-icons/action/accessibility";
import MyProjectsIcon from "material-ui/svg-icons/av/equalizer";
import ContentPasteIcon from "material-ui/svg-icons/content/content-paste";
import ViewProjectslIcon from "material-ui/svg-icons/action/view-carousel";
import SnackbarMessage from "../../commons/snackBarMessage";
import ModalMessageResult from "../../ModalMessageResult/modalMessageResultComponent";
import SpinnerLoading from "../../SpinnerLoading/componentSpinner";
import { connect } from "react-redux";
import { Link } from "react-router";
import { toggleNav } from "../../../actions/left_navbar";
import { makeSelectable as SelectableContainerEnhance } from "material-ui/List";
import { selectMenuItem } from "../../../actions/left_navbar";
import { bindActionCreators } from "redux";
import { LBLAPP } from "../../../constants/labels";
import FaqMessage from "../../fields/FaqMessage/FaqMessage";
import About from "../../fields/about/About";
import AuthComponent from "../../AuthComponent";
import Divider from "material-ui/Divider";

const Style = {
    menu: {
        paddingBottom: 63,
        zIndex: 9999,
    },
};

const SelectableList = SelectableContainerEnhance(List);

class LeftNavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: 0,
        };

        this.closeMenuLeft = this.closeMenuLeft.bind(this);
        this.handleRequestChange = this.handleRequestChange.bind(this);
        this.getItemMenu = this.getItemMenu.bind(this);
        this.getIconMenu = this.getIconMenu.bind(this);
    }

    handleRequestChange = (event, index) => {
        this.setState({
            selectedIndex: index,
        });
        this.closeMenuLeft();
    };

    closeMenuLeft(e) {
        const { toggleNav } = this.props;
        toggleNav(false);
    }

    getIconMenu(icon) {
        switch (icon) {
            case "survey":
                return <TableIcon />;
            case "question":
                return <QuestionsIcon />;
            case "list":
                return <IconList />;
            case "consent":
                return <ConsentIcon />;
            default:
                return <BusinessIcon />;
        }
    }

    getItemMenu(key, value, text, typeIcon, link, permission) {
        const itemMenu = (
            <AuthComponent
                key={getRamdonString()}
                component={
                    <ListItem
                        key={getRamdonString()}
                        value={value}
                        primaryText={text}
                        leftIcon={this.getIconMenu(typeIcon)}
                        containerElement={<Link to={link} />}
                        onClick={this.closeMenuLeft}
                        style={{ paddingLeft: 30 }}
                    />
                }
                permission={permission}
                type={"component"}
            />
        );
        return itemMenu;
    }

    render() {
        const { menuState } = this.props;
        return (
            <div style={{ zIndex: 9999, paddingTop: 63 }}>
                <LeftNav style={Style.menu} open={menuState} onRequestChange={this.closeMenuLeft} docked={false}>
                    <SelectableList onChange={this.handleRequestChange} style={{ marginTop: 63, paddingTop: 1 }}>
                        <AuthComponent
                            component={
                                <ListItem
                                    key={getRamdonString()}
                                    value={31}
                                    primaryText={LBLAPP.menu.myprojects}
                                    leftIcon={<MyProjectsIcon />}
                                    containerElement={<Link to="/admin/me/projects" />}
                                    onClick={this.closeMenuLeft}
                                />
                            }
                            key={getRamdonString()}
                            permission={"default"}
                            type={"component"}
                            defaultPermission
                        />
                        <AuthComponent
                            component={
                                <ListItem
                                    key={getRamdonString()}
                                    value={32}
                                    primaryText={LBLAPP.menu.assignprojects}
                                    leftIcon={<ViewProjectslIcon />}
                                    containerElement={<Link to="/admin/me/assignprojects" />}
                                    onClick={this.closeMenuLeft}
                                />
                            }
                            key={getRamdonString()}
                            permission={"default"}
                            type={"component"}
                            defaultPermission
                        />
                        <Divider style={{ margin: 0, padding: 0 }} key={getRamdonString()} />
                        <AuthComponent
                            component={
                                <ListItem
                                    key={getRamdonString()}
                                    value={1}
                                    primaryText={LBLAPP.menu.companies}
                                    leftIcon={<BusinessIcon />}
                                    containerElement={<Link to="/admin/companies/grid" />}
                                    onClick={this.closeMenuLeft}
                                />
                            }
                            key={getRamdonString()}
                            permission={"cgr_company_list"}
                            type={"component"}
                        />
                        <AuthComponent
                            component={
                                <ListItem
                                    key={getRamdonString()}
                                    primaryText={LBLAPP.menu.surveys}
                                    leftIcon={<SurveyIcon />}
                                    primaryTogglesNestedList
                                    value={-1}
                                    nestedItems={[
                                        this.getItemMenu(
                                            1,
                                            2,
                                            LBLAPP.menu.tests,
                                            "survey",
                                            "/admin/surveys/grid",
                                            "cgr_surveys_list",
                                        ),
                                        this.getItemMenu(
                                            2,
                                            3,
                                            LBLAPP.menu.questions,
                                            "question",
                                            "/admin/questions/grid",
                                            "cgr_questions_list",
                                        ),
                                        this.getItemMenu(
                                            3,
                                            4,
                                            LBLAPP.menu.lists,
                                            "list",
                                            "/admin/lists/grid",
                                            "cgr_answer_options_list",
                                        ),
                                        this.getItemMenu(
                                            4,
                                            5,
                                            LBLAPP.menu.consents,
                                            "consent",
                                            "/admin/consents/grid",
                                            "cgr_consents_list",
                                        ),
                                    ]}
                                />
                            }
                            key={getRamdonString()}
                            permissions={[
                                "cgr_surveys_list",
                                "cgr_questions_list",
                                "cgr_answer_options_list",
                                "cgr_consents_list",
                            ]}
                            type={"component"}
                        />
                        <AuthComponent
                            component={
                                <ListItem
                                    key={getRamdonString()}
                                    value={6}
                                    primaryText={LBLAPP.menu.evaluators}
                                    leftIcon={<PersonPinIcon />}
                                    containerElement={<Link to="/admin/referees/grid" />}
                                    onClick={this.closeMenuLeft}
                                />
                            }
                            key={getRamdonString()}
                            permission={"cgr_referees_list"}
                            type={"component"}
                        />
                        <AuthComponent
                            component={
                                <ListItem
                                    key={getRamdonString()}
                                    value={7}
                                    primaryText={LBLAPP.menu.faq}
                                    leftIcon={<HelpFaqIcon />}
                                    containerElement={<Link to="/admin/faq/grid" />}
                                    onClick={this.closeMenuLeft}
                                />
                            }
                            key={getRamdonString()}
                            permission={"cgr_faqs_list"}
                            type={"component"}
                        />
                        <AuthComponent
                            component={
                                <ListItem
                                    key={getRamdonString()}
                                    value={8}
                                    primaryText={LBLAPP.menu.adminLocations}
                                    leftIcon={<AdminLocationIcon />}
                                    containerElement={<Link to="/admin/locations/countries" />}
                                    onClick={this.closeMenuLeft}
                                />
                            }
                            key={getRamdonString()}
                            permission={"cgr_location_country_list"}
                            type={"component"}
                        />
                        <AuthComponent
                            component={
                                <ListItem
                                    key={getRamdonString()}
                                    value={9}
                                    primaryText={LBLAPP.menu.adminTemplateEmail}
                                    leftIcon={<EmailAdminIcon />}
                                    containerElement={<Link to="/admin/templateemail/grid" />}
                                    onClick={this.closeMenuLeft}
                                />
                            }
                            key={getRamdonString()}
                            permission={"cgr_template_list"}
                            type={"component"}
                        />
                        <AuthComponent
                            component={
                                <ListItem
                                    key={getRamdonString()}
                                    value={10}
                                    primaryText={LBLAPP.menu.audit}
                                    leftIcon={<AuditIcon />}
                                    containerElement={<Link to="/admin/audit/view" />}
                                    onClick={this.closeMenuLeft}
                                />
                            }
                            key={getRamdonString()}
                            permission={"cgr_audits_list"}
                            type={"component"}
                        />
                        <AuthComponent
                            component={
                                <ListItem
                                    key={getRamdonString()}
                                    value={11}
                                    primaryText={LBLAPP.menu.users}
                                    leftIcon={<PeopleIcon />}
                                    containerElement={<Link to="/admin/users/grid" />}
                                    onClick={this.closeMenuLeft}
                                />
                            }
                            key={getRamdonString()}
                            permission={"cgr_users_list"}
                            type={"component"}
                        />
                        <AuthComponent
                            component={
                                <ListItem
                                    key={getRamdonString()}
                                    value={12}
                                    primaryText={LBLAPP.menu.profiles}
                                    leftIcon={<AccessibilityIcon />}
                                    containerElement={<Link to="/admin/profiles/grid" />}
                                    onClick={this.closeMenuLeft}
                                />
                            }
                            key={getRamdonString()}
                            permission={"cgr_profiles_list"}
                            type={"component"}
                        />
                        <AuthComponent
                            component={
                                <ListItem
                                    key={getRamdonString()}
                                    primaryText={LBLAPP.menu.reports}
                                    leftIcon={<ContentPasteIcon />}
                                    primaryTogglesNestedList
                                    value={13}
                                    nestedItems={[
                                        this.getItemMenu(
                                            1,
                                            14,
                                            LBLAPP.menu.excelSurveyReport,
                                            "survey",
                                            "/admin/surveys/export/applications",
                                            "cgr_reports_solutions",
                                        ),
                                    ]}
                                />
                            }
                            key={getRamdonString()}
                            permissions={["cgr_reports_solutions"]}
                            type={"component"}
                        />
                        <AuthComponent
                            component={
                                <ListItem
                                    key={getRamdonString()}
                                    primaryText={"Chat"}
                                    value={15}
                                    leftIcon={<ChatIcon />}
                                    containerElement={<Link to="/admin/chat" />}
                                    onClick={this.closeMenuLeft}
                                />
                            }
                            key={getRamdonString()}
                            permission={"cgr_chat_use_admin"}
                            type={"component"}
                        />
                    </SelectableList>
                </LeftNav>
                <SpinnerLoading />
                <SnackbarMessage />
                <ModalMessageResult />
                <FaqMessage />
                <About />
            </div>
        );
    }
}

function mapStateToProps({ menu }) {
    return {
        menuState: menu.get("menuState"),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            selectMenuItem,
            toggleNav,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftNavBar);
