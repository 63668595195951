import {axiosAPI} from '../../../middleware/api';

export const GET_VALUES_LIST_MASTER = "GET_TREE_QUESTIONS_ITEMS";
export const CLEAN_STATE_TREE_MAP = "CLEAN_STATE_TREE_MAP";
export const CHANGE_QUANTITY_SELECTABLE = "CHANGE_QUANTITY_SELECTABLE";

/**
 *
 * @param nameList Name list to obtain values
 */
export function getValuesNameList(nameList) {
  
    const request = axiosAPI.get(`/list/${nameList}`);

    return {
        type: GET_VALUES_LIST_MASTER,
        payload: request
    };
}
/**
 *
 * @param nameList Name list to obtain values
 */
export function changeSelectable(quantityOptions) {
    return {
        type: CHANGE_QUANTITY_SELECTABLE,
        payload: quantityOptions
    };
}


export function cleanStateTreeMap(storeName) {
    return {
        type: CLEAN_STATE_TREE_MAP,
        storeName
    }
}
