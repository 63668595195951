import PropTypes from "prop-types";
import React, { Component } from "react";
import _ from "lodash";
import Scroll, { animateScroll } from "react-scroll";

class MenuComponent extends Component {
    state = { codeHover: null };

    static propTypes = {
        menu: PropTypes.array.isRequired,
    };

    handleOnMouseEnter = (codeComponent) => {
        this.setState({ codeHover: codeComponent });
    };

    handleOnMouseLeave = () => {
        this.setState({ codeHover: null });
    };

    _goTitle = (codeComponent) => {
        if (
            !_.isNull(codeComponent) &&
            !_.isUndefined(codeComponent) &&
            !_.isEqual(codeComponent, "")
        ) {
            const element = document.getElementById(codeComponent);
            if (!_.isNull(element) && !_.isUndefined(element)) {
                const position = _.toNumber(element.offsetTop);
                animateScroll.scrollTo(position - 85);
            }
        }
    };

    render() {
        const { menu } = this.props;
        const { codeHover } = this.state;
        const list = !_.isUndefined(menu) && !_.isNull(menu) ? menu : [];
        const styleList = {
            margin: 0,
            padding: "0 0 0 5px",
            listStyleType: "none",
            minWidth: 120,
        };

        return (
            <div>
                <h3
                    className="print_pdf_component remove-help-text-report"
                    style={{
                        fontWeight: 400,
                        color: "#4c4c4c",
                        textAlign: "center",
                        padding: 10,
                        backgroundColor: "#ececec",
                        borderRadius: 8,
                        fontSize: 13,
                        margin: "0 auto 15px auto",
                    }}
                >
                    Puede dar clic en cualquier título para ser dirigido a la
                    sección
                </h3>
                <ul style={styleList}>
                    {_.map(list, (value, index) => {
                        const styleItemLevel = {
                                paddingLeft: getPaddingTitle(
                                    _.get(value, "level", 1)
                                ),
                            },
                            stylePoint = {
                                width: 3,
                                height: 3,
                                borderRadius: "100%",
                                position: "absolute",
                                background: "#1A1A1A",
                                margin: "7px 0 0 -9px",
                                display: "none",
                            };
                        return (
                            <li
                                className="print_pdf_component"
                                key={`row-${index}`}
                                style={styleItemLevel}
                            >
                                <div style={stylePoint} />
                                <h3
                                    style={getStyleTitle(
                                        _.get(value, "level", 1),
                                        isItemSelected(
                                            _.get(value, "code", ""),
                                            codeHover
                                        )
                                    )}
                                    onClick={this._goTitle.bind(
                                        this,
                                        _.get(value, "code")
                                    )}
                                    onMouseEnter={this.handleOnMouseEnter.bind(
                                        this,
                                        _.get(value, "code")
                                    )}
                                    onMouseLeave={this.handleOnMouseLeave}
                                >
                                    {_.get(value, "text", "")}
                                </h3>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}

function isItemSelected(code, codeHover) {
    return (
        !_.isNull(codeHover) &&
        _.isEqual(code, codeHover) &&
        !_.isEqual(code, "")
    );
}

function getStyleTitle(level, isSelected) {
    const margin = "1px 0 0 0";
    const textAlign = "left";
    const cursor = "pointer";
    switch (level) {
        case 1:
            return {
                margin,
                padding: 0,
                textAlign,
                cursor,
                fontSize: 15,
                fontWeight: 500,
                color: "#1A1A1A",
                textDecoration: isSelected ? "underline" : "none",
            };
        case 2:
            return {
                margin,
                padding: 0,
                textAlign,
                cursor,
                fontSize: 14,
                fontWeight: 400,
                color: "#1A1A1A",
                textDecoration: isSelected ? "underline" : "none",
            };
        case 3:
            return {
                margin,
                padding: 0,
                textAlign,
                cursor,
                fontSize: 13,
                fontWeight: 400,
                color: "#5A5A5A",
                textDecoration: isSelected ? "underline" : "none",
            };
        default:
            return {
                margin,
                padding: 0,
                textAlign,
                cursor,
                textDecoration: isSelected ? "underline" : "none",
            };
    }
}

function getPaddingTitle(level) {
    switch (level) {
        case 2:
            return 17;
        case 3:
            return 34;
        default:
            return 0;
    }
}

export default MenuComponent;
