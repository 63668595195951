import PropTypes from "prop-types";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { Row, Col } from "react-flexbox-grid";
import { reduxForm, addArrayValue } from "redux-form";
import { blue800 } from "material-ui/styles/colors";
import {
    mapItemNames,
    buildItemIndex,
    validationBaseFilterItem,
} from "./ProjectFunctions";
import ItemSelector from "./ItemSelector";
import FiltersContainer from "./FiltersContainer";
import { Toolbar, ToolbarGroup, ToolbarTitle } from "material-ui/Toolbar";
import IconButton from "material-ui/IconButton";
import FontIcon from "material-ui/FontIcon";
import RaisedButton from "material-ui/RaisedButton";
import * as _ from "lodash";
import NavigationExpandMoreIcon from "material-ui/svg-icons/navigation/expand-more";
import transitions from "material-ui/styles/transitions";
import Divider from "material-ui/Divider";
import BaseFilterComponent from "./BaseFilterComponent";
import { allowedListFilters } from "./ProjectDucks";

const styles = {
    customWidth: {
        width: 320,
    },
    root: {
        background: "#f8f8f8",
    },
    markdown: {
        overflowY: "hidden",
        overflowX: "auto",
        maxHeight: 1400,
        transition: transitions.create(
            "max-height",
            "800ms",
            "0ms",
            "ease-in-out"
        ),
        marginTop: 0,
        marginBottom: 0,
    },
    markdownRetracted: {
        maxHeight: 0,
    },
    description: {
        background: "#ffffff",
        overflow: "auto",
        padding: "10px 20px 0",
        marginTop: 0,
        marginBottom: 0,
    },
    codeBlockTitle: {
        cursor: "pointer",
    },
    submit: {
        float: "right",
        margin: 10,
    },
};

class FiltersSection extends Component {
    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.object).isRequired,
        submitMessage: PropTypes.func.isRequired,
        allowSelectType: PropTypes.bool.isRequired,
        baseFilter: validationBaseFilterItem,
        allowedFilters: PropTypes.arrayOf(PropTypes.oneOf(allowedListFilters)),
    };

    constructor(props) {
        super(props);
        this.state = { expand: false };
    }

    componentDidMount() {
        const {
            resetForm,
            allowSelectType,
            baseFilter,
            fields: { type },
        } = this.props;
        resetForm();
        if (allowSelectType === false) {
            type.onChange(baseFilter);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            map: buildItemIndex(nextProps.data, "id"),
        });
    }

    render() {
        const {
            handleSubmit,
            fields: { filters, type, element },
            data,
            submitMessage,
            allowSelectType,
            allowedFilters,
            resetForm,
        } = this.props;
        const toolbarTitle = element.value
            ? _.get(this.state.map, `${element.value}.name`)
            : "Ninguno";
        let codeStyle = Object.assign(
            {},
            styles.markdown,
            styles.markdownRetracted
        );
        const shouldRenderBaseFilter = allowSelectType !== false;
        // if the base filters isn't going to be rendered, then the field value has to be updated.
        const baseFilterComponent = shouldRenderBaseFilter ? (
            <BaseFilterComponent field={type} styles={styles.customWidth} />
        ) : (
            <span />
        );
        let tooltip = "Ver configuración";

        if (this.state.expand) {
            codeStyle = styles.markdown;
            tooltip = "Ocultar configuración";
        }

        return (
            <div>
                <Row>
                    <Col xs>
                        <div
                            style={{
                                width: "100%",
                                background: blue800,
                                paddingTop: 5,
                                paddingBottom: 5,
                                color: "#FFF",
                            }}
                        >
                            <h2
                                style={{
                                    fontWeight: 400,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    lineHeight: 1.2,
                                }}
                            >{`Elemento a gráficar: ${toolbarTitle}`}</h2>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <Toolbar>
                            <ToolbarGroup
                                firstChild={true}
                                style={{ width: "100%" }}
                            >
                                <ToolbarTitle
                                    style={{
                                        color: "white",
                                        paddingLeft: 15,
                                        whiteSpace: "nowrap",
                                    }}
                                    text={""}
                                />
                            </ToolbarGroup>
                            <ToolbarGroup style={{ width: 250 }}>
                                <FontIcon className="muidocs-icon-custom-sort" />
                                <RaisedButton
                                    label="Reiniciar"
                                    primary={true}
                                    style={{ width: 150 }}
                                    onClick={() => {
                                        resetForm();
                                        const filtersToRemove = _.range(
                                            0,
                                            filters.length
                                        );
                                        _.each(filtersToRemove, (count) => {
                                            filters.removeField(0);
                                        });
                                    }}
                                />
                                <IconButton
                                    touch={true}
                                    tooltip={tooltip}
                                    style={{ marginTop: 4 }}
                                    onClick={() =>
                                        this.setState({
                                            expand: !this.state.expand,
                                        })
                                    }
                                >
                                    <NavigationExpandMoreIcon color={"#FFF"} />
                                </IconButton>
                            </ToolbarGroup>
                        </Toolbar>
                    </Col>
                </Row>
                <Row style={{ padding: "25px" }}>
                    <Col xs>
                        <form
                            style={codeStyle}
                            onSubmit={handleSubmit(submitMessage)}
                        >
                            <Row>
                                {baseFilterComponent}
                                <Col xs={12}>
                                    <Row>
                                        <Col xs={6}>
                                            <h3>Elemento a graficar</h3>
                                        </Col>
                                        <Col xs={6}>
                                            <ItemSelector
                                                onChange={element.onChange}
                                                value={element.value}
                                                data={mapItemNames(
                                                    data,
                                                    type.value
                                                )}
                                                currentValue={type.value}
                                            />
                                        </Col>
                                    </Row>
                                    <Divider />
                                </Col>
                                <Col xs={12}>
                                    <Row>
                                        <Col xs={6}>
                                            <h3>Seleccion de Filtros</h3>
                                        </Col>
                                        <Col xs={6}>
                                            <FiltersContainer
                                                addField={filters.addField}
                                                data={data}
                                                selectedValue={element.value}
                                                value={filters}
                                                allowedFilters={allowedFilters}
                                            />
                                        </Col>
                                    </Row>
                                    <Divider />
                                </Col>
                                <Col xs={12}>
                                    <Row>
                                        <Col xs>
                                            <RaisedButton
                                                type="submit"
                                                primary={true}
                                                label="Enviar"
                                                style={styles.submit}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </form>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            addArrayValue,
        },
        dispatch
    );
};

export default reduxForm(
    {
        form: "sarin",
        fields: [
            "type",
            "element",
            "filters[].item",
            "filters[].operator",
            "filters[].value",
            "filters[].columnFamily",
            "filters[].condition",
        ],
    },
    undefined,
    mapDispatchToProps
)(FiltersSection);
