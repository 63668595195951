import PropTypes from "prop-types";
import React, { Component } from "react";
import _ from "lodash";
import { select, scaleBand, scaleLinear, max, axisBottom, axisLeft, scaleOrdinal, format } from "d3";
import { stack } from "d3-shape";
import Faux from "react-faux-dom";
import Message from "../chartsComponents/message";
import MiniLegentGroup from "../legends/miniLegentGroup";

function getPercentage(d, total) {
    let percentage = 0;
    if (d[1] - d[0] > 0) {
        percentage = d[1] - d[0];
    }
    const full = percentage * 100 / total;
    const val = full.toFixed(1);
    return val;
}

function getValueSize(size) {
    let s = "middle";
    if ((!_.isUndefined(size) && !_.isNull(size)) || !_.isEqual(size, "")) {
        if (_.indexOf(["small", "middle", "large"], size) !== -1) {
            s = size;
        }
    }
    switch (s) {
        case "small":
            return 230;
        case "middle":
            return 320;
        case "large":
            return 410;
        default:
            return 180;
    }
}

function LightenDarkenColor(col, amt) {
    let usePound = false;
    if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
    }
    let num = parseInt(col, 16),
        r = (num >> 16) + amt;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    let b = ((num >> 8) & 0x00ff) + amt;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
    let g = (num & 0x0000ff) + amt;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

function updateData(data) {
    const keys = _.chain(data)
        .get(0)
        .keys()
        .omit(["total"])
        .value();
    return [
        _.reduce(
            keys,
            (sum, key) => {
                if (_.get(data, `0.${key}`, 0) <= 0) {
                    return sum;
                }
                return _.set(sum, key, _.get(data, `0.${key}`));
            },
            { total: _.get(data, "0.total", 0) }
        )
    ];
}

function updateKeys(data, keysstacked) {
    const keys = _.chain(data)
        .get(0)
        .keys()
        .omit(["total"])
        .map(key => ({ key }))
        .value();
    return _.intersectionBy(keysstacked, keys, "key");
}

function hasData(data) {
    const keys = _.chain(data)
        .get(0)
        .keys()
        .filter(key => _.indexOf(["total", "index", "textIndex"], key) === -1)
        .value();
    const values = _.get(data, "0", {});
    return _.reduce(keys, (sum, key) => sum + _.get(values, key, 0), 0) === 0;
}

class BarStacked3DGroupFilterComponent extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
        keys: PropTypes.array.isRequired,
        hasMiniLegend: PropTypes.bool,
        size: PropTypes.oneOf(["small", "middle", "large"])
    };

    render() {
        const { data, keys, size, hasMiniLegend } = this.props;
        const hml = _.isUndefined(hasMiniLegend) || _.isNull(hasMiniLegend) ? true : hasMiniLegend;
        const faux = Faux.createElement("div");
        const fontSizePercentage = 10;
        const dimensions = getValueSize(size);
        const width = dimensions;
        const height = dimensions;
        const darkerColor = -45;

        const total = _.get(data, "0.total", 100);

        if (hasData(data)) {
            return <Message />;
        }

        const dataUpdate = updateData(data);
        const keysUpdate = updateKeys(dataUpdate, keys);

        const svg = select(faux)
            .append("svg")
            // .style("backgroundColor", "#CCC")
            .attr("height", width * 0.22)
            .attr("width", width + 10)
            .style("page-break-inside", "avoid");

        const widthSvg = width - 30;
        const heightSvg = width * 0.24;

        const g = svg.append("g").attr("transform", "translate(20,10)");

        const stackKey = _.chain(keysUpdate)
            .map(k => _.get(k, "key", null))
            .filter(k => !_.isNull(k))
            .value();

        const layers = stack().keys(stackKey)(dataUpdate);

        const maxValue = _.chain(dataUpdate)
            .map(d => _.get(d, "total", 100))
            .filter(d => !_.isNull(d))
            .reduce((sum, n) => (sum > n ? sum : n), 0)
            .value();

        const x = scaleLinear()
            .rangeRound([0, widthSvg])
            .domain([0, max(layers[layers.length - 1], d => maxValue)])
            .nice();

        const y = scaleBand()
            .rangeRound([heightSvg, 0])
            .padding(0.2)
            .domain(_.map(dataUpdate, d => d.index));

        const color = scaleOrdinal(
            _.chain(keysUpdate)
                .map(k => _.get(k, "color", "#FFF"))
                .filter(k => !_.isNull(k))
                .value()
        );

        const color3D = scaleOrdinal(
            _.chain(keysUpdate)
                .map(k => LightenDarkenColor(_.get(k, "color", "#FFF"), darkerColor))
                .filter(k => !_.isNull(k))
                .value()
        );

        const bars3D = g
            .selectAll(".bar3D")
            .data(layers)
            .enter()
            .append("g")
            .attr("class", "bar3D")
            .style("fill", (d, i) => color3D(i))
            .selectAll("rect")
            .data(d => d)
            .enter()
            .append("rect")
            .attr("y", d => 0)
            .attr("x", d => x(d[0]))
            .attr("height", y.bandwidth() - 12) // 20
            .attr("width", d => x(d[1]) - x(d[0]));

        const bars = g
            .selectAll(".bar")
            .data(layers)
            .enter()
            .append("g")
            .attr("class", "bar")
            .style("fill", (d, i) => color(i))
            .selectAll("rect")
            .data(d => d)
            .enter()
            .append("rect")
            .attr("y", d => y(d.data.index))
            .attr("x", d => x(d[0]))
            .attr("height", y.bandwidth() - 12)
            .attr("width", d => x(d[1]) - x(d[0]));

        /* const text = g
            .selectAll(".text")
            .data(layers)
            .enter()
            .append("g")
            .attr("class", "tect-text")
            .selectAll("text")
            .data(d => d)
            .enter()
            .append("text")
            .attr("class", "text")
            .attr("font-family", "Roboto")
            .attr("y", d => y(d.data.index) + (y.bandwidth() - 12) / 2 + 4)
            .attr("x", d => {
                const val = getPercentage(d, total);
                let sizeText = 17;
                if (val < 10) {
                    sizeText = 14;
                }
                return (x(d[1]) - x(d[0])) / 2 + x(d[0]) - sizeText;
            })
            .text(d => {
                const val = getPercentage(d, total);
                return `${val}%`;
            }); */

        return (
            <div style={{ textAlign: "center", width: width + 10, display: "inline-block" }}>
                {faux.toReact()}
                {hml && <MiniLegentGroup data={data} size={size} keys={keys} />}
            </div>
        );
    }
}

export default BarStacked3DGroupFilterComponent;
