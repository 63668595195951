import PropTypes from "prop-types";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { Row, Col } from "react-flexbox-grid";
import { reduxForm } from "redux-form";
import { toggleSnackbar } from "../../actions/commons";
import Dialog from "material-ui/Dialog";
import { blue700 } from "material-ui/styles/colors";
import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";
import { LBLGROUPERS } from "../../constants/labels";
import {
    closeModal,
    saveNameGrouper,
} from "../../components/ModalFormGroupers/actions";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import _ from "lodash";
import AuthComponent from "../../components/AuthComponent";
import SelectFieldFormat from "../fields/SelectFieldFormat/SelectFieldFormat";
import MenuItem from "material-ui/MenuItem";

class FormGrouperModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
        this._saveGrouper = this._saveGrouper.bind(this);
        this._handleCloseAction = this._handleCloseAction.bind(this);
    }

    _saveGrouper(formData) {
        const {
            id,
            closeModal,
            resetForm,
            saveNameGrouper,
            toggleSnackbar,
        } = this.props;
        saveNameGrouper(
            id,
            _.get(formData, "name"),
            _.get(formData, "visible"),
            _.get(formData, "priority")
        )
            .then(
                (data) => {
                    if (
                        _.get(data, "error") ||
                        _.get(data, "payload.status") !== 200
                    ) {
                        toggleSnackbar(true, LBLGROUPERS.msg.errorSave);
                    } else {
                        toggleSnackbar(
                            true,
                            `La información del agrupador se modificó con éxito`
                        );
                    }
                    closeModal();
                    resetForm();
                },
                (reason) => {
                    toggleSnackbar(true, LBLGROUPERS.msg.errorSave);
                    closeModal();
                    resetForm();
                }
            )
            .catch((data) => {
                toggleSnackbar(true, LBLGROUPERS.msg.errorSave);
                closeModal();
                resetForm();
            });
    }

    _handleCloseAction() {
        const { resetForm, closeModal } = this.props;
        resetForm();
        closeModal();
    }

    render() {
        const {
            fields: { name, visible, priority },
            handleSubmit,
            arrayPriority,
            open,
        } = this.props;
        return (
            <Dialog
                title={"Cambiar nombre del agrupador"}
                titleStyle={{ textAlign: "center", color: blue700 }}
                contentStyle={{
                    maxWidth: 600,
                    minWidth: 300,
                    textAlign: "justify",
                }}
                autoScrollBodyContent={true}
                autoDetectWindowHeight={true}
                modal={false}
                open={open}
                onRequestClose={this._handleCloseAction}
            >
                <form onSubmit={handleSubmit(this._saveGrouper)}>
                    <Row>
                        <Col xsOffset={1} xs={10}>
                            <TextField
                                ref="name"
                                hintText={LBLGROUPERS.name}
                                floatingLabelText={LBLGROUPERS.name}
                                type="text"
                                fullWidth={true}
                                multiLine={true}
                                rows={1}
                                errorText={
                                    name.touched && name.error ? name.error : ""
                                }
                                {...name}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xsOffset={1} xs={10}>
                            <h4 style={{ fontWeight: 200 }}>
                                {LBLGROUPERS.titleVisible}
                            </h4>
                            <RadioButtonGroup
                                name="type"
                                onChange={(e, value) => visible.onChange(value)}
                                defaultSelected={"false"}
                                valueSelected={visible.value}
                                style={{ marginTop: 15 }}
                            >
                                <RadioButton
                                    value="false"
                                    label={LBLGROUPERS.buttons.no}
                                    style={{
                                        marginBottom: 16,
                                        display: "inline-block",
                                        width: 130,
                                    }}
                                />
                                <RadioButton
                                    value="true"
                                    label={LBLGROUPERS.buttons.yes}
                                    style={{
                                        marginBottom: 16,
                                        display: "inline-block",
                                        width: 130,
                                    }}
                                />
                            </RadioButtonGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={10} xsOffset={1}>
                            <h4 style={{ fontWeight: 200 }}>
                                {"Priorización del agrupador"}
                            </h4>
                            <SelectFieldFormat
                                config={{
                                    floatingLabelText: "Priorización",
                                    value: priority.value,
                                    errorText:
                                        priority.touched && priority.error
                                            ? priority.error
                                            : "",
                                    onChange: (e, index, value) =>
                                        priority.onChange(value),
                                }}
                            >
                                {_.map(arrayPriority, (priority, index) => (
                                    <MenuItem
                                        key={`priority-${index}`}
                                        value={priority}
                                        primaryText={priority}
                                    />
                                ))}
                            </SelectFieldFormat>
                        </Col>
                    </Row>
                    <Row>
                        <Col xsOffset={6} xs={6}>
                            <div style={{ marginTop: 20 }}>
                                <FlatButton
                                    label={LBLGROUPERS.buttons.cancel}
                                    secondary={true}
                                    onClick={this._handleCloseAction}
                                />
                                <AuthComponent
                                    component={
                                        <FlatButton
                                            style={{ marginLeft: 10 }}
                                            label={LBLGROUPERS.buttons.save}
                                            primary={true}
                                            type="submit"
                                        />
                                    }
                                    permission={"cgr_surveys_groupers_edit"}
                                    type={"component"}
                                />
                            </div>
                        </Col>
                    </Row>
                </form>
            </Dialog>
        );
    }
}

const validate = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = LBLGROUPERS.validations.name;
    }
    return errors;
};

function mapStateToProps({ formGrouper }) {
    let arrayPriority = [];
    for (let i = 1; i <= 100; i++) {
        arrayPriority.push(i);
    }
    return {
        id: formGrouper.get("id"),
        open: formGrouper.get("openModalGrouper"),
        arrayPriority: arrayPriority,
        initialValues: {
            name: formGrouper.get("name"),
            visible: formGrouper.get("visible"),
            priority: formGrouper.get("priority"),
        },
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleSnackbar,
            closeModal,
            saveNameGrouper,
        },
        dispatch
    );
}

export default reduxForm(
    {
        form: "GrouperEditForm",
        fields: ["name", "visible", "priority"],
        validate,
    },
    mapStateToProps,
    mapDispatchToProps
)(FormGrouperModal);
