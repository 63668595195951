import PropTypes from "prop-types";
import React, { Component } from "react";
import TableCell from "@material-ui/core/TableCell";
import _ from "lodash";
import IconButton from "material-ui/IconButton";
import { connect } from "react-redux";
import VisibilityIcon from "material-ui/svg-icons/action/visibility";
import {
    toggleDialog,
    selectUser,
} from "../../../actions/users/userDialogActions";

class UserActionColumn extends Component {
    constructor(props) {
        super(props);
        this.handleViewDetailUser = this.handleViewDetailUser.bind(this);
    }

    handleViewDetailUser(data) {
        const { toggleDialog, selectUser } = this.props;
        selectUser(data);
        toggleDialog(true);
    }

    render() {
        const { data, colSpan } = this.props;
        const cs = _.isUndefined(colSpan) ? 1 : colSpan;
        return (
            <TableCell colSpan={cs}>
                <div
                    style={{ position: "absolute", marginTop: -24, zIndex: 99 }}
                >
                    <IconButton
                        tooltip="Detalle"
                        tooltipPosition="top-center"
                        onClick={() => this.handleViewDetailUser(data)}
                    >
                        <VisibilityIcon />
                    </IconButton>
                </div>
            </TableCell>
        );
    }
}

UserActionColumn.propTypes = {
    data: PropTypes.object.isRequired,
    colSpan: PropTypes.number,
    extra: PropTypes.object,
};

export default connect(null, { toggleDialog, selectUser })(UserActionColumn);
