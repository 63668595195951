import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    submitRequest,
    SUBMIT_RESET_IN_PROGRESS,
    SUBMIT_RESET_FULFILLED,
    SUBMIT_RESET_REJECTED,
    cleanStatus,
} from "./ducks";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import IconButton from "material-ui/IconButton";
import Restore from "material-ui/svg-icons/action/restore";
import { toggleSnackbar } from "../../actions/commons";

function textToPresent(name, id) {
    return `¿Está seguro que desea reiniciar la prueba para el colaborador: ${name}, identificado con: ${id}?`;
}

class ResetSurvey extends Component {
    state = {
        open: false,
    };

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    static propTypes = {
        disabled: PropTypes.bool,
        project: PropTypes.string.isRequired,
        employee: PropTypes.string.isRequired,
        personName: PropTypes.string.isRequired,
        personId: PropTypes.string.isRequired,
    };

    UNSAFE_componentWillReceiveProps({ status }) {
        const { toggleSnackbar, cleanStatus } = this.props;
        if (status === SUBMIT_RESET_IN_PROGRESS) {
            toggleSnackbar(true, "Enviando solicitud...");
        } else if (status === SUBMIT_RESET_FULFILLED) {
            toggleSnackbar(true, "Solicitud de reinicio enviada con éxito.");
        } else if (status === SUBMIT_RESET_REJECTED) {
            toggleSnackbar(true, "Error al enviar la solitud de reinicio.");
            cleanStatus();
        }
    }

    render() {
        const {
            disabled,
            project,
            employee,
            submitRequest,
            personName,
            personId,
        } = this.props;

        const actions = [
            <FlatButton
                label="Cancelar"
                primary={true}
                onClick={this.handleClose}
            />,
            <FlatButton
                label="Confirmar"
                primary={true}
                onClick={() => {
                    submitRequest(project, employee);
                    this.handleClose();
                }}
            />,
        ];

        return (
            <span>
                <IconButton
                    tooltip="Reiniciar aplicación"
                    tooltipPosition="top-center"
                    onClick={this.handleOpen}
                    disabled={disabled}
                >
                    <Restore />
                </IconButton>
                <Dialog
                    actions={actions}
                    modal={false}
                    open={this.state.open}
                    onRequestClose={this.handleClose}
                >
                    {textToPresent(personName, personId)}
                </Dialog>
            </span>
        );
    }
}

function mapStateToProps({ resetEmployee }) {
    return {
        status: resetEmployee.get("status"),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            submitRequest,
            toggleSnackbar,
            cleanStatus,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetSurvey);
