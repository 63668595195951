import {Map} from 'immutable';
import {axiosAPI_V2} from '../../middleware/api';
import * as Rx from 'rxjs';
import findIndex from 'lodash/findIndex';
import set from 'lodash/set';

// actions
export const LOAD_SURVEY_METADATA = "nss/metadata-editor/LOAD_SURVEY_METADATA";
export const LOAD_SURVEY_METADATA_FULFILLED = "nss/metadata-editor/LOAD_SURVEY_METADATA_FULFILLED";
export const CLEAN_STATE = "nss/metadata-editor/CLEAN_STATE";

export const SAVE_COI = "nss/metadata-editor/SAVE_COI";
const SAVING_COI = "nss/metadata-editor/SAVING_COI";
export const SAVE_COI_FULFILLED = "nss/metadata-editor/SAVE_COI_FULFILLED";
const SAVE_COI_REJECTED = "nss/metadata-editor/SAVE_COI_REJECTED";

const status = {
    clean: "clean",
    saving: "saving",
    saved: "saved",
    error: "error"
};

//reducer
const initialState = Map({
    metadata: [],
    status: "clean"
});

export default function (state = initialState, action = {}) {
    switch (action.type) {
    case LOAD_SURVEY_METADATA_FULFILLED:
        return state.set("metadata", action.payload);
    case SAVING_COI:
        return state.withMutations(map => {
            /*const currentMeta = state.get("metadata");
            const grouperIndex = findIndex(currentMeta, item => item._id === action.payload.data.grouper);
            const grouperItem = currentMeta[grouperIndex];
            const conditionIndex = findIndex(grouperItem.conditions, condition => condition._id === action.payload.data.condition);
            const path = `${grouperIndex}.conditions.${conditionIndex}.coi`;
            const newMeta = set(currentMeta, path, action.payload.data.coi);
            map.set("metadata", newMeta).set("status", status.saving);*/
            const currentMeta = state.get("metadata");
            const grouperIndex = findIndex(currentMeta, item => _.get(item, '_id') === _.get(action, 'payload.data.grouper'));
            const grouperItem = currentMeta[grouperIndex];
            const conditionIndex = findIndex(grouperItem.conditions, condition => condition._id === action.payload.data.condition);
            const countCoi = _.chain(action)
                              .get('payload.data.coi', [])
                              .filter(c => _.get(c, 'coiDefault', false))
                              .size()
                              .value();
            const conditionsUpdate = _.map(_.get(grouperItem, 'conditions', []), (cond, index) => {
                if(_.isEqual(index, conditionIndex)){
                    return _.set(cond, 'coi', _.get(action, 'payload.data.coi', []));
                }
                if(_.isEqual(_.get(action, 'payload.data.type',"SE"), "EE") && countCoi > 0){
                    return _.set(cond, 'coi', _.map(_.get(cond, 'coi', []), c => _.set(c, 'coiDefault', false)));
                }
                return cond;
            });
            const path = `${grouperIndex}.conditions`;
            const newMeta = set(currentMeta, path, conditionsUpdate);
            map.set("metadata", newMeta).set("status", status.saving);
        });
    case SAVE_COI_FULFILLED:
        return state.set("status", status.saved);
    case SAVE_COI_REJECTED:
        return state.set('status', status.error);
    case CLEAN_STATE:
        return initialState;
    default:
        return state;
    }
}

// action creators
export const loadSurveyMetadata = surveyId => {
    return {
        type: LOAD_SURVEY_METADATA,
        payload: {
            surveyId
        }
    };
};

export const loadSurveyMetadataFulfilled = data => {
    return {
        type: LOAD_SURVEY_METADATA_FULFILLED,
        payload: data
    };
};

const savingCoiInProgress = data => ({type: SAVING_COI, payload: {data}});
const savingCoiRejected = error => ({type: SAVE_COI_REJECTED, error});
export const cleanState = () => ({type: CLEAN_STATE});

export const saveCoi = (coi, survey, grouper, condition, type) => {
    return {
        type: SAVE_COI,
        payload: {
            coi,
            survey,
            grouper,
            condition,
            type
        }
    }
};

export const saveCoiFulfilled = data => {
    return {
        type: SAVE_COI_FULFILLED,
        payload: {
            data
        }
    }
};

// Epics
export const loadSurveyEpic$ = action$ => {
    return action$
        .ofType(LOAD_SURVEY_METADATA)
        .mergeMap(action => {
            const request = axiosAPI_V2.get(`/surveys/${action.payload.surveyId}/groupers`);
            return Rx.Observable.fromPromise(request)
                .map(response => response.data)
        })
        .map(loadSurveyMetadataFulfilled)
};


export const saveCoiEpic$ = action$ => {
    return action$
        .ofType(SAVE_COI)
        .mergeMap(action => {
            const {payload: {coi, survey, grouper, condition, type}} = action;
            const coiObj = {
                coi,
                survey,
                grouper,
                condition,
                type
            };
            const request = axiosAPI_V2.post(`/surveys/${survey}/metadata/coi`, coiObj);

            const promiseObs$ = Rx.Observable.fromPromise(request)
                .map(response => response.data)
                .mapTo(saveCoiFulfilled(coiObj))
                .catch(err => {
                        return Rx.Observable.of(savingCoiRejected(err.response.data, err.response.status));
                    });

            return Rx.Observable.of(savingCoiInProgress(coiObj)).concat(promiseObs$);
        })
};
