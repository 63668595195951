import PropTypes from "prop-types";
import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import { ListItem } from "material-ui/List";
import _ from "lodash";

const styles = {
    listItemStyle: {
        borderBottom: "1px solid #CCC",
    },
    contentListItemStyle: {
        width: "100%",
        padding: "2px 5px 2px 20px",
    },
    primaryTextListItemStyle: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "100%",
    },
    secondaryTextListItemStyle: {
        fontSize: 14,
        height: 16,
        margin: "4px 0px 0px",
        color: "#858585",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "100%",
    },
};

class ContentListItem extends Component {
    constructor(props) {
        super(props);
        //this._handleActionListItem = this._handleActionListItem.bind(this);
    }

    /*_handleActionListItem() {
        const { onClickItem } = this.props;
        if(!_.isUndefined()){
            onClickItem.apply(this);
        }
    }*/

    render() {
        const {
            primaryText,
            secondaryText,
            secondaryTextElement,
            buttons,
            disabled,
            onClickItem,
        } = this.props;
        const disabledItem =
            !_.isUndefined(disabled) && !_.isNull(disabled) ? disabled : false;
        return (
            <ListItem
                style={styles.listItemStyle}
                disabled={disabledItem}
                onClick={onClickItem}
            >
                <Row style={styles.contentListItemStyle}>
                    <Col xs={8} style={{ padding: 0 }}>
                        <Row>
                            <Col xs style={{ padding: 0 }}>
                                <div style={styles.primaryTextListItemStyle}>
                                    {primaryText}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs style={{ padding: 0 }}>
                                <div style={styles.secondaryTextListItemStyle}>
                                    {secondaryText}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={4} style={{ padding: 0 }}>
                        <div style={{ marginTop: -11 }}>{buttons}</div>
                        <span style={{ float: "right" }}>
                            {secondaryTextElement}
                        </span>
                    </Col>
                </Row>
            </ListItem>
        );
    }
}

ContentListItem.propTypes = {
    primaryText: PropTypes.string.isRequired,
    secondaryText: PropTypes.string,
    buttons: PropTypes.element,
    secondaryTextElement: PropTypes.element,
    disabled: PropTypes.bool,
    onClickItem: PropTypes.func,
};

export default ContentListItem;
