import React, { Component } from "react";
import _ from "lodash";
import StarIcon from "material-ui/svg-icons/toggle/star";
import { blue700 } from "material-ui/styles/colors";

const styles = {
    table: {
        width: "100%",
        borderCollapse: "collapse",
        padding: 0,
        textAlign: "left",
        border: "1px solid #CCC",
        boxSizing: "border-box",
    },
    tableHead: {
        backgroundColor: "#D4D0D0",
        border: "1px solid #757575",
        paddingTop: 5,
    },
    tableBody: {
        padding: "14px 10px",
        border: "1px solid #757575",
    },
    widthFirstColumn: {
        width: 8,
    },
};

class RankingConfigComponent extends Component {
    renderBody = () => {
        const array = [1, 2, 3, 4, 5, 6];
        return _.map(array, (element, index) => {
            const key = `ranking-config-render-${index}`;
            if (index % 2 == 0) {
                return (
                    <tr key={key}>
                        <td
                            style={_.assign(
                                { ...styles.tableBody },
                                { backgroundColor: "#FAFAFA", ...styles.widthFirstColumn },
                            )}
                        />
                        <td style={_.assign({ ...styles.tableBody }, { backgroundColor: "#FAFAFA" })} />
                        <td style={_.assign({ ...styles.tableBody }, { backgroundColor: "#FAFAFA" })} />
                        <td style={_.assign({ ...styles.tableBody }, { backgroundColor: "#FAFAFA" })} />
                    </tr>
                );
            }
            return (
                <tr key={key}>
                    <td style={_.assign({ ...styles.tableBody }, { ...styles.widthFirstColumn })} />
                    <td style={{ ...styles.tableBody }} />
                    <td style={{ ...styles.tableBody }} />
                    <td style={{ ...styles.tableBody }} />
                </tr>
            );
        });
    };

    render() {
        return (
            <table style={{ ...styles.table }}>
                <thead>
                    <tr>
                        <td style={_.assign({ ...styles.tableHead }, { ...styles.widthFirstColumn })}>
                            <StarIcon color={blue700} />
                        </td>
                        <td style={{ ...styles.tableHead }} />
                        <td style={{ ...styles.tableHead }} />
                        <td style={{ ...styles.tableHead }} />
                    </tr>
                </thead>
                <tbody>{this.renderBody()}</tbody>
            </table>
        );
    }
}

export default RankingConfigComponent;
