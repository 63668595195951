import { Map } from "immutable";
import _ from "lodash";
import { axiosAPI_V2 } from "../../../middleware/api";

export const GET_RECORDS_ANSWERS_CONDITIONS_TAGS = "GET_RECORDS_ANSWERS_CONDITIONS_TAGS";
export const GET_RECORDS_ANSWERS_CONDITIONS_TAGS_IN_SEARCH = "GET_RECORDS_ANSWERS_CONDITIONS_TAGS_IN_SEARCH";
export const GET_VALUES_FILTER_RECORDS_ANSWERS_CONDITIONS_TAGS = "GET_VALUES_FILTER_RECORDS_ANSWERS_CONDITIONS_TAGS";
export const OPEN_VALUES_RECORDS_ANSWERS_CONDITIONS_TAGS = "OPEN_VALUES_RECORDS_ANSWERS_CONDITIONS_TAGS";
export const CLOSE_VALUES_RECORDS_ANSWERS_CONDITIONS_TAGS = "CLOSE_VALUES_RECORDS_ANSWERS_CONDITIONS_TAGS";
export const SELECTED_VALUES_RECORDS_ANSWERS_CONDITIONS_TAGS = "SELECTED_VALUES_RECORDS_ANSWERS_CONDITIONS_TAGS";
export const DELETE_SELECTED_VALUE_RECORDS_ANSWERS_CONDITIONS_TAGS = "DELETE_SELECTED_VALUE_RECORDS_ANSWERS_CONDITIONS_TAGS";
export const SAVE_STATE_COMBINATIONS_RECORDS_ANSWERS_CONDITIONS_TAGS = "SAVE_STATE_COMBINATIONS_RECORDS_ANSWERS_CONDITIONS_TAGS";
export const SAVE_STATE_NAME_COMBINATIONS_RECORDS_ANSWERS_CONDITIONS_TAGS = "SAVE_STATE_NAME_COMBINATIONS_RECORDS_ANSWERS_CONDITIONS_TAGS";
export const CHANGE_STEPINDEX_FILTER_RECORDS_ANSWERS_CONDITIONS_TAGS = "CHANGE_STEPINDEX_FILTER_RECORDS_ANSWERS_CONDITIONS_TAGS";
export const RESET_VALUES_FILTER_RECORDS_ANSWERS_CONDITIONS_TAGS = "RESET_VALUES_FILTER_RECORDS_ANSWERS_CONDITIONS_TAGS";
export const SAVE_CURRENTE_FILTERS = "nss/filter-report/SAVE_CURRENTE_FILTERS";

const initialState = Map({
    values: [],
    open: false,
    inSearch: false,
    numSteps: 1,
    finished: false,
    stepIndex: 0,
    selectedValues: {},
    combinations: [],
    nameCombinations: [],
    loadValuesDefault: false,
    currentFilters: null
});

export default (state = initialState, action) => {
    switch (_.get(action, "type")) {
        case GET_RECORDS_ANSWERS_CONDITIONS_TAGS:
            return state.withMutations(function(s) {
                s.set("values", _.get(action, "payload.data", []));
                s.set("inSearch", false);
            });
        case SAVE_CURRENTE_FILTERS:
            return state.set("currentFilters", _.get(action, "meta.filters", null));
        case GET_RECORDS_ANSWERS_CONDITIONS_TAGS_IN_SEARCH:
            return state.set("inSearch", true);
        case OPEN_VALUES_RECORDS_ANSWERS_CONDITIONS_TAGS:
            return state.set("open", true);
        case CLOSE_VALUES_RECORDS_ANSWERS_CONDITIONS_TAGS:
            return state.set("open", false);
        case SELECTED_VALUES_RECORDS_ANSWERS_CONDITIONS_TAGS:
            var mapRecordsAdd = state.get("selectedValues");
            var listRecordsIdAdd = _.keys(mapRecordsAdd);
            var listRecordsAdd = _.flatMap(listRecordsIdAdd, idGroup => _.get(mapRecordsAdd, idGroup, []));
            if (!_.isUndefined(_.get(action, "meta.record")) && !_.isNull(_.get(action, "meta.record"))) {
                listRecordsAdd.push(_.get(action, "meta.record"));
            }
            return state.set(
                "selectedValues",
                _.chain(listRecordsAdd)
                    .uniqBy("id")
                    .groupBy("type")
                    .value()
            );
        case DELETE_SELECTED_VALUE_RECORDS_ANSWERS_CONDITIONS_TAGS:
            var mapRecordsRemove = state.get("selectedValues");
            var listRecordsIdRemove = _.keys(mapRecordsRemove);
            var listRecordsRemove = _.flatMap(listRecordsIdRemove, idGroup => _.get(mapRecordsRemove, idGroup, []));
            return state.set(
                "selectedValues",
                _.chain(listRecordsRemove)
                    .filter(record => !_.isEqual(_.get(record, "id"), _.get(action, "meta.recordId")))
                    .uniqBy("id")
                    .groupBy("type")
                    .value()
            );
        case SAVE_STATE_COMBINATIONS_RECORDS_ANSWERS_CONDITIONS_TAGS:
            var nameCombinationsSave = state.get("nameCombinations");
            var combinationsSave = _.get(action, "meta.combinations", []);
            if (_.size(nameCombinationsSave) > 0) {
                combinationsSave = _.map(combinationsSave, combination => {
                    var nameCombine = "";
                    _.forEach(nameCombinationsSave, nameCombination => {
                        if (_.isEqual(nameCombine, "")) {
                            var arrayNameCombination = _.get(nameCombination, "combine", []);
                            var arrayCombination = _.get(combination, "combine", []);
                            if (_.size(arrayNameCombination) === _.size(arrayCombination)) {
                                var isEqualCombination = true;
                                _.forEach(arrayCombination, comb => {
                                    if (isEqualCombination) {
                                        isEqualCombination = _.findIndex(arrayNameCombination, { id: _.get(comb, "id") }) !== -1;
                                    }
                                });
                                if (isEqualCombination && !_.isEqual(_.get(nameCombination, "name", ""), "")) {
                                    nameCombine = _.get(nameCombination, "name", "");
                                }
                            }
                        }
                    });
                    return _.set(combination, "name", nameCombine);
                });
            }
            return state.withMutations(function(s) {
                s.set("nameCombinations", []);
                s.set("combinations", combinationsSave);
            });
        case SAVE_STATE_NAME_COMBINATIONS_RECORDS_ANSWERS_CONDITIONS_TAGS:
            return state.set("nameCombinations", _.get(action, "meta.nameCombinations", []));
        case GET_VALUES_FILTER_RECORDS_ANSWERS_CONDITIONS_TAGS:
            var loadValuesDefaultSelect = state.get("loadValuesDefault");
            if (loadValuesDefaultSelect) {
                return state;
            }

            return state.withMutations(function(s) {
                s.set("loadValuesDefault", true);
                s.set(
                    "selectedValues",
                    _.chain(action)
                        .get("payload.data", [])
                        .flatMap(combine => _.get(combine, "combine", []))
                        .uniqBy("id")
                        .groupBy("type")
                        .value()
                );
            });
        case RESET_VALUES_FILTER_RECORDS_ANSWERS_CONDITIONS_TAGS:
            return state.withMutations(function(s) {
                s.set("stepIndex", 0);
                s.set("finished", false);
                s.set("values", []);
                s.set("open", false);
                s.set("inSearch", false);
                s.set("numSteps", 1);
                s.set("selectedValues", {});
                s.set("combinations", []);
                s.set("nameCombinations", []);
                s.set("loadValuesDefault", false);
            });
        case CHANGE_STEPINDEX_FILTER_RECORDS_ANSWERS_CONDITIONS_TAGS:
            var stepIndexChange = _.get(action, "meta.stepIndex", 0);
            var numStepsChange = state.get("numSteps");
            return state.withMutations(function(s) {
                s.set("stepIndex", stepIndexChange);
                s.set("finished", stepIndexChange >= numStepsChange);
            });
        default:
            return state;
    }
};

export function getRecordsAnswersConditionsTags(idProject, textFilter) {
    const request = axiosAPI_V2.get(`/reports/filter/${idProject}`, {
        params: {
            search: textFilter
        }
    });

    return {
        type: GET_RECORDS_ANSWERS_CONDITIONS_TAGS,
        payload: request
    };
}

export function getValuesFilterRecordsAnswersConditionsTags(idProject) {
    const request = axiosAPI_V2.get(`/projects/filter/${idProject}`);

    return {
        type: GET_VALUES_FILTER_RECORDS_ANSWERS_CONDITIONS_TAGS,
        payload: request
    };
}

export function inSearchValues() {
    return {
        type: GET_RECORDS_ANSWERS_CONDITIONS_TAGS_IN_SEARCH
    };
}

export function openValues() {
    return {
        type: OPEN_VALUES_RECORDS_ANSWERS_CONDITIONS_TAGS
    };
}

export function closeValues() {
    return {
        type: CLOSE_VALUES_RECORDS_ANSWERS_CONDITIONS_TAGS
    };
}

export function addSelectedValue(record) {
    return {
        type: SELECTED_VALUES_RECORDS_ANSWERS_CONDITIONS_TAGS,
        meta: {
            record
        }
    };
}

export function removeSelectedValue(recordId) {
    return {
        type: DELETE_SELECTED_VALUE_RECORDS_ANSWERS_CONDITIONS_TAGS,
        meta: {
            recordId
        }
    };
}

export function saveStateCombinations(combinations) {
    return {
        type: SAVE_STATE_COMBINATIONS_RECORDS_ANSWERS_CONDITIONS_TAGS,
        meta: {
            combinations
        }
    };
}

export function saveStateNameCombinations(nameCombinations) {
    return {
        type: SAVE_STATE_NAME_COMBINATIONS_RECORDS_ANSWERS_CONDITIONS_TAGS,
        meta: {
            nameCombinations
        }
    };
}

export function resetValuesFilter() {
    return {
        type: RESET_VALUES_FILTER_RECORDS_ANSWERS_CONDITIONS_TAGS
    };
}

export function changeStepIndex(stepIndex) {
    const index = stepIndex > 0 ? stepIndex : 0;
    return {
        type: CHANGE_STEPINDEX_FILTER_RECORDS_ANSWERS_CONDITIONS_TAGS,
        meta: {
            stepIndex: index
        }
    };
}

export function saveCurrentFilter(filters) {
    return {
        type: SAVE_CURRENTE_FILTERS,
        meta: {
            filters
        }
    };
}
