import { GET_VALUES_TAGS_COMPANY, GET_VALUES_TAGS_EMPLOYEE, CHANGE_VALUE_TAG, CHANGE_TAG, CLEAN_VALUES_TAG_EMPLOYEE } from './constants';
import Immutable from 'immutable';
import _ from 'lodash';

const initialState = Immutable.Map({
    tags: Immutable.List(),
    searchTags: Immutable.List(),
    valuesTags: [],
    textValueTag: null,
    textTag: null,
    disabled: false
});

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_VALUES_TAGS_COMPANY:
            return state.withMutations(function(state) {
                        state.set("tags", _.get(action, 'payload.data.tags', []));
                        state.set("searchTags", _.get(action, 'payload.data.searchTags', []));
                   });
        case GET_VALUES_TAGS_EMPLOYEE:
            return state.withMutations(function(state) {
                        state.set("valuesTags", _.get(action, 'payload.data.valuesTags', []));
                        state.set("disabled", false);
                   });
        case CHANGE_VALUE_TAG:
            return state.set("textValueTag", _.get(action, 'meta.textValueTag', null));
        case CHANGE_TAG:
            return state.withMutations(function(state) {
                        state.set("textTag", _.get(action, 'meta.textTag', null));
                        state.set("disabled", true);
                        state.set("valuesTags", []);
                        state.set("textValueTag", null);
                   });
        case CLEAN_VALUES_TAG_EMPLOYEE:
            return state.withMutations(function(state) {
                        state.set("textValueTag", null);
                        state.set("textTag", null);
                        state.set("valuesTags", []);
                   });
        default:
            return state;
    }
}
