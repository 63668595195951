import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { declineSurvey } from "../../actions/consents_form";
import { blue700 } from "material-ui/styles/colors";
import { browserHistory } from "react-router";
import Dialog from "../../../node_modules/material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { LBLSURVEYS, idEmployeeTest } from "../../constants/labels";
import { toggleSnackbar } from "../../actions/commons";

const customContentStyle = {
    maxWidth: 600,
    minWidth: 300,
    textAlign: "justify",
};

var isAdmin = false;

class ButtonDeclineSurvey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            userId: "",
        };
        this.handleDeclineSurvey = this.handleDeclineSurvey.bind(this);
        this._handleCloseAction = this._handleCloseAction.bind(this);
        this._handleOpenAction = this._handleOpenAction.bind(this);
    }

    UNSAFE_componentWillMount() {
        const { login } = this.props;
        const userProfile = _.get(login.get("profile"), "role");
        isAdmin = !_.isEqual(userProfile, "employee");

        if (login.get("loggedIn")) {
            var profile = login.get("profile");
            this.setState({
                userId: _.get(profile, "person._id"),
            });
        } else {
            this.setState({
                userId: idEmployeeTest,
            });
            console.error("No hay un usuario logeado");
            console.error(
                "Se guardará con el empleado de prueba -> ",
                idEmployeeTest
            );
        }
    }

    _handleCloseAction() {
        this.setState({
            open: false,
        });
    }

    handleDeclineSurvey() {
        this.setState({
            open: false,
        });
        const {
            declineSurvey,
            consentsArray,
            idProject,
            companyId,
        } = this.props;
        declineSurvey(idProject, consentsArray, this.state.userId, companyId)
            .then(
                (data) => {
                    if (
                        _.get(data, "error") ||
                        _.get(data, "payload.status") !== 200
                    ) {
                        this.props.toggleSnackbar(true, "Hubo un error");
                    } else {
                        if (isAdmin) {
                            browserHistory.push("/admin/me/projects");
                        } else {
                            browserHistory.push("/dashboard/employee");
                        }
                    }
                },
                (reason) => {
                    console.log("reason", reason);
                    this.props.toggleSnackbar(
                        true,
                        "Hubo un error al guardar los consentimientos"
                    );
                }
            )
            .catch((data) => {
                console.log("error data", data);
                this.props.toggleSnackbar(
                    true,
                    "Hubo un error al guardar los consentimientos"
                );
            });
    }
    _handleOpenAction() {
        this.setState({
            open: true,
        });
    }
    render() {
        const actions = [
            <FlatButton
                label={LBLSURVEYS.buttons.cancel}
                secondary={true}
                onClick={this._handleCloseAction}
            />,
            <FlatButton
                label={LBLSURVEYS.buttons.acept}
                primary={true}
                keyboardFocused={true}
                onClick={this.handleDeclineSurvey}
            />,
        ];
        return (
            <span>
                <FlatButton
                    label={LBLSURVEYS.msg.declineSurveyConfirm}
                    secondary={true}
                    disabled={this.props.disabled}
                    onClick={this._handleOpenAction}
                />
                <Dialog
                    title={LBLSURVEYS.confirm}
                    actions={actions}
                    modal={false}
                    titleStyle={{ textAlign: "center", color: blue700 }}
                    contentStyle={customContentStyle}
                    autoScrollBodyContent={true}
                    open={this.state.open}
                    onRequestClose={this._handleCloseAction}
                >
                    <h3 style={{ fontWeight: 200, textAlign: "justify" }}>
                        {LBLSURVEYS.msg.confirmDeclineSurvey}
                    </h3>
                    <h3 style={{ fontWeight: 200, textAlign: "justify" }}>
                        {LBLSURVEYS.msg.rememberDecline}
                    </h3>
                </Dialog>
            </span>
        );
    }
}

ButtonDeclineSurvey.propTypes = {
    idProject: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    consentsArray: PropTypes.array.isRequired,
    companyId: PropTypes.string.isRequired,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            declineSurvey,
            toggleSnackbar,
        },
        dispatch
    );
}

function mapStateToProps({ login }, ownerProps) {
    return {
        login,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ButtonDeclineSurvey);
