import React, {Component} from 'react';
import MessageField from './messagesField';
import MessagesDashboard from './messagesDashboard';
import Card from 'material-ui/Card/Card';
import {Col, Row} from 'react-flexbox-grid';

class messagesCard extends Component {
    render() {
        return (
            <Row style={{marginTop: 100}}>
                <Col />
            </Row>
        );
    }
}

export default messagesCard;
