import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {green500} from 'material-ui/styles/colors';
import _ from 'lodash';

const ContentTitleStyle = {
          textAlign: 'justify',
          paddingLeft: 5,
          wordWrap: 'break-word'
      }, TitleStyle = {
          fontWeight: 400,
          padding: 0
      };

class TitleGroup extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {
            title,
            answerText
        } = this.props;
        const text = _.isUndefined(answerText) || _.isNull(answerText)
                        ? ""
                        : _.isEqual(answerText, "")
                            ? ""
                            : "R\\= " + answerText;
        return (
            <div style={ContentTitleStyle}>
                <h3 style={TitleStyle}>
                    {title}
                    <span style={{paddingLeft: 20, paddingBottom: 5, fontSize: 15, color: green500}}>{text}</span>
                </h3>
            </div>
        );
    }
}

TitleGroup.propTypes = {
    title: PropTypes.string.isRequired,
    answerText: PropTypes.string
};

export default TitleGroup;
