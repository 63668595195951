import PropTypes from "prop-types";
import React, { Component } from "react";
import { blue600 } from "material-ui/styles/colors";
import { SketchPicker } from "react-color";
import { Row, Col } from "react-flexbox-grid";
import _ from "lodash";

const styles = {
    cover: {
        position: "absolute",
        top: 20,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: -2,
        border: "0px solid #000",
    },
    coverButton: {
        top: 0,
        left: 29,
        zIndex: 2,
        height: 20,
        width: 38,
        padding: "2px 5px",
        borderRadius: "3px 3px 0 0",
        color: "#FFF",
        cursor: "pointer",
        backgroundColor: blue600,
        margin: "0px 0px -24px -48px",
    },
    styleButtonPicker: {
        border: 1,
        borderStyle: "solid",
        borderColor: "#999",
        width: 29,
        height: 29,
        cursor: "pointer",
        borderRadius: "100%",
        marginTop: -10,
        marginBottom: 10,
        marginLeft: 0,
        marginRight: 0,
    },
};

export default class PureInputColorPicker extends Component {
    static propTypes = {
        field: PropTypes.object.isRequired,
        onChangeFunc: PropTypes.func,
    };

    state = {
        displayColorPicker: false,
        colorDefault: "#1976d2",
    };

    handleChangeComplete = (color) => {
        const { field, onChangeFunc } = this.props;
        if (
            !_.isUndefined(onChangeFunc) &&
            !_.isNull(onChangeFunc) &&
            typeof onChangeFunc === "function"
        ) {
            onChangeFunc(_.get(color, "hex"));
        } else {
            field.onChange(_.get(color, "hex"));
        }
    };

    handleClickColorPicker = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker });
    };

    handleCloseColorPicker = () => {
        this.setState({ displayColorPicker: false });
    };

    render() {
        const { field, style, stylePopover } = this.props;
        const { displayColorPicker, colorDefault } = this.state;
        const popover = {
            zIndex: 12,
            paddingLeft: 30,
            marginTop: 0,
            marginLeft: 10,
        };

        return (
            <div>
                <Row>
                    <Col xs={12} mdOffset={1} md={4} style={{ paddingTop: 30 }}>
                        <div
                            onClick={this.handleClickColorPicker}
                            style={_.assign(
                                { ...styles.styleButtonPicker },
                                style
                            )}
                        >
                            <div
                                style={{
                                    width: 21,
                                    height: 21,
                                    borderRadius: "100%",
                                    marginTop: 4,
                                    marginLeft: 4,
                                    background: _.isUndefined(field.value)
                                        ? colorDefault
                                        : field.value,
                                }}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col
                        xs={12}
                        mdOffset={1}
                        md={4}
                        style={{ height: displayColorPicker ? 280 : 2 }}
                    >
                        {displayColorPicker ? (
                            <div style={_.assign(popover, stylePopover)}>
                                <div
                                    style={{ ...styles.cover }}
                                    onClick={this.handleCloseColorPicker}
                                />
                                <div
                                    style={{ ...styles.coverButton }}
                                    onClick={this.handleCloseColorPicker}
                                >
                                    Cerrar
                                </div>
                                <SketchPicker
                                    color={
                                        _.isUndefined(field.value)
                                            ? colorDefault
                                            : field.value
                                    }
                                    onChangeComplete={this.handleChangeComplete}
                                    width={150}
                                    presetColors={[
                                        "#F44E3B",
                                        "#FE5700",
                                        "#FE9200",
                                        "#FCDC00",
                                        "#DBDF00",
                                        "#A4DD00",
                                        "#68CCCA",
                                        "#73D8FF",
                                        "#AEA1FF",
                                        "#FDA1FF",
                                        "#999999",
                                        "#4D4D4D",
                                    ]}
                                />
                            </div>
                        ) : (
                            <span />
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}
