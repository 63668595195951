import React, {Component} from 'react';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import {Row, Col} from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';
import {TableFooter} from 'material-ui/Table';
import TextField from 'material-ui/TextField';
import Toggle from 'material-ui/Toggle';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import ContentAdd from 'material-ui/svg-icons/content/add';
import { FetchList } from '../../actions/grid/list_grid_form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {blue700} from 'material-ui/styles/colors';
import _ from 'lodash';

const style = {
        margin: 20,
        float: 'right'
    },
    stylePaper = {
        marginTop: 20,
        marginBottom: 20
    };

const stylePopover = {
    padding: 20,
};

class ListGrid extends Component {
    _onRowSelection( event, data ) {
         console.log(arguments, event, data);
    }
    UNSAFE_componentWillMount(){
        this.props.FetchList();
    }
    renderList(){
        if(_.get(this, 'props.list.data')){
            return this.props.list.data.map((source) => {
                return (
                    <TableRow key={source._id}>
                        <TableRowColumn>{source.name}</TableRowColumn>
                        <TableRowColumn>{source.key}</TableRowColumn>
                        <TableRowColumn>{source.value}</TableRowColumn>
                    </TableRow>
                );
            });
        }
    }
    render() {
        return (
          <Row>
              <Col xsOffset={2} xs={8}>
                  <Paper style={stylePaper}>
                      <Row>
                          <Col xs>
                              <div>
                                  <h1 style={{textAlign: 'center', fontWeight: 400}}>Valores de lista registradas</h1>
                                  <FloatingActionButton secondary={true} style={style}>
                                      <ContentAdd />
                                  </FloatingActionButton>
                              </div>
                              <Table onRowSelection={this._onRowSelection}>
                                  <TableHeader>
                                      <TableRow>
                                          <TableHeaderColumn>Nombre</TableHeaderColumn>
                                          <TableHeaderColumn>Clave</TableHeaderColumn>
                                          <TableHeaderColumn>Valor</TableHeaderColumn>
                                      </TableRow>
                                  </TableHeader>
                                  <TableBody>
                                      {this.renderList()}
                                  </TableBody>
                              </Table>
                          </Col>
                      </Row>
                  </Paper>
              </Col>
          </Row>
        );
    }
}

function mapStateToProps(state){
    return  { list: state.list.all };
}

export default connect (mapStateToProps, { FetchList })(ListGrid);
