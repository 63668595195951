import * as Rx from 'rxjs';
import {PEGASUS_URL} from '../../../constants/index';
import isNil from 'lodash/isNil';
import {createSoundQueue} from './functions';


export default class WebSocketManager {
    soundQueue = undefined;
    values = {
        person: '',
        as: '',
        initialMessages: [],
        projects: [],
        connection: undefined,
        pingPongOb$: undefined
    };


    set person(person) {
        this.values.person = person;
    }

    set as(as) {
        this.values.as = as;
    }

    set initialMessages(initialMessages) {
        this.values.initialMessages = initialMessages;
    }

    set projectId(projectId) {
        this.values.projects = projectId;
    }

    set connection(projectId) {
        this.soundQueue = createSoundQueue(this.values.person);
        this.values.connection = Rx
            .Observable
            .webSocket({
                url: `${PEGASUS_URL}?id=${this.values.person}`,
                openObserver: {
                    next: () => {
                        // Now it is safe to send a message
                        console.info('socket open');
                        // build the registration message as an employee
                        const registrationMessage = {
                            type: 'register',
                            as: this.values.as,
                            projectsId: this.values.projects
                        };
                        // send the registration message
                        [registrationMessage].concat(this.values.initialMessages)
                            .map(JSON.stringify)
                            .forEach(message => {
                                this.values.connection.next(message);
                            });
                    }
                }
            })
            .filter(message => !isNil(message))
            .do(message => {
                if(this.soundQueue !== undefined){
                    this.soundQueue.next(message);
                }
            });
    }

    get connection() {
        if (this.values.connection === undefined) {
            this.connection = this.projectId;
            return this.values.connection;
        } else {
            return this.values.connection;
        }
    }

    cleanConnection = () => {
        if (this.values.connection !== undefined) {
            this.values.connection.complete();
            this.values.connection.unsubscribe();
            this.values.connection = undefined;
            this.soundQueue.complete();
            this.soundQueue.unsubscribe();
            this.soundQueue = undefined;
        } else {
            console.error('No connection opened. Nothing to clean.');
        }
    };

    set pingHandler(initialValue) {
        this.values.pingPongOb$ = Rx.Observable.create(observer => {
            let value = initialValue;
            const ticker = setInterval(() => {
                observer.next(value++);
            }, 10000);
            return () => {
                clearTimeout(ticker);
            }
        });
    }

    get pingHandler() {
        if (this.values.pingPongOb$ === undefined) {
            this.pingHandler = 0;
            return this.values.pingPongOb$;
        } else {
            return this.values.pingPongOb$;
        }
    }
}
