import React, {Component} from 'react';
import {connect} from 'react-redux';
import UserDialog from './userDialog';
import {Row, Col} from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';
import TextField from 'material-ui/TextField';
import {LBLUSERS} from '../../constants/labels';
import { Link } from 'react-router';
import {blue600, blue700} from 'material-ui/styles/colors';
import FloatingActionButtonTooltip from '../../components/fields/FloatingActionButtonTooltip/FloatingActionButtonTooltip';
import AuthComponent from '../../components/AuthComponent';
import SpinnerLoading from '../../components/SpinnerLoading/componentSpinner';
import TablePagination from '../../components/TablePagination/tablePagination';

class UserTable extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const body = [
            {
                field: "id",
                title: "Identificación",
                filter: true,
                colspan: 2
            },
            {
                field: "name",
                title: "Nombre",
                colspan: 2,
                filter: true,
            },
            {
                field: "lastName",
                title: "Apellido(s)",
                colspan: 2,
                filter: true,
            },
            {
                field: "email",
                title: "Correo electrónico",
                colspan: 2
            },
            {
                field: "roleEmployee",
                title: "Rol en el sistema",
                order: false
            },
            {
                actions: true,
                title: ""
            }
        ];

        return (
            <Row>
                <AuthComponent
                    component={
                        <Col xsOffset={1} xs={10}>
                            <Paper style={{ marginTop: 20, marginBottom: 20 }}>
                                <Row>
                                    <Col xs>
                                        <div style={{width: '100%', background: blue600, paddingTop: 5, paddingBottom:5, color: "#FFF"}}>
                                            <h1 style={{textAlign: 'center', fontWeight: 400}}>
                                                {LBLUSERS.titleGrid}
                                            </h1>
                                        </div>
                                   </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <AuthComponent
                                            component={
                                                <Link to={`/admin/users/create`}>
                                                    <FloatingActionButtonTooltip textTooltip={LBLUSERS.tooltips.btnCreate}/>
                                                </Link>
                                            }
                                            permission={"cgr_users_create"}
                                            type={"component"}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <TablePagination
                                            name={`users`}
                                            URL={`/users/app`}
                                            version={1}
                                            body={body}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <UserDialog />
                                    </Col>
                                </Row>
                            </Paper>
                        </Col>
                    }
                    permission={"cgr_users_list"}
                    type={"url"}
                />
                <SpinnerLoading/>
            </Row>
        );
    }
}

export default connect(null)(UserTable);
