// @flow
import React from "react";
import Drawer from "material-ui/Drawer";
import MenuItem from "material-ui/MenuItem";
import ChartItem from "./chartComponent";
import DemographicChartComponent from "./demographicChartComponent";
import FilterGroupsComponent from "./filterGroupsComponent";
import MenuComponent from "./menuComponent";
import RankingComponent from "./rankingComponent";
import RowComponent from "./rowComponent";
import SectionComponent from "./sectionComponent";
import TextComponent from "./textComponent";

const componentsBar = () => (
    <Drawer open width={180} containerStyle={{ marginTop: 65, paddingTop: 80, paddingBottom: 64, zIndex: 1270 }}>
        <MenuItem disabled>Elementos</MenuItem>
        <ChartItem />
        <DemographicChartComponent />
        <FilterGroupsComponent />
        <MenuComponent />
        <RankingComponent />
        <RowComponent />
        <SectionComponent />
        <TextComponent />
    </Drawer>
);

export default componentsBar;
