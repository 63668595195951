import PropTypes from "prop-types";
import React, { Component } from "react";
import {
    Table,
    TableHeader,
    TableHeaderColumn,
    TableRow,
} from "material-ui/Table";

import TableHead4 from "@material-ui/core/TableHead";
import TableRow4 from "@material-ui/core/TableRow";
import Table4 from "@material-ui/core/Table";
import { connect } from "react-redux";
import _ from "lodash";
import {
    getRecords,
    resetOrderRecord,
    setInSearchRecords,
    changeLimitRecords,
} from "./actions";
import TableHeadColumn from "./tableHeadColumn";
import TableHeadColumnFilter from "./tableHeadColumnFilter";
import MenuItem from "material-ui/MenuItem";
import SelectFieldFormat from "../fields/SelectFieldFormat/SelectFieldFormat";

class TableHeadPagination extends Component {
    constructor(props) {
        super(props);
        this._renderTotalHead = this._renderTotalHead.bind(this);
        // this._renderHead = this._renderHead.bind(this);
        // this._renderRowHead = this._renderRowHead.bind(this);
        this._renderHeadFilter = this._renderHeadFilter.bind(this);
        this._onChangeLimit = this._onChangeLimit.bind(this);
    }

    _onChangeLimit(e, item, value) {
        const {
            URL,
            version,
            limit,
            skip,
            getRecords,
            setInSearchRecords,
            resetOrderRecord,
            changeLimitRecords,
            extra,
            body,
        } = this.props;
        let arrayFields = [];
        _.map(body, function (field) {
            if (!_.has(field, "actions") && _.has(field, "filter")) {
                if (_.get(field, "filter")) {
                    arrayFields.push(_.get(field, "field"));
                }
            }
        });
        this.setState({ text: "" });
        changeLimitRecords(value);
        resetOrderRecord();
        setInSearchRecords();
        if (!_.isUndefined(extra) && !_.isNull(extra)) {
            getRecords(
                URL,
                version,
                0,
                value,
                "null",
                0,
                arrayFields,
                "",
                extra
            );
        } else {
            getRecords(URL, version, 0, value, "null", 0, arrayFields, "");
        }
    }

    _renderTotalHead() {
        const { total, body, limit } = this.props;
        let colSpanHead = 0;
        _.map(body, function (field) {
            colSpanHead += _.get(field, "colspan", 1);
        });
        return (
            <TableRow>
                <TableHeaderColumn
                    colSpan={colSpanHead}
                    style={{ textAlign: "center" }}
                >
                    <h4 style={{ display: "inline-block", fontWeight: 400 }}>
                        {`Número de registros en total: ${total}`}
                    </h4>
                    <br />
                    <table style={{ width: 240, margin: "0 auto" }}>
                        <tbody>
                            <tr>
                                <td>
                                    <h4
                                        style={{
                                            marginTop: 0,
                                            marginBottom: -12,
                                            display: "inline-block",
                                            fontWeight: 400,
                                        }}
                                    >
                                        {`Número de registros por página:`}
                                    </h4>
                                </td>
                                <td>
                                    <SelectFieldFormat
                                        config={{
                                            floatingLabelText: "",
                                            value: limit,
                                            style: {
                                                marginLeft: 8,
                                                width: 55,
                                                display: "inline-block",
                                                marginBottom: -20,
                                                marginTop: 9,
                                            },
                                            onChange: this._onChangeLimit,
                                        }}
                                    >
                                        <MenuItem value={25} primaryText={25} />
                                        <MenuItem value={50} primaryText={50} />
                                        <MenuItem
                                            value={100}
                                            primaryText={100}
                                        />
                                        <MenuItem
                                            value={200}
                                            primaryText={200}
                                        />
                                        <MenuItem
                                            value={400}
                                            primaryText={400}
                                        />
                                    </SelectFieldFormat>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </TableHeaderColumn>
            </TableRow>
        );
    }

    _renderHeadFilter() {
        const { URL, version, body, extra, selection, hasFilter } = this.props;

        let colSpanHead = 0;
        _.forEach(body, function (field) {
            colSpanHead += _.get(field, "colspan", 1);
        });

        if (hasFilter) {
            return (
                <TableHeadColumnFilter
                    URL={URL}
                    version={version}
                    body={body}
                    extra={extra}
                    selection={selection}
                />
            );
        } else {
            return (
                <TableRow>
                    <TableHeaderColumn
                        colSpan={colSpanHead}
                    ></TableHeaderColumn>
                </TableRow>
            );
        }
    }

    render() {
        const { hasFilter } = this.props;
        return (
            <>
                <Table selectable={false}>
                    <TableHeader
                        displaySelectAll={false}
                        adjustForCheckbox={false}
                    >
                        {this._renderTotalHead()}
                        {this._renderHeadFilter()}
                    </TableHeader>
                </Table>
            </>
        );
    }
}

function mapStateToProps({ tablePagination }, ownProps) {
    return {
        limit: tablePagination.get("limit"),
        skip: tablePagination.get("skip"),
        total: tablePagination.get("total"),
        orderName: tablePagination.get("orderName"),
        orderType: tablePagination.get("orderType"),
        fieldSelection: tablePagination.get("fieldSelection"),
        arrayFields: tablePagination.get("arrayFields"),
        inSearch: tablePagination.get("inSearch"),
    };
}

TableHeadPagination.propTypes = {
    body: PropTypes.array.isRequired,
    URL: PropTypes.string.isRequired,
    version: PropTypes.number.isRequired,
    extra: PropTypes.object,
    selection: PropTypes.bool,
    hasFilter: PropTypes.bool,
};

export default connect(mapStateToProps, {
    getRecords,
    resetOrderRecord,
    setInSearchRecords,
    changeLimitRecords,
})(TableHeadPagination);
