import PropTypes from "prop-types";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { loadMetadata, resetState, sendMessage, selectItem, FETCHED_VALUES } from "./ProjectDucks";
import { blue700 } from "material-ui/styles/colors";
import FiltersSection from "./FiltersSection";
import { allowedListFilters } from "./ProjectDucks";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import TableChart from "./TableChart";
import {
    buildItemIndex,
    buildFields,
    mapItemType,
    mapFilters,
    mapSumParsers,
    mapCategories,
    validationBaseFilterItem,
    shouldPresentMessage,
    getSarinMessage,
} from "./ProjectFunctions";
import get from "lodash/get";
import identity from "lodash/identity";
import isArray from "lodash/isArray";
import { Row, Col } from "react-flexbox-grid";

class SarinCharter extends Component {
    constructor(props) {
        super(props);
    }

    static propTypes = {
        records: PropTypes.arrayOf(PropTypes.object).isRequired,
        title: PropTypes.string.isRequired,
        baseFilter: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]).isRequired,
        allowedFilters: PropTypes.arrayOf(PropTypes.oneOf(allowedListFilters)).isRequired,
        baseFilterItems: validationBaseFilterItem,
        allowSelectType: PropTypes.bool.isRequired,
    };

    sendMessage = ({ element, filters }) => {
        const { records, sendMessage, baseFilter } = this.props;
        const evaluateBaseFilter = isArray(baseFilter) ? baseFilter : [baseFilter];
        const index = buildItemIndex(records, "id", identity);
        const fieldItem = get(index, element);
        const messageBuild = {
            fields: buildFields(fieldItem),
            itemType: mapItemType(fieldItem.type),
            filters: mapFilters(filters, evaluateBaseFilter),
            parsers: mapSumParsers(fieldItem),
            reportType: "SE",
            categories: mapCategories(fieldItem),
        };
        sendMessage(messageBuild);
    };

    render() {
        const {
            records,
            title,
            items,
            selectedItem,
            status,
            selectItem,
            baseFilterItems,
            allowedFilters,
            allowSelectType,
        } = this.props;
        return (
            <div>
                <Row>
                    <Col xs>
                        <div
                            style={{
                                width: "100%",
                                background: blue700,
                                paddingTop: 5,
                                paddingBottom: 5,
                                color: "#FFF",
                            }}
                        >
                            <h1 style={{ textAlign: "center", fontWeight: 400, lineHeight: 1.2 }}>{title}</h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs>
                        <FiltersSection
                            data={records}
                            submitMessage={this.sendMessage}
                            allowSelectType={allowSelectType}
                            baseFilter={baseFilterItems}
                            allowedFilters={allowedFilters}
                        />
                    </Col>
                </Row>
                <div style={{ display: shouldPresentMessage(status) ? "block" : "none" }}>
                    <Row>
                        <Col mdOffset={4} md={5}>
                            <center>
                                <h2>{getSarinMessage(status)}</h2>
                            </center>
                        </Col>
                    </Row>
                </div>
                <div style={{ display: status !== FETCHED_VALUES ? "none" : "block" }}>
                    <Row style={{ padding: "25px" }}>
                        <Col xs={6}>
                            <BarChart yLabel="Cantidad" xLabel="Opciones" data={items} height={400} width={500} />
                        </Col>
                        <Col xs={6}>
                            <PieChart
                                data={items}
                                height={400}
                                width={500}
                                onClickPortion={selectItem}
                                selectedItem={selectedItem}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <TableChart data={items} selectedItem={selectedItem} onClickRow={selectItem} />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ projectReports }) => ({
        metadata: projectReports.get("metadata"),
        project: projectReports.get("project"),
        items: projectReports.get("toRender"),
        selectedItem: projectReports.get("selectedItem"),
        status: projectReports.get("status"),
    });

const mapDispatchToProps = dispatch => bindActionCreators(
        {
            loadMetadata,
            resetState,
            sendMessage,
            selectItem,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(SarinCharter);
