import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import SelectComponent from "../propComponents/styleContent/selectComponent";

const styles = {
    box: {
        boxSizing: "border-box",
        padding: 10,
    },
    title: {
        fontWeight: 400,
        textAlign: "center",
        backgroundColor: "#ADADAD",
        color: "#FFF",
        margin: 0,
        padding: "12px 5px",
        width: "100%",
        boxSizing: "border-box",
    },
    titleError: {
        textAlign: "center",
        color: "#CCC",
        margin: "15px 0",
        boxSizing: "border-box",
        width: "100%",
    },
};

class RowConfiguration extends PureComponent {
    static propTypes = {
        survey: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        array: PropTypes.any.isRequired,
    };

    render() {
        const { survey, path, array } = this.props;
        if (_.size(array) === 0) {
            return <div style={{ ...styles.titleError }}>No es posible cambiar el número de columnas de la fila</div>;
        }
        return (
            <div>
                <div style={{ ...styles.box }}>
                    <SelectComponent
                        survey={survey}
                        path={path}
                        fieldProp={"columns"}
                        title={"Cantidad de columnas"}
                        defaultValue={1}
                        array={array}
                        isNumber
                    />
                </div>
            </div>
        );
    }
}

function getArray(remainingColumns, columnsRow) {
    const arrayDecrease = [];
    const arrayIncrease = [];
    for (let i = 0; i < remainingColumns; i++) {
        arrayDecrease.push(columnsRow - (i + 1));
    }
    if (columnsRow < 5) {
        for (let k = columnsRow; k < 5; k++) {
            arrayIncrease.push(k + 1);
        }
    }
    return _.chain(arrayDecrease)
        .concat([columnsRow], arrayIncrease)
        .uniq()
        .value();
}

function mapStateToProps({ analyticsReportsConfiguration }, ownProps) {
    const config = analyticsReportsConfiguration.get("pageConfiguration");
    const loadingConfiguration = analyticsReportsConfiguration.get("loadingConfiguration");
    const columnsRow = _.get(config, `${_.get(ownProps, "path")}.columns`, 1);
    const sectionsRow = _.get(config, `${_.get(ownProps, "path")}.sections`, []);
    const sumSectionColumns = _.reduce(sectionsRow, (sum, section) => sum + _.get(section, "columns", 1), 0);
    const remainingColumns = columnsRow - sumSectionColumns;
    const arrayValues = getArray(remainingColumns, columnsRow);
    return {
        loadingConfiguration,
        array: _.chain(arrayValues)
            .map(value => ({ value, text: `${value}` }))
            .orderBy(["value", "text"], ["asc", "asc"])
            .value(),
    };
}

export default connect(mapStateToProps)(RowConfiguration);
