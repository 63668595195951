// @flow
import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import { DropTarget } from "react-dnd";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { dropElement as de } from "../ducks";
import type { ConnectDropTarget, DropTargetSpec } from "react-dnd";
import Types from "../dragComponentTypes";
import EmptyContent from "../componentsUtil/emptyContent";
import TitleContent from "../componentsUtil/titleContent";
import SectionContext from "./sectionDropContext";
import { colorHover, textHover, colorBackgroundHover, colorTitleHover, colorBorderHover } from "../utils";
import TabElementComponent from "../componentsUtil/tabElementComponent";
import { getPropsDefault } from "./functions";

const styles = {
    box: {
        minWidth: "100%",
        minHeight: 130,
        border: "1px solid",
        marginTop: 35,
        boxSizing: "border-box",
    },
    sectionBox: {
        minHeight: 170,
        marginTop: 4,
        paddingLeft: 0,
        paddingRight: 0,
        boxSizing: "border-box",
    },
};

type Title = {
    text: string,
    style: any,
    code: string,
};

type Props = {
    _id: string,
    columns: number,
    title: Title,
    path: string,
    indexElement: number,
    totalElements: number,
    dropPath: string,
    pathArray: string,
    position: number,
    survey: string,
    dropElement: (string, string) => Action,
    sections: any,
    connectDropTarget: ConnectDropTarget,
    isOver: boolean,
    canDrop: boolean,
    isOverCurrent: boolean,
    saving: boolean,
};

type DefaultProps = {
    columns: number,
    title: Title,
    sections: any,
};

const columnSizeCalc = {
    "1": 12,
    "2": 6,
    "3": 4,
    "4": 3,
    "5": 2.5,
};

const itemTarget: DropTargetSpec = {
    canDrop(props, monitor) {
        const item = monitor.getItem();
        const remainingColumns =
            _.get(props, "columns", 1) -
            _.reduce(_.get(props, "sections", []), (sum, section) => sum + _.get(section, "columns", 1), 0);
        return remainingColumns > 0 && item.type === Types.SECTION;
    },
    drop(props, monitor): ?Object {
        if (monitor.didDrop()) {
            return {};
        }
        const item = monitor.getItem();
        // const item = monitor.getItem();
        props.dropElement(`${props.path}.sections`, props.page, props.survey, getPropsDefault(item.type));
        return { moved: true };
    },
};

function collect(connect, monitor) {
    return {
        // Call this function inside render()
        // to let React DnD handle the drag events:
        connectDropTarget: connect.dropTarget(),
        // You can ask the monitor about the current drag state:
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
        itemType: monitor.getItemType(),
    };
}

class RowDropContext extends Component<DefaultProps, Props, void> {
    static defaultProps = {
        columns: 1,
        title: {},
        sections: [],
    };

    renderColumns = (section: any, index: number) => {
        const { columns, path, survey } = this.props;
        const size = _.get(section, "columns", 1) * 100 / columns;
        const sectionPath = `${path}.sections.${index}`;
        const pathArray = `${path}.sections`;
        return (
            <div key={`row-section-${path}-${index}`} style={{ ...styles.sectionBox, width: `${size}%` }}>
                <SectionContext
                    rowPath={path}
                    path={sectionPath}
                    pathArray={pathArray}
                    position={index}
                    columns={_.get(section, "columns", 1)}
                    title={_.get(section, "title", null)}
                    elements={_.get(section, "children", [])}
                    orientation={_.get(section, "orientation")}
                    contentAlign={_.get(section, "contentAlign")}
                    styleSection={_.get(section, "style")}
                    survey={survey}
                />
            </div>
        );
    };

    /* renderColumns = (section: any, index: number) => {
        const { columns, path, survey } = this.props;
        const columnSize = columnSizeCalc[columns] || 12;
        const size = Math.floor(columnSize * _.get(section, "columns", 1));
        const sectionPath = `${path}.sections.${index}`;
        const pathArray = `${path}.sections`;
        return (
            <Col key={`row-section-${path}-${index}`} xs={size} md={size} style={{ ...styles.sectionBox }}>
                <SectionContext
                    path={sectionPath}
                    pathArray={pathArray}
                    position={index}
                    columns={_.get(section, "columns", 1)}
                    title={_.get(section, "title", null)}
                    elements={_.get(section, "children", [])}
                    orientation={_.get(section, "orientation")}
                    contentAlign={_.get(section, "contentAlign")}
                    styleSection={_.get(section, "style")}
                    survey={survey}
                />
            </Col>
        );
    }; */

    render() {
        const {
            sections,
            _id,
            connectDropTarget,
            isOver,
            saving,
            canDrop,
            isOverCurrent,
            title,
            path,
            indexElement,
            totalElements,
            pathArray,
            position,
            survey,
        } = this.props;
        const colorSelector = colorHover(isOver, canDrop, isOverCurrent);
        const textMessage = textHover(isOver, canDrop, isOverCurrent, "Puede arrastrar elementos de título o sección");
        const sectionsRender = sections.map(this.renderColumns);
        const hasContent = _.size(sectionsRender) > 0;
        const backgroundColor = hasContent ? colorBackgroundHover(isOver, canDrop, isOverCurrent) : "#FFF";
        const backgroundColorTitle = colorTitleHover(isOver, canDrop, isOverCurrent);
        const backgroundColorBorder = colorBorderHover(isOver, canDrop, isOverCurrent);
        return connectDropTarget(
            <div
                style={{
                    paddingLeft: 10,
                    paddingRight: 10,
                    boxSizing: "border-box",
                }}
            >
                <Row id={_id} style={{ ...styles.box, borderColor: backgroundColorBorder }}>
                    <TabElementComponent
                        backgroundColorTitle={backgroundColorTitle}
                        indexElement={indexElement}
                        totalElements={totalElements}
                        pathArray={pathArray}
                        position={position}
                        path={path}
                        type={Types.ROW}
                        name={"Fila"}
                        survey={survey}
                        hasUpDown
                        propElement={{ type: Types.ROW }}
                    />
                    <Paper
                        style={{
                            width: "100%",
                            padding: "15px 10px 4px 10px",
                            borderRadius: 0,
                            backgroundColor,
                        }}
                        zDepth={0}
                    >
                        <TitleContent path={path} name={"Fila"} />
                        <Row style={{ paddingLeft: 2, paddingRight: 2 }}>
                            <EmptyContent text={textMessage} hasElements={hasContent} color={colorSelector} />
                            {sectionsRender}
                        </Row>
                    </Paper>
                </Row>
            </div>,
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            dropElement: de,
        },
        dispatch,
    );
}

function mapStateToProps({ analyticsReportsConfiguration }) {
    return {
        page: analyticsReportsConfiguration.get("page"),
        saving: analyticsReportsConfiguration.get("saving"),
    };
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    DropTarget(Object.values(Types), itemTarget, collect),
)(RowDropContext);
