import { axiosAPI, axiosAPI_V2 } from '../../../middleware/api';
import * as actions from './constants';

export function logOut() {
    const localStorage = window.localStorage;
    localStorage.removeItem('authToken');
    axiosAPI.defaults.headers.authorization = undefined;
    axiosAPI_V2.defaults.headers.authorization = undefined;
    return {
        type: actions.LOGOUT
    }
}

export function toggleProject(projectId, current){
    return {
        type: actions.TOGGLE_PROJECT,
        project: projectId,
        current
    };
}

export function loadProjects(userId) {
    const request = axiosAPI.get(`/projects/employee/${userId}`);
    return {
        type: actions.LOAD_PROJECTS,
        payload: request
    }
}

export function initialProjects() {
    return {
        type: actions.INITIAL_DASHBOARD,
        payload: {}
    }
}

export function cleanDashboardEmployeeProjects(){
    return {
        type: actions.CLEAN_DASHBOARD_EMPLOYEE_PROJECT
    }
}

export function fetchSolutionProcessed(projectId, personId){
    const request = axiosAPI_V2.get(`/solutions/employee/processed/${projectId}/${personId}`);
    return {
        type: actions.FIND_SOLUTIONS_PROCESSED,
        payload: request
    }
}
