import { GET_QUESTION,DELETE_QUESTION,GET_QUESTIONS_GROUP_SURVEYS } from '../constants/index';
import { GET_QUESTION_INFO } from '../actions/info/questions_info_form';
import _ from 'lodash';

const INITIAL_STATE = { all: [], questions: null, surveyquestionsgroup: []};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_QUESTION:
            return  _.assign({}, state, {
                all: action.payload.data
            });
        case GET_QUESTIONS_GROUP_SURVEYS:
            var id = action.meta.idQuestion;
            if(_.size(state.surveyquestionsgroup) === 0){
                return _.assign({}, state, {
                    surveyquestionsgroup: [{
                        id: id,
                        data: action.payload.data
                    }]
                });
            } else {
                let qt = _.get(state, 'surveyquestionsgroup');
                let exit = true;
                qt.map( (value) => {
                    if(_.isEqual(value.id, id)){
                        exit = false;
                    }
                });
                if(exit){
                    qt.push({
                        id: id,
                        data: action.payload.data
                    });
                    return _.assign({}, state, {
                        surveyquestionsgroup: qt
                    });
                }
                return state;
            }
        case GET_QUESTION_INFO:
            return  _.assign({}, state, {
                all: action.payload.data
            });
        case DELETE_QUESTION:
            var newAll = _.filter(state.all.data, item => {
                return !_.isEqual(item._id, action.meta.questionId);
            });
            return _.assign({}, state, {
                all: {
                    data:newAll
                }
            });
        default:
            return state;
    }
}
