import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import TextField from "material-ui/TextField";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateComponent } from "../../ducks";
import WaitComponent from "../waitComponent";

class NumberComponent extends Component {
    static propTypes = {
        survey: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        page: PropTypes.string.isRequired,
        fieldProp: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
        loadingConfiguration: PropTypes.bool.isRequired,
    };

    onChangeValue = (e, value) => {
        const { updateComponent, survey, page, path, fieldProp } = this.props;
        updateComponent(path, _.set({}, fieldProp, _.toNumber(value)), page, survey);
    };

    render() {
        const { value, title, loadingConfiguration } = this.props;
        if (loadingConfiguration) {
            return <WaitComponent />;
        }
        return (
            <TextField
                hintText=""
                floatingLabelText={title}
                style={{ minWidth: 140 }}
                type="number"
                value={value}
                onChange={this.onChangeValue}
            />
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateComponent }, dispatch);
}

function mapStateToProps({ analyticsReportsConfiguration }, ownProps) {
    const config = analyticsReportsConfiguration.get("pageConfiguration");
    const loadingConfiguration = analyticsReportsConfiguration.get("loadingConfiguration");
    return {
        value: _.get(
            config,
            `${_.get(ownProps, "path")}.${_.get(ownProps, "fieldProp")}`,
            _.get(ownProps, "defaultValue", 0),
        ),
        page: analyticsReportsConfiguration.get("page"),
        loadingConfiguration,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NumberComponent);
