import {GET_TREE_QUESTIONS_ITEMS, CLEAN_STATE_TREE_MAP} from './actions';
import Immutable from 'immutable';

const initialState = Immutable.Map({
   treeQuestions: Immutable.List()
});
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_TREE_QUESTIONS_ITEMS:
            return state.set('treeQuestions', action.payload.data);
        case CLEAN_STATE_TREE_MAP:
            return state.set(action.storeName, {data: null});
        default:
            return state;
    }
};
