import { axiosAPI, axiosAPI_V2 } from '../../../middleware/api';
import { LOAD_COMPANY_PROJECTS, LOAD_COMPANY_INFO, LOGOUT, CLEAR_LOAD_COMPANY_PROJECTS, GET_COMPANY_ID_USER } from './constants';

export function loadCompanyProjects(companyId) {
    const request = axiosAPI.get(`/projects/company/${companyId}`);
    return {
        type: LOAD_COMPANY_PROJECTS,
        payload: request
    }
}

export function cleanCompanyProjects() {
    return {
        type: CLEAR_LOAD_COMPANY_PROJECTS
    }
}

export function setCompanyIdUser(idCompany) {
    return {
        type: GET_COMPANY_ID_USER,
        meta: {
            idCompany: idCompany
        }
    }
}

export function getCompanyInfoFromPersonId(personId) {
    const request = axiosAPI.get(`/companies/person/${personId}`);
    return {
        type: LOAD_COMPANY_INFO,
        payload: request
    }
}

export function logOut() {
    const localStorage = window.localStorage;
    localStorage.removeItem('authToken');
    axiosAPI.defaults.headers.authorization = undefined;
    axiosAPI_V2.defaults.headers.authorization = undefined;
    return {
        type: LOGOUT
    }
}
