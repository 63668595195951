import { axiosAPI, axiosAPI_V2 } from "../../middleware/api";
import { CLEAN_PROJECT } from "../../constants/index";

export const GET_COMPANIES_GRID = "GET_COMPANIES_GRID";
export const GET_COMPANIES_NAMES = "GET_COMPANIES_NAMES";
export const POST_COMPANY_INFO = "POST_COMPANY_INFO";
export const GET_NUMBER_EMPLOYEES = "GET_NUMBER_EMPLOYEES";
export const GET_ECONOMIC_ACTIVITIES = "GET_ECONOMIC_ACTIVITIES";
export const GET_ARLS = "GET_ARLS";
export const GET_PROJECTS_COMPANIES_IN_SEARCH = "GET_PROJECTS_COMPANIES_IN_SEARCH";
export const CLEAN_COMPANY_INFO = "CLEAN_COMPANY_INFO";

export function FetchCompany() {
    const request = axiosAPI.get(`/companies`);
    return {
        type: GET_COMPANIES_GRID,
        payload: request,
    };
}

export function inSearchProjectCompanies() {
    return {
        type: GET_PROJECTS_COMPANIES_IN_SEARCH,
    };
}

export function FetchCompanyParam(id) {
    const request = axiosAPI.get(`/companies/${id}`);
    return {
        type: POST_COMPANY_INFO,
        payload: request,
    };
}

export function clearCompanyParam() {
    return {
        type: CLEAN_COMPANY_INFO,
    };
}

export function getCompaniesName() {
    const request = axiosAPI.get(`/companies/names`);

    return {
        type: GET_COMPANIES_NAMES,
        payload: request,
    };
}

export function getEmployeesNumber() {
    const request = axiosAPI_V2.get(`/master/employeesNumber`);

    return {
        type: GET_NUMBER_EMPLOYEES,
        payload: request,
    };
}

export function getARLs() {
    const request = axiosAPI_V2.get(`/master/arl`);

    return {
        type: GET_ARLS,
        payload: request,
    };
}

export function getEconomicActivities() {
    const request = axiosAPI_V2.get(`/master/economicActivities`);

    return {
        type: GET_ECONOMIC_ACTIVITIES,
        payload: request,
    };
}

export function cleanProject() {
    return {
        type: CLEAN_PROJECT,
    };
}
