import React, { Component } from "react";
import { reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { Row, Col, Grid } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import AppBar from "material-ui/AppBar";
import _ from "lodash";
import { Link, browserHistory } from "react-router";
import DocumentTitle from "react-document-title";
import SnackbarMessage from "../../components/commons/snackBarMessage";
import { toggleSnackbar } from "../../actions/commons";

import {
    login,
    LOGIN_REJECTED_AUTH,
    LOGIN_REJECTED_SERVER,
    LOGIN_FULFILLED,
    LOGIN_IN_PROGRESS,
    cleanLoginStatus,
    cleanStateUsingLogOut,
} from "./loginDucks";
import FaqMessage from "../../components/fields/FaqMessage/FaqMessage";
import About from "../../components/fields/about/About";
import { axiosAPI, axiosAPI_V2 } from "../../middleware/api";

const loginBoxStyle = {
    marginTop: 80,
};

const style = {
    marginTop: 50,
    marginBottom: 20,
};

class LoginBox extends Component {
    constructor(props) {
        super(props);
        this._checkError = this._checkError.bind(this);
        this._handleLogin = this._handleLogin.bind(this);
    }

    UNSAFE_componentWillReceiveProps({ status, token, uri }) {
        const { toggleSnackbar, cleanLoginStatus } = this.props;
        if (status === LOGIN_IN_PROGRESS) {
            toggleSnackbar(true, "Verificando tus credenciales...");
            cleanLoginStatus();
        } else if (status === LOGIN_FULFILLED) {
            toggleSnackbar(true, "Bienvenido.");
            window.localStorage.setItem("authToken", token);
            axiosAPI.defaults.headers.authorization = token;
            axiosAPI_V2.defaults.headers.authorization = token;
            browserHistory.push(uri);
            cleanLoginStatus();
        } else if (status === LOGIN_REJECTED_AUTH) {
            toggleSnackbar(true, "Usuario o contraseña incorrectos");
            cleanLoginStatus();
        } else if (status === LOGIN_REJECTED_SERVER) {
            toggleSnackbar(
                true,
                "Error inesperado, intentalo de nuevo más tarde."
            );
            cleanLoginStatus();
        }
    }

    componentDidMount() {
        const { cleanStateUsingLogOut } = this.props;
        cleanStateUsingLogOut();
    }

    _handleLogin(formdata) {
        const { login } = this.props;
        login(_.trim(formdata.user), _.trim(formdata.password));
    }

    _checkError() {
        const { loginState } = this.props;
        if (!_.isNull(loginState.get("errorMessage"))) {
            console.log("User error");
        }
    }

    render() {
        const {
            fields: { user, password },
            handleSubmit,
            status,
        } = this.props;
        return (
            <DocumentTitle title="Iniciar sesión">
                <Grid fluid>
                    <Row style={loginBoxStyle}>
                        <Col xs={12} sm={6} smOffset={3} md={4} mdOffset={4}>
                            <Paper
                                style={{
                                    paddingBottom: 25,
                                    paddingLeft: 0,
                                }}
                            >
                                <Row>
                                    <Col xs>
                                        <AppBar
                                            title="SURA Encuestas - Ingresar"
                                            showMenuIconButton={false}
                                        />
                                    </Col>
                                </Row>
                                <form
                                    onSubmit={handleSubmit(this._handleLogin)}
                                >
                                    <Row style={{ marginTop: 40 }}>
                                        <Col xs style={{ textAlign: "center" }}>
                                            <TextField
                                                hintText="ej: 1034244535"
                                                floatingLabelText="Número de identificación"
                                                style={{
                                                    margin: "0 auto",
                                                    textAlign: "left",
                                                }}
                                                errorText={
                                                    user.touched && user.error
                                                        ? user.error
                                                        : ""
                                                }
                                                {...user}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs style={{ textAlign: "center" }}>
                                            <TextField
                                                floatingLabelText="Contraseña"
                                                type="password"
                                                style={{
                                                    margin: "0 auto",
                                                    textAlign: "left",
                                                }}
                                                errorText={
                                                    password.touched &&
                                                    password.error
                                                        ? password.error
                                                        : ""
                                                }
                                                {...password}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs style={{ textAlign: "center" }}>
                                            <RaisedButton
                                                label="Iniciar sesión"
                                                secondary
                                                style={style}
                                                type="submit"
                                                disabled={
                                                    status === LOGIN_IN_PROGRESS
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs style={{ textAlign: "center" }}>
                                            <Link
                                                to="/forgot"
                                                style={{ color: "blue" }}
                                            >
                                                <FlatButton
                                                    label="¿Olvidó su contraseña?"
                                                    secondary
                                                />
                                            </Link>
                                        </Col>
                                    </Row>
                                </form>
                            </Paper>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs>
                            <SnackbarMessage />
                            <FaqMessage />
                            <About />
                        </Col>
                    </Row>
                </Grid>
            </DocumentTitle>
        );
    }
}
const validate = (values) => {
    const errors = {};
    if (!values.user) {
        errors.user = "Ingrese el número de identificación";
    }
    if (!values.password) {
        errors.password = "Ingrese una contraseña";
    }
    return errors;
};

function mapStateToProps({ login }) {
    return {
        loginState: login,
        status: login.get("status"),
        token: login.get("token"),
        uri: login.get("uri"),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            login,
            toggleSnackbar,
            cleanLoginStatus,
            cleanStateUsingLogOut,
        },
        dispatch
    );
}
export default reduxForm(
    {
        form: "loginForm",
        fields: ["user", "password"],
        validate,
    },
    mapStateToProps,
    mapDispatchToProps
)(LoginBox);
