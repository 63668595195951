import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateTextComponent } from "../../ducks";
import RichText from "../../../fields/RichText/editorRichText";
import WaitComponent from "../waitComponent";

class TextComponent extends Component {
    static propTypes = {
        survey: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        page: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        height: PropTypes.number,
        boxHeight: PropTypes.number.isRequired,
        textHeight: PropTypes.number.isRequired,
        loadingConfiguration: PropTypes.bool.isRequired,
    };

    onChangeValue = value => {
        const { updateTextComponent, survey, page, path } = this.props;
        updateTextComponent(`${path}.textProps`, { text: value }, page, survey);
    };

    render() {
        const { value, loadingConfiguration, boxHeight, textHeight } = this.props;
        if (loadingConfiguration) {
            return <WaitComponent />;
        }
        return (
            <div style={{ border: "1px solid #CCC", width: "100%", margin: "0 auto", height: boxHeight }}>
                <RichText heightText={textHeight} initValue={value} onChangeUpdate={this.onChangeValue} />
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateTextComponent }, dispatch);
}

function mapStateToProps({ analyticsReportsConfiguration }, ownProps) {
    const sizeText = 111;
    const config = analyticsReportsConfiguration.get("pageConfiguration");
    const loadingConfiguration = analyticsReportsConfiguration.get("loadingConfiguration");
    const height = _.get(ownProps, "height", 200);
    const boxHeight = height < 200 ? 200 : height;
    const textHeight = boxHeight - sizeText;
    return {
        value: _.get(config, `${_.get(ownProps, "path")}.textProps.text`, "<p></p>"),
        page: analyticsReportsConfiguration.get("page"),
        loadingConfiguration,
        boxHeight,
        textHeight,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TextComponent);
