import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {Row, Col} from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import IconButton from 'material-ui/IconButton';
import FloatingActionButtonTooltip from '../fields/FloatingActionButtonTooltip/FloatingActionButtonTooltip';
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';
import _ from 'lodash';
import { Link } from 'react-router';
import {blue700} from 'material-ui/styles/colors';
import { browserHistory } from 'react-router';
import {LBLLOCATIONS} from '../../constants/labels';
import ButtonBack from '../fields/buttonBack/buttonBack';
import { getNameCountry } from '../fields/Locations/actions';
import LocationFormModal from '../ModalFormLocation/componentModalFormLocation';
import {openModalForm} from '../ModalFormLocation/actions';
import LoadingPage from '../commons/loadingPage';
import AuthComponent from '../AuthComponent';
import TablePagination from '../TablePagination/tablePagination';

const stylePaper = {
        marginTop: 20,
        marginBottom: 20
    };

class AdminDepartment extends Component {

    constructor(props) {
        super(props);
    }

    UNSAFE_componentWillMount(){
        const {
            getNameCountry,
            params
        } = this.props;
        getNameCountry(_.get(params, 'countryId'));
    }

    _handleOpenModalForm(title, id, code, name){
        const {
            openModalForm,
            params
        } = this.props;
        openModalForm(id, code, name, title, "department", params.countryId, false);
    }

    render() {
        const body = [
            {
                field: "code",
                title: "Código"
            },
            {
                field: "name",
                title: "Nombre",
                colspan: 3,
                filter: true
            },
            {
                actions: true,
                title: "Operaciones",
                colspan: 1
            }
        ];
        const {
            params,
            nameCountry
        } = this.props;
        return (
            <Row>
                <AuthComponent
                    component={
                        <Col xs={12} mdOffset={2} md={8}>
                            <Paper style={stylePaper}>
                                <ButtonBack url={`/admin/locations/countries`}/>
                                <Row>
                                    <Col xs>
                                        <div style={{width: '100%', background: blue700, paddingTop: 5, paddingBottom:5, color: "#FFF"}}>
                                            <h1 style={{textAlign: 'center', fontWeight: 400}}>{`${LBLLOCATIONS.departments} de ${_.capitalize(nameCountry)}`}</h1>
                                            <AuthComponent
                                                component={
                                                    <div style={{position: "fixed", right: 15, bottom: 15}}>
                                                        <FloatingActionButtonTooltip
                                                            onClick={this._handleOpenModalForm.bind(this,`${LBLLOCATIONS.tooltips.btnCreate} departamento`, null, "", "" )}
                                                            textTooltip={LBLLOCATIONS.tooltips.btnCreateDepartment}
                                                        />
                                                    </div>
                                                }
                                                permission={"cgr_location_department_create"}
                                                type={"component"}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <TablePagination
                                            name={`departments`}
                                            URL={`/location/departments`}
                                            version={1}
                                            body={body}
                                            extra={{country: _.get(params, 'countryId')}}
                                        />
                                    </Col>
                                </Row>
                            </Paper>
                            <LocationFormModal/>
                        </Col>
                    }
                    permission={"cgr_location_country_config"}
                    type={"url"}
                />
            </Row>
        );
    }
}

function mapStateToProps({ master }, ownProps) {
    return {
        nameCountry: _.get(master, 'nameCountry', "")
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        openModalForm,
        getNameCountry
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDepartment);
