import { axiosAPI } from "../../middleware/api";

const REMEMBER_PASSWORD = "REMEMBER_PASSWORD";

export function rememberPassword(user) {
    const request = axiosAPI.post("/users/forgot", { user });
    return {
        type: REMEMBER_PASSWORD,
        payload: request,
    };
}
