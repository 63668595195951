import React, { PureComponent } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { grey400 } from "material-ui/styles/colors";
import InfoIcon from "material-ui/svg-icons/action/info-outline";
import IconButton from "material-ui/IconButton";
import Types from "../dragComponentTypes";
import { QUESTION, INDICATOR } from "../../analyticsReport/types";

const styles = {
    box: { position: "absolute", color: "#FFF", boxSizing: "border-box" },
    icon: { padding: 0, width: 30, height: 30 },
    tooltip: { margin: "-46px 0px 0px 16px" },
};

export function getTextTitle(elementProps) {
    switch (_.get(elementProps, "type")) {
        case Types.ROW:
            return "Fila";
        case Types.SECTION:
            return "Sección";
        case Types.TEXT:
            return "Texto";
        case Types.CHART:
            switch (_.get(elementProps, "chartProps.chartType")) {
                case Types.DEMOGRAPHIC_CHART:
                    return "Sociodemográfico";
                case Types.FILTER_GROUPS:
                    return "Grupo de filtros";
                case INDICATOR:
                    return "Indicador";
                case QUESTION:
                    return "Pregunta";
                default:
                    return "Ninguno";
            }
        case Types.RANKING:
            return "Clasificación";
        case Types.MENU:
            return "Menú";
        default:
            return "Ninguno";
    }
}

class TitleTypeComponent extends PureComponent {
    static propTypes = {
        elementProps: PropTypes.any.isRequired,
    };

    render() {
        const { elementProps } = this.props;
        return (
            <div style={{ ...styles.box }}>
                <IconButton
                    style={{ ...styles.icon }}
                    tooltipStyles={{ ...styles.tooltip }}
                    disableTouchRipple
                    tooltip={getTextTitle(elementProps)}
                    tooltipPosition="bottom-right"
                >
                    <InfoIcon color={grey400} />
                </IconButton>
            </div>
        );
    }
}

export default TitleTypeComponent;
