import PropTypes from "prop-types";
import React, { Component } from "react";
import TableCell from "@material-ui/core/TableCell";
import _ from "lodash";
import AuthComponent from "../../AuthComponent";
import { Link } from "react-router";
import IconButton from "material-ui/IconButton";
import { connect } from "react-redux";
import VisibilityIcon from "material-ui/svg-icons/action/visibility";
import CommentIcon from "material-ui/svg-icons/editor/insert-comment";
import SendIcon from "material-ui/svg-icons/content/send";
import { openModalSendEmail } from "../../ModalSendEmail/action";
import {
    openDialogUserAdvance,
    advanceUserProject,
} from "../../fields/AdvanceUser/actions";
import ResetSurvey from "../../resetSurvey";
import { changeStatusRecordEmployee } from "../actions";

class EmployeeStatusProjectActionColumn extends Component {
    constructor(props) {
        super(props);
        this.handleModalAdvanceUser = this.handleModalAdvanceUser.bind(this);
        this.handleModalSendEmail = this.handleModalSendEmail.bind(this);
        this.handleModalResetUser = this.handleModalResetUser.bind(this);
    }

    handleModalAdvanceUser(data) {
        const { extra, openDialogUserAdvance, advanceUserProject } = this.props;
        advanceUserProject(
            _.get(data, "_id"),
            _.get(extra, "project"),
            _.get(data, "employee.person")
        );
        openDialogUserAdvance();
    }

    handleModalSendEmail(data) {
        const { openModalSendEmail } = this.props;
        openModalSendEmail([_.get(data, "employee")], "employee");
    }

    handleModalResetUser(employeeId, projectId) {
        const { changeStatusRecordEmployee } = this.props;
        changeStatusRecordEmployee(employeeId, projectId, "Pendiente");
        // onClick={this.handleModalResetUser.bind(this, employeeId, projectId)}
    }

    render() {
        const { data, colSpan, extra } = this.props;
        const cs = _.isUndefined(colSpan) ? 1 : colSpan;
        const isFinished = _.isEqual(_.get(data, "status"), "Finalizado");
        const inProcess = _.isEqual(_.get(data, "status"), "En proceso");
        const inRejecter = _.isEqual(_.get(data, "status"), "Desertor");
        const canBeReseted = isFinished || inProcess || inRejecter;
        const namePerson = _.get(data, "name");
        const lastNamePerson = _.get(data, "lastName");
        const personId = _.get(data, "id");
        const projectId = _.get(data, "project");
        const employeeId = _.get(data, "employee._id", "");
        return (
            <TableCell colSpan={cs}>
                <div
                    style={{ position: "absolute", marginTop: -24, zIndex: 99 }}
                >
                    {inProcess && (
                        <AuthComponent
                            component={
                                <IconButton
                                    tooltip="Ver estado actual"
                                    tooltipPosition="top-center"
                                    disabled={!inProcess}
                                    children={<VisibilityIcon />}
                                    onClick={() =>
                                        this.handleModalAdvanceUser(data)
                                    }
                                />
                            }
                            permission={"cgr_projects_status_user"}
                            type={"component"}
                        />
                    )}
                    {isFinished && (
                        <AuthComponent
                            component={
                                <Link
                                    to={`${_.get(extra, "urlResult")}/${_.get(
                                        extra,
                                        "project"
                                    )}/surveys/${_.get(
                                        extra,
                                        "projectSurveyId"
                                    )}/employee/${_.get(
                                        data,
                                        "employee.person._id"
                                    )}/results`}
                                >
                                    <IconButton
                                        tooltip={"Comentar resultado"}
                                        tooltipPosition="top-center"
                                        disabled={!isFinished}
                                        children={<CommentIcon />}
                                    />
                                </Link>
                            }
                            permission={"cgr_results_view"}
                            type={"component"}
                        />
                    )}
                    {canBeReseted && (
                        <AuthComponent
                            component={
                                <ResetSurvey
                                    employee={employeeId}
                                    project={projectId}
                                    personId={personId}
                                    personName={`${namePerson} ${lastNamePerson}`}
                                    disabled={!canBeReseted}
                                />
                            }
                            permission={"cgr_survey_reset_user"}
                            type={"component"}
                        />
                    )}
                    <AuthComponent
                        component={
                            <IconButton
                                tooltip="Enviar correo"
                                tooltipPosition="top-center"
                                children={<SendIcon />}
                                onClick={this.handleModalSendEmail.bind(
                                    this,
                                    data
                                )}
                            />
                        }
                        permission={"cgr_results_send_emails"}
                        type={"component"}
                    />
                </div>
            </TableCell>
        );
    }
}

EmployeeStatusProjectActionColumn.propTypes = {
    data: PropTypes.object.isRequired,
    colSpan: PropTypes.number,
    extra: PropTypes.object,
};

export default connect(null, {
    advanceUserProject,
    openDialogUserAdvance,
    openModalSendEmail,
    changeStatusRecordEmployee,
})(EmployeeStatusProjectActionColumn);
