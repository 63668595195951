import React, { Component } from "react";
import PropTypes from "prop-types";
import { Editor, EditorState, Modifier, RichUtils } from "draft-js";
import IconButton from "material-ui/IconButton";
import FormatBold from "material-ui/svg-icons/editor/format-bold";
import FormatItalic from "material-ui/svg-icons/editor/format-italic";
import FormatUnderlined from "material-ui/svg-icons/editor/format-underlined";
import FormatStricketThrough from "material-ui/svg-icons/editor/strikethrough-s";
import FormatSize from "material-ui/svg-icons/editor/format-size";
import FormatColorFill from "material-ui/svg-icons/editor/format-color-fill";
import FormatColor from "material-ui/svg-icons/editor/border-color";
import _ from "lodash";
import Popover from "material-ui/Popover";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
import ColorPicker from "./colorPicker";
import EditorList from "./editorList";

class ControlEditor extends Component {
    state = {
        colorText: "#000",
        colorBackgroundText: "none",
        colorDefault: "#999",
        colorFocus: "#000",
        tooltipPosition: "top-center",
        openTitle: false,
    };

    static propTypes = {
        editorState: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
        onChangeColor: PropTypes.func,
        onChangeBackgroundColor: PropTypes.func,
        onToggleList: PropTypes.func.isRequired,
        colorStyleMap: PropTypes.object.isRequired,
    };

    _onChangeColor = (color) => {
        const { onChangeColor } = this.props;
        const colorSelected = _.get(color, "hex");
        this.setState({ colorText: colorSelected });
        const styleColor = _.toUpper(_.trim(_.replace(colorSelected, "#", "")));
        onChangeColor(styleColor, colorSelected);
        this._onChangeHandler(styleColor);
    };

    _onChangeBackgroundColor = (color) => {
        const { onChangeBackgroundColor } = this.props;
        const colorSelected = _.get(color, "hex");
        this.setState({ colorBackgroundText: colorSelected });
        const styleColor = _.toUpper(_.trim(_.replace(colorSelected, "#", "")));
        onChangeBackgroundColor(styleColor, colorSelected);
        this._onChangeHandler(styleColor);
    };

    _onChangeHandler = (style) => {
        const { onChange, editorState } = this.props;
        onChange(RichUtils.toggleInlineStyle(editorState, style));
    };

    handleTouchTap = (event) => {
        event.preventDefault();
        this.setState({ openTitle: true, anchorEl: event.currentTarget });
    };

    handleRequestClose = () => this.setState({ openTitle: false });

    render() {
        const { editorState, colorStyleMap, onToggleList } = this.props;
        const {
            colorText,
            colorBackgroundText,
            colorDefault,
            colorFocus,
            openTitle,
            anchorEl,
            tooltipPosition,
        } = this.state;
        const currentStyle = editorState.getCurrentInlineStyle();
        const TITLES = [
            { style: "TITLEH1", props: { fontSize: "2em" } },
            { style: "TITLEH2", props: { fontSize: "1.5em" } },
            { style: "TITLEH3", props: { fontSize: "1.33em" } },
            { style: "TITLEH4", props: { fontSize: "1.17em" } },
            { style: "TITLEH5", props: { fontSize: "0.83em" } },
            { style: "TITLEH6", props: { fontSize: "0.67em" } },
        ];
        const TEXTS = [
            { style: "BOLD", icon: FormatBold, name: "Negrita" },
            { style: "ITALIC", icon: FormatItalic, name: "Itálica" },
            { style: "UNDERLINE", icon: FormatUnderlined, name: "Subrayado" },
            {
                style: "STRIKETHROUGH",
                icon: FormatStricketThrough,
                name: "Tachado",
            },
        ];
        const hasTitle =
            _.chain(TITLES)
                .filter((title) => currentStyle.has(_.get(title, "style")))
                .size()
                .value() >= 1;
        return (
            <div>
                <div style={{ border: "0px solid #000", userSelect: "none" }}>
                    <ColorPicker
                        value={colorText}
                        Icon={FormatColor}
                        onChangeColor={this._onChangeColor}
                    />
                    <IconButton
                        tooltip={"Tamaño del texto"}
                        onClick={this.handleTouchTap.bind(this)}
                        tooltipPosition={tooltipPosition}
                        children={
                            <FormatSize
                                color={!hasTitle ? colorDefault : colorFocus}
                            />
                        }
                    />
                    <Popover
                        open={openTitle}
                        anchorEl={anchorEl}
                        anchorOrigin={{ horizontal: "right", vertical: "top" }}
                        targetOrigin={{ horizontal: "left", vertical: "top" }}
                        onRequestClose={this.handleRequestClose}
                        style={{ minWidth: 105, width: 210 }}
                    >
                        <div>
                            {_.map(TITLES, (edit, index) => {
                                let colorText = colorDefault;
                                if (currentStyle.has(edit.style)) {
                                    colorText = colorFocus;
                                }
                                return (
                                    <div
                                        key={`title-rich-text-editor-${index}`}
                                        style={getStyleTitleButton(
                                            _.get(edit, "props", {}),
                                            colorText,
                                            index
                                        )}
                                        onClick={this._onChangeHandler.bind(
                                            this,
                                            _.get(edit, "style")
                                        )}
                                    >
                                        Texto
                                    </div>
                                );
                            })}
                        </div>
                    </Popover>
                    {_.map(TEXTS, (edit, index) => {
                        let colorIcon = colorDefault;
                        const Icon = _.get(edit, "icon");
                        if (currentStyle.has(edit.style)) {
                            colorIcon = colorFocus;
                        }
                        return (
                            <IconButton
                                key={`color-rich-text-editor-${index}`}
                                tooltip={_.get(edit, "name")}
                                onClick={this._onChangeHandler.bind(
                                    this,
                                    _.get(edit, "style")
                                )}
                                tooltipPosition={tooltipPosition}
                                children={<Icon color={colorIcon} />}
                            />
                        );
                    })}
                    <EditorList
                        editorState={editorState}
                        onToggle={onToggleList}
                        colorDefault={colorDefault}
                        colorFocus={colorFocus}
                        tooltipPositionButton={tooltipPosition}
                    />
                </div>
            </div>
        );
    }
}

function getStyleTitleButton(props, color, index) {
    const border = "1px solid #e2e2e2";
    const noBorder = "0px solid #e2e2e2";
    return _.assign(props, {
        verticalAlign: "bottom",
        padding: "4px 3px",
        cursor: "pointer",
        color,
        padding: "10px 0",
        textAlign: "center",
        borderBottom: border,
        display: "inline-block",
        width: "50%",
        borderRight: index % 2 === 0 ? border : noBorder,
        boxSizing: "border-box",
    });
}

export default ControlEditor;
