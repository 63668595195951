import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { reduxForm } from "redux-form";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { browserHistory } from "react-router";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";
import { Row, Col } from "react-flexbox-grid";
import { blue700 } from "material-ui/styles/colors";
import _ from "lodash";
import Divider from "material-ui/Divider";
import { toggleSnackbar } from "../../actions/commons";
import SnackbarMessage from "../../components/commons/snackBarMessage";
import { deleteUser } from "../../actions/fnTable";
import {
    toggleDialog,
    changePassword,
} from "../../actions/users/userDialogActions";
import FaqMessage from "../../components/fields/FaqMessage/FaqMessage";
import About from "../../components/fields/about/About";
import AuthComponent from "../../components/AuthComponent";
import { LBLUSERS } from "../../constants/labels";
import DeleteConfirmModal from "../../components/ConfirmEntityModal/ConfirmEntityModal";
import SendMessageModal from "../../components/ModalSendEmail/modalSendEmail";
import {
    openModalSendEmailUser,
    openModalSendEmail,
} from "../../components/ModalSendEmail/action";
import { removeRecord } from "../../components/TablePagination/actions";

class UserDialog extends Component {
    constructor(props) {
        super(props);
        this._handleChangePassword = this._handleChangePassword.bind(this);
        this._renderProfiles = this._renderProfiles.bind(this);
        this._renderRole = this._renderRole.bind(this);
        this._renderCompany = this._renderCompany.bind(this);
        this.handleUpdateUser = this.handleUpdateUser.bind(this);
        this.handleDeleteUser = this.handleDeleteUser.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleModalSendEmail = this.handleModalSendEmail.bind(this);
    }

    handleClose() {
        const { toggleDialog, resetForm } = this.props;
        toggleDialog(false);
        resetForm();
    }

    handleUpdateUser() {
        const { userDialog, toggleDialog, resetForm } = this.props;
        toggleDialog(false);
        resetForm();
        browserHistory.push(
            `/admin/users/edit/${_.get(userDialog, "user._id")}`
        );
    }

    handleDeleteUser(user) {
        const { deleteUser, toggleSnackbar, removeRecord } = this.props;
        const userId = _.get(user, "_id");
        deleteUser(userId)
            .then(
                (data) => {
                    if (
                        _.get(data, "error") ||
                        _.get(data, "payload.status") !== 200
                    ) {
                        toggleSnackbar(true, LBLUSERS.msg.errorDelete);
                    } else {
                        removeRecord(userId, "_id");
                        this.handleClose();
                        toggleSnackbar(true, LBLUSERS.msg.successDelete);
                    }
                },
                (reason) => {
                    toggleSnackbar(true, LBLUSERS.msg.errorDelete);
                }
            )
            .catch((data) => {
                toggleSnackbar(true, LBLUSERS.msg.errorDelete);
            });
    }

    handleModalSendEmail(user) {
        const { openModalSendEmail } = this.props;
        openModalSendEmail([user], "users");
    }

    _handleChangePassword(formData) {
        const { changePassword, userDialog, toggleSnackbar } = this.props;
        changePassword(
            userDialog.user._id,
            _.get(formData, "newPassword")
        ).then(
            (data) => {
                toggleSnackbar(true, "Contraseña cambiada correctamente");
                this.props.resetForm();
                console.log(data);
            },
            (reason) => {
                toggleSnackbar(
                    true,
                    "Error al cambiar la contraseña, intentelo de nuevo más tarde."
                );
                console.log(reason);
            }
        );
    }

    _renderCompany() {
        const { user } = this.props.userDialog;
        if (_.has(user, "company")) {
            if (
                !_.isEqual(_.get(user, "company"), "") &&
                !_.isEqual(_.get(user, "company"), "Ninguna")
            ) {
                return (
                    <p>
                        <strong>Empresa(s):</strong> {_.get(user, "company")}
                    </p>
                );
            }
        }
    }

    _renderRole() {
        const { user } = this.props.userDialog;
        let rolUser = "";
        switch (_.get(user, "role")) {
            case "admin":
                rolUser = "Administrador";
                break;
            case "tabulator":
                rolUser = "Tabulador";
                break;
            case "employee":
                rolUser = "Empleado";
                break;
            case "referee":
                rolUser = "Evaluador";
                break;
            case "company":
                rolUser = "Empresa";
                break;
            case "arl":
                rolUser = "ARL";
                break;
            default:
                rolUser = "No definido";
                break;
        }
        return (
            <p>
                <strong>Rol en el sistema:</strong> {rolUser}
            </p>
        );
    }

    _renderProfiles() {
        const { user } = this.props.userDialog;
        if (
            _.get(user, "profiles") instanceof Array &&
            _.size(_.get(user, "profiles")) > 0
        ) {
            return (
                <div>
                    <p>
                        <strong>Perfiles asignados:</strong>
                    </p>
                    <ul>
                        {_.map(_.get(user, "profiles"), (profile, idx) => (
                            <li key={`profile${_.get(profile, "_id")}`}>
                                <p>{_.get(profile, "name")}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            );
        }
        return <div />;
    }

    render() {
        const {
            fields: { newPassword, confirmNewPassword },
            handleSubmit,
            resetForm,
        } = this.props;
        const actions = [
            <FlatButton label="Cerrar" secondary onClick={this.handleClose} />,
        ];
        const { user } = this.props.userDialog;
        // {JSON.stringify(_.get(user, "companyData"), null, 2)}
        return (
            <div>
                <Dialog
                    title="Información del usuario"
                    actions={actions}
                    modal={false}
                    open={this.props.userDialog.status}
                    titleStyle={{ textAlign: "center", color: blue700 }}
                    contentStyle={{
                        maxWidth: 720,
                        minWidth: 300,
                        textAlign: "justify",
                    }}
                    autoScrollBodyContent
                    onRequestClose={this.handleClose}
                >
                    <Row>
                        <Col xs={12} mdOffset={1} md={10}>
                            <Row style={{ marginBottom: 15, marginTop: 15 }}>
                                <Col
                                    xs
                                    style={{ fontSize: 14, paddingRight: 30 }}
                                >
                                    <p>
                                        <strong>Identificación:</strong>{" "}
                                        {_.get(user, "person.id")}
                                    </p>
                                    <p>
                                        <strong>Nombre:</strong>{" "}
                                        {_.get(user, "person.name")}{" "}
                                        {_.get(user, "person.lastName")}
                                    </p>
                                    <p>
                                        <strong>Correo:</strong>{" "}
                                        {_.get(user, "person.email")}
                                    </p>
                                    {this._renderCompany()}
                                    {this._renderRole()}
                                    {this._renderProfiles()}
                                </Col>
                                <Col>
                                    <AuthComponent
                                        component={
                                            <Col xs>
                                                <form
                                                    onSubmit={handleSubmit(
                                                        this
                                                            ._handleChangePassword
                                                    )}
                                                >
                                                    <TextField
                                                        hintText=""
                                                        floatingLabelText={
                                                            LBLUSERS.newPassword
                                                        }
                                                        type="password"
                                                        errorText={
                                                            newPassword.touched &&
                                                            newPassword.error
                                                                ? newPassword.error
                                                                : ""
                                                        }
                                                        {...newPassword}
                                                    />
                                                    <br />
                                                    <TextField
                                                        hintText=""
                                                        floatingLabelText={
                                                            LBLUSERS.confirmPassword
                                                        }
                                                        type="password"
                                                        errorText={
                                                            confirmNewPassword.touched &&
                                                            confirmNewPassword.error
                                                                ? confirmNewPassword.error
                                                                : ""
                                                        }
                                                        {...confirmNewPassword}
                                                    />
                                                    <br />
                                                    <RaisedButton
                                                        label="Cambiar contraseña"
                                                        secondary
                                                        type="submit"
                                                    />
                                                </form>
                                            </Col>
                                        }
                                        permission="cgr_users_change_password"
                                        type="component"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    xs
                                    style={{ marginBottom: 20, marginTop: 10 }}
                                >
                                    <Divider />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <AuthComponent
                                        component={
                                            <RaisedButton
                                                label="Modificar información"
                                                secondary
                                                onClick={this.handleUpdateUser}
                                                style={{ margin: "10px" }}
                                            />
                                        }
                                        permission="cgr_users_update"
                                        type="component"
                                    />
                                </Col>
                                <Col>
                                    <AuthComponent
                                        component={
                                            <DeleteConfirmModal
                                                style={{ margin: "10px" }}
                                                tooltip="Eliminar usuario"
                                                title="Eliminar usuario"
                                                message={LBLUSERS.msg.deleteModal(
                                                    `${_.get(
                                                        user,
                                                        "person.name"
                                                    )} ${_.get(
                                                        user,
                                                        "person.lastName"
                                                    )}`
                                                )}
                                                fn={this.handleDeleteUser}
                                                args={[user]}
                                                type="button"
                                            />
                                        }
                                        permission="cgr_users_update"
                                        type="component"
                                    />
                                </Col>
                                <Col>
                                    <Col>
                                        <AuthComponent
                                            component={
                                                <RaisedButton
                                                    label="Enviar correo"
                                                    secondary
                                                    onClick={this.handleModalSendEmail.bind(
                                                        this,
                                                        user
                                                    )}
                                                    style={{ margin: "10px" }}
                                                />
                                            }
                                            permission="cgr_users_send_email"
                                            type="component"
                                        />
                                    </Col>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Dialog>
                <SendMessageModal />
                <SnackbarMessage />
                <FaqMessage />
                <About />
            </div>
        );
    }
}

const validate = (values) => {
    const errors = {};
    if (!values.newPassword) {
        errors.newPassword = LBLUSERS.validations.newPassword;
    } else if (
        !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(values.newPassword)
    ) {
        errors.newPassword = LBLUSERS.validations.passwordStrength;
    }
    if (!values.confirmNewPassword) {
        errors.confirmNewPassword = LBLUSERS.validations.confirmPassword;
    } else {
        if (/\s/.test(values.confirmNewPassword)) {
            errors.confirmNewPassword = LBLUSERS.validations.notBlanksSpaces;
        }
        if (!_.isEqual(values.newPassword, values.confirmNewPassword)) {
            errors.confirmNewPassword = LBLUSERS.validations.notEqualsPasswords;
        }
    }

    return errors;
};

function mapStateToProps({ userDialog }) {
    return {
        userDialog,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleDialog,
            toggleSnackbar,
            changePassword,
            deleteUser,
            openModalSendEmailUser,
            openModalSendEmail,
            removeRecord,
        },
        dispatch
    );
}

export default reduxForm(
    {
        form: "ChangePasswordFormUser",
        fields: ["newPassword", "confirmNewPassword"],
        validate,
    },
    mapStateToProps,
    mapDispatchToProps
)(UserDialog);
