import PropTypes from "prop-types";
import React, { Component } from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";
import SelectFieldFormat from "../fields/SelectFieldFormat/SelectFieldFormat";
import { Col } from "react-flexbox-grid";
import _ from "lodash";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";
import {
    buildCollectionForFilters,
    filterItemsAndBuildMap,
    getCurrentTypeFilter,
} from "./ProjectFunctions";
import { allowedListFilters } from "./ProjectDucks";
import BaseFilterDialog from "./BaseFilterDialog";
import assign from "lodash/assign";
import get from "lodash/get";
import sortBy from "lodash/sortBy";

const mapItem = (path, id, item, i) => {
    return <MenuItem key={i} value={item[id]} primaryText={item[path]} />;
};

const initialState = {
    open: false,
    data: [],
    map: {},
    selectedItemMap: {},
    value: undefined,
    type: undefined,
    operator: undefined,
};

const customContentStyle = {
    width: "320px",
    maxWidth: "none",
};

class FilterCreator extends Component {
    constructor(props) {
        super(props);
    }

    state = assign({}, initialState);

    static propTypes = {
        currentSelected: PropTypes.string.isRequired,
        data: PropTypes.array.isRequired,
        save: PropTypes.func.isRequired,
        allowedFilters: PropTypes.arrayOf(PropTypes.oneOf(allowedListFilters))
            .isRequired,
    };

    handleOpen = () => {
        this.setState(
            assign({}, initialState, {
                data: this.state.data,
                map: this.state.map,
                open: true,
                type: getCurrentTypeFilter(this.props.allowedFilters),
                item: "",
            })
        );
    };

    handleClose = () => {
        this.setState(
            assign({}, initialState, {
                data: this.state.data,
                map: this.state.map,
                open: false,
                type: getCurrentTypeFilter(this.props.allowedFilters),
            })
        );
    };

    handleSubmit = () => {
        // TODO clean all the state before closing.
        this.setState(
            assign({}, initialState, {
                data: this.state.data,
                map: this.state.map,
            })
        );

        this.props.save({
            item: this.state.item,
            operator: this.state.operator,
            value: this.state.value,
            columnFamily: get(this.state.selectedItemMap, "type"),
            condition: "AND", // Assumed that all operation performed on the filter are && operations.
        });
    };

    UNSAFE_componentWillReceiveProps({
        data,
        currentSelected,
        allowedFilters,
    }) {
        this.setState({
            data: _.filter(data, (item) => {
                const itemId = get(item, "id");
                return !_.isEqual(itemId, currentSelected);
            }),
            map: filterItemsAndBuildMap(data, currentSelected),
            type: getCurrentTypeFilter(allowedFilters),
        });
    }

    render() {
        const { allowedFilters } = this.props;
        const actions = [
            <FlatButton
                label="Cancelar"
                primary={true}
                onClick={this.handleClose}
            />,
            <FlatButton
                label="Confirmar"
                primary={true}
                onClick={this.handleSubmit}
            />,
        ];
        const configSelect = {
            onChange: (e, index, value) => {
                this.setState({
                    item: value,
                    selectedItemMap: get(this.state.map, value, {}),
                });
            },
            floatingLabelText: "Seleccione...",
            floatingLabelFixed: true,
            value: this.state.item,
        };

        const configSelectType = {
            onChange: (e, index, value) => {
                this.setState({
                    type: value,
                });
            },
            floatingLabelText: "Tipo de filtro...",
            floatingLabelFixed: true,
            value: this.state.type,
        };

        const configSelectValue = {
            onChange: (e, index, value) => {
                this.setState({
                    value: value,
                });
            },
            floatingLabelText: "Valor...",
            floatingLabelFixed: true,
            value: this.state.value,
        };
        const dataForFilter = sortBy(
            buildCollectionForFilters(this.state.data, this.state.type),
            "name"
        ).map(mapItem.bind(null, "name", "id"));
        const subItemsToSelect = sortBy(
            get(this.state.selectedItemMap, "subItems", []),
            "item"
        ).map(mapItem.bind(null, "item", "item"));
        return (
            <div>
                <RaisedButton
                    label="Agregar filtro"
                    style={{ marginTop: 10, marginBottom: 10 }}
                    onClick={this.handleOpen}
                />
                <Dialog
                    title="Crear Filtro..."
                    actions={actions}
                    modal={true}
                    open={this.state.open}
                    autoScrollBodyContent={true}
                    contentStyle={customContentStyle}
                >
                    <div>
                        <Col xs={12} md={4}>
                            <BaseFilterDialog
                                allowedFilters={allowedFilters}
                                config={configSelectType}
                            />
                        </Col>
                        <Col xs={12} md={4}>
                            <SelectFieldFormat config={configSelect}>
                                {dataForFilter}
                            </SelectFieldFormat>
                        </Col>
                        <Col xs={12} md={4}>
                            <SelectField
                                onChange={(e, index, value) => {
                                    this.setState({
                                        operator: value,
                                    });
                                }}
                                value={this.state.operator}
                                floatingLabelText="Operador..."
                                floatingLabelFixed={true}
                            >
                                <MenuItem value="=" primaryText="Igual" />
                                <MenuItem value="<>" primaryText="Diferente" />
                            </SelectField>
                        </Col>
                        <Col xs={12} md={4}>
                            <SelectFieldFormat config={configSelectValue}>
                                {subItemsToSelect}
                            </SelectFieldFormat>
                        </Col>
                    </div>
                </Dialog>
            </div>
        );
    }
}

export default FilterCreator;
