import PropTypes from "prop-types";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";
import { Row, Col } from "react-flexbox-grid";
import _ from "lodash";
import { browserHistory, Link } from "react-router";

import { blue700 } from "material-ui/styles/colors";
import { toggleSnackbar } from "../../actions/commons";
import SnackbarMessage from "../commons/snackBarMessage";
import { logOut } from "../dashboards/employee/actions";
import { changeFirstPassword } from "./actions";
import { LBLUSERS } from "../../constants/labels";
import FaqMessage from "../fields/FaqMessage/FaqMessage";
import About from "../fields/about/About";

class ModalChangeFirstPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: props.showChangePass,
        };

        this._handleChangePassword = this._handleChangePassword.bind(this);
        this._handleLogOut = this._handleLogOut.bind(this);
    }

    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    _handleLogOut() {
        console.log("logout");
        this.props.logOut();
        browserHistory.push("/login");
    }

    _handleChangePassword(formData) {
        const {
            changeFirstPassword,
            user,
            toggleSnackbar,
            resetForm,
        } = this.props;
        changeFirstPassword(user._id, _.get(formData, "newPassword")).then(
            (data) => {
                resetForm();
                toggleSnackbar(true, LBLUSERS.msg.successChangePassword);
                this.handleClose();
            },
            (reason) => {
                resetForm();
                toggleSnackbar(true, LBLUSERS.msg.errorChangePassword);
                console.log(reason);
            }
        );
    }

    UNSAFE_componentWillMount() {
        const { resetForm } = this.props;
        resetForm();
    }

    render() {
        const {
            fields: { newPassword, confirmNewPassword },
            handleSubmit,
            resetForm,
            user,
        } = this.props;
        return (
            <div>
                <Dialog
                    title={LBLUSERS.titleModalChangeFirstPass}
                    titleStyle={{ textAlign: "center", color: blue700 }}
                    modal
                    open={this.state.open}
                    onRequestClose={this.handleClose}
                >
                    <form onSubmit={handleSubmit(this._handleChangePassword)}>
                        <Row>
                            <Col xs={6}>
                                <p>
                                    <b>{LBLUSERS.name}:</b>{" "}
                                    {_.get(user, "person.name")}
                                </p>
                                <p>
                                    <b>{LBLUSERS.lastName}:</b>{" "}
                                    {_.get(user, "person.lastName")}
                                </p>
                                <p>
                                    <b>{LBLUSERS.id}:</b>{" "}
                                    {_.get(user, "person.id")}
                                </p>
                                <p>
                                    <b>{LBLUSERS.email}:</b>{" "}
                                    {_.get(user, "person.email")}
                                </p>
                            </Col>
                            <Col xs={6}>
                                <TextField
                                    hintText=""
                                    floatingLabelText={LBLUSERS.newPassword}
                                    type="password"
                                    errorText={
                                        newPassword.touched && newPassword.error
                                            ? newPassword.error
                                            : ""
                                    }
                                    {...newPassword}
                                />
                                <TextField
                                    hintText=""
                                    floatingLabelText={LBLUSERS.confirmPassword}
                                    type="password"
                                    errorText={
                                        confirmNewPassword.touched &&
                                        confirmNewPassword.error
                                            ? confirmNewPassword.error
                                            : ""
                                    }
                                    {...confirmNewPassword}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xsOffset={3} xs={9}>
                                <FlatButton
                                    label={LBLUSERS.buttons.exit}
                                    secondary
                                    onClick={this._handleLogOut}
                                />
                                <RaisedButton
                                    label={LBLUSERS.buttons.changePass}
                                    secondary
                                    type="submit"
                                    style={{ marginLeft: 30 }}
                                />
                            </Col>
                        </Row>
                    </form>
                </Dialog>
                <SnackbarMessage />
                <FaqMessage />
                <About />
            </div>
        );
    }
}

const validate = (values) => {
    const errors = {};
    if (!values.newPassword) {
        errors.newPassword = LBLUSERS.validations.newPassword;
    } else if (
        !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(values.newPassword)
    ) {
        errors.newPassword = LBLUSERS.validations.passwordStrength;
    }
    if (!values.confirmNewPassword) {
        errors.confirmNewPassword = LBLUSERS.validations.confirmPassword;
    } else if (!_.isEqual(values.newPassword, values.confirmNewPassword)) {
        errors.confirmNewPassword = LBLUSERS.validations.notEqualsPasswords;
    }

    return errors;
};

ModalChangeFirstPassword.propTypes = {
    showChangePass: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
};

function mapStateToProps({}, ownerProps) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleSnackbar,
            changeFirstPassword,
            logOut,
        },
        dispatch
    );
}

export default reduxForm(
    {
        form: "ChangePasswordForm",
        fields: ["newPassword", "confirmNewPassword"],
        validate,
    },
    mapStateToProps,
    mapDispatchToProps
)(ModalChangeFirstPassword);
