import React, { Component } from "react";
import MenuComponent from "../../reportComponents/menuComponent";

class MenuConfigComponent extends Component {
    state = {
        menu: [
            {
                text: "Título 1 - Fila del informe",
                code: "n1fd55gfd45",
                order: 0,
                level: 1,
            },
            {
                text: "Título 2 - Sección del informe",
                code: "h1fd55gfd45",
                order: 1,
                level: 2,
            },
            {
                text: "Título 3 - Elemento del informe",
                code: "n1fd55gfd46",
                order: 2,
                level: 3,
            },
            {
                text: "Título 2 - Sección del informe",
                code: "n1fd55gfd55",
                order: 3,
                level: 2,
            },
            {
                text: "Título 2 - Sección del informe",
                code: "n1fd55dfd45",
                order: 4,
                level: 2,
            },
            {
                text: "Título 3 - Elemento del informe",
                code: "n1fg55gfd45",
                order: 2,
                level: 3,
            },
            {
                text: "Título 3 - Elemento del informe",
                code: "n1fd59gfd45",
                order: 2,
                level: 3,
            },
        ],
    };

    render() {
        const { menu } = this.state;
        return <MenuComponent menu={menu} />;
    }
}

export default MenuConfigComponent;
