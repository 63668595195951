import React, { Component } from "react";
import RaisedButton from "material-ui/RaisedButton";
import { browserHistory } from "react-router";
import AuthComponent from "../../AuthComponent";

const styleButton = {
    marginBottom: 10,
    marginRight: 10,
    marginTop: 0,
    float: "right",
};

class ProjectCardActions extends Component {
    constructor(props) {
        super(props);
        this.handleButton = this.handleButton.bind(this);
        this._handleProject = this._handleProject.bind(this);
    }

    handleButton() {
        const { URLAction } = this.props;
        browserHistory.push(URLAction);
    }

    _handleProject() {
        const { companyId, projectId } = this.props;
        browserHistory.push(
            `/dashboard/company/project/advance/comp/${companyId}/${projectId}`
        );
    }

    render() {
        return (
            <AuthComponent
                component={
                    <RaisedButton
                        label="Estado actual"
                        secondary={true}
                        style={styleButton}
                        onClick={this.handleButton}
                    />
                }
                permission={"cgr_projects_status"}
                type={"component"}
            />
        );
    }
}

export default ProjectCardActions;
