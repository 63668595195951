import PropTypes from 'prop-types';
import React, { Component } from 'react';
import IconButton from 'material-ui/IconButton';
import {blue700, blue300} from 'material-ui/styles/colors';
import ContentAdd from 'material-ui/svg-icons/content/add';

let styleButton = {
        margin: 20,
        float: 'right',
        position: 'fixed',
        bottom: 10,
        right: 10,
        zIndex: 1100,
        padding: 4,
        borderRadius: '100%',
        background: blue700,
        boxShadow: '0 3px 6px #aaa',
        WebkitTransition: 'all 0.8s'
    },
    styleButtonHover = {
        margin: 20,
        float: 'right',
        position: 'fixed',
        bottom: 10,
        right: 10,
        zIndex: 1100,
        padding: 4,
        borderRadius: '100%',
        background: blue300,
        boxShadow: '0 3px 6px #aaa',
        WebkitTransition: 'all 0.8s',
    },
    styleContent = {
        background: '#FFF',
        zIndez: 999
    };

class FloatingActionButtonTooltip extends Component {

    constructor(props) {
        super(props);
        this.handleOnMouseEnter = this.handleOnMouseEnter.bind(this);
        this.handleOnMouseLeave = this.handleOnMouseLeave.bind(this);
        this.state = {
            hover: false
        };
    }

    handleOnMouseEnter(){
        this.setState({
            hover: true
        });
    }

    handleOnMouseLeave(){
        this.setState({
            hover: false
        });
    }

    render() {
        const self = this;
        const {
            textTooltip,
            onClick
            } = self.props;
        return (
            <div style={styleContent}
                 onMouseEnter={this.handleOnMouseEnter}
                 onMouseLeave={this.handleOnMouseLeave}>
                <div style={this.state.hover ? styleButtonHover : styleButton}>
                    <IconButton
                        disabled={this.props.disabled}
                        tooltip={textTooltip}
                        onClick={onClick}
                        tooltipPosition="top-left"
                        children={<ContentAdd color='#FFF' />}/>
                </div>
            </div>
        );
    }
}



FloatingActionButtonTooltip.propTypes = {
    textTooltip: PropTypes.string.isRequired,
    disabled: PropTypes.bool
};

export default FloatingActionButtonTooltip;
