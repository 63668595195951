import PropTypes from "prop-types";
import React, { Component } from "react";
import TableCell from "@material-ui/core/TableCell";
import _ from "lodash";
import { Link } from "react-router";
import IconButton from "material-ui/IconButton";
import CircularProgress from "material-ui/CircularProgress";
import { connect } from "react-redux";
import SettingsIcon from "material-ui/svg-icons/action/settings";
import PlayIcon from "material-ui/svg-icons/av/play-arrow";
import TreeGroupers from "material-ui/svg-icons/hardware/device-hub";
import BuildIcon from "material-ui/svg-icons/action/build";
import AuthComponent from "../../AuthComponent";
import { LBLSURVEYS } from "../../../constants/labels";
import DeleteEntityModal from "../../deleteEntityModal/component";
import CloneEntityModal from "../../CloneEntityModal/CloneEntityModal";
import ConfirmEntityModal from "../../ConfirmEntityModal/ConfirmEntityModal";
import {
    deleteSurvey,
    cloneSurvey,
    lockSurvey,
} from "../../../actions/surveys_form";
import { toggleSnackbar } from "../../../actions/commons";
import {
    removeRecord,
    LockRecord,
    getRecords,
    resetOrderRecord,
    setInSearchRecords,
} from "../actions";

class SurveyActionColumn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadClone: false,
        };
        this._handleDeleteSurvey = this._handleDeleteSurvey.bind(this);
        this._handleCloneSurvey = this._handleCloneSurvey.bind(this);
        this._handleLockSurvey = this._handleLockSurvey.bind(this);
    }

    _handleDeleteSurvey(data) {
        const { deleteSurvey, toggleSnackbar, removeRecord } = this.props;
        const idSurvey = _.get(data, "_id");
        deleteSurvey(idSurvey)
            .then(
                (data) => {
                    if (
                        _.get(data, "error") ||
                        _.get(data, "payload.status") !== 200
                    ) {
                        toggleSnackbar(true, LBLSURVEYS.msg.errorDelete);
                    } else {
                        removeRecord(idSurvey, "_id");
                        toggleSnackbar(true, LBLSURVEYS.msg.successDelete);
                    }
                },
                (reason) => {
                    toggleSnackbar(true, LBLSURVEYS.msg.errorDelete);
                }
            )
            .catch((data) => {
                toggleSnackbar(true, LBLSURVEYS.msg.errorDelete);
            });
    }

    _handleCloneSurvey(data) {
        const {
            cloneSurvey,
            toggleSnackbar,
            limit,
            resetOrderRecord,
            setInSearchRecords,
            getRecords,
        } = this.props;
        this.setState({ loadClone: true });
        cloneSurvey(_.get(data, "_id"))
            .then(
                (data) => {
                    this.setState({ loadClone: false });
                    if (
                        _.get(data, "error") ||
                        _.get(data, "payload.status") !== 200
                    ) {
                        toggleSnackbar(true, LBLSURVEYS.msg.errorClone);
                    } else {
                        resetOrderRecord();
                        setInSearchRecords();
                        toggleSnackbar(true, LBLSURVEYS.msg.successClone);
                        getRecords(
                            "/surveys",
                            1,
                            0,
                            limit,
                            "null",
                            0,
                            ["name"],
                            ""
                        );
                    }
                },
                (reason) => {
                    this.setState({ loadClone: false });
                    toggleSnackbar(true, LBLSURVEYS.msg.errorClone);
                }
            )
            .catch((data) => {
                this.setState({ loadClone: false });
                toggleSnackbar(true, LBLSURVEYS.msg.errorClone);
            });
    }

    _handleLockSurvey(data) {
        const { lockSurvey, toggleSnackbar, LockRecord } = this.props;
        const idSurvey = _.get(data, "_id");
        lockSurvey(idSurvey)
            .then(
                (data) => {
                    if (
                        _.get(data, "error") ||
                        _.get(data, "payload.status") !== 200
                    ) {
                        toggleSnackbar(true, LBLSURVEYS.msg.errorLock);
                    } else {
                        LockRecord(idSurvey);
                        toggleSnackbar(true, LBLSURVEYS.msg.successLock);
                    }
                },
                (reason) => {
                    toggleSnackbar(true, LBLSURVEYS.msg.errorLock);
                }
            )
            .catch((data) => {
                toggleSnackbar(true, LBLSURVEYS.msg.errorLock);
            });
    }

    render() {
        const { data, colSpan } = this.props;
        console.log({ data });
        const cs = _.isUndefined(colSpan) ? 1 : colSpan;
        const styleIcons = {};
        const styleIconsValid = _.isUndefined(_.get(data, "locked"))
            ? {}
            : _.get(data, "locked")
            ? { display: "none" }
            : {};
        const styleListUL = { listStyle: "none", margin: 0 };
        const styleListLI = { display: "inline-block" };
        return (
            <TableCell colSpan={cs}>
                <div style={{}}>
                    <ul style={styleListUL}>
                        <li style={styleListLI}>
                            <AuthComponent
                                component={
                                    <Link
                                        to={`/admin/surveys/info/${_.get(
                                            data,
                                            "_id"
                                        )}`}
                                        style={styleIcons}
                                    >
                                        <IconButton
                                            tooltip={
                                                LBLSURVEYS.tooltips.btnConfig
                                            }
                                            tooltipPosition="top-center"
                                            children={<SettingsIcon />}
                                        />
                                    </Link>
                                }
                                permission={"cgr_surveys_config"}
                                type={"component"}
                            />
                        </li>
                        <li style={styleListLI}>
                            <AuthComponent
                                component={
                                    <Link
                                        to={`/admin/surveys/groupers/${_.get(
                                            data,
                                            "_id"
                                        )}`}
                                        style={styleIcons}
                                    >
                                        <IconButton
                                            tooltip={
                                                LBLSURVEYS.tooltips
                                                    .btnConfigGroupers
                                            }
                                            tooltipPosition="top-center"
                                            children={<TreeGroupers />}
                                        />
                                    </Link>
                                }
                                permission={"cgr_surveys_config_groupers"}
                                type={"component"}
                            />
                        </li>
                        <li style={styleListLI}>
                            <AuthComponent
                                component={
                                    <Link
                                        to={`/admin/surveys/${_.get(
                                            data,
                                            "_id"
                                        )}/metadata`}
                                        style={styleIcons}
                                    >
                                        <IconButton
                                            tooltip={
                                                LBLSURVEYS.tooltips
                                                    .btnConfigMetadata
                                            }
                                            tooltipPosition="top-center"
                                            children={<BuildIcon />}
                                        />
                                    </Link>
                                }
                                permission={"cgr_surveys_metadata_edit"}
                                type={"component"}
                            />
                        </li>
                        <li style={styleListLI}>
                            <AuthComponent
                                component={
                                    <Link
                                        to={`/admin/surveys/appl/${_.get(
                                            data,
                                            "_id"
                                        )}/1`}
                                        style={styleIcons}
                                    >
                                        <IconButton
                                            tooltip={
                                                LBLSURVEYS.tooltips.btnPreview
                                            }
                                            tooltipPosition="top-center"
                                            children={<PlayIcon />}
                                        />
                                    </Link>
                                }
                                permission={"cgr_survey_preview"}
                                type={"component"}
                            />
                        </li>
                        <li style={styleListLI}>
                            {this.state.loadClone ? (
                                <CircularProgress
                                    size={25}
                                    thickness={2}
                                    style={{
                                        float: "left",
                                        padding: 0,
                                        marginBottom: -3,
                                    }}
                                />
                            ) : (
                                <AuthComponent
                                    component={
                                        <CloneEntityModal
                                            tooltip={
                                                LBLSURVEYS.tooltips.btnClone
                                            }
                                            title={LBLSURVEYS.titleClone}
                                            message={LBLSURVEYS.msg.cloneModal(
                                                _.get(data, "name")
                                            )}
                                            fn={() =>
                                                this._handleCloneSurvey(
                                                    this.props.data
                                                )
                                            }
                                            args={[data]}
                                        />
                                    }
                                    permission={"cgr_surveys_duplicate"}
                                    type={"component"}
                                />
                            )}
                        </li>
                        <li style={styleListLI}>
                            <AuthComponent
                                component={
                                    <ConfirmEntityModal
                                        style={styleIconsValid}
                                        tooltip={LBLSURVEYS.tooltips.btnlock}
                                        title={LBLSURVEYS.titleLock}
                                        message={LBLSURVEYS.msg.lockModal(
                                            _.get(data, "name")
                                        )}
                                        valid={_.get(data, "info.description")}
                                        fn={() => {
                                            console.log(
                                                "About to lock survey",
                                                { data: this.props.data }
                                            );
                                            this._handleLockSurvey(
                                                this.props.data
                                            );
                                        }}
                                        args={[data]}
                                    />
                                }
                                permission={"cgr_surveys_lock"}
                                type={"component"}
                            />
                        </li>
                        <li style={styleListLI}>
                            <AuthComponent
                                component={
                                    <DeleteEntityModal
                                        tooltip={LBLSURVEYS.tooltips.btnDelete}
                                        title={LBLSURVEYS.titleDelete}
                                        message={LBLSURVEYS.msg.deleteModal(
                                            _.get(data, "name")
                                        )}
                                        fn={this._handleDeleteSurvey}
                                        args={[data]}
                                    />
                                }
                                permission={"cgr_surveys_delete"}
                                type={"component"}
                            />
                        </li>
                    </ul>
                </div>
            </TableCell>
        );
    }
}

SurveyActionColumn.propTypes = {
    data: PropTypes.object.isRequired,
    colSpan: PropTypes.number,
};

function mapStateToProps({ tablePagination }, ownProps) {
    return {
        limit: tablePagination.get("limit"),
    };
}

export default connect(mapStateToProps, {
    deleteSurvey,
    cloneSurvey,
    lockSurvey,
    toggleSnackbar,
    removeRecord,
    LockRecord,
    getRecords,
    resetOrderRecord,
    setInSearchRecords,
})(SurveyActionColumn);
