// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";

const styles = {
    box: {
        height: "100%",
        width: "100%",
        padding: 6,
        boxSizing: "border-box",
    },
    content: {
        borderWidth: 2,
        borderStyle: "dashed",
        borderColor: "#d6d6d6",
        minHeight: 60,
        boxSizing: "border-box",
        fontSize: 16,
        color: "#d6d6d6",
        height: "100%",
    },
    table: {
        textAlign: "center",
        width: "100%",
        height: "100%",
        minHeight: 60,
        boxSizing: "border-box",
        borderCollapse: "collapse",
    },
    columnText: {
        padding: "5px 10px",
        boxSizing: "border-box",
    },
};

class EmptyContent extends Component {
    static propTypes = {
        hasElements: PropTypes.bool.isRequired,
        loading: PropTypes.bool,
        text: PropTypes.string,
        color: PropTypes.string.isRequired,
    };

    render() {
        const { hasElements, loading, text, color } = this.props;
        if (!hasElements && !loading) {
            return (
                <div style={{ ...styles.box }}>
                    <div style={{ ...styles.content, borderColor: color, color }}>
                        <table style={{ ...styles.table }}>
                            <tbody>
                                <tr>
                                    <td style={{ ...styles.columnText }}>{text}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            );
        }
        return <span />;
    }
}

export default EmptyContent;
