import React, {Component} from 'react';
import {Row, Col} from 'react-flexbox-grid';
import {getCompanyInfoFromPersonId, loadCompanyProjects, cleanCompanyProjects, setCompanyIdUser} from './actions';
import {blue700, orange700, green700, red700} from 'material-ui/styles/colors';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import ProjectCard from '../commons/ProjectCardComponent';
import ProjectCardActionsCompanies from './projectCardActionsCompany';
import {browserHistory} from 'react-router';
import moment from 'moment';
import _ from 'lodash';

const style = {
    paddingTop: 30
};

class CompaniesProjects extends Component {

    constructor(props) {
        super(props);
        this._renderProject = this._renderProject.bind(this);
    }

    UNSAFE_componentWillMount() {
        const {profile, getCompanyInfoFromPersonId, loadCompanyProjects, cleanCompanyProjects, setCompanyIdUser} = this.props;
        const personId = _.get(profile, 'person._id');
        cleanCompanyProjects();
        if (personId) {
          getCompanyInfoFromPersonId(personId)
                .then((response) => {
                    const companyId = _.get(response, 'payload.data.data._id');
                    setCompanyIdUser(companyId);
                    loadCompanyProjects(companyId);
                });
        } else {
            console.error('No hay un usuario logeado');
            browserHistory.push(`/login`);
        }
    }

    convertDates(openDate, closeDate){
        return `De ${moment(openDate).format('DD/MMM/YYYY')} hasta ${moment(closeDate).format('DD/MMM/YYYY')}`;
    }

    getColor(openDate, closeDate){
        if (!_.isUndefined(openDate) && !_.isUndefined(closeDate)){
            let initDate = moment(openDate).format('YYYY-MM-DD');
            let finishDate = moment(closeDate).format('YYYY-MM-DD');
            let currentDate = moment(new Date()).format('YYYY-MM-DD');
            if(moment(currentDate).isBefore(initDate)){
                return red700;
            } else if(moment(currentDate).isBefore(finishDate)){
                return orange700;
            } else {
                return green700;
            }
        } else {
            return blue700;
        }
    }

    _renderProject(project, idx) {
        const { company, idCompany } = this.props;
        const projectId = project._id;
        const projectName = project.name;
        const projectInactive = _.isUndefined(_.get(project, 'inactive')) ? 20 : _.get(project, 'inactive');
        const projectDescription = project.name;
        const UrlAdvance = `/dashboard/company/project/advance/comp/${idCompany}/${projectId}`;
        return (
            <Row key={idx}>
                <Col xs>
                    <ProjectCard
                        businessAcronym={projectName}
                        projectName={projectName}
                        projectId={projectId}
                        projectDescription={projectDescription}
                        projectDates={this.convertDates(_.get(project, 'time.openDate'), _.get(project, 'time.closeDate'))}
                        projectDays={_.get(project, 'time.dayWeek', [])}
                        projectInactive={projectInactive}
                        projectColor={this.getColor(_.get(project, 'time.openDate'), _.get(project, 'time.closeDate'))}
                        CardActionsComponent={ProjectCardActionsCompanies}
                        urlAction={UrlAdvance}
                        user={"company"}
                    />
                </Col>
            </Row>
        );
    }

    render() {
        const {projects, idCompany} = this.props;
        if(_.isNull(idCompany)){
            return (
                <div></div>
            );
        } else {
            return (
                <Row style={style}>
                    <Col xs={12} mdOffset={2} md={8}>
                        {projects.map(this._renderProject)}
                    </Col>
                </Row>
            );
        }
    }
}

function mapStateToProps({companiesDashboard, login}) {
    return {
        projects: companiesDashboard.get('projects'),
        company: companiesDashboard.get('company'),
        idCompany: companiesDashboard.get('idCompany'),
        profile: login.get('profile')
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCompanyInfoFromPersonId,
        loadCompanyProjects,
        cleanCompanyProjects,
        setCompanyIdUser
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesProjects);
