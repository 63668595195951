import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {
    loadSurveys,
    selectSurvey,
    LOADING_SURVEYS,
    LOAD_SURVEYS_FULFILLED,
    LOAD_SURVEYS_REJECTED
} from './excelGeneratorDucks';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import loadingData from './loadingData';

class SurveysTable extends Component {
    componentDidMount() {
        this.props.loadSurveys();
    }

    renderSurveyRow = (selected, survey, index) => {
        return (
            <TableRow key={survey._id} selected={index === selected}>
                <TableRowColumn style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'justify' }}>{survey.name}</TableRowColumn>
            </TableRow>
        );
    };

    render() {
        const {surveys, selectSurvey, selectedSurvey, status} = this.props;
        const componentSurveys =  (
            <Table onRowSelection={row => {
                const selectedSurveyIndexInTable = row[0];
                const selectedSurveyObj = surveys.get(selectedSurveyIndexInTable);
                selectSurvey(selectedSurveyIndexInTable, selectedSurveyObj);
            }}>
                <TableHeader displaySelectAll={false}>
                    <TableRow>
                        <TableHeaderColumn>Nombre</TableHeaderColumn>
                    </TableRow>
                </TableHeader>
                <TableBody deselectOnClickaway={false}>
                    {surveys.map(this.renderSurveyRow.bind(undefined, selectedSurvey))}
                </TableBody>
            </Table>
        );
        return loadingData(LOADING_SURVEYS, LOAD_SURVEYS_REJECTED, LOAD_SURVEYS_FULFILLED, status, "encuestas", componentSurveys);
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({loadSurveys, selectSurvey}, dispatch);
}

function mapStateToProps({surveysExcelGenerator}) {
    return {
        surveys: surveysExcelGenerator.get('surveys'),
        selectedSurvey: surveysExcelGenerator.get('selectedSurvey'),
        status: surveysExcelGenerator.get('statusSurveys')
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveysTable);
