import Types from "../dragComponentTypes";
import { chartTypes } from "../../reportComponents/types";
import { FILTER_GROUPS, SOCIO_DEMOGRAPHIC, QUESTION, RANKING, MENU, CHART, TEXT } from "../../analyticsReport/types";

export function getPropsDefault(type) {
    switch (type) {
        case Types.ROW:
            return { columns: 1, sections: [] };
        case Types.SECTION:
            return {
                columns: 1,
                style: {
                    borders: [
                        { position: "top", style: "solid", size: 0, color: "#EEE" },
                        { position: "right", style: "solid", size: 0, color: "#EEE" },
                        { position: "bottom", style: "solid", size: 0, color: "#EEE" },
                        { position: "left", style: "solid", size: 0, color: "#EEE" },
                    ],
                    content: {
                        borderWidth: 0,
                        borderStyle: "solid",
                        boxShadow: "0 0 0 0 #EEE",
                        borderRadius: 0,
                        backgroundColor: "#FFF",
                        padding: 0,
                    },
                },
            };
        case Types.CHART:
            return {
                type: CHART,
                chartProps: {
                    chartType: QUESTION,
                    chartPresentationType: chartTypes.PIE,
                    items: [],
                },
                style: {
                    borderWidth: 0,
                    borderStyle: "solid",
                    boxShadow: "0 0 0 0 #EEE",
                    borderRadius: 0,
                    backgroundColor: "#FFF",
                    padding: 0,
                },
            };
        case Types.DEMOGRAPHIC_CHART:
            return {
                type: CHART,
                chartProps: {
                    chartType: SOCIO_DEMOGRAPHIC,
                    chartPresentationType: chartTypes.PIE,
                    items: [],
                },
                style: {
                    borderWidth: 0,
                    borderStyle: "solid",
                    boxShadow: "0 0 0 0 #EEE",
                    borderRadius: 0,
                    backgroundColor: "#FFF",
                    padding: 0,
                },
            };
        case Types.FILTER_GROUPS:
            return {
                type: CHART,
                chartProps: {
                    chartType: FILTER_GROUPS,
                    chartPresentationType: chartTypes.PIE,
                    items: [],
                },
                style: {
                    borderWidth: 0,
                    borderStyle: "solid",
                    boxShadow: "0 0 0 0 #EEE",
                    borderRadius: 0,
                    backgroundColor: "#FFF",
                    padding: 0,
                },
            };
        case Types.RANKING:
            return {
                type: RANKING,
                style: {
                    borderWidth: 0,
                    borderStyle: "solid",
                    boxShadow: "0 0 0 0 #EEE",
                    borderRadius: 0,
                    backgroundColor: "#FFF",
                    padding: 0,
                },
            };
        case Types.MENU:
            return {
                type: MENU,
                style: {
                    borderWidth: 0,
                    borderStyle: "solid",
                    boxShadow: "0 0 0 0 #EEE",
                    borderRadius: 0,
                    backgroundColor: "#FFF",
                    padding: 0,
                },
            };
        case Types.TEXT:
            return {
                type: TEXT,
                style: {
                    borderWidth: 0,
                    borderStyle: "solid",
                    boxShadow: "0 0 0 0 #EEE",
                    borderRadius: 0,
                    backgroundColor: "#FFF",
                    padding: 0,
                },
            };
        case Types.TITLE:
        default:
            return {};
    }
}
