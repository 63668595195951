import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { List, ListItem } from 'material-ui/List';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getRamdonString } from '../../lib/util';
import { FetchGroupQuestionMetricParam, cleanGroupQuestionMetricParam } from '../../actions/surveys_form';
import LoadingPage from '../../components/commons/loadingPage';

const innerDivStyle = {
    paddingRight: 35
}, innerDivStyle2 = {
    paddingRight: 35,
    paddingTop: 0
}, nestedListStyle = {
    paddingLeft: 10
};

class TreeGroupers extends Component {

    constructor(props) {
        super(props);
        this.renderChildren = this.renderChildren.bind(this);
        this.renderHeadList = this.renderHeadList.bind(this);
        this.renderQuestions = this.renderQuestions.bind(this);
    }

    UNSAFE_componentWillMount() {
        const { cleanGroupQuestionMetricParam, FetchGroupQuestionMetricParam, idGrouper } = this.props;
        cleanGroupQuestionMetricParam();
        FetchGroupQuestionMetricParam(idGrouper);
    }

    renderOnlyQuestions(questions) {
        return _.map(questions, function (question, index) {
            return (
                <ListItem
                    key={getRamdonString()}
                    primaryText={_.get(question, 'title')}
                    innerDivStyle={innerDivStyle}
                    nestedListStyle={nestedListStyle}
                    primaryTogglesNestedList={true}
                    nestedItems={_.map(_.get(question, 'items', []), item =>
                                    <ListItem
                                        key={getRamdonString()}
                                        primaryText={`${_.get(item, 'name')} (${_.get(item, 'value')})`}
                                        disabled={true}
                                        innerDivStyle={innerDivStyle2}
                                        nestedListStyle={nestedListStyle}
                                    />
                                )}
                />
            );
        });
    }

    renderQuestions(children) {
        return (
            <ListItem
                key={getRamdonString()}
                primaryText={_.get(children, 'name')}
                secondaryText={`Nilvel ${_.get(children, 'level')}`}
                innerDivStyle={innerDivStyle}
                nestedListStyle={nestedListStyle}
                primaryTogglesNestedList={true}
                nestedItems={_.map(_.get(children, 'questions', []), question =>
                        <ListItem
                            key={getRamdonString()}
                            primaryText={question.title}
                            innerDivStyle={innerDivStyle}
                            nestedListStyle={nestedListStyle}
                            primaryTogglesNestedList={true}
                            nestedItems={_.map(_.get(question, 'items', []), item =>
                                            <ListItem
                                                key={getRamdonString()}
                                                primaryText={`${_.get(item, 'name')} (${_.get(item, 'value')})`}
                                                disabled={true}
                                                innerDivStyle={innerDivStyle2}
                                                nestedListStyle={nestedListStyle}
                                            />
                                        )}
                        />
                    )}
            />
        );
    }

    renderHeadList(children) {
        return (
            <ListItem
                key={getRamdonString()}
                primaryText={_.get(children, 'name')}
                secondaryText={`Nilvel ${_.get(children, 'level')}`}
                innerDivStyle={innerDivStyle}
                nestedListStyle={nestedListStyle}
                primaryTogglesNestedList={true}
                nestedItems={this.renderChildren(_.get(children, 'children', []))}
            />
        );
    }

    renderChildren(childrens) {
        const {
            renderHeadList,
            renderQuestions
            } = this;
        return _.map(childrens, function (children) {
            return (
                (_.get(children, 'level') !== 1)
                    ? renderHeadList(children)
                    : renderQuestions(children)
            );
        });
    }

    render() {
        const {
            groupersTree
        } = this.props;
        if (!_.isUndefined(groupersTree) && !_.isNull(groupersTree)) {
            if (_.size(_.get(groupersTree, 'children', [])) != 0 || _.size(_.get(groupersTree, 'questions', [])) != 0) {
                return (
                    <List>
                        <ListItem
                            primaryText={_.get(groupersTree, 'name', '')}
                            initiallyOpen={false}
                            number={2}
                            innerDivStyle={innerDivStyle}
                            primaryTogglesNestedList={true}
                            nestedListStyle={nestedListStyle}
                            secondaryTextLines={1}
                            secondaryText={`Nivel ${_.get(groupersTree, 'level')}`}
                            nestedItems={_.get(groupersTree, 'level') !== 1 ? this.renderChildren(_.get(groupersTree, 'children', [])) : this.renderOnlyQuestions(_.get(groupersTree, 'questions', []))}
                        />
                    </List>
                );
            } else {
                return (<div></div>);
            }
        } else {
            return (<LoadingPage visible={true} text="Cargando..."/>);
        }
    }
}

TreeGroupers.propTypes = {
    idGrouper: PropTypes.string.isRequired
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        cleanGroupQuestionMetricParam,
        FetchGroupQuestionMetricParam
    }, dispatch);
}

function mapStateToProps({ groupersQuestion }, ownerProps) {
    const grouper = _.get(groupersQuestion, 'grouper.data');
    return {
        groupersTree: grouper
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TreeGroupers);
