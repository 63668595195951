import React from "react";
import { useField } from "formik";
import _ from "lodash";
import TextField from "@material-ui/core/TextField";
import { checkQuestionId } from "../api-operations";
import { LBLQUESTIONS } from "Labels";

async function validateQuestionCode(value) {
    const questionExists = await checkQuestionId(value);
    return questionExists ? LBLQUESTIONS.validations.codeExist : undefined;
}

const QuestionCodeField = ({ label, disabled, ...props }) => {
    const validateFunc = disabled ? _.noop : validateQuestionCode;
    const [field, meta, fieldProps] = useField({
        ...props,
        validate: validateFunc,
    });
    const hasError = meta.error && meta.touched;
    const errorMessage = meta.error;
    const errorText = hasError ? errorMessage : null;

    return (
        <TextField
            {...field}
            {...props}
            disabled={disabled}
            label={label}
            error={hasError}
            helperText={errorText}
        />
    );
};

export default QuestionCodeField;
