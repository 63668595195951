// @flow

import React, { Component } from "react";
import { connect } from "react-redux";
import randomColor from "randomcolor";
import { bindActionCreators, compose } from "redux";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { Row, Col } from "react-flexbox-grid";
import ButtonBack from "../fields/buttonBack/buttonBack";
import { cleanState as cs, setColorsRender as scr, questionsProps as qp, indicatorsProps as ip } from "./ducks";
import type { Action } from "./types";
import ComponentsBar from "./componentsBar";
import DropContext from "./dropContext";
import AuthComponent from "../AuthComponent";
import ComponentConfiguration from "./componentConfiguration";

type Props = {
    cleanState: () => Action,
    setColorsRender: () => Action,
    questionsProps: () => Action,
    indicatorsProps: () => Action,
    params: {
        survey: string,
    },
};

class AnalyticsConfigurationDashboard extends Component<void, Props, void> {
    componentWillUnmount() {
        const { cleanState } = this.props;
        cleanState();
    }

    componentDidMount() {
        const { params: { survey }, setColorsRender, questionsProps, indicatorsProps } = this.props;
        setColorsRender(randomColor({ luminosity: "light", count: 30, hue: "blue" }));
        questionsProps(survey);
        indicatorsProps(survey);
    }

    render() {
        const { params: { survey } } = this.props;
        // <PageSelector survey={survey} />
        return (
            <AuthComponent
                component={
                    <Row style={{ marginTop: 15 }}>
                        <Col xs={6} md={2} lg={1}>
                            <ButtonBack url={`/admin/surveys/info/${survey}`} styleButton={{ zIndex: 1275 }} />
                            <ComponentsBar />
                        </Col>
                        <Col xs={12} md={10} mdOffset={1}>
                            <DropContext survey={survey} dropPath="rows" />
                        </Col>
                        <Col xs={6} md={2} lg={1}>
                            <ComponentConfiguration survey={survey} />
                        </Col>
                    </Row>
                }
                permission={"cgr_surveys_config_report"}
                type={"url"}
            />
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            cleanState: cs,
            setColorsRender: scr,
            questionsProps: qp,
            indicatorsProps: ip,
        },
        dispatch,
    );
}

export default compose(connect(undefined, mapDispatchToProps), DragDropContext(HTML5Backend))(
    AnalyticsConfigurationDashboard,
);
