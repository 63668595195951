import PropTypes from "prop-types";
import React, { Component } from "react";
import _ from "lodash";
import { blue600 } from "material-ui/styles/colors";
import ButtonDetailComponent from "./buttonDetailComponent";
import ChartComponent from "./chartComponent";
import BarStackedGroupFilterComponent from "./chartsComponents/barStackedGroupFilterComponent";
import BarStacked3DGroupFilterComponent from "./charts3DComponents/barStacked3DGroupFilterComponent";
import LegendStackedComponent from "./legends/legendStackedComponent";
import { colorState, chartTypes, arrayChart, arraySizeChart } from "./types";
import { pageTypes } from "../reportAdmon/types";

function updateData(data) {
    const keys = _.chain(data)
        .get(0)
        .keys()
        .omit(["total"])
        .value();
    return [
        _.reduce(
            keys,
            (sum, key) => {
                if (_.get(data, `0.${key}`, 0) <= 0) {
                    return sum;
                }
                return _.set(sum, key, _.get(data, `0.${key}`));
            },
            { total: _.get(data, "0.total", 0) },
        ),
    ];
}

function updateKeys(data, keysstacked) {
    const keys = _.chain(data)
        .get(0)
        .keys()
        .omit(["total"])
        .map(key => ({ key }))
        .value();
    return _.intersectionBy(keysstacked, keys, "key");
}

function getName(name, per) {
    switch (name) {
        case "deserted":
            return `Desertores (${per})`;
        case "finished":
            return `Finalizados (${per})`;
        case "started":
            return `En proceso (${per})`;
        case "pending":
            return `Pendientes (${per})`;
        default:
            return "";
    }
}

function getData(data, colors, colorsDefault) {
    const values = _.chain(data)
        // Se crea un array de objetos. Cada objeto con un "id" y su contador "count"
        .reduce((acc, current) => [...acc, ...current.values], [])
        // Para cada valor, se reempleza el conteo sumando el anterior con el actual
        .reduce(
            (acc, current) =>
                _.assign({}, acc, {
                    [_.get(current, "_id")]: (acc[_.get(current, "_id")] || 0) + _.get(current, "count", 0),
                }),
            {},
        )
        // Entrega un solo objeto con los valores únicos y sumandos
        .value();
    const keys = _.keys(values);
    let total = 0;
    _.forEach(keys, key => {
        total += _.get(values, key, 0);
    });
    if (total <= 0) {
        total = 100;
    }
    // Se asignan los valores para graficar
    return _.map(keys, (key, index) => {
        const value = _.get(values, key, 0) * 100 / total;
        const i = value.toFixed(1);
        return {
            value: _.get(values, key, 0),
            textValue: _.get(values, key, 0),
            color: _.get(colorsDefault, key, _.get(colors, index, "#FFF")),
            index: `${i}%`,
            textIndex: getName(key, `${i}%`),
            key,
        };
    });
}

function assignValuesStacked(value, index) {
    let total = 0;
    let values = {};
    _.forEach(_.get(value, "values", []), value => {
        values = _.set(values, _.get(value, "_id"), _.get(value, "count", 0));
        total += _.get(value, "count", 0);
    });
    let stacked = { total, index: `#${index + 1}`, textIndex: _.get(value, "id", "") };
    stacked = _.assign(stacked, values);
    _.forEach(["deserted", "finished", "started", "pending"], key => {
        stacked = _.set(stacked, key, _.get(stacked, key, 0));
    });
    return stacked;
}

function getKeysStacked(data, colors, colorsDefault) {
    const values = _.chain(data)
        .reduce((acc, current) => [...acc, ...current.values], [])
        .reduce(
            (acc, current) =>
                _.assign({}, acc, {
                    [_.get(current, "_id")]: (acc[_.get(current, "_id")] || 0) + _.get(current, "count", 0),
                }),
            {},
        )
        .value();
    const keys = _.keys(values);
    let total = 0;
    _.forEach(keys, key => {
        total += _.get(values, key, 0);
    });
    if (total <= 0) {
        total = 100;
    }
    return _.map(keys, (key, index) => {
        const value = _.get(values, key, 0) * 100 / total;
        const i = value.toFixed(1);
        return {
            key,
            text: getName(key, `${i}%`),
            color: _.get(colorsDefault, key, _.get(colors, index, "#FFF")),
        };
    });
}

function invalidData(type, data, dataStacked) {
    switch (type) {
        case chartTypes.PIE:
        case chartTypes.PIE3D:
        case chartTypes.PIE_DONUT:
        case chartTypes.PIE_DONUT3D:
        case chartTypes.BAR_HORIZONTAL:
        case chartTypes.BAR_HORIZONTAL3D:
        case chartTypes.BAR_VERTICAL:
        case chartTypes.BAR_VERTICAL3D:
            return _.reduce(data, (sum, value) => sum + _.get(value, "value", 0), 0) === 0;
        case chartTypes.STACKED_BAR:
        case chartTypes.STACKED_BAR3D:
            return (
                _.chain(dataStacked)
                    .map(value => _.get(value, "total", 0))
                    .reduce((sum, value) => sum + value, 0)
                    .value() === 0
            );
        default:
            return true;
    }
}

class SociodemographicComponent extends Component {
    state = {
        colorsDefault: colorState,
    };

    static propTypes = {
        colors: PropTypes.array,
        data: PropTypes.array.isRequired,
        textValues: PropTypes.string,
        textKeys: PropTypes.string,
        type: PropTypes.oneOf(arrayChart).isRequired,
        hasLegend: PropTypes.bool,
        hasDetail: PropTypes.bool,
        size: PropTypes.oneOf(arraySizeChart),
        projectId: PropTypes.string.isRequired,
    };

    renderChart(dataFormat, keysStacked, dataStacked, textValues, textKeys, type, hasLegend, size) {
        const validLegendStaked = _.get(dataStacked, "0.total", 0) > 0;
        const dataStackedUpdate = updateData(dataStacked);
        const keysStackedupdate = updateKeys(dataStackedUpdate, keysStacked);
        if (invalidData(type, dataFormat, dataStacked)) {
            return (
                <h3
                    style={{
                        padding: 10,
                        fontWeight: 400,
                        boxSizing: "border-box",
                        color: "#4c4c4c",
                        textAlign: "center",
                    }}
                >
                    No hay resultados que coincidan con el filtro
                </h3>
            );
        }
        switch (type) {
            case "STACKED_BAR":
                return (
                    <div className="print_pdf_component">
                        <BarStackedGroupFilterComponent data={dataStacked} keys={keysStacked} size={size} />
                        {validLegendStaked && (
                            <LegendStackedComponent data={dataStackedUpdate} size={size} keys={keysStackedupdate} />
                        )}
                    </div>
                );
            case "STACKED_BAR3D":
                return (
                    <div className="print_pdf_component">
                        <BarStacked3DGroupFilterComponent data={dataStacked} keys={keysStacked} size={size} />
                        {validLegendStaked && (
                            <LegendStackedComponent data={dataStackedUpdate} size={size} keys={keysStackedupdate} />
                        )}
                    </div>
                );
            default:
                return (
                    <ChartComponent
                        data={dataFormat}
                        keysStacked={keysStacked}
                        dataStacked={dataStacked}
                        textValues={textValues}
                        textKeys={textKeys}
                        type={type}
                        hasLegend={hasLegend}
                        size={size}
                    />
                );
        }
    }

    render() {
        const { data, textValues, textKeys, type, hasLegend, size, colors, hasDetail, projectId } = this.props;
        const { colorsDefault } = this.state;
        const visibleDetail = _.isUndefined(hasDetail) || _.isNull(hasDetail) ? false : hasDetail;
        const dataFormat = getData(data, colors, colorsDefault);
        const keysStacked = getKeysStacked(data, colors, colorsDefault);
        let valueStacked = {};
        let total = 0;
        _.forEach(dataFormat, d => {
            total += _.get(d, "value", 0);
            valueStacked = _.set(valueStacked, _.get(d, "key"), _.get(d, "value", 0));
        });
        const dataStacked = [_.set(valueStacked, "total", total)];

        if (_.size(dataStacked) > 0 && _.size(keysStacked) > 0) {
            return (
                <div className="print_pdf_component">
                    {this.renderChart(
                        dataFormat,
                        keysStacked,
                        dataStacked,
                        textValues,
                        textKeys,
                        type,
                        hasLegend,
                        size,
                    )}
                    <div style={{ height: visibleDetail ? 50 : 0 }}>
                        <div style={{ margin: "20px 0px 0px 0px", float: "right" }}>
                            <ButtonDetailComponent
                                projectId={projectId}
                                textTooltip={"Ver detalle socio-demográfico"}
                                visible={visibleDetail}
                                goPage={pageTypes.SOCIO_DEMOGRAPHIC}
                            />
                        </div>
                    </div>
                </div>
            );
        }
        return <span style={{ color: "#CCC" }} />;
    }
}

export default SociodemographicComponent;
