import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PureInputColorPicker from "./PureInputColorPicker";
import { updateComponent } from "../../ducks";
import WaitComponent from "../waitComponent";

class ColorComponent extends Component {
    static propTypes = {
        survey: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        page: PropTypes.string.isRequired,
        fieldProp: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        loadingConfiguration: PropTypes.bool.isRequired,
    };

    onChangeValueColor = color => {
        const { updateComponent, survey, page, path, fieldProp } = this.props;
        updateComponent(path, _.set({}, fieldProp, color), page, survey);
    };

    render() {
        const { value, title, loadingConfiguration } = this.props;
        if (loadingConfiguration) {
            return <WaitComponent />;
        }
        return (
            <table style={{ borderCollapse: "collapse", padding: 0, width: 362 }}>
                <tbody>
                    <tr>
                        <td style={{ padding: "16px 3px 3px 3px", verticalAlign: "top" }}>{title}</td>
                        <td style={{ padding: 3, width: 226 }}>
                            <PureInputColorPicker
                                style={{ marginTop: -20, marginBottom: 5 }}
                                onChangeFunc={this.onChangeValueColor}
                                field={{ value }}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateComponent }, dispatch);
}

function mapStateToProps({ analyticsReportsConfiguration }, ownProps) {
    const config = analyticsReportsConfiguration.get("pageConfiguration");
    const loadingConfiguration = analyticsReportsConfiguration.get("loadingConfiguration");
    return {
        value: _.get(
            config,
            `${_.get(ownProps, "path")}.${_.get(ownProps, "fieldProp")}`,
            _.get(ownProps, "defaultValue", "#FFF"),
        ),
        page: analyticsReportsConfiguration.get("page"),
        loadingConfiguration,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ColorComponent);
