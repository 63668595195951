import React, {Component} from 'react';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';
import {Row, Col} from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';
import { deleteFaq } from '../../actions/faq_form';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import {blue700} from 'material-ui/styles/colors';
import {LBLFAQS} from '../../constants/labels';
import FloatingActionButtonTooltip from '../fields/FloatingActionButtonTooltip/FloatingActionButtonTooltip';
import DeleteEntityModal from '../deleteEntityModal/component';
import _ from 'lodash';
import LoadingPage from '../../components/commons/loadingPage';
import AuthComponent from '../../components/AuthComponent';
import TablePagination from '../TablePagination/tablePagination';

const stylePaper = {
        marginTop: 20,
        marginBottom: 20
    };

class FaqGrid extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const body = [
            {
                field: "name",
                title: "Nombre ó pregunta",
                colspan: 3,
                filter: true
            },
            {
                actions: true,
                title: "Operaciones",
                colspan: 1
            }
        ];
        return (
            <Row>
                <AuthComponent
                    component={
                        <Col mdOffset={2} md={8} xs={12}>
                            <Paper style={stylePaper}>
                                <Row>
                                    <Col xs>
                                        <div style={{width: '100%', background: blue700, paddingTop: 5, paddingBottom:5, color: "#FFF"}}>
                                            <h1 style={{textAlign: 'center', fontWeight: 400}}>{LBLFAQS.titleGrid}</h1>
                                            <AuthComponent
                                                component={<Link to={`/admin/faq/create`}>
                                                                <FloatingActionButtonTooltip textTooltip={LBLFAQS.tooltips.btnCreate}/>
                                                            </Link>}
                                                permission={"cgr_faqs_create"}
                                                type={"component"}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <TablePagination
                                            name={`faqs`}
                                            URL={`/faqs`}
                                            version={1}
                                            body={body}
                                        />
                                    </Col>
                                </Row>
                            </Paper>
                        </Col>
                    }
                    permission={"cgr_faqs_list"}
                    type={"url"}
                />
            </Row>
        );
    }
}

function mapStateToProps({faq}) {
    return {faq: faq.get('all').toArray()};
}

export default connect(mapStateToProps, {deleteFaq})(FaqGrid);
