import React, { Component } from "react";
import _ from "lodash";
import { Row, Col } from "react-flexbox-grid";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Report from "../renderReport/report";
import { setFilterDefault, resetValuesFilterDefault, getValuesReportRenderPrint } from "../renderReport/ducks";
import { cleanSelectedFilters } from "../../analyticsReport/ducks";
import { parseSolrFiltersToSarinFilters } from "../../analyticsReport/item/functions";

function validateData(report) {
    if (_.size(_.get(report, "rows", [])) === 0) {
        return false;
    }
    return true;
}

function getKeyReport(currentPage) {
    switch (currentPage) {
        case "main_page":
            return "report_main";
        case "question_page":
            return "report_question";
        case "indicator_page":
            return "report_indicator";
        case "ranking_page":
            return "report_ranking";
        default:
            return "report";
    }
}

class RenderReportProject extends Component {
    static propTypes = {
        page: PropTypes.string.isRequired,
    };

    componentDidMount() {
        const {
            cleanSelectedFilters,
            resetValuesFilterDefault,
            getValuesReportRenderPrint,
            project,
            page,
        } = this.props;
        resetValuesFilterDefault();
        cleanSelectedFilters();
        getValuesReportRenderPrint(project, page);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { setFilterDefault, cleanSelectedFilters, project } = this.props;
        cleanSelectedFilters();
        setFilterDefault();
    }

    renderContent = () => {
        const { report, colorsDefault, project, survey } = this.props;
        if (validateData(report)) {
            return (
                <Report
                    surveyId={survey}
                    projectId={project}
                    rows={_.get(report, "rows", [])}
                    menu={_.get(report, "menu", [])}
                    maxRanking={_.get(report, "maxRanking", 5)}
                    colors={_.concat(_.get(report, "colors", []), colorsDefault)}
                />
            );
        }
        return <div />;
    };

    render() {
        const { report, colorsDefault, filters } = this.props;
        document.getElementsByClassName("container")[0].style.backgroundColor = "#FFF";
        document.body.style.backgroundColor = "#FFF";
        document.getElementsByTagName("body")[0].style.backgroundColor = "#FFF";
        if (_.isEqual(filters, [])) {
            return <div />;
        }
        return (
            <div style={{ width: 980, margin: "0 auto", background: "#FFF" }}>
                <Row>
                    <Col xs>{this.renderContent()}</Col>
                </Row>
            </div>
        );
    }
}

function mapStateToProps({ renderReport, analyticsReport }, ownProps) {
    const keyReport = getKeyReport(_.get(ownProps, "page"));
    return {
        report: renderReport.get(keyReport),
        colorsDefault: renderReport.get("colorsDefault"),
        filters: analyticsReport.get("filters").toJS(),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setFilterDefault,
            cleanSelectedFilters,
            resetValuesFilterDefault,
            getValuesReportRenderPrint,
        },
        dispatch,
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderReportProject);
