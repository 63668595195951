import {Map, List} from 'immutable';
import * as Rx from 'rxjs';
import _ from 'lodash';
import {axiosAPI_V2} from '../../../middleware/api';
import moment from 'moment';
import {
    MESSAGES_STATUS,
    getCounterOfUnreadMessages,
    getCounterOnUnreadMessagesSingleConversation,
    CLEAN_STATE,
    UPDATE_PING,
    UPDATE_PONG,
    STATUS_CHANGE
} from '../commons/functions';

const NOTIFICATION_USER_ADDED = 'nss/pegasus/notification-user-added';
const NOTIFICATION_USER_REMOVED = 'nss/pegasus/notification-user-removed';
const NOTIFICATION_USER_ADDED_FULFILLED = 'nss/pegasus/notification-user-added-fulfilled';
const NOTIFICATION_USER_ADDED_REJECTED = 'nss/pegasus/notification-user-added-rejected';
const NOTIFICATION_CONVERSATION_CLOSED = 'nss/pegasus/notification-conversation-closed';

const ADD_CONVERSATION = 'nss/pegasus/add-conversation-evaluator';
const ADD_CONVERSATION_FULFILLED = 'nss/pegasus/add-conversation-evaluator-fulfilled';
const ADD_CONVERSATION_REJECTED = 'nss/pegasus/add-conversation-evaluator-rejected';

const SELECT_ACTIVE_CONVERSATION = 'nss/pegasus/select-active-conversation';
const SELECT_USER_ACTIVE_CONVERSATION = 'nss/pegasus/select-user-active-conversation';
const ADD_MESSAGE_CONVERSATION = 'nss/pegasus/add-message-conversation';
const MARK_ALL_MESSAGES_READ = 'nss/pegasus/mark-all-messages-read';
const UPDATE_COUNT_MESSAGES_UNREAD = 'nss/pegasus/update-count-messages-unread';
const UPDATE_IN_ACTIVE_CONVERSATION = 'nss/pegasus/update-in-active-conversation';

const GET_PROJECTS_BY_REFEREE = 'nss/pegasus/get-projects-by-referee';
export const GET_PROJECTS_BY_REFEREE_REJECTED = 'nss/pegasus/get-projects-by-referee-rejected';
export const GET_PROJECTS_BY_REFEREE_REQUESTED = 'nss/pegasus/get-projects-by-referee-requested';
export const GET_PROJECTS_BY_REFEREE_FULFILLED = 'nss/pegasus/get-projects-by-referee-fulfilled';

export const CONVERSATION_STATUS = {
    ACTIVE: 'ACTIVE',
    CLOSED: 'CLOSED'
};


const initialState = Map({
    queue: List(),
    conversations: Map(),
    messages: Map(),
    active: undefined,
    userDataActive: undefined,
    lastPing: -1,
    lastPong: -1,
    status: undefined,
    lastUpdated: moment(),
    statusProjects: undefined,
    assignedProjects: []
});
export default function (state = initialState, action = {}) {
    switch (action.type) {
    case ADD_CONVERSATION_FULFILLED:
        const conversationIdToAdd = _.get(action, 'payload.data.conversation._id');
        const conversationObj = _.assign({}, action.payload.data, {
            uiStatus: CONVERSATION_STATUS.ACTIVE
        });
        return state.set('conversations', state.get('conversations').set(conversationIdToAdd, conversationObj));
    case NOTIFICATION_USER_ADDED_FULFILLED:
        return state.set('queue', state.get('queue').push(action.payload.data));
    case NOTIFICATION_USER_REMOVED:
        const indexToDeleteQueue = state.get('queue').findIndex(item => item._id === action.id);
        return state.set('queue', state.get('queue').delete(indexToDeleteQueue));
    case SELECT_ACTIVE_CONVERSATION:
        return state.set('active', _.get(action, 'id'));
    case UPDATE_IN_ACTIVE_CONVERSATION:
        const validconversationActive = state.get('active');
        const currentConversationId = _.get(action, 'conversation');
        return state.set('inConversationActive', _.isEqual(currentConversationId, validconversationActive));
    case SELECT_USER_ACTIVE_CONVERSATION:
        return state.set('userDataActive', _.get(action, 'userDataActive'));
    case UPDATE_COUNT_MESSAGES_UNREAD:
        return state.withMutations(map => {
            map.set('unreadCount', 0);
            map.set('updateScroll', false);
            map.set('inConversationActive', true);
        });
    case ADD_MESSAGE_CONVERSATION:
        const conversationID = _.get(action, 'message.conversation');
        const currentMessages = state.get('messages').get(conversationID) || List();
        const messageR = _.get(action, 'currentConversation', false) || _.isEqual(_.get(action, 'message.author'), _.get(action, 'self'));
        const messageWithStatus = _.assign({}, action.message, {
            uiStatus: messageR ? MESSAGES_STATUS.READ : MESSAGES_STATUS.UNREAD
        });
        const newMessageList = currentMessages.push(messageWithStatus);
        const updatedMessageMap = state.get('messages').set(conversationID, newMessageList);
        return state.set('messages', updatedMessageMap);
    case NOTIFICATION_CONVERSATION_CLOSED:
        const conversationIdToUpdate = action.id;
        const currentConversations = state.get('conversations');
        const conversationUpdated = _.assign({}, currentConversations.get(conversationIdToUpdate), {
            uiStatus: CONVERSATION_STATUS.CLOSED
        });
        return state.withMutations(map => {
            if (currentConversations.has(conversationIdToUpdate)) {
                map.set('conversations', currentConversations.set(conversationIdToUpdate, conversationUpdated));
            }
        });
    case MARK_ALL_MESSAGES_READ:
        const currentMessagesInThread = state.get('messages');
        const conversationId = _.get(action, 'conversation');
        const conversationActive = state.get('active');
        const inConversationActive = state.get('inConversationActive');
        const currentMessagesToMark = currentMessagesInThread.get(conversationId) || List();
        const unreadCount = getCounterOnUnreadMessagesSingleConversation(currentMessagesInThread, conversationId);
        const markedMessages = currentMessagesToMark.map(message => _.assign({}, message, {
            uiStatus: MESSAGES_STATUS.READ
        }));
        return state.withMutations(map => {
            const messagesUpdated = currentMessagesInThread.set(conversationId, markedMessages);
            map.set('messages', messagesUpdated);
            map.set('unreadCount', unreadCount);
            map.set('updateScroll', !inConversationActive);
        });

        return state.set('inConversationActive', _.isEqual(conversationId, conversationActive));
    case UPDATE_PING:
    case UPDATE_PONG:
        const key = action.type === UPDATE_PONG ? 'lastPong' : 'lastPing';
        return state.withMutations(map => {
            map.set(key, action.id);
            map.set('lastUpdated', moment())
        });
    case CLEAN_STATE:
        return initialState;
    case STATUS_CHANGE:
        return state.withMutations(map => {
            map.set('status', action.status);
            map.set('lastUpdated', moment())
        });
    case GET_PROJECTS_BY_REFEREE_REJECTED:
    case GET_PROJECTS_BY_REFEREE_REQUESTED:
    case GET_PROJECTS_BY_REFEREE_FULFILLED:
        return state.withMutations(map => {
            map.set('statusProjects', action.type);
            map.set('assignedProjects', _.get(action, 'payload.data', []));
        });
    default:
        return state;
    }
}

export const notificationUserAdded = ({projects, employee}) => {
    return {
        type: NOTIFICATION_USER_ADDED,
        projects,
        user: employee
    };
};

export const notificationRemoveUser = id => {
    return {
        type: NOTIFICATION_USER_REMOVED,
        id
    }
};

export const addActiveConversation = conversation => {
    return {
        type: ADD_CONVERSATION,
        conversation
    }
};


export const selectActiveConversation = id => {
    return {
        type: SELECT_ACTIVE_CONVERSATION,
        id
    };
};

export const selectUserActiveConversation = userDataActive => {
    return {
        type: SELECT_USER_ACTIVE_CONVERSATION,
        userDataActive: userDataActive
    };
};

export const updateInConversation = conversation => {
    return {
        type: UPDATE_IN_ACTIVE_CONVERSATION,
        conversation
    };
}

export const updateCountMessageUnread = () => {
    return {
        type: UPDATE_COUNT_MESSAGES_UNREAD
    }
}

export const markAllMessagesAsRead = conversation => {
    return {
        type: MARK_ALL_MESSAGES_READ,
        conversation
    };
};

export const addMessageToConversation = (message, self, currentConversation) => {
    return {
        type: ADD_MESSAGE_CONVERSATION,
        message,
        self,
        currentConversation
    }
};

export const notificationConversationClosed = id => {
    return {
        type: NOTIFICATION_CONVERSATION_CLOSED,
        id
    }
};

export const requestProjectsByReferees = (referee) => {
    return {
        type: GET_PROJECTS_BY_REFEREE,
        payload: {
            referee
        }
    };
};

export const httpRequestFulfilled = action => data => ({type: action, payload: {data}});
export const httpRequestRejected = (action, err) => ({type: action, payload: {err}});

export const updateQueueEpic$ = action$ => action$
    .ofType(NOTIFICATION_USER_ADDED)
    .mergeMap(action => {
        const promise = axiosAPI_V2.get(`/users/info/${action.user}/${action.projects}`);
        return Rx.Observable.fromPromise(promise)
            .map(response => response.data)
            .map(httpRequestFulfilled(NOTIFICATION_USER_ADDED_FULFILLED))
            .catch(error => Rx.Observable.of(httpRequestRejected(NOTIFICATION_USER_ADDED_REJECTED, _.get(error, 'response.data'))))
    });


export const updateConversationEpic = action$ => action$
    .ofType(ADD_CONVERSATION)
    .mergeMap(action => {
        const conversation = action.conversation;
        const promise = axiosAPI_V2.get(`/users/info/${conversation.employee}/${conversation.projectId}`);
        return Rx.Observable.fromPromise(promise)
            .map(response => response.data)
            .map(user => ({user, conversation}))
            .map(httpRequestFulfilled(ADD_CONVERSATION_FULFILLED))
            .catch(error => Rx.Observable.of(httpRequestRejected(ADD_CONVERSATION_REJECTED, _.get(error, 'response.data'))))
    });

export const getRefereeProjectsEpic = action$ => action$
    .ofType(GET_PROJECTS_BY_REFEREE)
    .mergeMap(action => {
        const refereeId = action.payload.referee;
        const promise = axiosAPI_V2.get(`/projects/referee/${refereeId}/ids`);
        return Rx.Observable.fromPromise(promise)
            .map(response => response.data)
            .map(httpRequestFulfilled(GET_PROJECTS_BY_REFEREE_FULFILLED))
            .catch(error => Rx.Observable.of(httpRequestRejected(GET_PROJECTS_BY_REFEREE_REJECTED, _.get(error, 'response.data'))))
            .startWith({type: GET_PROJECTS_BY_REFEREE_REQUESTED})
    });
