import { CLEAN_RESULTS_EMPLOYEE_FOR_REFEREE, SEARCH_RESULTS_EMPLOYEE_FOR_REFEREE, LOAD_RESULTS_EMPLOYEE_FOR_REFEREE } from './constants';
import { axiosAPI_V2 } from '../../middleware/api';
import _ from 'lodash';

export function loadResultsReferees(projectId, employeeId) {
    const request = axiosAPI_V2.get(`/results/referee/projectId/${projectId}/employee/${employeeId}`);
    return {
        type: SEARCH_RESULTS_EMPLOYEE_FOR_REFEREE,
        payload: request
    }
}

export function cleanResultsReferees(){
    return {
        type: CLEAN_RESULTS_EMPLOYEE_FOR_REFEREE
    }
}

export function inSearchResultsReferees(inSearch){
    return {
        type: LOAD_RESULTS_EMPLOYEE_FOR_REFEREE,
        meta: {
            inSearch: inSearch
        }
    }
}
