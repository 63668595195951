import PropTypes from "prop-types";
import React, { Component } from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import { buildItemIndex } from "./ProjectFunctions";
import _ from "lodash";

const styles = {
    radioButton: {
        marginTop: 16,
    },
};

const TextItem = ({ text }) => {
    return text ? (
        <h3 style={{ fontWeight: "normal" }}>
            <b>Seleccionado: </b>
            {text}
        </h3>
    ) : (
        <div></div>
    );
};

class ItemSelector extends Component {
    constructor(props) {
        super(props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            map: buildItemIndex(nextProps.data, "id"),
        });
    }

    state = {
        open: false,
        selected: this.props.value,
    };

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    mapData = (item, index) => {
        return (
            <RadioButton
                key={index}
                value={item.id}
                label={item.name}
                style={styles.radioButton}
            />
        );
    };

    static propTypes = {
        data: PropTypes.array.isRequired,
        onChange: PropTypes.func,
        value: PropTypes.string,
        currentValue: PropTypes.string,
    };

    render() {
        const { onChange, value } = this.props;
        const actions = [
            <FlatButton
                label="Cancelar"
                primary={true}
                onClick={this.handleClose}
            />,
            <FlatButton
                label="Confirmar"
                primary={true}
                keyboardFocused={true}
                onClick={() => {
                    onChange(this.state.selected);
                    this.handleClose();
                }}
            />,
        ];

        return (
            <div>
                <RaisedButton
                    label="Seleccionar elemento a graficar"
                    style={{ marginTop: 10, marginBottom: 10 }}
                    onClick={this.handleOpen}
                    disabled={!this.props.currentValue}
                />
                <TextItem
                    text={`${_.get(
                        this.state.map,
                        `${value}.name`,
                        "Ninguno"
                    )}`}
                />
                <Dialog
                    title="Elementos disponibles para gráficar"
                    actions={actions}
                    modal={false}
                    open={this.state.open}
                    onRequestClose={this.handleClose}
                    autoScrollBodyContent={true}
                >
                    <RadioButtonGroup
                        name="analyticsElements"
                        defaultSelected="not_light"
                        onChange={(event, value) => {
                            this.setState({
                                selected: value,
                            });
                        }}
                    >
                        {this.props.data.map(this.mapData)}
                    </RadioButtonGroup>
                </Dialog>
            </div>
        );
    }
}

export default ItemSelector;
