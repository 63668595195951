import React, { Component } from "react";
import AppBar from "material-ui/AppBar";
import IconButton from "material-ui/IconButton";
import NavigationMenu from "material-ui/svg-icons/navigation/menu";
import IconMenu from "material-ui/IconMenu";
import MoreVertIcon from "material-ui/svg-icons/navigation/more-vert";
import AccountCircleIcon from "material-ui/svg-icons/action/account-circle";
import HelpIcon from "material-ui/svg-icons/action/help";
import MenuItem from "material-ui/MenuItem";
import { browserHistory } from "react-router";
import _ from "lodash";
import { blue600 } from "material-ui/styles/colors";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toggleNav } from "../../../actions/left_navbar";
import { showFaq, setInSearch } from "../../../actions/faq_form";
import { logOut } from "./actions";
import { showModalChangePassword } from "../../ModalChangePassword/actions";
import { LBLAPP, NameEmployeeTest } from "../../../constants/labels";
import ModalChangeFirstPassword from "../../ModalChangePassword/componentChangeFirstPassword";
import ModalChangePassword from "../../ModalChangePassword/componentChangePassword";

class AppBarMenu extends Component {
    onTapLeft = () => {
        const { toggleNav, menuState } = this.props;
        const newState = !menuState;
        toggleNav(newState);
    };

    onTapFaq = () => {
        const { showFaq, setInSearch } = this.props;
        setInSearch();
        showFaq(true);
    };

    UNSAFE_componentWillMount() {
        const { profile } = this.props;
        const personId = _.get(profile, "person._id");
        if (!personId) {
            console.error("No hay un usuario logeado");
            browserHistory.replace(`/login`);
        }
    }

    _handleLogOut = () => {
        console.log("logout");
        this.props.logOut();
        browserHistory.push("/login");
    };

    _handleShowModalChangePassword = () => {
        this.props.showModalChangePassword(true);
    };

    render() {
        const { profile, loggedIn } = this.props;
        const firstPassword = _.get(profile, "firstPassword");
        const userName = loggedIn
            ? `${_.get(profile, "person.name")} ${_.get(
                  profile,
                  "person.lastName"
              )}`
            : NameEmployeeTest;
        // <MenuItem primaryText={LBLAPP.rigthMenu.refresh} onClick={()=>location.reload()}/>/*listStyle={{border: '1px solid #00F'}} menuStyle={{border: '1px solid #000', maxWidth: 300, overflow: 'hidden'}}*/
        return (
            <AppBar
                title={LBLAPP.title}
                iconElementLeft={
                    <IconButton onClick={this.onTapLeft}>
                        <NavigationMenu />
                    </IconButton>
                }
                iconElementRight={
                    <div>
                        <IconButton
                            touch
                            tooltip={LBLAPP.menu.frecuenceQuestions}
                            children={<HelpIcon color={"#FFF"} />}
                            onClick={this.onTapFaq}
                        />
                        <IconMenu
                            iconButtonElement={
                                <IconButton iconStyle={{ fill: "#FFF" }}>
                                    <MoreVertIcon />
                                </IconButton>
                            }
                            targetOrigin={{
                                horizontal: "right",
                                vertical: "top",
                            }}
                            anchorOrigin={{
                                horizontal: "right",
                                vertical: "top",
                            }}
                        >
                            <MenuItem
                                primaryText={userName}
                                rightIcon={
                                    <AccountCircleIcon color={blue600} />
                                }
                                disabled
                                innerDivStyle={{
                                    paddingBottom: 5,
                                    borderBottom: "1px solid #CCC",
                                }}
                                style={{ color: "#2E2E2E" }}
                            />
                            <MenuItem
                                primaryText={"Cambiar contraseña"}
                                onClick={this._handleShowModalChangePassword}
                            />
                            <MenuItem
                                onClick={this._handleLogOut}
                                primaryText="Cerrar sesión"
                            />
                            <MenuItem
                                primaryText="v2.1.4.3"
                                disabled
                                innerDivStyle={{ textAlign: "right" }}
                            />
                        </IconMenu>
                        <ModalChangeFirstPassword
                            showChangePass={firstPassword}
                            user={profile}
                        />
                        <ModalChangePassword user={profile} />
                    </div>
                }
                style={{ zIndex: 1400, position: "fixed" }}
            />
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleNav,
            showFaq,
            setInSearch,
            logOut,
            showModalChangePassword,
        },
        dispatch
    );
}

function mapStateToProps({ menu, login }) {
    return {
        menuState: menu.get("menuState"),
        profile: login.get("profile"),
        loggedIn: login.get("loggedIn"),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppBarMenu);
