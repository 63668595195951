import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DatePickerFormat from '../../fields/DatePickerFormat/DatePickerFormat';
import Title from './Title';
import {connect} from 'react-redux';
import _ from 'lodash';

const styleWidthContent = {
    minWidth: 240
};

class QuestionDate extends Component {

    constructor(props) {
        super(props);
        this._onChange = this._onChange.bind(this);
    }

    _onChange(e, value) {
        const { _handlerUpdateValue } = this.props;
        _handlerUpdateValue(e, value);
    }

    render() {
        const self = this;
        const {
            question,
            index,
            valueQuestion
        } = this.props;
        return (
            <div style={styleWidthContent}>
                <div style={{marginBottom: 30}}>
                    <Title question={_.has(question, 'fieldName') ? question : _.get(question, '_id')} index={index} />
                </div>
                <div style={{ maxWidth: 250 }}>
                    <DatePickerFormat
                        datePickerConfig={{
                            hintText:"Seleccione la fecha",
                            mode:"landscape",
                            onChange: this._onChange,
                            value: valueQuestion
                        }}

                        stylefloatingLabelText={{marginTop: -10, marginBottom: -10}}
                    />
                </div>
            </div>
        );
    }
}

QuestionDate.propTypes = {
    question: PropTypes.object.isRequired
};

function mapStateToProps({ questionsSurvey }, ownerProps) {
    return {
        valueQuestion: _.get(questionsSurvey.get('answered').toObject(), _.get(ownerProps, 'idQuestion'))
    };
}

export default connect(mapStateToProps)(QuestionDate);
