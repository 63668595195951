import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Step, Stepper, StepLabel, StepContent } from "material-ui/Stepper";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import Paper from "material-ui/Paper";
import { Row, Col } from "react-flexbox-grid";
import SurveysTable from "./surveysTable";
import CompaniesTable from "./companiesTable";
import ProjectsTable from "./projectsTable";
import FormDetails from "./FormDetails";
import VerifyAndSend from "./VerifyAndSend";
import AppBar from "material-ui/AppBar";
import { resetGenerator } from "./excelGeneratorDucks";
import AuthComponent from "../AuthComponent";

class ExcelGenerator extends Component {
    state = {
        finished: false,
        stepIndex: 0,
    };

    componentWillUnmount() {
        this.props.resetGenerator();
    }

    handleNext = () => {
        const { stepIndex } = this.state;
        this.setState({
            stepIndex: stepIndex + 1,
            finished: stepIndex >= 4,
        });
    };

    handlePrev = () => {
        const { stepIndex } = this.state;
        if (stepIndex > 0) {
            this.setState({ stepIndex: stepIndex - 1 });
        }
    };

    renderStepActions(step, validationNext) {
        const { stepIndex } = this.state;

        return (
            <div style={{ margin: "12px 0" }}>
                <RaisedButton
                    label={stepIndex === 4 ? "Finalizar" : "Siguiente"}
                    disableTouchRipple={true}
                    disableFocusRipple={true}
                    disabled={validationNext}
                    primary={true}
                    onClick={this.handleNext}
                    style={{ marginRight: 12 }}
                />
                {step > 0 && (
                    <FlatButton
                        label="Atrás"
                        disabled={stepIndex === 0}
                        disableTouchRipple={true}
                        disableFocusRipple={true}
                        onClick={this.handlePrev}
                    />
                )}
            </div>
        );
    }

    render() {
        const { finished, stepIndex } = this.state;
        const { selectedSurvey, resetGenerator } = this.props;
        const resetCompleteStatus = () => {
            resetGenerator();
            this.setState({ stepIndex: 0, finished: false });
        };
        return (
            <div style={{ marginTop: 30, marginBottom: 30 }}>
                <AuthComponent
                    component={
                        <Row>
                            <Col md={8} mdOffset={2}>
                                <Paper>
                                    <AppBar
                                        title="Generación de reportes de soluciones"
                                        showMenuIconButton={false}
                                    />
                                    <Stepper
                                        activeStep={stepIndex}
                                        orientation="vertical"
                                    >
                                        <Step>
                                            <StepLabel>
                                                Seleccionar prueba
                                            </StepLabel>
                                            <StepContent>
                                                <SurveysTable />
                                                {this.renderStepActions(
                                                    0,
                                                    selectedSurvey === -1
                                                )}
                                            </StepContent>
                                        </Step>
                                        <Step>
                                            <StepLabel>
                                                Seleccionar compañías
                                            </StepLabel>
                                            <StepContent>
                                                <CompaniesTable />
                                                {this.renderStepActions(1)}
                                            </StepContent>
                                        </Step>
                                        <Step>
                                            <StepLabel>
                                                Seleccionar proyectos
                                            </StepLabel>
                                            <StepContent>
                                                <ProjectsTable />
                                                {this.renderStepActions(2)}
                                            </StepContent>
                                        </Step>
                                        <Step>
                                            <StepLabel>
                                                Detalles del reporte
                                            </StepLabel>
                                            <StepContent>
                                                <FormDetails
                                                    handleNext={this.handleNext}
                                                    handlePrev={this.handlePrev}
                                                />
                                            </StepContent>
                                        </Step>
                                        <Step>
                                            <StepLabel>
                                                Verificar y enviar
                                            </StepLabel>
                                            <StepContent>
                                                <VerifyAndSend
                                                    handleBack={this.handlePrev}
                                                    handleReset={
                                                        resetCompleteStatus
                                                    }
                                                />
                                            </StepContent>
                                        </Step>
                                    </Stepper>
                                </Paper>
                            </Col>
                        </Row>
                    }
                    permission={"cgr_reports_solutions"}
                    type={"url"}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ surveysExcelGenerator }) => {
    return {
        selectedSurvey: surveysExcelGenerator.get("selectedSurvey"),
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            resetGenerator,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ExcelGenerator);
