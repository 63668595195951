import PropTypes from "prop-types";
import React, { Component } from "react";
import { TableHeaderColumn, TableRow } from "material-ui/Table";
import { connect } from "react-redux";
import _ from "lodash";
import {
    getRecords,
    resetOrderRecord,
    addMassiveSelected,
    removeMassiveSelected,
    setInSearchRecords,
    cleanSelected,
} from "./actions";
import RaisedButton from "material-ui/RaisedButton";
import CircularProgress from "material-ui/CircularProgress";
import TextField from "material-ui/TextField";

class TableHeadColumnFilter extends Component {
    constructor(props) {
        super(props);
        this._handleCleanOrderAndFilterRecords = this._handleCleanOrderAndFilterRecords.bind(
            this
        );
        this._handleFilterRecords = this._handleFilterRecords.bind(this);
        this.onChangeTextFilter = this.onChangeTextFilter.bind(this);
        this._handleSelectedAllRecords = this._handleSelectedAllRecords.bind(
            this
        );
        this._renderSelectedActions = this._renderSelectedActions.bind(this);
        this._renderCleanSelected = this._renderCleanSelected.bind(this);
        this._handleCleanSelected = this._handleCleanSelected.bind(this);
        this.renderButtonsFilter = this.renderButtonsFilter.bind(this);
        this.state = {
            text: "",
            isAdd: false,
        };
    }

    _handleCleanOrderAndFilterRecords() {
        console.log("about to clean");
        const {
            URL,
            version,
            limit,
            skip,
            getRecords,
            setInSearchRecords,
            resetOrderRecord,
            extra,
            body,
        } = this.props;
        const arrayFields = [];
        _.map(body, (field) => {
            if (!_.has(field, "actions") && _.has(field, "filter")) {
                if (_.get(field, "filter")) {
                    arrayFields.push(_.get(field, "field"));
                }
            }
        });
        if (_.size(arrayFields) === 1) {
            arrayFields.push("default");
        }
        this.setState({ text: "" });
        resetOrderRecord();
        setInSearchRecords();
        if (!_.isUndefined(extra) && !_.isNull(extra)) {
            getRecords(
                URL,
                version,
                0,
                limit,
                "null",
                0,
                arrayFields,
                "",
                extra
            );
        } else {
            getRecords(URL, version, 0, limit, "null", 0, arrayFields, "");
        }
    }

    _handleFilterRecords() {
        const {
            URL,
            version,
            skip,
            limit,
            orderName,
            orderType,
            arrayFields,
            getRecords,
            setInSearchRecords,
            extra,
        } = this.props;
        setInSearchRecords();
        if (!_.isUndefined(extra) && !_.isNull(extra)) {
            getRecords(
                URL,
                version,
                0,
                limit,
                orderName,
                orderType,
                arrayFields,
                this.state.text,
                extra
            );
        } else {
            getRecords(
                URL,
                version,
                0,
                limit,
                orderName,
                orderType,
                arrayFields,
                this.state.text
            );
        }
    }

    _onKeyUpFilterRecords(e, value) {
        if (e.keyCode === 13) {
            this._handleFilterRecords();
        }
    }

    _handleSelectedAllRecords() {
        const {
            records,
            fieldSelection,
            limit,
            addMassiveSelected,
            removeMassiveSelected,
        } = this.props;
        let isAdd = 0;
        const idsMassive = _.map(records, (record) => {
            if (_.get(record, "selected")) {
                isAdd++;
            }
            return _.get(record, fieldSelection);
        });
        if (isAdd !== _.size(records)) {
            addMassiveSelected(idsMassive);
        } else {
            removeMassiveSelected(idsMassive);
        }
    }

    _renderCleanSelected() {
        const {
            countSelected,
            fieldSelection,
            selection,
            inSearch,
        } = this.props;
        const allowedSelection =
            _.isUndefined(selection) ||
            _.isNull(selection) ||
            _.isUndefined(fieldSelection) ||
            _.isNull(fieldSelection)
                ? false
                : selection;
        if (allowedSelection && countSelected > 0 && !inSearch) {
            return (
                <div style={{ display: "inline-block" }}>
                    <RaisedButton
                        label={"Limpiar selección"}
                        secondary
                        style={{ margin: 5, display: "inline-block" }}
                        onClick={this._handleCleanSelected}
                    />
                </div>
            );
        }
        return <div />;
    }

    _handleCleanSelected() {
        const { cleanSelected } = this.props;
        cleanSelected();
    }

    _renderSelectedActions() {
        const {
            selection,
            fieldSelection,
            records,
            limit,
            inSearch,
        } = this.props;
        const allowedSelection =
            _.isUndefined(selection) ||
            _.isNull(selection) ||
            _.isUndefined(fieldSelection) ||
            _.isNull(fieldSelection)
                ? false
                : selection;
        if (allowedSelection && !inSearch) {
            let isAdd = 0;
            const idsMassive = _.map(records, (record) => {
                if (_.get(record, "selected")) {
                    isAdd++;
                }
            });
            const textButton =
                isAdd !== _.size(records)
                    ? `Seleccionar todos`
                    : `Deseleccionar todos`;
            return (
                <div style={{ display: "inline-block" }}>
                    <RaisedButton
                        label={textButton}
                        secondary
                        style={{ margin: 5, display: "inline-block" }}
                        onClick={this._handleSelectedAllRecords}
                    />
                </div>
            );
        }
        return <div />;
    }

    onChangeTextFilter(e, value) {
        this.setState({ text: value });
    }

    renderButtonsFilter() {
        const { inSearch } = this.props;
        const disableFilter =
            _.isNull(this.state.text) ||
            _.isUndefined(this.state.text) ||
            _.isEqual(this.state.text, "");
        const disableClean = false;
        if (inSearch) {
            return (
                <div style={{ height: 30, display: "inline-block" }}>
                    <CircularProgress
                        size={25}
                        thickness={2}
                        style={{ padding: 0, margin: 0, position: "absolute" }}
                    />
                </div>
            );
        }
        return (
            <div style={{ margin: 5, display: "inline-block" }}>
                <RaisedButton
                    label="Filtrar"
                    secondary
                    style={{ margin: 5, display: "inline-block" }}
                    onClick={this._handleFilterRecords}
                    disabled={disableFilter}
                />
                <RaisedButton
                    label="Limpiar"
                    secondary
                    style={{ margin: 5, display: "inline-block" }}
                    onClick={this._handleCleanOrderAndFilterRecords}
                    disabled={disableClean}
                />
            </div>
        );
    }

    render() {
        const { fieldFilter, orderName, body } = this.props;
        let colSpanHead = 0;
        let nameTextFilter = "";
        _.map(body, (field) => {
            colSpanHead += _.get(field, "colspan", 1);
            if (!_.has(field, "actions") && _.has(field, "filter")) {
                if (_.get(field, "filter") && _.isEqual(nameTextFilter, "")) {
                    nameTextFilter = _.get(field, "title");
                } else if (
                    _.get(field, "filter") &&
                    !_.isEqual(nameTextFilter, "")
                ) {
                    nameTextFilter = "varios";
                }
            }
        });

        return (
            <TableRow>
                <TableHeaderColumn
                    colSpan={colSpanHead}
                    style={{ textAlign: "left" }}
                >
                    <div style={{ display: "inline-block" }}>
                        <TextField
                            hintText={`Buscar por: ${nameTextFilter}`}
                            style={{
                                margin: "5px 5px 5px 0",
                                width: 230,
                                display: "inline-block",
                            }}
                            type="text"
                            value={this.state.text}
                            onChange={this.onChangeTextFilter}
                            onKeyUp={this._onKeyUpFilterRecords.bind(this)}
                        />
                        {this.renderButtonsFilter()}
                        {this._renderSelectedActions()}
                        {this._renderCleanSelected()}
                    </div>
                </TableHeaderColumn>
            </TableRow>
        );
    }
}

function mapStateToProps({ tablePagination }, ownProps) {
    return {
        limit: tablePagination.get("limit"),
        records: tablePagination.get("records"),
        fieldSelection: tablePagination.get("fieldSelection"),
        skip: tablePagination.get("skip"),
        orderName: tablePagination.get("orderName"),
        orderType: tablePagination.get("orderType"),
        arrayFields: tablePagination.get("arrayFields"),
        inSearch: tablePagination.get("inSearch"),
        countSelected: _.size(tablePagination.get("selected")),
    };
}

TableHeadColumnFilter.propTypes = {
    body: PropTypes.array.isRequired,
    URL: PropTypes.string.isRequired,
    version: PropTypes.number.isRequired,
    extra: PropTypes.object,
    selection: PropTypes.bool,
};

export default connect(mapStateToProps, {
    getRecords,
    resetOrderRecord,
    addMassiveSelected,
    removeMassiveSelected,
    setInSearchRecords,
    cleanSelected,
})(TableHeadColumnFilter);
