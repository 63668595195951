import PropTypes from "prop-types";
import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { blue600 } from "material-ui/styles/colors";
import { bindActionCreators } from "redux";
import ChartComponent from "./chartComponent";
import BarStackedGroupFilterComponent from "./chartsComponents/barStackedGroupFilterComponent";
import BarStacked3DGroupFilterComponent from "./charts3DComponents/barStacked3DGroupFilterComponent";
import LegendStackedComponent from "./legends/legendStackedComponent";
import LegendComponent from "./legends/legendComponent";
import { getRamdonStringUniq, getRamdonString } from "../../lib/util";
import { selectedFilters } from "../analyticsReport/ducks";
import {
    colorState,
    arrayChart,
    arraySizeChart,
    arrayAlign,
    chartTypes,
} from "./types";

const styles = {
    titleGroup: {
        fontWeight: 400,
        textAlign: "right",
        margin: "2px 0px 5px 0px",
        padding: "4px 0 0 0",
        fontSize: 12,
        width: 130,
        display: "inline-block",
        verticalAlign: "top",
        cursor: "pointer",
    },
    titleNoFilter: {
        fontWeight: 400,
        cursor: "pointer",
        textAlign: "left",
        fontSize: 12,
        margin: "2px 0",
    },
};

function updateData(data) {
    const keys = _.chain(data).get(0).keys().omit(["total"]).value();
    return [
        _.reduce(
            keys,
            (sum, key) => {
                if (_.get(data, `0.${key}`, 0) <= 0) {
                    return sum;
                }
                return _.set(sum, key, _.get(data, `0.${key}`));
            },
            { total: _.get(data, "0.total", 0) }
        ),
    ];
}

function updateKeys(data, keysstacked) {
    const keys = _.chain(data)
        .get(0)
        .keys()
        .omit(["total"])
        .map((key) => ({ key }))
        .value();
    return _.intersectionBy(keysstacked, keys, "key");
}

function getNumFiltersSelected(numFilter) {
    if (numFilter === 1) {
        return "Hay 1 filtro realizado.";
    } else if (numFilter > 1) {
        return `Hay ${numFilter} filtros realizados.`;
    }
    return "";
}

function isSelectedFilter(id, array) {
    return _.indexOf(array, id) !== -1;
}

function highlightSelected(id, array) {
    if (isSelectedFilter(id, array) || _.size(array) === 0) {
        return 1;
    }
    return 0.6;
}

function getName(name, countData, text = "") {
    switch (name) {
        case "deserted":
            return countData > 1 ? "Desertores" : `Desertores ${text}`;
        case "finished":
            return countData > 1 ? "Finalizados" : `Finalizados ${text}`;
        case "started":
            return countData > 1 ? "En proceso" : `En proceso ${text}`;
        case "pending":
            return countData > 1 ? "Pendientes" : `Pendientes ${text}`;
        default:
            return "";
    }
}

function getDataLegend(data, colors, colorsDefault) {
    const countData = _.size(data);
    const values = _.chain(data)
        // Se crea un array de objetos. Cada objeto con un "id" y su contador "count"
        .reduce((acc, current) => [...acc, ...current.values], [])
        // Para cada valor, se reempleza el conteo sumando el anterior con el actual
        .reduce(
            (acc, current) =>
                _.assign({}, acc, {
                    [_.get(current, "_id")]:
                        (acc[_.get(current, "_id")] || 0) +
                        _.get(current, "count", 0),
                }),
            {}
        )
        // Entrega un solo objeto con los valores únicos y sumandos
        .value();
    const keys = _.keys(values);
    const total = _.reduce(keys, (sum, n) => sum + _.get(values, n, 0), 0);
    // Se asignan los valores para graficar
    return _.map(keys, (key, index) => {
        const full = (_.get(values, key, 0) * 100) / total;
        const i = full.toFixed(1);
        return {
            value: _.get(values, key, 0),
            textValue: _.get(values, key, 0),
            color: _.get(colorsDefault, key, _.get(colors, index, "#FFF")),
            textIndex: getName(key, countData, `(${i}%)`),
            key,
        };
    });
}

function getData(data, colors, colorsDefault) {
    const countData = _.size(data);
    const values = _.chain(data)
        .get("0.values", [])
        .map((val) => _.set({}, _.get(val, "_id"), _.get(val, "count")))
        .reduce((sum, n) => _.assign(sum, n), {})
        .value();
    const keys = _.keys(values);
    const total = _.reduce(keys, (sum, n) => sum + _.get(values, n, 0), 0);
    return _.map(_.get(data, "0.values", []), (value, index) => {
        const full = (_.get(value, "count", 0) * 100) / total;
        const i = full.toFixed(1);
        return {
            value: _.get(value, "count", 0),
            textValue: _.get(value, "count", 0),
            color: _.get(
                colorsDefault,
                _.get(value, "_id"),
                _.get(colors, index, "#FFF")
            ),
            index: `(${i}%)`, // `#${index + 1}`
            textIndex: getName(_.get(value, "_id"), countData, `(${i}%)`),
        };
    });
}

function getCountValuesData(data) {
    return _.reduce(
        _.get(data, "0.values", []),
        (sum, value) => sum + _.get(value, "count", 0),
        0
    );
}

function getValueDataStacked(count, total) {
    if (total === 0) {
        return 0;
    }
    return (count * 100) / total;
}

function getDataStacked(data, colors, colorsDefault) {
    const countData = _.size(data);
    const keysStacked = [];
    const arrayCodes = [];
    let dataStacked = {};
    let total = 0;
    _.forEach(_.get(data, "0.values", []), (value, index) => {
        const code = getRamdonStringUniq(arrayCodes);
        arrayCodes.push(code);
        keysStacked.push({
            key: code,
            text: getName(_.get(value, "_id"), countData),
            color: _.get(
                colorsDefault,
                _.get(value, "_id"),
                _.get(colors, index, "#FFF")
            ),
        });
        total += _.get(value, "count", 0);
        dataStacked = _.set(dataStacked, code, _.get(value, "count", 0));
    });
    const keyDataStacked = _.reduce(
        _.keys(dataStacked),
        (sum, val) =>
            _.set(
                sum,
                val,
                getValueDataStacked(_.get(dataStacked, val, 0), total)
            ),
        {}
    );
    return {
        keysStacked,
        dataStacked: [
            _.assign(keyDataStacked, { index: "", textIndex: "", total: 100 }),
        ],
    };
}

function isSelected(selected, filter) {
    return (
        _.size(selected) > 0 &&
        _.indexOf(selected, _.get(filter, "code")) === -1
    );
}

function hasData(data) {
    const keys = _.chain(data)
        .get(0)
        .keys()
        .filter((key) => _.indexOf(["total", "index", "textIndex"], key) === -1)
        .value();
    const values = _.get(data, "0", {});
    return _.reduce(keys, (sum, key) => sum + _.get(values, key, 0), 0) === 0;
}

function invalidData(type, info) {
    switch (type) {
        case chartTypes.PIE:
        case chartTypes.PIE3D:
        case chartTypes.PIE_DONUT:
        case chartTypes.PIE_DONUT3D:
        case chartTypes.BAR_HORIZONTAL:
        case chartTypes.BAR_HORIZONTAL3D:
        case chartTypes.BAR_VERTICAL:
        case chartTypes.BAR_VERTICAL3D:
            return (
                _.chain(info)
                    .filter(
                        (inf) =>
                            _.reduce(
                                _.get(inf, "dataFormat", []),
                                (sum, value) => sum + _.get(value, "value", 0),
                                0
                            ) === 0
                    )
                    .value() === _.size(info)
            );
        case chartTypes.STACKED_BAR:
        case chartTypes.STACKED_BAR3D:
            return (
                _.chain(info)
                    .filter((value) => hasData(_.get(value, "dataStacked", [])))
                    .size()
                    .value() === _.size(info)
            );
        default:
            return true;
    }
}

class GroupFilterComponent extends Component {
    state = {
        colorsDefault: colorState,
    };

    static propTypes = {
        colors: PropTypes.array,
        data: PropTypes.array.isRequired,
        textValues: PropTypes.string,
        textKeys: PropTypes.string,
        type: PropTypes.oneOf(arrayChart).isRequired,
        hasLegend: PropTypes.bool,
        size: PropTypes.oneOf(arraySizeChart),
        contentAlign: PropTypes.oneOf(arrayAlign),
        selected: PropTypes.arrayOf(PropTypes.string),
        filters: PropTypes.arrayOf(PropTypes.shape()),
        filtersCode: PropTypes.arrayOf(PropTypes.shape()),
    };

    _handledFilterGroup = (code, isSelected) => {
        const { selectedFilters } = this.props;
        selectedFilters(code, isSelected);
    };

    renderChart(
        dataFormat,
        keysStacked,
        dataStacked,
        textValues,
        textKeys,
        type,
        hasLegend,
        size,
        hasMiniLegend
    ) {
        switch (type) {
            case "STACKED_BAR":
                // <LegendStackedComponent data={dataStacked} size={size} keys={keysStacked} />
                return (
                    <div
                        style={{ display: "inline-block" }}
                        className="print_pdf_component"
                    >
                        <BarStackedGroupFilterComponent
                            data={dataStacked}
                            keys={keysStacked}
                            size={size}
                            hasMiniLegend={hasMiniLegend}
                        />
                    </div>
                );
            case "STACKED_BAR3D":
                return (
                    <div
                        style={{
                            display: "inline-block",
                            border: "1px solid #000",
                        }}
                        className="print_pdf_component"
                    >
                        <BarStacked3DGroupFilterComponent
                            data={dataStacked}
                            keys={keysStacked}
                            size={size}
                            hasMiniLegend={hasMiniLegend}
                        />
                    </div>
                );
            default:
                return (
                    <ChartComponent
                        data={dataFormat}
                        keysStacked={keysStacked}
                        dataStacked={dataStacked}
                        textValues={textValues}
                        textKeys={textKeys}
                        type={type}
                        hasLegend={hasLegend}
                        size={size}
                    />
                );
        }
    }

    renderLegendChart(data, size, type) {
        switch (type) {
            case "STACKED_BAR":
            case "STACKED_BAR3D":
                return (
                    <LegendComponent
                        data={_.filter(
                            data,
                            (value) => !_.isEqual(_.get(value, "value", 0), 0)
                        )}
                        size={size}
                    />
                );
            default:
                return <span />;
        }
    }

    renderFilterNoSelected(filters) {
        return (
            <ul
                style={{
                    verticalAlign: "top",
                    display: "inline-block",
                    margin: "5px 0",
                }}
            >
                {_.map(filters, (filter, idx) => (
                    <li
                        key={`filter-group-value-${getRamdonString()}`}
                        style={{ minWidth: 197, float: "left", width: "49%" }}
                    >
                        <h3
                            onClick={this._handledFilterGroup.bind(
                                this,
                                _.get(filter, "code"),
                                true
                            )}
                            style={styles.titleNoFilter}
                        >
                            {_.get(filter, "name", "")}
                        </h3>
                    </li>
                ))}
            </ul>
        );
    }

    render() {
        const {
            data,
            textValues,
            textKeys,
            type,
            hasLegend,
            size,
            colors,
            selected,
            filters,
            filtersCode,
            contentAlign,
        } = this.props;
        const { colorsDefault } = this.state;
        const dataLegend = getDataLegend(
            _.orderBy(data, ["id"], ["asc"]),
            colors,
            colorsDefault
        );
        const infoChart = _.chain(_.orderBy(data, ["id"], ["asc"]))
            .map((d) => [d])
            .map((d) => {
                const dataFormat = getData(d, colors, colorsDefault);
                const dataFormatStacked = getDataStacked(
                    d,
                    colors,
                    colorsDefault
                );
                const dataStacked = _.get(dataFormatStacked, "dataStacked", []);
                const keysStacked = _.get(dataFormatStacked, "keysStacked", []);
                const codeGroup = _.chain(filtersCode)
                    .filter((f) =>
                        _.isEqual(_.get(f, "name", "n"), _.get(d, "0.id", "a"))
                    )
                    .get("0.code", null)
                    .value();
                return {
                    dataFormat,
                    keysStacked,
                    dataStacked,
                    title: _.get(d, "0.id", ""),
                    code: codeGroup,
                    count: getCountValuesData(d),
                };
            })
            .value();

        if (_.size(infoChart) > 0) {
            if (invalidData(type, infoChart)) {
                return (
                    <h3
                        style={{
                            padding: 10,
                            fontWeight: 400,
                            boxSizing: "border-box",
                            color: "#4c4c4c",
                            textAlign: "center",
                        }}
                    >
                        No hay resultados que coincidan con el filtro
                    </h3>
                );
            }
            const countInfoChats = _.size(infoChart);
            return (
                <div
                    className="print_pdf_component"
                    style={{ textAlign: contentAlign }}
                >
                    <div style={{ textAlign: "left", marginBottom: 15 }}>
                        <div
                            style={{
                                minWidth: 265,
                                borderRadius: 10,
                                padding: _.size(filters) > 0 ? "10px 13px" : 0,
                                marginBottom: 5,
                                marginTop: 3,
                                background:
                                    _.size(filters) > 0 ? "#DDD" : "none",
                            }}
                        >
                            {_.size(filters) > 0 && (
                                <h3
                                    style={{
                                        fontWeight: 400,
                                        textAlign: "left",
                                        margin: 0,
                                        fontSize: 15,
                                    }}
                                >{`Grupos no filtrados`}</h3>
                            )}
                            {this.renderFilterNoSelected(filters)}
                        </div>
                    </div>
                    {_.map(infoChart, (info, index) => {
                        const codeGroup = _.get(info, "code", "");
                        const styleContent = {
                            display: "inline-block",
                            opacity: highlightSelected(codeGroup, selected),
                        };
                        const keyContent = `indicator-chart-${getRamdonString()}`;
                        const actionSelected = !isSelectedFilter(
                            codeGroup,
                            selected
                        );
                        const seeTitleGroup = !hasData(
                            _.get(info, "dataStacked", [])
                        );
                        return (
                            <div
                                style={styleContent}
                                key={keyContent}
                                className="print_pdf_component"
                            >
                                {seeTitleGroup && (
                                    <h3
                                        onClick={this._handledFilterGroup.bind(
                                            this,
                                            codeGroup,
                                            actionSelected
                                        )}
                                        style={styles.titleGroup}
                                    >{`${_.get(info, "title", "")}: ${_.get(
                                        info,
                                        "count",
                                        ""
                                    )}`}</h3>
                                )}
                                {this.renderChart(
                                    _.get(info, "dataFormat", []),
                                    _.get(info, "keysStacked", []),
                                    _.get(info, "dataStacked", []),
                                    textValues,
                                    textKeys,
                                    type,
                                    hasLegend,
                                    size,
                                    countInfoChats > 1
                                )}
                            </div>
                        );
                    })}
                    {this.renderLegendChart(dataLegend, size, type)}
                    <h3
                        className="remove-help-text-report"
                        style={{
                            float: "left",
                            fontWeight: 400,
                            textAlign: "left",
                            margin: "5px 5px 10px 10px",
                            fontSize: 13,
                            color: "#868686",
                        }}
                    >
                        {`Dar clic al título de un grupo para filtrar. ${getNumFiltersSelected(
                            _.size(selected)
                        )}`}
                    </h3>
                </div>
            );
        }
        return <span style={{ color: "#CCC" }} />;
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            selectedFilters,
        },
        dispatch
    );
}

function mapStateToProps() {
    return function ({ analyticsReport }) {
        const selected = analyticsReport.get("selectedFilters").toJS();
        return {
            selected,
            filtersCode: analyticsReport.get("filters").toJS(),
            filters: analyticsReport
                .get("filters")
                .filter((filter) => isSelected(selected, filter))
                .map((filter) => _.pick(filter, ["name", "code"]))
                .toJS(),
        };
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupFilterComponent);
