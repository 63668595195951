import PropTypes from "prop-types";
import React, { Component } from "react";
import * as _ from "lodash";
import SociodemographicComponent from "./sociodemographicComponent";
import GroupFiltersComponent from "./groupFiltersComponent";
import QuestionComponent from "./questionComponent";
import MultiIndicatorComponent from "./multiIndicatorComponent";
import TitleComponent from "./titleComponent";
import TextComponent from "./textComponent";
import MenuComponent from "./menuComponent";
import RankingComponent from "./rankingComponent";
import RankingStatsComponent from "./rankingStatsComponent";
import DataComponent from "../analyticsReport/item";
import {
    FILTER_GROUPS,
    SOCIO_DEMOGRAPHIC,
    INDICATOR,
    QUESTION,
    RANKING,
    RANKING_STATS,
    MENU,
    CHART,
    TEXT,
} from "../analyticsReport/types";
import { arrayOrientation, arrayAlign } from "./types";

class ChildrenComponent extends Component {
    static propTypes = {
        index: PropTypes.number.isRequired,
        title: PropTypes.object,
        numColumns: PropTypes.oneOf([1, 2, 3, 4, 5]).isRequired,
        contentColumns: PropTypes.oneOf([1, 2, 3, 4, 5]).isRequired,
        orientation: PropTypes.oneOf(arrayOrientation).isRequired,
        type: PropTypes.oneOf([CHART, TEXT, MENU, RANKING, RANKING_STATS]).isRequired,
        chartProps: PropTypes.object,
        textProps: PropTypes.object,
        menuProps: PropTypes.object,
        menu: PropTypes.array.isRequired,
        projectId: PropTypes.string.isRequired,
        colors: PropTypes.array.isRequired,
        contentAlign: PropTypes.oneOf(arrayAlign),
        styleContent: PropTypes.object,
        maxRanking: PropTypes.number.isRequired,
    };

    renderChartComponent = chartProps => {
        const { projectId, colors, numColumns, contentColumns, contentAlign } = this.props;
        const presentationChart = _.get(chartProps, "chartPresentationType");
        const typeChart = _.get(chartProps, "chartType");
        const sizeChart = _.get(chartProps, "size");
        const itemsChart = _.get(chartProps, "items", []);
        const hasDetail = _.get(chartProps, "hasDetail", false);
        const componentProps = {
            projectId,
            colors,
            type: presentationChart,
            hasLegend: true,
            size: sizeChart,
            hasDetail,
            numColumns,
            contentColumns,
            itemsChart,
            contentAlign,
        };

        switch (typeChart) {
            case SOCIO_DEMOGRAPHIC:
                return (
                    <DataComponent
                        projectId={projectId}
                        elementsId={itemsChart}
                        type={typeChart}
                        ToRender={SociodemographicComponent}
                        componentProps={componentProps}
                    />
                );
            case FILTER_GROUPS:
                return (
                    <DataComponent
                        projectId={projectId}
                        elementsId={itemsChart}
                        type={typeChart}
                        ToRender={GroupFiltersComponent}
                        componentProps={componentProps}
                    />
                );
            case QUESTION:
                return (
                    <DataComponent
                        projectId={projectId}
                        elementsId={itemsChart}
                        type={typeChart}
                        ToRender={QuestionComponent}
                        componentProps={componentProps}
                    />
                );
            case INDICATOR:
                return (
                    <DataComponent
                        projectId={projectId}
                        elementsId={itemsChart}
                        type={typeChart}
                        ToRender={MultiIndicatorComponent}
                        componentProps={componentProps}
                    />
                );
            default:
                return <div style={{ textAlign: "center", color: "#CCC" }}>(vacío)</div>;
        }
    };

    renderRankingComponent = rankingProps => {
        const { projectId, colors, numColumns, contentColumns, maxRanking } = this.props;
        const itemsRanking = _.get(rankingProps, "items");
        const hasDetail = _.get(rankingProps, "hasDetail", false);
        const componentProps = {
            projectId,
            colors,
            hasDetail,
            numColumns,
            contentColumns,
            maxRanking,
        };
        return (
            <DataComponent
                projectId={projectId}
                type={RANKING}
                elementsId={itemsRanking}
                ToRender={RankingComponent}
                componentProps={componentProps}
            />
        );
    };

    renderRankingStatsComponent = rankingProps => {
        const { projectId, colors, numColumns, contentColumns } = this.props;
        const itemsRanking = _.get(rankingProps, "items");
        const hasDetail = _.get(rankingProps, "hasDetail", false);
        const componentProps = {
            projectId,
            colors,
            hasDetail,
            numColumns,
            contentColumns,
        };
        return (
            <DataComponent
                projectId={projectId}
                type={RANKING_STATS}
                elementsId={itemsRanking}
                ToRender={RankingStatsComponent}
                componentProps={componentProps}
            />
        );
    };

    renderComponent = () => {
        const { type, chartProps, textProps, menuProps, menu, projectId } = this.props;
        switch (type) {
            case RANKING:
                return this.renderRankingComponent(chartProps);
            case RANKING_STATS:
                return this.renderRankingStatsComponent(chartProps);
            case CHART:
                return this.renderChartComponent(chartProps);
            case TEXT:
                return <TextComponent text={_.get(textProps, "text")} />;
            case MENU:
                return <MenuComponent menu={menu} />;
            default:
                return <span />;
        }
    };

    render() {
        const { index, title, orientation, type, contentAlign, styleContent } = this.props;
        const display = _.isEqual(orientation, "HORIZONTAL")
            ? "inline-block"
            : _.isEqual(orientation, "VERTICAL") ? "block" : "inline";
        const styleTable = {
                minWidth: 150,
                width: "100%",
                borderCollapse: "collapse",
                padding: 0,
            },
            tableCellStyle = {
                padding: 0,
                margin: 0,
                verticalAlign: "top",
            },
            styleComponent = {
                padding: 2,
                display,
                verticalAlign: "top",
                textAlign: contentAlign,
            };

        return (
            <div
                className="print_pdf_children"
                style={_.assign(styleComponent, styleContent, { boxSizing: "border-box" })}
            >
                <table className="data-tables" style={_.assign(styleTable, centerTable(orientation))}>
                    <tbody>
                        <tr>
                            <td style={tableCellStyle}>
                                <TitleComponent
                                    text={_.get(title, "text", "")}
                                    style={_.get(title, "style", { margin: 0, padding: 0 })}
                                    code={_.get(title, "code", "")}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td style={tableCellStyle}>{this.renderComponent()}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

function fullWidth(type) {
    switch (type) {
        case RANKING:
        case RANKING_STATS:
            return { width: "100%" };
        default:
            return {};
    }
}

function centerTable(orientation) {
    switch (orientation) {
        case "VERTICAL":
            return { margin: "0 auto" };
        case "HORIZONTAL":
        default:
            return {};
    }
}

export default ChildrenComponent;
