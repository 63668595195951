import React, { Component } from "react";
import PropTypes from "prop-types";
import { blue800 } from "material-ui/styles/colors";
import ColorComponent from "./styleContent/colorComponent";
import NumberComponent from "./styleContent/numberComponent";
import BorderComponent from "./styleContent/borderComponent";
import ShadowComponent from "./styleContent/shadowComponent";

class StyleContentComponent extends Component {
    static propTypes = {
        survey: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        isSection: PropTypes.bool,
    };

    render() {
        const { survey, path, isSection } = this.props;
        const is = !_.isNull(isSection) && !_.isUndefined(isSection) ? isSection : false;
        const field = is ? "style.content" : "style";
        return (
            <div>
                <NumberComponent
                    survey={survey}
                    path={`${path}.${field}`}
                    fieldProp={"borderRadius"}
                    title={"Radio del borde"}
                    defaultValue={0}
                />
                <ShadowComponent survey={survey} path={`${path}.${field}`} defaultValue={"0 0 0 0 #EEE"} />
                <BorderComponent survey={survey} path={`${path}.${field}`} />
                <ColorComponent
                    survey={survey}
                    path={`${path}.${field}`}
                    fieldProp={"backgroundColor"}
                    title={"Color de fondo:"}
                    defaultValue={"#FFF"}
                />
                <NumberComponent
                    survey={survey}
                    path={`${path}.${field}`}
                    fieldProp={"padding"}
                    title={"Espaciado"}
                    defaultValue={0}
                />
            </div>
        );
    }
}

export default StyleContentComponent;
