import { axiosAPI_V2 } from '../../middleware/api';
import { GET_DATA_EMPLOYEES_PROJECT_PROCESSED, DATA_EMPLOYEES_PROJECT_PROCESSED_IN_SEARCH, SEND_DATA_EMPLOYEES_PROJECT_PROCESSED } from './constants';

export function getEmployeeProcessed(idProjects) {

    const request = axiosAPI_V2.post(`/projects/employees/processed`, {
                                                                          "project": {
                                                                              "idProjects": idProjects
                                                                          }
                                                                      });

    return {
        type: GET_DATA_EMPLOYEES_PROJECT_PROCESSED,
        payload: request
    }
}

export function getEmployeeAndProcessed(idProjects) {

    const request = axiosAPI_V2.post(`/projects/employees/processed/solutions`, {
                                                                          "project": {
                                                                              "idProjects": idProjects
                                                                          }
                                                                      });

    return {
        type: GET_DATA_EMPLOYEES_PROJECT_PROCESSED,
        payload: request
    }
}

export function sendEmployeeProcessed(idProjects) {

    const request = axiosAPI_V2.post(`/solutions/processed`, {
                                                                  "solution": {
                                                                      "idProjects": idProjects
                                                                  }
                                                              });

    return {
        type: SEND_DATA_EMPLOYEES_PROJECT_PROCESSED,
        payload: request
    }
}

export function inSearchEmployeeProcessed() {

    return {
        type: DATA_EMPLOYEES_PROJECT_PROCESSED_IN_SEARCH
    }
}
