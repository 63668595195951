import * as actions from './constants';
import { axiosAPI } from '../../../middleware/api';

/**
 * @description save the answer data for a question.
 * @param question ID
 * @param section ID
 * @param answer data
 * @param project ID
 * @returns {{type, payload: {question: *, section: *, answer: *, project: *}}}
 */
export function preSaveAnswer(question, section, answer, project) {
    //const request = axiosAPI.post(`/answers`,  {
    //                                                      answer : {
    //                                                          employee: project,
    //                                                          question: question,
    //                                                          project: project,
    //                                                          section: section,
    //                                                          value: answer,
    //                                                          status: "Respondido"
    //                                                      }
    //                                                  });
    return {
        type: actions.PRE_SAVE_ANSWER,
        payload: {
            question,
            section,
            answer,
            project
        }
    }
}

export function SaveSolutionSection(employee, project, company, section, sectionId, survey, answers) {
    const solution = {
        employee,
        project,
        company,
        section,
        sectionId,
        survey,
        answers
    };
    const request = axiosAPI.post(`/solutions`, {solution: solution});
    return {
        type: actions.SAVE_SOLUTION_SECTION,
        payload: request
    }
}


export function updateOpenQuestionValue(questionId, value) {

    return {
        type: actions.UPDATE_CHECK,
        question: questionId,
        value
    }
}

export function updateQuestionOtherWhich(questionId, value) {

    return {
        type: actions.UPDATE_OTHER_WHICH,
        question: questionId,
        value
    }
}

export function setInitialOpenQuestionState(questionId, maxValues) {
    return {
        type: actions.SET_INITIAL_OPEN_QUESTION,
        question: questionId,
        maxValues
    }
}
export function deleteOtherWhich(questionId) {
    return {
        type: actions.DELETE_OTHER_WHICH,
        question: questionId
    }
}
export function setInitialSection(questions, size) {
    return {
        type: actions.SET_INITIAL_SECTION,
        questions,
        size
    }
}
export function updateAnswers(questionId, answer) {
    return {
        type: actions.UPDATE_ANSWERS,
        question: questionId,
        answer
    }
}
