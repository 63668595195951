import { UPLOAD_FILE } from '../../../constants/index';
import { axiosAPI } from '../../../middleware/api';
import _ from 'lodash';
import axios from 'axios';

export function uploadFile(data, store, id, email) {

    const url = _.isUndefined(id)
                    ? `/${store}/file/${email}`
                    : `/${store}/file/${id}/${email}`;
                    
    const request = axiosAPI.post(url, data, {
        responseType: 'json',
        contentType: 'application/vnd.ms-excel'
    });

    return {
        type: UPLOAD_FILE,
        payload: request
    }
}
