import _ from 'lodash';
import { POST_REFEREE, DELETE_REFEREE } from '../constants/index';
import { axiosAPI } from '../middleware/api';

export function createReferee(props, editMode, sendEmail ) {

    props = _.set(props, "sendPasswordEmail",sendEmail);

    function getProps(){
        if(_.isNull(_.get(props, '_id'))){
            return {'referee': _.omit(props, '_id')};
        } else {
            return {'referee': props};
        }
    }

    let request = null;
    if(editMode){
        request = axiosAPI.put(`/referees`, getProps());
    } else {
        request = axiosAPI.post(`/referees`, getProps());
    }

    return {
        type: POST_REFEREE,
        payload: request
    }
}

export function deleteReferee(refereeId) {

    const request = axiosAPI.delete(`/referees/${refereeId}`, {data: {"type": 'logic'}});

    return {
        type: DELETE_REFEREE,
        payload: request,
        meta: {
            refereeId
        }
    }
}
