import PropTypes from "prop-types";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";
import { Row, Col } from "react-flexbox-grid";
import _ from "lodash";
import { browserHistory, Link } from "react-router";

import { blue700 } from "material-ui/styles/colors";
import { toggleSnackbar } from "../../actions/commons";
import SnackbarMessage from "../commons/snackBarMessage";
import { changePasswordUser, showModalChangePassword } from "./actions";
import { LBLUSERS } from "../../constants/labels";
import FaqMessage from "../fields/FaqMessage/FaqMessage";
import About from "../fields/about/About";

class ModalChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            msgError: "",
            showError: false,
        };
        this._handleChangePassword = this._handleChangePassword.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShowError = this.handleShowError.bind(this);
    }

    handleClose() {
        this.props.showModalChangePassword(false);
    }

    handleShowError(show, msg) {
        this.setState({
            showError: show,
            msgError: msg,
        });
    }

    UNSAFE_componentWillMount() {
        const { resetForm } = this.props;
        resetForm();
    }

    _handleChangePassword(formData) {
        const {
            changePasswordUser,
            user,
            toggleSnackbar,
            resetForm,
        } = this.props;
        this.handleShowError(false, "");
        const idUser = _.get(user, "_id");
        const currentPassword = _.get(formData, "currentPassword");
        const newPasswordUser = _.get(formData, "newPasswordUser");

        changePasswordUser(idUser, currentPassword, newPasswordUser)
            .then(
                (data) => {
                    if (
                        _.get(data, "error") ||
                        _.get(data, "payload.status") !== 200
                    ) {
                        console.log("error", _.get(data, "error"));
                    } else if (_.has(data, "payload.data.error")) {
                        const result = _.get(data, "payload.data.error");
                        console.log("message", result);
                        switch (result) {
                            case "user_not_found":
                                this.handleShowError(
                                    true,
                                    LBLUSERS.msg.errorChangePassword
                                );
                                console.error("Usuario no existe");
                                break;
                            case "not_valid_current_password":
                                this.handleShowError(
                                    true,
                                    LBLUSERS.msg.currentPasswordNotValid
                                );
                                break;
                            case "password_not_change":
                                this.handleShowError(
                                    true,
                                    LBLUSERS.msg.errorChangePassword
                                );
                                break;
                        }
                    } else {
                        toggleSnackbar(
                            true,
                            LBLUSERS.msg.successChangePassword
                        );
                        this.handleClose();
                        resetForm();
                        toggleSnackbar(
                            true,
                            LBLUSERS.msg.successChangePassword
                        );
                    }
                },
                (reason) => {
                    console.log("reason", reason);
                }
            )
            .catch((data) => {
                console.log("data", data);
            });
    }

    render() {
        const {
            fields: {
                currentPassword,
                newPasswordUser,
                confirmNewPasswordUser,
            },
            handleSubmit,
            resetForm,
            showChangePassword,
            user,
        } = this.props;
        return (
            <div>
                <Dialog
                    title={LBLUSERS.titleModalChangePass}
                    titleStyle={{ textAlign: "center", color: blue700 }}
                    modal={false}
                    open={showChangePassword}
                    onRequestClose={this.handleClose}
                >
                    <form onSubmit={handleSubmit(this._handleChangePassword)}>
                        <Row>
                            <Col xs={6}>
                                <p>
                                    <b>{LBLUSERS.name}:</b>{" "}
                                    {_.get(user, "person.name")}
                                </p>
                                <p>
                                    <b>{LBLUSERS.lastName}:</b>{" "}
                                    {_.get(user, "person.lastName")}
                                </p>
                                <p>
                                    <b>{LBLUSERS.id}:</b>{" "}
                                    {_.get(user, "person.id")}
                                </p>
                                <p>
                                    <b>{LBLUSERS.email}:</b>{" "}
                                    {_.get(user, "person.email")}
                                </p>
                            </Col>
                            <Col xs={6}>
                                <TextField
                                    hintText=""
                                    floatingLabelText={LBLUSERS.currentPassword}
                                    type="password"
                                    errorText={
                                        currentPassword.touched &&
                                        currentPassword.error
                                            ? currentPassword.error
                                            : ""
                                    }
                                    {...currentPassword}
                                />
                                <TextField
                                    hintText=""
                                    floatingLabelText={LBLUSERS.newPassword}
                                    type="password"
                                    errorText={
                                        newPasswordUser.touched &&
                                        newPasswordUser.error
                                            ? newPasswordUser.error
                                            : ""
                                    }
                                    {...newPasswordUser}
                                />
                                <TextField
                                    hintText=""
                                    floatingLabelText={LBLUSERS.confirmPassword}
                                    type="password"
                                    errorText={
                                        confirmNewPasswordUser.touched &&
                                        confirmNewPasswordUser.error
                                            ? confirmNewPasswordUser.error
                                            : ""
                                    }
                                    {...confirmNewPasswordUser}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xsOffset={4} xs={8} style={{ paddingTop: 30 }}>
                                <div
                                    style={
                                        this.state.showError
                                            ? { display: "block" }
                                            : { display: "none" }
                                    }
                                >
                                    <b>
                                        <span style={{ color: "red" }}>
                                            {this.state.msgError}
                                        </span>
                                    </b>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 30 }}>
                            <Col xsOffset={3} xs={9}>
                                <FlatButton
                                    label={LBLUSERS.buttons.cancel}
                                    secondary
                                    onClick={this.handleClose}
                                />
                                <RaisedButton
                                    label={LBLUSERS.buttons.changePass}
                                    secondary
                                    type="submit"
                                    style={{ marginLeft: 30 }}
                                />
                            </Col>
                        </Row>
                    </form>
                </Dialog>
                <SnackbarMessage />
                <FaqMessage />
                <About />
            </div>
        );
    }
}

const validate = (values) => {
    const errors = {};
    if (!values.currentPassword) {
        errors.currentPassword = LBLUSERS.validations.currentPassword;
    }

    if (!values.newPasswordUser) {
        errors.newPasswordUser = LBLUSERS.validations.newPasswordUser;
    } else {
        if (
            !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(values.newPassword)
        ) {
            errors.newPasswordUser = LBLUSERS.validations.passwordStrength;
        }
        if (_.isEqual(values.newPasswordUser, values.currentPassword)) {
            errors.newPasswordUser = LBLUSERS.validations.equalsPasswords;
        }
    }
    if (!values.confirmNewPasswordUser) {
        errors.confirmNewPasswordUser = LBLUSERS.validations.confirmPassword;
    } else if (
        !_.isEqual(values.newPasswordUser, values.confirmNewPasswordUser)
    ) {
        errors.confirmNewPasswordUser = LBLUSERS.validations.notEqualsPasswords;
    }

    return errors;
};

ModalChangePassword.propTypes = {
    user: PropTypes.object.isRequired,
};

function mapStateToProps({ showChangePassword }, ownerProps) {
    return {
        showChangePassword: _.get(
            showChangePassword,
            "modalChangePasswordOpen"
        ),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            toggleSnackbar,
            changePasswordUser,
            showModalChangePassword,
        },
        dispatch
    );
}

export default reduxForm(
    {
        form: "ChangePasswordForm",
        fields: [
            "currentPassword",
            "newPasswordUser",
            "confirmNewPasswordUser",
        ],
        validate,
    },
    mapStateToProps,
    mapDispatchToProps
)(ModalChangePassword);
