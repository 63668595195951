import PropTypes from "prop-types";
import React, { Component } from "react";
import { arc, pie, select } from "d3";
import Faux from "react-faux-dom";
import LegendComponent from "../legends/legendComponent";
import _ from "lodash";

class PieChartComponent extends Component {
    constructor(props) {
        super(props);
        this.renderLegend = this.renderLegend.bind(this);
    }

    renderLegend() {
        const { hasLegend, data, size } = this.props;
        if (!_.isUndefined(hasLegend) && !_.isNull(hasLegend)) {
            if (hasLegend) {
                return <LegendComponent data={data} size={size} />;
            }
            return <span />;
        }
        return <span />;
    }

    render() {
        const { data, size, height, width } = this.props;
        const faux = Faux.createElement("div"),
            radius = Math.min(width, height) / 2;

        const arcFn = arc()
            .outerRadius(radius - 15)
            .innerRadius(0);

        const labelArc = arc()
            .outerRadius(radius - 50)
            .innerRadius(radius - 50);

        const pieFn = pie()
            .sort(null)
            .value(d => d.value);

        const svg = select(faux)
            .append("svg")
            .attr("width", width)
            .attr("height", height)
            .style("page-break-inside", "avoid")
            .append("g")
            .attr("transform", `translate(${width / 2}, ${height / 2})`);

        // Sombra
        /* const radialGradient = svg
            .append("defs")
            .append("radialGradient")
            .attr("id", "radial-gradient");

        radialGradient.append("stop")
            .attr("offset", "0%")
            .attr("stop-color", "#DDD");

        radialGradient.append("stop")
            .attr("offset", "100%")
            .attr("stop-color", "#FFF");

        svg.append("circle")
            .attr("cx", 25)
            .attr("cy", 25)
            .attr("r", radius - 35)
            .style("fill", "url(#radial-gradient)"); */

        const g = svg
            .selectAll(".arc")
            .data(pieFn(data))
            .enter()
            .append("g")
            .attr("class", "arc");

        g
            .append("path")
            .attr("d", arcFn)
            .style("fill", d => d.data.color);

        const gText = svg
            .selectAll(".texts")
            .data(pieFn(data))
            .enter()
            .append("g")
            .attr("class", "texts");

        /* const text = gText
            .append("text")
            .attr("transform", d => `translate(${labelArc.centroid(d)})`)
            .attr("dy", ".35em")
            .attr("dx", "-12")
            .text(d => d.data.index); */

        return (
            <div style={{ display: "inline-block", width: width + 2 }}>
                <div style={{ textAlign: "center", width, display: "inline-block" }}>{faux.toReact()}</div>
                {this.renderLegend()}
            </div>
        );
    }
}

PieChartComponent.propTypes = {
    data: PropTypes.array.isRequired,
    hasLegend: PropTypes.bool,
    size: PropTypes.oneOf(["small", "middle", "large"]),
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired
};

export default PieChartComponent;
