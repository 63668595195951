import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {reduxForm} from 'redux-form';
import {Row, Col} from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';
import _ from 'lodash';
import { toggleSnackbar } from '../actions/commons';
import { createTemplate, getTemplate } from '../actions/template_form';
import MenuItem from 'material-ui/MenuItem';
import { LBLTEMPLATES } from '../constants/labels';
import ButtonBack from '../components/fields/buttonBack/buttonBack';
import RichText from '../components/fields/RichText/RichText';
import { browserHistory } from 'react-router';
import SelectFieldFormat from '../components/fields/SelectFieldFormat/SelectFieldFormat';
import AuthComponent from '../components/AuthComponent';
import CircularProgress from 'material-ui/CircularProgress';

const style = {
        margin: 20,
        float: 'right'
    },
    stylePaper = {
        marginTop: 20,
        marginBottom: 20
    };

class TemplateEmailsNews extends Component {

    constructor(props) {
        super(props);
        this._saveTemplate = this._saveTemplate.bind(this);
    }

    UNSAFE_componentWillMount() {
        const { getTemplate, params } = this.props;
        if (_.has(params, '_id')) {
            getTemplate(params._id);
        }
    }

    _saveTemplate(formData) {
        // let htmlText = _.get(formData, 'html').replace(/<[^>]*>?/g, '');
        let template = {
            name: _.get(formData, 'name'),
            // template: htmlText.replace(/\n/g, "<br>"),
            template: _.get(formData, 'html'),
            type : _.get(formData, 'type'),
            _id: _.get(formData, '_id')
        };
        const {
            createTemplate,
            resetForm,
            toggleSnackbar,
            params
        } = this.props;
        const editMode = _.has(params, '_id');
        createTemplate(editMode ? template : _.omit(template, '_id'), editMode)
            .then((data) => {
                if(_.get(data, 'error') || (_.get(data, 'payload.status') !== 200)){
                    toggleSnackbar(true, LBLTEMPLATES.msg.errorSave);
                } else {
                    resetForm();
                    toggleSnackbar(true, LBLTEMPLATES.msg.successSave);
                    browserHistory.push(`/admin/templateemail/grid`);
                }
            }, (reason) => {
                toggleSnackbar(true, LBLTEMPLATES.msg.errorSave);
            })
            .catch((data) => {
                toggleSnackbar(true, LBLTEMPLATES.msg.errorSave);
            });
    }

    renderOptionList(list){
        if(_.size(list)){
            if(list instanceof Array){
                return list.map((option, index) => {
                    return (
                        <MenuItem key={`${index}option`} value={option.value} primaryText={option.title}/>
                    );
                });
            }
        }
    }

    render() {
        const {
            fields: {
                name,
                html,
                type
            },
            handleSubmit
        } = this.props;
        const editMode = _.has(this, 'props.params._id');
        return (
            <Row>
                <AuthComponent
                    component={
                        <Col md={8} mdOffset={2} xs={12}>
                            <Paper style={stylePaper}>
                                <Row>
                                    <Col xsOffset={1} xs={10}>
                                        <ButtonBack url={`/admin/templateemail/grid`}/>
                                        <h1 style={{textAlign: 'center', fontWeight: 400}}>{editMode ? LBLTEMPLATES.titleEdit : LBLTEMPLATES.titleCreate}</h1>
                                        <form onSubmit={handleSubmit(this._saveTemplate)}>
                                            <Row>
                                                <Col xs>
                                                    <h3 style={{fontWeight: 400}}>
                                                        <div style={{textAlign: 'justify'}}>{LBLTEMPLATES.labels.valuesAllowed}</div><br/>
                                                        <ol>
                                                            {LBLTEMPLATES.labels.arrayValuesAllowed.map((val, idx) =>
                                                                <li key={idx} style={{marginBottom: 12}}>
                                                                    {val.type}
                                                                    <ul>
                                                                        {val.array.map((v, i) =>
                                                                            <li key={`${idx}idx${i}`}>{v}</li>
                                                                        )}
                                                                    </ul>
                                                                </li>
                                                            )}
                                                        </ol>
                                                    </h3>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs>
                                                    <TextField
                                                        floatingLabelText={LBLTEMPLATES.name}
                                                        type="text"
                                                        fullWidth={true}
                                                        errorText={name.touched && name.error ? name.error : '' }
                                                        {...name}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs>
                                                    <SelectFieldFormat
                                                        config={{
                                                                  floatingLabelText: LBLTEMPLATES.type,
                                                                  value: type.value,
                                                                  autoWidth: true,
                                                                  errorText: type.touched && type.error ? type.error : '',
                                                                  onChange: (e, index, value) => type.onChange(value)
                                                              }}
                                                    >
                                                        <MenuItem value={'generic'} primaryText={LBLTEMPLATES.arrTypeTemplate[0]}/>
                                                        <MenuItem value={'login'} primaryText={LBLTEMPLATES.arrTypeTemplate[1]}/>
                                                    </SelectFieldFormat>
                                                </Col>
                                            </Row>
                                            <Row style={{paddingTop: 20}}>
                                                <Col xs>
                                                    <RichText
                                                        field={html}
                                                        valueInitial={html.initialValue}
                                                        errorText={html.touched && html.error ? html.error : '' }
                                                    />
                                                </Col>
                                            </Row>
                                            <AuthComponent
                                                component={<RaisedButton type="submit"
                                                              label={editMode ? LBLTEMPLATES.buttons.update : LBLTEMPLATES.buttons.save}
                                                              secondary={true}
                                                              style={style}
                                                          />}
                                                permission={editMode ? "cgr_template_update" : "cgr_template_create"}
                                                type={"component"}
                                            />
                                        </form>
                                    </Col>
                                </Row>
                            </Paper>
                        </Col>
                    }
                    permission={editMode ? "cgr_template_update" : "cgr_template_create"}
                    type={"url"}
                />
            </Row>
        );
    }
}
const validate = (values) => {
    const errors = {};
    let validSelectAndHTML = true;
    if (!values.name) {
        errors.name = LBLTEMPLATES.validations.name;
    }
    if (!values.type) {
        validSelectAndHTML = false;
        errors.type = LBLTEMPLATES.validations.type;
    }
    if(!values.html) {
        validSelectAndHTML = false;
        errors.html = LBLTEMPLATES.validations.html;
    } else {
        if(_.isEqual(values.html.trim(), "<p><br></p>")){
            validSelectAndHTML = false;
            errors.html = LBLTEMPLATES.validations.html;
        } else {
            let txtDescription = values.html;
            while(txtDescription.indexOf('&nbsp;') !== -1){
                txtDescription = _.replace(txtDescription, '&nbsp;', '');
            }
            if(_.isEqual(txtDescription, "<p><br></p>") || _.isEqual(txtDescription, "<p></p>")){
                validSelectAndHTML = false;
                errors.html = LBLTEMPLATES.validations.html;
            }
        }
    }
    if(validSelectAndHTML){
        if(_.isEqual(values.type, 'login')){
            let valuesHtml = values.html.replace(/<[^>]*>?/g, '');
            if(valuesHtml.indexOf('##clave') === -1){
                errors.html = LBLTEMPLATES.validations.placeholderPassword;
            } else {
                if(valuesHtml.indexOf('##usuario') === -1){
                    errors.html = LBLTEMPLATES.validations.placeholderUser;
                }
            }
        }
    }
    return errors;
};

function mapStateToProps({templateemails}, ownProps) {
    if (_.has(ownProps, 'params._id')) {
        let formTemplate = templateemails.get('template');
        return {
            initialValues: {
                name: _.get(formTemplate, 'name'),
                type: _.get(formTemplate, 'type'),
                html: _.get(formTemplate, 'template'),
                _id: _.get(formTemplate, '_id'),
            },
            _id: ownProps.params._id
        };
    } else {
        return {
            initialValues: {
                name: '',
                type: '',
                html: '',
                _id: ''
            }
        };
    }
}

export default reduxForm({
    form: 'TemplateEmailsNewsForm',
    fields: ['name', 'html', 'type', '_id'],
    validate
}, mapStateToProps, { toggleSnackbar, createTemplate, getTemplate })(TemplateEmailsNews);
