import { GET_TEMPLATE, DELETE_TEMPLATE, TEMPLATE_GRID } from '../constants/index';
import Immutable from 'immutable';
import _ from 'lodash';

const INITIAL_STATE = Immutable.Map({
    all: Immutable.List(),
    template: null
});

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_TEMPLATE:
            var template = _.get(action, 'payload.data', null);
            return state.set('template', template);
        case TEMPLATE_GRID:
            var list = Immutable.List(_.get(action, 'payload.data', []));
            return state.set('all', list);
        case DELETE_TEMPLATE:
            var listTemplates = state.get('all').filter( item => {
                return !_.isEqual(item._id, action.meta.templateId);
            });
            return state.set('all', listTemplates);
        default:
            return state;
    }
}
