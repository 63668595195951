import {TOGGLE_SNACKBAR} from '../../actions/commons';
import _ from 'lodash';

export default (state = {status: false, message: ''}, action) => {
    switch (action.type) {
        case TOGGLE_SNACKBAR:
            return _.assign({}, state, {
                status: action.status,
                message: _.get(action, "message", "")
            });
        default:
            return state;
    }
}
