import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import Paper from "material-ui/Paper";
import AppBar from "material-ui/AppBar";
import Subheader from "material-ui/Subheader";
import { List, ListItem } from "material-ui/List";
import IconButton from "material-ui/IconButton";
import HandIcon from "material-ui/svg-icons/action/pan-tool";
import FinishIcon from "material-ui/svg-icons/action/delete";
import Immutable from "immutable";
import { Editor, EditorState, getDefaultKeyBinding } from "draft-js";
import v4 from "uuid/v4";
import moment from "moment";
import { blue700, blue800, grey600 } from "material-ui/styles/colors";
import FloatingActionButton from "material-ui/FloatingActionButton";
import SendIcon from "material-ui/svg-icons/content/send";
import {
    changeStateConnection,
    setConversationId,
    addMessage,
    getCounterOnUnreadMessagesSingleConversation,
    cleanState,
    updateHeartbeat,
    UPDATE_PING,
    UPDATE_PONG,
} from "../commons/functions";
import {
    notificationUserAdded,
    notificationRemoveUser,
    addActiveConversation,
    selectActiveConversation,
    updateInConversation,
    selectUserActiveConversation,
    updateCountMessageUnread,
    addMessageToConversation,
    notificationConversationClosed,
    markAllMessagesAsRead,
    CONVERSATION_STATUS,
} from "./ducks";
import Message from "../commons/message";
import Counter from "../commons/counter";
import AuthComponent from "../../AuthComponent";
import StatusChip from "./statusChip";
import PersonDialogIcon from "./personDialogIcon";
import ContentListItem from "./contentListItem";

const styles = {
    box: {
        height: "100%",
        width: "100%",
        overflow: "hidden",
    },
    defaultNav: {
        height: 64,
        width: "100%",
        position: "absolute",
        left: "0",
        top: "0",
        zIndex: "110",
        backgroundColor: blue700,
        borderBottom: `3px solid ${blue800}`,
        color: "#ffffff",
        boxShadow: "0px 3px 3px 0px rgba(50, 50, 50, 0.1)",
        WebkitTransition: "0.3s ease",
        transition: "0.3s ease",
    },
    navContentMessage: {
        height: 65,
    },
    stylePaper: {
        margin: "5px auto 20px auto",
        padding: 0,
        width: 750,
    },
    mainNav: {
        position: "absolute",
        left: "0",
        width: "100%",
        height: "50px",
        top: "0",
        margin: "0",
        padding: "0",
        listStyle: "none",
        WebkitTransition: "0.3s ease",
        transition: "0.3s ease",
    },
    bottom: {
        height: 75,
        borderTop: `1px solid ${blue700}`,
    },
    inner: {
        overflowX: "hidden",
        overflowY: "auto",
        height: "100%",
        background: "#f2f2f2",
        MsOverflowStyle: "none",
        width: 399,
    },
    sendButton: {
        marginLeft: "-4px",
        marginTop: "3px",
    },
    editor: {
        padding: "10px 8px 12px 8px",
        height: 51,
        width: 335,
        overflowY: "auto",
    },
    title: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        letterSpacing: 0,
        fontSize: 18,
        fontWeight: 400,
        color: "white",
        height: "46px",
        lineHeight: "46px",
        marginLeft: "10px",
        marginTop: "10px",
    },
    content: {
        WebKitFlex: "0 0 auto",
        flex: "0 0 auto",
        paddingBottom: 8,
        transition: "padding 0.3s cubic-bezier(0.69, 0, 0.79, 0.14)",
        paddingLeft: "0",
        marginTop: "15px",
    },
    meta: {
        position: "absolute",
        top: 47,
        marginLeft: -8,
        fontSize: 11,
    },
    subListStyle: {
        padding: "10px 10px 10px 0px",
        fontSize: 14,
        color: "#807c7c",
        textAlign: "left",
        fontWeight: 400,
    },
    listItemStyle: {
        borderBottom: "1px solid #CCC",
        paddingLeft: 10,
    },
};

class ChatDashboard extends Component {
    state = {
        socket: undefined,
        editorState: EditorState.createEmpty(),
        pingOb$: undefined,
        obsPingSubscription: undefined,
    };

    UNSAFE_componentWillMount() {
        const { person, projects, chatWebSocketManager } = this.props;
        const queueMessage = {
            type: "queue",
            command: "list",
        };

        chatWebSocketManager.projectId = projects;
        chatWebSocketManager.as = "evaluator";
        chatWebSocketManager.initialMessages = [queueMessage];
        chatWebSocketManager.person = person;
        this.setState({
            socket: chatWebSocketManager.connection,
            pingOb$: chatWebSocketManager.pingHandler,
        });
    }

    componentDidMount() {
        const {
            notificationUserAdded,
            notificationRemoveUser,
            addActiveConversation,
            addMessageToConversation,
            notificationConversationClosed,
            updateHeartbeat,
            changeStateConnection,
            person,
            active,
            inConversationActive,
        } = this.props;

        if (this.state.socket !== undefined) {
            this.state.socket.subscribe(
                (message) => {
                    switch (message.type) {
                        case "register":
                            changeStateConnection(message.status);
                            break;
                        case "dequeue":
                            addActiveConversation(message.conversation);
                            break;
                        case "message":
                            const userActive = _.get(this.props, "active");
                            const userInConversationActive = _.get(
                                this.props,
                                "inConversationActive"
                            );
                            const currentConversationOpen = _.isEqual(
                                userActive,
                                _.get(message, "conversation")
                            );
                            addMessageToConversation(
                                message,
                                person,
                                currentConversationOpen
                            );
                            if (
                                _.isEqual(_.get(message, "author"), person) ||
                                userInConversationActive
                            ) {
                                setTimeout(() => this.scrollToDown(), 40);
                            }
                            break;
                        case "notification":
                            switch (message.notificationType) {
                                case "USER_REMOVED":
                                    notificationRemoveUser(
                                        message.message.employee
                                    );
                                    break;
                                case "USER_ADDED":
                                    notificationUserAdded(message.message);
                                    break;
                                case "CONVERSATION_CLOSED":
                                    notificationConversationClosed(
                                        message.message.id
                                    );
                                    break;
                                default:
                                    console.log(
                                        "not handled notification",
                                        message
                                    );
                            }
                            break;
                        case "queue":
                            _.forEach(message.users, notificationUserAdded);
                            break;
                        case "pong":
                            const replyTo = message.replyTo;
                            updateHeartbeat(UPDATE_PONG, replyTo);
                            break;
                        default:
                            console.log("not handled", message);
                            break;
                    }
                },
                (error) => console.log(error),
                () => console.log("complted")
            );
        }

        if (this.state.pingOb$ !== undefined) {
            const obsPingSubscription = this.state.pingOb$.subscribe(
                (nextPing) => {
                    const pingMessage = {
                        type: "ping",
                        id: nextPing,
                    };
                    this.state.socket.next(JSON.stringify(pingMessage));
                    updateHeartbeat(UPDATE_PING, nextPing);
                }
            );
            this.setState({
                pingSubscription: obsPingSubscription,
            });
        }
    }

    scrollToDown() {
        const { messagesContainer } = this.refs;
        if (!_.isUndefined(messagesContainer) && !_.isNull(messagesContainer)) {
            let scrollHeight = _.toNumber(messagesContainer.scrollHeight),
                clientHeight = _.toNumber(messagesContainer.clientHeight),
                sp = _.gt(scrollHeight, clientHeight)
                    ? scrollHeight - clientHeight
                    : 0;
            messagesContainer.scrollTop = sp;
        }
    }

    scrollToLastMessage(idElement) {
        const element = document.getElementById(idElement);
        const position = element.getBoundingClientRect();
        const { messagesContainer } = this.refs;
        if (
            !_.isUndefined(messagesContainer) &&
            !_.isNull(messagesContainer) &&
            !_.isUndefined(position) &&
            !_.isNull(position)
        ) {
            let scrollHeight = _.toNumber(messagesContainer.scrollHeight),
                messagePositionTop = _.toNumber(_.get(position, "top", 0)),
                sp = scrollHeight - (scrollHeight - messagePositionTop) - 40;
            if (sp < 0) {
                sp = 0;
            }
            messagesContainer.scrollTop = sp;
        }
    }

    componentDidUpdate() {
        const {
            active,
            conversation,
            updateScroll,
            inConversationActive,
            messages,
            updateCountMessageUnread,
            unreadCount,
        } = this.props;
        const updateScrollChat = _.isUndefined(updateScroll)
            ? false
            : updateScroll;
        const readOnly =
            _.isUndefined(active) ||
            _.get(conversation, "uiStatus") === CONVERSATION_STATUS.CLOSED;
        if (!readOnly && updateScrollChat && unreadCount > 0) {
            const countMessage = unreadCount - 1;
            const lastMessage = (messages.get(active) || Immutable.List()).get(
                countMessage * -1
            );
            if (!_.isUndefined(lastMessage) && !_.isNull(lastMessage)) {
                const uuid = _.get(lastMessage, "uuid");
                setTimeout(() => this.scrollToLastMessage(uuid), 40);
            }
            updateCountMessageUnread();
        }
    }

    handlerChangeText = (editorState) => {
        this.setState({ editorState });
    };

    sendMessage = (text) => {
        const { active } = this.props;
        if (!_.isUndefined(this.state.socket) && !_.isUndefined(active)) {
            const validMessage = {
                type: "message",
                conversation: active,
                text,
                status: "sending",
                uuid: v4(),
                timestamp: moment().valueOf(),
            };
            this.state.socket.next(JSON.stringify(validMessage));
        }
    };

    render() {
        const {
            person,
            queue,
            status,
            conversations,
            selectActiveConversation,
            updateInConversation,
            selectUserActiveConversation,
            active,
            userDataActive,
            messages,
            markAllMessagesAsRead,
            lastPing,
            lastPong,
            lastUpdated,
        } = this.props;

        const readOnly =
            _.isUndefined(active) ||
            _.get(conversations.get(active), "uiStatus") ===
                CONVERSATION_STATUS.CLOSED;
        const persons = queue.map((item, index) => {
            const name = `${_.get(item, "person.name")} ${_.get(
                item,
                "person.lastName"
            )}`;
            const rightIconMenu = (
                <div>
                    <IconButton
                        tooltipPosition="bottom-left"
                        tooltip="Tomar"
                        onClick={() => {
                            if (this.state.socket !== undefined) {
                                const messageDequeue = {
                                    type: "dequeue",
                                    id: item._id,
                                };
                                this.state.socket.next(
                                    JSON.stringify(messageDequeue)
                                );
                            }
                        }}
                    >
                        <HandIcon color={grey600} />
                    </IconButton>
                    <PersonDialogIcon
                        project={_.get(item, "project")}
                        person={_.get(item, "person")}
                    />
                </div>
            );
            return (
                <ContentListItem
                    key={`${_.get(item, "person._id")}-person-${index}`}
                    primaryText={name}
                    secondaryText={_.get(item, "project.name")}
                    disabled
                    buttons={rightIconMenu}
                />
            );
        });

        const conversationActiveFilter = (conversation) =>
            conversation.uiStatus === "ACTIVE";
        const conversationsActiveList = conversations
            .toList()
            .filter(conversationActiveFilter)
            .map((item) => {
                const conversationId = _.get(item, "conversation._id");
                const user = _.get(item, "user");
                const name = `${_.get(item, "user.person.name")} ${_.get(
                    item,
                    "user.person.lastName"
                )}`;
                const messagesUnread = getCounterOnUnreadMessagesSingleConversation(
                    messages,
                    conversationId
                );
                const rightIconMenu = (
                    <div>
                        <IconButton
                            tooltipPosition="bottom-left"
                            tooltip="Terminar"
                            onClick={() => {
                                if (this.state.socket !== undefined) {
                                    const terminationMessage = {
                                        conversation: conversationId,
                                        type: "conversation",
                                    };
                                    this.state.socket.next(
                                        JSON.stringify(terminationMessage)
                                    );
                                }
                            }}
                        >
                            <FinishIcon color={grey600} />
                        </IconButton>
                        <PersonDialogIcon
                            project={_.get(item, "user.project")}
                            person={_.get(item, "user.person")}
                        />
                    </div>
                );
                return (
                    <ContentListItem
                        key={conversationId}
                        primaryText={name}
                        secondaryText={_.get(item, "user.project.name")}
                        secondaryTextElement={
                            <Counter
                                styles={styles.meta}
                                count={messagesUnread}
                            />
                        }
                        buttons={rightIconMenu}
                        disabled={false}
                        onClickItem={() => {
                            updateInConversation(conversationId);
                            selectActiveConversation(conversationId);
                            selectUserActiveConversation(user);
                            markAllMessagesAsRead(conversationId);
                        }}
                    />
                );
            });

        const conversationsClosedList = conversations
            .toList()
            .filterNot(conversationActiveFilter)
            .map((item) => {
                const conversationId = _.get(item, "conversation._id");
                const user = _.get(item, "user");
                const name = `${_.get(item, "user.person.name")} ${_.get(
                    item,
                    "user.person.lastName"
                )}`;
                const rightIconMenu = (
                    <div>
                        <PersonDialogIcon
                            project={_.get(item, "user.project")}
                            person={_.get(item, "user.person")}
                        />
                    </div>
                );
                return (
                    <ContentListItem
                        key={conversationId}
                        primaryText={name}
                        secondaryText={_.get(item, "user.project.name")}
                        buttons={rightIconMenu}
                        disabled={false}
                        onClickItem={() => {
                            updateInConversation(conversationId);
                            selectActiveConversation(conversationId);
                            selectUserActiveConversation(user);
                            markAllMessagesAsRead(conversationId);
                            setTimeout(() => this.scrollToDown(), 40);
                        }}
                    />
                );
            });

        let finishConversation = <span />;

        let titleConversationMessage = "Mensajes";
        if (!_.isNull(active) && !_.isUndefined(active)) {
            if (!_.isNull(userDataActive) && !_.isUndefined(userDataActive)) {
                titleConversationMessage = `${_.get(
                    userDataActive,
                    "person.name"
                )} ${_.get(userDataActive, "person.lastName")}`;
                if (!readOnly) {
                    finishConversation = (
                        <IconButton
                            tooltipPosition="bottom-left"
                            tooltip="Terminar"
                            onClick={() => {
                                if (this.state.socket !== undefined) {
                                    const terminationMessage = {
                                        conversation: active,
                                        type: "conversation",
                                    };
                                    this.state.socket.next(
                                        JSON.stringify(terminationMessage)
                                    );
                                }
                            }}
                        >
                            <FinishIcon color={"#FFF"} />
                        </IconButton>
                    );
                }
            }
        } else {
            titleConversationMessage = "Sin conversación activa";
        }

        const heightChat = 500;
        const titleCola = `Cola (${persons.size})`;
        const titleActive = `Activas (${conversationsActiveList.size})`;
        const titleClosed = `Cerradas (${conversationsClosedList.size})`;

        return (
            <AuthComponent
                component={
                    <div style={{ marginTop: 30, marginBottom: 30 }}>
                        <Paper style={styles.stylePaper}>
                            <table
                                style={{
                                    width: "100%",
                                    borderCollapse: "collapse",
                                    padding: 0,
                                }}
                            >
                                <tbody>
                                    <tr>
                                        <td colSpan={2} style={{ padding: 0 }}>
                                            <AppBar
                                                title="Chat"
                                                showMenuIconButton={false}
                                                iconElementRight={
                                                    <div
                                                        style={{
                                                            marginTop: 10,
                                                            marginRight: 10,
                                                        }}
                                                    >
                                                        <StatusChip
                                                            lastUpdate={
                                                                lastUpdated
                                                            }
                                                            lastPing={lastPing}
                                                            lastPong={lastPong}
                                                            status={status}
                                                        />
                                                    </div>
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: 0 }}>
                                            <div
                                                style={{
                                                    height: heightChat,
                                                    overflowX: "hidden",
                                                    overflowY: "auto",
                                                    width: 350,
                                                    borderRight: `1px solid ${blue700}`,
                                                }}
                                            >
                                                <div>
                                                    <List>
                                                        <Subheader>
                                                            Conversaciones
                                                        </Subheader>
                                                        <ListItem
                                                            primaryText={
                                                                titleCola
                                                            }
                                                            initiallyOpen
                                                            disabled
                                                            primaryTogglesNestedList={
                                                                false
                                                            }
                                                            innerDivStyle={{
                                                                color:
                                                                    "#737373",
                                                                fontSize: 15,
                                                                fontWeight: 500,
                                                            }}
                                                            nestedItems={
                                                                persons.isEmpty()
                                                                    ? [
                                                                          <ListItem
                                                                              style={
                                                                                  styles.subListStyle
                                                                              }
                                                                              disabled
                                                                              key={
                                                                                  "message-null-cola"
                                                                              }
                                                                              primaryText="Nadie actualmente en cola."
                                                                          />,
                                                                      ]
                                                                    : persons.toArray()
                                                            }
                                                        />
                                                        <ListItem
                                                            primaryText={
                                                                titleActive
                                                            }
                                                            initiallyOpen
                                                            disabled
                                                            primaryTogglesNestedList={
                                                                false
                                                            }
                                                            innerDivStyle={{
                                                                color:
                                                                    "#737373",
                                                                fontSize: 15,
                                                                fontWeight: 500,
                                                            }}
                                                            nestedItems={
                                                                conversationsActiveList.isEmpty()
                                                                    ? [
                                                                          <ListItem
                                                                              style={
                                                                                  styles.subListStyle
                                                                              }
                                                                              disabled
                                                                              key={
                                                                                  "message-null-active"
                                                                              }
                                                                              primaryText="No hay conversaciones activas."
                                                                          />,
                                                                      ]
                                                                    : conversationsActiveList.toArray()
                                                            }
                                                        />
                                                        <ListItem
                                                            primaryText={
                                                                titleClosed
                                                            }
                                                            initiallyOpen
                                                            disabled
                                                            primaryTogglesNestedList={
                                                                false
                                                            }
                                                            innerDivStyle={{
                                                                color:
                                                                    "#737373",
                                                                fontSize: 15,
                                                                fontWeight: 500,
                                                            }}
                                                            nestedItems={
                                                                conversationsClosedList.isEmpty()
                                                                    ? [
                                                                          <ListItem
                                                                              style={
                                                                                  styles.subListStyle
                                                                              }
                                                                              disabled
                                                                              key={
                                                                                  "message-null-close"
                                                                              }
                                                                              primaryText="No hay conversaciones cerradas."
                                                                          />,
                                                                      ]
                                                                    : conversationsClosedList.toArray()
                                                            }
                                                        />
                                                    </List>
                                                </div>
                                            </div>
                                        </td>
                                        <td style={{ padding: 0 }}>
                                            <div
                                                style={{
                                                    height: heightChat,
                                                    width: 400,
                                                }}
                                            >
                                                <table
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        borderCollapse:
                                                            "collapse",
                                                    }}
                                                >
                                                    <tbody>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    height: 64,
                                                                    padding: 0,
                                                                }}
                                                            >
                                                                <AppBar
                                                                    title={
                                                                        titleConversationMessage
                                                                    }
                                                                    titleStyle={{
                                                                        fontSize: 20,
                                                                    }}
                                                                    showMenuIconButton={
                                                                        false
                                                                    }
                                                                    iconElementRight={
                                                                        finishConversation
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    padding: 0,
                                                                }}
                                                            >
                                                                <div
                                                                    style={
                                                                        styles.inner
                                                                    }
                                                                    ref={
                                                                        "messagesContainer"
                                                                    }
                                                                >
                                                                    <div
                                                                        style={
                                                                            styles.content
                                                                        }
                                                                    >
                                                                        {(
                                                                            messages.get(
                                                                                active
                                                                            ) ||
                                                                            Immutable.List()
                                                                        ).map(
                                                                            (
                                                                                message,
                                                                                key,
                                                                                list
                                                                            ) => {
                                                                                const dir =
                                                                                    person ===
                                                                                    message.author
                                                                                        ? "out"
                                                                                        : "in";
                                                                                const continuation =
                                                                                    _.get(
                                                                                        list.toJS(),
                                                                                        `${
                                                                                            key -
                                                                                            1
                                                                                        }.author`
                                                                                    ) ===
                                                                                    message.author;
                                                                                return (
                                                                                    <Message
                                                                                        text={
                                                                                            message.text
                                                                                        }
                                                                                        dir={
                                                                                            dir
                                                                                        }
                                                                                        continuation={
                                                                                            continuation
                                                                                        }
                                                                                        key={_.get(
                                                                                            message,
                                                                                            "uuid"
                                                                                        )}
                                                                                        idElement={_.get(
                                                                                            message,
                                                                                            "uuid"
                                                                                        )}
                                                                                        type={
                                                                                            message.senderType ||
                                                                                            "user"
                                                                                        }
                                                                                    />
                                                                                );
                                                                            }
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    height: 76,
                                                                    padding: 0,
                                                                }}
                                                            >
                                                                <div
                                                                    style={
                                                                        styles.bottom
                                                                    }
                                                                >
                                                                    <table
                                                                        style={{
                                                                            padding: 0,
                                                                            width:
                                                                                "100%",
                                                                            borderCollapse:
                                                                                "collapse",
                                                                        }}
                                                                    >
                                                                        <tbody>
                                                                            <tr>
                                                                                <td
                                                                                    style={{
                                                                                        padding: 0,
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        style={_.assign(
                                                                                            styles.editor,
                                                                                            {
                                                                                                width: 335,
                                                                                            }
                                                                                        )}
                                                                                    >
                                                                                        <Editor
                                                                                            editorState={
                                                                                                this
                                                                                                    .state
                                                                                                    .editorState
                                                                                            }
                                                                                            onChange={
                                                                                                this
                                                                                                    .handlerChangeText
                                                                                            }
                                                                                            placeholder={
                                                                                                "Escribir mensaje..."
                                                                                            }
                                                                                            keyBindingFn={(
                                                                                                event
                                                                                            ) => {
                                                                                                const currentText = this.state.editorState
                                                                                                    .getCurrentContent()
                                                                                                    .getLastBlock()
                                                                                                    .getText();
                                                                                                if (
                                                                                                    event.keyCode ===
                                                                                                        13 &&
                                                                                                    this.state.editorState
                                                                                                        .getCurrentContent()
                                                                                                        .hasText()
                                                                                                ) {
                                                                                                    // SEND THE MESSAGE
                                                                                                    this.setState(
                                                                                                        {
                                                                                                            editorState: EditorState.createEmpty(),
                                                                                                        }
                                                                                                    );
                                                                                                    this.sendMessage(
                                                                                                        currentText
                                                                                                    );
                                                                                                    return "send-message";
                                                                                                }
                                                                                                return getDefaultKeyBinding(
                                                                                                    event
                                                                                                );
                                                                                            }}
                                                                                            readOnly={
                                                                                                readOnly
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </td>
                                                                                <td
                                                                                    style={{
                                                                                        width:
                                                                                            "47px",
                                                                                        textAlign:
                                                                                            "center",
                                                                                        padding: 0,
                                                                                    }}
                                                                                >
                                                                                    <FloatingActionButton
                                                                                        mini
                                                                                        style={
                                                                                            styles.sendButton
                                                                                        }
                                                                                        disabled={
                                                                                            readOnly
                                                                                        }
                                                                                        onClick={() => {
                                                                                            const currentText = this.state.editorState
                                                                                                .getCurrentContent()
                                                                                                .getLastBlock()
                                                                                                .getText();
                                                                                            if (
                                                                                                this.state.editorState
                                                                                                    .getCurrentContent()
                                                                                                    .hasText()
                                                                                            ) {
                                                                                                // SEND THE MESSAGE
                                                                                                this.setState(
                                                                                                    {
                                                                                                        editorState: EditorState.createEmpty(),
                                                                                                    }
                                                                                                );
                                                                                                this.sendMessage(
                                                                                                    currentText
                                                                                                );
                                                                                                return "send-message";
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <SendIcon />
                                                                                    </FloatingActionButton>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Paper>
                    </div>
                }
                permission={"cgr_chat_use_admin"}
                type={"url"}
            />
        );
    }
}

function mapStateToProps({ login, chatEvaluator }) {
    const active = chatEvaluator.get("active");
    const unreadCount = chatEvaluator.get("unreadCount");
    const updateScroll = chatEvaluator.get("updateScroll");
    const inConversationActive = chatEvaluator.get("inConversationActive");
    const userDataActive = chatEvaluator.get("userDataActive");
    const status = chatEvaluator.get("status");
    const lastPing = chatEvaluator.get("lastPing");
    const lastPong = chatEvaluator.get("lastPong");
    const lastUpdated = chatEvaluator.get("lastUpdated");
    return {
        queue: chatEvaluator.get("queue"),
        person: _.get(login.get("profile"), "_id"),
        conversations: chatEvaluator.get("conversations"),
        active,
        userDataActive,
        unreadCount,
        updateScroll,
        inConversationActive,
        messages: chatEvaluator.get("messages"),
        status,
        lastPong,
        lastPing,
        lastUpdated,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            changeStateConnection,
            setConversationId,
            addMessage,
            notificationUserAdded,
            notificationRemoveUser,
            addActiveConversation,
            selectActiveConversation,
            updateInConversation,
            selectUserActiveConversation,
            updateCountMessageUnread,
            addMessageToConversation,
            notificationConversationClosed,
            markAllMessagesAsRead,
            cleanState,
            updateHeartbeat,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatDashboard);
