import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Title from './Title';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import {LBLCOMPANIES} from '../../../constants/labels';
import RefreshIndicator from 'material-ui/RefreshIndicator';
import {getCountries, getDepartments, getCities} from '../../fields/Locations/actions';
import { saveCountryInQuestionDepartment, saveDepartmentInQuestionDepartment, deleteDepartmentInQuestionDepartment } from './location/actions';
import SelectFieldFormat from '../../fields/SelectFieldFormat/SelectFieldFormat';

const styleWidthContent = {
    minWidth: 240
};

const style = {
  container: {
    position: 'relative',
  },
  refresh: {
    position: 'absolute',
    zIndex: 999
  },
};
class QuestionSelectCity extends Component {

    constructor(props) {
        super(props);
        this.state = {
            arrayDepartment: [],
            arrayCity: [],
            showLoadingDepartament:false,
            showLoadingCity: false
        };
        this._onChangeCountry = this._onChangeCountry.bind(this);
        this._onChangeDepartment = this._onChangeDepartment.bind(this);
        this._onChangeCity = this._onChangeCity.bind(this);
    }

    UNSAFE_componentWillMount() {
        const {
            getCountries,
            getDepartments,
            getCities,
            valueDepartment,
            valueCountry
        } = this.props;
        getCountries();
        if(!_.isNull(valueCountry) && !_.isUndefined(valueCountry)){
            this.setState({
                arrayDepartment: [],
                showLoadingDepartament: true
            });
            getDepartments(valueCountry)
                .then((data) => {
                    if (_.get(data, 'error') || (_.get(data, 'payload.status') !== 200)) {
                        console.log("Error",_.get(data, 'error'));
                        this.setState({ showLoadingDepartament: false });
                    } else {
                        this.setState({
                            arrayDepartment: _.get(data, 'payload.data', []),
                            showLoadingDepartament: false
                        });
                    }
                }, (reason) => {
                    this.setState({ showLoadingDepartament: false });
                    console.log("Error",reason);
                })
                .catch((data) => {
                    this.setState({ showLoadingDepartament: false });
                    console.log("Error",data);
                });
        }
        if(!_.isNull(valueDepartment) && !_.isUndefined(valueDepartment)){
            this.setState({
                arrayCity: [],
                showLoadingCity: false
            });
            getCities(valueDepartment)
                .then((data) => {
                    if (_.get(data, 'error') || (_.get(data, 'payload.status') !== 200)) {
                        console.log("Error",_.get(data, 'error'));
                        this.setState({ showLoadingCity: false });
                    } else {
                        this.setState({
                            arrayCity:_.get(data, 'payload.data', []),
                            showLoadingCity: false
                        });
                    }
                }, (reason) => {
                    this.setState({ showLoadingCity: false });
                    console.log("Error",reason);
                })
                .catch((data) => {
                    this.setState({ showLoadingCity: false });
                    console.log("Error",data);
                });
        }
    }

    _onChangeCountry(e, index, valueCountry) {
        const {
            getDepartments,
            idQuestion,
            _handlerUpdateValue,
            saveCountryInQuestionDepartment,
            deleteDepartmentInQuestionDepartment
        } = this.props;
        _handlerUpdateValue(e, "");
        deleteDepartmentInQuestionDepartment(idQuestion);
        saveCountryInQuestionDepartment(idQuestion, valueCountry);
        this.setState({
            arrayDepartment: [],
            arrayCity: [],
            showLoadingDepartament: true
        })
        getDepartments(valueCountry)
            .then((data) => {
                if (_.get(data, 'error') || (_.get(data, 'payload.status') !== 200)) {
                    console.log("Error",_.get(data, 'error'));
                    this.setState({ showLoadingDepartament: false });
                } else {
                    this.setState({
                      arrayDepartment:_.get(data, 'payload.data', []),
                      showLoadingDepartament: false
                  });
                }
            }, (reason) => {
                this.setState({ showLoadingDepartament: false });
                console.log("Error",reason);
            })
            .catch((data) => {
                this.setState({ showLoadingDepartament: false });
                console.log("Error",data);
            });
    }

    _onChangeDepartment(e, index, valueDepartment) {
        const {
            getCities,
            _handlerUpdateValue,
            idQuestion,
            saveDepartmentInQuestionDepartment
        } = this.props;
        _handlerUpdateValue(e, "");
        this.setState({
            arrayCity: [],
            showLoadingCity: true
        });
        saveDepartmentInQuestionDepartment(idQuestion, valueDepartment);
        getCities(valueDepartment)
            .then((data) => {
                if (_.get(data, 'error') || (_.get(data, 'payload.status') !== 200)) {
                    console.log("Error",_.get(data, 'error'));
                    this.setState({ showLoadingCity: false });
                } else {
                    this.setState({
                        arrayCity:_.get(data, 'payload.data', []),
                        showLoadingCity: false
                    });
                }
            }, (reason) => {
                this.setState({ showLoadingCity: false });
                console.log("Error",reason);
            })
            .catch((data) => {
                this.setState({ showLoadingCity: false });
                console.log("Error",data);
            });
    }

    _onChangeCity(e, index, valueCity) {
        const { _handlerUpdateValue } = this.props;
        _handlerUpdateValue(e, valueCity);
    }

    render() {
        const {
            question,
            countries,
            index,
            valueCountry,
            valueDepartment,
            valueQuestion
        } = this.props;
        return (
            <div style={styleWidthContent}>
                <div style={{marginBottom: 10}}>
                    <Title question={question} index={index}/>
                </div>
                <SelectFieldFormat
                    config={{
                        floatingLabelText: LBLCOMPANIES.country,
                        autoWidth: true,
                        fullWidth: true,
                        value: valueCountry,
                        onChange: this._onChangeCountry
                    }}
                >
                    {
                        _.orderBy(countries, ['name']).map((item) => {
                            return (
                                <MenuItem key={item._id} value={item._id} primaryText={_.capitalize(item.name)}/>
                            );
                        })
                    }
                </SelectFieldFormat>
                <div style={style.container}>
                    <SelectFieldFormat
                        config={{
                            floatingLabelText: LBLCOMPANIES.department,
                            autoWidth: true,
                            fullWidth: true,
                            disabled: (this.state.showLoadingDepartament || _.isEmpty(this.state.arrayDepartment)),
                            value: valueDepartment,
                            onChange: this._onChangeDepartment
                        }}
                    >
                        {
                            _.orderBy(this.state.arrayDepartment, ['name']).map((item) => {
                                return (
                                    <MenuItem key={item._id} value={item._id} primaryText={_.capitalize(item.name)}/>
                                );
                            })
                        }
                    </SelectFieldFormat>
                     <RefreshIndicator
                         size={40}
                         left={100}
                         top={20}
                         status={this.state.showLoadingDepartament ? "loading" :"hide"}
                         style={style.refresh}
                     />
               </div>
                <div style={style.container}>
                    <SelectFieldFormat
                        config={{
                            floatingLabelText: LBLCOMPANIES.city,
                            autoWidth: true,
                            fullWidth: true,
                            disabled: (this.state.showLoadingCity || _.isEmpty(this.state.arrayCity)),
                            value: valueQuestion,
                            onChange: this._onChangeCity
                        }}
                    >
                        {
                            _.orderBy(this.state.arrayCity, ['name']).map((item) => {
                                return (
                                    <MenuItem key={item._id} value={item._id} primaryText={_.capitalize(item.name)}/>
                                );
                            })
                        }
                    </SelectFieldFormat>
                    <RefreshIndicator
                        size={40}
                        left={100}
                        top={20}
                        status={this.state.showLoadingCity ? "loading" :"hide"}
                        style={style.refresh}
                    />
                </div>
            </div>
        );
    }
}

QuestionSelectCity.propTypes = {
    question: PropTypes.object.isRequired
};

function mapStateToProps({ master, questionsSurvey, questionLocation }, ownProps) {
    return {
        countries: master.countries,
        valueCountry: _.get(questionLocation.get("locations"), `${_.get(ownProps, 'idQuestion', 'default')}.country`),
        valueDepartment: _.get(questionLocation.get("locations"), `${_.get(ownProps, 'idQuestion', 'default')}.department`),
        valueQuestion: _.get(questionsSurvey.get('answered').toObject(), _.get(ownProps, 'idQuestion'))
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCountries,
        getDepartments,
        getCities,
        saveCountryInQuestionDepartment,
        saveDepartmentInQuestionDepartment,
        deleteDepartmentInQuestionDepartment
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionSelectCity);
