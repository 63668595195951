import React, { Component } from "react";
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn,
    TableFooter,
} from "material-ui/Table";
import { blue700, blue600 } from "material-ui/styles/colors";
import { Row, Col } from "react-flexbox-grid";
import Paper from "material-ui/Paper";
import { connect } from "react-redux";
import RaisedButton from "material-ui/RaisedButton";
import _ from "lodash";
import { LBLEMPLOYESS } from "../../constants/labels";
import { FetchCompanyParam } from "../../actions/info/companies_info_form";
import { toggleSnackbar } from "../../actions/commons";
import SendMessageModal from "../../components/ModalSendEmail/modalSendEmail";
import ButtonBack from "../../components/fields/buttonBack/buttonBack";
import { openModalSendEmailId } from "../ModalSendEmail/action";
import SpinnerLoading from "../SpinnerLoading/componentSpinner";
import AuthComponent from "../AuthComponent";
import TablePagination from "../TablePagination/tablePagination";
import TagsEmployee from "../TagsEmployee/TagsEmployee";

const stylePaper = {
    marginTop: 40,
    marginBottom: 20,
};

let urlBack;

class SendMasiveEmailEmployees extends Component {
    UNSAFE_componentWillMount() {
        const { FetchCompanyParam, params } = this.props;
        FetchCompanyParam(_.get(params, "company"));
        urlBack = `/admin/projects/info/${_.get(params, "profile")}/`;
    }

    handleModalSendEmail = () => {
        const { selected, openModalSendEmailId } = this.props;
        openModalSendEmailId(selected, "employee");
    };

    render() {
        const {
            businessName,
            params,
            selected,
            textTag,
            textValueTag,
            status,
        } = this.props;
        const body = [
            {
                field: "id",
                title: "Identificación",
                filter: true,
            },
            {
                field: "nameText",
                title: "Nombre",
                filter: true,
            },
            {
                field: "lastNameText",
                title: "Apellido(s)",
                filter: true,
            },
            {
                field: "email",
                title: "Correo electrónico",
            },
        ];
        let extra = { project: _.get(params, "_id") };
        if (!_.isNull(textValueTag) && !_.isNull(textTag)) {
            extra = _.chain(extra)
                .set("textTag", textTag)
                .set("textValueTag", textValueTag)
                .value();
        }
        if (!_.isEqual(status, "all") && !_.isNull(status)) {
            extra = _.chain(extra).set("status", status).value();
        }
        return (
            <AuthComponent
                component={
                    <Row>
                        <Col xsOffset={2} xs={8}>
                            <Paper style={stylePaper}>
                                <ButtonBack
                                    url={`${urlBack}${_.get(
                                        params,
                                        "_id"
                                    )}/${_.get(params, "company")}`}
                                />
                                <Row>
                                    <Col xs>
                                        <div
                                            style={{
                                                width: "100%",
                                                background: blue600,
                                                paddingTop: 5,
                                                paddingBottom: 5,
                                                color: "#FFF",
                                            }}
                                        >
                                            <h2
                                                style={{
                                                    textAlign: "center",
                                                    fontWeight: 400,
                                                }}
                                            >
                                                {businessName}
                                            </h2>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <div
                                            style={{
                                                width: "100%",
                                                background: blue700,
                                                paddingTop: 5,
                                                paddingBottom: 5,
                                                color: "#FFF",
                                            }}
                                        >
                                            <h1
                                                style={{
                                                    textAlign: "center",
                                                    fontWeight: 400,
                                                }}
                                            >{`${LBLEMPLOYESS.titleSendEmailMasive}`}</h1>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <TagsEmployee
                                            companyId={_.get(params, "company")}
                                            URL={`/projects/employees/${_.get(
                                                params,
                                                "company"
                                            )}/${_.get(params, "_id")}`}
                                            version={2}
                                            extra={{
                                                company: _.get(
                                                    params,
                                                    "company"
                                                ),
                                                profile: _.get(
                                                    params,
                                                    "profile"
                                                ),
                                            }}
                                            body={body}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <TablePagination
                                            name={"configemployees"}
                                            URL={`/projects/employees/${_.get(
                                                params,
                                                "company"
                                            )}/${_.get(params, "_id")}`}
                                            version={2}
                                            body={body}
                                            extra={extra}
                                            selection
                                            fieldSelection={"_id"}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs>
                                        <AuthComponent
                                            component={
                                                <RaisedButton
                                                    label={`${
                                                        LBLEMPLOYESS.buttons
                                                            .sendingBulkMail
                                                    } (${_.size(selected)})`}
                                                    secondary
                                                    style={{
                                                        margin: 20,
                                                        float: "right",
                                                    }}
                                                    disabled={
                                                        _.size(selected) === 0
                                                    }
                                                    onClick={
                                                        this
                                                            .handleModalSendEmail
                                                    }
                                                />
                                            }
                                            permission={
                                                "cgr_project_send_massive_email_associate_employees"
                                            }
                                            type={"component"}
                                        />
                                    </Col>
                                </Row>
                            </Paper>
                            <SpinnerLoading />
                            <SendMessageModal />
                        </Col>
                    </Row>
                }
                permission={
                    "cgr_project_send_massive_email_associate_employees"
                }
                type={"url"}
            />
        );
    }
}

function mapStateToProps(
    { companies, employeesAssocProject, login, tagsEmployee, tablePagination },
    owersProps
) {
    return {
        businessName: _.get(companies, "company.data.businessName", ""),
        profile: login.get("profile"),
        textValueTag: tagsEmployee.get("textValueTag"),
        textTag: tagsEmployee.get("textTag"),
        selected: tablePagination.get("selected"),
        status: tablePagination.get("status"),
    };
}

export default connect(mapStateToProps, {
    FetchCompanyParam,
    toggleSnackbar,
    openModalSendEmailId,
})(SendMasiveEmailEmployees);
