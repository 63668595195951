import { Map, List } from 'immutable';
import { Observable } from 'rxjs';
import _ from 'lodash';
import { axiosAPI_V2 as axios } from '../../../middleware/api';
import createReducer from '../../../lib/createReducer';

const SEARCH_TEXT = 'nss/searchBox/SEARCH_TEXT';
const SEARCH_TEXT_ERROR = 'nss/searchBox/SEARCH_TEXT_ERROR';
const SEARCH_TEXT_TYPING = 'nss/searchBox/SEARCH_TEXT_ERROR';
const SEARCH_TEXT_LOADING = 'nss/searchBox/SEARCH_TEXT_LOADING';
const SEARCH_TEXT_FULFILLED = 'nss/searchBox/SEARCH_TEXT_FULFILLED';
const SEARCH_TEXT_IN_PROGRESS = 'nss/searchBox/SEARCH_TEXT_IN_PROGRESS';
const CLEAN = 'nss/searchBox/CLEAN';

const initialState = Map({
    loading: false,
    data: List(),
});
export default createReducer(initialState, {
    [SEARCH_TEXT_FULFILLED]: (state, { payload: { data } }) => state.set('loading', false).set('data', List(data)),
    [SEARCH_TEXT_IN_PROGRESS]: state => state.set('loading', true).set('data', List()),
    [SEARCH_TEXT_LOADING]: state => state.set('loading', true).set('data', List()),
    [CLEAN]: () => initialState,
});

export function searchText(text, project) {
    return {
        type: SEARCH_TEXT_TYPING,
        payload: {
            text,
            project,
        },
    };
}

function isEmptyString(s) {
    return _.isString(s) ? !!_.trim(s) : _.isEmpty(s);
}

export const getTypeOfRecord = {
    QUESTION: 'Pregunta',
    GROUPER: 'Agrupador',
    TAG_LABEL: 'Etiqueta',
};

export const typingSearchEpic$ = action$ =>
    action$
        .ofType(SEARCH_TEXT_TYPING)
        .concatMap(action => Observable.of({ ...action, type: SEARCH_TEXT }, { type: SEARCH_TEXT_LOADING }));

export const searchEpic$ = action$ =>
    action$
        .ofType(SEARCH_TEXT)
        .debounceTime(200)
        .filter(action => isEmptyString(action.payload.text))
        .switchMap(action => {
            const { payload: { project, text } } = action;
            const encodedText = encodeURI(text);
            const promise = axios.get(`/reports/filter/${project}`, {
                params: {
                    search: encodedText,
                },
            });
            return Observable.fromPromise(promise)
                .map(response => response.data)
                .map(data => ({
                    type: SEARCH_TEXT_FULFILLED,
                    payload: {
                        data,
                    },
                }))
                .catch(error => Observable.of({ type: SEARCH_TEXT_ERROR, payload: { error } }))
                .startWith({ type: SEARCH_TEXT_IN_PROGRESS });
        });
