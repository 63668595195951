import React, {Component} from 'react';
import {Row, Col} from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';
import {blue700, orange700, green700, red700, purple700, brown700} from 'material-ui/styles/colors';
import {connect} from 'react-redux';
import {browserHistory} from 'react-router';
import moment from 'moment';
import _ from 'lodash';
import TablePagination from '../../TablePagination/tablePagination';

class RefereesProjects extends Component {

    constructor(props) {
        super(props);
    }

    UNSAFE_componentWillMount() {
        const { profile } = this.props;
        if (_.isNull(_.get(profile, 'person._id', null))) {
            console.error('No hay un usuario logeado');
            browserHistory.push(`/login`);
        }
    }

    render() {
        const { projects, profile } = this.props;
        const body = [
            {
                field: "name",
                title: "Proyecto",
                filter: true,
                colspan: 2
            },
            {
                field: "company.businessName",
                title: "Empresa",
                colspan: 2,
                order: false
            },
            {
                field: "time.openDate",
                title: "Fecha inicio"
            },
            {
                field: "time.closeDate",
                title: "Fecha final"
            },
            {
                actions: true,
                title: "Operaciones",
                colspan: 2
            }
        ];

        return (
            <div>
                <Row>
                    <Col xs={12} mdOffset={1} md={10}>
                        <Paper style={{marginTop: 20,marginBottom: 20}}>
                            <Row>
                                <Col xs>
                                    <TablePagination
                                        name={`projectsByReferee`}
                                        URL={`/projects/referees/person/${_.get(profile, 'person._id')}`}
                                        version={1}
                                        body={body}
                                    />
                                </Col>
                            </Row>
                        </Paper>
                    </Col>
                </Row>
            </div>
        );
    }
}

function mapStateToProps({refereesDashboard, login}) {
    return {
        projects: refereesDashboard.get('projects'),
        referee: refereesDashboard.get('referee'),
        profile: login.get('profile')
    }
}

export default connect(mapStateToProps)(RefereesProjects);
