import PropTypes from "prop-types";
import React, { Component } from "react";
import TitleComponent from "./titleComponent";
import ChildrenComponent from "./childrenComponent";
import { blue100, blue600, blue900, indigo900, grey800, grey900 } from "material-ui/styles/colors";
import * as _ from "lodash";
import { arrayOrientation, arrayAlign } from "./types";

class ColumnComponent extends Component {
    static propTypes = {
        columns: PropTypes.oneOf([1, 2, 3, 4, 5]).isRequired,
        numColumns: PropTypes.oneOf([1, 2, 3, 4, 5]).isRequired,
        rows: PropTypes.number.isRequired,
        index: PropTypes.number.isRequired,
        title: PropTypes.object,
        orientation: PropTypes.oneOf(arrayOrientation).isRequired,
        style: PropTypes.object,
        children: PropTypes.array,
        contentAlign: PropTypes.oneOf(arrayAlign),
        menu: PropTypes.array.isRequired,
        projectId: PropTypes.string.isRequired,
        colors: PropTypes.array.isRequired,
        maxRanking: PropTypes.number.isRequired,
    };

    render() {
        const {
            menu,
            columns,
            numColumns,
            rows,
            index,
            title,
            orientation,
            style,
            children,
            contentAlign,
            projectId,
            colors,
            maxRanking,
        } = this.props;
        const align = !_.isUndefined(contentAlign) && !_.isNull(contentAlign) ? contentAlign : "left";
        const backgroundColorChild = "#F8F8F8";
        const styleTable = {
                width: "100%",
                borderCollapse: "collapse",
                tableLayout: "fixed",
                padding: 0,
            },
            tableCellStyle = {
                margin: 0,
                verticalAlign: "top",
                textAlign: align,
            },
            styleContentSection = {
                verticalAlign: "top",
            };

        const styleSection = _.assign(styleContentSection, getBorders(_.get(style, "borders", [])));

        return (
            <td className="print_pdf_column" colSpan={columns} style={styleSection}>
                <table className="data-tables" style={styleTable}>
                    <tbody>
                        <tr>
                            <td style={_.assign(tableCellStyle, { padding: "0px 5px" })}>
                                <TitleComponent
                                    text={_.get(title, "text", "")}
                                    style={_.get(title, "style", { margin: 0, padding: 0 })}
                                    code={_.get(title, "code", "")}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td style={_.assign(tableCellStyle, { padding: "0px 5px" })}>
                                <div
                                    style={_.assign(_.get(style, "content", {}), {
                                        boxSizing: "border-box",
                                        padding: 5,
                                    })}
                                >
                                    {_.map(children, (child, indexChildren) => (
                                        <ChildrenComponent
                                            key={`row-column-${index}-children-${indexChildren}`}
                                            index={indexChildren}
                                            numColumns={numColumns}
                                            contentColumns={columns}
                                            title={_.get(child, "title", {})}
                                            orientation={orientation}
                                            type={_.get(child, "type")}
                                            chartProps={_.get(child, "chartProps", {})}
                                            textProps={_.get(child, "textProps", {})}
                                            menuProps={_.get(child, "menuProps", {})}
                                            menu={menu}
                                            projectId={projectId}
                                            colors={colors}
                                            contentAlign={align}
                                            styleContent={_.get(child, "style", {})}
                                            maxRanking={maxRanking}
                                        />
                                    ))}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        );
    }
}

function getBorders(borders) {
    const propsBorder = _.groupBy(borders, "position");
    const keysBorder = _.keys(propsBorder);
    return _.chain(keysBorder)
        .flatMap(key => [
            {
                key: `border${_.capitalize(key)}Color`,
                value: _.get(propsBorder, `${key}.0.color`, "#EEE"),
            },
            {
                key: `border${_.capitalize(key)}Style`,
                value: _.get(propsBorder, `${key}.0.style`, "solid"),
            },
            {
                key: `border${_.capitalize(key)}Width`,
                value: _.get(propsBorder, `${key}.0.size`, 0),
            },
        ])
        .reduce((acc, current) => _.assign({}, acc, { [current.key]: current.value }), {})
        .value();
}

export default ColumnComponent;
