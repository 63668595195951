import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, addArrayValue } from 'redux-form';
import { Row, Col } from 'react-flexbox-grid';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import MenuItem from 'material-ui/MenuItem';
import TextField from 'material-ui/TextField';
import { addUser, getUser, clearUser, getDocumentsTypes } from '../containers/users/addUserComponent/actions';
import { getARLs } from '../actions/info/companies_info_form';
import { FetchProfilesNames } from '../actions/grid/profile_grid_form';
import IconButton from 'material-ui/IconButton';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import _ from 'lodash';
import { axiosAPI } from '../middleware/api';
import { browserHistory } from 'react-router';
import {toggleSnackbar} from '../actions/commons';
import ButtonBack from '../components/fields/buttonBack/buttonBack';
import SelectFieldFormat from '../components/fields/SelectFieldFormat/SelectFieldFormat';
import { LBLUSERS } from '../constants/labels';
import AuthComponent from '../components/AuthComponent';
import Checkbox from 'material-ui/Checkbox';
import is from 'is_js';

const style = {
        margin: 20,
        float: 'right'
    },
    stylePaper = {
        marginTop: 20,
        marginBottom: 20
    };

class ListMasterNews extends Component {

    constructor(props) {
        super(props);
        this.state={
          chekedSendEmail: true
        }
        this._saveUser = this._saveUser.bind(this);
        this._onCheckSendEmail = this._onCheckSendEmail.bind(this);
    }

    UNSAFE_componentWillMount() {
        const {
            resetForm,
            FetchProfilesNames,
            getDocumentsTypes,
            getUser,
            clearUser,
            getARLs,
            params
        } = this.props;
        resetForm();
        clearUser();
        FetchProfilesNames();
        getDocumentsTypes();
        getARLs();
        if (_.has(this, 'props.params.user')) {
            getUser(params.user);
        }
    }

    _saveUser(formData) {
        const { addUser, toggleSnackbar, resetForm, params } = this.props;
        const editMode = _.has(params, 'user');
        formData = _.set(formData, 'profiles',formData.profiles.map(function (profile) {
                        return _.get(profile, '_id');
                    }));
        addUser(editMode ? formData : _.omit(formData, '_id'), editMode ? false: this.state.chekedSendEmail, editMode)
            .then((data) => {
                if(_.get(data, 'error') || (_.get(data, 'payload.status') !== 200)){
                    toggleSnackbar(true, LBLUSERS.msg.errorSave);
                } else {
                    resetForm();
                    toggleSnackbar(true, LBLUSERS.msg.successSave);
                    browserHistory.push(`/admin/users/grid`);
                }
            }, (reason) => {
                toggleSnackbar(true, LBLUSERS.msg.errorSave);
            })
            .catch((data) => {
                toggleSnackbar(true, LBLUSERS.msg.errorSave);
            });
    }

    _onCheckSendEmail(e, cheked){
        this.state ={
          chekedSendEmail: cheked
        };
    }

    render() {
        const {
            asyncValidating,
            fields: {
                documentType,
                idNumber,
                name,
                lastName,
                email,
                role,
                arl,
                profiles
            },
            arrayProfiles,
            handleSubmit,
            params,
            arlSeleted,
            documentsTypes
        } = this.props;
        const editMode = _.has(params, 'user');
        const editModeRole = (role.value === "employee" || role.value === "company" || role.value === "referee") && editMode;
        const editModeRoleCompanyARL = (role.value === "arl" || role.value === "company") && editMode;
        return (
            <Row>
                <Col xs={12} mdOffset={2} md={8}>
                    <Paper style={stylePaper}>
                        <Row>
                            <Col xsOffset={1} xs={10}>
                                <h1 style={{textAlign: 'center', fontWeight: 400}}>{editMode ? LBLUSERS.titleEdit : LBLUSERS.titleCreate} </h1>
                                <ButtonBack url={`/admin/users/grid`}/>
                                <form onSubmit={handleSubmit(this._saveUser)}>
                                    <Row>
                                        <Col xs>
                                            <SelectFieldFormat
                                                config={{
                                                  floatingLabelText: LBLUSERS.typeId,
                                                  value: documentType.value,
                                                  disabled: editMode,
                                                  errorText: documentType.touched && documentType.error ? documentType.error : '',
                                                  onChange: (e, index, value) => documentType.onChange(value)
                                              }}
                                            >
                                                {_.map(documentsTypes, (item) => {
                                                    return (
                                                        <MenuItem key={item._id} value={item._id} primaryText={item.value}/>
                                                    );
                                                })}
                                                <MenuItem value="NIT" primaryText="NIT Empresarial" style={{display: 'none'}}/>
                                            </SelectFieldFormat>
                                        </Col>
                                        <Col xs>
                                            <TextField
                                                hintText=""
                                                floatingLabelText={LBLUSERS.id}
                                                type="text"
                                                disabled={editMode}
                                                errorText={idNumber.touched && idNumber.error ? idNumber.error : '' }
                                                {...idNumber}
                                            />
                                            {asyncValidating === 'idNumber' && <i />}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs>
                                            <TextField
                                                floatingLabelText={LBLUSERS.name}
                                                disabled={editModeRole}
                                                errorText={name.touched && name.error ? name.error : '' }
                                                {...name}
                                            />
                                        </Col>
                                        <Col xs>
                                            <TextField
                                                floatingLabelText={LBLUSERS.lastName}
                                                disabled={editModeRole}
                                                errorText={lastName.touched && lastName.error ? lastName.error : '' }
                                                {...lastName}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs>
                                            <TextField
                                                floatingLabelText={LBLUSERS.email}
                                                disabled={editModeRoleCompanyARL ? false : editModeRole}
                                                errorText={email.touched && email.error ? email.error : '' }
                                                {...email}
                                            />
                                        </Col>
                                        <Col xs>
                                            <SelectFieldFormat
                                                config={{
                                                    floatingLabelText: LBLUSERS.profile,
                                                    value: role.value,
                                                    disabled: editModeRoleCompanyARL,
                                                    errorText: role.touched && role.error ? role.error : '',
                                                    onChange: (e, index, value) => role.onChange(value)
                                                }}
                                            >
                                                <MenuItem value="admin" primaryText="Administrador"/>
                                                <MenuItem value="employee" primaryText="Empleado"/>
                                                <MenuItem value="referee" primaryText="Evaluador"/>
                                                <MenuItem value="company" primaryText="Empresa" style={{display: 'none'}}/>
                                                <MenuItem value="arl" primaryText="ARL" />
                                            </SelectFieldFormat>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs>
                                            {!editMode &&
                                                <Checkbox
                                                     label="Enviar notificación de creación"
                                                     defaultChecked={true}
                                                     style={{padding:20}}
                                                     onCheck={this._onCheckSendEmail}
                                                 />
                                             }
                                        </Col>
                                        <Col xs>
                                            {_.isEqual(role.value, 'arl') &&
                                                <SelectFieldFormat
                                                    config={{
                                                        floatingLabelText: LBLUSERS.ARL,
                                                        value: arl.value,
                                                        autoWidth: true,
                                                        errorText: arl.touched && arl.error ? arl.error : '',
                                                        onChange: (e, index, value) => arl.onChange(value)
                                                    }}
                                                >
                                                    {
                                                        _.map(_.orderBy(arlSeleted, ['value']), item => {
                                                            return (
                                                                <MenuItem key={_.get(item, '_id')} value={_.get(item, '_id')} primaryText={_.get(item, 'value')}/>
                                                            );
                                                        })
                                                    }
                                                </SelectFieldFormat>
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs>
                                            <h2 style={{fontWeight: 200, textAlign: 'center', marginTop: 30}}>{LBLUSERS.titleProfileSelected}</h2>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: 30}}>
                                        <Col xs={12} mdOffset={2} md={8}>
                                            {profiles.map((profile, index) =>
                                                <Row key={index}>
                                                    <Col xs={9}>
                                                        <h3 style={{fontWeight: 200}}>{profile.name.value}</h3>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <div>
                                                            <IconButton
                                                                children={<DeleteIcon/>}
                                                                tooltip={LBLUSERS.tooltips.btnDelete}
                                                                tooltipPosition="top-center"
                                                                onClick={() => { profiles.removeField(index); }}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                              )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} mdOffset={2} md={7}>
                                            <SelectFieldFormat
                                                config={{
                                                    floatingLabelText: LBLUSERS.profiles,
                                                    fullWidth: true,
                                                    onChange: (e, index, value) => {
                                                        let exit = true;
                                                        profiles.map(function(profile) {
                                                            if(_.isEqual(_.get(value, '_id')+"", _.get(profile, '_id.value')+"")){
                                                                exit = false;
                                                            }
                                                        });
                                                        if(exit){
                                                            profiles.addField({
                                                                _id: _.get(value, '_id'),
                                                                name: _.get(value, 'name')
                                                            });
                                                        }
                                                    }
                                                }}
                                            >
                                                {_.map(arrayProfiles, (profile, index) => {
                                                    return (
                                                        <MenuItem key={`profile${index}`} value={profile} primaryText={_.get(profile, 'name')}/>
                                                    );
                                                })}
                                            </SelectFieldFormat>
                                        </Col>
                                    </Row>
                                    <AuthComponent
                                        component={<RaisedButton
                                                        type="submit"
                                                        label={!editMode ? LBLUSERS.buttons.save : LBLUSERS.buttons.update}
                                                        secondary={true}
                                                        style={style}
                                                    />}
                                        permission={!editMode ? "cgr_users_create" : "cgr_users_update"}
                                        type={"component"}
                                    />
                                </form>
                            </Col>
                        </Row>
                    </Paper>
                </Col>
            </Row>
        );
    }
}

const validate = values => {
    const errors = {};
    if (!values.email) {
        errors.email = LBLUSERS.validations.email;
    } else if (!is.email(values.email)) {
        errors.email = LBLUSERS.validations.invalidEmail;
    }
    if (!values.name) {
        errors.name = LBLUSERS.validations.name;
    }
    if (!values.lastName) {
        errors.lastName = LBLUSERS.validations.lastName;
    }
    if (!values.idNumber) {
        errors.idNumber = LBLUSERS.validations.id;
    }
    if (!values.documentType) {
        errors.documentType = LBLUSERS.validations.typeId;
    }
    if (!values.role) {
        errors.role = LBLUSERS.validations.role;
    } else {
        if (!values.arl && _.isEqual(values.role, 'arl')) {
            errors.arl = LBLUSERS.validations.arl;
        }
    }
    return errors;
};

const asyncValidate = (values) => {
    return new Promise((resolve, reject) => {
        axiosAPI.get(`/users/id`, {
                params: { id: values.idNumber }
            })
            .then((data) => {
                let resp = _.get(data.data, 'data');
                if(!_.isNull(resp)){
                    if (_.isNull(values._id)) {
                        reject({idNumber: LBLUSERS.validations.idExist});
                    } else {
                        resolve();
                    }
                } else {
                    resolve();
                }
            })
            .catch((data) => {
                console.log('Error', data);
            });
    });
};

function mapStateToProps({userAdd, profileForm, master}, ownProps) {
    if (_.has(ownProps, 'params.user')) {
        let user = userAdd.get('oneUser');
        const profiles = _.get(user, 'profiles', []);
        if(!_.isNull(user)){
            let typeDocumentDefault = _.isEqual(_.get(user, 'role'), 'company') ? 'NIT' : '';
            return  {
                arrayProfiles: profileForm.get('all').toArray(),
                documentsTypes: master.documentsTypes,
                arlSeleted: master.arls,
                initialValues: {
                    _id: _.get(user, '_id'),
                    documentType: _.get(user, 'person.documentType', typeDocumentDefault),
                    idNumber: _.get(user, 'person.id'),
                    name: _.get(user, 'person.name'),
                    lastName: _.get(user, 'person.lastName'),
                    email: _.get(user, 'person.email'),
                    role: _.get(user, 'role'),
                    arl: _.get(user, 'arl'),
                    profiles: _.map(profiles, function (profile) {
                                    return {
                                        _id: _.get(profile, '_id'),
                                        name: _.get(profile, 'name')
                                    }
                                })
                }
            };
        }
    } else {
        return  {
            arrayProfiles: profileForm.get('all').toArray(),
            documentsTypes: master.documentsTypes,
            arlSeleted: master.arls,
            initialValues: {
                _id: null,
                documentType: '',
                idNumber: '',
                name: '',
                lastName: '',
                email: '',
                role: '',
                arl: '',
                profiles: []
            }
        };
    }
}

ListMasterNews.propTypes = {
    fields: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    router: PropTypes.object
};

export default reduxForm({
    form: 'ListMasterNewForm',
    fields: [
        "_id",
        "documentType",
        "idNumber",
        "name",
        "lastName",
        "email",
        "role",
        "arl",
        'profiles[]._id',
        'profiles[].name'
    ],
    asyncValidate,
    asyncBlurFields: ['idNumber'],
    validate
}, mapStateToProps, {addUser, toggleSnackbar, FetchProfilesNames, getUser, clearUser, getDocumentsTypes, getARLs})(ListMasterNews);
