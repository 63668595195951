import React, {Component} from 'react';
import {ListItem} from 'material-ui/List';
import Divider from 'material-ui/Divider';
import Avatar from 'material-ui/Avatar';
import Person from 'material-ui/svg-icons/social/person';

function

getAvatar(size, avatar) {
    if (size < 0.5) {
        return <rightAvatar {...avatar}/>
    } else {
        return <leftAvatar {...avatar}/>
    }
}

export default class Message extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const random = Math.random();
        const avatar = <Avatar icon={<Person />}/>;
        return (
            <div>
                <ListItem
                    leftAvatar={<Avatar icon={<Person />} />}
                    secondaryText={
                        <p>
                         {this.props.message.body}
                        </p>
                     }
                    secondaryTextLines={2}
                    disabled={true}
                />
                <Divider inset={true}/>
            </div>
        );
    }
}
