import { POST_SECTION, DELETE_SECTION } from '../constants/index';
import { axiosAPI } from '../middleware/api';
import _ from 'lodash';

export function createSection(props, modeEdit) {

    function getProps(){
        let ListDataQuestion = _.get(props, 'collections').map((value, index) => {
            return _.chain(value)
                    .set("order", index + 1)
                    .set("srtSubQuestions", _.isUndefined(_.get(value, 'srtSubQuestions[0]')) ? "None" : _.get(value, 'srtSubQuestions') instanceof Array ? _.get(value, 'srtSubQuestions[0]') : _.get(value, 'srtSubQuestions'))
                    .set("sizeList", _.isUndefined(_.get(value, 'sizeList')) ? 0 : _.get(value, 'sizeList') instanceof Array ? _.get(value, 'sizeList[0]') : _.get(value, 'sizeList'))
                    .value();
        });
        let properties = _.set(props, 'questions', ListDataQuestion);
        properties = _.omit(properties, 'collections');
        if(_.isNull(_.get(properties, '_id'))){
            return {
                'section': _.omit(properties, '_id')
            };
        } else {
            return {
                'section': properties
            };
        }
    }

    let request = null;
    if(modeEdit){
        request = axiosAPI.put(`/sections`, getProps());
    } else {
        request = axiosAPI.post(`/sections`, getProps());
    }

    return {
        type: POST_SECTION,
        payload: request
    }
}

export function updateOrderSections(props) {

    function getProps(){
        return {
            'section': {
                'sections': _.get(props, 'sections').map( (section, index) => {
                                return _.chain(section)
                                        .pick('_id')
                                        .set('order', index+1)
                                        .value();
                            })
            }
        };
    }

    const request = axiosAPI.post(`/sections/order`, getProps());

    return {
        type: POST_SECTION,
        payload: request
    }

}

export function deleteSection(section, sections) {

    function getProps(){
        let i = 1;
        let sect = [];
        sections.map(function(s, index){
            if(!_.isEqual(_.get(s, '_id.value'), _.get(section, '_id.value'))){
                sect.push({
                    _id:_.get(s, '_id.value'),
                    order: i
                });
                i++;
            }
        });
        return {
            "type": 'logic',
            'sections': sect
        };
    }

    const request = axiosAPI.delete(`/sections/${_.get(section, '_id.value')}`, {data: getProps()});

    return {
        type: DELETE_SECTION,
        payload: request
    }

}
