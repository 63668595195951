import PropTypes from "prop-types";
import React, { Component } from "react";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import IconButton from "material-ui/IconButton";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import MoreVerticalIcon from "material-ui/svg-icons/navigation/more-vert";
import { blue700 } from "material-ui/styles/colors";
import { LBLPROJECTS } from "../../../constants/labels";
import { locale_ES } from "../../../constants/index";
import _ from "lodash";
import moment from "moment";

class ProjectCardActions extends Component {
    constructor(props) {
        super(props);
        this._handleCloseAction = this._handleCloseAction.bind(this);
        this._handleOpenAction = this._handleOpenAction.bind(this);
        this.state = {
            open: false,
        };
    }

    _handleCloseAction() {
        this.setState({ open: false });
    }

    _handleOpenAction() {
        this.setState({ open: true });
    }

    render() {
        const {
            projectName,
            projectDescription,
            projectDays,
            projectInactive,
        } = this.props;
        const arrayDay = _.orderBy(projectDays, ["day"], "asc");
        moment.locale(locale_ES);
        return (
            <div>
                <IconMenu
                    iconButtonElement={
                        <IconButton>
                            {" "}
                            <MoreVerticalIcon />{" "}
                        </IconButton>
                    }
                    anchorOrigin={{ horizontal: "right", vertical: "top" }}
                    targetOrigin={{ horizontal: "right", vertical: "top" }}
                    style={{
                        marginRight: 0,
                        position: "relative",
                        float: "right",
                    }}
                >
                    <MenuItem
                        primaryText="Ver proyecto"
                        onClick={this._handleOpenAction}
                    />
                </IconMenu>
                <Dialog
                    title={projectName}
                    actions={[
                        <FlatButton
                            label="Salir"
                            secondary={true}
                            onClick={this._handleCloseAction}
                        />,
                    ]}
                    titleStyle={{ textAlign: "center", color: blue700 }}
                    contentStyle={{
                        maxWidth: 600,
                        minWidth: 300,
                        textAlign: "justify",
                    }}
                    autoScrollBodyContent={true}
                    modal={false}
                    open={this.state.open}
                    onRequestClose={this._handleCloseAction}
                >
                    <div style={{ textAlign: "justify" }}>
                        {projectDescription}
                    </div>
                    <br />
                    <div
                        style={{
                            textAlign: "justify",
                            display: _.size(arrayDay) === 0 ? "none" : "block",
                        }}
                    >{`El horario para este proyecto, es el siguiente:`}</div>
                    <ul>
                        {_.map(arrayDay, (day, index) => (
                            <li key={index}>
                                <div>{getDayText(_.get(day, "day"))}</div>
                                <ul>
                                    {_.map(
                                        _.get(day, "lapses", []),
                                        (lapse, idx) => (
                                            <li key={`${index}-${idx}`}>
                                                {`De ${getTimeText(
                                                    lapse.open.hour,
                                                    lapse.open.minute
                                                )} a ${getTimeText(
                                                    lapse.close.hour,
                                                    lapse.close.minute
                                                )}`}
                                            </li>
                                        )
                                    )}
                                </ul>
                            </li>
                        ))}
                    </ul>
                    <div
                        style={{ textAlign: "justify" }}
                    >{`Este proyecto permite que el usuario este inactivo por ${projectInactive} minutos. Si pasa de ese tiempo, su sesión se cerrará.`}</div>
                </Dialog>
            </div>
        );
    }
}

function getDayText(num) {
    let n = num;
    if (n instanceof String) {
        n = parseInt(num);
    }
    switch (n) {
        case 1:
            return LBLPROJECTS.timeLapse.week[0];
        case 2:
            return LBLPROJECTS.timeLapse.week[1];
        case 3:
            return LBLPROJECTS.timeLapse.week[2];
        case 4:
            return LBLPROJECTS.timeLapse.week[3];
        case 5:
            return LBLPROJECTS.timeLapse.week[4];
        case 6:
            return LBLPROJECTS.timeLapse.week[5];
        case 7:
        case 0:
            return LBLPROJECTS.timeLapse.week[6];
        default:
            return "";
    }
}

function getTimeText(hour, minute) {
    let h = hour;
    let m = minute;
    if (h instanceof String) {
        h = parseInt(hour);
    }
    if (m instanceof String) {
        m = parseInt(minute);
    }
    let time = new Date();
    time.setHours(h);
    time.setMinutes(m);
    return moment(time).format("LT");
}

ProjectCardActions.PropTypes = {
    projectName: PropTypes.string.isRequired,
    projectDescription: PropTypes.string.isRequired,
    projectDays: PropTypes.array.isRequired,
    projectInactive: PropTypes.number.isRequired,
};

export default ProjectCardActions;
