import immutable from 'immutable';
import _ from 'lodash';
import * as actions from './constants';

const INITIAL_STATE = { projectEmployees : [], tags:[], nameTags:[], allEmployees:[], initialTags: [] };

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case actions.TOGGLE_STATE_EMPLOYEE:
            var employees = _.get(state,'projectEmployees');
            var index = _.findIndex(employees, item =>{
                return _.isEqual(item._id, action.employee._id);
            });
            var newState = _.set(state,`projectEmployees[${index}].associated`, !state.projectEmployees[index].associated);
            return newState;
        case actions.CLEAR_TABLE:
            return _.assign({}, state, {
                projectEmployees: []
            });
        case actions.GET_EMPLOYEES_ASSOCIATED:
            var arrayEmployees = _.get(action, "payload.data", []);
            var tagsCompany = getTagsEmployees(arrayEmployees);
            return _.assign({}, state, {
                projectEmployees: arrayEmployees,
                tags: _.get(tagsCompany, 'tags'),
                initialTags: _.get(tagsCompany, 'tags'),
                nameTags: _.get(tagsCompany, 'nameTags'),
                allEmployees: arrayEmployees
            });
        case actions.SELECT_ALL:
            var employeesSelect = _.get(state,'projectEmployees');
            var newEmployeesState = _.concat(state.projectEmployees, _.map(employeesSelect, (employee) => {
                                                                          return _.set(employee, "associated", true);
                                                                      }));
            return _.assign({}, state, {
                projectEmployees: newEmployeesState
            });
        case actions.UNSELECT_ALL:
            var employeesUnselect = _.get(state,'projectEmployees');
            var newEmployees = _.map(employeesUnselect, (employee) => {
                                      return _.set(employee, "associated", false);
                                  });
            return _.assign({}, state, {
                projectEmployees: newEmployees
            });
        case actions.FILTER_EMPLOYEES_ASSOCIATION:
            return _.assign({}, state, {
                projectEmployees: _.get(action, "employees", [])
            });
        case actions.FILTER_TAGS_WITH_NAMETAGS:
            if(_.isEqual(_.get(action, 'nameTag'), "")){
                return _.assign({}, state, {
                    tags: _.get(state, "initialTags")
                });
            } else {
                return _.assign({}, state, {
                    tags: getTagsForNameTagEmployees(_.get(state, 'allEmployees'), _.get(action, 'nameTag'))
                });
            }
        default:
            return state;
    }
}

function getTagsEmployees(employees) {
    let tags = [];
    let nameTags = [];
    if (!_.isUndefined(employees)) {
        employees.map((item)=> {
            var tagsEmployee = _.get(item, 'tags');
            if (_.size(tagsEmployee) > 0) {
                tagsEmployee.map((tag)=> {
                    tags.push(tag.value);
                    nameTags.push(tag.title);
                });
            }
        });
    }
    return {
        tags: _.uniq(tags),
        nameTags: _.uniq(nameTags)
    };
}

function getTagsForNameTagEmployees(employees, nameTag) {
    let tags = [];
    if (!_.isUndefined(employees)) {
        employees.map((item)=> {
            var tagsEmployee = _.get(item, 'tags');
            if (_.size(tagsEmployee) > 0) {
                tagsEmployee.map((tag)=> {
                    if(_.isEqual(nameTag, tag.title)){
                        tags.push(tag.value);
                    }
                });
            }
        });
    }
    return _.uniq(tags);
}
