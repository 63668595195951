import PropTypes from "prop-types";
import React, { Component } from "react";
import _ from "lodash";
import ChartComponent from "./chartComponent";
import ButtonDetailComponent from "./buttonDetailComponent";
import BarStackedGroupFilterComponent from "./chartsComponents/barStackedGroupFilterComponent";
import BarStacked3DGroupFilterComponent from "./charts3DComponents/barStacked3DGroupFilterComponent";
import { getRamdonStringUniq, getRamdonString } from "../../lib/util";
import LegendComponent from "./legends/legendComponent";
import LegendStackedComponent from "./legends/legendStackedComponent";
import { arrayChart, arraySizeChart } from "./types";
import { pageTypes } from "../reportAdmon/types";

const styles = {
    titleGroup: {
        fontWeight: 400,
        textAlign: "right",
        margin: "2px 0px 5px 0px",
        padding: "4px 0 0 0",
        fontSize: 12,
        width: getSizeTitle(),
        display: "inline-block",
        verticalAlign: "top"
    }
};

function getDataLegend(data, colors) {
    const values = _.chain(data)
        .flatMap(d => _.get(d, "values", []))
        .groupBy("equivalence")
        .value();
    const keys = _.keys(values);
    let total = _.reduce(keys, (sum, k) => sum + _.get(values, `${k}.0.count`, 0), 0);
    if (total <= 0) {
        total = 100;
    }
    return _.chain(keys)
        .map((key, index) => {
            const valueCount = _.get(values, `${key}.0.count`, 0) * 100 / total;
            const percentage = valueCount.toFixed(1);
            return {
                color: _.get(values, `${key}.0.color`, _.get(colors, index, "#FFF")),
                textIndex: _.get(values, `${key}.0.id`, ""),
                priority: _.get(values, `${key}.0.priority`, 0),
                order: _.get(values, `${key}.0.order`, 0),
                equivalence: key,
                percentage: `${percentage}%`
            };
        })
        .filter(legend => !_.isEqual(_.get(legend, "textIndex", ""), ""))
        .orderBy(["order", "priority", "textIndex"], ["asc", "asc", "asc"])
        .value();
}

function getTypeChart(type) {
    switch (type) {
        case "STACKED_BAR":
        case "STACKED_BAR3D":
            return true;
        default:
            return false;
    }
}

function getData(data, colors) {
    let total = _.reduce(_.get(data, "0.values", []), (sum, n) => sum + _.get(n, "count", 0), 0);
    if (total <= 0) {
        total = 100;
    }

    return _.map(_.get(data, "0.values", []), (value, index) => {
        const full = _.get(value, "count", 0) * 100 / total;
        const i = full.toFixed(1);
        return {
            value: _.get(value, "count", 0),
            // textValue: _.get(value, 'count', 0),
            color: _.get(value, "color", _.get(colors, index, "#FFF")),
            index: `${i}%`,
            textIndex: `${_.get(value, "id", "0")} (${i}%)`
        };
    });
}

function getCountValuesData(data) {
    return _.reduce(_.get(data, "0.values", []), (sum, value) => sum + _.get(value, "count", 0), 0);
}

function getValueDataStacked(count, total) {
    return count * 100 / total;
}

function getColor(dataLegend, equivalence, value, colors, index) {
    const dataColor = _.groupBy(dataLegend, "equivalence");
    return _.get(dataColor, `${equivalence}.0.color`, _.get(value, "color", _.get(colors, index, "#FFF")));
}

function getDataStacked(data, colors, dataLegend) {
    const keysStacked = [];
    const countData = _.size(data);
    const arrayCodes = [];
    let dataStacked = {};
    let total = _.reduce(_.get(data, "0.values", []), (sum, n) => sum + _.get(n, "count", 0), 0);
    if (total <= 0) {
        total = 100;
    }
    _.forEach(_.get(data, "0.values", []), (value, index) => {
        const code = getRamdonStringUniq(arrayCodes);
        const full = _.get(value, "count", 0) * 100 / total;
        const i = full.toFixed(1);
        arrayCodes.push(code);
        keysStacked.push({
            key: code,
            priority: _.get(value, "priority", 0),
            text: countData > 1 ? _.get(value, "id", "") : `${_.get(value, "id", "")} (${i}%)`,
            order: _.get(value, "order", 0),
            color: getColor(dataLegend, _.get(value, "equivalence", ""), value, colors, index)
        });
        dataStacked = _.set(dataStacked, code, _.get(value, "count", 0));
    });
    const keyDataStacked = _.reduce(
        _.keys(dataStacked),
        (sum, val) => _.set(sum, val, getValueDataStacked(_.get(dataStacked, val, 0), total)),
        {}
    );
    return {
        keysStacked: _.orderBy(keysStacked, ["order", "priority", "text"], ["asc", "asc", "asc"]),
        dataStacked: [_.assign(keyDataStacked, { index: "", textIndex: "", total: 100 })]
    };
}

function getCoi(data) {
    return _.chain(data)
        .orderBy(["order"], ["asc"])
        .map(d => ({
            title: _.get(d, "label"),
            texts: _.chain(_.get(d, "values", []))
                .filter(v => _.get(v, "isDominant", false))
                .map(v => _.get(v, "coi", null))
                .filter(v => !_.isNull(v) && !_.isEqual(v, ""))
                .value()
        }))
        .filter(d => _.size(_.get(d, "texts", [])) > 0)
        .value();
}

function getSizeTitle() {
    return 130;
}

function isFullWidth(numColumns, contentColumns, sizeComponent) {
    if (contentColumns * 100 / numColumns / 100 * 980 - sizeComponent - 50 < 330) {
        return true;
    }
    return false;
}

function getValueSize(size) {
    let s = "middle";
    if ((!_.isUndefined(size) && !_.isNull(size)) || !_.isEqual(size, "")) {
        if (_.indexOf(["small", "middle", "large"], size) !== -1) {
            s = size;
        }
    }
    switch (s) {
        case "small":
            return 220;
        case "middle":
            return 310;
        case "large":
            return 400;
        default:
            return 200;
    }
}

function getSizeCoi(numColumns, contentColumns, size) {
    const chartSize = getValueSize(size);
    const componentSize = chartSize + getSizeTitle();
    if (_.indexOf([1, 2, 3, 4, 5], numColumns) !== -1) {
        const s = contentColumns * 100 / numColumns / 100 * 980 - componentSize - 50;
        if (isFullWidth(numColumns, contentColumns, componentSize)) {
            return "94%";
        }
        return s - 53;
    }
    return "94%";
}

class MultiIndicatorComponent extends Component {
    static propTypes = {
        colors: PropTypes.array,
        data: PropTypes.array.isRequired,
        textValues: PropTypes.string,
        textKeys: PropTypes.string,
        type: PropTypes.oneOf(arrayChart).isRequired,
        hasLegend: PropTypes.bool,
        hasDetail: PropTypes.bool,
        numColumns: PropTypes.number,
        size: PropTypes.oneOf(arraySizeChart),
        projectId: PropTypes.string.isRequired
    };

    renderInterpretationConditions(dataCoi, sizeDataCoi) {
        if (_.size(dataCoi) > 0) {
            return (
                <div
                    className="coi-group-report"
                    style={{
                        backgroundColor: "#FBFBFB" /* "#EEEFF5" */,
                        borderRadius: 8,
                        minWidth: 170,
                        width: sizeDataCoi,
                        display: "inline-block",
                        verticalAlign: "top",
                        textAlign: "left",
                        padding: "7px 10px",
                        marginBottom: 7
                    }}
                >
                    {_.map(dataCoi, (coi, index) => (
                        <div key={`coi-indicator-${index}-${getRamdonString()}`} className="print_pdf_component">
                            <h3 style={{ fontSize: 14, margin: "10px 0 5px 0", color: "#363638" }}>{_.get(coi, "title", "")}</h3>
                            {_.map(_.get(coi, "texts", []), (text, idx) => (
                                <p
                                    key={`coi-indicator-${index}-${idx}-${getRamdonString()}`}
                                    style={{ textAlign: "justify", fontSize: 12, lineHeight: 1.4 }}
                                >
                                    {text}
                                </p>
                            ))}
                        </div>
                    ))}
                </div>
            );
        }
        return <span />;
    }

    renderLegendGroup(hasAutoLegend, dataLengend, type, hasMiniLegend) {
        switch (type) {
            case "STACKED_BAR":
            case "STACKED_BAR3D":
                if (hasAutoLegend) {
                    const dataLegendUpdate = _.map(dataLengend, d => {
                        // if (hasMiniLegend) {
                        return d;
                        /* }
                        const text = `${_.get(d, "textIndex", "")} (${_.get(d, "percentage", "")})`;
                        return _.set(_.omit(d, ["textIndex"]), "textIndex", text); */
                    });
                    return <LegendComponent data={dataLegendUpdate} />;
                }
                return <span />;
            default:
                return <span />;
        }
    }

    renderChart(hasAutoLegend, dataFormat, keysStacked, dataStacked, textValues, textKeys, type, hasLegend, size, hasMiniLegend) {
        switch (type) {
            case "STACKED_BAR":
                return (
                    <div style={{ display: "inline-block" }}>
                        <BarStackedGroupFilterComponent data={dataStacked} keys={keysStacked} size={size} hasMiniLegend={true} />
                        {!hasAutoLegend ? <LegendStackedComponent data={dataStacked} size={size} keys={keysStacked} /> : <span />}
                    </div>
                );
            case "STACKED_BAR3D":
                return (
                    <div style={{ display: "inline-block" }}>
                        <BarStacked3DGroupFilterComponent data={dataStacked} keys={keysStacked} size={size} hasMiniLegend={true} />
                        {!hasAutoLegend ? <LegendStackedComponent data={dataStacked} size={size} keys={keysStacked} /> : <span />}
                    </div>
                );
            default:
                return (
                    <ChartComponent
                        data={dataFormat}
                        keysStacked={keysStacked}
                        dataStacked={dataStacked}
                        textValues={textValues}
                        textKeys={textKeys}
                        type={type}
                        hasLegend={hasLegend}
                        size={size}
                    />
                );
        }
    }

    render() {
        const {
            data,
            textValues,
            textKeys,
            type,
            hasLegend,
            size,
            colors,
            hasDetail,
            projectId,
            numColumns,
            contentColumns,
            itemsChart
        } = this.props;
        const chartSize = getValueSize(size) + getSizeTitle();
        const orderItems = _.chain(itemsChart)
            .map((item, index) => _.set({}, item, index))
            .reduce((sum, n) => _.assign(sum, n), {})
            .value();
        const dataLengend = getDataLegend(data, colors);
        const visibleDetail = _.isUndefined(hasDetail) || _.isNull(hasDetail) ? false : hasDetail;
        const dataCoi = getCoi(data);
        const sizeDataCoi = getSizeCoi(numColumns, contentColumns, size);
        const hasAutoLegend = _.size(data) > 1 ? true : getTypeChart(type);
        const infoChart = _.chain(data)
            .map(d => _.set(d, "order", _.get(orderItems, _.get(d, "id"), 0)))
            .orderBy(["order"], ["asc"])
            .map(d => [d])
            .map(d => {
                const dataFormat = getData(d, colors);
                const dataFormatStacked = getDataStacked(d, colors, dataLengend);
                const dataStacked = _.get(dataFormatStacked, "dataStacked", []);
                const keysStacked = _.get(dataFormatStacked, "keysStacked", []);
                return {
                    dataFormat,
                    keysStacked,
                    dataStacked,
                    title: _.get(d, "0.label", ""),
                    count: getCountValuesData(d)
                };
            })
            .value();
        let styleContentChart = { display: "inline-block", verticalAlign: "top" };
        if (_.size(data) > 1 && !isFullWidth(numColumns, contentColumns, chartSize)) {
            styleContentChart = _.assign(styleContentChart, { width: chartSize + 50 });
        }

        if (_.size(infoChart) > 0) {
            const countInfoChats = _.size(infoChart);
            return (
                <div className="print_pdf_component">
                    <div style={styleContentChart}>
                        <div style={{ height: visibleDetail ? 30 : 0 }} />
                        {_.map(infoChart, (info, index) => (
                            <div key={`multi-indicator-chart-${index}-${getRamdonString()}`}>
                                {hasAutoLegend && <h3 style={styles.titleGroup}>{_.get(info, "title", "")}</h3>}
                                {this.renderChart(
                                    hasAutoLegend,
                                    _.get(info, "dataFormat", []),
                                    _.get(info, "keysStacked", []),
                                    _.get(info, "dataStacked", []),
                                    textValues,
                                    textKeys,
                                    type,
                                    hasLegend,
                                    size,
                                    countInfoChats > 1
                                )}
                            </div>
                        ))}
                        {this.renderLegendGroup(hasAutoLegend, dataLengend, type, countInfoChats > 1)}
                    </div>
                    {this.renderInterpretationConditions(dataCoi, sizeDataCoi)}
                    <div style={{ height: visibleDetail ? 50 : 0 }}>
                        <div style={{ margin: "12px 5px", float: "right" }}>
                            <ButtonDetailComponent
                                projectId={projectId}
                                textTooltip={"Ver detalle de indicadores"}
                                visible={visibleDetail}
                                goPage={pageTypes.INDICATORS}
                            />
                        </div>
                    </div>
                </div>
            );
        }
        return <span style={{ color: "#CCC" }} />;
    }
}

export default MultiIndicatorComponent;
