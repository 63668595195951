import {
    ADD_VALUE_STORE,
    REMOVE_VALUE_STORE,
    CHANGE_POSITION_VALUE_STORE,
    ADD_LIST_VALUES_STORE,
    REMOVE_LIST_VALUES_STORE
} from './actions';
import {actionTypes as formActions} from 'redux-form'
import Immutable from 'immutable';
import _ from 'lodash';


function findPropertyByKey(collection, property, compareTo) {
    return _.findIndex(collection, item => {
        return _.get(item, property) === _.get(compareTo, property);
    });
}

function initialStoreValidation(state, action) {
    const store = state.get(action.store);
    if (_.isUndefined(store)) {
        return -1;
    }
    return state;
}

function getIndexStore(state, action) {
    const values = state.get(action.store).toArray();
    return findPropertyByKey(values, '_id', action.value);
}

function getStore(state, action) {
    return state.get(action.store).toArray();
}

const initialState = Immutable.Map({
    questions: Immutable.List(),
    consents: Immutable.List(),
    referees: Immutable.List()
});

export default (state = initialState, action) => {
    let index = -1;
    switch (action.type) {
        case ADD_VALUE_STORE:
            if (initialStoreValidation(state, action) === -1) {
                const values = Immutable.List.of([action.value]);
                return state.set(action.store, values);
            }
            index = getIndexStore(state, action);
            if (index === -1) {
                let store = state.get(action.store);
                let exit = true;
                if (_.isEqual(action.store, "questions")) {
                    store.toArray().map((v) => {
                        if (_.isEqual(v._id._id, action.value._id)) {
                            exit = false;
                        }
                    });
                }
                if (exit) {
                    return state.set(action.store, store.push(action.value));
                }
            }
            return state;
        case ADD_LIST_VALUES_STORE:
            if (initialStoreValidation(state, action) === -1) {
                var dataArray = Immutable.List();
                const {data} = action.payload.data;
                _.each(_.get(data, action.meta.store), item => {
                    dataArray = dataArray.push(item);
                });
                return state.set(action.meta.store, dataArray);
            }
            return state;
        case REMOVE_LIST_VALUES_STORE:
            return state.set(action.meta.store, Immutable.List());
        case REMOVE_VALUE_STORE:
            if (initialStoreValidation(state, action) === -1) {
                return state;
            }
            var store = state.get(action.store);
            return state.set(action.store, store.delete(action.index));
        case CHANGE_POSITION_VALUE_STORE:
            if (initialStoreValidation(state, action) === -1) {
                return state;
            }
            var list = state.get(action.store);
            var from = list.get(action.index);
            var toIndex = action.direction === 'up' ? action.index - 1 : action.index + 1;
            var to = list.get(toIndex);
            var tmpFrom = list.set(action.index, to);
            var tmpTo = tmpFrom.set(toIndex, from);
            return state.set(action.store, tmpTo);
        case formActions.DESTROY:
        case formActions.INITIALIZE:
            return state;
        default:
            return state
    }
};
