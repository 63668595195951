import { grey300 } from "material-ui/styles/colors";

export function getStyleIsDragging(isDragging) {
    return {
        opacity: isDragging ? 0.5 : 1,
        backgroundColor: isDragging ? grey300 : "#FFF0",
    };
}

export const backgroundColor = grey300;
