import PropTypes from "prop-types";
import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import _ from "lodash";
import { blue700 } from "material-ui/styles/colors";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FetchProjectParam } from "../../../actions/info/projects_info_form";
import { LBLPROJECTS } from "../../../constants/labels";
import AuthComponent from "../../AuthComponent";
import { locale_ES } from "../../../constants/index";
import { Link } from "react-router";
import RaisedButton from "material-ui/RaisedButton";
import Divider from "material-ui/Divider";
import moment from "moment";
import { browserHistory } from "react-router";
import Popover from "material-ui/Popover/Popover";
import { Menu, MenuItem } from "material-ui/Menu";

class generalInfoProjectComponent extends Component {
    constructor(props) {
        super(props);
        this._toUpdateGeneralInfoProject = this._toUpdateGeneralInfoProject.bind(
            this
        );
        this.handleTouchTap = this.handleTouchTap.bind(this);
        this.handleRequestClose = this.handleRequestClose.bind(this);
        this._goEditColors = this._goEditColors.bind(this);
        this._goEditRanking = this._goEditRanking.bind(this);
        this._goEditFilter = this._goEditFilter.bind(this);
        this.state = {
            open: false,
        };
    }

    _goEditColors() {
        const { urlBaseColors } = this.props;
        browserHistory.push(urlBaseColors);
    }

    _goEditRanking() {
        const { urlBaseRanking } = this.props;
        browserHistory.push(urlBaseRanking);
    }

    _goEditFilter() {
        const { urlBaseFilter } = this.props;
        browserHistory.push(urlBaseFilter);
    }

    UNSAFE_componentWillMount() {
        this._toUpdateGeneralInfoProject();
    }

    _toUpdateGeneralInfoProject() {
        const { projectId, FetchProjectParam } = this.props;
        FetchProjectParam(projectId);
    }

    handleTouchTap(event) {
        event.preventDefault();
        this.setState({
            open: true,
            anchorEl: event.currentTarget,
        });
    }

    handleRequestClose() {
        this.setState({ open: false });
    }

    renderDescription(description) {
        let desp = "";
        if (!_.isUndefined(description) && !_.isEqual(description, "")) {
            desp = _.split(description, /\r\n|\r|\n/g);
        }
        if (desp instanceof Array) {
            return desp.map((value, index) => {
                if (!_.isEqual(value, "")) {
                    return (
                        <div key={index}>
                            {value}
                            <br />
                            <br />
                        </div>
                    );
                }
            });
        } else {
            return desp;
        }
    }

    render() {
        const { projects, businessName, urlBase } = this.props;
        if (!_.isNull(projects)) {
            moment.locale(locale_ES);
            return (
                <div style={{ height: "auto", padding: 0 }}>
                    <Row>
                        <Col xs>
                            <div
                                style={{
                                    width: "100%",
                                    background: blue700,
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    marginBottom: 20,
                                    color: "#FFF",
                                }}
                            >
                                <h1
                                    style={{
                                        textAlign: "center",
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                        fontWeight: 400,
                                        lineHeight: 1.5,
                                    }}
                                >
                                    {`${businessName}`}
                                </h1>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs>
                            <h1
                                style={{
                                    textAlign: "center",
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    fontWeight: 400,
                                    lineHeight: 1.5,
                                }}
                            >
                                {_.get(projects, "data.name", "")}
                            </h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col
                            xsOffset={1}
                            xs={10}
                            style={{ textAlign: "justify" }}
                        >
                            {this.renderDescription(
                                _.get(projects, "data.description")
                            )}
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 10 }}>
                        <Col xsOffset={1} xs={4}>
                            <strong>Fecha de Inicio: </strong>
                            {moment(
                                _.get(projects, "data.time.openDate")
                            ).format("LL")}
                            <br />
                            <strong>Fecha de cierre: </strong>
                            {moment(
                                _.get(projects, "data.time.closeDate")
                            ).format("LL")}
                        </Col>
                    </Row>
                    <Row>
                        <Col xsOffset={1} xs={10} style={{ marginTop: 35 }}>
                            {_.map(
                                _.orderBy(
                                    _.get(projects, "data.colors", []),
                                    ["order"],
                                    ["asc"]
                                ),
                                (color, index) => (
                                    <div
                                        key={`color-default-project-${index}`}
                                        style={{
                                            background: _.get(
                                                color,
                                                "color",
                                                "#FFF"
                                            ),
                                            width: 20,
                                            height: 20,
                                            border: "1px solid #CCC",
                                            borderRadius: "100%",
                                            display: "inline-block",
                                            margin: 2,
                                        }}
                                    ></div>
                                )
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col xsOffset={1} xs={10}>
                            <AuthComponent
                                component={
                                    <Link to={urlBase}>
                                        <RaisedButton
                                            label={LBLPROJECTS.buttons.edit}
                                            secondary={true}
                                            style={{
                                                margin: "40px 12px 12px",
                                                float: "right",
                                            }}
                                        />
                                    </Link>
                                }
                                permission={"cgr_project_update"}
                                type={"component"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xsOffset={1} xs={10}>
                            <AuthComponent
                                component={
                                    <div style={{ width: "100%" }}>
                                        <Popover
                                            open={this.state.open}
                                            anchorEl={this.state.anchorEl}
                                            anchorOrigin={{
                                                horizontal: "right",
                                                vertical: "top",
                                            }}
                                            targetOrigin={{
                                                horizontal: "right",
                                                vertical: "top",
                                            }}
                                            onRequestClose={
                                                this.handleRequestClose
                                            }
                                            style={{ minWidth: 195 }}
                                        >
                                            <Menu>
                                                <AuthComponent
                                                    component={
                                                        <MenuItem
                                                            primaryText="Configurar colores"
                                                            onClick={
                                                                this
                                                                    ._goEditColors
                                                            }
                                                        />
                                                    }
                                                    permission={
                                                        "cgr_project_config_save_colors"
                                                    }
                                                    type={"component"}
                                                />
                                                <AuthComponent
                                                    component={
                                                        <MenuItem
                                                            primaryText="Configurar ranking"
                                                            onClick={
                                                                this
                                                                    ._goEditRanking
                                                            }
                                                        />
                                                    }
                                                    permission={
                                                        "cgr_project_config_save_ranking"
                                                    }
                                                    type={"component"}
                                                />
                                                <AuthComponent
                                                    component={
                                                        <MenuItem
                                                            primaryText="Configurar filtro inicial"
                                                            onClick={
                                                                this
                                                                    ._goEditFilter
                                                            }
                                                        />
                                                    }
                                                    permission={
                                                        "cgr_project_config_save_filter_default"
                                                    }
                                                    type={"component"}
                                                />
                                            </Menu>
                                        </Popover>
                                        <RaisedButton
                                            onClick={this.handleTouchTap}
                                            label="Configuración del informe"
                                            style={{
                                                margin: "12px 12px 25px",
                                                float: "right",
                                            }}
                                        />
                                    </div>
                                }
                                permissions={[
                                    "cgr_project_config_save_colors",
                                    "cgr_project_config_save_ranking",
                                    "cgr_project_config_save_filter_default",
                                ]}
                                type={"component"}
                            />
                        </Col>
                    </Row>
                </div>
            );
        } else {
            return <div></div>;
        }
    }
}

function mapStateToProps({ projects }) {
    return {
        businessName: _.get(projects, "all.data.company.businessName", ""),
        projects: projects.all,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            FetchProjectParam,
        },
        dispatch
    );
}

generalInfoProjectComponent.propTypes = {
    projectId: PropTypes.string.isRequired,
    urlBase: PropTypes.string.isRequired,
    urlBaseColors: PropTypes.string.isRequired,
    urlBaseRanking: PropTypes.string.isRequired,
    urlBaseFilter: PropTypes.string.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(generalInfoProjectComponent);
