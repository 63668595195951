import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ToggleComponent from './ToggleComponent';
import _ from 'lodash';

class FormProfileCategory extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {
            resources
        } = this.props;
        if (!_.isUndefined(resources) && !_.isNull(resources)) {
            return (
                <div>
                    {_.map(resources, (resource, index) =>
                        <ToggleComponent
                            key={`filedKeyToggleProfile${index}`}
                            label={_.get(resource, 'name')}
                            id={_.get(resource, 'id')}
                        />
                    )}
                </div>
            );
        } else {
            return ( <div></div> );
        }

    }
}

FormProfileCategory.propTypes = {
    resources: PropTypes.array.isRequired
};

export default FormProfileCategory;
