import { axiosAPI_V2 } from '../../middleware/api';
import { GET_GROUPERS_SURVEY,DELETE_GROUPER_SURVEY,GET_TREE_GROUPERS_SURVEY } from './constants';

export function getSurveyGroupers(idSurvey) {

    const request = axiosAPI_V2.get(`/surveygroupers/${idSurvey}`);

    return {
        type: GET_GROUPERS_SURVEY,
        payload: request
    }
}

export function getSurveyTreeGroupers(idSurvey) {

    const request = axiosAPI_V2.get(`/surveygroupers/tree/${idSurvey}`);

    return {
        type: GET_TREE_GROUPERS_SURVEY,
        payload: request
    }
}

export function deleteSurveyGrouper(idGrouper) {

    const request = axiosAPI_V2.delete(`/surveygroupers/${idGrouper}`);

    return {
        type: DELETE_GROUPER_SURVEY,
        payload: request,
        meta:{
            idGrouper: idGrouper
        }
    }
}
