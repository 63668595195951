import PropTypes from "prop-types";
import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import IconButton from "material-ui/IconButton";
import { blue700, blue300 } from "material-ui/styles/colors";
import VisibilityAdd from "material-ui/svg-icons/action/visibility";
import {
    inSearchValues,
    getValuesReportRender,
} from "../reportAdmon/renderReport/ducks";

let styleButton = {
        padding: 0,
        borderRadius: "100%",
        background: blue700,
        WebkitTransition: "all 0.8s",
    },
    styleButtonHover = {
        padding: 0,
        borderRadius: "100%",
        background: blue300,
        WebkitTransition: "all 0.8s",
    },
    styleContent = { background: "#FFF" };

class ButtonDetailComponent extends Component {
    state = { hover: false };

    static propTypes = {
        textTooltip: PropTypes.string.isRequired,
        visible: PropTypes.bool,
        positionTooltip: PropTypes.string,
        goPage: PropTypes.string.isRequired,
        projectId: PropTypes.string.isRequired,
    };

    handleOnMouseEnter = () => {
        this.setState({ hover: true });
    };

    handleOnMouseLeave = () => {
        this.setState({ hover: false });
    };

    _handledGoPage = () => {
        const {
            inSearchValues,
            getValuesReportRender,
            projectId,
            goPage,
        } = this.props;
        inSearchValues();
        getValuesReportRender(projectId, goPage);
    };

    render() {
        const { textTooltip, onClick, visible, positionTooltip } = this.props;
        const { hover } = this.state;
        const ptt =
            _.isUndefined(positionTooltip) || _.isNull(positionTooltip)
                ? "top-left"
                : positionTooltip;
        if (visible) {
            return (
                <div
                    onMouseEnter={this.handleOnMouseEnter}
                    onMouseLeave={this.handleOnMouseLeave}
                >
                    <div style={hover ? styleButtonHover : styleButton}>
                        <IconButton
                            tooltip={textTooltip}
                            onClick={this._handledGoPage}
                            tooltipPosition={ptt}
                            children={<VisibilityAdd color="#FFF" />}
                        />
                    </div>
                </div>
            );
        }
        return <span />;
    }
}

export default connect(null, { inSearchValues, getValuesReportRender })(
    ButtonDetailComponent
);
