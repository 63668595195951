import React, { Component } from "react";
import PropTypes from "prop-types";
import ColorComponent from "./colorComponent";
import NumberComponent from "./numberComponent";
import SelectComponent from "./selectComponent";

class BorderComponent extends Component {
    static propTypes = {
        survey: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
    };
    state = {
        borderTypes: [
            { value: "solid", text: "Sólido" },
            { value: "dashed", text: "Discontinua" },
            { value: "double", text: "Doble" },
            { value: "dotted", text: "Punteada" },
        ],
    };

    render() {
        const { path, survey } = this.props;
        const { borderTypes } = this.state;
        return (
            <div>
                <NumberComponent
                    survey={survey}
                    path={path}
                    fieldProp={"borderWidth"}
                    title={"Tamaño del borde"}
                    defaultValue={0}
                />
                <br />
                <SelectComponent
                    survey={survey}
                    path={path}
                    fieldProp={"borderStyle"}
                    title={"Estilo de borde"}
                    defaultValue={""}
                    array={borderTypes}
                />
                <ColorComponent
                    survey={survey}
                    path={path}
                    fieldProp={"borderColor"}
                    title={"Color del borde:"}
                    defaultValue={"#EEE"}
                />
            </div>
        );
    }
}

export default BorderComponent;
