import PropTypes from "prop-types";
import React, { Component } from "react";
import _ from "lodash";
import IconButton from "material-ui/IconButton";
import BackIcon from "material-ui/svg-icons/navigation/arrow-back";
import { blue700, blue300 } from "material-ui/styles/colors";
import { Link } from "react-router";

/* const styles = {
    button: {
        border: 0,
        borderStyle: "solid",
        borderColor: "#000",
        position: "fixed",
        padding: "5px 5px 5px 3px", // padding: 5,
        borderRadius: "0 0 15px 0", // "borderRadius: "100%",
        background: blue700,
        left: 0, // left: 15,
        top: 63, // top: 80,
        zIndex: 999,
    },
    buttonHover: {
        border: 0,
        borderStyle: "solid",
        borderColor: "#000",
        position: "fixed",
        padding: "5px 5px 5px 3px", // padding: 5,
        borderRadius: "0 0 15px 0", // "borderRadius: "100%",
        background: blue700,
        left: 0, // left: 15,
        top: 63, // top: 80,
        zIndex: 999,
    },
}; */

const styles = {
    button: {
        border: 0,
        borderStyle: "solid",
        borderColor: "#000",
        position: "fixed",
        padding: 5,
        borderRadius: "100%",
        background: blue700,
        left: 15,
        top: 80,
        zIndex: 999,
    },
    buttonHover: {
        border: 0,
        borderStyle: "solid",
        borderColor: "#000",
        position: "fixed",
        padding: 5,
        borderRadius: "100%",
        background: blue300,
        left: 15,
        top: 80,
        zIndex: 999,
    },
};

class ButtomBack extends Component {
    static propTypes = {
        url: PropTypes.string.isRequired,
        styleButton: PropTypes.any,
    };

    state = {
        hover: false,
    };

    handleOnMouseEnter = () => {
        this.setState({ hover: true });
    };

    handleOnMouseLeave = () => {
        this.setState({ hover: false });
    };

    onChangeUrl = () => {
        // const { url } = this.props;
    };

    render() {
        const { url, styleButton } = this.props;
        const { hover } = this.state;
        const extraStyle = !_.isNull(styleButton) && !_.isUndefined(styleButton) ? styleButton : {};
        const styleButtom = hover ? { ...styles.buttonHover } : { ...styles.button };
        if (!_.isUndefined(url)) {
            return (
                <div onMouseEnter={this.handleOnMouseEnter} onMouseLeave={this.handleOnMouseLeave}>
                    <div style={_.assign({ ...styleButtom }, { ...extraStyle })}>
                        <Link to={url}>
                            <IconButton
                                tooltip={"Volver"}
                                tooltipPosition="bottom-right"
                                onClick={() => this.onChangeUrl()}
                            >
                                <BackIcon color={"#FFF"} />
                            </IconButton>
                        </Link>
                    </div>
                </div>
            );
        }
        return <div />;
    }
}

export default ButtomBack;
