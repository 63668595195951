import { Map } from "immutable";
import _ from "lodash";
import { axiosAPI_V2 } from "../../../middleware/api";

export const GET_VALUES_RENDER_REPORT = "nss/analytics-report/GET_VALUES_RENDER_REPORT";
export const GET_VALUES_RENDER_REPORT_PRINT = "nss/analytics-report/GET_VALUES_RENDER_REPORT_PRINT";
export const GET_VALUES_RENDER_REPORT_IN_SEARCH = "nss/analytics-report/GET_VALUES_RENDER_REPORT_IN_SEARCH";
export const SET_COLORS_DEFAULT_RENDER_REPORT = "nss/analytics-report/SET_COLORS_DEFAULT_RENDER_REPORT";
export const LOAD_FILTER_DEFAULT_PROJECT = "nss/analytics-report/LOAD_FILTER_DEFAULT_PROJECT";
export const LOAD_FILTER_TRACE_PROJECT = "nss/analytics-report/LOAD_FILTER_TRACE_PROJECT";
export const SET_FILTER_DEFAULT_PROJECT = "nss/analytics-report/SET_FILTER_DEFAULT_PROJECT";
export const RESET_FILTER_DEFAULT_PROJECT = "nss/analytics-report/RESET_FILTER_DEFAULT_PROJECT";
export const DOWNLOAD_PROJECT_REPORT = "nss/analytics-report/DOWNLOAD_PROJECT_REPORT";

const initialState = Map({
    inSearch: false,
    report: {},
    report_main: {},
    report_question: {},
    report_indicator: {},
    report_ranking: {},
    colorsDefault: [],
    currentPage: null,
    filterDefault: [],
    loadFilterDefault: false,
    setFilter: false
});

function updateReportRemoveProps(report) {
    const rows = _.chain(report)
        .get("rows", [])
        .map(row => {
            const sections = _.get(row, "sections", []);
            return _.chain(row)
                .omit(["sections"])
                .set(
                    "sections",
                    _.map(sections, section => {
                        const childrens = _.get(section, "children", []);
                        return _.chain(section)
                            .omit(["children"])
                            .set("children", _.map(childrens, children => _.set(children, "chartProps.hasDetail", false)))
                            .value();
                    })
                )
                .value();
        })
        .value();
    return _.chain(report)
        .omit(["rows"])
        .set("rows", rows)
        .value();
}

function getKeyReport(currentPage) {
    switch (currentPage) {
        case "main_page":
            return "report_main";
        case "question_page":
            return "report_question";
        case "indicator_page":
            return "report_indicator";
        case "ranking_page":
            return "report_ranking";
        default:
            return "report";
    }
}

export default (state = initialState, action) => {
    switch (_.get(action, "type")) {
        case GET_VALUES_RENDER_REPORT:
            return state.withMutations(s => {
                s.set("inSearch", false);
                s.set("report", _.get(action, "payload.data", {}));
                s.set("currentPage", _.get(action, "meta.currentPage", {}));
            });
        case GET_VALUES_RENDER_REPORT_PRINT:
            const keyReport = getKeyReport(_.get(action, "meta.currentPage", {}));
            return state.withMutations(s => {
                s.set("inSearch", false);
                s.set(keyReport, updateReportRemoveProps(_.get(action, "payload.data", {})));
            });
        case GET_VALUES_RENDER_REPORT_IN_SEARCH:
            return state.set("inSearch", true);
        case SET_COLORS_DEFAULT_RENDER_REPORT:
            return state.set("colorsDefault", _.get(action, "meta.colorsDefault", []));
        case LOAD_FILTER_DEFAULT_PROJECT:
            return state.withMutations(s => {
                s.set("loadFilterDefault", true);
                s.set("filterDefault", _.get(action, "payload.data", []));
            });
        case LOAD_FILTER_TRACE_PROJECT:
            return state.withMutations(s => {
                s.set("loadFilterDefault", true);
                s.set("filterDefault", _.get(action, "payload.data", []));
            });
        case SET_FILTER_DEFAULT_PROJECT:
            return state.set("setFilter", true);
        case RESET_FILTER_DEFAULT_PROJECT:
            return state.withMutations(s => {
                s.set("loadFilterDefault", false);
                s.set("setFilter", false);
                s.set("report", {});
            });
        default:
            return state;
    }
};

export function getValuesReportRender(idProject, type) {
    const request = axiosAPI_V2.get(`/projects/structure/${idProject}/render/${type}`);

    return {
        type: GET_VALUES_RENDER_REPORT,
        payload: request,
        meta: {
            currentPage: type
        }
    };
}

export function getValuesReportRenderPrint(idProject, type) {
    const request = axiosAPI_V2.get(`/projects/structure/${idProject}/render/${type}`);

    return {
        type: GET_VALUES_RENDER_REPORT_PRINT,
        payload: request,
        meta: {
            currentPage: type
        }
    };
}

export function inSearchValues() {
    return {
        type: GET_VALUES_RENDER_REPORT_IN_SEARCH
    };
}

export function setColorDefault(colors) {
    return {
        type: SET_COLORS_DEFAULT_RENDER_REPORT,
        meta: {
            colorsDefault: colors
        }
    };
}

export function getFilterDefault(idProject) {
    const request = axiosAPI_V2.get(`/projects/filter/report/${idProject}`);

    return {
        type: LOAD_FILTER_DEFAULT_PROJECT,
        payload: request
    };
}

export function getFilterTraceReport(idProject, idTraceProject) {
    const request = axiosAPI_V2.get(`/projects/filter/report/project/${idProject}/trace/${idTraceProject}`);

    return {
        type: LOAD_FILTER_TRACE_PROJECT,
        payload: request
    };
}

export function setFilterDefault() {
    return {
        type: SET_FILTER_DEFAULT_PROJECT
    };
}

export function downloadReportProject(projectId, filters, surveyId, companyId, nameFile, email) {
    const request = axiosAPI_V2.post(`/reports/project`, {
        report: {
            projectId,
            filters,
            surveyId,
            companyId,
            nameFile,
            email
        }
    });

    return {
        type: DOWNLOAD_PROJECT_REPORT,
        payload: request
    };
}

export function resetValuesFilterDefault() {
    return {
        type: RESET_FILTER_DEFAULT_PROJECT
    };
}
