import PropTypes from "prop-types";
import React, { Component } from "react";
import { getRamdonString } from "../../../lib/util";
import _ from "lodash";

class MiniLegentGroup extends Component {
    render() {
        const { data, keys } = this.props;
        return (
            <div style={{ marginTop: -10 }}>
                {_.map(data, (value, index) => (
                    <div
                        style={{
                            paddingBottom: 0,
                            marginLeft: 4,
                            display: "inline-block",
                            margin: 0,
                            verticalAlign: "top",
                            textAlign: "left"
                        }}
                        key={`legend-values-content-${index}-${getRamdonString()}`}
                        className="print_pdf_children_legend"
                    >
                        {_.map(keys, key => {
                            const valuePercentage = getPercentage(_.get(value, _.get(key, "key")));
                            if (valuePercentage <= 0) {
                                return <span />;
                            }
                            return (
                                <span
                                    key={`legend-values-${index}-${getRamdonString()}`}
                                    style={{
                                        display: "inline-block",
                                        fontSize: 11,
                                        marginRight: 2
                                    }}
                                >
                                    <div style={getStyleCircle(_.get(key, "color", "#FFF"))}>
                                        <div style={{ opacity: 0, position: "absolute" }}>.</div>
                                    </div>
                                    {`${valuePercentage}%`}
                                </span>
                            );
                        })}
                    </div>
                ))}
            </div>
        );
    }
}

function getStyleCircle(color) {
    return {
        display: "inline-block",
        width: 9,
        height: 9,
        margin: "0px 2px 0px 0px",
        borderRadius: "100%",
        backgroundColor: color,
        color
    };
}

function getPercentage(v) {
    const val = _.toNumber(v).toFixed(1);
    return val;
}

MiniLegentGroup.propTypes = {
    data: PropTypes.array.isRequired,
    keys: PropTypes.array.isRequired
};

export default MiniLegentGroup;
