const _ = require('lodash');

const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

function nomenclatureLyricsUpperCase(){
    var letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    var itemCount = 339;
    var arrayLetter = [];
    for (var i = 0; i < itemCount - 1; i++) {
        if(i >= _.size(letters)){
            arrayLetter.push(letters[getSequenceQT(i, _.size(letters))] + letters[getSequence(i, _.size(letters))]);
        } else {
            arrayLetter.push(letters[i]);
        }
    }
    return arrayLetter;
}

function nomenclatureLyricsLowerCase(){
    var letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    var itemCount = 339;
    var arrayLetter = [];
    for (var i = 0; i < itemCount - 1; i++) {
        if(i >= _.size(letters)){
            arrayLetter.push(letters[getSequenceQT(i, _.size(letters))] + letters[getSequence(i, _.size(letters))]);
        } else {
            arrayLetter.push(letters[i]);
        }
    }
    return arrayLetter;
}

function getSequence(i, lettersLength){
    return i - (~~(i / lettersLength) * lettersLength);
}

function getSequenceQT(i, lettersLength){
    return (~~(i / lettersLength)) -1;
}

exports.nomenclatureLyricsUpperCase = nomenclatureLyricsUpperCase;
exports.nomenclatureLyricsLowerCase = nomenclatureLyricsLowerCase;
exports.alphabet = alphabet;
