import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Row, Col} from 'react-flexbox-grid';
import Toggle from 'material-ui/Toggle';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FetchQuestionsGroupId } from '../../../../actions/questions_form';
import MixinQuestion from '../../question/mixinQuestion';
import QuestionCloseGroup from './QuestionCloseGroup';
import Title from '../Title';
import _ from 'lodash';

const ColsQuestions = {
          paddingBottom: 10
      }, styleWidthContent = {
          minWidth: 240,
          margin: '0 auto'
      }, styleTableContent = {
          width: '100%',
          minWidth: 240,
          paddingTop: 0,
          borderCollapse:'collapse'
      };

class GroupClose extends Component {

    constructor(props){
        super(props);
    }

    UNSAFE_componentWillMount() {
        const { question } = this.props;
        this.props.FetchQuestionsGroupId(_.get(question, '_id._id'));
    }

    renderQuestions(questions){
        const {
            propsToSet,
            index
        } = this.props;
        let lapso = _.split(index, '-', 2);
        let minNum = parseInt(lapso[0]) - 1;
        return _.orderBy(questions, 'fieldName', 'asc').map((question, index) => {
            const propsToSetQuestion = _.chain(propsToSet)
                                        .omit(['question', 'idQuestion'])
                                        .set('question', question)
                                        .set('idQuestion', question._id)
                                        .value();
            const MixinCloseQuestions = MixinQuestion(QuestionCloseGroup);
            minNum++;
            return <MixinCloseQuestions key={question._id}
                {...propsToSetQuestion}
                index={minNum}
            />;
        });
    }

    render() {
        const {
            groupquestions,
            question,
            index
        } = this.props;
        let questionList = _.map(groupquestions, question => {
            let num = getNumberQuestionGroup(question.fieldName);
            return _.set(question, 'key', _.isEqual(num, "") ? 0 : parseInt(num));
        });
        if(questionList instanceof Array && _.size(questionList)){
            return (
                <div style={styleWidthContent}>
                    <Title question={_.has(question, 'fieldName') ? question : _.get(question, '_id')}/>
                    <table style={styleTableContent}>
                        <thead>
                            <tr style={{textAlign: 'center'}}>
                                <td></td>
                                <td style={{paddingBottom: 0, paddingLeft: 5, textAlign: 'left', width: 90}}>
                                    <div style={{display: 'inline-block', width: '50%'}}>
                                        <h3 style={{fontWeight: 200}}>Si</h3>
                                    </div>
                                    <div style={{display: 'inline-block', width: '50%'}}>
                                        <h3 style={{fontWeight: 200}}>No</h3>
                                    </div>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderQuestions(_.orderBy(questionList, 'key', 'asc'))}
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return (
                <div style={{textAlign: 'center'}}><h3 style={{fontWeight: 400, color: "#bbb"}}>Cargando...</h3></div>
            );
        }
    }
}

GroupClose.propTypes = {
    router: PropTypes.object,
    question: PropTypes.object.isRequired,
    propsToSet: PropTypes.object.isRequired
};

function mapStateToProps({questions}, ownProps) {
    const id = _.isUndefined(_.get(ownProps.question, '_id._id')) ? _.get(ownProps.question, '_id') : _.get(ownProps.question, '_id._id');
    let groupquestions = [];
    questions.surveyquestionsgroup.map( (value) => {
        if(_.isEqual(id, value.id)){
            groupquestions = value.data.data;
        }
    });
    return {
        groupquestions
    };
}

function getNumberQuestionGroup(text) {
    let arrayText = text.split("");
    let order = "";
    for (let i = _.size(arrayText) -1; i >= 0; i--){
        if(_.isEqual("#", arrayText[i]+"")){
            break;
        } else {
            order = arrayText[i] + order;
        }
    }
    return order;
}

export default connect(mapStateToProps, {FetchQuestionsGroupId})(GroupClose);
