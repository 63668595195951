// @flow

import React, { Component } from "react";
import { Col, Row } from "react-flexbox-grid";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Paper from "material-ui/Paper";
import _ from "lodash";
import { DropTarget } from "react-dnd";
// import uuid from "uuid";
import type { ConnectDropTarget, DropTargetSpec } from "react-dnd";
import type { Action } from "../types";
import Types from "../dragComponentTypes";
import { dropElement as de } from "../ducks";
import Loading from "../componentsUtil/loadingConf";
import EmptyContent from "../componentsUtil/emptyContent";
import RowContext from "./rowDropContext";
import { colorHover, textHover, colorBackgroundHover } from "../utils";
import PageSelector from "../pageSelector/main";
import { getPropsDefault } from "./functions";

const itemTarget: DropTargetSpec = {
    canDrop(props, monitor) {
        const item = monitor.getItem();
        return item.type === Types.ROW;
    },
    drop(props, monitor): ?Object {
        if (monitor.didDrop()) {
            return {};
        }
        const item = monitor.getItem();
        props.dropElement(props.dropPath, props.page, props.survey, getPropsDefault(item.type));
        return { moved: true };
    },
};

function collect(connect, monitor) {
    return {
        // Call this function inside render()
        // to let React DnD handle the drag events:
        connectDropTarget: connect.dropTarget(),
        // You can ask the monitor about the current drag state:
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
        itemType: monitor.getItemType(),
    };
}

type Props = {
    connectDropTarget: ConnectDropTarget,
    isOver: boolean,
    canDrop: boolean,
    isOverCurrent: boolean,
    // eslint-disable-next-line
    dropElement: (string, string) => Action,
    // eslint-disable-next-line
    dropPath: string,
    // eslint-disable-next-line
    survey: string,
    // eslint-disable-next-line
    page: string,
    config: any,
    loadingConfiguration: boolean,
    saving: boolean,
};

type DefaultProps = {
    dropPath: string,
};

class DropContext extends Component<DefaultProps, Props, void> {
    static defaultProps = {
        dropPath: "rows",
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.props.isOver && nextProps.isOver) {
            // You can use this as enter handler
        }

        if (this.props.isOver && !nextProps.isOver) {
            // You can use this as leave handler
        }

        if (this.props.isOverCurrent && !nextProps.isOverCurrent) {
            // You can be more specific and track enter/leave
            // shallowly, not including nested targets
        }
    }

    renderRow = (row, index) => {
        const { config, dropPath, survey } = this.props;
        const rowId = _.get(row, "_id");
        const path = `${dropPath}.${index}`;
        const pathArray = `${dropPath}`;
        const indexElement = index;
        const totalElements = _.size(_.get(config, "rows", []));
        return (
            <RowContext
                path={path}
                pathArray={pathArray}
                position={index}
                totalElements={totalElements}
                indexElement={indexElement}
                key={rowId}
                columns={row.columns}
                title={row.title}
                survey={survey}
                sections={row.sections}
            />
        );
    };

    render() {
        const {
            connectDropTarget,
            isOver,
            isOverCurrent,
            canDrop,
            loadingConfiguration,
            config,
            survey,
            saving,
        } = this.props;
        const rows = _.get(config, "rows", []).map(this.renderRow);
        const hasContent = _.size(rows) > 0;
        const colorSelector = colorHover(isOver, canDrop, isOverCurrent);
        const backgroundColor = hasContent ? colorBackgroundHover(isOver, canDrop, isOverCurrent) : "none";
        const textMessage = textHover(isOver, canDrop, isOverCurrent, "Puede arrastrar el elemento fila");
        return connectDropTarget(
            <div style={{ marginBottom: 60, minWidth: 980 }}>
                <div style={{ width: "100%" }}>
                    <PageSelector survey={survey} />
                </div>
                <div style={{ width: "100%", boxSizing: "border-box" }}>
                    <Paper zDepth={0} style={{ padding: "35px 5px 35px 5px", backgroundColor }}>
                        <Loading loading={loadingConfiguration} />
                        <EmptyContent
                            text={textMessage}
                            hasElements={hasContent}
                            loading={loadingConfiguration}
                            color={colorSelector}
                        />
                        {rows}
                    </Paper>
                </div>
            </div>,
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            dropElement: de,
        },
        dispatch,
    );
}

function mapStateToProps({ analyticsReportsConfiguration }) {
    const config = analyticsReportsConfiguration.get("pageConfiguration");
    return {
        config,
        page: analyticsReportsConfiguration.get("page"),
        loadingConfiguration: analyticsReportsConfiguration.get("loadingConfiguration"),
        saving: analyticsReportsConfiguration.get("saving"),
    };
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    DropTarget(Object.values(Types), itemTarget, collect),
)(DropContext);
