import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';
import {Row, Col} from 'react-flexbox-grid';
import {blue700} from 'material-ui/styles/colors';

class DataPerson extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { result } = this.props;
        if(_.isNull(result)){
            return ( <div></div> )
        } else {
            const tdStyle = { width: 170 };
            const h4Style = { fontWeight: 400, margin: '2px 0' };
            const h4StyleTitle = { fontWeight: 600, margin: '2px 0' };
            return (
                <div style={{padding: '0 20px'}}>
                    <Row>
                        <Col xs>
                            <h1 style={{fontWeight: 400, marginTop: 30}}>Información personal</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs>
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={tdStyle}><h4 style={h4StyleTitle}>Identificación:</h4></td>
                                        <td><h4 style={h4Style}>{_.get(result, 'idPerson')}</h4></td>
                                    </tr>
                                    <tr>
                                        <td style={tdStyle}><h4 style={h4StyleTitle}>Nombre:</h4></td>
                                        <td><h4 style={h4Style}>{`${_.get(result, 'name')} ${_.get(result, 'lastName')}`}</h4></td>
                                    </tr>
                                    <tr>
                                        <td style={tdStyle}><h4 style={h4StyleTitle}>Correo electrónico:</h4></td>
                                        <td><h4 style={h4Style}>{_.get(result, 'email')}</h4></td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </div>
            );
        }
    }
}

export default DataPerson;
