import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-flexbox-grid';
import { bindActionCreators } from 'redux';
import { changeValueCities } from '../actions';
import CircularProgress from 'material-ui/CircularProgress';
import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import { getRecords } from '../../GenericTablePagination/actions';
import SelectFieldFormat from '../../fields/SelectFieldFormat/SelectFieldFormat';
import _ from 'lodash';

class FilterCitiesGeneric extends Component {

    constructor(props){
        super(props);
        this._onChangeCities = this._onChangeCities.bind(this);
        this._execGetRecords = this._execGetRecords.bind(this);
    }

    _onChangeCities(e, index, value){
        const { changeValueCities, extra } = this.props;
        let extraData = extra;
        changeValueCities(value);
        this._execGetRecords(_.set(extraData, 'city', value));
    }

    _execGetRecords(extraData){
        const {
            URL,
            version,
            skip,
            limit,
            orderName,
            orderType,
            textFilter,
            body,
            textValueTag,
            textTag,
            coreName,
            getRecords
        } = this.props;
        let arrayFields = [];
        _.map(body, function(field){
             if(!_.has(field, 'actions') && _.has(field, 'filter')){
                 if(_.get(field, 'filter')){
                     arrayFields.push(_.get(field, 'field'));
                 }
             }
        });
        getRecords(URL, version, skip, limit, orderName, orderType, arrayFields, textFilter, coreName, extraData);
    }

    render() {
        const { city, cities, department } = this.props;
        if(!_.isNull(department) && !_.isUndefined(department) && !_.isEqual(department, 'all')){
            return (
                <SelectFieldFormat
                    config={{
                        floatingLabelText: "Ciudad",
                        value: city,
                        autoWidth: true,
                        onChange: this._onChangeCities
                    }}
                >
                    <MenuItem key={`city0`} value={'all'} primaryText={'Todos'}/>
                    {
                        _.map(_.orderBy(cities, ['name']), (item, index) => {
                            return (
                                <MenuItem key={`${_.get(item, '_id')}_ne_${index}`} value={_.get(item, '_id')} primaryText={_.capitalize(_.get(item, 'name'))}/>
                            );
                        })
                    }
                </SelectFieldFormat>
            );
        } else {
            return ( <div></div> );
        }
    }
}

function mapStateToProps({ filterCompany, genericTablePagination }, ownProps) {
    const tablePagination = genericTablePagination.get(_.get(ownProps, 'coreName', 'default'));
    return {
        cities: filterCompany.get("cities"),
        city: filterCompany.get("city"),
        department: filterCompany.get("department"),
        skip: tablePagination.get('skip'),
        limit: tablePagination.get('limit'),
        orderName: tablePagination.get('orderName'),
        orderType: tablePagination.get('orderType'),
        textFilter: tablePagination.get('textFilter'),
        status: _.isNull(tablePagination.get('status')) ? 'all' : tablePagination.get('status')
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        changeValueCities,
        getRecords
    }, dispatch);
}

FilterCitiesGeneric.propTypes = {
    URL: PropTypes.string.isRequired,
    version: PropTypes.number.isRequired,
    extra: PropTypes.object.isRequired,
    body: PropTypes.array.isRequired,
    coreName: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterCitiesGeneric);
