import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import MenuItem from "material-ui/MenuItem";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateComponent } from "../../ducks";
import SelectFieldFormat from "../../../fields/SelectFieldFormat/SelectFieldFormat";
import WaitComponent from "../waitComponent";

function getValue(value, isNumber) {
    if (isNumber) {
        return _.toNumber(value);
    }
    return value;
}

class SelectComponent extends Component {
    static propTypes = {
        survey: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        page: PropTypes.string.isRequired,
        fieldProp: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
        // [{value , text}]
        array: PropTypes.arrayOf(PropTypes.object).isRequired,
        loadingConfiguration: PropTypes.bool.isRequired,
        isNumber: PropTypes.bool,
    };

    onChangeValue = (e, index, value) => {
        const { updateComponent, survey, page, path, fieldProp, isNumber } = this.props;
        updateComponent(path, _.set({}, fieldProp, getValue(value, isNumber)), page, survey);
    };

    render() {
        const { value, title, array, loadingConfiguration, isNumber } = this.props;
        if (loadingConfiguration) {
            return <WaitComponent />;
        }
        return (
            <SelectFieldFormat
                config={{
                    floatingLabelText: title,
                    value: getValue(value, isNumber),
                    autoWidth: true,
                    onChange: this.onChangeValue,
                }}
            >
                {_.map(array, (val, index) => (
                    <MenuItem
                        key={`val-edit-config-${index}`}
                        value={_.get(val, "value")}
                        primaryText={_.get(val, "text")}
                    />
                ))}
            </SelectFieldFormat>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateComponent }, dispatch);
}

function mapStateToProps({ analyticsReportsConfiguration }, ownProps) {
    const config = analyticsReportsConfiguration.get("pageConfiguration");
    const loadingConfiguration = analyticsReportsConfiguration.get("loadingConfiguration");
    return {
        value: _.get(
            config,
            `${_.get(ownProps, "path")}.${_.get(ownProps, "fieldProp")}`,
            _.get(ownProps, "defaultValue", null),
        ),
        page: analyticsReportsConfiguration.get("page"),
        loadingConfiguration,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectComponent);
