import PropTypes from "prop-types";
import React, { Component } from "react";
import { reduxForm, addArrayValue } from "redux-form";
import { Row, Col } from "react-flexbox-grid";
import { bindActionCreators } from "redux";
import _ from "lodash";
import {
    getTreeQuestionItems,
    cleanStateTreeMap,
    saveGrouperQuestions,
    saveGrouperQuestionsFrecuency,
} from "./actions";
import { toggleSnackbar } from "../../actions/commons";
import { blue700 } from "material-ui/styles/colors";
import { List, ListItem } from "material-ui/List";
import TextField from "material-ui/TextField";
import DeleteIcon from "material-ui/svg-icons/action/delete";
import AddListIcon from "material-ui/svg-icons/av/playlist-add";
import AddIcon from "material-ui/svg-icons/content/add";
import IconButton from "material-ui/IconButton";
import Divider from "material-ui/Divider";
import { browserHistory } from "react-router";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import RaisedButton from "material-ui/RaisedButton";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { LBLSURVEYS } from "../../constants/labels";
import { getRamdonString } from "../../lib/util";
import AuthComponent from "../../components/AuthComponent";
import SelectFieldFormat from "../../components/fields/SelectFieldFormat/SelectFieldFormat";
import MenuItem from "material-ui/MenuItem";

let idSurvey = "";

const innerDivStyle = {
        paddingRight: 35,
    },
    innerDivStyle2 = {
        paddingRight: 35,
        paddingTop: 0,
    },
    nestedListStyle = {
        paddingLeft: 10,
    };

class TreeQuestionsItems extends Component {
    constructor(props) {
        super(props);
        this.getTree = this.getTree.bind(this);
        this.getStructureTree = this.getStructureTree.bind(this);
        this._onCheckQuestion = this._onCheckQuestion.bind(this);
        this._saveGroupQuestion = this._saveGroupQuestion.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.renderDialogMessage = this.renderDialogMessage.bind(this);
        this.renderDialog = this.renderDialog.bind(this);
        this.assembleTree = this.assembleTree.bind(this);
        this.state = {
            open: false,
            questionsgroup: [],
            tree: <div></div>,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (idSurvey !== nextProps.surveyId && nextProps.surveyId != null) {
            idSurvey = nextProps.surveyId;
            nextProps
                .getTreeQuestionItems(nextProps.surveyId)
                .then(
                    (data) => {
                        this.assembleTree();
                    },
                    (reason) => {}
                )
                .catch((data) => {});
        }
    }

    UNSAFE_componentWillMount() {
        const { cleanStateTreeMap } = this.props;
        cleanStateTreeMap("treeQuestions");
        idSurvey = "";
    }

    componentDidMount() {
        this.assembleTree();
    }

    handleOpen(question) {
        this.setState({
            open: true,
            questionsgroup: _.get(question, "questions"),
        });
    }

    handleClose() {
        this.setState({ open: false });
    }

    handleSave() {
        const {
            fields: { name, survey, visible, priority },
            resetForm,
            saveGrouperQuestionsFrecuency,
            toggleSnackbar,
            URLBack,
        } = this.props;
        if (_.isUndefined(name.value) || _.isEqual(name.value, "")) {
            toggleSnackbar(
                true,
                "Error. Se debe de ingresar un nombre para este agrupador."
            );
        } else {
            saveGrouperQuestionsFrecuency(
                name.value,
                survey.value,
                visible.value,
                this.state.questionsgroup,
                priority.value
            )
                .then(
                    (data) => {
                        if (
                            _.get(data, "error") ||
                            _.get(data, "payload.status") !== 200
                        ) {
                            toggleSnackbar(
                                true,
                                LBLSURVEYS.msg.errorSaveGroupQuestions
                            );
                        } else {
                            resetForm();
                            toggleSnackbar(
                                true,
                                LBLSURVEYS.msg.successSaveGroupQuestions
                            );
                            if (!_.isUndefined(URLBack) && !_.isNull(URLBack)) {
                                browserHistory.push(URLBack);
                            }
                        }
                    },
                    (reason) => {
                        toggleSnackbar(
                            true,
                            LBLSURVEYS.msg.errorSaveGroupQuestions
                        );
                    }
                )
                .catch((data) => {
                    toggleSnackbar(
                        true,
                        LBLSURVEYS.msg.errorSaveGroupQuestions
                    );
                });
        }
        this.setState({ questionsgroup: [], open: false });
    }

    renderDialogMessage() {
        return (
            <h3 style={{ fontWeight: 200, textAlign: "justify" }}>
                <ul style={{ listStyleType: "decimal" }}>
                    {this.state.questionsgroup.map((questiongroup, index) => (
                        <li key={index}>{questiongroup.title}</li>
                    ))}
                </ul>
            </h3>
        );
    }

    renderDialog() {
        const {
            fields: { name, visible, priority },
            locked,
            arrayPriority,
        } = this.props;
        return (
            <Dialog
                title={LBLSURVEYS.metrical.titleDialogQuestionGroup}
                actions={[
                    <FlatButton
                        label={LBLSURVEYS.metrical.buttons.cancel}
                        secondary={true}
                        onClick={this.handleClose}
                    />,
                    <FlatButton
                        label={LBLSURVEYS.metrical.buttons.save}
                        primary={true}
                        disabled={
                            locked ||
                            (_.isUndefined(name.value) ||
                            _.isEqual(name.value, "")
                                ? true
                                : false)
                        }
                        keyboardFocused={true}
                        onClick={this.handleSave}
                    />,
                ]}
                modal={false}
                autoScrollBodyContent={true}
                open={this.state.open}
                onRequestClose={this.handleClose}
                titleStyle={{ textAlign: "center", color: blue700 }}
                contentStyle={{
                    maxWidth: 600,
                    minWidth: 300,
                    textAlign: "justify",
                }}
            >
                <h3 style={{ fontWeight: 200, textAlign: "justify" }}>
                    {LBLSURVEYS.metrical.descriptionDialogQuestionGroup}
                </h3>
                {this.renderDialogMessage()}
                <Row>
                    <Col xs={10} xsOffset={1}>
                        <TextField
                            hintText={LBLSURVEYS.metrical.fields.name}
                            floatingLabelText={
                                LBLSURVEYS.metrical.fields.titleName
                            }
                            type="text"
                            fullWidth={true}
                            errorText={
                                name.touched && name.error ? name.error : ""
                            }
                            {...name}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={10} xsOffset={1}>
                        <h3 style={{ fontWeight: 200 }}>
                            {LBLSURVEYS.metrical.fields.titleVisible}
                        </h3>
                        <RadioButtonGroup
                            name="type"
                            onChange={(e, value) => visible.onChange(value)}
                            defaultSelected={"false"}
                            disabled={locked}
                            valueSelected={visible.value}
                            style={{ marginTop: 15 }}
                        >
                            <RadioButton
                                value="false"
                                label={LBLSURVEYS.metrical.buttons.no}
                                style={{
                                    marginBottom: 16,
                                    display: "inline-block",
                                    width: 130,
                                }}
                            />
                            <RadioButton
                                value="true"
                                label={LBLSURVEYS.metrical.buttons.yes}
                                style={{
                                    marginBottom: 16,
                                    display: "inline-block",
                                    width: 130,
                                }}
                            />
                        </RadioButtonGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={10} xsOffset={1}>
                        <h3 style={{ fontWeight: 200 }}>
                            {"Priorizaciòn del agrupador"}
                        </h3>
                        <SelectFieldFormat
                            config={{
                                floatingLabelText: "Priorización",
                                value: priority.value,
                                errorText:
                                    priority.touched && priority.error
                                        ? priority.error
                                        : "",
                                onChange: (e, index, value) =>
                                    priority.onChange(value),
                            }}
                        >
                            {_.map(arrayPriority, (priority, index) => (
                                <MenuItem
                                    key={`priority-${index}`}
                                    value={priority}
                                    primaryText={priority}
                                />
                            ))}
                        </SelectFieldFormat>
                    </Col>
                </Row>
            </Dialog>
        );
    }

    _saveGroupQuestion(formData) {
        const {
            fields: { questions },
            resetForm,
            saveGrouperQuestions,
            toggleSnackbar,
            URLBack,
        } = this.props;
        saveGrouperQuestions(formData)
            .then(
                (data) => {
                    if (
                        _.get(data, "error") ||
                        _.get(data, "payload.status") !== 200
                    ) {
                        toggleSnackbar(
                            true,
                            LBLSURVEYS.msg.errorSaveGroupQuestions
                        );
                    } else {
                        resetForm();
                        toggleSnackbar(
                            true,
                            LBLSURVEYS.msg.successSaveGroupQuestions
                        );
                        if (!_.isUndefined(URLBack) && !_.isNull(URLBack)) {
                            browserHistory.push(URLBack);
                        }
                        let exit = _.size(questions) !== 0;
                        let i = _.size(questions);
                        while (exit) {
                            questions.removeField();
                            i--;
                            if (i === 0) {
                                exit = false;
                            }
                        }
                    }
                },
                (reason) => {
                    toggleSnackbar(
                        true,
                        LBLSURVEYS.msg.errorSaveGroupQuestions
                    );
                }
            )
            .catch((data) => {
                toggleSnackbar(true, LBLSURVEYS.msg.errorSaveGroupQuestions);
            });
        this.setState({ questionsgroup: [], open: false });
    }

    _onCheckQuestion(id, title) {
        const {
            fields: { questions },
        } = this.props;
        let exits = true;
        questions.map(function (question) {
            if (_.isEqual(question.id.value, id)) {
                exits = false;
            }
        });
        if (exits) {
            questions.addField({
                title,
                id,
            });
        }
    }

    getStructureTree(sections) {
        return sections.map(function (section, index) {
            return {
                title: _.get(section, "idSection.title"),
                questions: _.map(_.get(section, "questions", []), function (
                    question,
                    idx
                ) {
                    if (_.has(question, "questions")) {
                        return {
                            title: _.get(question, "idQuestion.title"),
                            type: _.get(question, "idQuestion.type"),
                            _id: _.get(question, "idQuestion._id"),
                            questions: _.map(
                                _.get(question, "questions", []),
                                function (qt, dx) {
                                    return {
                                        title: _.get(qt, "idQuestion.title"),
                                        _id: _.get(qt, "idQuestion._id"),
                                        items: _.map(
                                            _.get(qt, "items"),
                                            function (item, i) {
                                                return {
                                                    title:
                                                        _.get(
                                                            item,
                                                            "idListItem.value"
                                                        ) +
                                                        " ( " +
                                                        _.get(item, "value") +
                                                        " )",
                                                    _id: _.get(
                                                        item,
                                                        "idListItem._id"
                                                    ),
                                                };
                                            }
                                        ),
                                    };
                                }
                            ),
                        };
                    } else {
                        return {
                            title: _.get(question, "idQuestion.title"),
                            _id: _.get(question, "idQuestion._id"),
                            items: _.map(
                                _.get(question, "items", []),
                                function (item, i) {
                                    return {
                                        title:
                                            _.get(item, "idListItem.value") +
                                            " ( " +
                                            _.get(item, "value") +
                                            " )",
                                        _id: _.get(item, "idListItem._id"),
                                    };
                                }
                            ),
                        };
                    }
                }),
            };
        });
    }

    getTree(sections) {
        const { locked } = this.props;
        const self = this;
        const styleButtonQuestion = {
            padding: "4px 4px 1px 4px",
            cursor: "pointer",
            marginTop: -5,
            borderRadius: "100%",
            background: blue700,
            display: locked ? "none" : "block",
        };
        this.setState({
            tree: _.map(self.getStructureTree(sections), function (
                section,
                index
            ) {
                return (
                    <ListItem
                        primaryText={section.title}
                        key={getRamdonString()}
                        innerDivStyle={innerDivStyle}
                        nestedListStyle={nestedListStyle}
                        initiallyOpen={false}
                        primaryTogglesNestedList={true}
                        nestedItems={_.map(
                            _.get(section, "questions", []),
                            (question, idx) =>
                                _.has(question, "questions") ? (
                                    <ListItem
                                        key={getRamdonString()}
                                        primaryText={_.get(question, "title")}
                                        leftCheckbox={
                                            <div
                                                style={
                                                    _.isEqual(
                                                        _.get(question, "type"),
                                                        "frecuency"
                                                    )
                                                        ? styleButtonQuestion
                                                        : {}
                                                }
                                                onClick={() => {
                                                    if (
                                                        _.isEqual(
                                                            _.get(
                                                                question,
                                                                "type"
                                                            ),
                                                            "frecuency"
                                                        )
                                                    ) {
                                                        self.handleOpen(
                                                            question
                                                        );
                                                    }
                                                }}
                                            >
                                                {_.isEqual(
                                                    _.get(question, "type"),
                                                    "frecuency"
                                                ) ? (
                                                    <AddListIcon
                                                        color={"#FFF"}
                                                        hoverColor={"#000"}
                                                    />
                                                ) : (
                                                    <span></span>
                                                )}
                                            </div>
                                        }
                                        initiallyOpen={false}
                                        number={2}
                                        innerDivStyle={innerDivStyle}
                                        nestedListStyle={nestedListStyle}
                                        secondaryText={
                                            LBLSURVEYS.metrical
                                                .titleQuestionGroup +
                                            (_.isEqual(
                                                question.type,
                                                "summatory"
                                            )
                                                ? LBLSURVEYS.metrical
                                                      .grouperQuestions.sumatory
                                                : LBLSURVEYS.metrical
                                                      .grouperQuestions
                                                      .frecuency)
                                        }
                                        secondaryTextLines={1}
                                        disabled={_.isEqual(
                                            _.get(question, "type"),
                                            "frecuency"
                                        )}
                                        primaryTogglesNestedList={true}
                                        nestedItems={_.map(
                                            _.get(question, "questions", []),
                                            (qt, idx) => (
                                                <ListItem
                                                    key={getRamdonString()}
                                                    primaryText={_.get(
                                                        qt,
                                                        "title"
                                                    )}
                                                    innerDivStyle={
                                                        innerDivStyle
                                                    }
                                                    disabled={true}
                                                    nestedListStyle={
                                                        nestedListStyle
                                                    }
                                                    primaryTogglesNestedList={
                                                        true
                                                    }
                                                    leftCheckbox={
                                                        <div
                                                            onClick={() => {
                                                                self._onCheckQuestion(
                                                                    _.get(
                                                                        qt,
                                                                        "_id"
                                                                    ),
                                                                    _.get(
                                                                        qt,
                                                                        "title"
                                                                    )
                                                                );
                                                            }}
                                                            style={
                                                                styleButtonQuestion
                                                            }
                                                        >
                                                            <AddIcon
                                                                color={"#FFF"}
                                                                hoverColor={
                                                                    "#000"
                                                                }
                                                            />
                                                        </div>
                                                    }
                                                    nestedItems={_.map(
                                                        _.get(qt, "items", []),
                                                        (item) => (
                                                            <ListItem
                                                                key={getRamdonString()}
                                                                disabled={true}
                                                                primaryText={_.get(
                                                                    item,
                                                                    "title"
                                                                )}
                                                                innerDivStyle={
                                                                    innerDivStyle2
                                                                }
                                                                nestedListStyle={
                                                                    nestedListStyle
                                                                }
                                                            />
                                                        )
                                                    )}
                                                />
                                            )
                                        )}
                                    />
                                ) : (
                                    <ListItem
                                        key={getRamdonString()}
                                        primaryText={_.get(question, "title")}
                                        innerDivStyle={innerDivStyle}
                                        disabled={true}
                                        nestedListStyle={nestedListStyle}
                                        primaryTogglesNestedList={true}
                                        leftCheckbox={
                                            <div
                                                onClick={() => {
                                                    self._onCheckQuestion(
                                                        _.get(question, "_id"),
                                                        _.get(question, "title")
                                                    );
                                                }}
                                                style={styleButtonQuestion}
                                            >
                                                <AddIcon
                                                    color={"#FFF"}
                                                    hoverColor={"#000"}
                                                />
                                            </div>
                                        }
                                        nestedItems={_.map(
                                            _.get(question, "items", []),
                                            (item) => (
                                                <ListItem
                                                    key={getRamdonString()}
                                                    disabled={true}
                                                    innerDivStyle={
                                                        innerDivStyle2
                                                    }
                                                    primaryText={item.title}
                                                    nestedListStyle={
                                                        nestedListStyle
                                                    }
                                                />
                                            )
                                        )}
                                    />
                                )
                        )}
                    />
                );
            }),
        });
    }

    assembleTree() {
        const { sections } = this.props;
        this.getTree(sections);
    }

    render() {
        const {
            fields: { questions, name, visible, priority },
            sections,
            arrayPriority,
            locked,
            handleSubmit,
        } = this.props;
        return (
            <div>
                <Row>
                    <Col xs>
                        <List>{this.state.tree}</List>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 20 }}>
                    <Col xs>
                        <Divider />
                    </Col>
                </Row>
                <Row style={{ marginBottom: 15 }}>
                    <Col xs>
                        <h2 style={{ fontWeight: 200, textAlign: "center" }}>
                            {LBLSURVEYS.metrical.titleConfigGrouperQuestion}
                        </h2>
                    </Col>
                </Row>
                <form onSubmit={handleSubmit(this._saveGroupQuestion)}>
                    {questions.map((question, index) => (
                        <div key={getRamdonString()}>
                            <Row>
                                <Col xsOffset={2} xs={7}>
                                    <h3
                                        style={{
                                            textAlign: "justify",
                                            fontWeight: 200,
                                        }}
                                    >
                                        {_.get(question, "title.value")}
                                    </h3>
                                </Col>
                                <Col xs={1}>
                                    <div>
                                        <IconButton
                                            children={<DeleteIcon />}
                                            tooltip={
                                                LBLSURVEYS.metrical.buttons
                                                    .delete
                                            }
                                            tooltipPosition="top-center"
                                            onClick={() => {
                                                questions.removeField(index);
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xsOffset={2} xs={8}>
                                    <Divider />
                                </Col>
                            </Row>
                        </div>
                    ))}
                    <Row style={{ display: this.state.open ? "none" : "flex" }}>
                        <Col
                            xs={10}
                            xsOffset={1}
                            style={{ marginBottom: 5, marginTop: 20 }}
                        >
                            <TextField
                                hintText={LBLSURVEYS.metrical.fields.name}
                                floatingLabelText={
                                    LBLSURVEYS.metrical.fields.titleName
                                }
                                type="text"
                                fullWidth={true}
                                errorText={
                                    name.touched && name.error ? name.error : ""
                                }
                                {...name}
                            />
                        </Col>
                    </Row>
                    <Row style={{ display: this.state.open ? "none" : "flex" }}>
                        <Col xs={10} xsOffset={1}>
                            <h3 style={{ fontWeight: 200 }}>
                                {LBLSURVEYS.metrical.fields.titleVisible}
                            </h3>
                            <RadioButtonGroup
                                name="type"
                                onChange={(e, value) => visible.onChange(value)}
                                defaultSelected={"false"}
                                valueSelected={visible.value}
                                style={{ marginTop: 15 }}
                            >
                                <RadioButton
                                    value="false"
                                    label={LBLSURVEYS.metrical.buttons.no}
                                    style={{
                                        marginBottom: 16,
                                        display: "inline-block",
                                        width: 130,
                                    }}
                                />
                                <RadioButton
                                    value="true"
                                    label={LBLSURVEYS.metrical.buttons.yes}
                                    style={{
                                        marginBottom: 16,
                                        display: "inline-block",
                                        width: 130,
                                    }}
                                />
                            </RadioButtonGroup>
                        </Col>
                    </Row>
                    <Row style={{ display: this.state.open ? "none" : "flex" }}>
                        <Col xs={10} xsOffset={1}>
                            <h3 style={{ fontWeight: 200 }}>
                                {"Priorización del agrupador"}
                            </h3>
                            <SelectFieldFormat
                                config={{
                                    floatingLabelText: "Priorización",
                                    value: priority.value,
                                    errorText:
                                        priority.touched && priority.error
                                            ? priority.error
                                            : "",
                                    onChange: (e, index, value) =>
                                        priority.onChange(value),
                                }}
                            >
                                {_.map(arrayPriority, (priority, index) => (
                                    <MenuItem
                                        key={`priority-${index}`}
                                        value={priority}
                                        primaryText={priority}
                                    />
                                ))}
                            </SelectFieldFormat>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginTop: 30,
                            display: this.state.open ? "none" : "flex",
                        }}
                    >
                        <Col xs style={{ textAlign: "center" }}>
                            <AuthComponent
                                component={
                                    <RaisedButton
                                        label={
                                            LBLSURVEYS.metrical.buttons
                                                .saveGrouperQuestion
                                        }
                                        type={"submit"}
                                        secondary={true}
                                        disabled={
                                            _.size(questions) === 0 || locked
                                        }
                                        style={{ margin: "0 auto" }}
                                    />
                                }
                                permission={"cgr_surveys_groupers_questions"}
                                type={"component"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs>{this.renderDialog()}</Col>
                    </Row>
                </form>
            </div>
        );
    }
}

TreeQuestionsItems.propTypes = {
    surveyId: PropTypes.string.isRequired,
    locked: PropTypes.bool,
    URLBack: PropTypes.string,
};

const validate = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = LBLSURVEYS.metrical.msg.nameRequired;
    }
    if (!values.priority) {
        errors.priority = LBLSURVEYS.metrical.msg.priorityRequired;
    }
    return errors;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getTreeQuestionItems,
            toggleSnackbar,
            cleanStateTreeMap,
            saveGrouperQuestions,
            saveGrouperQuestionsFrecuency,
        },
        dispatch
    );
}

function mapStateToProps({ treeQuestionsItems }, ownProps) {
    let arrayPriority = [];
    for (let i = 1; i <= 100; i++) {
        arrayPriority.push(i);
    }
    if (
        !_.isUndefined(
            _.get(treeQuestionsItems.get("treeQuestions"), "data.sections")
        )
    ) {
        if (
            _.size(
                _.get(treeQuestionsItems.get("treeQuestions"), "data.sections")
            ) !== 0
        ) {
            return {
                sections: _.get(
                    treeQuestionsItems.get("treeQuestions"),
                    "data.sections"
                ),
                arrayPriority: arrayPriority,
                initialValues: {
                    visible: "false",
                    priority: 1,
                    typeEvaluation: "summatory",
                    survey: _.get(ownProps, "surveyId"),
                },
            };
        } else {
            return {
                sections: [],
                arrayPriority: arrayPriority,
                initialValues: {
                    visible: "false",
                    priority: 1,
                    typeEvaluation: "summatory",
                    survey: _.get(ownProps, "surveyId"),
                },
            };
        }
    } else {
        return {
            sections: [],
            arrayPriority: arrayPriority,
            initialValues: {
                visible: "false",
                priority: 1,
                typeEvaluation: "summatory",
                survey: _.get(ownProps, "surveyId"),
            },
        };
    }
}

export default reduxForm(
    {
        form: "TreeQuestionsItemsNewForm",
        fields: [
            "name",
            "visible",
            "priority",
            "survey",
            "typeEvaluation",
            "questions[].title",
            "questions[].id",
        ],
        validate,
    },
    mapStateToProps,
    mapDispatchToProps
)(TreeQuestionsItems);
