import PropTypes from "prop-types";
import React, { Component } from "react";
import { arc, pie, select } from "d3";
import Faux from "react-faux-dom";
import { color } from "d3-color";
import LegendComponent from "../legends/legendComponent";
import _ from "lodash";

class PieDonut3DChartComponent extends Component {
    constructor(props) {
        super(props);
        this.renderLegend = this.renderLegend.bind(this);
    }

    renderLegend() {
        const { hasLegend, data, size } = this.props;
        if (!_.isUndefined(hasLegend) && !_.isNull(hasLegend)) {
            if (hasLegend) {
                return <LegendComponent data={data} size={size} />;
            }
            return <span />;
        }
        return <span />;
    }

    render() {
        const { data, size, height, width } = this.props;
        const faux = Faux.createElement("div"),
            heightPerspective = height / 3 * 2 + 30,
            darkerColor = -45,
            rx = width * 0.4,
            ry = width * 0.3,
            h = 17,
            ir = 0.4;

        const _data = pie()
            .sort(null)
            .value(d => d.value)(data);

        const svg = select(faux)
            .append("svg")
            .attr("width", width)
            .attr("height", heightPerspective)
            .style("page-break-inside", "avoid");

        const g = svg.append("g").attr("transform", `translate(${width / 2}, ${heightPerspective / 2})`);

        g
            .selectAll(".innerSlice")
            .data(_data)
            .enter()
            .append("path")
            .attr("class", "innerSlice")
            .style("fill", d => LightenDarkenColor(d.data.color, darkerColor))
            .attr("d", d => pieInner(d, rx + 0.5, ry + 0.5, h, ir));

        g
            .selectAll(".topSlice")
            .data(_data)
            .enter()
            .append("path")
            .attr("class", "topSlice")
            .style("fill", d => d.data.color)
            .style("stroke", d => d.data.color)
            .attr("d", d => pieTop(d, rx, ry, ir));

        g
            .selectAll(".outerSlice")
            .data(_data)
            .enter()
            .append("path")
            .attr("class", "outerSlice")
            .style("fill", d => LightenDarkenColor(d.data.color, darkerColor))
            .attr("d", d => pieOuter(d, rx - 0.5, ry - 0.5, h));

        /* g
            .selectAll(".percent")
            .data(_data)
            .enter()
            .append("text")
            .attr("class", "percent")
            .attr("x", d => 0.6 * rx * Math.cos(0.5 * (d.startAngle + d.endAngle)))
            .attr("y", d => 0.6 * ry * Math.sin(0.5 * (d.startAngle + d.endAngle)))
            .text(d => d.data.index); */

        return (
            <div style={{ display: "inline-block", width: width + 2 }}>
                <div style={{ textAlign: "center", width, display: "inline-block" }}>{faux.toReact()}</div>
                {this.renderLegend()}
            </div>
        );
    }
}

function pieTop(d, rx, ry, ir) {
    if (d.endAngle - d.startAngle == 0) {
        return "M 0 0";
    }
    let sx = rx * Math.cos(d.startAngle),
        sy = ry * Math.sin(d.startAngle),
        ex = rx * Math.cos(d.endAngle),
        ey = ry * Math.sin(d.endAngle);

    const ret = [];
    ret.push("M", sx, sy, "A", rx, ry, "0", d.endAngle - d.startAngle > Math.PI ? 1 : 0, "1", ex, ey, "L", ir * ex, ir * ey);
    ret.push("A", ir * rx, ir * ry, "0", d.endAngle - d.startAngle > Math.PI ? 1 : 0, "0", ir * sx, ir * sy, "z");
    return ret.join(" ");
}

function pieOuter(d, rx, ry, h) {
    const startAngle = d.startAngle > Math.PI ? Math.PI : d.startAngle;
    const endAngle = d.endAngle > Math.PI ? Math.PI : d.endAngle;

    let sx = rx * Math.cos(startAngle),
        sy = ry * Math.sin(startAngle),
        ex = rx * Math.cos(endAngle),
        ey = ry * Math.sin(endAngle);

    const ret = [];
    ret.push("M", sx, h + sy, "A", rx, ry, "0 0 1", ex, h + ey, "L", ex, ey, "A", rx, ry, "0 0 0", sx, sy, "z");
    return ret.join(" ");
}

function pieInner(d, rx, ry, h, ir) {
    const startAngle = d.startAngle < Math.PI ? Math.PI : d.startAngle;
    const endAngle = d.endAngle < Math.PI ? Math.PI : d.endAngle;

    let sx = ir * rx * Math.cos(startAngle),
        sy = ir * ry * Math.sin(startAngle),
        ex = ir * rx * Math.cos(endAngle),
        ey = ir * ry * Math.sin(endAngle);

    const ret = [];
    ret.push("M", sx, sy, "A", ir * rx, ir * ry, "0 0 1", ex, ey, "L", ex, h + ey, "A", ir * rx, ir * ry, "0 0 0", sx, h + sy, "z");
    return ret.join(" ");
}

function LightenDarkenColor(col, amt) {
    let usePound = false;
    if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
    }
    let num = parseInt(col, 16),
        r = (num >> 16) + amt;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;
    let b = ((num >> 8) & 0x00ff) + amt;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;
    let g = (num & 0x0000ff) + amt;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

function getValueSize(size, defaultSize) {
    switch (size) {
        case "small":
            return 250;
        case "middle":
            return 360;
        case "large":
            return 470;
        default:
            return defaultSize;
    }
}

PieDonut3DChartComponent.propTypes = {
    data: PropTypes.array.isRequired,
    hasLegend: PropTypes.bool,
    size: PropTypes.oneOf(["small", "middle", "large"]),
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired
};

export default PieDonut3DChartComponent;
