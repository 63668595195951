import PropTypes from "prop-types";
import React, { Component } from "react";
import { select, scaleBand, scaleLinear, max, axisBottom, axisLeft } from "d3";
import Faux from "react-faux-dom";
import _ from "lodash";
import LegendComponent from "../legends/legendComponent";

class BarChartVerticalComponent extends Component {
    constructor(props) {
        super(props);
        this.renderLegend = this.renderLegend.bind(this);
    }

    renderLegend() {
        const { hasLegend, data, size } = this.props;
        const dataEdit = _.map(data, (d, i) =>
            _.chain(d)
                .omit(["textValue"])
                .set("textValue", _.get(d, "value", ""))
                .value()
        );
        if (!_.isUndefined(hasLegend) && !_.isNull(hasLegend)) {
            if (hasLegend) {
                return <LegendComponent data={dataEdit} size={size} />;
            }
            return <span />;
        }
        return <span />;
    }

    render() {
        const { textVertical, textHorizontal, data, size, height, width } = this.props;

        let total = 0;
        _.forEach(data, d => {
            total += _.get(d, "value", 0);
        });
        if (total <= 0) {
            total = 100;
        }

        const dataEdit = _.chain(data)
            .map(d => _.set(d, "idx", _.get(d, "index")))
            .map((d, i) =>
                _.chain(d)
                    .omit(["index"])
                    .set("index", `#${i + 1}`)
                    .value()
            )
            .map(d => _.set(d, "percentage", getPercentage(d.value, total)))
            .value();

        const faux = Faux.createElement("div"),
            margin = { top: 20, right: 20, bottom: 50, left: 60 },
            barSpacing = getSpacingBar(_.size(dataEdit));

        const svg = select(faux)
            .append("svg")
            .attr("height", height)
            .attr("width", width)
            .style("page-break-inside", "avoid");

        const widthSvg = +width - margin.left - margin.right;
        const heightSvg = +height - margin.top - margin.bottom;

        const x = scaleBand()
            .rangeRound([0, widthSvg])
            .padding(barSpacing)
            .domain(_.map(dataEdit, item => item.index));

        const y = scaleLinear()
            .rangeRound([heightSvg, 0])
            .domain([0, max(dataEdit, d => d.percentage)]);

        const g = svg.append("g").attr("transform", `translate(${margin.left},${margin.top})`);

        // Sombra
        /* const radialGradient = svg
            .append("defs")
            .append("linearGradient")
            .attr("id", "radial-gradient");

        radialGradient.append("stop")
            .attr("offset", "0%")
            .attr("stop-color", "#DDD");

        radialGradient.append("stop")
            .attr("offset", "100%")
            .attr("stop-color", "#FFF");

        const barsShadow = g
            .selectAll(".bar-shadow")
            .data(dataEdit)
            .enter()
            .append("rect")
            .attr("class", "bar-shadow")
            .attr("x", d => x(d.index) + 12 )
            .attr("y", d => y(d.percentage))
            .attr("width", x.bandwidth())
            .attr("height", d => heightSvg - y(d.percentage) )
            .attr('fill', "url(#radial-gradient)" ); */

        const bars = g
            .selectAll(".bar")
            .data(dataEdit)
            .enter()
            .append("rect")
            .attr("class", "bar")
            .attr("x", d => x(d.index))
            .attr("y", d => y(d.percentage))
            .attr("width", x.bandwidth())
            .attr("height", d => heightSvg - y(d.percentage))
            .attr("fill", d => d.color);

        /* const text = g
            .selectAll(".text")
            .data(dataEdit)
            .enter()
            .append("text")
            .attr("class", "text")
            .style("stroke-width", 2)
            .attr("font-size", 10)
            .attr("y", d => 0)
            .attr("x", d => 0)
            .attr("transform", d => `rotate(-90), translate(${  -(heightSvg - 5)  },${  x(d.index) + 12  })`)
            .text(d => `${d.percentage}%`); */

        // Lines with the values
        /* const xAxis = g
            .append("g")
            .attr("class", "axis axis--x-v")
            .attr("transform", "translate(0," + heightSvg + ")")
            .call(axisBottom(x));
        changeColorAxis("#AAA", xAxis); */

        const yAxis = g
            .append("g")
            .attr("class", "axis axis--y-v")
            .call(axisLeft(y).ticks(5));
        changeColorAxis("#AAA", yAxis);

        if (validText(textVertical)) {
            // text label for the y axis
            g
                .append("text")
                .attr("transform", "rotate(-90)")
                .attr("y", 0 - margin.left)
                .attr("x", 0 - heightSvg / 2)
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .text(textVertical);
        }

        if (validText(textHorizontal)) {
            // text label for the x axis
            g
                .append("text")
                .attr("transform", `translate( ${widthSvg / 2}, ${heightSvg + margin.top + 20})`)
                .style("text-anchor", "middle")
                .text(textHorizontal);
        }

        return (
            <div style={{ display: "inline-block", width: width + 2 }}>
                <div style={{ textAlign: "center", width, display: "inline-block" }}>{faux.toReact()}</div>
                {this.renderLegend()}
            </div>
        );
    }
}

function getPercentage(value, total) {
    const por = _.toNumber(value) * 100 / total;
    return por.toFixed(1);
}

function getSpacingBar(sizeData) {
    if (sizeData <= 3) {
        return 0.4;
    } else if (sizeData > 3 && sizeData <= 5) {
        return 0.3;
    } else if (sizeData > 5 && sizeData <= 7) {
        return 0.2;
    }
    return 0.1;
}

function changeColorAxis(color, axis) {
    axis.selectAll("line").style("stroke", color);
    axis.selectAll("path").style("stroke", color);
    axis
        .selectAll("text")
        .style("stroke", color)
        .style("stroke-width", ".4");
}

function validText(text) {
    return !(_.isUndefined(text) || _.isNull(text) || _.isEqual(text, ""));
}

function getValueSize(size, defaultSize) {
    switch (size) {
        case "small":
            return 250;
        case "middle":
            return 360;
        case "large":
            return 470;
        default:
            return defaultSize;
    }
}

BarChartVerticalComponent.propTypes = {
    data: PropTypes.array.isRequired,
    textVertical: PropTypes.string,
    textHorizontal: PropTypes.string,
    hasLegend: PropTypes.bool,
    size: PropTypes.oneOf(["small", "middle", "large"]),
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired
};

export default BarChartVerticalComponent;
