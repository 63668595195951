import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Row, Col } from "react-flexbox-grid";
import _ from "lodash";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import {
    submitJob,
    mapJobStatusToLabel,
    SUBMIT_JOB_CLEAN,
} from "./excelGeneratorDucks";
import EmployeesProcessed from "../employeesProcessed/employeesProcessed";
import { getRamdonString } from "../../lib/util";

const mapNameToElement = (name, idx) => (
    <li key={`${getRamdonString()}-${idx}`}>
        <span>{name}</span>
    </li>
);

const mapTypes = (type = []) =>
    type
        .map((item, idx) => [item, idx])
        .filter((item) => item[0])
        .map((item) => {
            if (item[1] == 0) {
                return "Datos Brutos";
            }
            return "Datos Procesados";
        })
        .map(mapNameToElement);

const mapTypesObj = (type = []) =>
    type
        .map((item, idx) => [item, idx])
        .filter((item) => item[0])
        .map((item) => {
            if (item[1] == 0) {
                return "RAW";
            }
            return "PROCESSED";
        });

function getidProjects(projects) {
    let ids = [];
    projects.map((p) => {
        ids.push(_.get(p, "_id", null));
    });
    ids = _.filter(ids, (id) => !_.isNull(id));
    return ids;
}

class VerifyAndSend extends Component {
    render() {
        const {
            survey,
            companies,
            projects,
            email,
            filename,
            type,
            submitJob,
            jobStatus,
            handleBack,
            handleReset,
        } = this.props;
        return (
            <div style={{ marginBottom: "20px" }}>
                <Row>
                    <Col xs={12} md={6}>
                        <h4>Encuesta:</h4>
                        <span>{survey}</span>
                    </Col>
                    <Col xs={12} md={6}>
                        <h4>Tipo de archivo:</h4>
                        <ul>{mapTypes(type)}</ul>
                    </Col>
                    <Col xs={12} md={6}>
                        <h4>Compañías:</h4>
                        <ul>
                            {companies
                                .map((companyObj) => companyObj.businessName)
                                .map(mapNameToElement)}
                        </ul>
                    </Col>
                    <Col xs={12} md={6}>
                        <h4>Proyectos:</h4>
                        <ul>
                            {projects
                                .map((project) => project.name)
                                .map(mapNameToElement)}
                        </ul>
                    </Col>
                    <Col xs={12} md={6} />
                    <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{ marginBottom: 20, marginTop: 20 }}
                    >
                        <EmployeesProcessed
                            idProjects={getidProjects(projects)}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <h4>Nombre Archivo:</h4>
                        {filename}
                    </Col>
                    <Col xs={12} md={6}>
                        <h4>Dirección de correo electrónico: </h4>
                        {email}
                    </Col>
                    <Col xs={12} md={6} />
                    <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{ marginBottom: 20, marginTop: 50 }}
                    >
                        <RaisedButton
                            label={mapJobStatusToLabel(jobStatus)}
                            primary
                            style={{ margin: "8px" }}
                            onClick={() => {
                                const projectsId = projects.map(
                                    (project) => project._id
                                );
                                const types = mapTypesObj(type);
                                const data = {
                                    projects: projectsId,
                                    email,
                                    filename,
                                    type: types,
                                };
                                submitJob(data);
                            }}
                            disabled={jobStatus !== SUBMIT_JOB_CLEAN}
                        />
                        <FlatButton
                            label="Atrás"
                            style={{ margin: "8px" }}
                            onClick={handleBack}
                        />
                        <FlatButton
                            label="Reiniciar"
                            style={{ margin: "8px" }}
                            onClick={() => {
                                console.log("reset click");
                                handleReset();
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

function mapStateToProps({ surveysExcelGenerator }) {
    return {
        survey: _.get(
            surveysExcelGenerator.get("selectedSurveyObj"),
            "name",
            "No seleccionada."
        ),
        companies: surveysExcelGenerator
            .get("selectedCompanies")
            .filter((company) => _.get(company, "selected", false)),
        projects: surveysExcelGenerator
            .get("selectedProjects")
            .mapEntries(([K]) => [
                K,
                surveysExcelGenerator.get("selectedProjects").get(K),
            ])
            .toArray()
            .filter((project) => !(_.isNull(project) || _.isUndefined(project)))
            .filter((project) => _.get(project, "selected", false)),
        email: surveysExcelGenerator.get("email"),
        filename: surveysExcelGenerator.get("filename"),
        type: surveysExcelGenerator.get("type"),
        jobStatus: surveysExcelGenerator.get("jobStatus"),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ submitJob }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyAndSend);
