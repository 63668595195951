import PropTypes from "prop-types";
import React from "react";
import { blue800, white } from "material-ui/styles/colors";

const stylesComp = {
    meta: {
        WebKitFlex: "none",
        flex: "none",
        color: "gray",
        marginLeft: 6,
        fontSize: 12
    },
    unread: {
        backgroundColor: blue800,
        color: white,
        fontWeight: 500,
        fontSize: "12px !important",
        padding: "0px 5px",
        borderRadius: "12px",
        textAlign: "center",
        display: "inline-flex",
        WebKitAlignItems: "center",
        alignItems: "center",
        WebKitJustifyContent: "center",
        justifyContent: "center",
        height: "19px",
        minWidth: "19px",
        boxSizing: "border-box",
        lineHeight: "normal",
        transform: "scaleX(1) scaleY(1)",
        opacity: 1
    }
};

const counterMessages = props => {
    const { count, styles } = props;
    const messageCount = count > 99 ? "+99" : count;
    const counter = (
        <div style={Object.assign({}, stylesComp.meta, styles)}>
            <span>
                <span style={stylesComp.unread}>{messageCount}</span>
            </span>
        </div>
    );
    return count > 0 ? counter : <span />;
};

counterMessages.propTypes = {
    count: PropTypes.number.isRequired,
    styles: PropTypes.shape()
};

counterMessages.defaultProps = {
    styles: {}
};

export default counterMessages;
