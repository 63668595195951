import React from "react";
import { useField } from "formik";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useCleanUpOnUnmount } from "./utils";

const OtherField = ({ disabled, label, ...props }) => {
    const [field, , fieldProps] = useField(props);
    useCleanUpOnUnmount({
        fieldProps,
        pristineValue: false,
    });
    return (
        <FormControlLabel
            control={<Checkbox {...field} color="primary" />}
            label={label}
        />
    );
};

export default OtherField;
