import {GET_VALUES_LIST_MASTER, CLEAN_STATE_TREE_MAP,CHANGE_QUANTITY_SELECTABLE} from './actions';
import Immutable from 'immutable';
import _ from 'lodash';

const initialState = Immutable.Map({
  values: Immutable.List(),
   quantitySelectable: 0
});
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_VALUES_LIST_MASTER:
            return state.set('values', action.payload.data)
                        .set('quantitySelectable', _.size(_.get(action.payload.data, 'data')));
        case CLEAN_STATE_TREE_MAP:
            return state.set(action.storeName, {data: null});
        default:
            return state;
    }
};
