import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { blue700 } from "material-ui/styles/colors";
import { IconClose } from "./icons";
import { updatePropsComponent } from "../ducks";

const styles = {
    contentIcon: {
        width: 16,
        height: 16,
        cursor: "pointer",
    },
};

class DeleteElementComponent extends Component {
    static propTypes = {
        nameElement: PropTypes.string.isRequired,
        pathArray: PropTypes.string.isRequired,
        position: PropTypes.number.isRequired,
        page: PropTypes.string.isRequired,
        survey: PropTypes.string.isRequired,
        updatePropsComponent: PropTypes.func.isRequired,
    };

    state = {
        hover: false,
        open: false,
    };

    handleOnMouseEnter = () => {
        this.setState({ hover: true });
    };

    handleOnMouseLeave = () => {
        this.setState({ hover: false });
    };

    _handleOpenMessage = () => {
        this.setState({ open: true });
    };

    _handleCloseMessage = () => {
        this.setState({ open: false });
    };

    _handleDeleteElement = () => {
        const {
            pathArray,
            position,
            updatePropsComponent,
            page,
            survey,
        } = this.props;
        updatePropsComponent(pathArray, position, page, survey, "DELETE");
        this.setState({ open: false });
    };

    render() {
        const { nameElement } = this.props;
        const { hover, open } = this.state;
        const actions = [
            <FlatButton
                label="Cancelar"
                secondary
                onClick={this._handleCloseMessage}
            />,
            <FlatButton
                label={"Eliminar"}
                primary
                keyboardFocused
                onClick={this._handleDeleteElement}
            />,
        ];
        return (
            <div>
                <div
                    style={{ ...styles.contentIcon }}
                    onClick={this._handleOpenMessage}
                    onMouseEnter={this.handleOnMouseEnter}
                    onMouseLeave={this.handleOnMouseLeave}
                >
                    <IconClose color={hover ? "#0B54CE" : "#FFF"} />
                </div>
                <Dialog
                    title={`Eliminar ${nameElement}`}
                    actions={actions}
                    titleStyle={{ textAlign: "center", color: blue700 }}
                    contentStyle={{
                        maxWidth: 600,
                        minWidth: 300,
                        textAlign: "justify",
                    }}
                    modal={false}
                    open={open}
                    onRequestClose={this._handleCloseMessage}
                >
                    <h3 style={{ fontWeight: 200, textAlign: "justify" }}>
                        ¿Está seguro de eliminar este elemento? Todo el
                        contenido y sus propiedades se descartarán.
                    </h3>
                </Dialog>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updatePropsComponent }, dispatch);
}

function mapStateToProps({ analyticsReportsConfiguration }) {
    return {
        page: analyticsReportsConfiguration.get("page"),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteElementComponent);
