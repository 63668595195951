import { Map } from "immutable";
import * as Rx from "rxjs";
import { axiosAPI_V2 } from "../../middleware/api";

export const REPORT_EMPLOYEE_SUBMIT_REQUEST = "nss/report-employee/REPORT_EMPLOYEE_SUBMIT_REQUEST";
export const REPORT_EMPLOYEE_SUBMIT_CLEAN = "nss/report-employee/REPORT_EMPLOYEE_SUBMIT_CLEAN";
export const REPORT_EMPLOYEE_SUBMIT_FULFILLED = "nss/report-employee/REPORT_EMPLOYEE_SUBMIT_FULFILLED";
export const REPORT_EMPLOYEE_SUBMIT_REJECTED = "nss/report-employee/REPORT_EMPLOYEE_SUBMIT_REJECTED";
export const REPORT_EMPLOYEE_SUBMIT_IN_PROGRESS = "nss/report-employee/REPORT_EMPLOYEE_SUBMIT_IN_PROGRESS";
export const REPORT_EMPLOYEE_RESET = "nss/report-employee/REPORT_EMPLOYEE_RESET";

export const requestReports = (project, employees, email) => ({
    type: REPORT_EMPLOYEE_SUBMIT_REQUEST,
    payload: { project, employees, email },
});

export const requestReportsReset = () => ({
    type: REPORT_EMPLOYEE_RESET,
});

const initialState = Map({
    statusReportReferee: REPORT_EMPLOYEE_SUBMIT_CLEAN,
});

export default function(state = initialState, action = {}) {
    switch (action.type) {
        case REPORT_EMPLOYEE_RESET:
            return initialState;
        case REPORT_EMPLOYEE_SUBMIT_REQUEST:
        case REPORT_EMPLOYEE_SUBMIT_FULFILLED:
        case REPORT_EMPLOYEE_SUBMIT_REJECTED:
        case REPORT_EMPLOYEE_SUBMIT_IN_PROGRESS:
            return state.set("statusReportReferee", action.type);
        default:
            return state;
    }
}

const buildReportObj = (project, email, employees) => ({
    report: {
        projectId: project,
        email,
        employeesId: employees,
    },
});

export const submitRequestEpic$ = action$ =>
    action$.ofType(REPORT_EMPLOYEE_SUBMIT_REQUEST).mergeMap(action => {
        const { project, employees, email } = action.payload;
        const dataToProcess$ = Rx.Observable
            .from(employees)
            .bufferCount(20)
            .mergeMap(employeesBuffer => {
                const dataToSend = buildReportObj(project, email, employeesBuffer);
                const promise = axiosAPI_V2.post("/reports/referee", dataToSend);
                return Rx.Observable.fromPromise(promise).catch(error =>
                    Rx.Observable
                        .of({
                            payload: dataToSend,
                            error,
                        })
                        .delay(230),
                );
            })
            .toArray()
            .map(responses => ({ type: REPORT_EMPLOYEE_SUBMIT_FULFILLED, payload: { data: responses } }))
            .catch(error => Rx.Observable.of({ type: REPORT_EMPLOYEE_SUBMIT_REJECTED, error }));

        return Rx.Observable.of({ type: REPORT_EMPLOYEE_SUBMIT_IN_PROGRESS }).concat(dataToProcess$);
    });
