import PropTypes from "prop-types";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FloatingActionButton from "material-ui/FloatingActionButton";
import Popover from "material-ui/Popover";
import MessageIcon from "material-ui/svg-icons/communication/message";
import SendIcon from "material-ui/svg-icons/content/send";
import { blue700, blue800, blue500, blue900 } from "material-ui/styles/colors";
import { Editor, EditorState, getDefaultKeyBinding } from "draft-js";
import _ from "lodash";
import v4 from "uuid/v4";
import moment from "moment";
import AppBar from "material-ui/AppBar";
import ResetIcon from "material-ui/svg-icons/action/autorenew";
import CircleIcon from "material-ui/svg-icons/av/fiber-manual-record";
import FinishIcon from "material-ui/svg-icons/action/delete";
import HideIcon from "material-ui/svg-icons/hardware/keyboard-arrow-down";
import IconButton from "material-ui/IconButton";
import Message from "../commons/message";
import {
    mapConnectionLabel,
    mapConnectionColor,
    isFinalizedChat,
    getCounterUnreadEmployee,
    cleanState,
    changeStateConnection,
    setConversationId,
    addMessage,
} from "../commons/functions";
import {
    closeConversation,
    markMessagesAsRead,
    dequeueMessage,
    openDialogMessage,
    resetFirstMessageChat,
} from "./ducks";
import SendMessageDialog from "./sendMessageDialog";

const styles = {
    box: {
        height: "550px",
        width: "320px",
        overflow: "hidden",
        WebkitTransform: "translateX(-50%)",
        transform: "translateX(-50%)",
        boxShadow: "0px 3px 3px 0px rgba(50, 50, 50, 0.5)",
        WebkitTransition: "0.3s ease",
        transition: "0.3s ease",
        position: "relative",
        backgroundColor: "white",
        left: "50%",
    },
    nav: {
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        height: "64px",
        zIndex: "100",
        WebkitTransition: "0.3s ease",
        transition: "0.3s ease",
    },
    defaultNav: {
        height: "64px",
        width: "100%",
        position: "absolute",
        left: "0",
        top: "0",
        zIndex: "110",
        backgroundColor: blue700,
        borderBottom: `3px solid ${blue800}`,
        color: "#ffffff",
        boxShadow: "0px 3px 3px 0px rgba(50, 50, 50, 0.1)",
        WebkitTransition: "0.3s ease",
        transition: "0.3s ease",
    },
    mainNav: {
        position: "absolute",
        left: "0",
        width: "100%",
        height: "50px",
        top: "0",
        margin: "0",
        padding: "0",
        listStyle: "none",
        WebkitTransition: "0.3s ease",
        transition: "0.3s ease",
    },
    bottom: {
        height: 64,
        background: "#FFF",
    },
    inner: {
        overflowY: "auto",
        overflowX: "hidden",
        height: 357,
        background: "#f2f2f2",
        MsOverflowStyle: "none",
    },
    sendButton: {
        marginLeft: 2,
        marginTop: 4,
    },
    editor: {
        padding: "6px 6px 6px",
        height: 45,
        overflowY: "auto",
    },
    title: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        letterSpacing: 0,
        fontSize: 18,
        fontWeight: 400,
        color: "white",
        height: "46px",
        lineHeight: "46px",
        marginLeft: "10px",
        marginTop: "10px",
    },
    content: {
        WebKitFlex: "0 0 auto",
        flex: "0 0 auto",
        paddingBottom: "8px",
        transition: "padding 0.3s cubic-bezier(0.69, 0, 0.79, 0.14)",
        paddingLeft: "0",
        marginTop: "15px",
        marginBottom: 0,
    },
    buttonPopOver: {
        cursor: "pointer",
        background: blue700,
        width: 155,
        position: "fixed",
        bottom: 0,
        right: 0,
        zIndex: 100,
        textAlign: "center",
        borderRadius: "20px 0 0 0",
    },
    buttonPopOverHover: {
        cursor: "pointer",
        background: blue900,
        width: 155,
        position: "fixed",
        bottom: 0,
        right: 0,
        zIndex: 100,
        textAlign: "center",
        borderRadius: "20px 0 0 0",
    },
    meta: {
        position: "absolute",
        right: "15px",
        marginTop: "48px",
    },
    messageFinalized: {
        width: "85%",
        margin: "0 auto",
        fontFamily: "Roboto, sans-serif",
        borderRadius: "7.5px",
        textShadow: "0 1px 0 rgba(255,255,255,0.4)",
        backgroundColor: "rgba(255,245,196,0.95)",
        padding: "6px 12px 6px 12px",
        boxShadow: "0 1px 0.5px rgba(0,0,0,0.13)",
        color: "rgba(0,0,0,0.95)",
        fontSize: "12.5px",
        lineHeight: "21px",
    },
};

class EmployeeButton extends Component {
    static propTypes = {
        project: PropTypes.string.isRequired,
    };

    state = {
        open: false,
        hover: false,
        editorState: EditorState.createEmpty(),
        socket: undefined,
        ended: false,
    };

    scrollToDown() {
        const { messagesContainer } = this.refs;
        if (!_.isUndefined(messagesContainer) && !_.isNull(messagesContainer)) {
            const scrollHeight = _.toNumber(messagesContainer.scrollHeight);
            const clientHeight = _.toNumber(messagesContainer.clientHeight);
            const sp = _.gt(scrollHeight, clientHeight)
                ? scrollHeight - clientHeight
                : 0;
            messagesContainer.scrollTop = sp;
        }
    }

    scrollToLastMessage(idElement) {
        const element = document.getElementById(idElement);
        const position = element.getBoundingClientRect();
        const { messagesContainer } = this.refs;
        if (
            !_.isUndefined(messagesContainer) &&
            !_.isNull(messagesContainer) &&
            !_.isUndefined(position) &&
            !_.isNull(position)
        ) {
            const scrollHeight = _.toNumber(messagesContainer.scrollHeight);
            const messagePositionTop = _.toNumber(_.get(position, "top", 0));
            let sp = scrollHeight - (scrollHeight - messagePositionTop) - 50;
            if (sp < 0) {
                sp = 0;
            }
            messagesContainer.scrollTop = sp;
        }
    }

    handleTouchTap = (event) => {
        const {
            hasFirstMessage,
            markMessagesAsRead,
            conversation,
            messages,
            openDialogMessage,
        } = this.props;
        event.preventDefault();
        if (hasFirstMessage) {
            const readOnly = _.isUndefined(conversation);
            const unreadMessages = getCounterUnreadEmployee(messages);

            this.setState({
                open: !this.state.open,
                anchorEl: event.currentTarget,
            });

            if (this.state.socket === undefined) {
                this.handleConnection();
            }

            if (!this.state.open && !readOnly) {
                if (unreadMessages > 0) {
                    const lastMessage = (messages || Immutable.List()).get(
                        (unreadMessages - 1) * -1
                    );
                    if (!_.isUndefined(lastMessage) && !_.isNull(lastMessage)) {
                        const uuid = _.get(lastMessage, "uuid");
                        setTimeout(() => this.scrollToLastMessage(uuid), 40);
                    } else {
                        setTimeout(() => scrollToDown(), 40);
                    }
                }
            }
            markMessagesAsRead();
        } else {
            openDialogMessage();
        }
    };

    handleConnection = (firstMessage) => {
        const {
            person,
            project,
            changeStateConnection,
            setConversationId,
            addMessage,
            chatWebSocketManager,
            closeConversation,
            dequeueMessage,
        } = this.props;
        chatWebSocketManager.projectId = project;
        chatWebSocketManager.as = "employee";
        chatWebSocketManager.person = person;
        const connection = chatWebSocketManager.connection;
        this.setState({
            socket: connection,
        });
        connection.subscribe(
            (message) => {
                switch (message.type) {
                    case "register":
                        changeStateConnection(message.status);
                        break;
                    case "dequeue":
                        dequeueMessage(message);
                        break;
                    case "message":
                        addMessage(message, person, this.state.open);
                        if (
                            _.isEqual(_.get(message, "author"), person) ||
                            this.state.open
                        ) {
                            setTimeout(() => this.scrollToDown(), 40);
                        }
                        break;
                    case "conversation":
                        switch (message.status) {
                            case "created":
                                setConversationId(message.conversation);
                                if (!_.isEmpty(firstMessage)) {
                                    this.sendMessage(firstMessage);
                                }
                                break;
                            default:
                                console.log(
                                    "Not handled conversation message type",
                                    message
                                );
                                break;
                        }
                        break;
                    case "notification":
                        switch (message.notificationType) {
                            case "CONVERSATION_CLOSED":
                                closeConversation();
                                break;
                            default:
                                console.info(
                                    "notification not hadled",
                                    message
                                );
                                break;
                        }
                        break;
                    default:
                        console.log("not handled", message);
                        break;
                }
            },
            (error) => console.log(error),
            () => console.log("completed")
        );
    };

    handleRequestClose = (reason) => {
        this.setState({ open: false });
    };

    handlerChangeText = (editorState) => {
        this.setState({ editorState });
    };

    handlerReboot = () => {
        const { hasFirstMessage, openDialogMessage } = this.props;
        if (!hasFirstMessage) {
            openDialogMessage();
        }
    };

    handleEndConversation = () => {
        const { conversation, resetFirstMessageChat } = this.props;
        if (!_.isUndefined(this.state.socket) && !_.isUndefined(conversation)) {
            const terminationMessage = {
                conversation,
                type: "conversation",
            };
            this.state.socket.next(JSON.stringify(terminationMessage));
            this.setState({
                ended: true,
            });
            resetFirstMessageChat();
        }
    };

    sendMessage = (text) => {
        const { conversation } = this.props;
        if (!_.isUndefined(this.state.socket) && !_.isUndefined(conversation)) {
            const validMessage = {
                type: "message",
                conversation,
                text,
                status: "sending",
                uuid: v4(),
                timestamp: moment().valueOf(),
            };
            this.state.socket.next(JSON.stringify(validMessage));
        }
    };

    render() {
        const {
            status,
            conversation,
            messages,
            person,
            evaluator,
            chatWebSocketManager,
            closeConversation,
        } = this.props;
        const readOnly = _.isUndefined(conversation);
        const unreadMessages = getCounterUnreadEmployee(messages);
        const sizeMessages = messages.size;
        const menu = (
            <div>
                <div>
                    <IconButton
                        tooltipPosition="bottom-left"
                        tooltip="Minimizar"
                        onClick={this.handleRequestClose}
                        tooltipStyles={{ margin: "-37px 34px 0px 0px" }}
                    >
                        <HideIcon color={"#FFF"} style={{ marginRight: 10 }} />
                    </IconButton>
                </div>
            </div>
        );
        const subMenu = (
            <div>
                <div>
                    {readOnly && (
                        <IconButton
                            disabled={!readOnly}
                            tooltipPosition="bottom-left"
                            tooltip="Reiniciar"
                            onClick={this.handlerReboot}
                        >
                            <ResetIcon color={"#FFF"} />
                        </IconButton>
                    )}
                    {!readOnly && (
                        <IconButton
                            disabled={readOnly}
                            tooltipPosition="bottom-left"
                            tooltip="Terminar"
                            onClick={this.handleEndConversation}
                        >
                            <FinishIcon color={"#FFF"} />
                        </IconButton>
                    )}
                </div>
            </div>
        );
        const isStatusMenu = (
            <CircleIcon
                style={{
                    margin: "12px 0 0 6px",
                    borderRadius: "100%",
                    background: "#FFF",
                }}
                color={mapConnectionColor(status)}
            />
        );

        let styleContentChatLine = this.state.hover
            ? styles.buttonPopOver
            : styles.buttonPopOverHover;
        if (this.state.open) {
            styleContentChatLine = _.assign({}, styleContentChatLine, {
                display: "none",
            });
        }
        const nameEvaluator =
            !_.isUndefined(evaluator) && !_.isNull(evaluator)
                ? `${_.get(evaluator, "name", "")} ${_.get(
                      evaluator,
                      "lastName",
                      ""
                  )}`
                : "";

        return (
            <div>
                <div
                    onClick={this.handleTouchTap}
                    style={{ position: "fixed", bottom: 0, right: 0 }}
                >
                    <div
                        style={styleContentChatLine}
                        onMouseEnter={() => this.setState({ hover: true })}
                        onMouseLeave={() => this.setState({ hover: false })}
                    >
                        <div style={{ padding: "13px 5px 13px" }}>
                            {unreadMessages > 0 ? (
                                <span
                                    style={{
                                        color: "white",
                                        fontSize: "14px",
                                        fondWeight: "bold",
                                    }}
                                >
                                    {unreadMessages > 99
                                        ? "+99"
                                        : unreadMessages}{" "}
                                    mensajes sin leer
                                </span>
                            ) : (
                                <span
                                    style={{
                                        color: "#FFF",
                                        fontSize: "14px",
                                        fondWeight: "bold",
                                        marginLeft: -28,
                                    }}
                                >
                                    Chat en línea
                                    <div
                                        style={{
                                            display: "inline-block",
                                            width: 6,
                                        }}
                                    />
                                    <div
                                        style={{
                                            position: "absolute",
                                            display: "inline-block",
                                        }}
                                    >
                                        <MessageIcon color={"#FFF"} />
                                    </div>
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <Popover
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    targetOrigin={{ horizontal: "right", vertical: "bottom" }}
                    useLayerForClickAway={false}
                    autoCloseWhenOffScreen={false}
                    style={{ zIndex: "1460 !important" }}
                >
                    <div style={styles.box}>
                        <AppBar
                            showMenuIconButton={false}
                            title={`${mapConnectionLabel(
                                status,
                                nameEvaluator
                            )}`}
                            titleStyle={{ fontSize: "15px" }}
                            iconElementRight={menu}
                        />
                        <AppBar
                            style={{ background: blue500 }}
                            iconElementLeft={isStatusMenu}
                            iconElementRight={subMenu}
                        />
                        <div style={styles.inner} ref={"messagesContainer"}>
                            <div style={styles.content}>
                                {messages.map((message, key, list) => {
                                    const dir =
                                        person === message.author
                                            ? "out"
                                            : "in";
                                    const continuation =
                                        _.get(
                                            list.toJS(),
                                            `${key - 1}.author`
                                        ) === message.author;

                                    return (
                                        <Message
                                            text={message.text}
                                            dir={dir}
                                            continuation={continuation}
                                            key={_.get(message, "uuid")}
                                            idElement={_.get(message, "uuid")}
                                            type={message.senderType}
                                        />
                                    );
                                })}
                                {isFinalizedChat(status) &&
                                messages.size === 0 ? (
                                    <div style={styles.messageFinalized}>
                                        <div
                                            style={{
                                                textAlign: "center",
                                                paddingTop: 12,
                                            }}
                                        >
                                            Esta conversación ha terminado. Si
                                            desea conectarse nuevamente con un
                                            evaluador, puede hacer clic en el
                                            botón reiniciar.
                                        </div>
                                        <div style={{ textAlign: "center" }}>
                                            <IconButton
                                                tooltipPosition="bottom-center"
                                                tooltip="Reiniciar"
                                                onClick={this.handlerReboot}
                                            >
                                                <ResetIcon color={"#1C1C1C"} />
                                            </IconButton>
                                        </div>
                                    </div>
                                ) : (
                                    <span />
                                )}
                            </div>
                        </div>
                        <div style={styles.bottom}>
                            <table
                                style={{
                                    borderCollapse: "collapse",
                                    width: "100%",
                                    padding: 0,
                                    height: "100%",
                                }}
                            >
                                <tbody>
                                    <tr>
                                        <td
                                            style={{
                                                padding: 0,
                                                verticalAlign: "top",
                                            }}
                                        >
                                            <div
                                                style={_.assign(styles.editor, {
                                                    width: 260,
                                                })}
                                            >
                                                <Editor
                                                    editorState={
                                                        this.state.editorState
                                                    }
                                                    onChange={
                                                        this.handlerChangeText
                                                    }
                                                    placeholder={
                                                        "Escribir mensaje..."
                                                    }
                                                    keyBindingFn={(event) => {
                                                        const currentText = this.state.editorState
                                                            .getCurrentContent()
                                                            .getLastBlock()
                                                            .getText();
                                                        if (
                                                            event.keyCode ===
                                                                13 &&
                                                            this.state.editorState
                                                                .getCurrentContent()
                                                                .hasText()
                                                        ) {
                                                            // SEND THE MESSAGE
                                                            this.setState({
                                                                editorState: EditorState.createEmpty(),
                                                            });
                                                            this.sendMessage(
                                                                currentText
                                                            );
                                                            return "send-message";
                                                        }
                                                        return getDefaultKeyBinding(
                                                            event
                                                        );
                                                    }}
                                                    readOnly={readOnly}
                                                />
                                            </div>
                                        </td>
                                        <td
                                            style={{
                                                padding: 0,
                                                width: 45,
                                                verticalAlign: "top",
                                            }}
                                        >
                                            <FloatingActionButton
                                                mini
                                                style={styles.sendButton}
                                                disabled={readOnly}
                                                onClick={() => {
                                                    const currentText = this.state.editorState
                                                        .getCurrentContent()
                                                        .getLastBlock()
                                                        .getText();
                                                    if (
                                                        !_.isEqual(
                                                            currentText,
                                                            ""
                                                        )
                                                    ) {
                                                        // SEND THE MESSAGE
                                                        this.setState({
                                                            editorState: EditorState.createEmpty(),
                                                        });
                                                        this.sendMessage(
                                                            currentText
                                                        );
                                                        return "send-message";
                                                    }
                                                }}
                                            >
                                                <SendIcon />
                                            </FloatingActionButton>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Popover>
                <SendMessageDialog
                    socket={this.state.socket}
                    handleConnection={this.handleConnection}
                    closeConversation={closeConversation}
                    cleanConnection={chatWebSocketManager.cleanConnection}
                />
            </div>
        );
    }
}

function mapStateToProps({ chatEmployee, login }) {
    return {
        person: _.get(login.get("profile"), "_id"),
        status: chatEmployee.get("socketStatus"),
        conversation: chatEmployee.get("conversation"),
        messages: chatEmployee.get("messages"),
        evaluator: chatEmployee.get("evaluator"),
        hasFirstMessage: chatEmployee.get("hasFirstMessage"),
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            changeStateConnection,
            setConversationId,
            addMessage,
            cleanState,
            closeConversation,
            markMessagesAsRead,
            dequeueMessage,
            openDialogMessage,
            resetFirstMessageChat,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeButton);
